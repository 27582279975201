import React from "react";
import { checkWebViewEqualsOne } from "../Js-CommonFunctions";

export default function BreadcrumbMobile({ fields }) {
    var hasWebviewEqualsOne = checkWebViewEqualsOne();
    if (fields !== null && fields !== undefined && fields !== "" && fields.breadCrumb !== null && fields.breadCrumb !== "" && fields.breadCrumb.breadcrumbList !== null && !hasWebviewEqualsOne) {
        return (
            <>
                <ol className={"breadcrumb"}>
                    {fields.breadCrumb.breadcrumbList.map((breadcrump, index) => {
                        {
                            return (
                                <>
                                    {(breadcrump.url != null && breadcrump.url != "") ?
                                        (
                                            <>
                                                {breadcrump.url == "#" ?
                                                    (
                                                        <li className="breadcrumb-item">
                                                            <span>{breadcrump.title}</span>
                                                        </li>
                                                    ) :
                                                    (
                                                        <li className="breadcrumb-item">
                                                            <a href={breadcrump.url} aria-label={breadcrump.title} >{breadcrump.title}</a>
                                                        </li>
                                                    )
                                                }
                                            </>
                                        )
                                        :
                                        (<li className="breadcrumb-item  active">
                                            <span>{breadcrump.title}</span>
                                        </li>
                                        )}
                                </>
                            );
                        }
                    }
                    )}
                </ol>
            </>
        )
    } else {
        return <></>;
    }
}
