import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect } from "react";
import { CommonBookmarkallpage, webViewEqualsOne } from '../Js-CommonFunctions';

export default function MosaicAll({ model, commonValues, visibleItemCount }) {
  let overallcount = 0;
  let tabcount = 0;

  useEffect(() => {
    CommonBookmarkallpage();
    webViewEqualsOne();
  }, []);

  return (
    <>
      <input type="hidden" value={model.customItems.length} id="totalresult" />
      <input type="hidden" value={model.newsPagenumber} id="pagecount" />

      <div className={'row ' + (model.isEventsPage ? 'filter-result' : '')}>
        {model.customItems != null &&
          model.customItems
            .slice(0, visibleItemCount)
            .map((res, index) => {
              overallcount++;
              if (res !== null) {
                if (tabcount < model.newsPagenumber * model.perDevice) {
                  return (
                    <div
                      key={index}
                      className={
                        'col-12 col-md-4 mb-3 ' +
                        (model.isEventsPage ? '' : '')
                      }
                      id={'event-' + tabcount}
                    >
                      <div
                        className="col grid-item grid-withpicture bg-transparant shadow-none border-0 position-relative"
                        data-date=""
                      >

                        <a
                          className="icon-heart-unfill-rw bookmark-news-new bookmark picto  ava-wishlist position-absolute ava-wishlist"
                          role="button"
                          href="javascript:void(0);"
                          aria-label={res.newsId}
                          aria-labelledby={'news'}
                          data-templatetype="News List"
                          data-itemlabel={res.newsDisplayName}
                        ></a>

                        <div
                          className=
                          ' grid-new-item grid-withpicture grid-no-home position-relative ' >
                          <div className={"position-relative grid-picture m-0 p-0 rounded-3 " +
                            (res.hasVideo ? ' play-icon' : '')}>
                            {res.newsImgUrl !== null && res.newsImgUrl !== '' && res.newsImgUrl !== undefined &&
                              <img
                                className="rounded-3"
                                src={res.newsImgUrl}
                                alt={res.newsImgAlt}
                                loading="lazy"
                                title={res.newsImgAlt}
                              />
                            }
                          </div>
                          {!res.newsIsElibigible &&
                            model.newTypesItems != null &&
                            model.newTypesItems != null &&
                            model.newTypesItems.map((item, index) => {
                              return <>{item.image}</>;
                            })}
                          <a
                            href={commonValues.urlPrefix + res.newsUrlPath}
                            aria-label="news-detail"
                            className="position-absolute w-100 h-100 block-link stretched-link"
                          ></a>
                          <div className="grid-new-content text-start grid-content-mobile">
                            {res.retailerName != null && res.retailerName != '' && (
                              <div className="retailer-name my-1">{res.retailerName}</div>
                            )}

                            {res.isEventsDisplayDate &&
                              res.newsProperDate.value != null &&
                              res.newsProperDate.value != '' && (
                                <div className="date">
                                  <RichText field={res.newsProperDate} />
                                </div>
                              )}

                            {res.newsDisplayName != null && res.newsDisplayName != '' && (
                              <div className="grid-new-title py-1 ">{res.newsDisplayName}</div>
                            )}


                            <div className="" >
                              <a aria-label={model.homePageOffersText} href={model.urlPrefix + res.newsUrlPath} className="grid-new-cta text-decoration-underline gtmClickNavigation"
                              > {res.externalUrlDescription}</a>
                            </div >
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
              tabcount++;
            })}
      </div>
    </>
  );
}
