import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';


export default function TitleDescription({ fields }) {
  if (fields) {
    return (
      <section className="welcome text-center" style={{ overflow: "hidden" }}>
        <div className="container">
          {fields.fromContentResolver !== null && fields.constant.offerTitle != null && fields.constant.offerTitle !== "" &&
            <h2 className="text-uppercase">
              {(fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.offerTitle] !== null && fields.fromContentResolver[fields.constant.offerTitle]?.value !== "" && fields.fromContentResolver[fields.constant.offerTitle]?.value !== null) &&
                <RichText field={fields.fromContentResolver[fields.constant.offerTitle]} />
              }
            </h2>
          }
          <div>
            {(fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.offerdescription] !== null && fields.fromContentResolver[fields.constant.offerdescription]?.value !== "" && fields.fromContentResolver[fields.constant.offerdescription]?.value !== null) &&
              <RichText field={fields.fromContentResolver[fields.constant.offerdescription]} />
            }
          </div>
        </div>
      </section>
    )
  } else {
    return <></>;
  }
}