import React from 'react';

export default function MovieDetails({ fields }) {

  if (fields != null && fields.movieDetails != null) {
    return (
      <section className="movie-synopsis" id="details">
        {/* {(!fields.movieDetails.isNewBrandedSite) &&
          <div className="text-center">
            {(fields.movieDetails.details) &&

              <h2 className="content-title text-uppercase text-center underline">{fields.movieDetails.details}</h2>
            }
          </div>
        } */}
        <div className="container">
          {/* {(fields.movieDetails.isNewBrandedSite) && */}
            <div className="header-title carousel-title  d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
              <a className="title mb-1 mb-lg-0" href="#">
                <h2>{fields.movieDetails.details}</h2>
              </a>
            </div>
          {/* } */}
          <div className="row">
            <div className={"col-sm-9 col-12 synopsis-wrap" + (fields.movieDetails.isNewBrandedSite ? " " : "")}>
              <div className={"production " + (fields.movieDetails.isNewBrandedSite ? "" :"")}>
                <ul className="clearfix">
                  {(fields.movieDetails.releaseMovieDate !== null && fields.movieDetails.releaseMovieDate !== "") &&
                    <li>
                      <span className={"job" + (fields.movieDetails.isNewBrandedSite ? "" : " text-uppercase")}>
                        {fields.movieDetails.releasedOn}
                      </span>
                      <span className="value">
                        {fields.movieDetails.releaseMovieDate}
                      </span>
                    </li>
                  }
                  {(fields.movieDetails.directedByInfo !== null && fields.movieDetails.directedByInfo !== "") &&

                    <li>
                      <span className={"job" + (fields.movieDetails.isNewBrandedSite ? "" : " text-uppercase")}>
                        {fields.movieDetails.directedBy}
                      </span>
                      <span className="value">
                        {fields.movieDetails.directedByInfo}
                      </span>
                    </li>
                  }
                  {(fields.movieDetails.genreInfo !== null && fields.movieDetails.genreInfo !== "") &&

                    <li>
                      <span className={"job" + (fields.movieDetails.isNewBrandedSite ? "" : " text-uppercase")}>
                        {fields.movieDetails.genre}
                      </span>
                      <span className="value">
                        {fields.movieDetails.genreInfo}
                      </span>
                    </li>
                  }
                  {(fields.movieDetails.filmRunningTime !== null && fields.movieDetails.filmRunningTime !== "") &&
                    <li>
                      <span className={"job" + (fields.movieDetails.isNewBrandedSite ? "" : " text-uppercase")}>
                        {fields.movieDetails.runningTime}
                      </span>
                      <span className="value">
                        {fields.movieDetails.filmRunningTime}
                      </span>
                    </li>
                  }
                  {(fields.movieDetails.audienceId !== null && fields.movieDetails.audienceId !== "") &&
                    <li>
                      <span className={"job" + (fields.movieDetails.isNewBrandedSite ? "" : " text-uppercase")}>
                        {fields.movieDetails.public}
                      </span>
                      <span className="value">
                        {fields.movieDetails.audienceId}
                      </span>
                    </li>
                  }
                </ul>
              </div>
              {((fields.movieDetails.synopsis !== null && fields.movieDetails.synopsis !== "") || (fields.movieDetails.castInfo !== null && fields.movieDetails.castInfo !== "")) &&
                <div className={"synopsis-container "+ (fields.movieDetails.isNewBrandedSite?" " :"")}>
                  {(fields.movieDetails.synopsis !== null && fields.movieDetails.synopsis !== "") &&
                    <p className="synopsis">
                      {fields.movieDetails.synopsis}
                    </p>
                  }
                  {(fields.movieDetails.castInfo !== null && fields.movieDetails.castInfo !== "") &&
                    <div className="cast">
                      <span className={(fields.movieDetails.isNewBrandedSite ? "" : " text-uppercase") + " cast caption"}>{fields.movieDetails.cast}</span> <span className="star"> {fields.movieDetails.castInfo} </span>
                    </div>
                  }
                </div>
              }
            </div>
            <div className={"col-sm-3 col-12" + (fields.movieDetails.isNewBrandedSite ? " p-0" : "")}>
              <div className={"next-show text-uppercase position-relative w-100 d-flex flex-row flex-wrap " + (fields.movieDetails.isNewBrandedSite ? "" : " bg-main")}>

                {(fields.movieDetails.filmPoster !== "" && fields.movieDetails.filmPoster !== null) &&
                  <div className="img-wrap w-100">
                    <img className='border-8' src={fields.movieDetails.filmPoster} alt={fields.movieDetails.filmTitle} title={fields.movieDetails.filmPoster} />
                  </div>
                }
                <div className={"movie-next-hour position-relative w-100" + (fields.movieDetails.isNewBrandedSite ? "" : " text-light")}>
                  {(!fields.movieDetails.isNewBrandedSite) &&
                    (fields.movieDetails.differentHasValue) &&
                    <><div className="next-show-caption">
                      {(fields.movieDetails.different !== fields.movieDetails.timeSpanMinValue) &&
                        fields.movieDetails.nextshowin
                      }
                    </div>
                      <div className="hour">
                        <span>
                          {(fields.movieDetails.hourValue !== null && fields.movieDetails.hourValue !== "") &&
                            fields.movieDetails.hourValue
                          }
                        </span>
                      </div>
                    </>
                  }
                  {(fields.movieDetails.bookNowUrl !== "" && fields.movieDetails.bookNowUrl !== null) &&
                    <div className="action">
                      <a className={(fields.movieDetails.isNewBrandedSite ? " btn btn-dark contact-button " : " link-button btn-transparent text-uppercase btn-see-more") + " analytics_moviebooknowclk1 gtmMovieBook"} target="_blank" rel="noreferrer" href={fields.movieDetails.bookNowUrl}>{fields.movieDetails.bookNow}</a>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return <></>;
  }
}
