import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

export default function NewsletterUpgrade({ fields }) {
  if (fields !== undefined && fields !== null && fields !== "" &&
    fields.model !== undefined && fields.model !== null && fields.model !== "" &&
    fields.model !== undefined && fields.model !== null && fields.model !== "") {
    var model = fields.model;
    return (
      <div className="container account-unsubscribe" id="unsubscribeErrorView">
        <div className="unsubscribe-logo">
          <img src={model.logo?.value?.src} alt="" title="" className="unsubscribe-image-logo" />
        </div>
        <div className="unsubscribe-manage-preferance">
          {model.displayErrorMessage &&
            <div className="manage-preferance-subheading-thanku text-center"><Text field={model.data.errorMessageText} /></div>
          }
          {!model.displayErrorMessage &&
            <div className="manage-preferance-subheading-thanku text-center"><Text field={model.data.newsletterSuccessText} /></div>
          }
        </div>
        {!model.displayErrorMessage &&
          <div className="unsubscribe-confirm-cta">
            <div className="align-items-center d-flex justify-content-center ">
              <a className="link-button btn-dark center-block" aria-label={model.data.newsletterLinkText} href={model.data.newsletterLink}>{model.data.newsletterLinkText}</a>
            </div>
          </div>
        }
      </div>
    )
  }
  else {
    return (
      <></>
    )
  }
}
