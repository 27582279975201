import React from 'react';
import SelectShoppingCenter from '../SelectShoppingCenter';
import ReactDOM from "react-dom";

export default function AddPreferredSC({ fields }) {
    if (fields !== undefined && fields !== null && fields !== "" && fields.discoverModel !== undefined && fields.discoverModel !== null && fields.discoverModel !== "") {
        return (
            <div className="tab-pane preferred-sc fade show active p-0 border-0" id="v-pills-home"
                role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div className="account-tab-pane1 rounded-0 bg-white p-5 pt-lg-4">
                    <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                        <div className="container modalContainer p-0 ">
                            <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                                <div className="col-2 text-center px-0">
                                    <a aria-label={fields?.title} className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                                        href="/"></a>
                                </div>
                                <div className="col-8 p-0 text-center">
                                    <div className=" text-center benefits-subtitle ">{fields.title}</div>
                                </div>
                                <div className="col-2 text-center px-0"></div>
                            </nav>
                        </div>
                    </div>
                    <div
                        className="d-flex d-lg-block d-none d-xl-block justify-content-start  account-center-name">{fields.title} </div>
                    <div className="d-flex my-3 preferred-sc-card-header mob-pd text-black ps-1">
                        <span className="picto icon-vip-card account-icon-spacing  fs-3"></span>
                        <span className="d-flex ms-2  account-card-header align-items-center">{fields.loyaltyProgramTitle}</span>
                    </div>
                    <div className="col-12 preferred-sc-box bg-white text-center">
                        <div className="preferred-sc-title mt-3">{fields.discoverModel?.discoverMoreTitle}</div>
                        <div className="preferred-sc-desc my-3"> {fields.discoverModel?.discoverMoreDescription}</div>
                        <div className="align-items-center d-flex justify-content-center ">
                            {fields.discoverModel?.link?.value?.url?.includes("javascript") ?
                                (
                                    <a href="javascript:void(0);" aria-label={fields.discoverModel?.link?.value?.text} data-testid="fnSelectPreferredSCclick" onClick={(e) => { fnSelectPreferredSC(fields) }} className="btn btn-preferred-sc btn-dark my-3 align-items-center">{fields.discoverModel?.link?.value?.text}</a>
                                ) :
                                (
                                    (fields.discoverfields.link?.value?.url !== null && fields.discoverfields.link?.value?.url !== undefined && fields.discoverfields.link?.value?.url !== "") ?
                                        (<a href={fields.discoverfields.link?.value?.url} aria-label={fields.discoverModel?.link?.value?.text} className="btn btn-preferred-sc btn-dark my-3 align-items-center">{fields.discoverModel?.link?.value?.text}</a>)
                                        : (
                                            <a href="javascript:void(0);" aria-label={fields.discoverModel?.link?.value?.text} data-testid="fnSelectPreferredSCElseclick" onClick={(e) => { fnSelectPreferredSC(fields) }} className="btn btn-preferred-sc btn-dark my-3 align-items-center">{fields.discoverModel?.link?.value?.text}</a>
                                        )
                                )}
                        </div>
                    </div>
                    <a aria-label={fields?.discoverMyAdv} className="d-flex justify-content-center preferred-sc-discover-button account-discover-button" href={"/" + fields.loyaltyProgramPageLink}>{fields.discoverMyAdv}</a>
                </div>
            </div>
        )
    }
    else {
        return <></>
    }
}

function fnSelectPreferredSC(data) {
    var globalPopinHtml = document.getElementById("popinPreferredSC")?.innerHTML;
    if (globalPopinHtml !== null && globalPopinHtml !== undefined
        && globalPopinHtml !== "" && globalPopinHtml.trim() == "") {
        ReactDOM.render(<SelectShoppingCenter fields={data} />, document.getElementById("popinPreferredSC"))
    }
    else {
        var ele = document.getElementById("preferredSC");
        if (ele !== null && ele !== undefined) {
            var popinClick = document.getElementById("popinPreferredSCClick");
            popinClick.click();
        }
    }
}
