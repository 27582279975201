import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';
import axios from 'axios';

export default function TopServices({ fields }) {

    const [topServiceData, setTopServiceData] = useState();

    let workerCenter = useSelector((state) => state.user.workerCenter);

    useEffect(() => {
        let isWorker =
            workerCenter !== undefined &&
            workerCenter !== '' &&
            workerCenter.split(',').includes(baseModel.pC_Code);
            
        if (isWorker) {
            // var loader = document.getElementById("loader");
            // if (loader)
            //     loader.classList.remove("d-none");
            axios({
                method: "post",
                url: "/api/sitecore/ServiceApi/GetTopServices",
                data: {
                    ursite: baseModel.urContextSite,
                    urlang: baseModel.contextLang,
                    isWorker: isWorker
                },
                async: false,
            })
                .then(function (response) {
                    // if (loader)
                    //     loader.classList.add("d-none");
                    if (response.data !== "") {
                        setTopServiceData(response.data);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    // if (loader)
                    //     loader.classList.add("d-none");
                });

        }
        else {
            if (fields && fields.topServices != null && fields.topServices?.serviceDetailModelList?.length > 0)
                setTopServiceData(fields.topServices)
        }
    }, []);

    if (fields !== null && fields !== null && fields !== undefined) {
        return (
            <>
                <div className={"all-services " + (fields?.topServices?.isNewBrandedSite ? "all-services-new " : "") + " top-services py-4 loyalty-bg"} id="top-services">
                    {topServiceData !== null && topServiceData !== undefined && topServiceData !== "" && topServiceData.serviceDetailModelList?.length > 0 &&
                        <div className="container">
                            {!topServiceData.isNewBrandedSite && topServiceData.topServices !== "" &&
                                < h2 className="content-title text-uppercase text-start">{topServiceData.topServices}</h2>
                            }

                            <div className="row">
                                {topServiceData.serviceDetailModelList.map((topserviceitem, index) => {
                                    if (topserviceitem == null || topserviceitem.serviceData == null

                                    ) {
                                        return <></>;
                                    }
                                    return (
                                        <div key={index} className="col-12 col-lg-4 mb-3 mb-lg-0">
                                            {topServiceData.display_Picture_ForService && topserviceitem.image !== "" ?
                                                (
                                                    <div className="card border-8">
                                                        <img src={topserviceitem.image} className="card-img-top border-8" alt={topserviceitem.serviceData.shortTitle}></img>
                                                        <div className="card-body text-start px-0">
                                                            <a href={topserviceitem.serviceUrl} aria-label={topserviceitem?.serviceData?.shortTitle} className="d-flex align-items-center text-start stretched-link">
                                                                <span className={"picto " + (topserviceitem.serviceData.pictoOn !== "" ? topserviceitem.serviceData.pictoOn : " icon-servicesaspictosservices")} ></span >
                                                                <div className=" title">{topserviceitem.serviceData.shortTitle}</div>
                                                            </a>
                                                            {(topserviceitem.isServiceLoyalty) &&

                                                                < div className="label loyalty-text mt-1">
                                                                    <span className="align-middle picto icon-vip-card"></span>
                                                                    <div className="text d-inline-block text-uppercase fw-bold">{topserviceitem.elligibility_Text}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ) :
                                                (
                                                    <div className="card border-8">
                                                        <div className="service-picto picto-block">
                                                            <span className={"picto " + (topserviceitem.serviceData.pictoOn !== "" ? topserviceitem.serviceData.pictoOn : " icon-servicesaspictosservices")} ></span >
                                                        </div>
                                                        <div className="card-body text-start px-0">
                                                            <a href={topserviceitem.serviceUrl} aria-label={topserviceitem?.serviceData?.shortTitle} className="d-flex align-items-center stretched-link">
                                                                <div className="title">{topserviceitem.serviceData.shortTitle}</div>
                                                            </a>
                                                            {(topserviceitem.isServiceLoyalty) &&

                                                                <div className="label loyalty-text mt-1">
                                                                    <span className="align-middle picto icon-vip-card"></span>
                                                                    <div className="text d-inline-block text-uppercase fw-bold">{topserviceitem.elligibility_Text}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className="loadersmall asyncloader d-none" id="loader">
                    <div className="dot-spin"></div>
                </div>
            </>
        )
    }
    else {
        return (<></>)
    }
}
