import React from 'react';

export default function GamePage404({ fields }) {
    if (fields !== null && fields !== "" && fields !== undefined) {
        var props = fields.gamePage404;
        return (
            <div className="container-fluid lbe home position-absolute">
                <div className="container lbe s-1 global-form">
                    <div className="app-lbe-container gamepage404">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="fs-4 d-flex align-items-center justify-content-center" style={{ height: "100px" }}>
                                    {props.errorText}
                                </div>
                                <div className="btn-sticky btn-submit">
                                    <a aria-label={props?.gamePageHomepageText} className="btn btn-dark center-block" href={props.urlPrefix + "/" + props.homePage}>{props.gamePageHomepageText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<></>)
    }
}
