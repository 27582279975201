import { RichText } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import Enumerable from "linq";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useSelector } from "react-redux";
import { GetRenewedAccessToken, createCookie, isTokenExpired, sortCenterList } from "../Js-CommonFunctions.js";
import { GoBackToMenu } from "../MyAccount/MyAccountScripts";
import VisitorCenterAddPopin from "../VisitorCenterAddPopin";
import VisitorCenterDeletePopin from "../VisitorCenterDeletePopin";

export default function MyCommunications({ fields, setPreferredCenters, setIsUpdateCenter, legalTextIdInfo, setLegalTextIdInfo }) {

  const [visitorCenters, setVisitorCenters] = useState([]);

  const [centers, setCenters] = useState([]);
  const [loyaltyOptin, setLoyaltyOptin] = useState(false);
  const [commercialEmailOptin, setCommercialEmailOptin] = useState(false);

  var visitorId = useSelector((state) => state.user.visitorId);
  var accessToken = useSelector((state) => state.user.accessToken);
  var refreshToken = useSelector((state) => state.user.refreshToken);

  useEffect(() => {

    if (fields != undefined && fields != null && fields != "") {

      if (fields.preferredCenters) {
        setVisitorCenters(sortCenterList(Enumerable.from(fields.preferredCenters).orderBy(x => x.centerName).toArray()));
      }

      if (fields.addPreferredCenter != undefined && fields.addPreferredCenter != null && fields.addPreferredCenter != "" &&
        fields.addPreferredCenter.centersList != undefined && fields.addPreferredCenter.centersList != null && fields.addPreferredCenter.centersList != "") {
        setCenters(fields.addPreferredCenter.centersList);
      }

      if (document.getElementById('loyaltyOptinEmail')) {
        document.getElementById('loyaltyOptinEmail').checked = fields.loyaltyOptinEnabled;
      }

      if (document.getElementById('commercialOptinEmail')) {
        document.getElementById('commercialOptinEmail').checked = fields.commercialEmailOptinEnabled;
      }

      setLoyaltyOptin(fields.loyaltyOptinEnabled);
      setCommercialEmailOptin(fields.commercialEmailOptinEnabled);

    }
  }, []);

  useEffect(() => {

    if (fields != undefined && fields != null && fields != "") {

      if (fields.preferredCenters) {
        setVisitorCenters(sortCenterList(Enumerable.from(fields.preferredCenters).orderBy(x => x.centerName).toArray()));
      }
    }
  }, [fields]);

  function removePreferredCenter(pcCode) {
    setIsUpdateCenter(true);
    setPreferredCenters(visitorCenters.filter(c => c.pcCode !== pcCode));
    setVisitorCenters(visitorCenters.filter(c => c.pcCode !== pcCode));

    createCookie("RefreshContent", "personalinformations-loyaltycard", 0);
  }

  function addPreferredCenter(centerName, pcCode, countryCode, isWestfield) {
    setIsUpdateCenter(true);
    setPreferredCenters([
      ...visitorCenters,
      { centerName: centerName, pcCode: pcCode, countryCode: countryCode, isWestfield: isWestfield }
    ]);

    var newArr = ([...visitorCenters, { centerName: centerName, pcCode: pcCode, countryCode: countryCode, isWestfield: isWestfield }]);
    var sortedArr = sortCenterList(Enumerable.from(newArr).orderBy(x => x.centerName).toArray());
    setVisitorCenters(sortedArr);

    createCookie("RefreshContent", "personalinformations-loyaltycard", 0);
  }

  function addRemovePreferredCenter(centerNameToAdd, pcCodeToAdd, pcCodeToRemove, countryCode) {
    setIsUpdateCenter(true);
    const newList = ([...visitorCenters, { centerName: centerNameToAdd, pcCode: pcCodeToAdd, countryCode: countryCode }]);
    setPreferredCenters(newList.filter(c => c.pcCode !== pcCodeToRemove));
    const newCenters = ([...visitorCenters, { centerName: centerNameToAdd, pcCode: pcCodeToAdd, countryCode: countryCode }]);
    setVisitorCenters(newCenters.filter(c => c.pcCode !== pcCodeToRemove));

    createCookie("RefreshContent", "personalinformations-loyaltycard", 0);
  }

  function updateLegalTextId(legalTextId) {
    setLegalTextIdInfo(({ }) => ({
      ...legalTextIdInfo,
      visitorOptedLegalTextId: legalTextId,
    }));
  }

  function loyaltyOptinEmailChange() {
    if (isTokenExpired()) {
      GetRenewedAccessToken(toggleLoyaltyOptin);
    } else {
      toggleLoyaltyOptin(visitorId, accessToken, refreshToken);
    }
  }

  function toggleLoyaltyOptin(visitorId, accessToken, refreshToken) {
    var isActive = document.getElementById('loyaltyOptinEmail').checked;
    var loyaltyLegalTextId = document.getElementById('loyaltyOptinEmail').getAttribute("data-legaltextid").valueOf();

    if (isActive) {

      var createOptinURL =
        "/api/sitecore/VisitorApi/CreateVisitorOptin?optinType=loyalty&optinChannel=email&legalTextId="
        + loyaltyLegalTextId;

      axios({
        method: "post",
        url: createOptinURL,
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken
        },
        async: true,
      })
        .then(function (response) {
          if (response.data[0]) {
            setLoyaltyOptin(true);
          } else {
            document.getElementById('loyaltyOptinEmail').checked = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    } else {

      var deleteOptinURL = "/api/sitecore/VisitorApi/DeleteVisitorOptin?optinType=loyalty&optinChannel=email";
      var isCommercialEmailActive = document.getElementById('commercialOptinEmail').checked;

      if (isCommercialEmailActive) {
        var deleteEmailOptinURL = "/api/sitecore/VisitorApi/DeleteVisitorOptin?optinType=commercial&optinChannel=email";

        axios({
          method: "post",
          url: deleteEmailOptinURL,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
          },
          async: true,
        })
          .then(function (response) {
            if (response.data[0]) {
              setCommercialEmailOptin(false);
              document.getElementById('commercialOptinEmail').checked = false;

              axios({
                method: "post",
                url: deleteOptinURL,
                data: {
                  visitorId: visitorId,
                  accessToken: accessToken,
                  refreshToken: refreshToken
                },
                async: true,
              })
                .then(function (response1) {
                  if (response1.data[0]) {
                    setLoyaltyOptin(false);
                  } else {
                    document.getElementById('loyaltyOptinEmail').checked = true;
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });

            } else {
              document.getElementById('commercialOptinEmail').checked = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      else {
        axios({
          method: "post",
          url: deleteOptinURL,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
          },
          async: true,
        })
          .then(function (response) {
            if (response.data[0]) {
              setLoyaltyOptin(false);
            } else {
              document.getElementById('loyaltyOptinEmail').checked = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  function commercialOptinEmailChange() {
    if (isTokenExpired()) {
      GetRenewedAccessToken(toggleCommercialOptin);
    } else {
      toggleCommercialOptin(visitorId, accessToken, refreshToken);
    }
  }

  function toggleCommercialOptin(visitorId, accessToken, refreshToken) {
    var isActive = document.getElementById('commercialOptinEmail').checked;
    var commercialLegalTextId = document.getElementById('commercialOptinEmail').getAttribute("data-legaltextid").valueOf();

    if (isActive) {

      var loyaltyActive = document.getElementById('loyaltyOptinEmail').checked;

      if (loyaltyActive) {

        var createOptinURL =
          "/api/sitecore/VisitorApi/CreateVisitorOptin?optinType=commercial&optinChannel=email&legalTextId="
          + commercialLegalTextId;

        axios({
          method: "post",
          url: createOptinURL,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
          },
          async: true,
        })
          .then(function (response) {
            if (response.data[0]) {
              setCommercialEmailOptin(true);
            } else {
              document.getElementById('commercialOptinEmail').checked = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });

      } else {

        var loyaltyLegalTextId = document.getElementById('loyaltyOptinEmail').getAttribute("data-legaltextid").valueOf();
        var createLoyaltyOptinURL = "/api/sitecore/VisitorApi/CreateVisitorOptin?optinType=loyalty&optinChannel=email&legalTextId="
          + loyaltyLegalTextId;

        axios({
          method: "post",
          url: createLoyaltyOptinURL,
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
          },
          async: true,
        })
          .then(function (response) {
            if (response.data[0]) {

              setLoyaltyOptin(true);
              document.getElementById('loyaltyOptinEmail').checked = true;

              var createCommercialOptinURL =
                "/api/sitecore/VisitorApi/CreateVisitorOptin?optinType=commercial&optinChannel=email&legalTextId="
                + commercialLegalTextId;

              axios({
                method: "post",
                url: createCommercialOptinURL,
                data: {
                  visitorId: visitorId,
                  accessToken: accessToken,
                  refreshToken: refreshToken
                },
                async: true,
              })
                .then(function (response1) {
                  if (response1.data[0]) {
                    setCommercialEmailOptin(true);
                  } else {
                    document.getElementById('commercialOptinEmail').checked = false;
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    } else {

      var deleteOptinURL = "/api/sitecore/VisitorApi/DeleteVisitorOptin?optinType=commercial&optinChannel=email";
      axios({
        method: "post",
        url: deleteOptinURL,
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken
        },
        async: true,
      })
        .then(function (response) {
          if (response.data[0]) {
            setCommercialEmailOptin(false);
          } else {
            document.getElementById('commercialOptinEmail').checked = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function deletePreferredCenterClick(pcCode) {
    createRoot(document.getElementById("popinVisitorCenterDelete"))
      .render(<VisitorCenterDeletePopin model={fields.deletePreferredCenter} pcCode={pcCode} removePreferredCenter={removePreferredCenter} />);
  }

  function addPreferredCenterClick(data) {
    if (data.isMobileView) {
      document.getElementById("communicationsHeader").classList.add('d-none');
      document.getElementById("communicationsBody").classList.add('d-none');
      createRoot(document.getElementById("addPreferredCenterMobile"))
        .render(<VisitorCenterAddPopin fields={data} consent={fields.content} allCenters={centers} preferredCenters={visitorCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={false} />);
      document.getElementById("addPreferredCenterMobile").classList.remove('d-none');

      if (document.getElementById('addPreferredSC')) {
        window.scrollTo({
          top: document.getElementById('addPreferredSC').offsetTop - 78,
          behavior: "smooth"
        });
      }
    }
    else {
      createRoot(document.getElementById("popinVisitorCenterAdd"))
        .render(<VisitorCenterAddPopin fields={data} consent={fields.content} allCenters={centers} preferredCenters={visitorCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={false} />);
    }
  }

  function editPreferredCenterClick(data, pcCode) {
    if (data.isMobileView) {
      document.getElementById("communicationsHeader").classList.add('d-none');
      document.getElementById("communicationsBody").classList.add('d-none');
      createRoot(document.getElementById("addPreferredCenterMobile"))
        .render(<VisitorCenterAddPopin fields={data} consent={fields.content} allCenters={centers} preferredCenters={visitorCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} pcCodeToRemove={pcCode} addRemovePreferredCenter={addRemovePreferredCenter} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={false} />);
      document.getElementById("addPreferredCenterMobile").classList.remove('d-none');

      if (document.getElementById('addPreferredSC')) {
        window.scrollTo({
          top: document.getElementById('addPreferredSC').offsetTop - 78,
          behavior: "smooth"
        });
      }
    }
    else {
      createRoot(document.getElementById("popinVisitorCenterAdd"))
        .render(<VisitorCenterAddPopin fields={data} consent={fields.content} allCenters={centers} preferredCenters={visitorCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} pcCodeToRemove={pcCode} addRemovePreferredCenter={addRemovePreferredCenter} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={false} />);
    }
  }

  if (fields != undefined && fields != null && fields != "") {
    return (
      <>
        <div className="basic-benefits-container d-md-none d-lg-none d-xl-none bg-white" id="communicationsHeader">
          <div className="container modalContainer p-0 ">
            <nav className="benefits-header-height px-lg-1 row m-0   py-2">
              <div className="col-2 text-center px-0">
                <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black fs-3"
                  href="javascript:void(0);" onClick={() => { GoBackToMenu("my-communications") }}></a>
              </div>
              <div className="col-8 p-0 text-center">
                <div className=" text-center benefits-subtitle ">
                  {fields.title}
                </div>
              </div>
              <div className="col-2 text-center px-0">
              </div>
            </nav>
          </div>
        </div>

        <div className="mobile-container" id="communicationsBody">
          <div className="account-communications">
            <div className="row text-black pb-4 d-flex align-items-center d-none d-lg-block">
              <div className=" col-3">
              </div>
              <div className="d-flex justify-content-start  account-personal-informations">
                {fields.title}
              </div>
              <div className="account-go-back col-3">
              </div>
            </div>

            <div className="col-md-12 col-lg-12 communications-mobile-padding">
              <div className=" w-100  mb-4 communications-content ">
                {fields.content?.communicationDesc}
              </div>
              <div className=" w-100 mb-2 communications-title ">
                {fields.content?.communicationTitle}
              </div>
              <div className="container w-100 mb-2 personal-profile-container px-3">
                <div className="d-flex">
                  <div className="communications-profile-title-header flex-grow-1 pb-4">
                    <RichText field={fields.content?.loyaltyOptinHeading} />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 communications-profile-title-content  pb-2 pe-3">
                    <RichText field={fields.loyaltyLegalText} />
                  </div>
                  <div className="switch-control">
                    <input type="checkbox" id="loyaltyOptinEmail" onChange={() => { loyaltyOptinEmailChange() }}
                      data-legaltextid={fields.loyaltyLegalTextId} />
                    <label htmlFor="loyaltyOptinEmail"></label>
                  </div>
                </div>
              </div>

              <div className="container w-100  mb-4 personal-profile-container px-3">
                <div className="d-block">
                  <div className="communication-title-head account-profile-title-header flex-grow-1 pb-2">
                    {fields.content?.commercialOptinHeading}
                  </div>
                  {(!loyaltyOptin && !commercialEmailOptin) &&
                    <div className="d-flex">
                      <a className="picto icon-srv-information text-black text-decoration-none fs-5 me-3"
                        href="#"></a>
                      <div className="flex-grow-1 pb-2 communications-profile-title-content pe-3">
                        {fields.content?.commercialOptinInfoText}
                      </div>
                    </div>
                  }
                </div>

                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 communications-profile-title-content pb-2 pe-3">
                    <RichText field={fields.commercialEmailLegalText} />
                  </div>
                  <div className="switch-control">
                    <input type="checkbox" id="commercialOptinEmail" onChange={() => { commercialOptinEmailChange() }}
                      data-legaltextid={fields.commercialEmailLegalTextId} />
                    <label htmlFor="commercialOptinEmail"></label>
                  </div>
                </div>
              </div>


              <div className="w-100  mb-2 communications-title">
                {fields.content?.myPreferredSCTitle}
              </div>

              <button className="communications-button" onClick={(e) => { addPreferredCenterClick(fields.addPreferredCenter) }}>
                {fields.content?.searchShoppingCenter}
              </button>

              <div className="container w-100 px-0 py-3">
                {visitorCenters.map((item, index) => (
                  <div key={index} className="d-flex mx-0 communication-item px-0">
                    <div className="communications-item-center">
                      <div className=" communications-center">
                        {item.centerName}
                      </div>
                    </div>
                    <div className="d-flex communications-profile-align-items justify-content-center align-items-center">
                      {visitorCenters.length > 1 ? (
                        <>
                          <a className="picto icon-close-circle-rw fs-5 me-1 close-icon-color text-decoration-none" href="javascript:void(0);"
                            onClick={(e) => { deletePreferredCenterClick(item.pcCode) }}>
                          </a>
                          <a className="account-delete text-decoration-underline" href="javascript:void(0);"
                            onClick={(e) => { deletePreferredCenterClick(item.pcCode) }} >
                            {fields.content?.deleteCenter}
                          </a>
                        </>
                      ) : (
                        <>
                          <a className="picto icon-chevron-pen fs-5 me-1 close-icon-color text-decoration-none" href="javascript:void(0);"
                            onClick={(e) => { editPreferredCenterClick(fields.addPreferredCenter, item.pcCode) }}>
                          </a>
                          <a className="account-delete text-decoration-underline" href="javascript:void(0);"
                            onClick={(e) => { editPreferredCenterClick(fields.addPreferredCenter, item.pcCode) }}>
                            {fields.content?.editCenter}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="communications-profile-title-content">
                <RichText field={fields.content?.fullAccountMentionText} />
              </div>
            </div>
          </div>
        </div>

        {(fields.addPreferredCenter != undefined && fields.addPreferredCenter != null &&
          fields.addPreferredCenter != "" && fields.addPreferredCenter.isMobileView) &&
          < div className="d-none" id="addPreferredCenterMobile">
          </div>
        }
      </>
    )
  }
  else {
    return <></>
  }
}
