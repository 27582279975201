import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';



export default function MallAnchors({ fields }) {
  if (fields.mallAnchors !== null && fields.mallAnchors !== undefined && fields.mallAnchors != "") {
    return (
      <div className="anchor-navigation no-logo d-none d-sm-block">
        <div className="container">
          <ul className="nav">
            {fields.mallAnchors.anchorsLst !== null && fields.mallAnchors.anchorsLst.length > 0 && fields.mallAnchors.anchorsLst.map((item, index) => {
              return (
                <li key={index}><a href={item.jsslink.value.url} aria-label={item.jsslink.value} className="text-uppercase scrollTo"><Text field={item.blockObj["Name"]} /></a></li>
              )
            })
            }
          </ul>
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}