import React from 'react';

export default function ParkingConfirmationPopin({ fields }) {

  function closeSuccessPopin() {
    if (document.getElementById("ParkingConfirmationPopin"))
      document.getElementById("ParkingConfirmationPopin").classList.add("d-none");

    if (document.getElementsByClassName("modal-backdrop")[0])
      document.getElementsByClassName("modal-backdrop")[0].classList.add("d-none");

  }

  if (fields != null && fields !== "" && fields !== undefined) {
    return (
      <div className="modal modal-dialog modal-popin-trends licenseplate-popin" tabIndex="-1" role="dialog" id="ParkingConfirmationPopin">
        <div className="popinsuccess">
          <div className="modal-content">
            <div className="modal-header justify-content-end">
              <a
                className="close search-modal-header fs-5 ms-2 text-decoration-none" role="button"
                data-bs-dismiss="modal" aria-label="Close" data-testid="closeSuccessPopin" onClick={(e) => { closeSuccessPopin() }}>
                <span aria-hidden="true" className="picto icon-close-circle-rw fs-3 text-black"></span>
              </a>
            </div>
            <div className="modal-body text-center">
              <div className="popin-wrap">
                <img src={fields.picto?.value?.src} alt="" title="" className="parking-activated-icon-img" />
                <p className="easy-parking-modal-content ">{fields.successTitle}</p>
                <p className="easy-parking-modal-content-text">{fields.successDescription}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return <></>
  }
}
