import React, { useEffect } from 'react';
import { backToCenterPopin, closeMenu, countrySelecterDataLayer, enableAnnouncementBanner } from '../Js-CommonFunctions';

export default function CountrySelectorPopin({ model }) {
    useEffect(() => {

        var ele = document.getElementById("countrySelection");
        if (ele !== null && ele !== undefined) {
            var heightHeader = "0px";
            if(document.getElementById("headerBlock")){
                heightHeader =
                       document.getElementById("headerBlock").offsetHeight;
           }
           //To show header portion
           document.getElementById("countrySelection").style.top = heightHeader+"px";
           
            if (document.getElementById("shoppingCenterSelection")) {
                //close the existing modal pop up
                var popinClick = document.getElementById("popinCenterSelectorClick");
                if (popinClick !== null && popinClick !== undefined && popinClick !== "") { popinClick.click(); }
            }
            var popinClick = document.getElementById("popinCountrySelectorClick");
            if (popinClick !== null && popinClick !== undefined && popinClick !== "") { popinClick.click(); }
        }

    }, [model]);

    if (model !== null && model !== undefined && model !== "") {
        return (
            <div className=" modal-dialog modal-popin-selector m-0" id="countrySelection">
                <div className={"modal-content border-0 rounded" + (model.isBrandedSite ? " bg-white" : " bg-gray-90")}>
                    <div className="modal-header p-0 position-relative">
                    {model.exitLinkText !== "" && model.exitLinkText !== null &&
                                    <div className={"quitter-la-recherche d-lg-flex align-items-center"}>
                                        <a aria-label={model?.exitLinkText} className="d-flex align-items-center text-decoration-none" href="javascript:void(0);" data-bs-dismiss="modal" data-testid="enableAnnouncementBanner" onClick={(e) => { closeMenu(); enableAnnouncementBanner() }}>
                                            <span className="quit-content me-4">{model.exitLinkText}</span>
                                            <span className="picto icon-close-circle-rw text-center fs-1 text-black "></span>
                                        </a>
                                    </div>
                                }
                        {/* <div className="shoppingheader modal-popin-selector justify-content-center w-100">
                            <div className={"p-0"}>
                                <nav className={"navbar header-height col-sm-12 col-md-12 col-lg-12" + (model.isBrandedSite ? " bg-black" : " bg-white")}>
                                    <div className={"col-sm-2 col-md-2 col-lg-1 text-center px-0"}>
                                        <a className={"picto icon-chevron-bottom fs-3" + (model.isBrandedSite ? "" : " text-black")} aria-label="Close"
                                            data-bs-dismiss="modal" data-testid="backToCenterPopinClick" onClick={(e) => { backToCenterPopin() }}></a>
                                    </div>
                                    <div className={"col-sm-8 col-md-8 col-lg-10 text-center p-0"}>
                                        <div className={"text-center find-shopping-center text-uppercase" + (model.isBrandedSite ? " text-white" : " text-black")}>
                                            {model.heading}
                                        </div>
                                    </div>
                                    <div className={"col-sm-2 col-md-2 col-lg-1 text-center px-0"}>
                                        <a aria-label={model?.heading} className={"picto icon-close-circle-rw d-sm-block text-center fs-3 d-lg-none" + (model.isBrandedSite ? "" : " text-black")}
                                            href="javascript:void(0);" data-bs-dismiss="modal" data-testid="closemenuclick" onClick={(e) => { closeMenu(); enableAnnouncementBanner() }}></a>
                                    </div>
                                </nav>
                            </div>
                        </div> */}
                    </div>
                    <div className={"modal-body text-center p-0" + (model.isBrandedSite ? " bg-white" : "")}>
                        <div className={"popin-wrap w-100" + (model.isBrandedSite ? "" : " bg-gray-90")}>
                            <div className="shopping-center-selection position-relative" id="globalcountrySelection">
                                <section className={"country-selection-section py-4" + (model.isBrandedSite ? " odd" : " odd")}>
                                    <div className="wrapper container modalContainer">

                                        <div className="row m-0">
                                            {model.countryOrCenterList !== null && model.countryOrCenterList.length > 0 &&
                                                <div className="col-sm-4 p-lg-3 px-0 w-33 pb-0 ps-lg-0 pt-lg-0">
                                                    <ul className="list-group shopping-center-selection-list">
                                                        {model.countryOrCenterList.slice(0, model.column1).map((item, index) => {
                                                            if (item.link?.value?.url != null && item.link.value.url != "") {
                                                                return (
                                                                    <li key={index} className="list-group-item d-inline-block border-0">
                                                                        <a aria-label={item?.title?.value} data-testid="countryselectordatalayerclick" onClick={(e) => countrySelecterDataLayer(item.gaTitle.value)} href={item.link.value.url} className="d-block text-decoration-none shopping-list-item">{item.title.value}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {model.countryOrCenterList !== null && model.countryOrCenterList.length > 6 &&
                                                <div className="col-sm-4 p-lg-3 px-0 w-33 pt-lg-0 pb-0">
                                                    <ul className="list-group shopping-center-selection-list">
                                                        {model.countryOrCenterList.slice(model.column1, model.column1 + model.column2).map((item, index) => {
                                                            if (item.link?.value?.url != null && item.link.value.url != "") {
                                                                return (
                                                                    <li key={index} className="list-group-item d-inline-block border-0">
                                                                        <a aria-label={item?.title?.value} data-testid={"countryselectordatalayerclick" + index} onClick={(e) => countrySelecterDataLayer(item.gaTitle.value)} href={item.link.value.url} 
                                                                        className="d-block text-decoration-none shopping-list-item">{item.title.value}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {model.countryOrCenterList !== null && model.countryOrCenterList.length > 12 &&
                                                <div className=" col-sm-4 p-lg-3 px-0 w-33 pt-lg-0 pe-lg-0">
                                                    <ul className="list-group shopping-center-selection-list">
                                                        {model.countryOrCenterList.slice(model.column1 + model.column2).map((item, index) => {
                                                            if (item.link?.value?.url != null && item.link.value.url != "") {
                                                                return (
                                                                    <li key={index} className="list-group-item d-inline-block border-0">
                                                                        <a aria-label={item?.title?.value} data-testid={"countryselectordatalayerclick" + index} onClick={(e) => countrySelecterDataLayer(item.gaTitle.value)} href={item.link.value.url}
                                                                         className="d-block text-decoration-none shopping-list-item">{item.title.value}</a>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {model.goToLinkText !== "" && model.goToLinkText !== null &&
                                        <div className="d-flex container modalContainer mt-4">
                                            <a aria-label={model?.goToLinkText} className="d-flex mx-2 fw-bold go-to-westfield text text-decoration-underline" href="/">{model.goToLinkText}
                                            </a>
                                            <span className='picto icon-arrow-right-rw text-center fs-5'/>
                                        </div>
                                    }
                                </section>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return <></>;
    }
}
