import { RichText } from "@sitecore-jss/sitecore-jss-react";
import Enumerable from "linq";
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { baseModel } from "../../RouteHandler";


export default function RelatedNewsEvents({ fields }) {
  const [model, setModel] = useState();
  let workerCenter = useSelector((state) => state.user.workerCenter);
  useEffect(() => {
    if (fields !== null && fields !== undefined && fields !== "" && fields.relatedNewsEventsModel !== null
      && fields.relatedNewsEventsModel !== undefined && fields.relatedNewsEventsModel !== "" && fields.relatedNewsEventsModel.items !== null && fields.relatedNewsEventsModel.items?.length > 0) {

      let isWorker =
        workerCenter !== undefined &&
        workerCenter !== '' &&
        workerCenter.split(',').includes(baseModel.pC_Code);
      if (!isWorker) {
        let relatedEvents = Enumerable.from(fields.relatedNewsEventsModel.items).where(x => !x.isWorkerNews).toArray();
        fields.relatedNewsEventsModel.items = relatedEvents;
        setModel(fields.relatedNewsEventsModel)
      }
      else {
        setModel(fields.relatedNewsEventsModel) 
      }
    }
  }, []);
if (model !== null && model !== undefined && model !== "" && model.items !== null && model.items?.length > 0) {
  //var model = fields.relatedNewsEventsModel;
  return (
    <section className={"whats-hot-mosiac related-news-events " + (model.isNewBrandedsite ? "back-gray" : "four-block")}>
      <div
        className={"container " + (model.isNewBrandedsite ? "border-bottom" : "")}
      >
        {model.relatedEvents.trim() !== "" && model.items.length > 0 && (
          <h2
            className={"content-title " +(model.isNewBrandedsite ? "" : " underline text-center")}
          >
            {model.relatedEvents}
          </h2>
        )}
        <div className="row ">
          {model.items.slice(0,4).map((newsitem, index) => {
            var dateVal = { value: newsitem.date[0] };
            var dateMonthVal = { value: newsitem.dateMonth[0] };
            var descriptionVal = { value: newsitem.description[0] };
            return (
              <div key={index} className="col-12 col-md-4 col-lg-3 mb-3 ps-lg-0 pe-lg-3 analytics_relatedeventshow">
                <div
                  className="col grid-item grid-new-item grid-withpicture position-relative  bg-transparant shadow-none border-0"
                  data-date=""
                >
                  {newsitem.imgUrl[0].toString().trim() !== "" && (
                    <div className={"position-relative " + (newsitem.hasVideo ? " play-icon " : "") + " grid-picture m-0 p-0 rounded-3"}>
                      <img
                        className="img-fluid"
                        src={model.defaultImage}
                        data-alt={newsitem.imgUrl[0]}
                        alt={newsitem.displayName[0]}
                        title={newsitem.displayName[0]}
                      />
                    </div>
                  )}
                  <a
                    href={
                      model.siteVirtualPath + "/" + newsitem.newsDetailsUrl[0]
                    }
                    aria-label="news-detail"
                    className="position-absolute w-100 h-100 block-link"
                  ></a>
                  <div className="grid-content py-3 px-0 text-start">
                    {newsitem.retailerList !== "" && newsitem.retailerList !== null && newsitem.retailerList.trim() !== "" && (
                      <div className="retailer-name my-1">{newsitem.retailerList}</div>
                    )}
                    {newsitem.date.length > 0 &&
                      newsitem.date[0].toString().trim() !== "" ? (
                      <div className="date">
                        <RichText field={dateVal} />
                      </div>
                    ) : (
                      <>
                        {newsitem.dateMonth.length > 0 && (
                          <div className="date">
                            <RichText field={dateMonthVal} />
                          </div>
                        )}
                      </>
                    )}
                    {newsitem.displayName[0].toString().trim() !== "" && (
                      <div className="grid-new-title py-1">
                        {newsitem.displayName[0]}
                      </div>
                    )}
                    <div className="my-2">
                      <a
                        href={
                          model.siteVirtualPath +
                          "/" +
                          newsitem.newsDetailsUrl[0]
                        }
                        className=" grid-new-cta analytics_relatedeventclick"
                      >
                        {newsitem.externalUrlDescription}
                        <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
} else {
  return (
    <></>
  )
}
}
