import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

export default function GenericWidgets({ fields }) {
  if (fields !== null && fields !== undefined && fields !== "" && fields.genericWidget !== null && fields.genericWidget !== undefined &&
    fields.genericWidget !== "" && ((fields.genericWidget.script !== undefined && fields.genericWidget.script !== null &&
      fields.genericWidget.script.value !== "" && fields.genericWidget.script.value !== undefined && fields.genericWidget.script.value !== null &&
      fields.genericWidget.script.value !== "") || (fields.genericWidget.data.title !== null && fields.genericWidget.data.title !== undefined &&
        fields.genericWidget.data.title !== "" && fields.genericWidget.data.title?.value !== null && fields.genericWidget.data.title?.value !== undefined &&
        fields.genericWidget.data.title?.value !== ""))) {
    return (
      <>
        <section className="odd webstory-block">
          <div className="container">
            <div className="row webstory-block-wrapper aside">
              {fields.genericWidget.script.value !== null && fields.genericWidget.script.value !== undefined && fields.genericWidget.script.value !== "" &&
                <div className="webstory-left-block aside__item col">
                  <div className="webstory-left-block-image" id="widgetScript">
                    <RichText field={fields.genericWidget.script} />
                  </div>
                </div>
              }
              {fields.genericWidget.data !== null && fields.genericWidget.data !== undefined && fields.genericWidget.data !== "" &&
                <div className="webstory-right-block aside__item col">
                  <div className="webstory-right-block-title">{fields.genericWidget.data.title?.value} </div>
                  <div className="webstory-right-block-content"><Text field={fields.genericWidget.data.description} /></div>
                  {(fields.genericWidget.data.link !== null && fields.genericWidget.data.link !== undefined &&
                    fields.genericWidget.data.link !== "" && fields.genericWidget.data.link?.value?.url !== null &&
                    fields.genericWidget.data.link?.value?.url !== undefined && fields.genericWidget.data.link?.value?.url !== "" &&
                    fields.genericWidget.data.link?.value?.text !== null && fields.genericWidget.data.link?.value?.text !== undefined &&
                    fields.genericWidget.data.link?.value?.text !== "" &&
                    <div className="webstory-right-block-button">
                      <a href={fields.genericWidget.data.link?.value?.url} className="link-button text-uppercase btn-dark webstory-right-block-button-content">{fields.genericWidget.data.link?.value?.text}</a>
                    </div>
                  )
                  }
                </div>
              }
            </div>
          </div>
        </section>
      </>
    )
  }
  else {
    return (<></>)
  }
}
