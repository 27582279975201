import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler';
import { lazyLoadingImg } from '../Js-CommonFunctions.js';

export default function SearchNewStores({ model }) {
  useEffect(() => {
    lazyLoadingImg();
  }, []);
  if (model != null && model.new_Store_Items != null && model.new_Store_Items.length > 0) {
    return (
      <>
        <div className={"align-items-end d-flex justify-content-between search-title text-black" + (model.isNewBranded ? " " : "")}>
          <h2>{model.newStoreTitle}</h2>
        </div>
        <div className="categories row justify-content-start">
          {model.new_Store_Items.map((newstore, index) => {
            return (
              <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1 pb-0 px-2" key={index}>
                <a className={"block store-wrap d-flex justify-content-center pb-0 border-8 bg-white" + (baseModel.isEuropeanSite ?"":"us-uk")} href={newstore.url_Path}>
                  <div className="store-img d-flex justify-content-center">
                    {(newstore.image_Url === null || newstore.image_Url === "") ?
                      (
                        SearchNewStoresCon(newstore)
                      ) :
                      (
                        <img src={baseModel.defaultImage} data-alt={newstore.image_Url} alt={newstore.display_Name} />

                      )

                    }
                  </div>
                  </a>
                  <a className="d-flex store-name text-start gtmNewStoreNavigation" href={newstore.url_Path}>{newstore.display_Name}</a>              
              </div>
            )
          })}
        </div>
      </>

    )
  } else {
    return <></>
  }

}


function SearchNewStoresCon(newstore) {
  if (newstore.type === baseModel.typeStore) {
    return (
      <span className="picto icon-ico-ph-shops"></span>
    )
  }
  else if (newstore.type === baseModel.typeRestaurant) {
    return (
      <span className="picto icon-srv-restaurant"></span>

    )
  }
}

