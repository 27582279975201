import React from "react";
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';


export default function PromotionBanner({ fields }) {
  function handleButtonClick(e, url, opentab) {
    e.preventDefault();
    window.open(url, opentab);
  }

  if (fields !== null && fields !== undefined && fields !== "" && fields.promotionBanner !== null && fields.promotionBanner.promotionalBanner !== null) {
    const isMobileView = fields.promotionBanner.isMobileView;
    const promoBanner = fields.promotionBanner.promotionalBanner;
    const constant = fields.promotionBanner.constant;
    const blockPosition = promoBanner.text_Placement === "Left" ? "justify-content-start" : promoBanner.text_Placement === "Right" ? "justify-content-end" : "justify-content-center";
    const textPosition = promoBanner.text_Alignment === "Left" ? "align-items-start text-start" : promoBanner.text_Alignment === "Right" ? "align-items-end text-end" : "align-items-center text-center";
    const color = (promoBanner.text_Colour !== null && promoBanner.text_Colour != "") ? promoBanner.text_Colour + "" : "black";
    const bgcolor = promoBanner.background_Colour !== "" && (promoBanner.underneath === true || isMobileView === true) ? promoBanner.background_Colour : "#fff";
    const opentab = promoBanner.button !== null && promoBanner.button.value.target.length > 0 && promoBanner.button.value.target === "_blank" ? "_blank" : "_self";
    const linkurl = (promoBanner.link !== null && promoBanner.link.value !== null) ? promoBanner.link.value.url : "#";
    const openLink = promoBanner.link !== null && promoBanner.link.value.target.length > 0 && promoBanner.link.value.target === "_blank" ? "_blank" : "_self";
    const gtmClass = (promoBanner.link !== null && promoBanner.link.value !== null) ? promoBanner.link.value.gtmClass : "";
    const gtmActionEventBanner = (fields.promotionBanner.promotionalBanner.event_action_Link !== null) ? fields.promotionBanner.promotionalBanner.event_action_Link : "";

    const mystyle = {
      backgroundColor: bgcolor
    };


    return (
      (fields.promotionBanner.displayInFullWidth && promoBanner.jssBackground_Image != null && promoBanner.jssBackground_Image?.value?.src != null && promoBanner.jssBackground_Image?.value?.src != "") ?
        (
          <section className={"parking-banner p-0 " + fields.promotionBanner.displayComponentClass}>
            <div className="full-width">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-wrap">

                    {(linkurl != null && linkurl != "" && fields.promotionBanner.isPageEditor === false) &&

                      <a className={"stretched-link " + gtmClass + (fields.promotionBanner.setAspectRatio ? " new-banner-header" : "")} id="eve-banner-link" target={openLink} href={linkurl} style={{ position: "relative" }} >
                        {(isMobileView === true && promoBanner.jssMobile_Image !== null && promoBanner.jssMobile_Image?.value?.src != null && promoBanner.jssMobile_Image?.value?.src != "") ?
                          (
                            <Image field={fields.content["Mobile Image"]} className={"img-fluid w-100 " + (fields.promotionBanner.setAspectRatio ? "new-banner-header" : "")} />
                          ) :
                          (
                            <img alt={fields.content["Background Image"]?.value?.alt} width={fields.content["Background Image"]?.value?.width} height={fields.content["Background Image"]?.value?.height} className="w-100 h-auto img-fluid" src={fields.content["Background Image"]?.value?.src}></img>
                          )
                        }
                      </a>
                    }
                  </div>
                </div>

              </div>
            </div>
          </section>
        ) :
        (
          <>
            <section className={"promotional-banner bg-1  " + fields.promotionBanner.displayComponentClass}>
              <div className={"wrapper container"}>

                {(promoBanner.content !== null && promoBanner.content === "<iframe" && fields.promotionBanner.isPageEditor === false) ?
                  (<>
                    <RichText field={fields.content[constant.content]} />
                  </>) :
                  (

                    <div className={(promoBanner.dark_Overlay === true ? "dark-overlay-active " : "") + " carousel-card"}>
                      <div className={"promo-img position-relative"}>
                        {

                          (isMobileView === true && (promoBanner.jssMobile_Image !== null)) ?
                            (
                              <Image field={fields.content["Mobile Image"]}  />
                            ) :
                            (
                              <Image field={fields.content["Background Image"]}  />
                            )
                        }
                      </div>
                      {(promoBanner.underneath === false && isMobileView === false && fields.promotionBanner.isPageEditor === false) ?
                        (
                          <div className={"carousel-wrap  " + ((fields.promotionBanner.isPageEditor === false) ? blockPosition : "") + " px-0 pt-3"}>
                            <div className={"carousel-block " + textPosition}>
                              {promoBanner.title !== "" &&
                                <div className={"carousel-title"} style={{ color: `${color}` }}><Text field={fields.content["Title"]}></Text></div>

                              }
                              {
                                promoBanner.sub_Title !== "" &&
                                <div className={"carousel-description"} style={{ color: `${color}` }}>
                                  <Text field={fields.content["Sub-title"]} />
                                </div>

                              }
                              {(promoBanner.button !== null) &&
                                (promoBanner.button.value.text !== null && promoBanner.button.value.text != "" && promoBanner.button.value.url !== null && promoBanner.button.value.url !== "") &&
                                <button className="carousel-link" target={opentab} data-testid="promobanneronclck1" onClick={e => handleButtonClick(e, promoBanner.button.value.url, opentab)} style={{ color: `${color}`}}>{promoBanner.button.value.text}</button>
                              }
                            </div>
                          </div>
                        )
                        :
                        (
                          <div style={{ backgroundColor: `${bgcolor}` }} className={"carousel-wrap  " + ((fields.promotionBanner.isPageEditor === false ? blockPosition + " position-relative" : "")) + " pt-4 pb-3 py-lg-4 px-3 "}  >
                            <div className={"carousel-block " + textPosition + " py-lg-2"}>
                              {promoBanner.title !== "" &&
                                <div className={"carousel-title"} style={{ color: `${color}` }}><Text field={fields.content["Title"]}></Text></div>
                              }

                              {
                                promoBanner.sub_Title !== "" &&
                                <div className={"carousel-description"} style={{ color: `${color}` }}>
                                  <Text field={fields.content["Sub-title"]} />										</div>

                              }
                              {(promoBanner.button !== null) &&
                                (promoBanner.button.value.text !== null && promoBanner.button.value.text !== "" && promoBanner.button.value.url !== null && promoBanner.button.value.url !== "") &&
                                <button className="carousel-link" target={opentab} data-testid="promobanneronclck2" onClick={e => handleButtonClick(e, promoBanner.button.value.url, opentab)} style={{ color: `${color}`, border: "solid" }}>{promoBanner.button.value.text}</button>
                              }
                            </div>
                          </div>
                        )

                      }
                      {(linkurl != null && linkurl != "" && fields.promotionBanner.isPageEditor === false) &&
                        <a className={"stretched-link " + gtmClass} id="eve-banner-link" target={openLink} href={linkurl} ></a>
                      }

                    </div>
                  )}

              </div>
            </section>
          </>
        )
    )
  } else {
    return <></>;
  }
}




