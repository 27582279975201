import Enumerable from "linq";
import React, { useEffect, useState } from 'react';
import { URWindowLocation, convertUTCTimeToClientTimeForBookingEvents } from "../Js-CommonFunctions";
import { GoBackToMenu } from "../MyAccount/MyAccountScripts";


export default function MyBookings({ fields }) {
  const [upcomingBookingList, setUpcomingBookingList] = useState([]);
  const [pastBookingList, setPastBookingList] = useState([]);
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [hidePastEvents, setHidePastEvents] = useState(false);

  useEffect(() => {
    var localeDateTime = new Date();
    if (fields.bookingInfo !== null && fields.bookingInfo !== "" && fields.bookingInfo !== undefined && fields.bookingInfo.length > 0) {
      var activeData = Enumerable.from(fields.bookingInfo).orderBy(x => new Date(x.startsAt).getTime()).where(x => (new Date(x.startsAt).getTime() <= localeDateTime.getTime() && new Date(x.endsAt).getTime() >= localeDateTime.getTime())).toArray(); //Active Events
      var upcomingData = Enumerable.from(fields.bookingInfo).orderBy(x => new Date(x.startsAt).getTime()).where(x => (new Date(x.startsAt).getTime() > localeDateTime.getTime())).toArray(); //Upcoming Events
      var finalPushedData = activeData.push(...upcomingData);
      setUpcomingBookingList(activeData);
      //pastBookings
      var pastList = Enumerable.from(fields.bookingInfo).orderByDescending(x => new Date(x.endsAt).getTime()).where(x => (new Date(x.endsAt).getTime() <= localeDateTime.getTime())).toArray();
      setPastBookingList(pastList);
      if (pastList !== null && pastList.length > 0)
        setShowPastEvents(true);

    }
  }, []);

  function showPastEventsClick() {
    setShowPastEvents(false);
    setHidePastEvents(true);
  }
  function hidePastEventClick() {
    if (document.getElementById("showpasteventsfocus")) {
      var heightHeader = document.getElementById("headerBlock") !== null && document.getElementById("MainHeader") !== null ?
        document.getElementById("headerBlock").offsetHeight +
        document.getElementById("MainHeader").offsetHeight : 0;
      var rect = document.querySelector("#showpasteventsfocus").getBoundingClientRect();
      window.scrollTo({ top: rect.top - heightHeader, behavior: "instant" });
    }
    setShowPastEvents(true);
    setHidePastEvents(false);
  }
  if (fields !== null && fields !== undefined && fields !== "") {
    let pstBookingsUniqueVal = [];
    let pastBookingsYear = [];
    let upcomingBookingsYear = [];
    let upcomingBookingsUniqueVal = [];
    let currentYear = new Date().getFullYear();
    return (
      (pastBookingList !== null && pastBookingList.length > 0) || (upcomingBookingList !== null && upcomingBookingList.length > 0) ?
        (
          <>
            <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
              <div className="container modalContainer p-0 ">
                <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                  <div className="col-2 text-center px-0">
                    <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                      onClick={() => { GoBackToMenu("my-bookings") }} href="javascript:void(0);"></a>
                  </div>
                  <div className="col-8 p-0 text-center">
                    <div className=" text-center benefits-subtitle ">{fields.myBookingInfoTitle}</div>
                  </div>
                  <div className="col-2 text-center px-0">
                  </div>
                </nav>
              </div>
            </div>
            <div className="user-card">
              <div className="evenements-card-title d-none d-lg-block pb-0 mb-4">{fields.myBookingInfoTitle}</div>
              <div className="d-block text-start" id="showpasteventsfocus">
                {showPastEvents &&
                  <button type="button" className="btn evenements-button mb-4 border-8" onClick={(e) => { showPastEventsClick() }} >{fields.showPastBookings} <i className="ms-1 picto icon-arrow-square-up-rw"></i></button>
                }
                {hidePastEvents && pastBookingList.length > 0 && pastBookingList.map((item, index) => {
                  let hasSameMonthYear = true;
                  let displayYear = false; //currentYear.toString() !== year ? year : "";

                  const { year, month, date } = convertUTCTimeToClientTimeForBookingEvents(item.startsAt, false, false);
                  let monthYear = year + "," + month;
                  if (!pstBookingsUniqueVal.includes(monthYear)) {
                    hasSameMonthYear = false;
                    pstBookingsUniqueVal.push(monthYear);
                  }
                  if (!pastBookingsYear.includes(year) && pastBookingList.length > 1 && currentYear.toString() !== year) {
                    displayYear = true;
                    pastBookingsYear.push(year);
                  }
                  return (
                    <div key={index} className=" mb-3">
                      {!hasSameMonthYear &&
                        <div className="row evenements-date-container mb-4 d-flex justify-content-between align-items-center">
                          <div className="col-1 evenements-month-text evenements-month-year mx-3 text-center">{displayYear ? year : ""}</div>
                          <div className="col-10 evenements-month-text text-uppercase">{month}</div>
                        </div>
                      }
                      <div className="row evenements-date-container mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-1 evenements-dates border-8 mx-lg-2">
                          <div className="d-flex flex-column align-items-center px-2 w-100 pt-2">
                            <div className="evenements-datefield">{date}</div>
                            <div className="evenements-monthfield pb-2">{month}</div>
                            <div className="evenements-yearfield">{year}</div>
                          </div>
                        </div>
                        <div className="col-10 evenements-description border-8" >
                          <div className="d-flex flex-column">
                            <div className="evenements-description-bold">{item.fullDescribe}</div>
                            <div className="evenements-description-sub-bold">{item.serviceName}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                })}
                {hidePastEvents &&
                  <button type="button" className="btn evenements-button mb-4 border-8" onClick={(e) => { hidePastEventClick() }}>{fields.hidePastBookings}
                    <i className="ms-1 picto icon-arrow-square-up-rw"></i></button>
                }

                {pastBookingList.length > 0 && upcomingBookingList.length > 0 &&
                  <div className="full-red-line mb-4"></div>
                }
                {upcomingBookingList !== null && upcomingBookingList.length > 0 && upcomingBookingList.map((item, index) => {
                  var startAtTime = new Date(item.startsAt);
                  var finishAtTime = item.endsAt !== undefined && item.endsAt !== null && item.endsAt !== "" ? new Date(item.endsAt) : "";
                  var localeDateTime = new Date();
                  let hasSameMonthYear = true;
                  let displayYear = false; //currentYear.toString() !== year ? year : "";
                  const { year, month, date } = convertUTCTimeToClientTimeForBookingEvents(item.startsAt, false, false);
                  let monthYear = year + "," + month;
                  if (!upcomingBookingsUniqueVal.includes(monthYear)) {
                    hasSameMonthYear = false;
                    upcomingBookingsUniqueVal.push(monthYear);
                  }
                  if (!upcomingBookingsYear.includes(year) && upcomingBookingList.length > 1 && currentYear.toString() !== year) {
                    displayYear = true;
                    upcomingBookingsYear.push(year);
                  }

                  // if ((finishAtTime !== "" && finishAtTime.getTime() >= localeDateTime.getTime())) {
                  return (
                    <>
                      {!hasSameMonthYear &&
                        <div key={index} className="row evenements-date-container mb-4 d-flex justify-content-between align-items-center">
                          <div className="col-1 evenements-month-text evenements-month-year mx-3 text-center">{displayYear ? year : ""}</div>
                          <div className="col-10 evenements-month-text text-uppercase">{month}</div>
                        </div>
                      }
                      <div className="row evenements-date-container mb-4 d-flex justify-content-between align-items-center">
                        <div className={"col-1 evenements-dates border-8  mx-lg-2 " + (index === 0 ? "active" : "")}>
                          <div className="d-flex flex-column align-items-center px-2 w-100 pt-2">
                            <div className="evenements-datefield">{date}</div>
                            <div className="evenements-monthfield pb-2">{month}</div>
                            <div className="evenements-yearfield">{year}</div>
                          </div>
                        </div>
                        <div className="col-10 evenements-description border-8 cursor-pointer">
                          <div className="d-flex flex-column " onClick={(e) => URWindowLocation(item.bookingUrl)}>
                            <div className="evenements-description-bold">{item.fullDescribe}</div>
                            <div className="evenements-description-sub-bold">{item.serviceName}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  //}
                })}
              </div>
            </div>
          </>
        ) :
        (
          <>
            <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
              <div className="container modalContainer p-0 ">
                <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                  <div className="col-2 text-center px-0">
                    <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                      onClick={() => { GoBackToMenu("my-bookings") }} href="javascript:void(0);"></a>
                  </div>
                  <div className="col-8 p-0 text-center">
                    <div className=" text-center benefits-subtitle ">{fields.myBookingInfoTitle}</div>
                  </div>
                  <div className="col-2 text-center px-0">
                  </div>
                </nav>
              </div>
            </div>
            <div className="user-card">
              <div className="evenements-card-title d-none d-lg-block pb-0 mb-4">{fields.myBookingInfoTitle}</div>
              <div className="events-empty d-flex flex-start my-3 px-2">{fields.emptyBookingsText}</div>
            </div>
          </>
        )
    )
  }
  else {
    return <></>;
  }
}
