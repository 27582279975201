import loadable from "@loadable/component";
import React, { useRef } from "react";
import { fnGtmVideo, getVideoTypeFromvideoUrl } from "../Js-CommonFunctions";
const ReactPlayer = loadable(() => import('react-player'), { ssr: false });


export default function ReactPlayerCarousel({ fields, carouselref, windowinnerWidth }) {

  const videoref = useRef(null);
  var item = fields;
  var pauseSlick = () => {
    carouselref.current.slickPause();

  };

  var playSlick = () => {
    carouselref.current.slickPlay();
  };

  return (
    <ReactPlayer
      ref={videoref}
      muted={false}
      config={{
        youtube: {
          playerVars: {
            playsinline: 1,
            rel: 0,

            loop: 0,
          },
        },

        vimeo: { playerOptions: { playsinline: 1 } },
      }}
      playsinline={true}
      className="react-player"
      url={item?.videoLink?.value?.url}
      width="100%"
      height="393px"
      controls={true}
      playing={true}
      loop={false}
      light={
        windowinnerWidth < 991 &&
          item.jssMobile_Image != null &&
          item.jssMobile_Image.value != null &&
          item.jssMobile_Image.value.src != ""
          ? item.jssMobile_Image.value.src
          : item.jssBackground_Image.value.src
      }
      pip={false}
      playIcon={false}
      onPlay={pauseSlick}
      onStart={() => [
        (fnGtmVideo('video_start', 'Home Center', item.videoLink.value.url, item.title?.value, videoref.current.getDuration(), videoref.current.getCurrentTime())),
      ]}
      onProgress={(x) => [
        (gtmVideoOnProgress(item, x.played, videoref.current.getDuration(), videoref.current.getCurrentTime()))
      ]}
      onPause={playSlick}
      onEnded={() => [
        (carouselref.current.slickNext(), fnGtmVideo('video_completion', 'Home Center', item.videoLink.value.url, item.title?.value, videoref.current.getDuration(), videoref.current.getCurrentTime())),
      ]}
    />
  )
}

var pushedValue = 0;

function gtmVideoOnProgress(item, played, videoDuration, videoCurrentTime) {
  var duration = Math.round((played + Number.EPSILON) * 100) / 100;

  var approximateDuration = (0.20 <= duration && duration <= 0.30) ? "0.25" : (
    (0.45 < duration && duration <= 0.55) ? "0.5" : (
      (0.70 <= duration && duration <= 0.80) ? "0.75" : (duration)
    ));

  if (approximateDuration == "0.5" || approximateDuration == "0.25" || approximateDuration == "0.75") {
    var videoProvider = getVideoTypeFromvideoUrl("", item.videoLink.value.url);
    if (videoCurrentTime === null)
      videoCurrentTime = 0;
    if (pushedValue !== approximateDuration) {
      pushedValue = approximateDuration;
      if (window !== undefined && window !== null && window.dataLayer !== undefined && window !== null) {
        window.dataLayer.push({
          event: "video_progress",
          video_current_time: Math.round(videoCurrentTime),
          video_duration: Math.round(videoDuration),
          video_percent: (approximateDuration * 100),
          video_provider: (videoProvider !== null && videoProvider !== "") ? videoProvider : undefined,
          video_title: (item.title?.value !== null && item.title?.value !== "") ? item.title?.value : undefined,
          video_url: (item.videoLink.value.url !== null && item.videoLink.value.url !== "") ? item.videoLink.value.url : undefined,
          visible: true
        });
      }
    }
  }
}
