import React, {  useState } from "react";
import {  RichText } from '@sitecore-jss/sitecore-jss-react';
import { replaceQueryString } from '../Js-CommonFunctions';
import axios from "axios";
import { baseModel } from "../../RouteHandler";



export default function MultiNewsWithImagesInnerPage({ newsImagedata }) {
  const [model, setModel] = useState(newsImagedata);
  let isFromSpliceCon = false;

  function fnViewMoreClick(dynamicId) {
    let genericUrl =
      "/api/sitecore/GenericApi/GetMultiNewsWithImageFilter?ursite=" + baseModel.urContextSite + "&urlang=" + baseModel.contextLang + "&itemId=" + model.itemSource + "&pageNo=" + "1" + "&uniqueid=" + model.dynamicid;

    var viewcount = parseInt(document.getElementById('offernewspageno' + model.dynamicid).getAttribute('value')) + 1;
    let url = replaceQueryString(genericUrl, 'pageNo', viewcount);
    axios({
      method: 'get',
      url: url,
      async: false
    }).then(function (response) {
      setModel(response.data)
    })
      .catch(function (error) {

        console.log(error);
      });

    return false;

  }

  let picright = false;
  let withPicsCount = 0;
  let indexcnt = 0;
  let textright = false;
  let withoutPicsCount;
  let rowtwocnt;
  let contentTextClass;
  let contentBgClass;
  let contentLinkClass;
  let isNewsPage = model.isNewsPage;
  const urlPrefix = model.urlPrefix;
  var title;

  if (model.genericPageTitle != "" && model.genericPageTitle !== null && (model.genericPageTitle.type !== null && model.genericPageTitle.type !== "")) {
    title = { value: model.genericPageTitle.type }
  }

  return (
    <section className={"whats-hot" + (model.isNewBrandedsite ? " back-gray p-2" : "")} id={"news" + (model.dynamicid)} >
      {(!model.isNewBrandedsite && (model.genericPageTitle != null && model.genericPageTitle != "" && (model.genericPageTitle.type !== null && model.genericPageTitle.type !== ""))) &&
        <h2 className="content-title text-uppercase text-center underline"><RichText field={model.genericPageTitle.type} /></h2>
      }

      <div className="container border-bottom">
        {(model.genericPageTitle != null && model.genericPageTitle != "" && model.genericPageTitle.type !== null && model.genericPageTitle.type !== "") &&
          <h2 className="page-title text-uppercase"><RichText field={title} /></h2>
        }
        <div className="row">
          {(model.multiINewsWithImages !== null && model.multiINewsWithImages.length > 0) &&

            model.multiINewsWithImages.map((item, index) => {
              indexcnt++;
              if (item.imgurl) {
                const picClass = !picright ? "right" : "left";
                contentTextClass = !picright ? "left" : "right";
                contentBgClass = !picright ? "bg-lighter" : "bg-main";
                const contentArrowClass = !picright ? " arrow-right" : "arrow-left";
                picright = !picright;
                withPicsCount++;
                return (

                  <div key={index} className="col-sm-12  item  picture-and-text">
                    <div className="clearfix">
                      <div className={"whats-hot-item position-relative whats-hot-picture " + picClass + (model.isNewBrandedsite ? " bg-lighter" : " bg-light")}>

                        {(item.multiINewsWithImages.image !== null && item.multiINewsWithImages.image.value !== null && item.multiINewsWithImages.image.value !== "") &&
                          <img src={item.multiINewsWithImages.image.value.src} alt={item.multiINewsWithImages.image.value.alt} />
                        }
                      </div>
                      <div className={"whats-hot-item position-relative whats-hot-text " + contentTextClass + " " + (model.isNewBrandedsite ? "bg-lighter " : contentBgClass + " text-light " + contentArrowClass)}>
                        <div className={"whats-hot-content " + (model.isNewBrandedsite ? " card-new" : "")} >
                          <div className="vertical-align">
                            <div className="no-picto"></div>
                            {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&

                              <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                            }
                            <div className="description-news text-midgray"><RichText field={item.multiINewsWithImages.description} /></div>
                            {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                              <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} target={item.multiINewsWithImages.discover.value.target} className={(model.isNewBrandedsite ? "btn-link-news" : "link-button btn-white analytics_newsOffersClk") + " analytics_newsOffersClk"} >
                                {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                              </a>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })
          }

          {/*                         Withoutpics Loop
 */}
          {(model.multiINewsWithImages !== null) &&
            withOutPicsloop(model, withPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, isFromSpliceCon)
          }
          <div className="col-md-12">
            <div className="mt-3 mb-3 text-center view-more" id={"viewmoreid" + model.dynamicid} data-testid="fnViewMoreClick" onClick={(e) => fnViewMoreClick(model.dynamicId)}>
              <input type="hidden" id={"offernewspageno" + model.dynamicid} value={model.pagecount} />
              {(model.isViewMoreRequired) &&
                <div>{model.viewMore}</div>
              }
            </div>
          </div>


        </div>
      </div>
        </section >

  )

}


let rowtwoflag = false;

function withOutPicsloop(fields, withPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, isFromSpliceCon) {

  let withoutPicsCount = fields.multiINewsWithImages.length - withPicsCount;
  let rowtwocnt = withPicsCount % 4;
  let textright = false;
  return (
    <>
      {fnrowtwoflagcn(rowtwocnt, withoutPicsCount)}


      {fntestloop(fields, withoutPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, textright, withPicsCount, isFromSpliceCon)}

    </>

  )

}

function fnrowtwoflagcn(rowtwocnt, withoutPicsCount) {
  if (rowtwocnt <= 2 && withoutPicsCount === 2) {
    return (
      rowtwoflag = true
    )
  }
}

let itemCountValue = -1;
let isRemoveItem = false;


function fntestloop(fields, withoutPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, textright, withPicsCount, isFromSpliceCon) {
  let indexcnt = 0;
  if ((withoutPicsCount === 1) || (withoutPicsCount === 2 && rowtwoflag)) {
    return (
      ((withoutPicsCount == 1) || (withoutPicsCount == 2)) && !((withPicsCount == 3) && withoutPicsCount == 2) ?
        (
          (fields.multiINewsWithImages.map((item, index) => {
            indexcnt++;
            if (!item.imgurl) {
              return (
                <div key={index} className="col-sm-12  item  picture-and-text">
                  <div className="clearfix">
                    <div className="whats-hot-item whats-hot-text bg-dark text-light no-picture">
                      <div className="whats-hot-content">
                        <div className="vertical-align">
                          {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&

                            <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                          }
                          <div className={"description" + (fields.isNewBrandedsite ? "-news text-light" : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                          {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                            <a href={item.multiINewsWithImages.discover.value.url} className={"link-button btn-transparent"} target={item.multiINewsWithImages.discover.value.target} >
                              {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                            </a>

                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              )
            }
          }))
        ) :
        (
          (withoutPicsCount == 1) || (withoutPicsCount == 2) ?
            (
              (fields.multiINewsWithImages.map((item, index) => {
                indexcnt++;

                if (!item.imgurl) {
                  contentTextClass = textright ? "text-dark" : "text-light";
                  contentBgClass = textright ? "bg-light" : "bg-main";
                  contentLinkClass = textright ? " link-dark" : ""
                  textright = !textright;
                  let marginClass = textright ? "no-margin-left" : "no-margin-right";

                  return (
                    <div key={index} className={"col-sm-6 " + marginClass + " item"}>
                      <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " " + contentLinkClass}>
                        <div className="whats-hot-content">
                          <div className="vertical-align">
                            {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&
                              <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /> </h3>
                            }
                            <div className={"description" + (fields.isNewBrandedsite ? "-news " + contentTextClass : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                            {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                              <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={"link-button btn-transparent"} target={item.multiINewsWithImages.discover.value.target}>
                                {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                              </a>

                            }
                          </div>
                        </div>
                      </div>
                    </div>)
                }
              }))
            ) :
            (<></>)
        )
    )
  }

  else if (((withoutPicsCount % 2) == 0) || ((withoutPicsCount % 2) == 1)) {
    textright = false;
    let itemcnt;
    itemcnt = 0;
    let indexcnt = 0;
    let itemCount = 0;
    let withoutPicsClass = "";
    isRemoveItem = false;
    return (
      <>
        {spilcecond(fields, itemcnt, withoutPicsCount, isFromSpliceCon, itemCount)}

        {(fields.multiINewsWithImages.length > 0) &&
          (fields.multiINewsWithImages.map((item, index) => {
            indexcnt++;
            contentTextClass = textright ? "text-dark" : "text-light";
            contentBgClass = textright ? "bg-light" : "bg-main";
            contentLinkClass = textright ? " link-dark" : "";
            textright = !textright;
            if (isRemoveItem) {
              withoutPicsClass = itemCountValue === index ? "d-none " : " d-block";
            }
            return (
              (!item.imgurl) &&

              <div key={index} className={"col-sm-6  no-margin-left  item " + withoutPicsClass}>
                <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " " + contentLinkClass}>
                  <div className="whats-hot-content">
                    <div className="vertical-align">
                      {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&
                        <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                      }
                      <div className={"description" + (fields.isNewBrandedsite ? "-news " + contentTextClass : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                      {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                        <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={"link-button btn-transparent"} target={item.multiINewsWithImages.discover.value.target}>
                          {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                        </a>

                      }
                    </div>
                  </div>
                </div>
              </div>


            )

          })
          )

        }

      </>
    )

  }



  function spilcecond(fields, itemcnt, withoutPicsCount, isFromSpliceCon, itemCount) {
    let breakCondition = false;
    let indexcnt = 0;

    if ((withoutPicsCount % 2) == 1) {
      return (

        fields.multiINewsWithImages.map((item, index) => {
          indexcnt++;
          itemcnt++;
          if (!item.imgurl && !breakCondition) {
            breakCondition = true;
            isRemoveItem = true;
            itemCountValue = indexcnt - 1;
            return (
              <div key={index} className="col-sm-12  item  picture-and-text">
                <div className="clearfix">
                  <div className="whats-hot-item whats-hot-text   bg-dark  text-light no-picture ">
                    <div className="whats-hot-content">
                      <div className="vertical-align">
                        {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&

                          <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                        }
                        <div className={"description" + (fields.isNewBrandedsite ? "-news text-light" : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                        {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                          <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={"link-button btn-transparent"} target={item.multiINewsWithImages.discover.value.target}>
                            {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        })


      )




    }

  }

}