import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { articleConstant } from "../SitecoreFieldConstants.js";

export default function ArticleIntroduction({ fields }) {

  if (fields.currentArticleItem !== null && fields.currentArticleItem !== undefined &&
    fields.currentArticleItem.item !== null && fields.currentArticleItem.item !== undefined &&
    fields.currentArticleItem.item.title.trim() !== ""
  ) {
    let title = { value: "" };

    if (!fields.currentArticleItem.isExperienceEditor) {
      title.value = fields.currentArticleItem.item.title;
    }

    if (
      fields.currentArticleItem !== null && fields.currentArticleItem !== undefined &&
      fields.currentArticleItem.item !== null && fields.currentArticleItem.item !== undefined &&
      fields.currentArticleItem.item.title !== ""
    ) {
      return (
        <div>
          <div className="anchor-navigation">
            {/* {fields.currentArticleItem.isNewBrandedSite ? ( */}
            <nav className="container">
              {fields.currentArticleItem.isExperienceEditor ? (
                <h1 className="page-title text-capitalize text-start mb-0">
                  {" "}
                  <RichText field={fields.content[articleConstant.title]} />
                </h1>
              ) : (
                <h1 className="page-title text-capitalize text-start mb-0">
                  <RichText field={title} />
                </h1>
              )}
            </nav>
            {/* ) : (
              <nav className="container navbar">
                {fields.currentArticleItem.isExperienceEditor ? (
                  <h1 className="page-title text-uppercase">
                    <RichText field={fields.content[articleConstant.title]} />
                  </h1>
                ) : (
                  <h1 className="page-title text-uppercase">
                    <RichText field={title} />
                  </h1>
                )}
              </nav>
            )} */}
          </div>
          {imageTag(fields)}
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}

function imageTag(fields) {
  if (fields.currentArticleItem !== null && fields.currentArticleItem.item !== null
    && fields.currentArticleItem.item.introduction_Text.trim() !== "") {
    let introtext = { value: "" };
    if (!fields.currentArticleItem.isExperienceEditor) {
      introtext.value = fields.currentArticleItem.item.introduction_Text;
    }

    if ((fields.currentArticleItem.item.desktop_Image != null &&
      fields.currentArticleItem.item.desktop_Image?.value?.src !== null &&
      !fields.currentArticleItem.isMobileView) ||
      (fields.currentArticleItem.item.mobile_Image != null &&
        fields.currentArticleItem.item.mobile_Image?.value?.src !== null &&
        fields.currentArticleItem.isMobileView) ||
      fields.currentArticleItem.item.introduction_Text !== "") {
      return (
        <article className="article-detail article-intro text-center">
          <div className="container">
            <div className="row">
              {fields.currentArticleItem.item.introduction_Text !== "" && (
                <div className="col-lg-5 col-sm-12 order-lg-0 order-md-0 order-sm-1 order-1">
                  <div
                    className="article-description ms-0 mb-4">
                    {fields.currentArticleItem.isExperienceEditor ? (
                      <RichText field={fields.content[articleConstant.introtext]} />
                    ) : (
                      <RichText field={introtext} />
                    )}
                  </div>
                </div>
              )}
              {((fields.currentArticleItem.item.desktop_Image !== null &&
                fields.currentArticleItem.item.desktop_Image?.value?.src !== null &&
                !fields.currentArticleItem.isMobileView) ||
                (fields.currentArticleItem.item.mobile_Image !== null &&
                  fields.currentArticleItem.item.mobile_Image?.value?.src !== null &&
                  fields.currentArticleItem.isMobileView)) && (
                  <div className="col-lg-7 col-sm-12 order-lg-1 order-md-1 order-sm-0 order-0">
                    <div className="img-wrap">
                      {fields.currentArticleItem.item.mobile_Image !== null &&
                        fields.currentArticleItem.item.mobile_Image?.value?.src !== null &&
                        fields.currentArticleItem.isMobileView ? (
                        <Image
                          field={fields.currentArticleItem.item.mobile_Image}
                          imageParams={{ mh: 300 }}
                          alt={
                            fields.currentArticleItem.item.mobile_Image?.value?.alt
                          }
                          title={
                            fields.currentArticleItem.item.mobile_Image?.value?.alt
                          }
                          className="d-md-none boder-8 img-fluid"
                        />
                      ) : fields.currentArticleItem.item.desktop_Image != null &&
                        fields.currentArticleItem.item.desktop_Image?.value?.src !=
                        null &&
                        !fields.currentArticleItem.isMobileView ? (
                        <Image
                          className="d-none d-sm-none d-md-block p-0 boder-8 img-fluid"
                          field={fields.currentArticleItem.item.desktop_Image}
                          imageParams={{ mh: 300 }}
                          alt={
                            fields.currentArticleItem.item.desktop_Image?.value?.alt
                          }
                          title={
                            fields.currentArticleItem.item.desktop_Image?.value?.alt
                          }
                        />

                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </article>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
