import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';


export default function PlaceHolderDivider({ fields }) {
    if (fields.placeHolderData != null && fields.fromContentResolver !== null && fields.placeHolderData.rightImage?.value !== "" && fields.placeHolderData.rightImage?.value !== null && fields.placeHolderData.leftImage?.value !== "" && fields.placeHolderData.rightImage?.value !== null) {
        return (
            <section className="whats-hot">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12  item  picture-and-text">
                            <div className="clearfix">
                                {(fields.placeHolderData.isExperienceEditor) ?
                                    (
                                        <div className="whats-hot-item whats-hot-picture  right  bg-lighter ">
                                            <Image field={fields.placeHolderData.rightImage} />
                                        </div>

                                    ) :
                                    (
                                        <div className="whats-hot-item whats-hot-picture  right  bg-lighter ">
                                            <img src={fields.placeHolderData.defaultImage} data-alt={fields.placeHolderData.altRightImage} alt={fields.placeHolderData.rightImage?.value?.alt} />
                                        </div>
                                    )
                                }
                                <div className="whats-hot-item whats-hot-text arrow-right  left  bg-lighter  text-dark  link-dark ">
                                    <div className="whats-hot-content">
                                        <div className="vertical-align">
                                            <div className="no-picto"></div>
                                            {(fields.placeHolderData.isExperienceEditor) ?
                                                (
                                                    <div className="title-img">
                                                        <Image field={fields.placeHolderData.leftImage} />
                                                    </div>

                                                ) :
                                                (
                                                    <div className="title-img">
                                                        <img src={fields.placeHolderData.defaultImage} data-alt={fields.placeHolderData.altLeftImage} alt={fields.placeHolderData.leftImage?.value?.alt} />
                                                    </div>
                                                )
                                            }
                                            <> <div className="description test">
                                                {(fields.fromContentResolver[fields.constant.description] !== null && fields.fromContentResolver[fields.constant.description].value !== "" && fields.fromContentResolver[fields.constant.description].value !== null) &&
                                                    <RichText field={fields.fromContentResolver[fields.constant.description]} />
                                                }

                                            </div>
                                                <a href={fields.placeHolderData.discoverLink.value?.url} aria-label={fields?.placeHolderData?.discoverLink?.value} target={fields.placeHolderData.discoverLink.value?.target} className={"link-button btn-transparent " + fields.placeHolderData.gtmClickNavigation}><RichText field={fields.placeHolderData.discoverLinkLabel} /></a>

                                                <div className="annex text-uppercase">
                                                    {(fields.fromContentResolver[fields.constant.bottomText] !== null && fields.fromContentResolver[fields.constant.bottomText].value !== "" && fields.fromContentResolver[fields.constant.bottomText].value !== null) &&
                                                        <RichText field={fields.fromContentResolver[fields.constant.bottomText]} />
                                                    }
                                                </div>
                                            </>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        return <div></div>;
    }

}

