import { defaultImage } from '../RouteHandler.js';

export function RetailerSearch() {
  if (document.getElementById('RetailerSearchText')) {
    document.getElementById('RetailerSearchText').addEventListener('keyup', function (e) {
      document.querySelectorAll('.retailerblock').forEach((x) => {
        x.setAttribute('style', 'display:block');
        var img = x.querySelector('.img-fluid');
        if (
          img != null &&
          img.getAttribute('data-alt') != undefined &&
          img.getAttribute('data-alt') != ''
        ) {
          img.setAttribute('src', img.getAttribute('data-alt'));
        }

        if (
          !(
            x
              .querySelector('.retail-title')
              .textContent.toLowerCase()
              .indexOf(e.target.value.toLowerCase()) > -1
          )
        ) {
          x.setAttribute('style', 'display:none');
        } else {
          x.setAttribute('style', 'display:block');
        }
      });
    });
  }
}

export function isElementPresentInViewport(element, options = {}) {
  const rect = element.getBoundingClientRect();
  const height = window.innerHeight || document.documentElement.clientHeight;
  const width = window.innerWidth || document.documentElement.clientWidth;
  const offset = { left: 0, right: 0, top: 0, bottom: 0, ...options };

  // At least 1 corner is in viewport
  return (
    rect.right >= -offset.left &&
    rect.bottom >= -offset.top &&
    rect.left <= width + offset.right &&
    rect.top <= height + offset.bottom
  );
}

function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = typeof el === 'string' ? document.querySelector(el) : el;
  if (el == null) {
    return;
  } else {
    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
    return Math.ceil(el.offsetHeight + margin);
  }
}

export function LazyLoadingImg() {
  if (defaultImage !== undefined && defaultImage != '') {
    document.querySelectorAll("img[src='" + defaultImage + "']").forEach((x) => {
      var isInViewport;
      if (x.id !== '' && x.id !== null && x.id.includes('mallGalleryLazyLoadImg')) {
        //  isInViewport = x.offsetTop < (window.scrollY + window.innerHeight + 500);
        isInViewport = false;
      } else {
        isInViewport = isElementPresentInViewport(x);
      }

      if (
        x.getAttribute('data-alt') != undefined &&
        x.getAttribute('data-alt') != '' &&
        isInViewport
      ) {
        x.setAttribute('src', x.getAttribute('data-alt'));
      }
    });
  }
}

function getElementOffsetTopValue(ele) {
  var rect = document.querySelector(ele).getBoundingClientRect();

  var offset = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
  return offset.top;
}

export function CategoryScrollTop() {
  var scrollTop = window.scrollY;
  var height = window.innerHeight ? window.innerHeight : window.innerHeight;
  if (document.getElementById('navbarHeader') !== null)
    var headerBlockheight = getAbsoluteHeight('#navbarHeader'); //document.getElementById('navbarHeader').offsetHeight;
  if (document.getElementById('categoryfilter') !== null)
    var categoryFilterheight = getAbsoluteHeight('#categoryfilter'); //document.getElementById('categoryfilter').offsetHeight;  outerHeight(true);
  document.querySelectorAll('#categoryfilter').forEach((item) => {
    var topDistance =
      getElementOffsetTopValue('#categoryfilter') - getAbsoluteHeight('#navbarHeader'); //document.getElementById('navbarHeader').offsetHeight;
    var topDistanceDown =
      getElementOffsetTopValue('.filter-wrap') - getAbsoluteHeight('#navbarHeader'); //document.getElementById('navbarHeader').offsetHeight;
    if (topDistance < scrollTop) {
      document
        .getElementById('categoryfilter')
        .setAttribute('style', 'top:' + headerBlockheight + 'px');
      document.querySelectorAll('.filter-category-wrap').forEach((item) => {
        item.classList.add('sticky');
      });
      document.getElementById('filter-category-list').style.maxHeight =
        height - (headerBlockheight + categoryFilterheight) + 'px';
    }

    if (topDistanceDown >= scrollTop) {
      document.getElementById('categoryfilter').removeAttribute('style');
      document.querySelectorAll('.filter-category-wrap').forEach((item) => {
        item.classList.remove('sticky');
      });
      document.getElementById('filter-category-list').style.maxHeight = ''; //('max-height', '');
    }
  });
}

export function CategoryClickMobile() {
  if (window.innerWidth < 991) {
    document.querySelectorAll('#categoryfilter').forEach((x) => {
      x.addEventListener('click', function (e) {
        if (
          document.getElementsByClassName('goup')[0] != undefined &&
          document.getElementsByClassName('goup')[0] != null
        ) {
          document.getElementsByClassName('goup')[0].classList.add('godown');
          document.getElementsByClassName('goup')[0].classList.remove('goup');

          document.getElementById('categoryfilter').classList.add('collapsed');
          document.getElementById('brandFilter').classList.remove('show');
        } else if (
          document.getElementsByClassName('godown')[0] != undefined &&
          document.getElementsByClassName('godown')[0] != null
        ) {
          document.getElementsByClassName('godown')[0].classList.add('goup');
          document.getElementsByClassName('godown')[0].classList.remove('godown');

          document.getElementById('brandFilter').classList.add('show');
        }
      });
    });

    document.querySelectorAll('#brandFilter > ul > li > a').forEach((x) => {
      x.addEventListener('click', function (e) {
        document.getElementById('categoryfilter').classList.add('collapsed');
        document.getElementById('brandFilter').classList.remove('show');
      });
    });

    document.querySelectorAll('#brandFilter > ul > li > ul >li > a').forEach((x) => {
      x.addEventListener('click', function (e) {
        document.getElementById('categoryfilter').classList.add('collapsed');
        document.getElementById('brandFilter').classList.remove('show');
      });
    });
  }
}
