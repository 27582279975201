import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from 'react';
import { fnGtmAppClickDownload } from "../Js-CommonFunctions";

export default function AppBlock({ fields }) {
  if (fields !== null && fields !== undefined && fields !== "" && fields.heroData !== null && fields.heroData !== undefined && fields.heroData !== "") {
    return (
      <div className="footer">
        <div className="footer-app-wrap p-0" id="AppBlock">
          {fnAppDownloadLinks(fields)}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function fnAppDownloadLinks(fields) {
  if (fields.heroData.customMobile !== null && fields.heroData.customMobile.length !== 0 && fields.heroData.displayAppDownload) {
    if (!fields.heroData.isClub) {
      return (
        <> <div className="footer-bg-image position-absolute"></div>
          <div className="position-relative">
            <div className={"d-flex footer-experience footer-experience-new justify-content-center text-white " + (fields.heroData.isNewBrandedSite ? "" : "")}>
              <div className="flex-lg-column ms-lg-5">
                <div className="footer-title-new"><RichText field={fields.heroData.heading} /></div>
                {/* <div className="footer-title text-uppercase text-black"><RichText field={fields.heroData.description} /></div> */}
                <div className="d-flex justify-content-lg-start justify-content-center footer-stores-new">
                  <ul className="mobile-app-links p-0 d-flex">
                    {fields.heroData.customMobile.map((item, index) => {
                      const anchorId = "footer" + item.storeClass;
                      return (
                        (item.downloadLink !== null && item.downloadImagePath !== null) &&
                        <li key={index} className="d-inline pe-2">
                          <a aria-label={item?.downloadLink} href={item.downloadLink} id={anchorId} target={item.downloadLinkTarget} data-testid={"fnGtmAppClickDownload" + index} className="text-uppercase " onClick={(e) => fnGtmAppClickDownload(item.gtmAppOS)}>
                            <img className="app-download-img" src={fields.heroData.defaultImage} loading="lazy" data-alt={item.downloadImagePath} alt={item.downloadImageAltText} />
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="flex-lg-row footer-banner">
                <a href="#" className="text-uppercase">
                  <img className="appblock-img" src={fields.heroData.item?.backgroundImage?.value?.src} loading="lazy" alt={fields.heroData.item?.backgroundImage?.value?.alt}
                    title="android app" />
                </a>
              </div>
            </div>
          </div>
        </>
      )
    }

  }
}
