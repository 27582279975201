import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import RoutableSitecoreLink from "../RoutableSitecoreLink";

export default function FourBlocksPicto({ fields }) {
  if (fields) {
    var bgclr;
    if (fields.fourBlocksPictoModel.bgcolor === null) {
      bgclr = "";
    } else {
      bgclr = fields.fourBlocksPictoModel.bgcolor;
    }

    if (
      fields.fromContentResolver[fields.constant?.offerpicto] !== null &&
      fields.fromContentResolver[fields.constant?.offerpicto]?.value !== "" &&
      fields.fromContentResolver[fields.constant?.offerpicto]?.value !== null
    ) {
      var OfferPicto = {
        value:
          fields.constant.mallPictoStyleStart +
          fields.fromContentResolver[fields.constant.offerpicto]?.value +
          fields.constant.mallPictoStyleEnd,
      };
    }

    return (
      <div
        className={
          "col-md-3 col-sm-6 col-12 col" +
          (fields.fourBlocksPictoModel.isNewBrandedSite ? "" : " " + bgclr)
        }
      >
        <div className="item">
          <div className="picto-wrap">
            {fields.fourBlocksPictoModel.querryMessage &&
              fields.fromContentResolver[fields.constant.offerpicto] !== null &&
              fields.fromContentResolver[fields.constant.offerpicto]?.value !==
              "" &&
              fields.fromContentResolver[fields.constant.offerpicto]?.value !==
              null ? (
              <RichText
                field={fields.fromContentResolver[fields.constant.offerpicto]}
              />
            ) : (
              <RichText field={OfferPicto} />
            )}
          </div>
          {fields.constant.offertitle != null &&
            fields.constant.offertitle !== "" && (
              <h3 className="whats-hot-title text-uppercase">
                <span className="">
                  {fields.fromContentResolver[fields.constant.offertitle] !==
                    null &&
                    fields.fromContentResolver[fields.constant.offertitle]
                      ?.value !== "" &&
                    fields.fromContentResolver[fields.constant.offertitle]
                      ?.value !== null && (
                      <RichText
                        field={
                          fields.fromContentResolver[fields.constant.offertitle]
                        }
                      />
                    )}
                </span>
              </h3>
            )}
          <div className="description">
            {fields.fromContentResolver[fields.constant.offerdescription] !==
              null &&
              fields.fromContentResolver[fields.constant.offerdescription]
                ?.value !== "" &&
              fields.fromContentResolver[fields.constant.offerdescription]
                ?.value !== null && (
                <RichText
                  field={
                    fields.fromContentResolver[fields.constant.offerdescription]
                  }
                />
              )}
          </div>
          {fields.fromContentResolver[fields.constant.title] !== null &&
            fields.fromContentResolver[fields.constant.title]?.value !== "" &&
            fields.fromContentResolver[fields.constant.title]?.value !==
            null && (
              <RoutableSitecoreLink field={fields.fromContentResolver[fields.constant.offerurl]}
                showLinkTextWithChildrenPresent={false}
                className={
                  fields.fourBlocksPictoModel.isNewBrandedSite
                    ? "btn-link text-start p-0"
                    : "link-button btn-transparent"}>
                {
                  <RichText
                    field={fields.fromContentResolver[fields.constant.title]}
                  />
                }
              </RoutableSitecoreLink>
            )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
