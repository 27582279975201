import React from "react";
import { fngtmWorldOrCountrySelDataLayer } from "../Js-CommonFunctions.js";


export default function HomePageAssets({ fields }) {
  if (fields !== null && fields !== undefined && fields !== "" &&
    fields.homePageAsset !== null && fields.homePageAsset !== undefined && fields.homePageAsset !== ""
    && fields.homePageAsset.countryOrCenterSelectorData !== null && fields.homePageAsset.countryOrCenterSelectorData !== undefined && fields.homePageAsset.countryOrCenterSelectorData !== ""
    && fields.homePageAsset.countryOrCenterSelectorData.countryOrCenterList !== null && fields.homePageAsset.countryOrCenterSelectorData.countryOrCenterList !== undefined &&
    fields.homePageAsset.countryOrCenterSelectorData.countryOrCenterList !== "" && fields.homePageAsset.countryOrCenterSelectorData.countryOrCenterList.length > 0)
    return (
      <div className="center-selection" id="centerSelection">
        <section className="odd ">
          <div className={"wrapper container" + (fields.homePageAsset.isGlobalHomePage ? "":" px-3 px-lg-3 px-md-0")}>
            <h1 className="choose-your-country mb-3">{fields.homePageAsset.countryOrCenterSelectorData.title}</h1>
            <p className="discover-center">{fields.homePageAsset.countryOrCenterSelectorData.subtitle}</p>
            <div className={"choose-country text-center " + (fields.homePageAsset.isGlobalHomePage ? "p-3" : "p-1")}>

              {fields.homePageAsset.isGlobalHomePage && fields.homePageAsset.countryOrCenterSelectorData.countryOrCenterList.map((item, index) => {
                let shoppingCenterText = getShoppingCenterText(fields.homePageAsset.countryOrCenterSelectorData.description, parseInt(item?.count));
                if (item !== null && item !== undefined && item !== "") {
                  return (
                    <a href={item.link?.value?.url} key={index} data-testid={"fngtmWorldCountrySelDataLayerclick" + index} onClick={() => { fngtmWorldOrCountrySelDataLayer("select_country", item.title?.value) }} >
                      <figure>
                        <div className={"national-country-image picto " + item.picto?.value}></div>
                        <figcaption className="center-name pt-2">
                          {item.title?.value}
                        </figcaption>
                        <span className="center-shopping centers mt-1">{shoppingCenterText}</span>
                      </figure>
                    </a>)
                }
              })}

              {!fields.homePageAsset.isGlobalHomePage && fields.homePageAsset.countryOrCenterSelectorData.countryOrCenterList.map((item, index) => {
                if (item !== null && item !== undefined && item !== "") {
                  return (
                    <a className="national-center-list my-lg-3 cursor-pointer" href={item.link?.value?.url} key={index} data-testid={"fngtmCountryCenterSelDataLayerclick" + index} onClick={() => { fngtmWorldOrCountrySelDataLayer("select_center", item.title?.value) }}  >
                      <figure className="figure-width m-lg-0">
                        <img className={"national-center-image responsive " + (item.title?.value.toLowerCase().startsWith("westfield") ? "" : " unbranded-center-image")} loading="lazy" src={item.image?.value?.src} alt={item.image?.value?.alt} />
                        <div className="center-selection-mobile">
                          <figcaption className="country-name country-name-mobile pt-lg-1 my-lg-2 responsive cursor-pointer">
                            {item.title?.value}
                          </figcaption>
                          <span className="country-shopping country-shopping-mobile centers my-lg-2 responsive cursor-pointer">{item.description?.value}</span>
                        </div>
                        <span className="center-button d-none d-lg-block cursor-pointer">{item.link?.value?.text}</span>
                      </figure>
                    </a>
                  )
                }
              })}

            </div>
          </div>
        </section>
      </div>
    );
}

function getShoppingCenterText(description, count) {
  if (description !== null && description !== undefined && description !== "" && count !== null && count !== undefined && count !== "") {
    let desc = description.split("|");
    if (count > 1) {
      return desc[1]?.replace("{CENTERS}", count);
    }
    else {
      return desc[0]?.replace("{CENTERS}", count);
    }
  }

}

