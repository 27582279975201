import initialState from "../initialState";
import types from './types';

export default function userReducers(state = initialState, action) {
    switch (action.type) {
        case types.SET_VISITORID:
            return {
                ...state,
                visitorId: action.payload
            }
        case types.SET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload
            }
        case types.SET_REFRESHTOKEN:
            return {
                ...state,
                refreshToken: action.payload
            }
        case types.SET_ACQUISITION_PCCODE:
            return {
                ...state,
                acquisitionPcCode: action.payload
            }
        case types.SET_VISITORNAMEINITIALS:
            return {
                ...state,
                initials: action.payload
            }
        case types.SET_VISITOR_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case types.SET_VISITOR_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload
            }
        case types.SET_VISITOR_LAST_NAME:
            return {
                ...state,
                lastName: action.payload
            }
        case types.SET_WORKERCENTER:
            return {
                ...state,
                workerCenter: action.payload
            }
        case types.SET_BARCODE:
            return {
                ...state,
                barCode: action.payload
            }
        case types.SET_VISITORCENTERS:
            return {
                ...state,
                visitorCenters: action.payload
            }
        case types.SET_LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload
            }
        case types.DO_LOGOUT:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                visitorId: action.payload.visitorId,
                acquisitionPcCode: action.payload.acquisitionPcCode,
                barCode: action.payload.barCode,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                initials: action.payload.initials,
                email: action.payload.email,
                workerCenter: action.payload.workerCenter,
                birthdate: action.payload.birthdate,
                accountCreationDate: action.payload.accountCreationDate,
                favCenterLables: action.payload.favCenterLables,
                bookmarkedOffer: action.payload.bookmarkedOffer,
                bookmarkedStore: action.payload.bookmarkedStore,
                bookmarkedRestaurant: action.payload.bookmarkedRestaurant,
                bookmarkedEvent: action.payload.bookmarkedEvent,
                //communityName: action.payload.communityName,
                smartParkUser: action.payload.smartParkUser,
                parkingClubActiveUser: action.payload.parkingClubActiveUser,
                parkingPartnerCenter: action.payload.parkingPartnerCenter,
                smartParkerCenter: action.payload.smartParkerCenter,
                bookingAccessToken: action.payload.bookingAccessToken,
                bookingAccessTokenExpiryValue: action.payload.bookingAccessTokenExpiryValue,
                encryptedEmail : action.payload.encryptedEmail,
            }
        case types.SET_VISITORBIRTHDATE:
            return {
                ...state,
                birthdate: action.payload
            }
        case types.SET_VISITORACCOUNTCREATIONDATE:
            return {
                ...state,
                accountCreationDate: action.payload
            }
        case types.SET_VISITORFAVCENTERS:
            return {
                ...state,
                favCenterLables: action.payload
            }
        case types.SET_VISITORBOOKMARKEDOFFER:
            return {
                ...state,
                bookmarkedOffer: action.payload
            }
        case types.SET_VISITORBOOKMARKEDSTORE:
            return {
                ...state,
                bookmarkedStore: action.payload
            }
        case types.SET_VISITORBOOKMARKEDRESTAURANT:
            return {
                ...state,
                bookmarkedRestaurant: action.payload
            }
        case types.SET_VISITORBOOKMARKEDEVENT:
            return {
                ...state,
                bookmarkedEvent: action.payload
            }
       
        case types.SET_SMARTPARKUSER:
            return {
                ...state,
                smartParkUser: action.payload
            }
        case types.SET_PARKINGCLUBACTIVEUSER:
            return {
                ...state,
                parkingClubActiveUser: action.payload
            }
        case types.SET_PARKINGPARTNERS:
            return {
                ...state,
                parkingPartners: action.payload
            }
        case types.SET_SMARTPARKERCENTER:
            return {
                ...state,
                smartParkerCenters: action.payload
            }
        case types.SET_BOOKINGACCESSTOKEN:
            return {
                ...state,
                bookingAccessToken: action.payload
            }
        case types.SET_BOOKINGACCESSTOKENEXPIRYVALUE:
            return {
                ...state,
                bookingAccessTokenExpiryValue: action.payload
            }
        case types.SET_ENCRYPTEDEMAIL:
            return {
                ...state,
                encryptedEmail: action.payload
            }   
        default:
            return state;
    }
}