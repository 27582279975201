import React from 'react';
import { baseModel } from '../../RouteHandler';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function PhTitleImageDesc({ rendering }) {
  if (rendering) {
    return (
      <section className="history" id="history">
        <div className="container">
          < div className={(baseModel.isExperienceEditor ? "" : " row")}>
            <Placeholder name="ph_titleimagedesc" rendering={rendering} />
          </div>
        </div>
      </section >
    )
  } else {
    return <></>;
  }
}
