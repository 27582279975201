import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from "react";
import { convertUTCTimeToClientTime, countdown, fngtmLiveDetailClick } from "../Js-CommonFunctions";

export default function LiveEventDetailsPage({ fields }) {
    const [videoStreamInfo, setVideoStreamInfo] = useState(null);

    const [startAtTime, setStartAtTime] = useState(new Date());
    const [finishAtTime, setFinishAtTime] = useState(new Date());
    const [localeDateTime, setLocaleDateTime] = useState(new Date());

    const [playVideo, setPlayVideo] = useState(false);

    useEffect(() => {
        if (fields !== null && fields !== undefined && fields !== "" && fields.liveEvent !== null && fields.liveEvent !== "" && fields.liveEvent !== undefined &&
            fields.liveEvent.videoStreamInfo !== null && fields.liveEvent.videoStreamInfo !== "" && fields.liveEvent.videoStreamInfo !== undefined) {
            setVideoStreamInfo(fields.liveEvent.videoStreamInfo);

            if (fields.liveEvent.videoStreamInfo.startsAt) { setStartAtTime(new Date(fields.liveEvent.videoStreamInfo.startsAt)); }
            if (fields.liveEvent.videoStreamInfo.finishesAt) { setFinishAtTime(new Date(fields.liveEvent.videoStreamInfo.finishesAt)); }

            let startsAt = new Date(fields.liveEvent.videoStreamInfo.startsAt);
            let finishAt = new Date(fields.liveEvent.videoStreamInfo.finishesAt);

            if (startsAt > localeDateTime && fields.liveEvent.videoStreamInfo.status != "unpublished" && fields.liveEvent.videoStreamInfo.status != "pending" &&
                fields.liveEvent.videoStreamInfo.videoId != "" && fields.liveEvent.videoStreamInfo.videoId != null && fields.liveEvent.videoStreamInfo.playlistId != "" &&
                fields.liveEvent.videoStreamInfo.playlistId != null) { setPlayVideo(true) }
            else if (startsAt < localeDateTime && finishAt > localeDateTime && fields.liveEvent.videoStreamInfo.status != "unpublished" &&
                fields.liveEvent.videoStreamInfo.status != "pending" && fields.liveEvent.videoStreamInfo.videoId != "" && fields.liveEvent.videoStreamInfo.videoId != null &&
                fields.liveEvent.videoStreamInfo.channelId != "" && fields.liveEvent.videoStreamInfo.channelId != null) { setPlayVideo(true) }
            else if (finishAt < localeDateTime && fields.liveEvent.videoStreamInfo.status != "unpublished" && fields.liveEvent.videoStreamInfo.status != "pending" &&
                fields.liveEvent.videoStreamInfo.videoId != "" && fields.liveEvent.videoStreamInfo.videoId != null && fields.liveEvent.videoStreamInfo.channelId != "" &&
                fields.liveEvent.videoStreamInfo.channelId != null) { setPlayVideo(true) }
        }
    }, []);

    useEffect(() => {
        let status = startAtTime < localeDateTime && finishAtTime > localeDateTime ? "Direct" :
            startAtTime > localeDateTime && finishAtTime > localeDateTime ? "Upcoming" : "Replay";

        if (document.getElementById('liveDetailStoryBlock') != null && document.getElementById('liveDetailStoryBlock') != undefined) {
            const reload = document.getElementById('liveDetailStoryBlock');
            reload.addEventListener('fw:storyblock:impression', function (event) {
                fngtmLiveDetailClick(fields, "extra_live_video_view", undefined, status);
            })

            reload.addEventListener('fw:video:start', function (event) {
                fngtmLiveDetailClick(fields, "extra_live_video_start", undefined, status);
            })

            reload.addEventListener('fw:video:first-quartile', function (event) {
                fngtmLiveDetailClick(fields, "video_first_quartile", undefined, status);
            })

            reload.addEventListener('fw:video:midpoint', function (event) {
                fngtmLiveDetailClick(fields, "video_mid_point", undefined, status)
            })
            reload.addEventListener('fw:video:third-quartile', function (event) {
                fngtmLiveDetailClick(fields, "video_third_quartile", undefined, status);
            })

            reload.addEventListener('fw:video:complete', function (event) {
                fngtmLiveDetailClick(fields, "extra_live_video_complete", undefined, status);
            })

            reload.addEventListener('fw:player:transition', function (event) {
                if (event !== null && event !== undefined && event !== "" && event.detail !== null && event.detail !== "" && event.detail !== undefined && event.detail.transitionTo === "fullscreen") {
                    fngtmLiveDetailClick(fields, "click_enlarge_player");
                }
            })

            reload.addEventListener('fw:video:click-cta', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "click-cta");
            })

            reload.addEventListener('fw:video:click-share', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "click-share");
            })

            reload.addEventListener('fw:video:share', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "share");
            })

            reload.addEventListener('fw:video:submit-answer', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "submit-answer");
            })

            reload.addEventListener('fw:video:submit-choice', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "submit-choice");
            })

            reload.addEventListener('fw:player:quit', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "close-button");
            })
            reload.addEventListener('fw:shopping:product-clicked', function (event) {
                fngtmLiveDetailClick(fields, "live_interaction", "product_click_link");
            })
        }
    }, [playVideo]);

    function fnVideoPlayer() {
        window._fwn?.players["urw-storyblock"].fullscreen();
    }

    if (videoStreamInfo) {
        return (
            <section className="welcome bg-1">
                <div className="container pg-detail" id="liveEventDetailPage">
                    <div className="row description description-col-detail">
                        <div className="col-md-6 col-lg-8 opening-hours-details order-lg-0 order-md-0 order-sm-1 order-1">
                            <div className="position-relative">
                                <div className="grid-content ">
                                    {videoStreamInfo.gtmRetailerName !== null && videoStreamInfo.gtmRetailerName !== undefined && videoStreamInfo.gtmRetailerName !== "" &&
                                        <><pre className="description-event">
                                            {videoStreamInfo.gtmRetailerName.replace(/,/g, "     ")}
                                        </pre>
                                        </>
                                    }
                                    <div className='description-title'>
                                        <h1 className="grid-title-detail">  {videoStreamInfo.name}</h1>
                                        {(startAtTime < localeDateTime && finishAtTime > localeDateTime) &&
                                            <div className="grid-new-cta-border">
                                                <a href="javascript:void(0);" data-testid="fngtmLiveDetailClick" aria-label={fields.liveEvent.watch_Live}
                                                    onClick={() => { fnVideoPlayer(); fngtmLiveDetailClick(fields, "click_watch_now") }}
                                                    className="grid-new-cta text-center d-flex line-height-normal">{fields.liveEvent.watch_Live}</a>
                                                {/* <span className='picto icon-arrow-square-red-right-rw align-middle px-2 fs-5' /> */}

                                            </div>
                                        }
                                        {(finishAtTime < localeDateTime) &&
                                            <div className="grid-new-cta-border">
                                                <a href="javascript:void(0);"
                                                    aria-label={fields.liveEvent.watch_Live}
                                                    data-testid="fnVideoPlayer"
                                                    onClick={() => { fnVideoPlayer(); fngtmLiveDetailClick(fields, "click_watch_replay") }}
                                                    className="grid-new-cta text-center d-flex line-height-normal">
                                                    {fields.liveEvent.watch_Replay}</a>
                                                {/* <span className='picto icon-arrow-square-red-right-rw align-middle px-2 fs-5' /> */}
                                            </div>
                                        }
                                    </div>
                                    <div className="date-details">{convertUTCTimeToClientTime(videoStreamInfo.startsAt, false, false)}</div>
                                </div>
                                {(startAtTime > localeDateTime)
                                    ? <p className="counter-detail"
                                        id={"counter-upcoming1"}>{countdown(fields, videoStreamInfo, 1)}
                                    </p>
                                    : <></>
                                }
                                {(startAtTime < localeDateTime && finishAtTime > localeDateTime) &&
                                    <div className='d-flex align-items-center'>
                                        <div className="btn-wrap btn-live d-flex align-items-center">
                                            <span className='picto icon-record-rw d-inline-flex align-items-center fs-6 rounded-circle' />
                                            <span className="link-button-up-detail-live ps-1">{fields.liveEvent.live_Now}</span>
                                        </div>
                                    </div>
                                }
                                <div className="description-detail first-para">
                                    <RichText field={{ value: videoStreamInfo.description }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 rich-text order-lg-1 order-md-1 order-sm-0 order-0">
                            <div className="promo-img-detail position-relative ">
                                {playVideo ?
                                    <fw-storyblock
                                        id="liveDetailStoryBlock"
                                        data-testid="liveDetailStoryBlockEvent"
                                        name="urw-storyblock"
                                        channel={videoStreamInfo.channelId}
                                        video={videoStreamInfo.videoId}
                                        max_videos="1"
                                        branding="false"
                                        player_minimize="false"
                                        height="610px"
                                        loop="false"></fw-storyblock>
                                    : <img className="detail-img"
                                        src={videoStreamInfo?.media?.eventImage}
                                        alt={videoStreamInfo.name}
                                        title={videoStreamInfo.name} />
                                }
                                {(startAtTime < localeDateTime && finishAtTime > localeDateTime) &&
                                    <div className="live-now-notification">
                                        {fields.liveEvent.live_Now}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else {
        return <></>;
    }
}
