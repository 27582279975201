import React from "react";

export default function Trailer({ fields }) {
  if (
    fields?.trailerModelObj !== null &&
    fields?.trailerModelObj?.trailerLink !== ""
  ) {
    return (
      <section
        className={
          "movie-trailer" +
          (fields.trailerModelObj.isNewBrandedSite ? " back-gray" : "")
        }
        id="trailer"
      >
        <div className="container">
          {fields.trailerModelObj.isNewBrandedSite ? (
            <div className="header-title carousel-title  d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
              <a aria-label={fields?.trailerModelObj?.trailerHeading} className="title mb-1 mb-lg-0">
                <h2>{fields.trailerModelObj.trailerHeading}</h2>
              </a>
            </div>
          ) : (
            <h2 className="content-title text-uppercase text-center underline">
              {fields.trailerModelObj.trailerHeading}
            </h2>
          )}
          <div className="row">
            <div className="col-md-12">
              <div
                className="embed-responsive position-relative d-block w-100"
                style={{
                  padding: fields.trailerModelObj.isNewBrandedSite
                    ? "25%"
                    : "28%",
                }}
              >
                <iframe
                  className="position-absolute top-0 bottom-0 start-0 w-100 h-100 border-0"
                  title="trailerIframe"
                  frameBorder="0"
                  src={fields.trailerModelObj.trailerLink + "?wmode=transparent"}
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
