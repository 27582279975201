import Enumerable from "linq";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { convertUTCTimeToClientTime, countdown, fngtmModuleclick, isMobile, lazyLoadingImg } from "../Js-CommonFunctions";

export default function UpcomingEvent({ fields }) {
  const [counter, setCounter] = useState(0);
  const [visible, setVisible] = useState(3);
  const [pgload, setpgload] = useState(1);
  const [upComingEventList, setupComingEventList] = useState([]);

  var totalSlidesCount = fields.liveEvent.videoList.length + 1;
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: totalSlidesCount > 2 ? 2.5 : totalSlidesCount,
          slidesToScroll: 1,
        },
      },
    ],
    onInit: () => {
      setTimeout(() => {
        document.querySelectorAll('.live-events .slick-track').forEach(item => {
          item.style.transform = 'translate3d(0,0,0)';
        })
      }, 500)
    }
  };

  var ct = 0;
  useEffect(() => {
    var localeDateTime = new Date();
    if (fields.liveEvent !== null && fields.liveEvent.videoList !== null && fields.liveEvent.videoList !== "" && fields.liveEvent.videoList !== undefined && fields.liveEvent.videoList.length > 0) {
      var activeData = Enumerable.from(fields.liveEvent.videoList).orderBy(x => new Date(x.startsAt).getTime()).where(x => (x.status != "unpublished" && x.status != "pending" && new Date(x.startsAt).getTime() <= localeDateTime.getTime() && new Date(x.finishesAt).getTime() >= localeDateTime.getTime())).toArray(); //Active Events
      var upcomingData = Enumerable.from(fields.liveEvent.videoList).orderBy(x => new Date(x.startsAt).getTime()).where(x => (x.status != "unpublished" && x.status != "pending" && new Date(x.startsAt).getTime() > localeDateTime.getTime())).toArray(); //Upcoming Events
      var finalPushedData = activeData.push(...upcomingData);
      setupComingEventList(activeData);
    }
  }, []);

  useEffect(() => {
    if (isMobile.any()) {
      setVisible(upComingEventList.length);
    }
    else if (isMobile.isTablet()) {
      setVisible(3);
    }
    else if (window.screen.orientation != null && window.screen.orientation != "" && window.screen.orientation.type != null && window.screen.orientation.type != "") {
      if (window.screen.orientation.type == "landscape-primary" || window.screen.orientation.type == "landscape-secondary") {
        setVisible(3);
      }
    }
    else {
      setVisible(3);
    }
  }, [upComingEventList]);

  const fnViewrightClick = () => {
    if (visible >= upComingEventList.length) {
      return false;
    }
    if (visible + 3 >= upComingEventList.length) {
      setVisible(upComingEventList.length)
    }
    else {
      setVisible(visible + 3)
    }
    setCounter(counter + 3)
    setpgload(0);
  }

  const fnViewleftClick = () => {
    if (counter === 0) {
      return false;
    }
    if (visible === upComingEventList.length) {
      setCounter(counter - 3)
      if (visible % 3 === 0) {
        setVisible(visible - 3)
      }
      else {
        setVisible(visible - visible % 3)
      }
    }
    else if (counter - 3 <= 0) {
      setCounter(0)
      setVisible(3)
    }
    else {
      setCounter(counter - 3)
      setVisible(visible - 3)
    }
    setpgload(0);
  }

  if (fields != null && fields.liveEvent != null && upComingEventList != null && upComingEventList.length > 0) {
    return (
      <section className="generic-three-block even-live live-events position-relative bg-2">
        <div className="container">
          <div className="row">
            <div className="header-title header-title-events d-flex align-items-center flex-wrap justify-content-between ps-0 mb-2">
              <h2 className="title-shopping-event mt-4 ps-0">{fields.liveEvent.upcoming_Live_Events_Title}</h2>
              <div className="upcomming-arrows">
                <a href="javascript:void(0);" aria-label={fields?.liveEvent?.upcoming_Live_Events_Title} id="left-arrow" className={"picto icon-circle-left fs-2 me-2 text-black" + (counter < 3 ? " upcoming-arrow-opacity" : "")} data-testid="fnViewleftClick" onClick={(e) => fnViewleftClick()}></a>
                <a href="javascript:void(0);" aria-label={fields?.liveEvent?.upcoming_Live_Events_Title} id="right-arrow" className={"picto icon-circle-right fs-2 me-2 text-black" + (upComingEventList.length <= (counter + 3) ? " upcoming-arrow-opacity" : "")} data-testid="fnViewrightClick" onClick={(e) => fnViewrightClick()}></a>
              </div>
            </div>
            <Slider {...settings} className={"d-flex upcoming-shopping-slider p-0"} id="carouselEvent">
              {upComingEventList !== null && upComingEventList.slice(counter, visible).map((item, index) => {
                var startAtTime = new Date(item.startsAt);
                var finishAtTime = item.finishesAt !== undefined && item.finishAtTime !== null && item.finishAtTime !== "" ? new Date(item.finishesAt) : "";
                var localeDateTime = new Date();
                ct = counter + index;
                var formatedDateTime = convertUTCTimeToClientTime(item.startsAt, false, false);
                if ((finishAtTime !== "" && finishAtTime.getTime() >= localeDateTime.getTime())) {
                  return (
                    <div key={ct} className="col-6 col-lg-4 col-md-4  mb-3 ps-lg-0 pe-lg-2 px-1 upcoming-col-pd" id="scrolldiv">
                      <div className="bg-transparent border-0 shadow-none col grid-item past-live-shopping-event-mob grid-withpicture bg-white position-relative">
                        <div className="picto-icon img-event-container position-relative grid-picture m-0 p-0">
                          {
                            (item.media != null && item.media != "") ? (pgload === 1) ?
                              <img
                                src={fields.liveEvent.defaultImage}
                                data-alt={item.media.eventImage}
                                alt={item.name}
                                title={item.name}
                                className="border-8"
                              /> :
                              <img
                                src={item.media.eventImage}
                                alt={item.name}
                                title={item.name}
                                className="border-8"
                              /> : <></>
                          }
                          {
                            (startAtTime > localeDateTime) ? <p className="position-absolute-live ctr-upcoming" id={"counter-upcoming" + ct}>{countdown(fields, item, ct)}</p> :
                              <div className="position-absolute-live live-now-upcoming live-now ">{fields.liveEvent.live_Now}</div>
                          }
                        </div>
                        <a
                          aria-label={fields?.liveEvent}
                          href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                          className={"position-absolute w-100 z-index-1 h-100 block-link"}
                        ></a>
                        <div className="grid-content grid-content-event p-0">
                        {item.startsAt != null && item.startsAt != "" && (
                            <div className={"date-live-event  text-start mt-3 mb-1  " + (startAtTime > localeDateTime ? "" : "date-upcoming")}>{formatedDateTime}
                            </div>
                          )}
                          {item.retailers != null && item.retailers != "" ?
                            (item.retailers.length > 1 ? <div className="description-event text-start p-0 mb-1">{fields.liveEvent.multi_Retailers}</div> :
                              <div className="description-event text-start p-0 mb-1">
                                {item.retailers[0]?.retailerName}
                              </div>)
                            : <div className="description-event retailer-name-title text-start p-0 mt-3">
                            </div>
                          }
                          {item.name != null && item.name != "" && (
                            <h3 className="grid-title-live d-flex p-0 text-start mt-2 mb-0">
                              {item.name}
                            </h3>
                          )}
                          
                          <div className={"d-flex position-absolute text-start flex-column my-1"}>
                            {!(startAtTime > localeDateTime) ?
                              <div className="text-start bg-transparent ">
                                <a aria-label={fields?.liveEvent?.watch_Live} href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                                  data-testid="fngtmModuleclick" onClick={() => fngtmModuleclick(item, "click_watch_now")}
                                  className="grid-new-cta pe-1 grid-new-cta-color">{fields.liveEvent.watch_Live}
                                </a><span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-lg-5" /> </div>
                              : <></>
                            }
                            <div className="text-start bg-transparent ">
                              <a aria-label={fields?.liveEvent?.see_Details}
                                href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                                className="grid-new-cta pe-1 grid-new-cta-color"
                                data-testid="fngtmModuleclic2" onClick={() => fngtmModuleclick(item, "click_see_details", "Upcoming")}
                              >{fields.liveEvent.see_Details}</a>
                              <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-lg-5" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
  else {
    return <></>;
  }
}
