import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';


export default function LegalText({ fields }) {
  if (fields !== null && fields !== undefined && fields !== "") {
    return (
      <>
        <div className="container py-4">
          <RichText field={fields.legalTexts.legalText} />
        </div>
      </>
    )
  }
  else {
    return (
      <></>
    )
  }
}
