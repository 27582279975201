import React from 'react';
import { offersClick, fnGtmOfferClick } from '../Js-CommonFunctions';
import { RichText } from '@sitecore-jss/sitecore-jss-react';


export default function PersonalizedOffers({ model, obj }) {
    let offerIndex = 0;
    let isDetailPage = model.isStoreOrRestaurantDetailPage;
    let isConnected = (model.uID != null && model.uID != "");
    if (model.personalizedOfferItems != null) {
        return (
            model.personalizedOfferItems.map((offeritems, index) => {
                var subtitle = { value: offeritems.offer.subtitle };
                if (offeritems != null) {

                    offerIndex++;
                    let offerStoreRetailerName = "";
                    let offerCommercialOper = obj.commercialOfferType;
                    let offerAnchor = "";
                    let isOfferLogo = true;
                    let isOfferExternal = true;
                    let logoSource = "";
                    let logoAltText = "";
                    let offerLink = "";
                    let OfferStoreName = "";
                    let OfferTitle = "";
                    let storeName = "";
                    let retailerName = "";
                    let offerStickerName = "";
                    let strCommercialOperation = offeritems.commercialOperation != null && offeritems.commercialOperation.name != null && offeritems.commercialOperation.name != "" ? offeritems.commercialOperation.name : "";
                    if (offeritems.offer != null) {
                        if (offeritems.offer.featured && (offeritems.offer == null || offeritems.offer.categoryIds == null && offeritems.offer.categoryIds.length > 0 && offeritems.offer.categoryIds[0].value !== obj.commercialOfferType)) {
                            offerAnchor = obj.offerAnchorTopOffer;
                        }
                        else {
                            if (offeritems.offer.categoryIds !== null) {
                                if (strCommercialOperation !== null && strCommercialOperation !== "") {
                                    let offerAnchorHref = strCommercialOperation.toLowerCase().replace(" ", "");
                                    offerAnchor = offerAnchorHref;
                                }
                            }
                        }
                    }
                    if (offeritems.commercialOperation != null && offeritems.commercialOperation.categoryId !== obj.commercialTypeExternal) {
                        if (offeritems.storeitem != null) {
                            offerStoreRetailerName = ((offeritems.storeitem != null && offeritems.storeitem.name !== null && offeritems.storeitem.name !== "") ? offeritems.storeitem.name : "");
                            isOfferLogo = (offeritems.imgLogoURL !== null && offeritems.imgLogoURL !== "");
                            isOfferExternal = false;
                            logoSource = offeritems.imgLogoURL;
                            logoAltText = offeritems.imgLogoAltText;
                            offerLink = offeritems.storeLinkURL.startsWith(".") ? offeritems.storeLinkURL.trimStart('.') : offeritems.storeLinkURL;
                            OfferStoreName = ((offeritems.storeitem != null && offeritems.storeitem.name !== null && offeritems.storeitem.name !== "") ? offeritems.storeitem.name : "");
                            offerStickerName = (offeritems != null && offeritems.offer != null) ? offeritems.offer.sticker : "";
                            storeName = OfferStoreName;
                            retailerName = ((offeritems.storeitem != null && offeritems.storeitem.retailerName !== null && offeritems.storeitem.retailerName !== "") ? offeritems.storeitem.retailerName : "");
                            OfferTitle = ((offeritems.offer != null && offeritems.offer.title !== null && offeritems.offer.title !== "") ? offeritems.offer.title : "");
                        }
                        else {
                            isOfferLogo = (offeritems.externalOfferLogo !== null && offeritems.externalOfferLogo !== "");
                            isOfferExternal = false;
                            logoSource = offeritems.externalOfferLogo.toString().includes(obj.imgCloudinary) ? offeritems.scaleExternalOfferLogo : offeritems.maxExternalOfferLogo;
                            logoAltText = offeritems.externalOfferLogoAlt;
                            offerLink = obj.fid !== "" ? (offeritems.externalOfferLink + "?FidID=" + obj.fid) : offeritems.externalOfferLink;
                            offerStickerName = (offeritems != null && offeritems.offer != null) ? offeritems.offer.sticker : "";
                            OfferStoreName = ((offeritems.offer != null && offeritems.offer.title !== null && offeritems.offer.title !== "") ? offeritems.offer.title : "");
                            storeName = ((offeritems != null && offeritems.offer != null && offeritems.offer.title !== null && offeritems.offer.title !== "") ? offeritems.offer.title : "");
                            OfferTitle = OfferStoreName;
                        }

                    }
                    else {
                        isOfferLogo = (offeritems.externalOfferLogo !== null && offeritems.externalOfferLogo !== "");
                        logoSource = offeritems.externalOfferLogo.toString().includes(obj.imgCloudinary) ? offeritems.scaleExternalOfferLogo74 : offeritems.maxExternalOfferLogo74;
                        logoAltText = offeritems.externalOfferLogoAlt;
                        offerLink = obj.fid !== "" ? (offeritems.externalOfferLink + "?FidID=" + obj.fid) : offeritems.externalOfferLink;
                        offerStickerName = (offeritems != null && offeritems.offer != null) ? offeritems.offer.sticker : "";
                        storeName = ((offeritems != null && offeritems.offer != null && offeritems.offer.title !== null && offeritems.offer.title !== "") ? offeritems.offer.title : "");
                        retailerName = ((offeritems.storeitem != null && offeritems.storeitem.retailerName !== null && offeritems.storeitem.retailerName !== "") ? offeritems.storeitem.retailerName : "");
                    }
                    var offerdesc = { value: offeritems.offer.description }
                    var offerImageClass = model.isOffersComponent && offeritems.offer.image != "" ? "offers-header-back-img" : "offers-header";
                    var tooltipClass = model.isOffersComponent && offeritems.offer.image != "" ? "offers-tooltip-btn-wrap-back" : "offers-tooltip-btn-wrap";

                    return (
                        <div key={index} className={"col-lg-3 col-12 contentBlockPersonalized flip-card gtmOffersNavigation " + (offerIndex > 4 ? "hidden" : "OnLoadoffers")} data-testid='testclick1' data-link={offerLink} onClick={e => { fnGtmOfferClick(OfferTitle, storeName, retailerName, offeritems.eligibilityText, false, model.isNewBrandedSite); offersClick(e, offerLink, isDetailPage) }} aria-label={offeritems.offer.dealId} data-istop={offeritems.offer.featured} data-letterfilter={offeritems.letterForFilter}>
                            <div className={"offers-thumbnail position-relative border-0 rounded-0 p-0 " + (model.isUSUK ? model.uS_UK_StoreLogo : "")}>
                                {offeritems.offerType !== null && offeritems.offerType !== "" ?
                                    (<input type="hidden" id="offerType" value={offeritems.offerType} />) :
                                    (<input type="hidden" id="offerType" value="Regular" />)
                                }
                                <a className="open-popin ava-wishlist bookmark picto icon-heart-unfill-rw" role="button"
                                    data-bs-target="#popin" data-popinurl="" data-bs-toggle="modal" href="#"
                                    aria-label={offeritems.offer.dealId} aria-labelledby="deal" data-templatetype="Offers" data-testid='testclick2' onClick={(e) => e.preventDefault()}
                                    data-itemlabel={OfferTitle}>
                                </a>
                                {(offeritems.offer.termsAndConditions != null && offeritems.offer.termsAndConditions != "") &&
                                    // <div className={(tooltipClass) + " position-absolute rounded-circle js-tooltip"}>
                                    //     <span className="picto icon-infos gtmFlipOffer js-tooltip"></span>
                                    // </div>
                                    <div className={"picto icon-information-rw fs-5 position-relative rounded-circle js-tooltip mx-2"}>
                                    </div>
                                }
                                <a className={(offerImageClass) + " d-block"}>
                                    <span className="helper"></span>
                                    <img src={logoSource} className="d-inline-block m-0" alt={logoAltText} title={logoAltText} />
                                </a>
                                <div className="offers-caption">
                                    {!isOfferExternal && !isDetailPage && OfferStoreName !== null && OfferStoreName !== "" ?
                                        (<a className="brand-name mb-2 text-uppercase position-relative text-emmet fw-bold analytics_shopLandingOffersClk gtmOffersNavigation" data-link={offerLink} href="#" data-testid='testclick3' onClick={(e) => { e.preventDefault(); return this.closest('.flip-card').click(this); }} >{OfferStoreName}</a>) :
                                        (isDetailPage && isConnected ?
                                            (<a className="brand-name mb-2 text-uppercase position-relative text-emmet fw-bold" href="#" data-testid='testclick4' onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>{OfferStoreName}</a>) :
                                            (isConnected && !isDetailPage ?
                                                (<a className="brand-name mb-2 text-uppercase position-relative text-emmet fw-bold" href="#" data-testid='testclick5' onClick={(e) => { e.stopPropagation(); }}>{OfferStoreName}</a>) :
                                                (isDetailPage ?
                                                    (<a className="brand-name mb-2 text-uppercase position-relative text-emmet fw-bold">{OfferStoreName}</a>) :
                                                    (
                                                        //comehere for Loginpage implementation
                                                        <a className="brand-name mb-2 text-uppercase position-relative text-emmet fw-bold" href="#">{OfferStoreName}</a>
                                                    )
                                                )
                                            )
                                        )
                                    }
                                    <a className={"reduction-item loyalty-text mb-1 text-uppercase position-relative fw-bold " + (model.isLPV2Site ? "premium-text" : "loyalty-text")}>{offeritems.offer.sticker}</a>
                                    <a className="conditions mb-12 position-relative text-emmet"><RichText field={subtitle} /></a>
                                    {offeritems.eligibilityText !== null && offeritems.eligibilityText !== "" && offeritems.eligibilityText === model.eligibilityLoyaltyText ?
                                        (
                                            <a className={"offer-tap loyalty-text mb-0 text-uppercase fw-bold " + (model.isLPV2Site ? "premium-text" : "loyalty-text")}>
                                                <span className="align-middle picto icon-vip-card"></span>
                                                <span>{offeritems.eligibilityText}</span>
                                            </a>
                                        ) :
                                        (offeritems.eligibilityText !== null && offeritems.eligibilityText !== "" && offeritems.eligibilityText === model.eligibilityWorkerText ?
                                            (
                                                <a className="offer-tap loyalty-text mb-0 text-uppercase fw-bold">
                                                    <span className="picto icon-vip-card align-middle"></span>
                                                    <span>{offeritems.eligibilityText}</span>
                                                </a>
                                            ) :
                                            (<a className="offer-tap loyalty-text mb-0 text-uppercase fw-bold"></a>)
                                        )
                                    }
                                </div>
                            </div>
                            <div className="offers-thumbnail bg-white flip-back position-relative border-0 rounded-0 " style={{ display: "none" }}>
                                {model.isNewBrandedSite ?
                                    (<span className="flip-message"><RichText field={offerdesc} /></span>) :
                                    (<div className="flip-message"><RichText field={offerdesc} /></div>)
                                }
                                <span className="flip-close picto icon-close-circle-rw"> </span>
                            </div>
                        </div>
                    )
                }
            })

        )
    }
    else {
        return (<></>)
    }
}