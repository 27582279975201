import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function PhGenericThreeColumn({ rendering }) {

  if (rendering) {
    return (
      <Placeholder name="ph_ThreeColumn" rendering={rendering} />
    )
  } else {
    return <></>;
  }

}