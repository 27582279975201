import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function DestinationDescription({ fields }) {
  if (fields.fromContentResolver !== null) {
    if (fields.fromContentResolver[fields.props.description] !== null && fields.fromContentResolver[fields.props.description] !== "") {
      return (
        <section className="general bg-1">
          <div className="container">
            {(fields.fromContentResolver[fields.props.destSubTitle] !== null && fields.fromContentResolver[fields.props.destSubTitle] !== "") &&
              <h2 className="content-title"><RichText field={fields.fromContentResolver[fields.props.destSubTitle]} /></h2>
            }
            <p><Text field={fields.fromContentResolver[fields.props.description]} /></p>
          </div>
        </section>
      )
    }
    else {
      return <></>;
    }
  }
  else {
    return <></>;
  }
}
