import React from 'react';

export default function FilmLogoHeader({ fields }) {
  if (fields.filmLogoHeaderModel != null) {
    return (
      (fields.filmLogoHeaderModel.isNewBrandedSite) ?
        (
          <div className="anchor-navigation">
            <div className="page-title movie-title pt-lg-4 pb-0 m-0 back-gray">
              <div className="container">
                <div className="title d-flex align-items-center flex-wrap justify-content-lg-between">
                  {/* <div className="text-uppercase"> */}
                    <h1 className="page-title">
                      {(fields.filmLogoHeaderModel.filmHeader !== null) &&
                        fields.filmLogoHeaderModel.filmHeader
                      }
                    </h1>
                  {/* </div> */}
                  {(fields.filmLogoHeaderModel.imgurl !== "" && fields.filmLogoHeaderModel.imgurl !== null) &&
                    <a className="action mb-4 mb-lg-0" aria-label={fields?.filmLogoHeaderModel?.imgalt}>
                      <img src={fields.filmLogoHeaderModel.filmImgUrl} alt={fields.filmLogoHeaderModel.imgalt} title={fields.filmLogoHeaderModel.imgalt} />
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        ) :
        (
          <div className="anchor-navigation">
            <div className="container">
              <div className="page-title">
                {(fields.filmLogoHeaderModel.imgurl !== "" && fields.filmLogoHeaderModel.imgurl !== null) &&
                  <img src={fields.filmLogoHeaderModel.filmImgUrl} alt={fields.filmLogoHeaderModel.imgalt} title={fields.filmLogoHeaderModel.imgalt} />
                }
              </div>
              <h1 className="page-title text-uppercase m-0">
                {(fields.filmLogoHeaderModel.filmHeader !== null) &&
                  fields.filmLogoHeaderModel.filmHeader
                }
              </h1>
            </div>
          </div>
        )
    );
  } else {
    return <></>;
  }
}
