import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { baseModel } from '../../RouteHandler';

export default function PhFourColumnRow({ fields ,rendering }) {
  if (rendering) {
    return (
      <section className={"our-offer" + (baseModel.isNewBrandedSite ? " back-gray" : "")} id="offer" >
        <div className="container">
          {(fields.fourBlocksPictoViewModel.phFourColumnTitle !== "" && fields.fourBlocksPictoViewModel.phFourColumnTitle!==null) &&

            <h2 className={(baseModel.isNewBrandedSite ? " page-title" : "content-title") + "text-uppercase text - center" + (baseModel.isNewBrandedSite ? "" : " underline")}>{fields.fourBlocksPictoViewModel.phFourColumnTitle}</h2>

          }

          <div className={(baseModel.isExperienceEditor ? "" : " row isflex")}>
            <Placeholder name="ph_pictobox" rendering={rendering} />
          </div >
        </div >
      </section >
    )
  } else {
    return <></>;
  }
}
