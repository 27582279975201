import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler.js';
import GamePage404 from '../GamePage404/index.js';
import {
    MoveToNextPage,
    checkGameEmail,
    fnConnectRedirectTodetailPage,
    fnInputCheckBoxClick,
    fnRedirectToConnectLogin,
    gameFormValidate
} from '../Js-CommonFunctions.js';

export default function Game({ fields }) {
    var userEmail = useSelector((state) => state.user.email)
    let isWifit = "";
    let isWelcomeJourney = "";

    if (typeof window !== "undefined") {
        const query = new URLSearchParams(window.location.search);
        isWifit = query.get('isWifit') == null ? "" : query.get('isWifit');
        isWelcomeJourney = query.get('isWelcomeJourney') == null ? "" : query.get('isWelcomeJourney');
    }

    useEffect(() => {
        if (fields !== null) {
            window.Segments = {};
            window.currentPageId = "welcomePage";
            window.customerGameEmail = fields._currentGameToDisplay?.userEmail;
            window.centerId = baseModel.centerId;
            window.gameId = fields._currentGameToDisplay?.id;
            window.storeTitle = fields._currentGameToDisplay?.storesPageTitle;
            window.emptyStoreTitle = fields._currentGameToDisplay?.emptyStoresPageTitle;
            window.gameName = fields._currentGameToDisplay?.gameName;
            window.userEmail = userEmail;
            fnInputCheckBoxClick();
        }
    }, []);

    if (fields !== null && fields !== "" && fields !== undefined && fields.props !== undefined) {
        var model = fields._currentGameToDisplay;
        var props = fields.props;
        return (
            <>
                {model !== null &&
                    <>
                        {props.isNewBrandedSite ?
                            (
                                <div className="preloader d-none">
                                    <img src={props.nonBrandedProgressImage} alt="loader" />
                                    <div className="loader-label">{props.gamePageLoaderText}</div>
                                </div>
                            ) :
                            (
                                <div className="preloader d-none">
                                    <img src={props.brandedProgressImage} alt="loader" />
                                    <div className="loader-label">{props.gamePageLoaderText}</div>
                                </div>
                            )
                        }
                        <div className="game-header d-none" id="gameHeader">
                            <div className="container d-flex justify-space-between align-items-center game-progress-block">
                                <div className="logo"><img src={model.logo?.value !== null ? model.logo?.value?.src : ""} alt="" /></div>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" id="progressbar" style={{ width: "33%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="oval">
                                    <span className="picto icon-gift" id="icongift"></span>
                                </div>
                            </div>
                        </div>
                        {/* Page 0 */}
                        <div className="container-fluid lbe home position-absolute">
                            <div className="container global-form">
                                <div className="app-lbe-container border-8">
                                    <div className="logo text-center"><img src={model.logo?.value !== null ? model.logo?.value?.src : ""} alt="" /></div>
                                    <div className="catchphrase-container">
                                        {model.catchPhrase}
                                    </div>
                                    <form id="form-game">
                                        <div className="form-group">
                                            <label>{props.emailGamePlaceholder} * </label>
                                            <input className="form-control" type="email" placeholder={props.emailGamePlaceholder + " *"}
                                                name="customerGameEmail" id="customerGameEmail" required="required" defaultValue={(props.email != "" ? props.email : null)} />
                                            <label id="emailCheck-error" className="error display-none" data-validatormsg1={props.validateEmail}
                                                data-validatormsg2={props.validationValidateEmail}
                                                data-validatormsg3={props.validateLoginFailedUnknownEmail + " "}
                                                data-validatormsg4=" api error something went wrong "
                                                htmlFor=" emailcheck">
                                            </label>
                                            <a aria-label={props?.registerNowText} id="game-registernow" href="javascript:void(0)"
                                                onClick={(e) => { fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRegisterUrl); }}
                                                style={{ display: "none" }}>
                                                {props.registerNowText}
                                            </a>
                                            <input type="hidden" id="isContestGame" name="isContestGame" value={model.isContestGame.toString()} />
                                        </div>
                                        <div className="form-group input-checkbox">
                                            {model.isContestGame &&
                                                <><input className="form-control" type="checkbox" name="game-rules-checkbox" id="gameRules" />
                                                    <label htmlFor="gameRules">
                                                        <a href={model.gameRulesLink?.value != null ? model.gameRulesLink.value.url : "#"} aria-label={model.gameRulesLink} target="_blank" rel="noreferrer">
                                                            {props.gameOptinText}
                                                        </a>*
                                                        <span className="picto icon-stop-rw"></span>
                                                    </label></>
                                            }
                                            <label id="requiredOptinCheck-error" className="error display-none"
                                                data-validatormsg1={props.validateAcceptPolicy}
                                                htmlFor="requiredOptinCheck">
                                            </label>
                                        </div>
                                    </form>
                                    {model.isContestGame &&
                                        <div className="form-group game-rules">
                                            {(model.gameRulesExtract !== null && model.gameRulesExtract !== "" && model.gameRulesExtractLess == null || model.gameRulesExtractLess == "") &&
                                                <p>
                                                    {model.gameRulesExtract}
                                                </p>
                                            }
                                            {(model.gameRulesExtractLess != null && model.gameRulesExtractLess != "") &&
                                                <><p className="gamerules-extract-displayed" style={{ display: "block" }}>
                                                    {model.gameRulesExtractLess}
                                                </p>
                                                    <p className="gamerules-extract-to-hide" style={{ display: "none" }}>
                                                        {model.gameRulesExtract}
                                                    </p>
                                                    <a href="javascript:void(0)" aria-label={model.gameRulesExtract} className="game-read-more-trigger" id="game-read-more-trigger">{props.showMore}</a>
                                                    <a href="javascript:void(0)" aria-label={model.gameRulesExtract} className="game-read-less-trigger" id="game-read-less-trigger" style={{ display: "none" }}>{props.showLess}</a>
                                                </>
                                            }
                                        </div>
                                    }
                                    <div className="btn-submit btn-sticky text-center">
                                        <button className="btn btn-dark" type="submit" data-toggle="modal" data-target="#popinAccountRegistered" onClick={(e) => { if (gameFormValidate()) { checkGameEmail(isWifit, isWelcomeJourney, props.visitorId, props.email) } }}>
                                            {props.startGameCtaText}
                                        </button>
                                    </div>
                                    <span className="mentions">* {props.gameMandatoryFieldsText}</span>
                                    <span className="specificity">{props.gameWarningOnlyCustomerCanPlay}</span>
                                </div>
                            </div>
                        </div>

                        {/* Pages Segments */}
                        <div className="container-fluid page-question page1--active" hidden>
                            <div className="container container-question page1--active">
                                {/* Segments selection page 1 */}
                                <div id="segmentsSelectionPage1" style={{ display: "none" }}>
                                    <div className="container-question__header">
                                        {props.isNewBrandedSite ?
                                            (
                                                <><h3 className="">{model.segmentsPage1Title}</h3>
                                                    <div className="information">
                                                        {props.gameSegmentPage1SubTitle}
                                                    </div></>
                                            ) :
                                            (
                                                <><h3>{model.segmentsPage1Title}</h3>
                                                    <div className="information">
                                                        {props.gameSegmentPage1SubTitle}
                                                    </div></>
                                            )
                                        }
                                    </div>
                                    <div className="categorie-liste">
                                        <div className='row'>
                                            {model.segmentlist != null &&
                                                <>
                                                    {model.segmentlist.map((segment, index) => {
                                                        var index = (segment.position - 1);
                                                        if (index < 0) {
                                                            index = 0;
                                                        }
                                                        if (index < 6) {
                                                            if (index % 2 == 0) {
                                                                let colLimit = 0;
                                                                var arr = [];
                                                                while (colLimit < 2) {
                                                                    arr.push(
                                                                        <><div className='col-6 col-lg-4'>
                                                                            <div className="categorie-liste__image border-8" style={{ backgroundImage: "url(" + model.segmentlist[index + colLimit].image.value?.src + ")" }}>
                                                                                <div className="categorie-liste__cta categorie-liste__cta-hover" data-category-id={model.segmentlist[index + colLimit].id}
                                                                                    data-category-name={model.segmentlist[index + colLimit].label} title={model.segmentlist[index + colLimit].label}>
                                                                                    <span>
                                                                                        {model.segmentlist[index + colLimit].label}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            <input type="hidden" value={colLimit++} /></>
                                                                    )
                                                                }
                                                                return arr
                                                            }
                                                        }
                                                    })}
                                                </>
                                            }
                                        </div>
                                        <div className="pager-nav">
                                            <div className="btn-submit btn-sticky">
                                                <button className={"btn btn-dark btn-next " + (props.isNewBrandedSite ? "" : " text-uppercase")}>{props.gameSegmentPage1CtaText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Segments selection page 2 */}
                                <div id="segmentsSelectionPage2" style={{ display: "none" }}>
                                    <div className="container-question__header">
                                        {props.isNewBrandedSite ?
                                            (
                                                <><h3 className="">{model.segmentsPage2Title}</h3>
                                                    <div className="information">
                                                        {props.gameSegmentPage2SubTitle}
                                                    </div></>
                                            ) :
                                            (
                                                <><h3>{model.segmentsPage2Title}</h3>
                                                    <div className="information">
                                                        {props.gameSegmentPage2SubTitle}
                                                    </div></>
                                            )
                                        }
                                    </div>
                                    <div className="categorie-liste">
                                        <div className='row'>
                                            {model.segmentlist != null &&
                                                <>
                                                    {model.segmentlist.map((segment, index) => {
                                                        var index = (segment.position - 1);
                                                        if (index < 0) {
                                                            index = 0;
                                                        }
                                                        if (index > 5 && index < 11) {
                                                            if (index % 2 == 0) {
                                                                var arr = [];
                                                                let col = 0;

                                                                while (col < 2) {
                                                                    arr.push(
                                                                        <><div className='col-6 col-lg-4'>
                                                                            <div className="categorie-liste__image border-8" style={{ backgroundImage: "url(" + model.segmentlist[index + col].image?.value?.src + ")" }}>
                                                                                <div className="categorie-liste__cta categorie-liste__cta-hover" data-category-id={model.segmentlist[index + col].id}
                                                                                    data-category-name={model.segmentlist[index + col].label} title={model.segmentlist[index + col].label}>
                                                                                    <span>
                                                                                        {model.segmentlist[index + col].label}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                            <input type="hidden" value={col++} /></>
                                                                    )
                                                                }
                                                                return arr
                                                            }
                                                        }
                                                    })}
                                                </>
                                            }
                                        </div>
                                        <div className="pager-nav">
                                            <div className="btn-submit btn-sticky">
                                                <button className={"btn btn-dark btn-next " + (props.isNewBrandedSite ? "" : " text-uppercase")} disabled>{props.gameSegmentPage3CtaText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Would you like to continue page 4 */}
                        <div id="IncreaseChancePage" style={{ display: "none" }}>
                            <div className="container-fluid page-confirmation">
                                <div className="container">
                                    <div className="container-merci border-8">
                                        <div className="logo text-center">
                                            <img src={model.logo?.value !== null ? model.logo?.value?.src : ""} alt="" />
                                        </div>
                                        <div className="container-merci__textafter catchphrase-container merci">
                                            <div id="increaseChancePhrase">{model.increaseChancePageText}</div>
                                        </div>
                                        <div className="btn-submit btn-sticky text-center">
                                            <button className={"btn btn-dark btn-increase " + (props.isNewBrandedSite ? "" : " text-uppercase")} type="submit">{model.increaseChancePageCtaText}</button>
                                        </div>
                                        <div className="container-merci__skip text-center">
                                            {props.isNewBrandedSite ?
                                                (
                                                    <a href='#' aria-label={props.skipStorePageLinkText} className="skip" onClick={(e) => { MoveToNextPage('IncreaseChancePage', true) }}>{props.skipStorePageLinkText}</a>
                                                ) :
                                                (
                                                    <a href='#' aria-label={props.skipStorePageLinkText} onClick={(e) => { MoveToNextPage('IncreaseChancePage', true) }} style={{ color: "black" }}>{props.skipStorePageLinkText}</a>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Stores selection page 5 */}
                        <div id="storesSelectionPage" style={{ display: "none" }}>
                        </div>

                        {/* Thanks-ending page 6 */}
                        <div id="finalPage" style={{ display: "none" }}>
                            <div className="container-fluid page-confirmation">
                                <div className="container">
                                    <div className="container-bravo">
                                        <div className="logo text-center">
                                            <img src={model.logo?.value !== null ? model.logo?.value?.src : ""} alt="" />
                                        </div>
                                        <div className="container-bravo__textafter catchphrase-container bravo">
                                            <div id="finalPageText">{model.finalPageText}</div>
                                        </div>
                                        <div className="btn-submit btn-sticky text-center">
                                            <a aria-label={props?.finalPageCtaText} className={"btn btn-dark " + (props.isNewBrandedSite ? "" : " ")}
                                                value={props.finalPageCtaText}
                                                href={model.finalPageCtaLink}>
                                                {props.finalPageCtaText}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
    else if (fields !== null && fields !== "" && fields !== undefined && fields.gamePage404 !== null && fields.gamePage404 !== undefined && fields.gamePage404 !== "") {

        return <GamePage404 fields={fields} />;
    }
    else {
        return <></>;
    }
}
