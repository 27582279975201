import React, { useEffect } from "react";
import { global } from '../../RouteHandler';
import { floorCenterMapDataPush } from '../Js-CommonFunctions';


export default function VisioGlobe({ fields }) {
    let isAccessPage = false;

    if (fields !== undefined && fields !== null && fields !== "") {
        var visioGlobeModel = fields.visioGlobeModel;
        var model = fields.model;
        
        isAccessPage=model.isAccessPage;
        var strContextPageName = (fields?.model?.strContextPageName !== null && fields?.model?.strContextPageName !== undefined) ? (fields.model.strContextPageName) : ("");
        var templateType = (strContextPageName === "news-detail") ? "News Detail" : (
            (strContextPageName === "store") ? "Store Detail" : (
                (strContextPageName === "cinema") ? "Cinema" : (
                    (strContextPageName === "restaurant") ? "Restaurant Detail" : (
                        (strContextPageName === "access") ? "Access" : ("")
                    )

                )
            )
        );
    }
    useEffect(() => {
        if (visioGlobeModel !== undefined && visioGlobeModel !== null && visioGlobeModel !== "") {
            var jsonStoreList = JSON.stringify(visioGlobeModel.storeList);
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = "var mapURL='" + visioGlobeModel.mapUrl + "';var showSidebar =false;var visioGlobeStoreId='" + visioGlobeModel.visioGlobeId + "';var visioGlobeStoreList=" + jsonStoreList + ";var logoUrl='" + visioGlobeModel.imageUrl + "';var viewParams={cameraRadius: 395,floorName:'" +
                visioGlobeModel.floorName + "',initialX:'" + visioGlobeModel.initialX + "',initialY:'" + visioGlobeModel.initialY + "',cameraType: 'orthographic', maxRadius: " + visioGlobeModel.maxRadius + "};var assetServerUrl='" + fields.model.assetServerUrl + "';var jssCssVersion='" + fields.model.jssCssVersion + "';";
            document.head.appendChild(s);
        }
        setTimeout(function () { loadVgScript(); }, 1000);

        function loadVgScript() {
            const scriptTag = document.createElement('script');
            scriptTag.src = global.assetServerUrl + '/dist/newunibailreactapp/js/vgScripts.min.js?version=' + global.jssCssVersion;
            scriptTag.addEventListener('load', () => { });
            document.body.appendChild(scriptTag);
        }

        var floorchangeInterval;
        if (document.querySelectorAll(".change_floor").length <= 0) {
            floorchangeInterval = setInterval(onClickChangeFloor(templateType), 1000);
        }
        else {
            clearInterval(floorchangeInterval);
            floorchangeInterval = null;
        }

    }, []);

    if ((visioGlobeModel !== undefined && visioGlobeModel !== null && visioGlobeModel.mapUrl != null && visioGlobeModel.mapUrl != "") && (((model.isGenericTemplate || isAccessPage) && model.isAccessPageExist) || (visioGlobeModel !== undefined && visioGlobeModel !== null && visioGlobeModel.visioGlobeId != null && visioGlobeModel.visioGlobeId != ""))) {
        return (
            <>
                <section>
                    <div className="container" id="mapofthemall">
                        {!isAccessPage && model.isAccessPageExist ?
                            (
                                <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
                                    <a className="content-title  gtmClickNavigation" aria-label={visioGlobeModel?.mapofthemall} href={model.urlPrefix + model.slash + model.accessPage}>
                                        {(visioGlobeModel.mapofthemall != null && visioGlobeModel.mapofthemall != "") &&
                                            <h2>{visioGlobeModel.mapofthemall}</h2>
                                        }
                                    </a>
                                    {/* {!model.isNewBrandedSite &&
                                        <a className="action gtmClickNavigation" aria-label={visioGlobeModel?.accessToCenter} href={model.urlPrefix + model.slash + model.accessPage}><strong>{visioGlobeModel.accessToCenter}</strong> <span className="picto icon-ico-chevron-right"></span></a>
                                    } */}
                                </div>
                            ) :
                            (
                                <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
                                    <div className="content-title">
                                        {(visioGlobeModel.mapofthemall != null && visioGlobeModel.mapofthemall != "") &&
                                            <h2>{visioGlobeModel.mapofthemall}</h2>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className="access-center">
                            <div id="vg_mapviewer" className="visioglobe">
                                <div id="vg_footer" className="noselect vg_footer bg-transparent">
                                    <div className="pull-right" id="route_container" style={{ display: "none" }}>
                                        <button id="route_clear" className="vg_button">
                                            <span id="route_clear_img"></span>
                                            <span id="route_clear_label">Clear Route</span>
                                        </button>
                                    </div>
                                    <div className="pull-left floor_container  pb-3" id="floor_container">
                                        <div id="change_floor"></div>
                                    </div>
                                </div>
                                <div id="mapContainer" className="leaflet-container leaflet-fade-anim" >
                                </div>
                                <div id="progress">
                                    <input value="0" data-width="150" data-readonly="true" data-thickness=".2" data-fgcolor="#00afcf" data-bgcolor="#292c2e" className="knob" readOnly="readonly" />
                                </div>
                                <div id="instructions" className="instructions hidden">
                                    <div id="vg_statusbar">
                                        <div className="info">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button id="zoomIn" className={ "picto icon-plus-big" }>
                                        {/* {!model.isNewBrandedSite &&
                                            visioGlobeModel.zoomin
                                        } */}
                                    </button>
                                    <button id="zoomOut" className={ "picto icon-minus" }>
                                        {/* {!model.isNewBrandedSite &&
                                            visioGlobeModel.zoomout
                                        } */}
                                    </button>
                                </div>
                            </div>
                            <div id="poiContainer" className="hidden"></div>
                            <div style={{ display: "none" }}>
                                <div id="place_bubble">
                                    <div id="place_bubble_title">Title</div>
                                    <div id="place_bubble_close_button"></div>
                                    <div id="place_bubble_info_button"></div>
                                    <button id="place_bubble_set_origin" className="vg_button route_choice_button" data-direction="src" type="button">Set Origin</button>
                                    <button id="place_bubble_set_waypoint" className="vg_button route_choice_button" data-direction="waypoint" type="button">Set Waypoint</button>
                                    <button id="place_bubble_set_destination" className="vg_button route_choice_button" data-direction="dst" type="button">Set Destination</button>
                                </div>
                            </div>
                            {/* {model.isNewBrandedSite &&
                            <hr className="mt-5 mb-0" />
                        } */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
    else {
        return (<></>)
    }
}

function onClickChangeFloor(templateType) {
    var myInterval = setInterval(() => {
        var floorEle = document.querySelectorAll(".change_floor");
        floorEle.forEach((x) => {
            var targetFloorID = x.getAttribute("data-floor").replace("B1-", "");
            x.onclick = function () {
                floorCenterMapDataPush(targetFloorID, templateType);
            }
            clearInterval(myInterval);
            myInterval = null;
        });
    }, 1000);
}
