import React from "react";
import { checkWebViewEqualsOne } from "../Js-CommonFunctions";

export default function Breadcrumb({ fields }) {
  var hasWebviewEqualsOne = checkWebViewEqualsOne();

  if (fields !== null && fields !== undefined && fields !== "" && fields.breadCrumb !== null && fields.breadCrumb !== "" && fields.breadCrumb !== undefined && fields.breadCrumb.breadcrumbList !== null && !hasWebviewEqualsOne) {
    return (<>
      <div className={"breadcrumb-list border-0" + (fields.breadCrumb.isLoyaltyPage ? " anchor-navigation-loyalty-bg" : "")}>
        <div className={"container"}>
          <ol className={"breadcrumb"}>
            {fields.breadCrumb.breadcrumbList.map((breadcrump, index) => {
              {
                return (
                  <>
                    {(breadcrump.url != null && breadcrump.url != "") ?
                      (
                        <>
                          {breadcrump.url == "#" ?
                            (
                              <li key={index} className="breadcrumb-item">
                                <span>{breadcrump.title}</span>
                              </li>
                            ) :
                            (
                              <li key={index} className="breadcrumb-item">
                                <a href={breadcrump.url} aria-label={breadcrump.title} >{breadcrump.title}</a>
                              </li>
                            )
                          }
                        </>
                      )
                      :
                      (<li key={index} className="breadcrumb-item  active">
                        <span>{breadcrump.title}</span>
                      </li>
                      )}
                  </>
                );
              }
            }
            )}
          </ol>
          <a className={"ellipses"} href="#">...</a>
        </div>
      </div>
    </>)
  } else {
    return <></>;
  }
}
