import React from 'react';
import { Text } from "@sitecore-jss/sitecore-jss-react";

export default function OpinionBlock({ fields, isWestfield }) {

  function opinionWidget(isWestfield) {
    return GoodaysSDK.open("modal:send_feedback", fields.pcCode, {
      "tag": (isWestfield ? "loyalty-survey-westfield" : "loyalty-survey-unbranded"),
      "mode": "quiz",
      "user": (fields.visitorData !== null && fields.visitorData !== "" ? fields.visitorData : "")
    });
  };

  if (fields !== undefined && fields !== null && fields !== "") {
    return (
      <>
        {
          (fields !== undefined && fields !== null && fields !== "" &&
            fields.opinionData?.title?.value !== undefined && fields.opinionData?.title?.value !== null && fields.opinionData?.title?.value !== "") &&
          <div className="multi-preferred-shopping-center">
            {/* <RichText field={fields.opinionWidgetScript} tag="script" /> */}

            <div className="col-12 preferred-sc-box bg-white text-center">
              <div className="d-flex my-3 smart-parking-title text-black align-items-center justify-content-center">
                <img src={fields.opinionData.image?.value?.src} alt="red-smile-image" title=""
                  className="card-icon-img"></img>
              </div>
              <div className="my-opinion-title mt-3">{fields.opinionData.title.value}</div>
              <div className="my-opinion-desc m-3"><Text field={fields.opinionData.description} /></div>
              {fields.opinionData.link?.value?.url !== undefined && fields.opinionData.link?.value?.url !== null && fields.opinionData.link?.value?.url !== "" &&
                <div className="align-items-center d-flex justify-content-center ">
                  <a id="opinionwidget" className="d-flex justify-content-center my-opinion-button account-discover-button goodays-widget text-decoration-none" href="javascript:void(0);"
                    data-type="store_button:fact" onClick={() => { opinionWidget(isWestfield) }}
                  >
                    {fields.opinionData.link?.value?.text}</a>
                </div>
              }

            </div>
          </div>
        }
      </>
    );

  }
  else {
    return <></>;
  }

}
