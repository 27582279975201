import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';


export default function Ph_VisioGlobe({ rendering }) {
  if (rendering) {
    return (
      <section>
        <div className="container">
          <Placeholder name="ph_visioglobe" rendering={rendering} />
        </div>
      </section>
    )

  } 
  else {
    return <></>;
  }

}