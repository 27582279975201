import React from "react";
import {  RichText } from "@sitecore-jss/sitecore-jss-react";


export default function QueueManagementInfo({ fields }) {
  if (fields?.model !== null && fields?.model?.isQueueEnabled) {
    var model = fields.model;
    var desc = { value: model.queueManagementDesc };
    return (
      <section className="access-center" id="queuemanagement">
        <div className="container">
          <h2 className="content-title text-uppercase text-center underline">
            {model.queueManagementTitle}
          </h2>
          <div className="row">
            <div className="col-md-12 text-center mb-3">
              <div className="description mb-3 text-center">
                <RichText field={desc} />
              </div>
              <div className="btn-wrap flex-md-nowrap justify-content-center">
                {model.iosUrl.trim() !== "" && (
                  <a
                    href={model.iosUrl}
                    target={model.iosTarget}
                    className="link-button text-uppercase btn-dark mb-3 mb-md-0 me-md-3 me-3"
                  >
                    {model.iosLabel}
                  </a>
                )}
                {model.androidUrl.trim() !== "" && (
                  <a
                    href={model.androidUrl}
                    target={model.androidTarget}
                    className="link-button text-uppercase btn-dark"
                  >
                    {model.androidLabel}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
