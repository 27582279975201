import Enumerable from "linq";
import React, { useEffect, useState } from "react";
import { checkWebViewEqualsOne, createCookie, fngtmModuleclick, fngtmVpvComponent, isMobile, readCookie } from '../Js-CommonFunctions';
import { dragElement } from "../liveShopping";

export default function LiveShoppingComponent({ fields }) {
  const [liveShoppingevent, setliveShoppingevent] = useState(fields.videoStream.videoList);
  const [hasLiveEvent, sethasLiveEvent] = useState(false);
  const [showPIP, setshowPIP] = useState(false);
  const [showLiveNow, setshowLiveNow] = useState(false);
  var hasCTAFullScreen = false;
  let isPastEvent = false;
  var hasWebviewEqualsOne = checkWebViewEqualsOne();

  useEffect(() => {
    if (!hasWebviewEqualsOne && fields.videoStream !== null && fields.videoStream !== "" && fields.videoStream !== undefined &&
      fields.videoStream.videoList !== null && fields.videoStream.videoList?.length !== null
      && fields.videoStream.videoList?.length !== undefined && fields.videoStream.videoList?.length > 0) {
      var list = Array.from(fields.videoStream.videoList);
      for (var i = 0; i < list.length; i++) {
        if (!readCookie(list[i].cookieName)) {
          var finishAtTime = new Date(list[i].finishesAt);
          var startAtTime = new Date(list[i].startsAt);
          var localeDateTime = new Date();
          if (startAtTime.getTime() <= localeDateTime.getTime() && finishAtTime.getTime() >= localeDateTime.getTime()) {
            setliveShoppingevent(list[i]);
            sethasLiveEvent(true);
            setshowPIP(true);
            isPastEvent = false;
            setshowLiveNow(true);
            break;
          }
          else {
            isPastEvent = true;
          }
        }
        else if (readCookie(list[i].cookieName)) {
          isPastEvent = false;
          setshowPIP(false);
          sethasLiveEvent(false);
          break;
        }

      }
      if (isPastEvent) {
        var pastList = Enumerable.from(fields.videoStream.videoList).orderByDescending(x => new Date(x.finishesAt).getTime()).where(x => (x.status != "unpublished" && x.status != "pending" && new Date(x.finishesAt).getTime() <= localeDateTime.getTime())).toArray();
        var pList = Array.from(pastList);
        if (pList.length > 0) {
          if (!readCookie(pList[0].cookieName)) {
            setliveShoppingevent(pastList[0]);
            sethasLiveEvent(true);
            setshowPIP(true);
            setshowLiveNow(false);
          }
        }
        else {
          setshowPIP(false);
        }
      }
    }
  }, []);


  useEffect(() => {
    if (!hasWebviewEqualsOne) {
      if (document.querySelector('#pipBlock') != null && document.querySelector('#pipBlock') != undefined) {
        const reload = document.querySelector('#pipBlock');
        reload.addEventListener('fw:storyblock:impression', function (event) {
          fngtmVpvComponent(liveShoppingevent, "extra_live_video_view");

          reload.addEventListener('fw:video:start', function (event) {
            fngtmVpvComponent(liveShoppingevent, "extra_live_video_start");
          })

          reload.addEventListener('fw:player:transition', function (event) {
            if (event !== null && event !== undefined && event !== "" && event.detail !== null && event.detail !== "" && event.detail !== undefined && !hasCTAFullScreen && event.detail.transitionTo === "fullscreen") {
              fngtmVpvComponent(liveShoppingevent, "click_enlarge_player");
            }
          })
        })
        reload.addEventListener('fw:video:click-cta', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "click-cta");
        })

        reload.addEventListener('fw:video:click-share', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "click-share");
        })

        reload.addEventListener('fw:video:share', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "share");
        })

        reload.addEventListener('fw:video:submit-answer', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "submit-answer");
        })

        reload.addEventListener('fw:video:submit-choice', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "submit-choice");
        })

        reload.addEventListener('fw:player:quit', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "close-button");
        })
        reload.addEventListener('fw:shopping:product-clicked', function (event) {
          fngtmVpvComponent(liveShoppingevent, "live_interaction", "product_click_link");
        })
      }

      if (!isMobile.any()) {
        if (document.querySelector(".live-module")) {
          dragElement(document.querySelector(".live-module"));
        }
      }

      if (fields.videoStream !== null && fields.videoStream !== "" && fields.videoStream !== undefined
        && fields.videoStream.isLiveEventEnabled !== null && fields.videoStream.isLiveEventEnabled === true) {
        const js = document.createElement("script");
        js.type = "text/javascript";
        js.src = "https://asset.fwcdn3.com/js/fwn.js";
        js.setAttribute("async", "");
        document.head.appendChild(js);
      }
    }
  }, [liveShoppingevent, hasLiveEvent]);

  function fnAddVideoStreamCookie(name, days) {
    if (name !== undefined && name !== null && name !== "") {
      createCookie(name, "true", days);
      var liveShoppingEle = document.getElementById("liveShoppingModule");
      if (liveShoppingEle)
        liveShoppingEle.classList.add("d-none");
      var tagEle = document.getElementsByName("pip-storyblock")[0];
      if (tagEle !== undefined && tagEle !== null && tagEle !== "")
        tagEle.remove();
    }
  }

  if (hasLiveEvent) { fngtmVpvComponent(liveShoppingevent, "view_global_modal_component") }


  const fnPIPPlayer = () => {
    hasCTAFullScreen = true;
    window._fwn?.players["pip-storyblock"].fullscreen()
  }


  if (fields.videoStream !== null && fields.videoStream !== "" && fields.videoStream !== undefined && !hasWebviewEqualsOne
    && fields.videoStream.isLiveEventEnabled !== null && fields.videoStream.isLiveEventEnabled === true
    && fields.videoStream.isPopUpEnabled !== null && fields.videoStream.isPopUpEnabled === true
    && liveShoppingevent !== null && liveShoppingevent !== "" && liveShoppingevent !== undefined && showPIP) {
    return (

      <div className="live-module" id="liveShoppingModule">
        <a href="javascript:void(0);" data-testid="fnAddVideoStreamCookie" aria-label="liveShoppingModule" onClick={(e) => { e.preventDefault(); fnAddVideoStreamCookie(liveShoppingevent.cookieName, liveShoppingevent.cookieExpiry) }} className="close px-1" ></a>
        <div className="overlay-pip overlay-live">
          {(showLiveNow) ?
            <div className="live-now">{fields.videoStream.liveNow}</div> : <></>}
          <span className="title-pip title-live">{liveShoppingevent.name}</span>
          <div className="d-flex align-items-baseline">
            <a href="javascript:void(0);" aria-label="liveShoppingEvent" data-testid="fngtmVpvComponent"
              onClick={(e) => { e.preventDefault(); fnPIPPlayer(); fngtmVpvComponent(liveShoppingevent, "click_watch_now") }}
              className="btn-watch-pip">{fields.videoStream.watchNow}</a>
            <a href={fields.videoStream.urlPrefix + liveShoppingevent.liveEventDetailPagePath}
              aria-label="LiveEvent-detail" data-testid="fngtmModuleclick"
              onClick={() => fngtmModuleclick(liveShoppingevent, "click_see_details", "Pip")}
              className={"details"} >{fields.videoStream.seeDetails}</a>
          </div>
        </div>
        <div className="live-screen">
          <fw-storyblock
            id="pipBlock"
            name="pip-storyblock"
            channel={liveShoppingevent.channelId}
            video={liveShoppingevent.videoId}
            autoplay="true"
            max_videos="1"
            branding="false"
            player_minimize="false"
            height="352px"
            loop="false"></fw-storyblock>
        </div>
      </div>

    );

  }
  else {
    return <></>;
  }
}



