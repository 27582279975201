import axios from 'axios';
import React, { useEffect, useState } from "react";
import { CommonBookmarkallpage, lazyLoadingImg, replaceQueryString, webViewEqualsOne } from '../Js-CommonFunctions';
import { baseModel } from '../../RouteHandler';
import { useSelector } from 'react-redux';

export default function OffersAndnewsInnerPage({ newsData }) {
  const [model, setModel] = useState();
  let workerCenter = useSelector((state) => state.user.workerCenter);
  let isWorker = false;

  useEffect(() => {
    if (newsData) {
      isWorker =
        workerCenter !== undefined &&
        workerCenter !== '' &&
        workerCenter.split(',').includes(baseModel.pC_Code);
      if (isWorker) {
        var loader = document.getElementById("offersnewsloader")

        if (loader) {
          loader.classList.remove("invisible");
          loader.classList.add("visible");
        }
        var url = '/api/sitecore/NewsApi/GetOffersAndNews';
        const newsUrl = newsData.urlPrefix + "/" + newsData.whatsHotPage;
        const headingLeftLink = newsData.headingLeftLink !== null && newsData.headingLeftLink !== "" ? newsData.headingLeftLink : (newsData.isNewBrandedSite ? newsUrl : newsUrl + "/periodicity/Today");;
        const headingRightLink = (newsData.headingRightLink !== "" && newsData.headingRightLink !== null) ? newsData.headingRightLink : newsData.urlPrefix + "/" + newsData.whatsHotPage;     
        axios({
          method: 'post',
          url: url,
          data: {
            ursite: baseModel.urContextSite,
            urlang: baseModel.contextLang,
            itemId: newsData.itemSource,
            pageNo: 1,
            isNews: newsData.isNews,
            isTopNews: newsData.isTopNews,
            isOffer: newsData.isOffers,
            strNewsDatasource: newsData.newsDatasource,
            strOfferDatasource: newsData.offerDatasource,
            isArticles: newsData.isArticles,
            isTopArticles: newsData.isTopArticles,
            currentPage: newsData.currentPage,
            leftLink: headingLeftLink,
            rightLink: headingRightLink,
            isWorker: isWorker
          },
          async: false
        }).then(function (response) {
          if (loader) {
            loader.classList.add("invisible");
            loader.classList.remove("visible");
          }
          if (response.data !== '')          
          setModel(response.data);
          webViewEqualsOne();
          // CommonBookmarkallpage();
        })
          .catch(function (error) {
            if (loader) {
              loader.classList.add("invisible");
              loader.classList.remove("visible");
            }
            console.log(error);
          });
      }

      else {
        setModel(newsData)
      }
    }
  }, []);

  useEffect(() => {
    CommonBookmarkallpage();
    lazyLoadingImg();
  }, [newsData]);

  function offersViewMore(url) {
    var loader = document.getElementById("offersnewsloader")

    if (loader) {
      loader.classList.remove("invisible");
      loader.classList.add("visible");
    }
    var viewcount = parseInt(document.getElementById("offernewspageno").value) + 1;
    document.getElementById("offernewspageno").value = viewcount;
    url = replaceQueryString(url, 'pageNo', viewcount);
    axios({
      method: 'get',
      url: url,
      async: false
    }).then(function (response) {
      if (loader) {
        loader.classList.add("invisible");
        loader.classList.remove("visible");
      }
      setModel(response.data)

      webViewEqualsOne();
      CommonBookmarkallpage();
    })
      .catch(function (error) {
        if (loader) {
          loader.classList.add("invisible");
          loader.classList.remove("visible");
        }
        console.log(error);
      });

  }

  let isFromSpliceCon = false;
  var currentPage = model != null && model.currentPage != null ? model.currentPage : "";

  if (model && model.newsOffersIntegratedItems !== null && model.newsOffersIntegratedItems.length > 0) {
    let picright = false;
    let withPicsCount = 0;
    let indexcnt = 0;
    let contentTextClass;
    let contentBgClass;
    let contentLinkClass;
    let isNewsPage = model.isNewsPage;
    const newsUrl = model.urlPrefix + "/" + model.whatsHotPage;
    const urlPrefix = model.urlPrefix;


    const headingLeftLink = model.headingLeftLink !== null && model.headingLeftLink !== "" ? model.headingLeftLink : (model.isNewBrandedSite ? newsUrl : newsUrl + "/periodicity/Today");;
    const headingRightLink = (model.headingRightLink !== "" && model.headingRightLink !== null) ? model.headingRightLink : model.urlPrefix + "/" + model.whatsHotPage;
    let workervalue =
      workerCenter !== undefined &&
      workerCenter !== '' &&
      workerCenter.split(',').includes(baseModel.pC_Code);
    var url =
      '/api/sitecore/NewsApi/GetOffersAndNews?ursite=' +
      model.urContextSite +
      '&itemId=' +
      model.itemSource +
      '&pageNo=' +
      1 +
      '&isNews=' +
      model.isNews +
      '&isTopNews=' +
      model.isTopNews +
      '&isOffer=' +
      model.isOffers +
      '&strNewsDatasource=' +
      model.newsDatasource +
      '&strOfferDatasource=' +
      model.offerDatasource +
      '&isArticles=' +
      model.isArticles +
      '&isTopArticles=' +
      model.isTopArticles +
      '&currentPage=' +
      currentPage +
      '&urlang=' +
      model.contextLanguage +
      '&leftLink=' +
      headingLeftLink +
      '&rightLink=' +
      headingRightLink +
      '&isWorker=' +
      workervalue
    return (
      <section className={"whats-hot" + (model.isNewBrandedSite ? " back-gray p-2" : "")} id="news" >
        <div className={"container" + (model.isNewBrandedSite ? " " : "")}>
          {(model.newsOffersIntegratedItems !== null && model.newsOffersIntegratedItems.length > 0) &&
            <>
              {NotnewsPageCondition(model, isNewsPage, headingLeftLink, headingRightLink)}
              <div className="row">

                {model.newsOffersIntegratedItems.map((item, index) => {

                  indexcnt++;

                  if (item.imageUrl !== "" && item.imageUrl !== null) {
                    const picClass = !picright ? "right" : "left";
                    contentTextClass = !picright ? "left" : "right";
                    contentBgClass = !picright ? "bg-lighter" : "bg-main";
                    //const contentArrowClass = !picright ? " arrow-right" : "arrow-left";
                    picright = !picright;
                    withPicsCount++;

                    return (
                      <div key={index} className="col-sm-12 item picture-and-text">
                        <div className={"position-relative whats-hot-item whats-hot-picture whats-hot-mobile-image " + (item.hasVideo ? "play-icon " : "") + (picClass) + (model.isNewBrandedSite ? " bg-lighter" : " bg-light")}>
                          <a href={urlPrefix + "/" + item.detailsPageUrl} aria-label="news-detail" className="position-absolute w-100 h-100 block-link"></a>
                          <img src={item.itemImageUrl}  loading="lazy"
                          alt={item.imageAlt} title={item.imageAlt} />
                          <div className="bookmark-wrap d-sm-block d-lg-none">
                            <a className={"open-popin add-wishlist ava-wishlist bookmark-news-new bookmark picto icon-heart-unfill-rw"}
                              href="javascript:void(0);" role="button" aria-label={item.iddata} aria-labelledby={item.isOffer ? "deal" : "news"} data-itemlabel={item.displayName}
                              data-templatetype={currentPage === "store" ? "Store Detail" : (
                                currentPage === "restaurant" ? "Restaurant Detail" : (
                                  currentPage === "stores" ? "Stores List" : (
                                    currentPage === "restaurants" ? "Restaurants List" : (
                                      item.isOffer ? "Offers List" : "News List"
                                    )
                                  )
                                )
                              )}>
                            </a>
                          </div>
                        </div>
                        <div className={"position-relative whats-hot-item  whats-hot-text whats-hot-mobile-content " + contentTextClass + " " + (model.isNewBrandedSite ? "bg-lighter " : "bg-lighter" + (model.isNewBrandedSite ? "" : " text-light "))}>
                          <a href={urlPrefix + "/" + item.detailsPageUrl} aria-label="news-detail" className="position-absolute w-100 h-100 block-link"></a>
                          <div className={"whats-hot-content  text-center" + (model.isNewBrandedSite ? " card-new p-0 px-2" : "")}>
                            <div className="vertical-align picto-after ">
                              <div className="bookmark-wrap d-lg-block d-none">
                                <a className={"open-popin add-wishlist ava-wishlist picto icon-heart-unfill-rw text ava-wishlist bookmark-news-new bookmark analytics_newsOffersBMClk"}
                                  href="javascript:void(0);" role="button" aria-label={item.iddata} aria-labelledby={item.isOffer ? "deal" : "news"} data-itemlabel={item.displayName}
                                  data-templatetype={currentPage === "store" ? "Store Detail" : (
                                    currentPage === "restaurant" ? "Restaurant Detail" : (
                                      currentPage === "stores" ? "Stores List" : (
                                        currentPage === "restaurants" ? "Restaurants List" : (
                                          item.isOffer ? "Offers List" : "News List"
                                        )
                                      )
                                    )
                                  )}>
                                </a>
                              </div>
                              <div className="no-picto"></div>
                              {(item.retailerName !== null && item.retailerName !== "") &&
                                <h3 className="retailer-name text-uppercase">{item.retailerName}</h3>

                              }
                              {(item.displayName !== null && item.displayName !== "") &&
                                <h3 className="whats-hot-title text-midgray m-0">{item.displayName}</h3>

                              }
                              {(!item.isOffer) &&
                                <a href={urlPrefix + "/" + item.detailsPageUrl} className=" btn-link-news my-2 m-0 analytics_newsOffersClk">{(item.externalUrlDescription !== "") ? item.externalUrlDescription : model.discover}</a>
                              }

                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  }
                })}

                {/* Withoutpics Loop */}
                {(model.newsOffersIntegratedItems !== null) &&
                  withOutPicsloop(model, withPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, isFromSpliceCon)
                }
                {(model.isViewMoreRequired && model.isNewBrandedSite) &&

                  <div className="text-end black-view-more my-2" id="viewmoreid">
                    <input type="hidden" id="offernewspageno" value={model.pagecount} />
                    <a className="grid-new-cta analytics_newsoffervm gtmViewMoreClick" id="newsoffersviewmore" onClick={() => offersViewMore(url)}
                      data-analyticspushdisplayclassName="analytics_newsOffers" data-analyticsperpagecount={model.pagecount * (model.newsOffersIntegratedItems != null ? model.newsOffersIntegratedItems.length : 0)}>{model.viewMore}
                      <span className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></span></a>
                  </div>

                }
              </div>
              {
                (model.isViewMoreRequired && !model.isNewBrandedSite) &&
                <div className="mt-3 mb-1 text-black text-center text-uppercase view-more" id="viewmoreid">
                  <input type="hidden" id="offernewspageno" value={model.pagecount} />
                  <div className="analytics_newsoffervm gtmViewMoreClick" id="newsoffersviewmore" onClick={() => offersViewMore(url)} data-analyticspushdisplayclassName="analytics_newsOffers" data-analyticsperpagecount={model.pagecount * (model.newsOffersIntegratedItems != null ? model.newsOffersIntegratedItems.length : 0)}>{model.viewMore}</div>
                  <i className="picto icon-go-down-arrow"></i>
                </div>
              }
            </>
          }
        </div >
      </section >
    );
  } else {
    return <></>;
  }
}

let rowtwoflag = false;

function withOutPicsloop(fields, withPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, isFromSpliceCon) {

  let withoutPicsCount = fields.newsOffersIntegratedItems.length - withPicsCount;
  let rowtwocnt = withPicsCount % 4;
  let textright = false;
  return (
    <>
      {fnrowtwoflagcn(rowtwocnt, withoutPicsCount)}

      {fntestloop(fields, withoutPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, textright, withPicsCount, isFromSpliceCon)}
    </>
  )
}

function fnrowtwoflagcn(rowtwocnt, withoutPicsCount) {
  if (rowtwocnt <= 2 && withoutPicsCount === 2) {
    return (
      rowtwoflag = true
    )
  }
}

let itemCountValue = -1;
let isRemoveItem = false;

function fntestloop(fields, withoutPicsCount, contentTextClass, contentBgClass, contentLinkClass, urlPrefix, textright, withPicsCount, isFromSpliceCon) {
  let indexcnt = 0;
  var currentPage = fields.newsData != null && fields.newsData.currentPage != null ? fields.newsData.currentPage : "";

  if ((withoutPicsCount === 1) || (withoutPicsCount === 2 && rowtwoflag)) {
    return (
      ((withoutPicsCount == 1) || (withoutPicsCount == 2)) && !((withPicsCount == 3) && withoutPicsCount == 2) ?
        (
          (fields.newsOffersIntegratedItems.map((item, index) => {
            indexcnt++;

            if (item.imageUrl === "") {
              return (
                <div key={index} className="col-sm-12 item picture-and-text">

                  <div className="whats-hot-item whats-hot-text bg-dark text-light no-picture">
                    <a href={urlPrefix + "/" + item.detailsPageUrl} aria-label="news-detail" className="position-absolute w-100 h-100 block-link"></a>
                    <div className="whats-hot-content">
                      <div className="vertical-align">
                        <div className="bookmark-wrap">
                          <a className="text-hide add-wishlist ava-wishlist bookmark picto icon-heart-unfill-rw analytics_newsOffersBMClk"
                            href="javascript:void(0);" aria-label={item.iddata} role="button" aria-labelledby={item.templateId}
                            data-templatetype={currentPage === "store" ? "Store Detail" : (
                              currentPage === "restaurant" ? "Restaurant Detail" : (
                                currentPage === "stores" ? "Stores List" : (
                                  currentPage === "restaurants" ? "Restaurants List" : (
                                    item.isOffer ? "Offers List" : "News List"
                                  )
                                )
                              )
                            )}>
                          </a>
                        </div>
                        {(item.isOffer) &&
                          <div className="picto-wrap"><i className="picto icon-vip-card"></i></div>

                        }
                        {(item.retailerName !== null && item.retailerName !== "") &&
                          < div className="retailer-name text-uppercase">{item.retailerName}</div>

                        }
                        {(item.displayName !== null && item.displayName !== "") &&
                          <h3 className="whats-hot-title m-0 text-midgray">{item.displayName}</h3>

                        }
                        {(!item.isOffer) &&
                          <a href={urlPrefix + "/" + item.detailsPageUrl} className="btn-link-news my-2 m-0 analytics_newsOffersClk">{(item.externalUrlDescription !== "") ? item.externalUrlDescription : fields.discover}</a>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          }))
        ) :
        (
          (withoutPicsCount == 1) || (withoutPicsCount == 2) ?
            (
              (fields.newsOffersIntegratedItems.map((item, index) => {
                indexcnt++;

                if (item.imageUrl === "") {
                  contentTextClass = textright ? "text-dark" : "text-light";
                  contentBgClass = textright ? "bg-light" : "bg-main";
                  contentLinkClass = textright ? " link-dark" : ""
                  textright = !textright;
                  return (
                    <div key={index} className="col-sm-6 item">

                      <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " " + contentLinkClass}>
                        <a href={urlPrefix + "/" + item.detailsPageUrl} aria-label="news-detail" className="position-absolute w-100 h-100 block-link"></a>
                        <div className="whats-hot-content">
                          <div className="vertical-align">
                            <div className="bookmark-wrap">
                              <a className="text-hide add-wishlist ava-wishlist bookmark picto icon-heart-unfill-rw analytics_newsOffersBMClk" role="button"
                                data-templatetype={currentPage === "store" ? "Store Detail" : (
                                  currentPage === "restaurant" ? "Restaurant Detail" : (
                                    currentPage === "stores" ? "Stores List" : (
                                      currentPage === "restaurants" ? "Restaurants List" : (
                                        item.isOffer ? "Offers List" : "News List"
                                      )
                                    )
                                  )
                                )}
                                href="javascript:void(0);" aria-label={item.iddata} aria-labelledby={item.templateId} >
                              </a>
                            </div>
                            {(item.isOffer) &&
                              <div className="picto-wrap"><i className="picto icon-vip-card"></i></div>

                            }
                            {(item.retailerName !== null && item.retailerName !== "") &&
                              < div className="retailer-name text-uppercase">{item.retailerName}</div>

                            }
                            {(item.displayName !== null && item.displayName !== "") &&
                              <h3 className="whats-hot-title m-0 text-midgray">{item.displayName}</h3>

                            }
                            {(!item.isOffer) &&
                              <a href={urlPrefix + "/" + item.detailsPageUrl} className="btn-link-news my-2 m-0 analytics_newsOffersClk">{(item.externalUrlDescription !== "") ? item.externalUrlDescription : fields.discover}</a>

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              }))
            ) :
            (<></>)
        )
    )
  }
  else if (((withoutPicsCount % 2) == 0) || ((withoutPicsCount % 2) == 1)) {
    textright = false;
    let itemcnt;
    itemcnt = 0;
    let indexcnt = 0;
    let itemCount = 0;
    let withoutPicsClass = "";
    isRemoveItem = false;
    return (
      <>
        {spilcecond(fields, itemcnt, withoutPicsCount, isFromSpliceCon, itemCount)}

        {(fields.newsOffersIntegratedItems.length > 0) &&
          (fields.newsOffersIntegratedItems.map((item, index) => {
            indexcnt++;
            contentTextClass = textright ? "text-dark" : "text-light";
            contentBgClass = textright ? "bg-light" : "bg-main";
            contentLinkClass = textright ? " link-dark" : "";
            textright = !textright;
            if (isRemoveItem) {
              withoutPicsClass = itemCountValue === index ? "d-none " : " d-block";
            }
            return (
              (item.imageUrl === "") &&

              <div key={index} className={"col-sm-6 item  " + withoutPicsClass}>

                <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " " + contentLinkClass}>
                  <a href={urlPrefix + "/" + item.detailsPageUrl} aria-label="news-detail" className="position-absolute w-100 h-100 block-link"></a>
                  <div className="whats-hot-content">
                    <div className="vertical-align">
                      <div className="bookmark-wrap">
                        <a className="open-popin add-wishlist ava-wishlist bookmark-news picto icon-heart-unfill-rw text-light analytics_newsOffersBMClk"
                          href="javascript:void(0);" role="button" aria-label={item.iddata} aria-labelledby={item.templateId}
                          data-templatetype={currentPage === "store" ? "Store Detail" : (
                            currentPage === "restaurant" ? "Restaurant Detail" : (
                              currentPage === "stores" ? "Stores List" : (
                                currentPage === "restaurants" ? "Restaurants List" : (
                                  item.isOffer ? "Offers List" : "News List"
                                )
                              )
                            )
                          )}>
                        </a>
                      </div>
                      {(item.isOffer) &&
                        <div className="picto-wrap"><i className="picto icon-vip-card"></i></div>
                      }
                      {(item.retailerName !== null && item.retailerName !== "") &&
                        < div className="retailer-name text-uppercase">{item.retailerName}</div>
                      }
                      {(item.displayName !== null && item.displayName !== "") &&
                        <h3 className="whats-hot-title m-0 text-midgray">{item.displayName}</h3>
                      }
                      {(!item.isOffer) &&
                        <a href={urlPrefix + "/" + item.detailsPageUrl} className="btn-link-news my-2 m-0 analytics_newsOffersClk">{(item.externalUrlDescription !== "") ? item.externalUrlDescription : fields.discover}</a>
                      }
                    </div>
                  </div>
                </div>
              </div >
            )
          })
          )
        }
      </>
    )
  }


  function spilcecond(fields, itemcnt, withoutPicsCount, isFromSpliceCon, itemCount) {
    let breakCondition = false;
    let indexcnt = 0;

    if ((withoutPicsCount % 2) == 1) {
      return (

        fields.newsOffersIntegratedItems.map((item, index) => {
          indexcnt++;
          itemcnt++;
          if (item.imageUrl === "" && !breakCondition) {
            breakCondition = true;
            isRemoveItem = true;
            itemCountValue = indexcnt - 1;
            return (
              <div key={index} className="col-sm-12  item  picture-and-text">

                <div className="position-relative whats-hot-item whats-hot-text   bg-dark  text-light no-picture ">
                  <a href={urlPrefix + "/" + item.detailsPageUrl} className="position-absolute w-100 h-100 block-link"></a>
                  <div className="whats-hot-content card-new text-center card-new">
                    <div className="vertical-align   picto-after">
                      <div className="bookmark-wrap">
                        <a className="open-popin add-wishlist ava-wishlist bookmark-news picto icon-heart-unfill-rw text-light analytics_newsOffersBMClk"
                          href="javascript:void(0);" aria-label={item.iddata} aria-labelledby={item.templateId}
                          data-templatetype={currentPage === "store" ? "Store Detail" : (
                            currentPage === "restaurant" ? "Restaurant Detail" : (
                              currentPage === "stores" ? "Stores List" : (
                                currentPage === "restaurants" ? "Restaurants List" : (
                                  item.isOffer ? "Offers List" : "News List"
                                )
                              )
                            )
                          )}>
                        </a>
                      </div>
                      {(item.isOffer) &&
                        <div className="picto-wrap"><i className="picto icon-vip-card"></i></div>
                      }
                      {(item.retailerName !== null && item.retailerName !== "") &&
                        < div className="retailer-name text-uppercase">{item.retailerName}</div>
                      }
                      {(item.displayName !== null && item.displayName !== "") &&
                        <h3 className="whats-hot-title m-0 text-midgray">{item.displayName}</h3>
                      }
                      {(!item.isOffer) &&
                        <a href={urlPrefix + "/" + item.detailsPageUrl} className="btn-link-news my-2 m-0 analytics_newsOffersClk">{(item.externalUrlDescription !== "") ? item.externalUrlDescription : fields.discover}</a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })
      )
    }
  }
}


function NotnewsPageCondition(fields, isNewsPage, headingLeftLink, headingRightLink) {
  if (!isNewsPage) {
    return (
      (fields.whatsHotPage !== "") ?
        (
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            <a className="title mb-1 mb-lg-0 gtmClickNavigation" href={headingLeftLink}>
              {(fields.eventsTitle !== "" && fields.eventsTitle !== null) &&
                <h2>{fields.eventsTitle}</h2>
              }
            </a>
            {/* {(!fields.isNewBrandedSite) &&
              <a className="action gtmClickNavigation" href={headingRightLink}>
                <strong>{fields.seeAllNews}</strong> {" (" + fields.totalNewsCount + ")"}<span className="picto icon-ico-chevron-right"></span>
              </a>
            } */}
          </div>
        ) :
        (
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            <div className="title mb-1 mb-lg-0" style={{ cursor: 'default' }}>
              {(fields.eventsTitle !== "") &&
                <h2>{fields.eventsTitle}</h2>
              }
            </div>
          </div>
        )
    )
  }
}
