import React from 'react';

export default function LPDiscoverMyAdvantages({ fields }) {
  if (fields !== undefined && fields !== null && fields !== "") {
    return (
      <>
        <div className="shopping-center-tab-pane2 account-tab-pane2 bg-white">
          <div className={"fade " + (fields.isNewBrandedSite ? "shopping-center-inner-tab" : "") + " show active border-0"} role="tabpanel">
            <div className="d-flex  account-card-header shopping-center-card-header align-items-center mb-3">{fields.discoverMoreTitle}
            </div>
            <div className="d-flex shopping-center-good-text mb-3 account-good-text">{fields.discoverMoreDescription}</div>
            <a href={fields.discoverMoreLink} aria-label={fields?.discoverMoreLink} className="d-flex shopping-center-good-text-link account-good-text-link">{fields.discoverMoreLinkText}
            </a>
          </div>
        </div>
      </>
    );

  }
  else {
    return <></>;
  }

}
