import React, { useEffect, useState } from 'react';
import store from "../../redux/store.js";
import axios from 'axios';
import { Buffer } from 'buffer';

export default function LoyaltyCardBarCodePopin({ model }) {
  const [image, setImage] = useState('');
  const state = store.getState();
  const visitorId = state.user.visitorId;
  const accessToken = state.user.accessToken;
  const refreshToken = state.user.refreshToken;  

  useEffect(() => {
    var ele = document.getElementById("barCodeModalPopin");
    if (ele !== null && ele !== undefined) {
      var popinClick = document.getElementById("popinBarCodeClick");
      if (popinClick !== null && popinClick !== undefined && popinClick !== "") {
        popinClick.click();
      }
    }
    if (model !== null) {
      axios({
        method: "post",
        responseType: "arraybuffer",
        url: "/api/sitecore/VisitorApi/BarcodeImage?strBarCode=" + model,
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken,
        },
        async: false
      })
        .then(function (response) {
          if (response != null && response.data) {
            setImage("data:image/png;base64," + Buffer.from(response.data, 'binary').toString('base64'));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  if (model !== null) {
    return (
      <div className="modal-dialog popinbarcode" id="barCodeModalPopin">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="picto icon-close-circle-rw border-0 bg-white text-black fs-3 click-catcher" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body text-center transform-modal text-center">
            <img id="barCode" src={image} alt={model} title={model} />
          </div>
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}
