import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import Slider from "react-slick";
import { baseModel } from '../../RouteHandler';


export default function MallGallery({ fields }) {
  let index = 0;
  useEffect(() => {
    window.addEventListener('scroll', function () {
      mallGallerylazyLoadingImg();

    });

  }, []);

  let styles = {
    width: '337px',
    height: 'auto'
  }
  if (fields.mallGallery != null) {
    if (fields.mallGallery.mallGalleryImg !== null && fields.mallGallery.mallGalleryImg.length > 0) {

      var settings = {
        customPaging: function (i) {
          return (
            <button type="button" className="bg-main" data-role="none" role="button" tabIndex="0" />
          );
        },
        arrows: true,
        dots: true,
        infinite: fields.mallGallery.mallGalleryImg.length > 3 ? true : false,
        slidesToShow: 3,
        slidesToScroll: 3,
      };
      return (
        <section className="back-gray" id="pictures">
          <div className="container">
            {(fields.mallGallery.photoGallery.value !== "" && fields.mallGallery.photoGallery.value !== null) &&
              <Text className={(fields.mallGallery.isNewBrandedSite ? " page-title" : "content-title") + " text-uppercase" + (fields.mallGallery.isNewBrandedSite ? "" : " text-center underline")} field={fields.mallGallery.photoGallery} tag="h2" />

            }
            {(fields.mallGallery.theMallPhotoGallery?.value !== "" && fields.mallGallery.theMallPhotoGallery?.value !== null && !fields.mallGallery.isNewBrandedSite) &&
              <Text className="sub-title text-uppercase" field={fields.mallGallery.theMallPhotoGallery} tag="h3" />
            }
            <Slider {...settings} className="carousel-gallery" id="centreGallery">
              {fields.mallGallery.mallGalleryImg.map((item, index) => {
                index++;
                return (
                  (fields.mallGallery.isExperienceEditor) ?
                    (

                      <div key={index} id={"mallGalleryCarousel" + index} className="picture-wrap gtmCarouselView" >
                        <img src={item.jssBackgroundImg.value?.src} style={styles} alt={item.imageAlt} />
                      </div>
                    ) :
                    (
                      <div key={index} id={"mallGalleryCarousel" + index} className="picture-wrap gtmCarouselView" >
                        <img src={fields.mallGallery.defaultImage} alt={item.imageAlt} data-alt={item.imageDataAlt} id={"mallGalleryLazyLoadImg" + index} />
                      </div>
                    )
                )
              })}
            </Slider>
            {(fields.mallGallery.isNewBrandedSite) &&
              <hr className="mt-5 mb-0" />

            }
          </div>
        </section>
      );
    }
    else {
      return <></>;
    }
  } else {
    return <></>;
  }
}

function mallGallerylazyLoadingImg() {
  document.querySelectorAll("img[src='" + baseModel.defaultImage + "']").forEach(x => {
    var isInViewport;
    if (x.id !== "" && x.id !== null && x.id !== undefined && x.id.includes("mallGalleryLazyLoadImg")) {
      isInViewport = x.offsetTop < (window.scrollY + window.innerHeight + 500);
    }
    else {
      isInViewport = false;
    }

    if (x.getAttribute("data-alt") != undefined && x.getAttribute("data-alt") != "" &&
      isInViewport) {
      x.setAttribute('src', x.getAttribute("data-alt"));
    }
  });
}