import React from 'react';

export default function Page500({ fields }) {
  if (fields.page404 != null && fields.page404.imageItem !== "" || (fields.page404?.centers != null && fields.page404?.centers?.length > 0)) {
    return (
      <section className={"whats-hot" + (fields.page404.isNewBrandedSite ? " pnf" : "")} >
        <div className="container">
          <div className="row">
            {
              (fields.page404.imageItem !== "" && fields.page404.imageItem !== null) &&
              <div className="col-sm-4  item">
                <div className="whats-hot-item whats-hot-picture ">
                  <img src={fields.page404.modifiedImageItem} alt={fields.page404.imageItemAlt} title={fields.page404.imageItemAlt} />
                </div>
              </div>
            }
            {fields.page404.centers !== undefined && fields.page404.centers !== null &&  fields.page404.centers !== "" && fields.page404.centers.length >0 && fields.page404.centers.map((items, index) => {
              return (
                <div key={index} className=" col-sm-4  item" id="centeraddress">
                  <div className="whats-hot-item whats-hot-text  text-light bg-main link-light centeraddress">
                    <div className="whats-hot-content">
                      <div className="vertical-align">
                        <div className="no-picto"></div>
                        {(items.centerName !== "") &&
                          <h3 className="whats-hot-title text-uppercase">
                            <a href="#"  aria-label={items?.centerName}>
                              <span className="">
                                {items.centerName}
                              </span>
                            </a>
                          </h3>
                        }
                        <div className="description">{items.streetAdress}</div>
                        <div className="action">
                          <a href={fields.page404.actionUrl}
                          aria-label={fields?.page404?.contactus}
                          className="link-button btn-white">{fields.page404.contactus}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>


    );
  } else {
    return <div></div>;
  }

}

