import React from 'react';


export default function RestaurantAnchors({ fields }) {
  const handleClick = (e) => {
    e.preventDefault();
  };
  if (fields != null && fields.restaurantsAnchors != null) {
    return (

      <div className="anchor-navigation d-none d-sm-block">
        <div className="container">
          <ul className="nav">
            {(fields.restaurantsAnchors.restaurantAnchors != null && fields.restaurantsAnchors.restaurantAnchors.length > 0) &&

              fields.restaurantsAnchors.restaurantAnchors.map((child, index) => {
                if (child !== null && child !== undefined && child !== "") {
                  return (
                    <li key={index}><a href={child.links?.value?.url} aria-label={child?.links?.value} className="text-uppercase scrollTo subanchors d-none" data-testid="testclick1" onClick={handleClick}>{child.links?.value?.text}</a></li>
                  )
                }
              })}

          </ul>
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}
