import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler';
import { fnDiscoverAnotherCountryPopin, fnDiscoverAnotherCenterPopin, bannerToggleGlobal } from '../Js-CommonFunctions';
import { handlePageScrollBarOnMenuOpened, menuDataLayer, closeBurgerMenu, menuElementDataLayer } from '../Js-CommonFunctions';

export default function GlobalBurgerMenu({ fields ,visitorInitials}) {
  useEffect(() => {
    closeBurgerMenu();
    // disable page scrollbar when burgermenu opens
    handlePageScrollBarOnMenuOpened();

  }, []);
  var url = fields.globalHeaderItem.headerItem?.logoLink?.value?.url?.length > 1 ? 
  fields.globalHeaderItem.headerItem?.logoLink?.value?.url.replace(/\/$/, '') : fields.globalHeaderItem.headerItem?.logoLink?.value?.url;
//   function bannerToggle() {
   
//     //Hide Smart App Banner
//     if (document.getElementsByClassName("smartbanner")[0]) {
//         if (document.getElementsByClassName("smartbanner")[0].classList.contains("d-none"))
//             document.getElementsByClassName("smartbanner")[0].classList.remove('d-none');
//         else
//             document.getElementsByClassName("smartbanner")[0].classList.add('d-none');
//     }
//     if (document.getElementById("headerBlock")) {
//         if (document.getElementById("headerBlock").classList.contains("preheader-frontindex")) {
//             document.getElementById("headerBlock").classList.remove("preheader-frontindex");
//         }
//         else {
//             document.getElementById("headerBlock").classList.add("preheader-frontindex");
//         }
//     }
// }
  return (
    <div className="container navbar-container-desktop m-0 d-block d-sm-none d-lg-block">
      <div id="navbar" className="navbar-collapse slidemenu collapse bg-gray navbar-toggler-container-fluid" role="navigation"
        aria-expanded="false">
        <div className="desktop-overlay  col-md-4 px-0 bg-gray position-relative">

          {/* <a className="picto icon-cross navbar-toggler-navbar d-none d-lg-block ps-4 fs-1 collapsed text-decoration-none text-white"
            data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar" id="burgerIcon"
            aria-label="Toggle navigation"></a> */}


          {/* <div className="navbar-header  mb-1 w-100 w-lg-auto mr-lg-5">
            <div className="logo d-none text-center d-lg-block m-0">
              <a href={url} aria-label={fields.globalHeaderItem.headerItem?.logoLink?.value}>
                <img src={fields.globalHeaderItem.headerItem?.logo?.value?.src} alt={fields.globalHeaderItem.headerItem?.logo?.value?.alt} className="top-logo"
                  title="" ></img>
              </a>
            </div>
          </div> */}

          <ul className="navbar-nav submenu position-relative mt-lg-4">
            {fields.burgerMenu.menuList != null && fields.burgerMenu.menuList.map((linkItem, index) => {
              var subMenus = false;
              var submenusCount = 0;
              var hasMenuUrl = false;
              hasMenuUrl = (linkItem.parentMenuLink?.value?.url !== null && linkItem.parentMenuLink?.value?.url !== "") ? true : false;
              var megaDropCollapseId = "";
              if (linkItem.subMenusLst !== null && linkItem.subMenusLst !== "" && linkItem.subMenusLst !== undefined && linkItem.subMenusLst.length > 0) {
                subMenus = true;
                submenusCount = linkItem.subMenusLst.length;
                megaDropCollapseId = "megadropcollapse" + index;
              }


              if (linkItem.parentMenuLink?.value?.text !== "" && linkItem.parentMenuLink.value?.text !== null && (hasMenuUrl || subMenus)) {
                return (
                  <li key={index} className={(subMenus ? "dropdown MenuMegaDrop multi-level-new" : "") + " multi-level"} id={megaDropCollapseId}>
                    {!subMenus ?
                      (<a href={linkItem.parentMenuLink.value.url} aria-label={linkItem.parentMenuLink.value?.url}
                        className={" d-block gtmHeader multi-level-list premium-text"}
                        onClick={() => { menuElementDataLayer(linkItem.parentMenuLink.value.url) }}>{linkItem.parentMenuLink.value.text}
                      </a>
                      ) :
                      (
                        <>
                          <a href={"#" + megaDropCollapseId} aria-label={linkItem.parentMenuLink?.value}
                            className={" d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list  multi-level-list-new"}
                            data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" >{linkItem.parentMenuLink.value.text}
                          </a>
                          <div className="megadrop megadrop-new d-lg-block dropdown-menu dropdown-menu-new desktop-mega">
                          <a className="picto icon-close-circle-rw navbar-toggler-navbar navbar-toggler-navbar-new d-none d-lg-block ps-3 fs-1" data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="true" aria-controls="navbar" aria-label="Toggle navigation"
                           onClick={() => { bannerToggleGlobal() }}></a>
                            <div className="container megadrop-header p-0 ms-lg-0">
                              <div className="row">
                                <div className="col-sm-12 d-flex align-items-center ps-4">
                                  <h3 className="category-stores-new">{linkItem.parentMenuLink.value.text}
                                  </h3>
                                  {/* <a href="#" className="ps-2 category-info">(voir toutes les boutiques)</a> */}
                                </div>
                              </div>
                            </div>
                            <div className="container megadrop-list megadrop-list-new p-1 m-0">
                              <ul className="list-group megadrop-list-items ">
                                {linkItem.subMenusLst !== null && linkItem.subMenusLst.map((subMenu, index) => {
                                  if (subMenu.link?.value?.url !== "" && subMenu.link?.value?.url !== null && subMenu.link?.value?.text && subMenu.link?.value?.text) {
                                    return (
                                      <li key={index} className="list-unstyled"> <a href={subMenu.link?.value?.url} aria-label={subMenu.link?.value} target={subMenu.link?.value?.target}
                                        className=" list-group-item list-group-item-action dropdown-item" onClick={(e) => { menuElementDataLayer(subMenu.link?.value?.url) }}>{subMenu.link?.value?.text}</a> </li>
                                    )
                                  }
                                }
                                )}
                              </ul>

                            </div>
                          </div>
                        </>
                      )
                    }
                  </li>
                )
              }
            })}
             {fields.burgerMenu.discoverCTABtn !== null && fields.burgerMenu.discoverCTABtn !== "" && fields.burgerMenu.discoverCTABtn.link?.value?.text !== null && fields.burgerMenu.discoverCTABtn.link?.value?.text !== "" && fields.burgerMenu.discoverCTABtn.link?.value?.url !== null && fields.burgerMenu.discoverCTABtn.link?.value?.url !== "" &&
            <li className="multi-level multi-level-new dropdown MenuMegaDrop">
              {fields.burgerMenu.discoverCTABtn.link.value.url.includes("javascript") ?
                (
                  <a className=" d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list discover-center-icon multi-level-list-new" aria-label={fields?.burgerMenu?.discoverCTABtn?.link?.value?.text}
                    href="#" onClick={(e) => { e.preventDefault(); fnDiscoverCTA(fields.burgerMenu.discoverCTABtn.link.value.url) }}>{fields.burgerMenu.discoverCTABtn.link.value.text}
                  </a>
                ) :
                (
                  <a className=" d-block gtmHeader dropdown-toggle dropdown-toggle-new d-inline-block multi-level-list discover-center-icon multi-level-list-new" aria-label={fields?.burgerMenu?.discoverCTABtn?.link?.value?.text}
                    href={fields.burgerMenu.discoverCTABtn?.link?.value?.url} >{fields.burgerMenu.discoverCTABtn.link.value.text}
                  </a>
                )}
            </li>
          }

          </ul>
         {(fields.languageData !== null) &&
            <footer className="footer my-2">
              <div className=" text-center menu-language-switcher bg-gray">
                <ul className="lang-links lang-links-new d-flex text-uppercase">
                  {fields.languageData.languages.map((item, index) => {
                    const url = ",-w-,";
                    const wlUrl = item.destinationUrl.replace(url, item.endUrl);
                    return (
                      (item.destinationUrl.includes(url)) ?
                        (
                          <li key={index} className={(item.cssState ? " active " : " ")}>
                            <a href={wlUrl} aria-label={item.language.title} className="">{item.language.title}
                            </a>
                          </li>
                        ) :
                        (
                          <li key={index} className={(item.cssState ? " active  " : " ")}>
                            <a href={item.destinationUrl} aria-label={item.destinationUrl} className="" >{item.language.title}
                            </a>
                          </li>
                        )

                    )
                  })}
                </ul>
              </div>
            </footer>
          } 
          {(visitorInitials != null && visitorInitials != undefined && visitorInitials != "") ?
            (
              <div className=" w-100  my-3  m-auto  text-center loyalty menu-logout">
                <a href={fields.globalHeaderItem.myAccountLink?.link?.value?.url} aria-label={fields.globalHeaderItem.myAccountLink?.link?.value} target=""
                className="nav-link px-2 text-center text-white w-100 btn-dark gtmHeader">{fields.globalHeaderItem.myAccountLink?.link?.value?.text}</a></div>
            ) : (
              <div className=" w-100 px-5 my-3 px-sm-3 m-auto  text-center loyalty menu-logout"><a href={baseModel.connectRedirectUrl} aria-label={fields.globalHeaderItem.myAccountLink?.link?.value} target=""
                className="nav-link px-2 text-center text-white w-100 btn-dark gtmHeader">{fields.globalHeaderItem.loginLink?.link?.value?.text}</a></div>
            )}
          
        </div>
      </div>
    </div>

  )

}

function fnDiscoverCTA(url) {
  if (url.includes("openDiscoverAnotherCountryPopin")) {
    menuDataLayer();
    fnDiscoverAnotherCountryPopin();
  }
  else if (url.includes("openDiscoverAnotherCenterPopin")) {
    menuDataLayer();
    fnDiscoverAnotherCenterPopin("Country");
  }
  else
    return;
}