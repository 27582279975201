import React from 'react';
import { RichText, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

export default function PlainRichText({ fields }) {
  if (isEditorActive()) {
    return <></>;
  } else {
    if (fields && fields["Html Data"] !== null && fields["Html Data"].value !== "" && fields["Html Data"].value !== null) {
      return (
        <RichText field={fields["Html Data"]} />
      )
    }
    else {
      return <></>;
    }
  }
}
