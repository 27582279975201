import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function FAQ({ fields }) {
  if (fields !== null && fields !== undefined && fields !== "" && fields.faqViewModel !== null && fields.faqViewModel !== undefined && fields.faqViewModel !== "") {
    let model = fields.faqViewModel;
    return (
      <>
        <div className="loyalty-block-wc-img position-relative">
          {/* <picture>
            <source media="(max-width: 799px)" src={fields.faqViewModel.bgImage?.value?.src} />
            <img className="loyalty-program-howto-img" src={fields.faqViewModel.bgImage?.value?.src} loading="lazy" alt="">
            </img>
          </picture> */}
          <div>
            <div className="loyalty-program-howto-img"></div>
          </div>
          <div className="faq-text-block">
            <div className="faq-text-content">
              <RichText field={fields.faqViewModel.heading} />
            </div>
          </div>
        </div>
        <div className="faq-accordion-container">
          {(model.groupedQuestions1 !== null && model.groupedQuestions1 !== "" && model.groupedQuestions1.length > 0) &&
            model.groupedQuestions1.map((groupedQuestion, index) => {
              if (groupedQuestion !== null && groupedQuestion.groupCategory !== null && groupedQuestion !== "" && groupedQuestion.groupCategory !== "") {
                return (
                  <>
                    {(groupedQuestion.groupCategory.display_Name !== null && groupedQuestion.groupCategory.display_Name !== "") &&
                      <div key={index} className="faq-accordian-header">{groupedQuestion.groupCategory.display_Name.toString()}</div>
                    }
                    {groupedQuestion.questions.map((question, index1) => {
                      let Answer_Text = { value: question.answer_Text };
                      let question_Text = { value: question.question_Text }
                      return (
                        <div key={index1} className="accordion accordion-flush" id={"accordionFlush" + index1}>
                          <div className="accordion-item">
                            <div className="accordion-header" id={"flush-heading" + index1}>
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target={"#flush-collapse" + index1} aria-expanded="false" aria-controls={"flush-collapse" + index1}>
                                <RichText field={question_Text} />
                              </button>
                            </div>
                            <div id={"flush-collapse" + index1} className="accordion-collapse collapse" aria-labelledby={"flush-heading" + index1}
                              data-bs-parent={"#accordionFlush" + index1}>
                              <div className="accordion-body">
                                <RichText field={Answer_Text} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    }
                  </>
                )
              }
            })
          }
        </div>
      </>
    )
  }
  else {
    return <></>
  }
}
