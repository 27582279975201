import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';
import Deals from '../Deals/index';
import { CommonBookmarkallpage, fnGtmCategorySearchFilter, lazyLoadingImg, offersTooltipOnHover } from '../Js-CommonFunctions.js';
import PersonalizedBlock from '../PersonalizedBlock/index';
import PersonalizedOffers from '../PersonalizedOffers/index';
import ShopLandingOfferInnerPage from '../ShopLandingOfferInnerPage';
import axios from "axios";

var isNewBrandedSite = '';
export default function Offers({ fields }) {
  const [landingOfferModel, setLandingOfferModel] = useState();
  const [shopLandingOfferItem, setShopLandingOfferItem] = useState();
  let workerCenter = useSelector((state) => state.user.workerCenter);
  var isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  useEffect(() => {

    if (fields !== null && fields !== undefined && fields !== "" && fields.shopLandingOfferItem !== undefined && fields.shopLandingOfferItem !== null && fields.shopLandingOfferItem !== "") {
      let isWorker =
        workerCenter !== undefined &&
        workerCenter !== '' &&
        workerCenter.split(',').includes(baseModel.pC_Code);

      if (isWorker) {
        var loader = document.getElementById("dotloader");
        if (loader) {
          loader.classList.remove("d-none");
        }
        axios({
          method: "post",
          url: "/api/sitecore/OffersApi/GetOffersPageData?ursite=" +
            baseModel.urContextSite +
            "&urlang=" +
            baseModel.contextLang +
            "&commercialCategory=" +
            fields.obj.commercialCategory +
            "&periodicity=" +
            fields.obj.periodicity +
            "&strCategoryName=" +
            fields.obj.strCategoryName +
            "&strChildCategoryName=" +
            fields.obj.strChildCategoryName +
            "&loyaltyCardFilter=" +
            fields.obj.loyaltyCardFilter +
            "&workerCardFilter=" +
            fields.obj.workerCardFilter +
            "&isWorker=" + isWorker+
            "&isloggedIn"+ isLoggedIn
          ,
          async: false,
        })
          .then(function (response) {
            if (loader) {
              loader.classList.add("d-none");
            }
            if (response.data) {
              if (response.data.shopLandingOfferItem.redirectUrl.length != 0) {
                window.location.href = response.data.shopLandingOfferItem.redirectUrl;
              }
              setShopLandingOfferItem(response.data.shopLandingOfferItem);
              setLandingOfferModel(response.data.landingOfferModel);

            }
          })
          .catch(function (error) {
            console.log(error);
            if (loader) {
              loader.classList.add("d-none");
            }
          });
      }
      else {

      if (fields.shopLandingOfferItem.redirectUrl.length != 0) {
        window.location.href = fields.shopLandingOfferItem.redirectUrl;
      }
      setShopLandingOfferItem(fields.shopLandingOfferItem);
      setLandingOfferModel(fields.landingOfferModel);
    }
  }

    //execute the below code only when offers component is present in the page
    if (document.getElementById('offersComponent')) {
      fnUpdateAlphabetsFilters();
      //on page load, All filter should be selected by default
      document.querySelector('#offersComponent .letters a.allFilter').classList.remove('disablehref')
      document.querySelector('#offersComponent .letters a.allFilter').classList.add('active');

    }
  }, [])

  useEffect(() => {

    if (shopLandingOfferItem !== undefined && shopLandingOfferItem !== null && shopLandingOfferItem !== "") {
      // if (fields.shopLandingOfferItem.redirectUrl.length != 0) {
      //   window.location.href = fields.shopLandingOfferItem.redirectUrl;
      // }
      CommonBookmarkallpage();
      offersTooltipOnHover();
      lazyLoadingImg();
    }

    //execute the below code only when offers component is present in the page
    if (document.getElementById('offersComponent')) {
      fnUpdateAlphabetsFilters();
      //on page load, All filter should be selected by default
      document.querySelector('#offersComponent .letters a.allFilter').classList.remove('disablehref')
      document.querySelector('#offersComponent .letters a.allFilter').classList.add('active');

    }
  }, [shopLandingOfferItem])

  const loadMore = (e) => {
    e.preventDefault();
  }

  if (shopLandingOfferItem !== undefined && shopLandingOfferItem !== null && shopLandingOfferItem !== "") {
    let modelJson = JSON.stringify(shopLandingOfferItem);
    let modelObj = JSON.parse(modelJson);
    if (shopLandingOfferItem.topOffers != null && shopLandingOfferItem.topOffers.length > 0) {
      modelObj.offerItems = shopLandingOfferItem.topOffers;
    }

    isNewBrandedSite = fields.obj.isNewBrandedSite;
    var obj = fields.obj;
    let offerText = (obj.filterLoyaltyCard != null && obj.filterLoyaltyCard != "") && (obj.filterWorkersCard != null && obj.filterWorkersCard != "") ? shopLandingOfferItem.offers : ((obj.filterLoyaltyCard != null && obj.filterLoyaltyCard != "") && (obj.filterWorkersCard == null || obj.filterWorkersCard == "")) ? (shopLandingOfferItem?.offers?.replace("{CATEGORY}", obj.offerEligibilityLoyalty)) : (shopLandingOfferItem?.offers?.replace("{CATEGORY}", obj.offerEligibilityWorker));
    let offerText2 = ((obj.strParentCategory !== null && obj.strParentCategory !== "") && (obj.strChildCategory !== null && obj.strChildCategory !== "")) ? (shopLandingOfferItem?.offers?.replace("{CATEGORY}", obj?.strChildCategory + " - " + obj.strParentCategory)) : ((obj.strParentCategory !== null && obj.strParentCategory !== "") && (obj.strChildCategory == null || obj.strChildCategory == "")) ? (shopLandingOfferItem?.offers?.replace("{CATEGORY}", obj?.strParentCategory)) : shopLandingOfferItem.offers;
    let offerText3 = (shopLandingOfferItem.strSelectedCategory == null || shopLandingOfferItem.strSelectedCategory == "") ? shopLandingOfferItem?.offers?.replace("{CATEGORY} ", "") : (shopLandingOfferItem?.offers?.replace("{CATEGORY}", shopLandingOfferItem.strSelectedCategory));

    return (
      <>
        <div className="anchor-navigation no-logo">
          <div className="container">
            {(shopLandingOfferItem.offers != null &&
              shopLandingOfferItem.offers != '' &&
              obj.filterLoyaltyCard != null &&
              obj.filterLoyaltyCard != '' &&
              obj.filterLoyaltyCard === obj.selected) ||
              (obj.filterWorkersCard != null &&
                obj.filterWorkersCard != '' &&
                obj.filterWorkersCard === obj.selected) ? (
              <h1 className="page-title text-uppercase">
                {offerText} <span className="number">({shopLandingOfferItem.offersCount})</span>
              </h1>
            ) : (obj.strParentCategory != null && obj.strParentCategory != '') ||
              (obj.strChildCategory != null && obj.strChildCategory != '') ? (
              <h1 className="page-title text-uppercase">
                {offerText2} <span className="number">({shopLandingOfferItem.offersCount})</span>
              </h1>
            ) : (
              <h1 className="page-title text-uppercase">
                {offerText3} <span className="number">({shopLandingOfferItem.offersCount})</span>
              </h1>
            )}
          </div>
        </div>
        {!obj.isLoyaltyDisabled &&
          (!isLoggedIn ? (
            <section className="bg-beige non-logged-user">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <div className="personal-offer justify-content-between flex-column">
                      <PersonalizedBlock model={shopLandingOfferItem} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            shopLandingOfferItem.personalizedOfferItems.length > 0 && (
              <section className="bg-beige logged-user d-none d-md-block">
                <div className="container">
                  <div className="offers personal-offers">
                    <div className="content-title offers-title Personalized text-start d-block">
                      {shopLandingOfferItem.personalizedOffers}
                    </div>
                    <div className="row contentSectionPersonalized">
                      <PersonalizedOffers
                        model={shopLandingOfferItem}
                        obj={fields.obj}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )
          ))}
        <section
          className={
            'filter-area new offers  offers-new offers-store-new ' + (obj.isNewBrandedSite ? ' back-gray' : '') + ''
          }
          id="offersComponent"
        >
          <div className="container ">
            {!obj.isNewBrandedSite &&
              ((shopLandingOfferItem.topOffers != null &&
                shopLandingOfferItem.topOffers.length > 0) ||
                (shopLandingOfferItem.groupedOffers != null &&
                  shopLandingOfferItem.groupedOffers.length > 0)) && (
                <div className="content-navigation">
                  <ul className="nav">
                    {shopLandingOfferItem.personalizedOfferItems.length > 0 && (
                      <li id="PersonalizedofferAnchor">
                        <a
                          href="#PersonalizedofferHref"
                          onClick={loadMore}
                          className="text-uppercase scrollTo"
                        >
                          {shopLandingOfferItem.personalizedOffers}
                        </a>
                      </li>
                    )}
                    {shopLandingOfferItem.topOffers != null &&
                      shopLandingOfferItem.topOffers.length > 0 && (
                        <li id="topOffersAnchor">
                          <a
                            href="#topofferTitle"
                            onClick={loadMore}
                            className="text-uppercase scrollTo"
                          >
                            {shopLandingOfferItem.topOffersTitle}
                          </a>
                        </li>
                      )}
                    {shopLandingOfferItem.groupedOffers.map((offertitle, index) => {
                      if (offertitle == null && offertitle.Offers.Count <= 0) {
                        return;
                      }
                      let offerAnchor =
                        offertitle.key != null && offertitle.key.name != null
                          ? offertitle.key.name
                          : '';
                      if (offerAnchor === '') {
                        return;
                      }
                      let offerAnchorId = offertitle.commercialCategoryTitle + 'anchor';
                      let offerAnchorHref = offertitle.commercialCategoryTitle;
                      return (
                        <li key={index} id={offerAnchorId} className="offer-anchor">
                          <a
                            href={'#' + offerAnchorHref}
                            onClick={loadMore}
                            className="text-uppercase scrollTo"
                          >
                            {offerAnchor}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            <div className="row filter-wrap new">
              <div className={"col-12 col-lg-3 filter-category-wrap " + (obj.isNewBrandedSite ? ' mb-3' : '')}>
                <div
                  className={"action btn-show-filter " + (obj.isNewBrandedSite ? 'bg-transparent collapsed' : '')}
                  id="categoryfilter"
                  data-bs-toggle="collapse"
                  data-bs-target="#brandFilter"
                  aria-expanded="false"
                  aria-controls="brandFilter"
                >
                  <a className="link-button text-uppercase btn-see-more link-expandable">
                    {obj.filterby} <span className="picto icon-go-down-arrow"></span>
                  </a>
                </div>
                <div id="brandFilter" className="brandfilter slidemenu collapse">
                  <h2 className="filter-wrap-title fw-bold invisible">Catégories boutiques</h2>
                  <div
                    className={'filter-category-list ' + obj.allSelected}
                    id="filter-category-list"
                  >
                    <a
                      href={obj.urlPrefix + obj.slash + obj.strPageItemName}
                      className={obj.allSelected}
                      aria-expanded="false"
                      onClick={(e) =>
                        fnGtmCategorySearchFilter(
                          e.target,
                          shopLandingOfferItem.allOffers,
                          '',
                          shopLandingOfferItem.totalOffersCount,
                          'Offers',
                          'Offers List'
                        )
                      }
                    >
                      {shopLandingOfferItem.allOffers} (
                      {shopLandingOfferItem.totalOffersCount})
                    </a>
                    {shopLandingOfferItem.periodicityCategories != null &&
                      shopLandingOfferItem.periodicityCategories.length > 0 && (
                        <>
                          <hr />
                          <h2 className="filter-wrap-news-title ">
                            {shopLandingOfferItem.periodicityTitle}
                          </h2>
                          {shopLandingOfferItem.periodicityCategories.map(
                            (offerGroup, index) => {
                              return (
                                <a
                                  key={index}
                                  href={
                                    obj.urlPrefix +
                                    obj.slash +
                                    obj.strPageItemName +
                                    obj.slash +
                                    shopLandingOfferItem.periodicitySegment +
                                    obj.slash +
                                    offerGroup.categoryUrl
                                  }
                                  className={offerGroup.class}
                                  aria-expanded="false"
                                  onClick={(e) =>
                                    fnGtmCategorySearchFilter(
                                      e.target,
                                      '',
                                      offerGroup.perodicityItemName,
                                      offerGroup.offersCount,
                                      'Offers',
                                      'Offers List'
                                    )
                                  }
                                >
                                  {offerGroup.categoryName} ({offerGroup.offersCount})
                                </a>
                              );
                            }
                          )}
                        </>
                      )}
                    {shopLandingOfferItem.commercialCategories != null &&
                      shopLandingOfferItem.commercialCategories.length > 0 && (
                        <>
                          <hr />
                          <h2 className="filter-wrap-news-title">
                            {shopLandingOfferItem.commercialCategoryTitle}
                          </h2>
                          {shopLandingOfferItem.commercialCategories.map(
                            (offerGroup, index) => {
                              return (
                                <a
                                  key={index}
                                  href={
                                    obj.urlPrefix +
                                    obj.slash +
                                    obj.strPageItemName +
                                    obj.slash +
                                    shopLandingOfferItem.categorySegment +
                                    obj.slash +
                                    offerGroup.categoryUrl
                                  }
                                  className={offerGroup.class}
                                  aria-expanded="false"
                                  onClick={(e) =>
                                    fnGtmCategorySearchFilter(
                                      e.target,
                                      offerGroup.categoryName,
                                      '',
                                      offerGroup.offersCount,
                                      'Offers',
                                      'Offers List'
                                    )
                                  }
                                >
                                  {offerGroup.categoryName} ({offerGroup.offersCount})
                                </a>
                              );
                            }
                          )}
                        </>
                      )}
                    {(shopLandingOfferItem.loyaltyCardOffersCount > 0 ||
                      shopLandingOfferItem.workerCardOffersCount > 0) && (
                        <>
                          <hr />
                          <h2 className="filter-wrap-news-title">
                            {obj.offerEligibility}
                          </h2>
                          {shopLandingOfferItem.loyaltyCardOffersCount > 0 && (
                            <a
                              href={obj.urlPrefix + obj.eligibilityFilterURL?.toLowerCase()}
                              onClick={(e) =>
                                fnGtmCategorySearchFilter(
                                  e.target,
                                  obj.offerEligibilityLoyalty,
                                  '',
                                  shopLandingOfferItem.loyaltyCardOffersCount,
                                  'Offers',
                                  'Offers List'
                                )
                              }
                              className={obj.filterLoyaltyCard}
                              aria-expanded="false"
                            >
                              {obj.offerEligibilityLoyalty} (
                              {shopLandingOfferItem.loyaltyCardOffersCount})
                            </a>
                          )}
                          {(shopLandingOfferItem.isWorker && shopLandingOfferItem.workerCardOffersCount > 0) && (
                            <a
                              href={obj.urlPrefix + obj.workedFilterURL?.toLowerCase()}
                              onClick={(e) =>
                                fnGtmCategorySearchFilter(
                                  e.target,
                                  obj.offerEligibilityWorker,
                                  '',
                                  shopLandingOfferItem.workerCardOffersCount,
                                  'Offers',
                                  'Offers List'
                                )
                              }
                              className={obj.filterWorkersCard}
                              aria-expanded="false"
                            >
                              {obj.offerEligibilityWorker} (
                              {shopLandingOfferItem.workerCardOffersCount})
                            </a>
                          )}
                        </>
                      )}
                    {shopLandingOfferItem.parentStoreCategories != null &&
                      shopLandingOfferItem.parentStoreCategories.length > 0 && (
                        <>
                          <hr />
                          <h2 className="filter-wrap-news-title">
                            {obj.storeCategories}
                          </h2>
                          {shopLandingOfferItem.parentStoreCategories.map(
                            (parentCategory, index) => {
                              if (parentCategory != null) {
                                var parentmatch =
                                  shopLandingOfferItem.categoryStoreCount.filter(
                                    (a) => a.catId === parentCategory.categoryId
                                  )[0];
                                let url = obj.slash + obj.strPageItemName;
                                let parentCategoryFilterURL =
                                  url +
                                  obj.slash +
                                  (parentCategory.name != null && parentCategory.name != ''
                                    ? parentCategory.urlName
                                    : '');
                                let parentSelectedClass = '';
                                if (
                                  parentCategory.name.trim().toLowerCase() ===
                                  obj.strParentCategory.trim().toLowerCase()
                                ) {
                                  if (
                                    obj.strChildCategory == null ||
                                    obj.strChildCategory == ''
                                  ) {
                                    parentSelectedClass = 'selected';
                                  } else {
                                    parentSelectedClass = 'active';
                                  }
                                }
                                return (
                                  <>
                                    {parentmatch != null && parentmatch.offersCount > 0 && (
                                      <a key={index}
                                        href={
                                          obj.urlPrefix + parentCategoryFilterURL?.toLowerCase()
                                        }
                                        onClick={(e) =>
                                          fnGtmCategorySearchFilter(
                                            e.target,
                                            parentCategory.name,
                                            '',
                                            parentmatch.offersCount,
                                            'Offers',
                                            'Offers List'
                                          )
                                        }
                                        className={'collapsed ' + parentSelectedClass}
                                        aria-expanded={
                                          parentSelectedClass != null &&
                                            parentSelectedClass != ''
                                            ? 'true'
                                            : 'false'
                                        }
                                      >
                                        {parentCategory.name} (
                                        {parentmatch != null ? parentmatch.offersCount : 0})
                                      </a>
                                    )}
                                    {shopLandingOfferItem.childStoreCategories != null &&
                                      shopLandingOfferItem.childStoreCategories.length >
                                      0 && (
                                        <ul
                                          className={
                                            'filter-item-list ' +
                                            (parentSelectedClass != null &&
                                              parentSelectedClass != ''
                                              ? ''
                                              : 'collapse') +
                                            (parentSelectedClass === 'selected'
                                              ? parentSelectedClass
                                              : '')
                                          }
                                          id={parentCategoryFilterURL}
                                          aria-expanded={
                                            parentSelectedClass != null &&
                                              parentSelectedClass != ''
                                              ? 'true'
                                              : 'false'
                                          }
                                          style={{
                                            height:
                                              parentSelectedClass != null &&
                                                parentSelectedClass != ''
                                                ? ''
                                                : '0px;',
                                          }}
                                        >
                                          {shopLandingOfferItem.childStoreCategories.map(
                                            (subCategory, index) => {
                                              if (subCategory != null) {
                                                let childSelectedClass = '';

                                                if (
                                                  subCategory.name.toString().toLowerCase() ===
                                                  obj.strChildCategory.toString().toLowerCase()
                                                ) {
                                                  childSelectedClass = 'selected';
                                                }
                                                if (
                                                  subCategory._links.parent.parentId ===
                                                  parentCategory.categoryId
                                                ) {
                                                  var match =
                                                    shopLandingOfferItem.categoryStoreCount.filter(
                                                      (a) => a.catId === subCategory.categoryId
                                                    )[0];
                                                  let childcategoryFilterURL =
                                                    url +
                                                    obj.slash +
                                                    parentCategory.urlName +
                                                    obj.slash +
                                                    (subCategory.name != null &&
                                                      subCategory.name != ''
                                                      ? subCategory.urlName
                                                      : '');
                                                  if (match != null && match.offersCount > 0) {
                                                    return (
                                                      <li key={index}>
                                                        <a
                                                          href={
                                                            obj.urlPrefix +
                                                            childcategoryFilterURL?.toLowerCase()
                                                          }
                                                          onClick={(e) =>
                                                            fnGtmCategorySearchFilter(
                                                              e.target,
                                                              subCategory.name,
                                                              '',
                                                              match.offersCount,
                                                              'Offers',
                                                              'Offers List'
                                                            )
                                                          }
                                                          className={childSelectedClass}
                                                          data-analyticslabel={subCategory.name}
                                                        >
                                                          {subCategory.name} (
                                                          {match != null
                                                            ? match.offersCount
                                                            : 0}
                                                          )
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              }
                                            }
                                          )}
                                        </ul>
                                      )}
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      )}
                    {shopLandingOfferItem.childRestaurantCategories != null &&
                      shopLandingOfferItem.childRestaurantCategories.length > 0 && (
                        <>
                          <hr />
                          <h2 className="filter-wrap-news-title">
                            {obj.restaurantCategories}
                          </h2>
                          {shopLandingOfferItem.childRestaurantCategories.map(
                            (subCategory, index) => {
                              if (subCategory != null) {
                                let url = obj.slash + obj.strPageItemName;
                                let subCategoryFilterURL =
                                  url +
                                  obj.slash +
                                  (subCategory.name != null && subCategory.name != ''
                                    ? subCategory.urlName
                                    : '');
                                let childSelectedClass = '';
                                if (
                                  subCategory.name.trim().toLowerCase() ===
                                  obj.strParentCategory.trim().toLowerCase()
                                ) {
                                  {
                                    childSelectedClass = 'selected';
                                  }
                                }
                                var subCategoryMatch =
                                  shopLandingOfferItem.categoryRestaurantCount.filter(
                                    (a) => a.catId === subCategory.categoryId
                                  )[0];
                                if (
                                  subCategoryMatch != null &&
                                  subCategoryMatch.offersCount > 0
                                ) {
                                  return (
                                    <a key={index}
                                      href={obj.urlPrefix + subCategoryFilterURL?.toLowerCase()}
                                      className={childSelectedClass}
                                      onClick={(e) =>
                                        fnGtmCategorySearchFilter(
                                          e.target,
                                          subCategory.name,
                                          '',
                                          subCategoryMatch.offersCount,
                                          'Offers',
                                          'Offers List'
                                        )
                                      }
                                    >
                                      {subCategory.name} ({subCategoryMatch.offersCount})
                                    </a>
                                  );
                                }
                              }
                            }
                          )}
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9 filter-result-wrap">
                <div className="filter-alphabetic-old letters">
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(
                        e,
                        'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,Others',
                        e.currentTarget
                      );
                    }}
                    className="filter bg-lighter-plus text-dark allFilter "
                    data-gtmletterfilter="All"
                    data-testid="testClick1"
                  >
                    <span className="vertical-align text-uppercase all"> {obj.filterAll} </span>
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'A,B', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="A B"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> a b </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'C,D', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="C D"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> c d </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'E,F', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="E F"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> e f </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'G,H', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="G H"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> g h </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'I,J', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="I J"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> i j </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'K,L', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="K L"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> k l </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'M,N', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="M N"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> m n </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'O,P', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="O P"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> o p </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'Q,R', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="Q R"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> q r </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'S,T', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="S T"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> s t </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'U,V', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="U V"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> u v </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'W,X', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="W X"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> w x </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'Y,Z', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="Y Z"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> y z </span>{' '}
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      return fnAlphabetFilter(e, 'Others', e.currentTarget);
                    }}
                    className="filter bg-lighter-plus text-dark disablehref"
                    data-gtmletterfilter="0-9"
                  >
                    {' '}
                    <span className="vertical-align text-uppercase"> 0-9 </span>{' '}
                  </a>
                </div>
                {shopLandingOfferItem.personalizedOfferItems.length > 0 && (
                  <div
                    className="bg-beige filter-result logged-user d-md-none"
                    id="PersonalizedofferHref"
                  >
                    <div className="content-title text-start d-block">
                      {shopLandingOfferItem.personalizedOffers}
                    </div>
                    <div className="offers personal-offers">
                      <PersonalizedOffers
                        model={shopLandingOfferItem}
                        obj={fields.obj}
                      />
                    </div>
                  </div>
                )}
                {shopLandingOfferItem.topOffers != null &&
                  shopLandingOfferItem.topOffers
                    // .filter((x) => {
                    //   if (
                    //     !isWorker && x.offer.eligibilities.length > 0 &&
                    //     x.offer.eligibilities[0] === 1034
                    //   ) {
                    //     return false;
                    //   }
                    //   return true;
                    // })
                    .length > 0 && (
                    <>
                      <div className="filter-result">
                        <div className="top-offers">

                          <div className="content-title offers-title" id="topofferTitle">
                            {shopLandingOfferItem.topOffersTitle}
                          </div>
                          <div
                            className={
                              'row top-offers ' +
                              (obj.isNewBrandedSite ? '' : '') +
                              ' contentSection'
                            }
                            id="topOffersDiv"
                            data-title="topofferTitle"
                          >
                            {shopLandingOfferItem.isEuropeanSite ? (
                              <ShopLandingOfferInnerPage
                                model={modelObj}
                                landingOfferModel={landingOfferModel}
                                values={shopLandingOfferItem.offersCount}
                              // isWorker={isWorker}
                              />
                            ) : (
                              <Deals
                                model={modelObj}
                                landingOfferModel={landingOfferModel}
                                values={shopLandingOfferItem.offersCount}
                              // isWorker={isWorker}
                              />
                            )}
                          </div>

                        </div>
                      </div>
                    </>
                  )}

                {shopLandingOfferItem.groupedOffers != null &&
                  shopLandingOfferItem.groupedOffers.length > 0 &&
                  shopLandingOfferItem.groupedOffers.map((offerGroup, index) => {
                    if (offerGroup == null) {
                      return;
                    }
                    var groupedOffersModel = JSON.parse(modelJson);
                    groupedOffersModel.items = offerGroup.offers;
                    groupedOffersModel.offerItems = groupedOffersModel.items;
                    let isOtherOffers = true;
                    let contentTitleid = offerGroup.commercialCategoryTitle;
                    return (
                      <>
                        <div key={index} className="filter-result">
                          <div className="top-offers">
                            <div className="content-title offers-title" id={contentTitleid}>
                              {offerGroup?.key?.name}
                            </div>
                            <div
                              className={
                                'row commercial-offers ' +
                                (obj.isNewBrandedSite ? '' : '') +
                                ' contentSection ' +
                                (isOtherOffers ? 'otherOffers' : '')
                              }
                              data-title={contentTitleid}
                            >
                              {shopLandingOfferItem.isEuropeanSite ? (
                                <ShopLandingOfferInnerPage
                                  model={groupedOffersModel}
                                  landingOfferModel={landingOfferModel}
                                  values={shopLandingOfferItem.offersCount}
                                // isWorker={isWorker}
                                />
                              ) : (
                                <Deals
                                  model={groupedOffersModel}
                                  landingOfferModel={landingOfferModel}
                                  values={shopLandingOfferItem.offersCount}
                                // isWorker={isWorker}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            {/* {obj.isNewBrandedSite && <hr className="mt-5 mb-0" />} */}
          </div>
        </section>
      </>
    );
  }
  else {
    return (<></>)
  }
}

// enables/disables the alphabet filters based on whether content available for that alphabet or not
function fnUpdateAlphabetsFilters() {
  var elements = document.querySelectorAll('#offersComponent .letters .disablehref');
  elements.forEach(x => {
    var element = x;
    var filterLetters = x.getAttribute('data-gtmletterfilter').split(' ');
    for (var i = 0; i < filterLetters.length; i++) {
      var filterLetter = filterLetters[i];
      if (filterLetter == '0-9') {
        filterLetter = 'Others';
      }
      if (document.querySelectorAll("#offersComponent .contentBlock[data-letterfilter='" + filterLetter + "']").length > 0) {
        element.classList.remove('disablehref');
      }
    }
  });
}

//Applies the alphabet filter based on the current filter selection
function fnAlphabetFilter(event, filterAlphabets, filterElmt) {
  event.preventDefault();
  if (filterElmt.classList.contains('disablehref')) { //if current filter element is disabled, then do not process
    event.nativeEvent.stopImmediatePropagation();
    return false;
  }
  document.querySelectorAll(".contentBlock").forEach(x => { x.classList.add("hidden") }); //hide all the blocks (e.g., all offer blocks)
  document.querySelectorAll(".contentSection").forEach(x => { x.classList.add("hidden") }); //hide the parent section as well
  document.querySelectorAll(".content-title.offers-title").forEach(x => { x.classList.add("hidden") });
  document.querySelectorAll(".offer-anchor").forEach(x => { x.classList.add("hidden") }); //hide the offeranchor as well
  document.querySelectorAll(".letters a.active").forEach(x => { x.classList.remove("active") }); //make all the other alphabet filters in-active
  filterElmt.classList.add("active"); //make only the currently selected alphabet filter as 'active'

  //based on alphabet filter selected, show only those content blocks and section 
  var filterAlphabetsList = filterAlphabets.split(",");
  fnFilterUpdatePersonalizedOffers(filterAlphabetsList);//function to update the Personalized offers section in 'Offers' component based on the alphabet filter selected
  for (var i = 0; i < filterAlphabetsList.length; i++) {
    var elementsBlock = document.querySelectorAll(".contentBlock[data-letterfilter='" + filterAlphabetsList[i] + "']");
    elementsBlock.forEach(x => {
      x.classList.remove("hidden");
      if (x.closest(".contentSection") && x.closest(".contentSection").classList.contains("hidden"))
        x.closest(".contentSection").classList.remove("hidden");
      if (x.closest(".contentSection") && x.closest(".contentSection").previousElementSibling.classList.contains("hidden")) {
        x.closest(".contentSection").previousElementSibling.classList.remove("hidden");
        if (x.closest(".contentSection").id.toString() == "topOffersDiv") {
          if (document.getElementById('topOffersAnchor'))
            document.getElementById('topOffersAnchor').classList.remove('hidden');
        }
      }
    });
  }
  fnFilterUpdateTopOffers(filterAlphabetsList); //function to update the Top offers section in 'Offers' component based on the alphabet filter selected    
  CommonBookmarkallpage();
  lazyLoadingImg();
}

function fnFilterUpdatePersonalizedOffers(filterAlphabetsList) {
  document.querySelectorAll(".contentBlockPersonalized").forEach(x => { x.classList.add("hidden") }); //hide all the blocks (e.g., all offer blocks)
  document.querySelectorAll(".contentSectionPersonalized").forEach(x => { x.classList.add("hidden") }); //hide the parent section as well
  document.querySelectorAll(".content-title.offers-title.Personalized").forEach(x => { x.classList.add("hidden") });
  document.querySelectorAll(".bg-beige.logged-user").forEach(x => { x.classList.add("hidden") });

  if (filterAlphabetsList.length != 27) {
    for (var i = 0; i < filterAlphabetsList.length; i++) {
      var elementsBlock = document.querySelectorAll(".contentBlockPersonalized[data-letterfilter='" + filterAlphabetsList[i] + "']");
      elementsBlock.forEach(x => {
        x.classList.remove("hidden");
        if (x.closest(".contentSectionPersonalized") && x.closest(".contentSectionPersonalized").classList.contains("hidden"))
          x.closest(".contentSectionPersonalized").classList.remove("hidden");
        if (x.closest(".contentSectionPersonalized") && x.closest(".contentSectionPersonalized").previousElementSibling.classList.contains("hidden")) {
          x.closest(".contentSectionPersonalized").previousElementSibling.classList.remove("hidden");
          if (x.closest(".contentSectionPersonalized").id.toString() == "topOffersDiv")
            document.getElementById('topOffersAnchor').classList.remove('hidden');
          else {
            if (document.querySelector('#' + x.closest(".contentSectionPersonalized").previousElementSibling.id.toString() + 'anchor'))
              document.querySelector('#' + x.closest(".contentSectionPersonalized").previousElementSibling.id.toString() + 'anchor').classList.remove('hidden');
          }

        }
      });

      document.querySelectorAll(".bg-beige.logged-user").forEach(x => { x.classList.remove("hidden") });
    }
  }
  else {

    document.querySelectorAll(".OnLoadoffers").forEach(x => { x.classList.remove("hidden") });
    document.querySelectorAll(".contentSectionPersonalized").forEach(x => { x.classList.remove("hidden") });
    document.querySelectorAll(".content-title.offers-title.Personalized").forEach(x => { x.classList.remove("hidden") });
    document.querySelectorAll(".bg-beige.logged-user").forEach(x => { x.classList.remove("hidden") });

  }
}

//Filter the top offers section in Offers component based on alphabet filter selected
//This section should display randomly, max 6 top offers from 'Other offers' section
function fnFilterUpdateTopOffers(filterAlphabetsList) {
  var topOtherOffersList = new Array();

  // from 'Other offers' section, get all top offers related to selected alphabets
  for (var i = 0; i < filterAlphabetsList.length; i++) {
    var elements = document.querySelectorAll(".otherOffers .contentBlock[data-letterfilter='" + filterAlphabetsList[i] + "']");
    elements.forEach(x => function () {
      if (x.getAttribute('data-istop') == 'True') {
        topOtherOffersList.push(x.cloneNode(true)); //clone- this offer should not be removed from original place (i.e., 'Other offers' section), just a copy is added to 'Top Offers'
      }
    });
  }
  //from this list of top offers, select random max 6 top offers and display it in 'Top Offers' section
  if (topOtherOffersList.length > 0) {
    var minNumber = 1;
    var maxNumber = topOtherOffersList.length;
    var randomNumber = Math.floor(Math.random() * ((maxNumber - minNumber) + 1) + minNumber);

    document.querySelectorAll('#topOffersDiv .contentBlock').remove();
    for (i = 0; (i < 6 && i < topOtherOffersList.length); i++) {
      if (randomNumber >= topOtherOffersList.length) {
        randomNumber = 0;
      }
      document.getElementById('topOffersDiv').append(topOtherOffersList[randomNumber]);
      randomNumber++;
    }
    document.getElementById('topOffersDiv').classList.remove('hidden');
    document.getElementById('topOffersDiv').previousElementSibling.classList.remove('hidden');
    document.getElementById('topOffersAnchor').classList.remove('hidden');
  }
}
