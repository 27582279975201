import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function MallPageIntroduction({ fields }) {

  if (fields?.mallIntroduction !== null && fields?.mallIntroduction !== undefined && fields?.mallIntroduction !== "" && fields?.mallIntroduction?.offerDescription !== null && fields?.mallIntroduction?.offerDescription?.value !== "") {
    return (
      <section className="welcome  text-center" style={{ overflow: "hidden" }}>
        <div className="container">
          {(fields.mallIntroduction.offerTitle !== "") &&
            (
              <h2 className="text-uppercase"><RichText field={fields.mallIntroduction.offerTitle} /></h2>
            )}
          <div>
            <RichText field={fields.mallIntroduction.offerDescription} />
          </div>
        </div>
      </section>

    );

  } else {
    return <div></div>;
  }

}



