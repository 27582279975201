import React from "react";
import OffersAndnewsInnerPage from '../OffersAndNewsInnerPage';

export default function OffersAndNews({ fields }) {

  if (fields && fields.offerNewMV !== null) {
    return (
      <>
        <OffersAndnewsInnerPage newsData={fields.offerNewMV} />
        <div className="loadersmall asyncloader invisible" id="offersnewsloader">
          <div className="dot-spin top-left"></div>
        </div>
      </>
    );
  }
  else {
    return (
      <></>
    )
  }
}
