import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import { lazyLoadingImg } from '../Js-CommonFunctions.js';

export default function DiscoverMallHistory({ fields }) {

    useEffect(() => {
        lazyLoadingImg();
    }, []);

    if (fields != null && fields?.mallHistory?.historyList != null && fields?.mallHistory?.historyList.length > 0) {
        var constant = fields.constants;
        var content = fields.json.items;
        return (
            <section className='history'>
                <div className='container'>
                    <section className="history history-centre" id="history">
                        <div className="container p-lg-0">

                            {content.map((historyList, index) => {
                                var i = 0;
                                return (
                                    (historyList.fields[constant.history_Title]?.value != "" ?
                                        (<div key={index} className="row">
                                            {(fields.mallHistory.history != null && fields.mallHistory.history != "") &&
                                                <Text className="history-page-title text-black d-lg-none d-block p-lg-1" field={fields.mallHistory.history} tag="h2" />
                                            }
                                            <div className="col-12 d-flex big-block justify-content-between">
                                                <div className="row col-12 col-lg-6 m-0 history-page-desc">
                                                    <h2 className={"history-page-title text-black "+(fields.mallHistory.isNewBrandedSite ? "d-lg-block d-none p-0" : "p-lg-1")}>Histoire</h2>
                                                    {(historyList.fields[constant.history_Title] != null && historyList.fields[constant.history_Title]?.value != "") &&
                                                         <div className="history-page-subtitle p-0"><RichText field={historyList.fields[constant.history_Title]} /></div>            
                                                    }
                                                    <div className={(fields.mallHistory.isNewBrandedSite ? "" : "") + "hostory-description p-0"}>
                                                        <div className="p-0 text-start">
                                                            <RichText field={historyList.fields[constant.history_Description]} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {(fields.mallHistory.isExperienceEditor) ?
                                                    (<div className="img-wrap col-12 col-lg-6 px-lg-3">
                                                        <Image className="history-img" field={historyList.fields[constant.history_Image]} />
                                                    </div>) :
                                                    (
                                                        (historyList != null && historyList.fields[constant.history_Image] != null && historyList.fields[constant.history_Image]?.value?.src != null) &&
                                                        <div className="img-wrap col-12 col-lg-6 px-lg-3">
                                                            <img className="history-img" src={fields.mallHistory.defaultImage} data-alt={historyList.fields[constant.history_Image]?.value?.src} alt={historyList.fields[constant.history_Image]?.value?.alt} />
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>) :
                                        (
                                            <div className="row">
                                                <div className={(fields.mallHistory.isNewBrandedSite ? "col-md-12 big-block" : "col-md-6") + " col-12 history-block"}>
                                                    {(historyList.fields[constant.history_Date] != null && historyList.fields[constant.history_Date]?.value != "") &&
                                                        <h3 className="title">
                                                            <RichText field={historyList.fields[constant.history_Date]} />
                                                        </h3>
                                                    }
                                                    {(fields.mallHistory.isExperienceEditor) ?
                                                        (<div className="img-wrap">
                                                            <Image field={historyList.fields[constant.history_Image]} />
                                                        </div>) :
                                                        (
                                                            (historyList != null && historyList.fields[constant.history_Image] != null && historyList.fields[constant.history_Image]?.value?.src != null) &&
                                                            <div className="img-wrap">
                                                                <img src={fields.mallHistory.defaultImage} data-alt={historyList.fields[constant.history_Image]?.value?.src} alt={historyList.fields[constant.history_Image]?.value?.alt} />
                                                            </div>
                                                        )
                                                    }
                                                    <div className="description">
                                                        <RichText field={historyList.fields[constant.history_Description]} />
                                                    </div>
                                                </div>
                                                {<input type="hidden" value={i = i + 1}></input>}
                                                {(i < fields.mallHistory.historyList.length && fields.mallHistory.historyList[i].history_Title == "") ?
                                                    (<div className={(fields.mallHistory.isNewBrandedSite ? "col-md-12 big-block" : "col-md-6") + " col-12 history-block"}>
                                                        {(historyList.fields[constant.history_Date] != null && historyList.fields[constant.history_Date]?.value != "") &&
                                                            <h3 className="title">
                                                                <RichText field={historyList.fields[constant.history_Date]} />
                                                            </h3>
                                                        }
                                                        {(fields.mallHistory.isExperienceEditor) ?
                                                            (<div className="img-wrap">
                                                                <Image field={historyList.fields[constant.history_Image]} />
                                                            </div>) :
                                                            (
                                                                (historyList != null && historyList.fields[constant.history_Image] != null && historyList.fields[constant.history_Image]?.value?.src != null) &&
                                                                <div className="img-wrap">
                                                                    <img src={fields.mallHistory.defaultImage} data-alt={historyList.fields[constant.history_Image]?.value?.src} alt={historyList.fields[constant.history_Image]?.value?.alt} />
                                                                </div>
                                                            )
                                                        }
                                                        <div className="description">
                                                            <RichText field={historyList.fields[constant.history_Description]} />
                                                        </div>
                                                    </div>)
                                                    : (<input type="hidden" value={i = i - 1}></input>)
                                                }
                                            </div>
                                        )
                                    )
                                )
                            }
                            )}
                        </div>
                    </section>
                </div>
            </section>
        )
    }
    else {
        return (<></>)
    }
}
