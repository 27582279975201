import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';
import axios from "axios";
import { CommonBookmarkallpage, lazyLoadingImg, offersTooltipOnHover, webViewEqualsOne } from '../Js-CommonFunctions';
const ShopLandingOfferInnerPage = loadable(() => import("../ShopLandingOfferInnerPage"));



export default function ShopLandingOffers({ fields }) {
  const [visible, setVisible] = useState(0);
  const [shopLandingData, setShopLandingData] = useState();
  const [modelData, setModelData] = useState();
  let isperPage = 0;
  let workerCenter = useSelector((state) => state.user.workerCenter);


  useEffect(() => {
    if (fields.shopLandingOfferItem !== null) {

      let isWorker =
        workerCenter !== undefined &&
        workerCenter !== '' &&
        workerCenter.split(',').includes(baseModel.pC_Code);

      if (isWorker) {
        var loader = document.getElementById("sloffersloader")

        if (loader) {
          loader.classList.remove("invisible");
          loader.classList.add("visible");
        }
        axios({
          method: "get",
          url: "/api/sitecore/OffersApi/GetShopLandingOffersData?ursite=" +
            baseModel.urContextSite +
            "&urlang=" +
            baseModel.contextLang +
            "&queryStringItemId=" +
            fields.shopLandingOfferItem.queryStringItemId +
            "&queryStringItemName=" +
            fields.shopLandingOfferItem.queryStringItemName +
            "&offerTypeParameter=" +
            fields.shopLandingOfferItem.offerTypeParameter +
            "&leftLink=" + fields.shopLandingOfferItem.leftLink +
            "&rightLink=" + fields.shopLandingOfferItem.rightLink +
            "&isNewsDetailPage=" + fields.shopLandingOfferItem.isNewsDetailPage +
            "&destinationType=" + fields.shopLandingOfferItem.destinationType +
            "&destinationTitle=" + fields.shopLandingOfferItem.destinationTitle +
            "&isStoreOrRestaurantDetailPage=" + fields.shopLandingOfferItem.isStoreOrRestaurantDetailPage +
            "&isWorker=" + isWorker
          ,
          async: false,
        })
          .then(function (response) {
            if (loader) {
              loader.classList.add("invisible");
              loader.classList.remove("visible");
            }
            if (response.data) {
              if (response.data !== null && response.data.shopLandingOfferItem != null && response.data.shopLandingOfferItem.totalOffersCount !== null && (response.data.shopLandingOfferItem.totalOffersCount > 0 || response.data.shopLandingOfferItem.offerTypeParameter === response.data.shopLandingOfferItem.destination)) {
                if (document.getElementById("offers"))
                  document.getElementById("offers").style.display = "block";
                setShopLandingData(response.data.shopLandingOfferItem);
                setModelData(response.data.model);
                if (response.data.shopLandingOfferItem.offerTypeParameter === response.data.shopLandingOfferItem.destination) {
                  isperPage = 8;
                  setVisible(isperPage);
                } else {
                  isperPage = response.data.shopLandingOfferItem.offerItems.length;
                  setVisible(response.data.shopLandingOfferItem.offerItems.length);

                }
              }
              else {
                if (document.getElementById("offers"))
                  document.getElementById("offers").style.display = "none";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            if (loader) {
              loader.classList.add("invisible");
              loader.classList.remove("visible");
            }
          });
      }
      else {
        if (fields && fields.shopLandingOfferItem !== null && fields.shopLandingOfferItem.totalOffersCount !== null && (fields.shopLandingOfferItem.totalOffersCount > 0 || fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.destination)) {
          if (document.getElementById("offers"))
            document.getElementById("offers").style.display = "block";
        }
        else{
          if (document.getElementById("offers"))
            document.getElementById("offers").style.display = "none";
        }
        setShopLandingData(fields.shopLandingOfferItem);
        setModelData(fields.model);
        if (fields.shopLandingOfferItem.offerTypeParameter === fields.shopLandingOfferItem.destination) {
          isperPage = 8;
          setVisible(isperPage);
        } else {
          isperPage = fields.shopLandingOfferItem.offerItems.length;
          setVisible(fields.shopLandingOfferItem.offerItems.length);
        }
      }
    }
  }, []);

  useEffect(() => {
    lazyLoadingImg();
    CommonBookmarkallpage();
    offersTooltipOnHover();

  }, [visible, shopLandingData]);

  let isDestinationPage = false;
  let shoprestaurant = false;
  let isDetailPage = false;
  let destinationType = "asZX";
  let title = "";
  let itemId = ""
  let strContextPageName = "";
  let count = 0;


  if (shopLandingData != null && shopLandingData.offerTypeParameter != null) {
    if (shopLandingData.offerTypeParameter === shopLandingData.shopLanding || shopLandingData.offerTypeParameter === shopLandingData.restaurantLanding) {
      shoprestaurant = true;
      shopLandingData.displayCTA = true;
    }
    if (shopLandingData.offerTypeParameter === shopLandingData.destination) {
      isDestinationPage = true;
      shopLandingData.displayCTA = false;
      destinationType = shopLandingData != null && shopLandingData.destinationType != null && shopLandingData.destinationType !== "" ? shopLandingData.destinationType : "";
      title = (shopLandingData.destinationTitle !== "" && shopLandingData.destinationTitle !== null) ? shopLandingData.offers + shopLandingData.symbolHyphen + shopLandingData.destinationTitle : shopLandingData.offers;
    }
    if (shopLandingData.offerTypeParameter === shopLandingData.storeDetailOffer || shopLandingData.offerTypeParameter === shopLandingData.restaurantDetailOffer || shopLandingData.offerTypeParameter === shopLandingData.newsDetailOffer) {
      isDetailPage = true;
      shopLandingData.displayCTA = false;
    }
    itemId = shopLandingData.itemId;
    strContextPageName = shopLandingData.strContextPageName;
  }
  if (fields != null && fields !== undefined && fields !== "") {
    return (
      <section className='offers offers-new offers-store-new back-gray  position-relative' id="offers">
        {shopLandingData != null && shopLandingData.totalOffersCount !== null && (shopLandingData.totalOffersCount > 0 || isDestinationPage) &&
          <div className="container">
            {isDetailPage && (
              <div
                className='header-title d-flex align-items-baseline flex-wrap justify-content-between'
              >
                {
                  shopLandingData.isOfferPageExists &&
                    shopLandingData.offersCount > 0 ? (
                    <>
                      <a className="title gtmClickNavigation" href={shopLandingData.leftLink}>
                        <h2>
                          <span className="news-title">{shopLandingData.offers + " *"}</span>
                        </h2>
                      </a>
                      <div className="text-end my-2">
                        <a
                          className="grid-new-cta-black gtmClickNavigation"
                          href={shopLandingData.rightLink}
                        >
                          {shopLandingData.seeAllOffers}
                        </a>
                      </div>
                    </>
                  )
                    : (
                      <><div className={'title'}>
                        <h2>
                          {' '}
                          <span className="news-title">{shopLandingData.offers + " *"}</span>
                        </h2>

                      </div>
                      </>
                    )
                }
              </div>
            )}
            {isDestinationPage && (
              <div className="header-title d-flex align-items-baseline flex-wrap justify-content-between mb-3 destination">
                {shopLandingData.isOfferPageExists &&
                  shopLandingData.offersCount > 0 ? (
                  <>
                    <h2 className='title'>
                      <a className="gtmClickNavigation" href={shopLandingData.leftLink}>
                        {shopLandingData.offers}
                      </a>{' '}
                      {shopLandingData.symbolHyphen}{' '}
                      <span>{shopLandingData.destinationTitle}</span>
                    </h2>
                    <div className="text-end my-2">
                      <a className="grid-new-cta-black gtmClickNavigation" href={shopLandingData.rightLink}>
                        <strong>{shopLandingData.seeAllOffers}</strong>{' '}
                        {'(' + shopLandingData.offersCount + ')'}
                        <span className="picto icon-ico-chevron-right"></span>
                      </a>
                    </div>
                  </>
                ) : (
                  title !== '' &&
                  title !== null && (
                    <h2 className="title ">
                      <span>{title}</span>
                    </h2>
                  )
                )}
              </div>
            )}
            {shoprestaurantCondition(shopLandingData, shoprestaurant)}
            <>
              <div className="row " id="offersresult">
                <ShopLandingOfferInnerPage
                  model={shopLandingData}
                  landingOfferModel={modelData}
                  values={visible}
                />
              </div>
              {shopLandingData.totalOffersCount > 0 && (
                <>
                  {Innercondtion(shopLandingData)}
                </>
              )}
            </>
          </div>
        }
        <div className="loadersmall asyncloader invisible" id="sloffersloader">
          <div className="dot-spin top-left"></div>
        </div>
      </section>
    );

  } else {
    return <></>;
  }
}



function shoprestaurantCondition(shopLandingData, shoprestaurant) {
  if (shoprestaurant) {
    return (
      <div className="header-title d-flex align-items-baseline flex-wrap justify-content-between">
        <a className="title" href={shopLandingData.leftLink}>
          <h2><span className="news-title">{shopLandingData.totalOffersCount > 1 ? shopLandingData.offers + " *" : shopLandingData.offer}</span></h2>
        </a>
        <div className="text-end my-2">
          <a
            className="grid-new-cta-black gtmClickNavigation"
            href={shopLandingData.rightLink}
          >
            {shopLandingData.seeAllOffers}
          </a>
        </div>
      </div>
    )
  }
}

function Innercondtion(shopLandingData) {
  if (shopLandingData.totalOffersCount > 0) {
    return (
      <div className="more-info ">
        {shopLandingData.cezOffer}
      </div>
    )
  }
}
