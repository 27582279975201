import React, { useEffect, useState } from "react";
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { replaceQueryString } from '../Js-CommonFunctions';
import axios from "axios";
import { baseModel } from "../../RouteHandler";


export default function MultiNewsWithImageInnerPage({ newsImagedata }) {
  const [model, setModel] = useState(newsImagedata);

  let withPicsCount = 0;
  let indexcnt;
  let rowtwoflag = false;
  let picright = false;
  let contentTextClass;
  let contentBgClass;
  let contentArrowClass;
  let contentLinkClass;
  useEffect(() => {
      }, []);

  function fnViewMoreClick(dynamicId) {
    let genericUrl =
      "/api/sitecore/GenericApi/GetMultiNewsWithImageFilter?ursite=" + baseModel.urContextSite + "&urlang=" + baseModel.contextLang + "&itemId=" + model.itemSource + "&pageNo=" + "1" + "&uniqueid=" + model.dynamicid;

    var viewcount = parseInt(document.getElementById('offernewspageno' + model.dynamicid).getAttribute('value')) + 1;
    let url = replaceQueryString(genericUrl, 'pageNo', viewcount);
    axios({
      method: 'get',
      url: url,
      async: false
    }).then(function (response) {
      setModel(response.data)
    })
      .catch(function (error) {

        console.log(error);
      });

    return false;
  }
  var title;

  if (model.genericPageTitle != "" && model.genericPageTitle !== null && (model.genericPageTitle.type !== null && model.genericPageTitle.type !== "")) {
    title = { value: model.genericPageTitle.type }
  }
  return (
    <section className={"whats-hot" + (model.isNewBrandedsite ? " back-gray p-2" : "")} id={"news" + (model.dynamicid)} >

      {(!model.isNewBrandedsite && (model.genericPageTitle != null && model.genericPageTitle != "" && model.genericPageTitle.type !== null && model.genericPageTitle.type !== "")) &&
        <h2 className="content-title text-uppercase text-center underline"><RichText field={title} /></h2>
      }
      {(model.isNewBrandedsite) ?
        (<div className="container border-bottom">
          {(model.genericPageTitle != null && model.genericPageTitle != "" && model.genericPageTitle.type !== null && model.genericPageTitle.type !== "") &&
            <h2 className="page-title text-uppercase"><RichText field={title} /></h2>
          }
          <div className="row">
            <>{(model.multiINewsWithImages !== null && model.multiINewsWithImages.length > 0) &&

              model.multiINewsWithImages.map((item, index) => {
                if (item.imgurl) {
                  const picClass = !picright ? "right" : "left";
                  contentTextClass = !picright ? "left" : "right";
                  contentBgClass = "bg-lighter";
                  contentArrowClass = !picright ? " arrow-right" : "arrow-left";
                  picright = !picright;
                  withPicsCount++;
                  var url;
                  var image;
                  if (item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) {
                    url = { value: item.multiINewsWithImages.discover.value.url }
                  }
                  if (item.multiINewsWithImages.image.value.src !== "" && item.multiINewsWithImages.image.value.src !== null) {
                    image = { value: item.multiINewsWithImages.image.value.src }
                  }

                  return (
                    <div key={index} className="col-sm-12  item  picture-and-text">
                      <div className="clearfix">
                        <div className={"whats-hot-item whats-hot-picture " + picClass + " bg-lighter"}>

                          <Image field={item.multiINewsWithImages.image} />
                        </div>
                        <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass}>
                          <div className="whats-hot-content card-new">
                            <div className="vertical-align">
                              <div className="no-picto"></div>
                              {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&

                                <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                              }
                              <div className="description-news text-midgray"><RichText field={item.multiINewsWithImages.description} /></div>
                              {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                                <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={model.isNewBrandedsite ? "btn-link-news" : "link-button btn-transparent"} >
                                  {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })
            }
              {
                (model.multiINewsWithImages.length > 0) &&
                withOutPicsloop(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass)
              }

            </>
            <div className="col-md-12">
              <div className="mt-3 mb-3 text-center view-more" id={"viewmoreid" + model.dynamicid} data-testid="fnViewMoreClick" onClick={(e) => fnViewMoreClick(model.dynamicId)}>
                <input type="hidden" id={"offernewspageno" + model.dynamicid} value={model.pagecount} />
                {(model.isViewMoreRequired) &&
                  <div>{model.viewMore}</div>
                }
              </div>
            </div>

          </div>
        </div>

        ) :
        (
          <div className="container">
            {(model.multiINewsWithImages.length > 0) &&
              model.multiINewsWithImages.map((item, index) => {

                if (item.imgurl) {
                  let picClass = !picright ? "right" : "left";
                  contentTextClass = !picright ? "left" : "right";
                  contentBgClass = !picright ? "bg-lighter" : "bg-main";
                  contentArrowClass = !picright ? " arrow-right" : "arrow-left";
                  picright = !picright;
                  withPicsCount++;
                  var url;
                  if (item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) {
                    url = { value: item.multiINewsWithImages.discover.value.url }
                  }
                  return (
                    <div key={index} className="col-sm-12  item  picture-and-text">
                      <div className="clearfix">
                        <div className={"whats-hot-item whats-hot-picture " + picClass + " bg-light"}>
                          <Image field={item.multiINewsWithImages.image} />
                        </div>
                        <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " text-light " + contentArrowClass}>
                          <div className="whats-hot-content">
                            <div className="vertical-align">
                              <div className="no-picto"></div>
                              {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title.value !== "" && item.multiINewsWithImages.title.value !== null)) &&

                                <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                              }
                              <div className="description"><RichText field={item.multiINewsWithImages.description} /></div>
                              {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                                <a href={item.multiINewsWithImages.discover.value.url}  aria-label={item?.multiINewsWithImages?.discover} className={model.isNewBrandedsite ? "btn-link-news" : "link-button btn-transparent"} >
                                  {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })

            }
            {
              withOutPicsloop(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass)
            }
            <div className="mt-3 mb-1 text-black text-center text-uppercase view-more" id={"viewmoreid" + model.dynamicid} data-testid="fnViewMoreClick2" onClick={(e) => fnViewMoreClick(model.dynamicId)}>
              <input type="hidden" id={"offernewspageno" + (model.dynamicid)} value={model.pagecount} />
              {(model.isViewMoreRequired) &&
                <><div>{model.viewMore}</div><i className="picto icon-go-down-arrow"></i>
                </>
              }
            </div>
          </div >
        )
      }
    </section>
  )
}

let itemCountValue = -1;
let isRemoveItem = false;

function fnNewsmodelsItem(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass, withoutPicsCount) {

  let textright = false;
  if ((withoutPicsCount == 1) || (withoutPicsCount == 2 && rowtwoflag)) {

    return (
      ((withoutPicsCount == 1) || (withoutPicsCount == 2)) && !((withPicsCount == 3) && withoutPicsCount == 2) ?
        (model.multiINewsWithImages.map((item, index) => {
          if (!item.imgurl) {
            var url;
            if (item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) {
              url = { value: item.multiINewsWithImages.discover.value.url }
            }

            return (
              <div key={index} className="col-sm-12  item  picture-and-text">
                <div className="clearfix">
                  <div className="whats-hot-item whats-hot-text bg-dark text-light no-picture">
                    <div className="whats-hot-content">
                      <div className="vertical-align">
                        {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&

                          <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                        }
                        <div className={"description" + (model.isNewBrandedsite ? "-news text-light" : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                        {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                          <a href={item.multiINewsWithImages.discover.value.url} className={"link-button btn-transparent"} >
                            {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                          </a>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })
        ) :
        (((withoutPicsCount == 1) || (withoutPicsCount == 2)) ?
          (
            model.multiINewsWithImages.map((item, index) => {
              if (!item.imgurl) {
                contentTextClass = textright ? "text-dark" : "text-light";
                contentBgClass = textright ? "bg-light" : "bg-main";
                contentLinkClass = textright ? " link-dark" : "";
                let marginClass = textright ? "no-margin-left" : "no-margin-right";
                textright = !textright;
                var url;

                if (item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) {
                  url = { value: item.multiINewsWithImages.discover.value.url }
                }

                return (
                  <div key={index} className={"col-sm-6 " + marginClass + " item"}>
                    <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " " + contentLinkClass}>
                      <div className="whats-hot-content">
                        <div className="vertical-align">
                          {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&
                            <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /> </h3>
                          }
                          <div className={"description" + (model.isNewBrandedsite ? "-news " + contentTextClass : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                          {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                            <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={"link-button btn-transparent"} >
                              {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                            </a>

                          }
                        </div>
                      </div>
                    </div>
                  </div>

                )
              }
            })
          ) :
          (<></>)

        )
    )
  }
  else if (((withoutPicsCount % 2) == 0) || ((withoutPicsCount % 2) == 1)) {
    textright = false;
    isRemoveItem = false;
    let withoutPicsClass = "";
    return (
      <>
        {spilcecond(model, withoutPicsCount)}
        {model.multiINewsWithImages.map((item, index) => {
          if (!item.imgurl) {
            contentTextClass = textright ? "text-dark" : "text-light";
            contentBgClass = textright ? "bg-light" : "bg-main";
            contentLinkClass = textright ? " link-dark" : "";
            textright = !textright;
            if (isRemoveItem) {
              withoutPicsClass = itemCountValue === index ? "d-none " : " d-block";
            }
            var url;

            if (item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) {
              url = { value: item.multiINewsWithImages.discover.value.url }
            }

            return (
              <div key={index} className={"col-sm-6  no-margin-left  item " + withoutPicsClass}>
                <div className={"whats-hot-item whats-hot-text " + contentTextClass + " " + contentBgClass + " " + contentLinkClass}>
                  <div className="whats-hot-content">
                    <div className="vertical-align">
                      {(item != null && (item.multiINewsWithImages.title !== null && item.multiINewsWithImages.title !== "")) &&
                        <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                      }
                      <div className={"description" + (model.isNewBrandedsite ? "-news " + contentTextClass : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                      {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                        <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={"link-button btn-transparent"} >
                          {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                        </a>

                      }
                    </div>
                  </div>
                </div>
              </div>

            )
          }
        }
        )}

      </>
    )
  }



}
function fnrowtwoflagcn(rowtwocnt, withoutPicsCount, rowtwoflag) {
  if (rowtwocnt <= 2 && (withoutPicsCount == 2)) {
    return (
      rowtwoflag = true
    )
  }

}


function withOutPicsloopNonBranded(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass) {
  let withoutPicsCount = model.multiINewsWithImages.length - withPicsCount;
  let rowtwocnt = withPicsCount % 4;

  return (
    <>
      {fnrowtwoflagcn(rowtwocnt, withoutPicsCount, rowtwoflag)}
      {fnNewsmodelsItem(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass, withoutPicsCount)

      }
    </>
  )

}

function withOutPicsloop(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass) {
  let withoutPicsCount = model.multiINewsWithImages.length - withPicsCount;
  let rowtwocnt = withPicsCount % 4;


  return (
    <>
      {fnrowtwoflagcn(rowtwocnt, withoutPicsCount, rowtwoflag)}
      {fnNewsmodelsItem(model, withPicsCount, rowtwoflag, contentTextClass, contentBgClass, contentLinkClass, withoutPicsCount)

      }
    </>
  )
}
function spilcecond(model, withoutPicsCount) {
  let breakCondition = false;
  let indexcnt = 0;

  if ((withoutPicsCount % 2) == 1) {

    let itemcnt = 0;
    return (
      model.multiINewsWithImages.map((item, index) => {
        indexcnt++;
        itemcnt++;
        var url;

        if (item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) {
          url = { value: item.multiINewsWithImages.discover.value.url }
        }

        if (!item.imgurl && !breakCondition) {
          breakCondition = true;
          itemCountValue = indexcnt - 1;
          isRemoveItem = true;
          return (
            <div key={index} className="col-sm-12  item  picture-and-text">
              <div className="clearfix">
                <div className="whats-hot-item whats-hot-text   bg-dark  text-light no-picture ">
                  <div className="whats-hot-content">
                    <div className="vertical-align">
                      {(item != null && (item.multiINewsWithImages.title == null && item.multiINewsWithImages.title !== "")) &&

                        <h3 className="whats-hot-title text-uppercase"><RichText field={item.multiINewsWithImages.title} /></h3>
                      }
                      <div className={"description" + (model.isNewBrandedsite ? "-news text-light" : "")}><RichText field={item.multiINewsWithImages.description} /></div>
                      {(item.multiINewsWithImages.discover.value.url !== "" && item.multiINewsWithImages.discover.value.url !== null) &&
                        <a href={item.multiINewsWithImages.discover.value.url} aria-label={item?.multiINewsWithImages?.discover} className={"link-button btn-transparent"} >
                          {item.multiINewsWithImages.discover.value.text !== "" && item.multiINewsWithImages.discover.value.text}
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )
        }
      })

    )
  }
}