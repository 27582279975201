import React from 'react';

export default function AccountDelete({ fields }) {

  if (fields != null && fields !== undefined && fields !== "" && fields.accountDelete !== null && fields.accountDelete !== undefined && fields.accountDelete !== "") {
    return (
      <div className="container account-unsubscribe">
        <div className="unsubscribe-logo">
          {fields.accountDelete?.logo?.value?.src !== null && fields.accountDelete?.logo?.value?.src !== undefined && fields.accountDelete?.logo?.value?.src !== "" ?
            <img src={fields.accountDelete.logo.value.src} alt="" loading="lazy" title="" className="unsubscribe-image-logo" /> : <></>}
        </div>
        {fields.accountDelete.displayErrorMessage ?
          <div className="manage-preferance-subheading text-center mt-4">{fields.accountDelete.errorMessage}</div>
          : <div className="manage-preferance-subheading text-center mt-4">{fields.accountDelete.successMessage}</div>}

      </div>
    );
  } else {
    return <></>;
  }
}
