import React, { useEffect } from 'react';

export default function BackToTop({ fields }) {

  const BackToTopLoad = () => {
    var ele = document.getElementById("back-to-top");
    if (ele) {
      if (window.resize,
        document.getElementById("back-to-top").innerHTML.length) {
        var scrollTrigger = 100,
          backToTop = function () {
            var scrollTop = document.documentElement.scrollTop;

            var backToTopElement = document.getElementById("back-to-top");
            if (backToTopElement !== null)
              return scrollTop > scrollTrigger ? backToTopElement.classList.add("d-block") : backToTopElement.classList.remove("d-block")
          };
        { backToTop() };
        window.addEventListener("scroll", function () {
          return (backToTop())
        })
      }

      ele.addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      });
    }

    if (document.getElementById("back-to-top-sticky") !== null && document.getElementById("back-to-top-sticky") !== undefined) {
      document.getElementById("back-to-top-sticky").addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      });
    }
  }

  useEffect(() => {
    if (fields !== null && fields !== undefined && fields !== "" && fields.backToTopData !== null && fields.backToTopData !== undefined && fields.backToTopData !== "") {
      BackToTopLoad();
    }
  }, []);


  if (fields !== null && fields !== undefined && fields !== "" && fields.backToTopData !== null && fields.backToTopData !== undefined && fields.backToTopData !== "") {
    return (
      <div className="footer" id="BackToTop">
        <>
          <a href="#" aria-label="" id="back-to-top" className="back-to-top" title="Back to top">
            <span className="picto icon-go-top-arrow"></span>
          </a>
          <a href="#" aria-label="" id="back-to-top-sticky" className={"back-to-top-sticky "+(fields.backToTopData.isNewBrandedSite? " ":" bg-gray text-white")+" d-block text-center pb-4"} title="Back to top">
            <span className="picto icon-arrow-square-up-rw d-block"></span>
            <span className="caption-back-to-top fw-bold" >{fields.backToTopData.dictionaryKeysBacktop} </span>
          </a>
        </>
      </div>
    );
  } else {
    return <></>;
  }
}
