import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import StoreMapInfoLeft from "../StoreMapInfoLeft";
import StoreMapInfoRight from "../StoreMapInfoRight";

export default function Marque({ fields }) {
  if (fields.marque !== null && fields.marque !== undefined) {
    return (
      <section
        className={`maps-and-info ${fields.marque.isNewBrandedSite ? "back-gray" : ""
          }  bg-white`}
        id="infos"
      >
        <div className="container">
          <div className="row">
            <div
              className={`col-12 col-md-8 left-container-maps border-8 bg-white  ${fields.marque.isNewBrandedSite ? "" : ""
                } ${fields.marque.isUSUK ? "us-uk" : ""} `}
            >
              <StoreMapInfoLeft fields = {fields.marque} />
              {/* <Placeholder
                name={fields.marque.leftPlaceholder}
                rendering={rendering}
              /> */}
            </div>
            <div
              className={`col-12 col-md-4 right-container-maps border-8 storerightcontent ${fields.marque.isNewBrandedSite ? "" : ""
                }`}
            >
              <StoreMapInfoRight fields={fields.marque} />
              {/* <Placeholder
                name={fields.marque.rightPlaceholder}
                rendering={rendering}
              /> */}
            </div>
          </div>
          {fields.marque.isNewBrandedSite && fields.marque.isDetailPage && (
            <hr className="mt-5 mb-0" />
          )}
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
