import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import BreadcrumbMobile from '../BreadcrumbMobile';
import { checkWebViewEqualsOne, fnGtmSocailNetwork } from "../Js-CommonFunctions";

export default function Footer({ fields }) {

  var hasWebviewEqualsOne = checkWebViewEqualsOne();

  if (fields !== null && fields !== undefined && fields !== "" && fields.footerData !== null && fields.footerData !== undefined && fields.footerData !== "" && !hasWebviewEqualsOne) {
    return (
      <div className={"footer "+(fields.footerData.isNewBrandedSite ? " bg-gray":" bg-gray")} id="footer">
        <div className="footer-nav container d-flex justify-content-center"  >
          <div className="">
            <div className="col-12 d-sm-block d-md-block d-lg-flex justify-content-center footer-new">
              {fields.footerData.isMobileView &&
                <div className="breadcrumb-list footerbreadcrumb-list footerbreadcrumb-list-new d-flex w-sm-100 d-lg-none p-0" >
                  <div className="footer-breadcrumb py-2">
                    <BreadcrumbMobile fields={fields} />
                  </div>
                </div>
              }
              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <div className="footer-container flex-column d-flex align-items-start ">
                  <ul className="d-flex list-unstyled justify-content-center">
                    <li>
                      <a href={(fields.footerData.urlPrefix != null && fields.footerData.urlPrefix != "") ? fields.footerData.urlPrefix : "/"} aria-label={fields.footerData?.centerName} className="navbar-brand footer-image logo p-0 m-0">
                        <img src={fields.footerData?.logo} loading="lazy" alt={fields.footerData?.centerName} title={fields.footerData?.centerName} />
                      </a>
                    </li>
                  </ul>

                  <div className="flex-column w-100  d-flex align-self-center">
                    {(fields.footerData.otherLinksChildItem.centerAddress !== null && fields.footerData.otherLinksChildItem.centerAddress !== "") &&
                      <div className="address my-2 footer-about-us color-black d-flex"><span className="picto  icon-location-rw fs-5"></span>
                        <a aria-label={fields.footerData?.otherLinksChildItem?.centerAddress} className="ps-2 " href={fields.footerData.otherLinksChildItem.hrefCenterLink}>{fields.footerData.otherLinksChildItem.centerAddress} </a>
                      </div>
                    }
                    {(fields.footerData.otherLinksChildItem.phonenumber !== null && fields.footerData.otherLinksChildItem.phonenumber !== "") &&
                      <div className="phone my-2 footer-about-us color-black  d-flex "><span className="picto  icon-call-rw fs-5"></span>
                        {(fields.footerData.otherLinksChildItem.phoneContainsOnlyNumbers) ?
                          (
                            <a aria-label={fields.footerData?.otherLinksChildItem?.phonenumber} className="ps-2 " href={"tel:" + (fields.footerData.otherLinksChildItem.phonenumber)}>{fields.footerData.otherLinksChildItem.phonenumber}</a>
                          ) :
                          (
                            <div className="ps-2 " >{fields.footerData.otherLinksChildItem.phonenumber}</div>
                          )
                        }
                      </div>
                    }
                    {(fields.footerData.otherLinksChildItem.openingClosingHours.openingHoursStatusTime !== null && fields.footerData.otherLinksChildItem.openingClosingHours.openingHoursStatusTime !== "") &&
                      <div className="time my-2 footer-about-us color-black  d-flex "><span className="picto icon-clock-rw fs-5"></span>
                        <a aria-label={fields.footerData?.otherLinksChildItem?.openingClosingHours?.openingHoursStatusTime} className="ps-2 " href={fields.footerData.otherLinksChildItem.hrefCenterOpenCloseStatusLink}>
                          {fields.footerData.otherLinksChildItem.openingClosingHours.openingHoursStatusTime}
                        </a>
                      </div>
                    }
                    <div className="d-flex flex-row justify-content-start">
                      <ul className="social-links w-sm-100 d-flex my-auto flex-row">
                        {fields.footerData.socialNetworkChildItem.map((item, index) => {
                          return (
                            <li key={index} className="social-list pe-3">
                              {item.name != null && item.name.value != "" && (
                                <a
                                  className="social-icon color-black "
                                  onClick={(e) => fnGtmSocailNetwork(item.name)}
                                  href={item.url}
                                  aria-label="social-networks"
                                  rel="noreferrer"
                                  target="_blank"
                                  data-testid="GtmSocailNetwork"
                                >
                                  {item.logo != null && item.logo.value != "" && (
                                    <RichText field={item.logo} />
                                  )}
                                </a>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                      {(fields.languageData !== null) &&
                        <ul className="lang-links d-flex justify-content-start align-items-center d-none d-lg-block">
                          {fields.languageData.languages.map((item, index) => {
                            const url = ",-w-,";
                            const wlUrl = item.destinationUrl.replace(url, item.endUrl);
                            const destinationurl = "/en/";
                            return (
                              (item.destinationUrl.includes(url)) ?
                                (
                                  <li key={index} className={(item.cssState ? " active text-uppercase fw-bold " : " ")}> <a href={wlUrl} aria-label={item.language.title} className={"language-link-active " + (item.cssState ? "text-decoration-underline" : "")}>{item.language.title}</a></li>
                                ) :
                                (
                                  <li key={index} className={(item.cssState ? " active text-uppercase fw-bold " : " ")}> <a href={item.destinationUrl} aria-label={item.destinationUrl} className={"language-link-active " + (item.cssState ? "text-decoration-underline" : "")}>{item.language.title}</a></li>
                                )
                            )
                          })}
                        </ul>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 mt-lg-5 footer-link-div p-0">
                <div className="d-flex text-uppercase footer-quicklinks flex-wrap mt-lg-5">
                  {fields.footerData.otherLinksChildItem != null &&
                    fields.footerData.otherLinksChildItem.jssLinkUrlList.map((otherlinks, index) => {
                      const item = otherlinks?.value;
                      const target = item.target ? item.target : "";
                      const otherLinksText = item?.text;

                      if (item.text !== null && item.text !== "" && item.url !== null && item.url !== "") {
                        return (
                          (item.url.includes("javascript") && item.url.includes("cookieconsent")) ?
                            (
                              <div className="col-lg-4 footer-link-col">
                              <div className="footer-links-gap-new">
                                <a aria-label={otherLinksText} key={index} id='ot-sdk-btn' href="#" onClick={(e) => { e.preventDefault(); fnCookieConsent(e) }} className="footer-link flex-item " data-testid="idCookieConsent" target={target}>
                                  <span role="button" tabIndex="0">{otherLinksText}</span>
                                </a>
                              </div>
                              </div>
                            ) :
                            (
                              <div className="col-lg-4 footer-link-col">
                              <div className="footer-links-gap-new">
                                <a aria-label={otherLinksText} key={index} href={item.url} className="footer-link flex-item " target={target}>
                                  <span role="button" tabIndex="0">{otherLinksText}</span>
                                </a>
                              </div>
                              </div>
                            )
                        )
                      }
                    })}
                  {/* {(fields.footerData.otherLinksChildItem.jssLinkUrl?.value?.text !== null && fields.footerData.otherLinksChildItem.jssLinkUrl?.value?.text !== "") &&
                ((fields.footerData.otherLinksChildItem.currentLanguage) !== (fields.footerData.otherLinksChildItem.frenchLanguage)) ?
                (
                  <li>
                    <div className="footer-links-gap col-md-2">
                      <a aria-label={fields?.footerData?.otherLinksChildItem?.jssLinkUrl?.value?.url} className="text-white flex-item " target="_blank" rel="noreferrer" href={fields.footerData.otherLinksChildItem.jssLinkUrl?.value?.url}>
                        <span role="button" tabIndex="0">{corporateLinkText}</span>
                      </a>
                    </div>
                  </li>
                ) :
                (
                  (fields.footerData.otherLinksChildItem.corporateLinkFieldText != "" && fields.footerData.otherLinksChildItem.corporateLinkFieldText != null) &&
                  <li>
                    <div className="footer-links-gap col-md-2">
                      <a href={fields.footerData.otherLinksChildItem.corporateLinkUrl} aria-label={fields.footerData.otherLinksChildItem.corporateLinkUrl} className="text-white flex-item " target="_blank" rel="noreferrer">
                        <span role="button" tabIndex="0">{fields.footerData.otherLinksChildItem.corporateLinkFieldText}</span>
                      </a>
                    </div>
                  </li>
                )
              } */}
                </div>
              </div>
            </div>
            {(fields.languageData !== null) &&
              <div className="d-flex flex-row justify-content-start py-3 d-lg-none d-sm-block">
                <ul className="lang-links d-flex justify-content-start ">
                  {fields.languageData.languages.map((item, index) => {
                    const url = ",-w-,";
                    const wlUrl = item.destinationUrl.replace(url, item.endUrl);
                    const destinationurl = "/en/";

                    return (
                      (item.destinationUrl.includes(url)) ?
                        (
                          <li key={index} className={(item.cssState ? " active text-uppercase fw-bold " : " ")}> <a href={wlUrl} aria-label={item.language.title} className={"language-link-active " + (item.cssState ? "text-decoration-underline" : "")}>{item.language.title}</a></li>
                        ) :
                        (
                          <li key={index} className={(item.cssState ? " active text-uppercase fw-bold " : " ")}> <a href={item.destinationUrl} aria-label={item.destinationUrl} className={"language-link-active " + (item.cssState ? "text-decoration-underline" : "")}>{item.language.title}</a></li>
                        )
                    )
                  })}
                </ul>
              </div>
            }
          </div>
        </div >
      </div>
    );
  } else {
    return <></>;
  }
}

function fnCookieConsent(e) {
  window.Optanon.ToggleInfoDisplay();
}
