import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import BreadcrumbMobile from '../BreadcrumbMobile';
import { checkWebViewEqualsOne, fnGtmSocailNetwork } from "../Js-CommonFunctions";

export default function GlobalFooter({ fields }) {
  var hasWebviewEqualsOne = checkWebViewEqualsOne();


  if (fields !== null && fields.footerData !== "" && fields.footerData !== null && !hasWebviewEqualsOne) {
    var url = fields.footerData?.logoLink?.length > 1 ?
      fields.footerData?.logoLink?.replace(/\/$/, '') : fields.footerData?.logoLink;

    return (
      <div className="footer bg-gray" id="footer">
        <div className="footer-nav container d-flex justify-content-center">
          <div>
            <div className="col-12 d-sm-block d-md-block d-lg-flex justify-content-center footer-new">

              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <div className="footer-container flex-column d-flex align-items-start ">
                  <ul className="d-flex list-unstyled justify-content-center">
                    <li>
                      <a href={url} aria-label={fields.footerData} className="navbar-brand footer-image logo p-0 m-0">
                        <img src={fields.footerData?.logo?.value?.src} loading="lazy" alt={fields.footerData?.logo?.value?.alt} />
                      </a>
                    </li>
                  </ul>
                  {(fields.footerData.isMobileView && !fields.footerData.isHomePage) &&
                    <div className="breadcrumb-list footerbreadcrumb-list d-flex w-sm-100 d-lg-none p-0 ">
                      <div className="footer-breadcrumb py-2">
                        <BreadcrumbMobile fields={fields} />
                      </div>
                    </div>
                  }

                  <div className="flex-column w-100  d-flex align-self-center">
                    <div className="d-flex flex-row justify-content-start">
                      <ul className="social-links w-sm-100 d-flex my-auto flex-row">
                        {fields.footerData.socialNetworkChildItem.map((item, index) => {
                          return (
                            <li className="social-list pe-3" key={index}>
                              {item.name != null && item.name.value != "" && (
                                <a
                                  className="social-icon color-black"
                                  onClick={(e) => fnGtmSocailNetwork(item.name)}
                                  href={item.url}
                                  aria-label="social-networks"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {item.logo != null && item.logo.value != "" && (
                                    <RichText field={item.logo} />
                                  )}
                                </a>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                      {(fields.languageData !== null) &&
                        <ul className="lang-links d-flex justify-content-start align-items-center d-none d-lg-block list-unstyled">
                          {fields.languageData.languages.map((item, index) => {
                            const url = ",-w-,";
                            const wlUrl = item.destinationUrl.replace(url, item.endUrl);
                            const destinationurl = "/en/";

                            return (
                              (item.destinationUrl.includes(url)) ?
                                (
                                  <li key={index} className={(item.cssState ? " active  text-uppercase fw-bold " : " ")}>
                                    <a href={wlUrl} aria-label={item?.language} className={(item.cssState ? "text-decoration-underline" : "") +"  language-link-active "}>{item.language.title}</a></li>
                                ) :
                                (
                                  <li key={index} className={(item.cssState ? " active  text-uppercase fw-bold " : " ")}>
                                    <a href={item.destinationUrl} aria-label={item?.destinationUrl} className={(item.cssState ? "text-decoration-underline" : "  ")+" language-link-active"}>{item.language.title}</a></li>
                                )
                            )
                          })}
                        </ul>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {fields.footerData.otherLinksChildItem != null &&
                <div className="col-lg-9 mt-lg-5 footer-link-div p-0">
                  <div className="d-flex text-uppercase footer-quicklinks flex-wrap mt-lg-5">
                    {fields.footerData.otherLinksChildItem.jssLinkUrlList.map((otherlinks, index) => {
                      const item = otherlinks?.value;
                      const target = item.target ? item.target : "";
                      const otherLinksText = item?.text;

                      if (item.text !== null && item.text !== "" && item.url !== null && item.url !== "") {
                        return (
                          (item.url.includes("javascript") && item.url.includes("cookieconsent")) ?
                            (
                              <div key={index} className="col-lg-4 footer-link-col">
                                <div className="footer-links-gap-new">
                                  <a aria-label={otherLinksText} data-testid="idCC" href="javascript:void(0);" onClick={(e) => fnCookieConsent(e)} className="footer-link flex-item " target={target}>
                                    <span role="button" tabIndex="0">{otherLinksText}</span>
                                  </a>
                                </div>
                              </div>
                            ) :
                            (
                              <div key={index} className="col-lg-4 footer-link-col">
                                <div className="footer-links-gap-new">
                                  <a aria-label={otherLinksText} href={item.url} className="footer-link flex-item " target={target}>
                                    <span role="button" tabIndex="0">{otherLinksText}</span>
                                  </a>
                                </div>
                              </div>
                            )

                        )
                      }
                    })}
                  </div>
                </div>

              }

            </div>
            {(fields.languageData !== null) &&
              <div className="d-flex flex-row justify-content-start py-3 d-lg-none d-sm-block">
                <ul className="lang-links d-flex justify-content-start">
                  {fields.languageData.languages.map((item, index) => {
                    const url = ",-w-,";
                    const wlUrl = item.destinationUrl.replace(url, item.endUrl);
                    const destinationurl = "/en/";

                    return (
                      (item.destinationUrl.includes(url)) ?
                        (
                          <li key={index} className={(item.cssState ? " active  text-uppercase fw-bold " : " ")}>
                            <a href={wlUrl} aria-label={item?.language} className={(item.cssState ? "text-decoration-underline" : "  ") +" language-link-active"}>{item.language.title}</a></li>
                        ) :
                        (
                          <li key={index} className={(item.cssState ? " active  text-uppercase fw-bold " : " ")}>
                            <a href={item.destinationUrl} aria-label={item?.destinationUrl} className={(item.cssState ? "text-decoration-underline" : "  ") +" language-link-active"}>{item.language.title}</a></li>
                        )
                    )
                  })}
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }


}

export function fnCookieConsent(e) {
  window.Optanon?.ToggleInfoDisplay();
}
