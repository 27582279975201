import React, { useEffect, useState } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { pageRoute } from '../../RouteHandler.js';


export default function PreFooter({ fields }) {
  var upperText = { value: '' };
  var lowerText = { value: '' };

  const [showMoreEnable, setshowMoreEnable] = useState(true);
  const [showMoreDisable, setshowMoreDisable] = useState(false);

  function seeMoreEnable() {
    if (pageRoute.fields["Pre Footer Upper Text"] !== null && pageRoute.fields["Pre Footer Upper Text"] !== undefined && pageRoute.fields["Pre Footer Upper Text"].value !== ""
      && pageRoute.fields["Pre Footer Lower Text"] !== null && pageRoute.fields["Pre Footer Lower Text"] !== undefined && pageRoute.fields["Pre Footer Lower Text"].value !== "") {
      upperText.value = pageRoute.fields["Pre Footer Upper Text"].value;
      lowerText.value = pageRoute.fields["Pre Footer Lower Text"].value;
      return true;
    }
    else if (pageRoute.fields["Pre Footer Upper Text"] === null || pageRoute.fields["Pre Footer Upper Text"]?.value === ""
      && pageRoute.fields["Pre Footer Lower Text"] !== null && pageRoute.fields["Pre Footer Lower Text"] !== undefined && pageRoute.fields["Pre Footer Lower Text"].value !== "") {
      upperText.value = pageRoute.fields["Pre Footer Lower Text"].value;
      lowerText.value = "";
    }
    else if (pageRoute.fields["Pre Footer Lower Text"] === null || pageRoute.fields["Pre Footer Lower Text"]?.value === ""
      && pageRoute.fields["Pre Footer Upper Text"] !== null && pageRoute.fields["Pre Footer Upper Text"] !== undefined && pageRoute.fields["Pre Footer Upper Text"].value !== "") {
      upperText.value = pageRoute.fields["Pre Footer Upper Text"].value;
      lowerText.value = "";
    }
    return false;
  }
  function showMoreClick() {
    setshowMoreEnable(false);
    setshowMoreDisable(true);
  }
  function showLessClick() {
    setshowMoreEnable(true);
    setshowMoreDisable(false);
  }

  if (fields != null && fields !== undefined && fields !== "" &&
    fields.preFooterData != null && fields.preFooterData != undefined && fields.preFooterData != "") {
    return (
      <div className="pre-footer pre-footer-new" id="preFooter">
        <div className="container">
          <div className="wrapper">
            {(fields.headingTag && fields.preFooterData.title != null && fields.preFooterData.title != "") ?
              (
                <h2 className={"pre-footer-title" + (fields.preFooterData.isNewBrandedSite ? "" :" text-start")}> <Text field={pageRoute.fields["Pre Footer Title"]} /></h2>
              ) :
              (
                <div className={"pre-footer-title" + (fields.preFooterData.isNewBrandedSite ? "" :" text-start")}> <Text field={pageRoute.fields["Pre Footer Title"]} /></div>
              )
            }
            <div className={"pre-footer-description "+ (fields.preFooterData.isNewBrandedSite ? "" :" text-start")}>
              {seeMoreEnable() ?
                <>
                  {showMoreEnable && <><div id="UpperText"><RichText field={upperText} /></div>
                    <div id="LowerText" className="d-none"><RichText field={lowerText} /></div>
                    {/* <a href="javascript:void(0);" className="showtoggle-button" data-testid="showMoreClick" onClick={(e) => { showMoreClick() }}>{fields.preFooterData.showMoreCTA}</a> */}
                    <div className="text-start my-2 ">
                      <a href="javascript:void(0);" className="grid-new-cta pe-1" data-testid="showMoreClick" onClick={(e) => { showMoreClick() }}>{fields.preFooterData.showMoreCTA}</a>
                      <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5"></span>                </div>
                  </>}

                  {showMoreDisable && <><div id="UpperText"><RichText field={upperText} /></div>
                    <div id="LowerText"><RichText field={lowerText} /></div>
                    {/* <a href="javascript:void(0);" className="showtoggle-button show" data-testid="showLessClick" onClick={(e) => { showLessClick() }}>{fields.preFooterData.showLessCTA}</a> */}
                    <div className="text-start my-2">
                      <a href="javascript:void(0);" className="grid-new-cta pe-1 show" data-testid="showLessClick" onClick={(e) => { showLessClick() }}>{fields.preFooterData.showLessCTA}</a>
                      <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5"></span>
                    </div>
                  </>}
                </>
                :
                <><div id="UpperText"><RichText field={upperText} /></div></>}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}



