import React, { useEffect, useState } from "react";
import { clickItineraryLaunchDataPush, mapInteractionDataPush } from '../Js-CommonFunctions';

import { baseModel } from "../../RouteHandler.js";
import {
  calcRoute,
  initialize,
  unibailGooglemapLoad,
} from "../Js-CommonFunctions";

export default function GoogleMapsCenterTest({ fields, isServiceDetailPage }) {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const js = document.createElement("script");
    js.type = "text/javascript";
    js.src = baseModel.googleMapUrl;
    js.setAttribute("async", "");
    js.setAttribute("defer", "");
    js.addEventListener("load", () => setLoaded(true));
    document.body.appendChild(js);
  }, []);

  useEffect(() => {
    if (!loaded) {
    } else if (fields.googleMapCenterData) {

      if (
        fields.googleMapCenterData.centerLatitude != null &&
        fields.googleMapCenterData.centerLongitude !== null
      ) {
        initialize(
          fields.googleMapCenterData.centerLatitude,
          fields.googleMapCenterData.centerLongitude
        );
      }
      if (!isServiceDetailPage)
        unibailGooglemapLoad();
    }
  }, [loaded]);

  if (fields && fields.googleMapCenterData !== null && fields.googleMapCenterData !== undefined && fields.googleMapCenterData !== "") {
    return (
      <>
        <section id="googlemaps">
          <div
            className={isServiceDetailPage ? "" : "container"}
            data-latitude={fields.googleMapCenterData.centerLatitude}
            data-longitude={fields.googleMapCenterData.centerLongitude}
          >
            {!isServiceDetailPage &&
              <div className="header-title header-title-access d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
                <div className="content-title">
                  {fields.googleMapCenterData.googleMapHeading}
                </div>
              </div>
            }
            <div className="access-center">
              {!isServiceDetailPage &&
                <div className="row">

                  <div className="col p-0 d-flex w-100">
                    <form
                      className="global-form d-flex  flex-wrap address-search"
                      action="#"
                      method="GET"
                      noValidate="novalidate"
                    >
                      <div className="access-tabs access-page-formgroup px-0 col-lg-3 col-sm-12 order-1 order-lg-0 js-access-tab">
                        <ul className="nav nav-tabs" role="tablist">
                          <li
                            role="presentation"
                            className="active analytics_googlemapitineraryclk"
                          >
                            <label htmlFor="car" className="nav-link active">
                              <input
                                type="radio"
                                data-testid="input-1"
                                onClick={() => {
                                  calcRoute(
                                    fields.googleMapCenterData.centerLatitude,
                                    fields.googleMapCenterData.centerLongitude
                                  ); mapInteractionDataPush();
                                }}
                                name="mode"
                                id="car"
                                value="DRIVING"
                                checked
                              />
                              <span
                                className="picto icon-srv-cars gtmCommonEvents"
                              ></span>
                            </label>
                          </li>
                          <li
                            role="presentation"
                            className="analytics_googlemapitineraryclk"
                          >
                            <label htmlFor="foot" className="nav-link">
                              <input
                                type="radio"
                                data-testid="input-2"
                                onClick={() => {
                                  calcRoute(
                                    fields.googleMapCenterData.centerLatitude,
                                    fields.googleMapCenterData.centerLongitude
                                  ); mapInteractionDataPush();
                                }}
                                name="mode"
                                id="foot"
                                value="WALKING"
                              />
                              <span
                                className="picto icon-foot-bike gtmCommonEvents"
                              ></span>
                            </label>
                          </li>
                          <li
                            role="presentation"
                            className="analytics_googlemapitineraryclk"
                          >
                            <label htmlFor="transit" className="nav-link">
                              <input
                                type="radio"
                                data-testid="input-3"
                                onClick={() => {
                                  calcRoute(
                                    fields.googleMapCenterData.centerLatitude,
                                    fields.googleMapCenterData.centerLongitude
                                  ); mapInteractionDataPush();
                                }}
                                name="mode"
                                id="transit"
                                value="TRANSIT"
                              />
                              <span
                                className="picto icon-srv-trains-metro gtmCommonEvents"
                              ></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="form-group access-page-formgroup col-lg-6 col-sm-12 position-relative ">
                        <label
                          htmlFor="targetAddress"
                          aria-label="search-text"
                          className="picto icon-search-rw"
                        ></label>
                        <input
                          className="form-control search-input"
                          id="targetAddress"
                          type="text"
                          required=""
                          aria-required="true"
                          placeholder={
                            fields.googleMapCenterData.enteryouraddress
                          }
                        />
                      </div>
                      <div className="form-group access-page-formgroup input-submit col-lg-3 col-sm-12 order-md-2 order-sm-2 order-2">
                        <a
                          type="button"
                          className="link-button btn-dark"
                          data-testid="input-4"
                          onClick={() => {
                            calcRoute(
                              fields.googleMapCenterData.centerLatitude,
                              fields.googleMapCenterData.centerLongitude
                            ); clickItineraryLaunchDataPush();
                          }}
                        >OK</a>

                      </div>
                    </form>
                  </div>
                </div>
              }
              <div
                id="googleMap"
                className="googlemap gtmCommonEvents"
                data-gtmevent="mapInteraction"
                data-testid="mapinteraction-id"
                onClick={() => { mapInteractionDataPush() }}
              ></div>
            </div>
            <div id="itineraire"></div>
          </div>
        </section>
      </>
    );
  } else {
    return <></>;
  }
}
