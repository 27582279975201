import React, { useEffect } from "react";
import { isMobile, lazyLoadingImg } from "../Js-CommonFunctions.js";

function ShowMovieDetail(
  filmCarousel,
  iTotalcount,
  iFilmCounter,
  isNewBrandedSite,
  urlPrefix,
  defaultImage
) {
  var movieDetail = null;
  let movies = [];
  for (let i = 0; i <= iTotalcount / 12; i++) {
    for (let j = 0; j < 12; j++) {
      if (iFilmCounter < iTotalcount) {
        iFilmCounter++;
        if (filmCarousel[iFilmCounter - 1] != null) {
          movieDetail = filmCarousel[iFilmCounter - 1];

          let movieURL = movieDetail.movieUrl;
          let styleclassname = "";
          let displayStyle = i > 0 ? "d-none" : "";
          movies.push(
            <div
              className={
                "col-6 col-md-3 col-lg-2 p-0 " +
                (isNewBrandedSite ? "mb-22" : "mb-22") +
                " text-start movie-block gtmCarouselView " +
                displayStyle
              }
              id={"movieBlock-" + iFilmCounter.toString()}
            >
              <>
                <a
                  aria-label={movieDetail?.film?.filmTitle}
                  className={
                    "img-wrap d-flex justify-content-center align-items-center " +
                    (isNewBrandedSite ? "mb-2" : "mb-2") +" shadow-none"
                  }
                  href={urlPrefix + movieURL}
                >
                  {movieDetail.film.imageposter !== null &&
                    movieDetail.film.imageposter !== "" ? (
                    <img
                      src={defaultImage}
                      data-alt={movieDetail.film.imageposter}
                      alt={movieDetail.film.filmTitle}
                      title={movieDetail.film.filmTitle}
                    />
                  ) : (
                    <span className="picto icon-srv-cinema"></span>
                  )}
                </a>
                <div
                  className={
                    "movie-title " +
                    (isNewBrandedSite ? "mb-0" : "mb-0") +
                    " fw-bold"
                  }
                >
                  <a aria-label={movieDetail?.film?.filmTitle} className="d-block" href={urlPrefix + movieURL}>
                    {movieDetail.film.filmTitle}
                  </a>
                </div>
                {movieDetail.filmEvent != null && (
                  <div className="">
                    <a
                      aria-label={movieDetail?.filmEvent?.filmEventVersion}
                      href={urlPrefix + movieURL}
                      className="movie-version-new d-block gtmClickNavigation"
                    >
                      {movieDetail.filmEvent.filmEventVersion}
                    </a>
                  </div>
                )}
              </>
            </div>
          );
        }
      }
    }
  }
  return movies;
}

var blocksPerClick = 12;
export default function CinemaCarousel({ fields }) {
  useEffect(() => {
    lazyLoadingImg();
  }, []);

  useEffect(() => {
    cinemaLoadNumber();
  }, [blocksPerClick]);

  let iTotalcount =
    fields.filmCarouselObj != null &&
      fields.filmCarouselObj.filmCarousel != null
      ? fields.filmCarouselObj.filmCarousel.length
      : 0;
  let iFilmCounter = 0;

  if (
    fields.filmCarouselObj != null &&
    fields.filmCarouselObj?.filmCarousel.length > 0
  ) {
    return (
      <section className="all-movies" id="all-movies">
        <div className="container">
          {fields.filmCarouselObj.titleAllmovies !== null &&
            fields.filmCarouselObj.titleAllmovies !== "" &&
            fields.filmCarouselObj.isNewBrandedSite ? (
            <div className="row header-title ">
              <div className="title">
                {fields.filmCarouselObj.totalMovieCount > 0 ? (
                  <h2>
                    <span className="news-title">
                      {fields.filmCarouselObj.titleAllmovies !== null &&
                        fields.filmCarouselObj.titleAllmovies !== ""
                        ? fields.filmCarouselObj.titleAllmovies
                        : ""}
                    </span>
                    <span className="ms-2 fw-bold">
                      ({fields.filmCarouselObj.totalMovieCount})
                    </span>
                  </h2>
                ) : (
                  <h2>
                    <span className="news-title">
                      {fields.filmCarouselObj.titleAllmovies !== null &&
                        fields.filmCarouselObj.titleAllmovies !== ""
                        ? fields.filmCarouselObj.titleAllmovies
                        : ""}
                    </span>
                    <span className="ms-2 fw-bold"></span>
                  </h2>
                )}
              </div>
            </div>
          ) : fields.filmCarouselObj.totalMovieCount > 0 ? (
            <h2 className="text-uppercase text-start content-title">
              {fields.filmCarouselObj.titleAllmovies !== null &&
                fields.filmCarouselObj.titleAllmovies !== ""
                ? fields.filmCarouselObj.titleAllmovies
                : ""}{" "}
              ({fields.filmCarouselObj.totalMovieCount}){" "}
            </h2>
          ) : (
            <h2 className="text-uppercase text-start content-title">
              {fields.filmCarouselObj.titleAllmovies !== null &&
                fields.filmCarouselObj.titleAllmovies !== ""
                ? fields.filmCarouselObj.titleAllmovies
                : ""}{" "}
            </h2>
          )}
          <div className="row pt-3 cinema movies-list">
            {ShowMovieDetail(
              fields.filmCarouselObj.filmCarousel,
              iTotalcount,
              iFilmCounter,
              fields.filmCarouselObj.isNewBrandedSite,
              fields.filmCarouselObj.urlPrefix,
              fields.filmCarouselObj.defaultImage
            )}
          </div>
          {/* {fields.filmCarouselObj.isNewBrandedSite ? ( */}
            <>
              <div className="action" id="listOfMoviesViewMore">
                <a className="link-button btn-dark">{fields.filmCarouselObj.viewMore}</a>
              </div>
              <input type="hidden" id="hidCinemaBlocksCount" value="12" />
            </>
          {/* // ) : (
          //   <>
          //     <div
          //       className="mt-1 text-black text-center text-uppercase view-more"
          //       id="listOfMoviesViewMore"
          //     >
          //       <div>{fields.filmCarouselObj.viewMore}</div>
          //       <i className="picto icon-go-down-arrow"></i>
          //     </div>
          //     <input type="hidden" id="hidCinemaBlocksCount" value="12" />
          //   </>
          // )} */}
        </div>
      </section>
    );
  } else return <></>;
}

function cinemaLoadNumber() {
  var totalBlocks = document.getElementsByClassName("movie-block").length;
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;

  if (isMobileView) {
    blocksPerClick = 8;
    for (var startCount = 1; startCount <= totalBlocks; startCount++) {
      var id = "movieBlock-" + startCount;
      if (document.getElementById(id)) {
        if (startCount <= blocksPerClick)
          document.getElementById(id).classList.add("d-block");
        else document.getElementById(id).classList.add("d-none");
      }
    }
  } else {
    blocksPerClick = 12;
  }

  if (!(blocksPerClick < totalBlocks)) {
    if (document.getElementById("listOfMoviesViewMore")) {
      document
        .getElementById("listOfMoviesViewMore")
        .classList.remove("d-block");
      document.getElementById("listOfMoviesViewMore").classList.add("d-none");
    }
  } else {
    document.getElementById("hidCinemaBlocksCount").value = blocksPerClick;
    if (document.getElementById("listOfMoviesViewMore")) {
      document
        .getElementById("listOfMoviesViewMore")
        .classList.remove("d-none");
      document.getElementById("listOfMoviesViewMore").classList.add("d-block");
    }
  }

  if (document.getElementById("listOfMoviesViewMore")) {
    document.getElementById("listOfMoviesViewMore").onclick = function (e) {
      e.preventDefault();
      var visibleBlocks = parseInt(
        document.getElementById("hidCinemaBlocksCount").value
      );

      if (totalBlocks > visibleBlocks) {
        var tobeDisplayed = visibleBlocks + blocksPerClick;
        for (
          var startCount = visibleBlocks;
          startCount <= tobeDisplayed;
          startCount++
        ) {
          var id = "movieBlock-" + startCount;
          var ele = document.getElementById(id);
          if (ele !== null) {
            ele.classList.remove("d-none");
            ele.classList.add("d-block");
          }
        }
      }

      if (!(tobeDisplayed < totalBlocks)) {
        if (document.getElementById("listOfMoviesViewMore")) {
          document
            .getElementById("listOfMoviesViewMore")
            .classList.remove("d-block");
          document
            .getElementById("listOfMoviesViewMore")
            .classList.add("d-none");
        }
      } else {
        document.getElementById("hidCinemaBlocksCount").value = tobeDisplayed;
      }

      lazyLoadingImg();
    };
  }
}
