import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import RoutableSitecoreLink from "../RoutableSitecoreLink";


export default function Hero1({ fields }) {
    if (fields !== null && fields !== "" && fields.hero1Items !== null && fields.hero1Items?.itemId !== null && fields.hero1Items?.itemId !== "" && fields.jsonContent !== null) {
        var jsonContent = fields.jsonContent;
        var model = fields.hero1Items;
        return (
            <section className="hero">
                <div className="container">
                    <div className="whats-hot whats-hot-front">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="big-text  bg-dark  item">
                                    <div className="whats-hot-item whats-hot-text text-light">
                                        <div className="whats-hot-content">
                                            <div className="vertical-align">
                                                <RichText field={jsonContent["IconLeft"]} />
                                                {((model.item.heading1left !== null && model.item.heading1left !== "") || (model.item.heading2left !== null && model.item.heading2left !== "")) &&
                                                    <h3 className="whats-hot-title text-uppercase">
                                                        <Text field={jsonContent["Heading1Left"]} /> <Text field={jsonContent["Heading2Left"]} />
                                                    </h3>
                                                }
                                                <br />
                                                {(model.item.linkLeft !== null && model.item.linkLeft.value !== null) &&
                                                    <RoutableSitecoreLink field={jsonContent["LinkLeft"]} className="link-button btn-transparent gtmClickNavigation" >
                                                        <Text field={jsonContent["Link labelLeft"]} />
                                                    </RoutableSitecoreLink>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="big-text  bg-lighter  item">
                                    <div className="whats-hot-item whats-hot-text text-dark">
                                        <div className="whats-hot-content">
                                            <div className="vertical-align">
                                                <RichText field={jsonContent["IconRight"]} />
                                                {((model.item.heading1right !== null && model.item.heading1right !== "") || (model.item.heading2right !== null && model.item.heading2right !== "")) &&
                                                    <h3 className="whats-hot-title text-uppercase">
                                                        <Text field={jsonContent["Heading1Right"]} /> <Text field={jsonContent["Heading2Right"]} />
                                                    </h3>
                                                }
                                                <br />
                                                {model.uidCookie === null || model.uidCookie === "" ?
                                                    (
                                                        <a href={model.hero1Url} aria-label={model?.hero1Url} className="link-button btn-transparent gtmClickNavigation" >{model.hero1Text}</a>
                                                    ) :
                                                    (
                                                        <RoutableSitecoreLink field={jsonContent["LinkRight"]} className="link-button btn-transparent gtmClickNavigation" >
                                                            <Text field={jsonContent["Link labeRight"]} />
                                                        </RoutableSitecoreLink>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else {
        return (<></>)
    }
}