import React from 'react';
import { baseModel } from '../../RouteHandler.js';
import { fnRedirectToConnectLogin } from '../Js-CommonFunctions.js';

export default function PersonalizedBlock({ model }) {
    if (model !== null) {
        return (
            <>
                {(model.personalizedBlockPage != null && model.personalizedBlockPage.toLowerCase() === "homepage") &&
                    <div className="picto-wrap my-4"> <span className="offer-logo d-flex picto icon-offers"></span> </div>
                }
                <div className="personal-offer-wrap text-center">
                    <div className={model.personalizedBlockPage.toLowerCase() === "homepage" ? 'personal-offer-title text-uppercase d-flex ' : 'title text-uppercase'}>{model.personalizedOfferTitle + " *"}</div>
                    <div className={model.personalizedBlockPage.toLowerCase() === "homepage" ? 'personal-offer-description mb-1' : 'description mb-1'}>{model.personalizedOfferDescription}</div>
                </div>

                <div className={model.personalizedBlockPage.toLowerCase() === "homepage" ? "text-start mt-3" : "text-center mt-1"}>
                    <a href="javascript:void(0);" aria-label={model?.personalizedOfferCTA} data-testid="createcookie" onClick={() => { fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}
                        className={"grid-new-cta " + (model.personalizedBlockPage.toLowerCase() === "homepage" ? "" : "pe-1")}>{model.personalizedOfferCTA}
                    </a>
                    <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5" />
                </div>
                <div className={"cta-block d-flex " + (model.personalizedBlockPage.toLowerCase() === "homepage" ? "text-start " : " text-center align-items-center justify-content-center ") + " mb-2"}>
                    <a href="javascript:void(0);" aria-label={model?.personalizedOfferLink} data-testid="createcookie2" onClick={() => { fnRedirectToConnectLogin(baseModel.connectRegisterUrl) }}
                        className="offer-link-text text-underline">{model.personalizedOfferLink}</a>
                </div>
                {(model.personalizedBlockPage.toLowerCase() !== "homepage") &&
                    <div className='more-info-offers text-center'>
                        {model.cezOffer}
                    </div>
                }
            </>
        )
    } else {
        return <></>
    }
}
