import React, { useEffect } from "react";
import { baseModel } from "../../RouteHandler";
import { lazyLoadingImg } from "../Js-CommonFunctions.js";

export default function NewsRelatedStoresAndRestaurants({ fields }) {
  var model = fields.storeItems;
  let topBrandIndex = 0;
  useEffect(() => {
    lazyLoadingImg();
  }, []);
  if (model != null && model.storeItems.length > 1) {
    return (
      <section
        className={
          "top-brands " + (model.isNewBrandedSite ? " back-gray" : "") + " "
        }
        id="brands"
      >
        <div className="container">
          <div
            className={
              "header-title " +
              (model.isNewBrandedSite ? "header-title-new d-flex align-items-baseline flex-wrap justify-content-lg-between " : "  destination")
            }
          >
            {model.brandTitle.trim() !== "" && (
              <>
                {model.isNewBrandedSite ? (
                  <a
                    className="title"
                    href={model.urlPrefix + "/" + model.shopLanding_Page}
                  >
                    <h2>
                      <span className="title-border">{model.brandTitle}</span>
                    </h2>
                  </a>
                ) : (
                  <a
                    className="title"
                    href={model.urlPrefix + "/" + model.shopLanding_Page}
                  >
                    <h2>
                      <span style={{ cursor: "pointer" }}>
                        {model.brandTitle}
                      </span>
                    </h2>
                  </a>
                )}
              </>
            )}
            {/* <div>
              {model.storeAvailable && (
                <>
                  <a
                    className={
                      "action " +
                      (model.isNewBrandedSite ? " picto icon-plus-big" : "") +
                      " gtmClickNavigation"
                    }
                    href={model.urlPrefix + "/" + model.shopLanding_Page}
                  >
                    {!model.isNewBrandedSite && (
                      <>
                        <strong>
                          {model.seeAllStores} ({model.storesCount})
                        </strong>
                        <span className="picto icon-ico-chevron-right"></span>
                      </>
                    )}
                  </a>
                </>
              )}
              {model.restaurantAvailable && (
                <>
                  <a
                    className={
                      "action gtmClickNavigation " +
                      (model.isNewBrandedSite ? " hidden" : "")
                    }
                    href={model.urlPrefix + "/" + model.restaurant_Landing_Page}
                  >
                    {!model.isNewBrandedSite && (
                      <>
                        <strong>
                          {model.seeAllRestaurants} ({model.restaurantsCount})
                        </strong>
                        <span className="picto icon-ico-chevron-right"></span>
                      </>
                    )}
                  </a>
                </>
              )}
            </div> */}
          </div>

          <div className="topbrands-wrap row text-center" id="topBrands">
            {model.storeItems.map((storeItem, index) => {
              topBrandIndex = topBrandIndex + 1;
              return (
                <div key={index}
                  className={
                    "col-lg-3 col-6 brand-block mb-lg-0 px-6 px-lg-12 " +
                    (model.isUSUK ? model.usUkStoreLogo : "")
                  }
                >
                  <a
                    href={model.urlPrefix + "/" + storeItem.url_Path}
                    className="d-block bg-gray brand-anchor gtmClickNavigation"
                  >
                    <div className="card bg-transparent border-0">
                      <div
                        className={
                          "brand-img " +
                          (model.isNewBrandedSite ? "" : "") +
                          ""
                        }
                      >
                        <img
                          src={model.defaultImage}
                          data-alt={storeItem.image_Url}
                          alt={storeItem.display_Name}
                          title={storeItem.display_Name}
                        />
                      </div>

                    </div>
                  </a>
                  <a
                    href={model.urlPrefix + "/" + storeItem.url_Path}
                    className={
                      "d-flex text-start brand-title fw-bold  mt-2" +
                      (model.isNewBrandedSite
                        ? ""
                        : "")
                    }
                  >
                    {storeItem.display_Name}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
