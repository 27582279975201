import React, { useEffect } from "react";
import { CommonBookmarkallpage } from '../Js-CommonFunctions';

export default function BrandsList({ model }) {

  useEffect(() => {
    CommonBookmarkallpage();
  }, []);

  let loyaltyPillarIndex = 0;
  let nextPillarDisplay = 5;
  let count = 0;

  if (model !== null && model.displaystoreitems !== null) {
    let tempvalue = "";
    let parent = [];
    let blockCount = 0;

    let store;

    for (let i = 0; i < model.displaystoreitems.length;) {
      let store = model.displaystoreitems[i];
      if (
        store != null &&
        store.display_Name !== "" &&
        !store.display_Name.substring(0, 1).toLowerCase() === tempvalue
      ) {
        tempvalue = store.display_Name.substring(0, 1).toLowerCase();
      } else {
      }

      for (; i < model.displaystoreitems.length; i++) {
        store = model.displaystoreitems[i];
        if (
          store != null &&
          store.display_Name !== "" &&
          !store.display_Name.substring(0, 1).toLowerCase() === tempvalue
        ) {
          return <></>;
        }
        blockCount++;
        let isPillarDisplay = false;
        let pillarItem;
        let loyaltyGTMClickPostion;
        let hascCurbside = store.curbsideUrl !== "";
        let isLoyalityPageExists = false;
        isLoyalityPageExists = model.isUS
          ? (isLoyalityPageExists = true)
          : (isLoyalityPageExists =
            model.loyaltyPorgramExists && model.is_Loyalty_Page_Exists
              ? true
              : false);
        if (
          blockCount == nextPillarDisplay &&
          isLoyalityPageExists &&
          model.pillarItems != null &&
          model.pillarItems.length > 0
        ) {
          nextPillarDisplay = nextPillarDisplay + 9;
          pillarItem = model.isLPV2Site
            ? loyaltyPillarIndex % model.pillarItems.length
            : loyaltyPillarIndex % model.pillarItems.length;
          if (model.pillarItems[pillarItem] != null) {
            loyaltyGTMClickPostion =
              (model.pillarItems[pillarItem].pillarGTMPushName !== ""
                ? model.pillarItems[pillarItem].pillarGTMPushName
                : "") +
              "-" +
              (blockCount + loyaltyPillarIndex) +
              "-" +
              model.store_GTM_Type;

            isPillarDisplay = true;
            loyaltyPillarIndex++;
          }
        }

        parent.push(
          <>
            {isPillarDisplay && (
              <div
                key={i}
                className={
                  "categoryListFilter letter-filterLoyaltyPillar" +
                  (model.isCategoryFilterComponent
                    ? " col-lg-3"
                    : " col-lg-4") +
                  " col-12 mb-1 mb-lg-3 gtmClickNavigation"
                }
                data-title=""
                data-name="LoyaltyPillar"
                data-alt="LoyaltyPillar"
              >
                <div
                  className={
                    "position-relative mb-2" +
                    (!model.isNewBrandedSite && model.isLPV2Site
                      ? " premium-block"
                      : " loyalty-block") +
                    (!model.isNewBrandedSite && !model.isLPV2Site
                      ? " loyalty-bg"
                      : "")
                  }
                >
                  <div className="loyalty-wrap d-flex justify-content-center flex-lg-column">
                    <a aria-label={model.pillarItems[pillarItem]?.pillarLink}
                      className="helper"
                      href={model.pillarItems[pillarItem].pillarLink}
                    ></a>
                    <div className="header">
                      {bandListInnerfn(model, pillarItem, i, count)}
                    </div>
                    <div className="loyalty-details d-flex justify-content-center flex-column">
                      <div className="title text-uppercase fw-bold">
                        {model.pillarItems[pillarItem].pillarTitle}
                      </div>
                      <div className="description">
                        {" "}
                        {model.pillarItems[pillarItem].pillarLongDescrition}
                      </div>
                      <a
                        aria-label={model.pillarItems[pillarItem]?.pillarLink}
                        href={model.pillarItems[pillarItem].pillarLink}
                        className={
                          "d-none d-lg-block" +
                          (model.isNewBrandedSite
                            ? " btn-link"
                            : " link-button text-uppercase") +
                          " " +
                          (model.isNewBrandedSite
                            ? " "
                            : model.isLPV2Site
                              ? " btn-loyalty"
                              : " btn-transparent")
                        }
                      >
                        {model.pillarItems[pillarItem].pillarLinkText}
                      </a>
                    </div>
                    <span
                      className={
                        "d-flex d-lg-none picto" +
                        (!model.isNewBrandedSite && model.isLPV2Site
                          ? " icon-chevron-black"
                          : " icon-ico-chevron-right")
                      }
                    ></span>
                  </div>
                </div>
              </div>
            )}

            {AlphabetsFilterResult(
              model,
              store,
              model.urlPrefix,
              hascCurbside,
              i,
              count
            )}
          </>
        );
        count++;
      }
      return parent;
    }
  } else {
    return <></>;
  }
}

function AlphabetsFilterResult(
  model,
  store,
  urlPrefix,
  hascCurbside,
  i,
  count
) {
  return (
    <div
      key={i}
      className={
        "categoryListFilter letter-filter letter-filter " +
        store.checkforothers +
        (model.isCategoryFilterComponent ? " col-lg-3" : " col-lg-4") +
        " " +
        "col-12 mb-1 mb-lg-3"
      }
      data-title={store.strCategory}
      data-name={
        store != null && store.display_Name !== ""
          ? store.display_Name.substring(0, 1).toUpperCase()
          : ""
      }
      data-alt={
        store != null && store.checkforothers !== ""
          ? store.checkforothers.toLowerCase()
          : ""
      }
    >
      <div
        className={
          "thumbnail position-relative border-0 rounded-0 p-0 text-center " +
          (model.isUSUK ? model.uS_UK_STORELOGO : "") +
          (hascCurbside || store.clickAndCollect ? " curbside-block" : "")
        }
      >
        <a
          href="javascript:void(0);"
          className={
            (model.isNewBrandedSite ? "" : " text-hide") +
            " add-wishlist ava-wishlist open-popin  bookmark picto icon-heart-unfill-rw"
          }
          aria-label={store.storeId.toLowerCase()}
          aria-labelledby={model.filterType === 1 ? "restaurant" : "store"}
          data-itemlabel={store.display_Name}
          data-templatetype={model.filterType === 1 ? "Restaurants List" : "Stores List"}
        ></a>

        <a
          aria-label={store?.curbsideText}
          href={
            store != null && store.urlPath !== ""
              ? urlPrefix + model.cslash + store.urlPath
              : " javascript:void(0);"
          }
          className="position-relative"
        >
          {store.clickAndCollect && (
            <div className="curbside loyalty-text fw-bold position-absolute text-start text-uppercase">
              {model.clickAndCollectText}
            </div>
          )}
          {hascCurbside && !store.clickAndCollect && (
            <div className="curbside loyalty-text fw-bold position-absolute text-start text-uppercase">
              {store.curbsideText}
            </div>
          )}
          {store.isNewStore && (
            <div
              className={
                "new-tap fw-bold position-absolute text-start text-uppercase" +
                (model.isNewBrandedSite ? "" : " pink" + " text-white")
              }
            >
              {model.newTagText}
            </div>
          )}
          {store.isComingSoon && (
            <div
              className={
                "new-tap fw-bold position-absolute text-start text-uppercase" +
                (model.isNewBrandedSite ? "" : " pink" + " text-white")
              }
            >
              {model.comingSoon}
            </div>
          )}
          <div className="header">
            <span className="helper"></span>
            <img
              src={model.defaulT_IMAGE}
              className="align-middle generic-stores-mobile"
              data-alt={store.imgUrl === "" ? "" : store.imgUrl}
              alt={store.imgAltText === "" ? "" : store.imgAltText}
              title={store.imgAltText === "" ? "" : store.imgAltText}
            />
          </div>
        </a>
        <div className="caption">
          <a
            aria-label={store?.display_Name}
            className={
              "brand-name text-uppercase d-block" +
              (model.isNewBrandedSite ? " mb-3" : "")
            }
            href={urlPrefix + model.cslash + store.urlPath}
          >
            {store.display_Name === "" ? "" : store.display_Name}
          </a>
          {storesCondition(model, store, urlPrefix)}
          <a
            aria-label={store?.display_Name}
            className="area-map d-block"
            href={urlPrefix + model.cslash + store.urlPath}
          >
            {store.level === "" && store.zone === ""
              ? ""
              : store.level + " " + store.zone}
          </a>
          {store.offersCount > 0 && (
            <>
              <a
                aria-label={model?.evenT_OFFERS}
                className={
                  "offer-tap fw-bold d-block " +
                  (model.isLPV2Site ? " premium-text" : " loyalty-text")
                }
                href={
                  urlPrefix +
                  model.cslash +
                  store.urlPath +
                  (store.offersCount > 0 ? "#" + model.evenT_OFFERS : "")
                }
              >
                <span
                  className={
                    "picto align-middle " +
                    (store.viPIconDisplay === true ? " icon-vip-card" : "")
                  }
                ></span>
                <span>{store.offersCount + " " + store.offersDictionary} </span>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function storesCondition(model, store, urlPrefix) {
  if (store.openingHours.length > 0 && store.isOpen && !store.isComingSoon) {
    {
      return store.openingHours.map((opening, key) => {
        return (
          <a key={key}
            aria-label={store?.urlPath}
            className="opening-hours fw-bold d-block"
            href={urlPrefix + model.cslash + store.urlPath}
          >
            {opening}
          </a>
        );
      });
    }
  } else if (!store.IsOpen && !store.isComingSoon) {
    return (
      <a
        aria-label={store?.urlPath}
        className="opening-hours fw-bold d-block"
        href={urlPrefix + model.cslash + store.urlPath}
      >
        {store.openingHours}
      </a>
    );
  }
}

function bandListInnerfn(model, pillarItem, i, count) {
  if (!model.isLPV2Site && !model.pillarItems[pillarItem].pillarHasImage) {
    return (
      <span className={"picto " + model.pillarItems[pillarItem].pillarPicto}></span>
    );
  } else if (!model.pillarItems[pillarItem].pillarImageMediaURL !== "") {
    return (
      <img
        src={model.defaulT_IMAGE}
        data-alt={model.pillarItems[pillarItem].pillarImageMediaURL}
        alt={model.pillarItems[pillarItem].pillarImageAlt}
      />
    );
  }
}
