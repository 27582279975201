import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler';
import { fnMallOffers } from '../Js-CommonFunctions';
import RoutableSitecoreLink from '../RoutableSitecoreLink';

export default function MallOffers({ fields }) {
  useEffect(() => {
    fnMallOffers();
  }, []);

  let i = 0;

  if (
    fields.model != null &&
    fields.model?.mallOffers != null &&
    fields.model?.mallOffers.length > 0
  ) {
    var content = fields.json.items;
    var constant = fields.constants;
    return (
      <section
        className={'our-offer ' + (fields.model.isNewBrandedsite ? ' bg-gray' : '')}
        id="offer"
      >
        <div className="container">
          <h2
            className={'page-title ' +
              (fields.model.isNewBrandedsite ? '  text-start' : 'text-black text-uppercase') +
              '  ' +
              (fields.model.isNewBrandedsite ? '' : '')
            }
          >
            {fields.model.ouroffer}
          </h2>
          <div className="history-blocks d-flex">
            {content.map((offer, index) => {
              let message = '';
              // By default sitecore external and internal links does not have the virtual path in RoutableSitecoreLink
              let url = '';
              let item = offer.fields[constant.offerUrl];
              if (item?.value?.href) {
                url = item?.value?.href
                  ?.replace(baseModel.domain, '') // removes sitecore domain
                  .replace(baseModel.appDomain, '') // removes webapp domain
                  .replace(baseModel.nodeServerPort, '') // removes port number
                  .replace(baseModel.siteUrlPrefix, ''); // removes virtual path language
                if (!url.startsWith('http')) {
                  url = baseModel.appDomain + baseModel.siteUrlPrefix + url;
                }
                item.value.href = url;
              }
              if (i == 0) {
                message = 'bg-main  text-light';
              }
              if (i == 1) {
                message = 'bg-light  text-light';
              }
              if (i == 2) {
                message = 'bg-dark  text-light';
              }
              if (i == 3) {
                message = 'bg-lighter  text-light  link-dark  last';
              }
              i++;

              return (
                <div
                  key={index}
                  className={
                    'position-relative history-block px-1 ' +
                    (fields.model.isNewBrandedsite ? '' : '  ' + message)
                  }
                >
                  <div className="item">
                    {fields.model.isNewBrandedsite === false && (
                      <div className="share-wrap">
                        <a
                          data-gtmlabel={offer.fields[constant.offerTitle]}
                          data-gtmbookmarktype={fields.model.gtmBookMarkOffer}
                          data-bs-target="#popin"
                          data-popinurl=""
                          className='share'
                          data-bs-toggle="modal"
                          href="javascript:void(0);"
                          aria-label={offer.id}
                          aria-labelledby={offer.templateId}
                        ></a>
                      </div>
                    )}
                    <div className={"picto-wrap "+(fields.model.isNewBrandedsite? " d-flex justify-content-center" :"py-4")}>
                      <i className={'picto ' + offer.fields[constant.offerpicto]?.value + (fields.model.isNewBrandedsite?' d-flex flex-column justify-content-center' :"")}></i>
                    </div>
                    <div>
                      {offer.fields[constant.offerTitle] != null &&
                        offer.fields[constant.offerTitle] != '' && (
                          <h3 className={"whats-hot-title "+(fields.model.isNewBrandedsite?" text-black" :" text-white")}>
                            <RichText field={offer.fields[constant.offerTitle]} />
                          </h3>
                        )}

                      <div className="description px-1">
                        <RichText field={offer.fields[constant.offerdescription]} />
                      </div>
                      <RoutableSitecoreLink
                        field={item}
                        showLinkTextWithChildrenPresent={false}
                        className={'btn-link '+(fields.model.isNewBrandedsite?" text-black" :" text-white")+' d-flex text-start '}
                      >
                        {fields.model.discover}
                      </RoutableSitecoreLink>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
