import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import {
  CommonBookmarkallpage,
  fnClickNavigation,
  fnUpdatePageNameInDataAttr,
  webViewEqualsOne,
} from "../Js-CommonFunctions";

const DestinationRetailersInnerPage = loadable(() => import("../DestinationRetailersInnerPage"));

export default function DestinationRetailers({ fields }) {
  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render
  const [visibleRetailers, setvisibleRetailers] = useState(8);
  useEffect(() => {
    setvisibleRetailers(8);
  }, []);

  useEffect(() => {
    CommonBookmarkallpage();
    document
      .querySelectorAll("#destinationstores .gtmClickNavigation")
      .forEach((x) => {
        x.addEventListener("click", function (e) {
          fnUpdatePageNameInDataAttr(e.currentTarget, "gtmclickposition");
          fnClickNavigation(e.currentTarget);
        });
      });
  }, [visibleRetailers]);

  function ShowMoreRetailers(e, retailersUrl, model) {
    e.preventDefault();

    var itemsToDisplay = visibleRetailers + 8;
    setvisibleRetailers(itemsToDisplay);
    if (document.getElementById("viewmoreretailerscount")) {
      var viewcount =
        parseInt(document.getElementById("viewmoreretailerscount").value) + 1;
      model.retailerPageNumber = viewcount;
    }
    if (document.getElementById("totalviewmoreretailerscount")) {
      var totalcount = document.getElementById("totalviewmoreretailerscount")
        .value;

      if (totalcount > itemsToDisplay) {
        document.getElementById("storeViewMore").style.display = "block";
      } else {
        document.getElementById("storeViewMore").style.display = "none";
      }
    }
    webViewEqualsOne();
    CommonBookmarkallpage();
  }

  const fnRetailersViewMore = (model, ajaxurl, isNewBrandedsite) => {
    if (isNewBrandedsite) {
      return (
        <div className="mb-5 view-more-unexpected" id="storeViewMore">
          <div
            className="analytics_searchstorevm" data-testid="test-id"
            onClick={(e) => ShowMoreRetailers(e, ajaxurl, model)}
          >
            {model.viewMore}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="mb-5 view-more-unexpected view-more storeViewMore"
          id="storeViewMore" data-testid="test2-id"
          onClick={(e) => ShowMoreRetailers(e, ajaxurl, model)}
        >
          {" "}
          <div className="analytics_searchstorevm">{model.viewMore}</div>
          <i className="picto icon-go-down-arrow"></i>
        </div>
      );
    }
  };

  if (
    fields.listofShopRestaurantCategoryViewModel !== null &&
    fields.listofShopRestaurantCategoryViewModel !== undefined &&
    fields.listofShopRestaurantCategoryViewModel !== ""
  ) {
    let destinationType =
      fields.listofShopRestaurantCategoryViewModel != null &&
        fields.listofShopRestaurantCategoryViewModel.destinationType != null &&
        fields.listofShopRestaurantCategoryViewModel.destinationType !== ""
        ? fields.listofShopRestaurantCategoryViewModel.destinationType
          .toString()
          .replace(fields.props.leftFlowerBraces, "")
          .replace(fields.props.rightFlowerBraces, "")
        : "";
    let destinationTitle =
      fields.listofShopRestaurantCategoryViewModel.destinationTitle;
    let isNewBrandedsite =
      fields.listofShopRestaurantCategoryViewModel.isRebranded;
    let urlPrefix = fields.listofShopRestaurantCategoryViewModel.urlPrefix;
    let ajaxurl =
      "/api/sitecore/DestinationRetailersApi/GetDestinationRetailersFilter?ursite=" +
      fields.listofShopRestaurantCategoryViewModel.contextSite +
      "&destinationType=" +
      destinationType +
      "&urlang=" +
      fields.listofShopRestaurantCategoryViewModel.contextLanguage;

    return (
      <section
        className={"stores stores-new back-gray bg-4" + (isNewBrandedsite ? " " : "")}
        id="destinationstores"
      >
        <div className="container">
          <div className="header-title destination d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            <h2 className="title">
              {fields.props.isStorePageExists ? (
                <a
                  aria-label={fields?.listofShopRestaurantCategoryViewModel?.storeTitle}
                  href={
                    urlPrefix +
                    fields.props.slash +
                    fields.listofShopRestaurantCategoryViewModel.storesPage
                  }
                  className="gtmClickNavigation"
                >
                  {fields.listofShopRestaurantCategoryViewModel.storeTitle}
                </a>
              ) : (
                <span>
                  {fields.listofShopRestaurantCategoryViewModel.storeTitle}
                </span>
              )}
              <span>{" & "}</span>
              {fields.props.isRestaurantsPageExists ? (
                <a
                  aria-label={fields?.listofShopRestaurantCategoryViewModel?.restaurantsPage}
                  href={
                    urlPrefix +
                    fields.props.slash +
                    fields.listofShopRestaurantCategoryViewModel.restaurantsPage
                  }
                  className="gtmClickNavigation"
                >
                  {fields.listofShopRestaurantCategoryViewModel.restaurantTitle}
                </a>
              ) : (
                <span>
                  {fields.listofShopRestaurantCategoryViewModel.restaurantTitle}
                </span>
              )}
              <span>{" - " + destinationTitle}</span>
            </h2>
            <div className={isNewBrandedsite ? "d-flex flex-column" : "d-flex flex-column"}>
              {fields.props.isStorePageExists && (
                <a
                  aria-label={fields?.listofShopRestaurantCategoryViewModel?.storesPage}
                  className={
                    (isNewBrandedsite ? " w-100 " : "") +
                    " action gtmClickNavigation"
                  }
                  href={
                    urlPrefix +
                    fields.props.slash +
                    fields.listofShopRestaurantCategoryViewModel.storesPage
                  }
                >
                  <strong>
                    {fields.listofShopRestaurantCategoryViewModel.seeAllStores +
                      " (" +
                      fields.listofShopRestaurantCategoryViewModel.storesCount +
                      ")"}
                  </strong>
                  <span className="picto icon-ico-chevron-right"></span>
                </a>
              )}
              {fields.props.isRestaurantsPageExists && (
                <a
                  aria-label={fields?.listofShopRestaurantCategoryViewModel?.restaurantsPage}
                  className={
                    (isNewBrandedsite ? " w-100 " : "") +
                    " action gtmClickNavigation"
                  }
                  href={
                    urlPrefix +
                    fields.props.slash +
                    fields.listofShopRestaurantCategoryViewModel.restaurantsPage
                  }
                >
                  <strong>
                    {fields.listofShopRestaurantCategoryViewModel
                      .seeAllRestaurants +
                      " (" +
                      fields.listofShopRestaurantCategoryViewModel
                        .restaurantsCount +
                      ")"}
                  </strong>
                  <span className="picto icon-ico-chevron-right"></span>
                </a>
              )}
            </div>
          </div>
          <div className="row" id="retailers">
            <DestinationRetailersInnerPage
              model={fields.listofShopRestaurantCategoryViewModel}
              itemsPerPage={visibleRetailers}
              props={fields.props}
            />
          </div>
          {fields.listofShopRestaurantCategoryViewModel.displaystoreitems
            .length >
            fields.listofShopRestaurantCategoryViewModel.retailerPageNumber *
            fields.listofShopRestaurantCategoryViewModel.itemsPerPage &&
            fnRetailersViewMore(
              fields.listofShopRestaurantCategoryViewModel,
              ajaxurl,
              isNewBrandedsite
            )}
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
