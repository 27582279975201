import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React from "react";
import RoutableSitecoreLink from "../RoutableSitecoreLink";
export default function HeroOneColumnRightText({ fields }) {
    if (fields?.heroItems?.backgroundImage != null) {
        return (
            <section className="whats-hot" id="hero3">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 item picture-and-text">
                            <div className="clearfix">
                                <div className="whats-hot-item whats-hot-picture left bg-main d-none d-sm-block">
                                    {fields.heroItems.isExperienceEditor ?
                                        (<Image field={fields.heroItems.item.backgroundImage} imageParams={{ mh: 224 }} />) :
                                        (<img src={fields.heroItems.defaultImage} data-alt={fields.heroItems.item.backgroundImageMaxHeight} alt={fields.heroItems.item.backgroundImage.value.alt} title={fields.heroItems.item.backgroundImage.value.alt} />)
                                    }
                                </div>
                                <div className="whats-hot-item whats-hot-text right bg-main text-light">
                                    <div className="whats-hot-content">
                                        <div className="vertical-align">
                                            <div className="no-picto"></div>
                                            {(fields.heroItems.item.heading != null && fields.heroItems.item.heading != "") &&
                                                <RichText field={fields.heroItems.item.heading} tag="h3" className="whats-hot-title text-uppercase" />
                                            }
                                            <div className="description"><RichText field={fields.heroItems.item.description} /></div>
                                            {fields.heroItems.customMobile != null &&
                                                fields.heroItems.customMobile.map((linkitems, index) => {
                                                    let storeclass = "";
                                                    let strAnalyticsLabel = "";
                                                    let strGTMAppOS = "";
                                                    if (linkitems.platformItem.toLowerCase() == fields.heroItems.android.toLowerCase()) {
                                                        storeclass = fields.heroItems.googlePlay;
                                                        strAnalyticsLabel = fields.heroItems.analytics_Label_GooglePlay;
                                                        strGTMAppOS = fields.heroItems.android;
                                                    }
                                                    if (linkitems.platformItem.toLowerCase() == fields.heroItems.itunes.toLowerCase()) {
                                                        storeclass = fields.heroItems.appStore;
                                                        strAnalyticsLabel = fields.heroItems.analytics_Label_Appstore;
                                                        strGTMAppOS = fields.heroItems.iOS;
                                                    }
                                                    let btnCss = fields.heroItems.analytics_Appstore + storeclass + " " + fields.heroItems.btn_Transparent;
                                                    return (<RoutableSitecoreLink field={linkitems.mobileApp.link} className={btnCss} data_analyticsLabel={strAnalyticsLabel} data_gtmappos={strGTMAppOS} data_gtmevent="appDownloadCTAclick" key={index}>
                                                        {linkitems.mobileApp.link != null &&
                                                            <>{linkitems.mobileApp.link_Label}</>
                                                        }
                                                    </RoutableSitecoreLink>)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else {
        return <></>
    }
}