import React, { useEffect } from 'react';
import { CategoryClickMobile, RetailerSearch } from '../national.js';

export default function RetailersList({ fields }) {
  useEffect(() => {
    if (fields !== null && fields !== undefined && fields !== "") {
      CategoryClickMobile();
      RetailerSearch();
    }
  }, []);

  let count;
  if (fields !== null && fields !== undefined && fields !== "") {
    return (
      <>
        <section className="filter-area stores">
          <div className="container">
            <div className="row filter-wrap">
              <div className="col-12 col-lg-3 filter-category-wrap" id="filterCategorywrap">
                <div className="global-form retail-search">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="RetailerSearchText"
                      aria-label="search-text"
                      className="picto icon-search-rw"
                    ></label>

                    <input
                      type="text"
                      className="form-control"
                      id="RetailerSearchText"
                      placeholder={fields.retailersList.findRetailers}
                    />
                  </div>
                </div>

                <div
                  className="action btn-show-filter bg-transparent"
                  id="categoryfilter"
                  href="#brandFilter"
                  role="button"
                  aria-expanded="false"
                  aria-controls="brandFilter"
                >
                  <a className="link-button text-uppercase btn-see-more link-expandable">
                    {fields.retailersList.filterBy}
                    <span className="picto icon-go-down-arrow godown"></span>
                  </a>
                </div>
                <div id="brandFilter" className="brandfilter collapse">
                  <h2 className="filter-wrap-title text-uppercase">
                    {fields.retailersList.retailersCategory}
                  </h2>

                  <ul
                    className={
                      'filter-category-list ' +
                      ((fields.retailersList.selectedParentCategory == null ||
                        fields.retailersList.selectedParentCategory == '') &&
                        (fields.retailersList.selectedChildCategory == null ||
                          fields.retailersList.selectedChildCategory == '')
                        ? 'selected'
                        : '')
                    }
                    id="filter-category-list"
                  >
                    <li>
                      <a
                        href={fields.retailersList.retailersPageUrl}
                        id="AtoZtag"
                        className={
                          (fields.retailersList.selectedParentCategory == null ||
                            fields.retailersList.selectedParentCategory == '') &&
                            (fields.retailersList.selectedChildCategory == null ||
                              fields.retailersList.selectedChildCategory == '')
                            ? 'selected'
                            : ''
                        }
                      >
                        {fields.retailersList.allRetailers +
                          ' (' +
                          fields.retailersList.retailersCount +
                          ')'}
                      </a>
                    </li>
                    <hr />
                    {fields.retailersList.categoryInfo != undefined &&
                      fields.retailersList.categoryInfo.map((parentCategory, index) => {
                        return (
                          <li key={index}>
                            <a
                              id="filter"
                              className={
                                parentCategory.active &&
                                  (fields.retailersList.selectedChildCategory == null ||
                                    fields.retailersList.selectedChildCategory == '')
                                  ? 'selected'
                                  : ''
                              }
                              href={parentCategory.url}
                              role="button"
                              aria-expanded={parentCategory.active ? true : false}
                            >
                              {parentCategory.categoryName +
                                ' (' +
                                parentCategory.categoryCount +
                                ')'}
                            </a>
                            {parentCategory.ChildCategoryList != undefined &&
                              parentCategory.ChildCategoryList.length >
                              (
                                <ul
                                  className={
                                    'filter-item-list ' + !parentCategory.active ? 'collapse' : ''
                                  }
                                >
                                  {parentCategory.ChildCategoryList != undefined &&
                                    parentCategory.ChildCategoryList.map((subCategory, index) => {
                                      return (
                                        <li key={index}>
                                          <a
                                            href={subCategory.url}
                                            className={subCategory.active ? 'selected' : ''}
                                            id="filter"
                                          >
                                            {subCategory.categoryName +
                                              ' (' +
                                              subCategory.categoryCount +
                                              ')'}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="col-11 col-lg-9 filter-result-wrap">
                <div className="row filter-result">
                  {fields.retailersList.retailerList != undefined &&
                    fields.retailersList.retailerList.map((retailer, index) => {
                      return (
                        <div key={index} className="col-6 col-lg-4 mb-2 mb-lg-4 retailerblock">
                          <div className="bg-transparent border-0 card retail-block rounded-0">
                            <div className="border-8 bg-white img-wrap">
                              {count > 9 && fields.retailersList.isLazyLoadEnabled ? (
                                <img
                                  src={fields.retailersList.defaultImgSrc}
                                  data-alt={retailer.logo}
                                  className="img-fluid"
                                  alt={retailer.retailerName}
                                />
                              ) : (
                                <img
                                  src={retailer.logo}
                                  className="img-fluid"
                                  alt={retailer.retailerName}
                                />
                              )}
                            </div>
                            <div className="border-0 pt-3 card-body px-0 text-start">
                              <h5 className="retail-title text-uppercase m-0">
                                {retailer.retailerName}
                              </h5>
                              <a href={retailer.retailerUrl} className="stretched-link"></a>
                            </div>
                          </div>
                        </div>
                      );
                      count++;
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  else {
    return <></>
  }
}

