import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  CommonBookmarkallpage,
  fnClickNavigation,
  fnUpdateStoreSINameInDataAttr,
  lazyLoadingImg,
} from "../Js-CommonFunctions.js";

export default function RelatedItems({ fields }) {
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render
  useEffect(() => {
    setBoolUseEffect(true);
  }, []);

  useEffect(() => {
    lazyLoadingImg();
    CommonBookmarkallpage(); //Bind the bookmark click event and show already bookmarked items with active heart symbol

    // For the related shops component update the Retailer name in the attribute
    document
      .querySelectorAll("#related-shops .gtmClickNavigation")
      .forEach((x) => {
        x.addEventListener("click", function (e) {
          fnUpdateStoreSINameInDataAttr(e.currentTarget, "gtmclickposition");
          fnClickNavigation(e.currentTarget);
        });
      });
      //pyjama effect
      // if (document.getElementById("related-shops")) {
      //   var className = document.getElementsByClassName("related-items-img-pyjama")
      //   if (document.getElementById("related-shops").classList.contains("even")) {
      //     for (var index = 0; index < className.length; index++) {
      //       if (className[index])
      //         className[index].classList.add("bg-white");
      //       if (document.getElementsByClassName("related-items-content-pyjama")[index])
      //         document.getElementsByClassName("related-items-content-pyjama")[index].classList.add("bg-gray");
      //     }
      //   }
      //   else if(document.getElementById("related-shops").classList.contains("odd")) {
      //     for (var index = 0; index < className.length; index++) {
      //       if (className[index])
      //       className[index].classList.add("bg-gray");
      //       if (document.getElementsByClassName("related-items-content-pyjama")[index])
      //       document.getElementsByClassName("related-items-content-pyjama")[index].classList.add("bg-white");
    
      //     }
      //   }
      // }
  }, [boolUseEffect]);
  if (fields?.storeItems !== null && fields?.storeItems?.relatedItemCount > 1) {
    var model = fields.storeItems;
    let cnt = 0;
    let generalLinkClickPosition =
      "CrossSelling-" + model.itemType + "-{storeSIName}";
    let bookMarkClickPostion = model.itemType + "/#CrossSelling/Bookmark";
    return (
      <section
        className={"related-shops " + (model.isNewBrandedSite ? " " : "")}
        id="related-shops"
      >
        <div className="wrapper container">
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            {model.title.trim() !== "" && (
              <a
                className="title mb-1 mb-lg-0 gtmClickNavigation"
                href={model.urlString}
              >
                <h2>{model.title.trim() !== "" ? model.title : ""}</h2>
              </a>
            )}           
              <a
                className="action gtmClickNavigation"
                href={model.urlString}
              >
                <strong>{model.generalLink}</strong> ({model.totalCount})
                {/* <span className="picto icon-ico-chevron-right"></span> */}
              </a>      
          </div>

          <div className="row carousel-related-shop">
            {model.items.map((store, index) => {
              model.storecategoryname = "";
              if (store.storeId !== model.itemId.toString() && cnt < 5) {
                cnt++;
                let storeItemClickPosition =
                  "CrossSelling-" +
                  cnt +
                  "-" +
                  model.itemType +
                  "-{storeSIName}";
                return (
                  <div className="col pe-lg-3" key={index}>
                    <div
                      className={
                        "thumbnail " + (model.isUSUK ? model.usUkStoreLogo : "")
                      }
                    >
                      {store.isNewStore && (
                        <a
                          href="#"
                          className={
                            "new-tap " + (model.isNewBrandedSite ? "loyalty-text" : "pink")
                          }
                        >
                          {model.newTag}
                        </a>
                      )}
                      {store.isComingSoon && (
                        <a
                          href="#"
                          className={
                            "new-tap " + (model.isNewBrandedSite ? " loyalty-text" : "pink")
                          }
                        >
                          {model.comingSoon}
                        </a>
                      )}
                      <a
                        href="javascript:void(0);"
                        role="button"
                        className="open-popin picto bookmark icon-heart-unfill-rw  bookmark-news-new ava-wishlist "
                        aria-label={store.storeId}
                        aria-labelledby={model.itemType}
                        data-itemlabel={store.display_Name}
                        data-templatetype={(model.itemType === "store") ? "Store Detail" : "Restaurant Detail"}
                      ></a>

                      <a
                        href={model.urlPrefix + store.urlPath}
                        className="gtmClickNavigation"
                      >
                        <div className="header related-items-img-pyjama bg-white">
                          <span className="helper"></span>
                          <img
                            src={model.defaultImage}
                            data-alt={
                              store.imgUrl.trim() == "" ? "" : store.imgUrl
                            }
                            alt={store.imgAltText}
                          />
                        </div>
                      </a>
                      <div
                        className=
                        "related-items-content-pyjama caption text-start"

                      >
                        <span className="d-flex flex-row align-items-center">
                          <a
                            className="brand-name my-1 gtmClickNavigation"
                            href={model.urlPrefix + store.urlPath}
                          >
                            {store.display_Name}
                          </a>
                          {/* <a className="offer-tap-stores fw-bold d-flex align-items-center text-start loyalty-text px-2 m-0" href="/france/aeroville/boutiques-detail/etam-lingerie/64070#offers">
                            <span class="picto align-middle pe-2 icon-support-rw"></span>
                            <span class="text-uppercase">1 Offre</span>
                          </a> */}
                          <a
                            className={
                              "offer-tap-stores fw-bold d-flex align-items-center text-start loyalty-text px-2 m-0 gtmClickNavigation"
                            }
                            href={
                              model.urlPrefix +
                              store.urlPath +
                              (store.offersCount > 0
                                ? "#" + model.eventOffers
                                : "")
                            }
                          >
                            {store.offersCount > 0 && (
                              <>
                                <span
                                  className={
                                    "picto align-middle pe-2 " +
                                    (store.viPIconDisplay == true
                                      ? "icon-support-rw"
                                      : "")
                                  }
                                ></span>
                                <span className="text-uppercase">
                                  {store.offersCount +
                                    " " +
                                    store.offersDictionary}{" "}
                                </span>
                              </>
                            )}
                          </a>
                        </span>
                        {store.openingHours.length > 0 &&
                          store.isOpen &&
                          !store.isComingSoon
                          ? store.openingHours.map((opening, index2) => {
                            return (
                              <a
                                className="opening-hours gtmClickNavigation"
                                href={model.urlPrefix + store.urlPath}
                                key={index2}
                              >
                                {opening}
                              </a>
                            );
                          })
                          : !store.isOpen &&
                          !store.isComingSoon && (
                            <a
                              className="opening-hours gtmClickNavigation"
                              href={model.urlPrefix + store.urlPath}
                            >
                              {store.openingHours[0]}
                            </a>
                          )}

                        <a
                          className="area-map  gtmClickNavigation"
                          href={
                            model.urlPrefix +
                            store.urlPath +
                            (store.level.trim() === "" &&
                              store.zone.trim() === ""
                              ? ""
                              : "#topBar")
                          }
                        >
                          {store.level.trim() === "" && store.zone.trim() === ""
                            ? ""
                            : store.level + " " + store.zone}
                        </a>

                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
