import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler.js';
import { setCookie } from '../Js-CommonFunctions.js';

export default function ErrorPopin({ model }) {
    useEffect(() => {
        var ele = document.getElementsByClassName("techErrorPopin")[0];
        if (ele !== null && ele !== undefined) {
            var popinClick = document.getElementById("popinClick");
            popinClick.click();
        }
    }, [model]);

    function ResetTcErrorCookie() {
        if ((model.invalid_Credentials_Popin || model.missing_IBAN || model.cashback_Rejected_Popin)) {
            setCookie(baseModel.tcErrorCookieName, 'notified');
        }
    }

    var urlPrefix = model.url_Prefix;
    var gtmErrorMessage = "";
    var gtmPushType = "";

    if (model.invalid_Credentials_Popin) {
        gtmErrorMessage = "invalid_credentials";
        gtmPushType = "popup_cta";
    }
    else if (model.cashback_Rejected_Popin) {
        gtmErrorMessage = "add_ibanCode";
        gtmPushType = "popup_cta";
    }
    else if (model.missing_IBAN) {
        gtmErrorMessage = "missing_iban_popin";
        gtmPushType = "popup_cta";
    }

    function renderAnchorTag(model) {
        if (model.content_Link_URL != null && (model.content_Link_URL.value?.url) && model.cashback_Rejected_Popin) {
            return (
                <a aria-label={model?.content_Link_Text.value} id="lnkErrorPopinCTA" className="link-button text-uppercase btn-loyalty" href={model.manageMyCredentialsPageUrl}
                    onClick={() => { setCookie(baseModel.tcErrorCookieName, 'notified') }} >{model.content_Link_Text.value}</a>
            )
        }
        else if (model.invalid_Credentials_Popin) {
            return (
                <a aria-label={model?.content_Link_Text.value} id="lnkErrorPopinCTA" className="link-button text-uppercase btn-loyalty" href={model.manageMyCredentialsPageUrl}
                    onClick={() => { setCookie(baseModel.tcErrorCookieName, 'notified'); }} >{model.content_Link_Text.value}</a>
            )
        }
        else if (model.missing_IBAN) {
            return (
                <a aria-label={model?.content_Link_Text?.value} id="lnkErrorPopinCTA" className="link-button text-uppercase btn-loyalty" href={model.manageMyIBANPageUrl}
                    onClick={() => { setCookie(baseModel.tcErrorCookieName, 'notified'); }} >{model.content_Link_Text.value}</a>
            )
        }
        else if (model.content_Link_URL != null && (model.content_Link_URL.value?.url !== null && model.content_Link_URL.value?.url !== "")) {
            return (
                <a aria-label={model?.content_Link_Text?.value} id="lnkErrorPopinCTA" className="link-button btn-white center-block" href={model.content_Link_URL_Text}>{model.content_Link_Text.value}</a>
            )
        }
    }

    if (model !== null) {
        return (
            <div className="modal-dialog techErrorPopin" id={model.invalid_Credentials_Popin || model.cashback_Rejected_Popin || model.missing_IBAN ? "tcErrorPopin" : "technicalErrorPopin"}>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" id="btnCloseErrorPopin" className="picto icon-close-circle-rw" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => { ResetTcErrorCookie() }}></button>
                    </div>
                    <div className="modal-body text-center">
                        <div className="picto-wrap">
                            <span className={"picto " + (model.invalid_Credentials_Popin ? "icon-cash-back" : (model.missing_IBAN || model.cashback_Rejected_Popin) ? "icon-impossible-credit" : "icon-lock")}></span>
                        </div>
                        <div className="popin-wrap">
                            {(model.content_Heading !== null && model.content_Heading !== "" && model.content_Heading.value !== null) &&
                                <h3 className="msg-title text-uppercase">{model.content_Heading.value}</h3>
                            }
                            {(model.content_Title !== null && model.content_Title !== "" && model.content_Title.value !== null) &&
                                <p className="msg-text-bold">{model.content_Title.value}</p>
                            }
                            <p className="msg-text">{model.content_Introduction.value}</p>

                            <>{renderAnchorTag(model)}</>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<></>)
    }
}
