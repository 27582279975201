import { Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { baseModel } from '../../RouteHandler';
import { carouselLoad, fnCarouselDotsEvent, fnGTMCarouselViewEvent, isMobile } from "../Js-CommonFunctions";
import ReactPlayerCarousel from "../ReactPlayerCarousel";

export default function CarouselComponent({ fields }) {
  const [windowinnerWidth, setwindowinnerWidth] = useState(1000); //by default set to be greater value to target desktop device
  const ref = useRef(null);
  let showClickCarouselBannerGtm = false;

  var stopAllYouTubeVideos = () => {
    var iframes = document.querySelectorAll("iframe");
    Array.prototype.forEach.call(iframes, (iframe) => {
      iframe.contentWindow.postMessage(
        JSON.stringify({ event: "command", func: "pauseVideo" }),
        "*"
      );
      iframe.contentWindow.postMessage(
        JSON.stringify({ method: "pause", value: "true" }),
        "*"
      );
    });

    var videoElements = document.querySelectorAll("video");
    Array.prototype.forEach.call(videoElements, (video) => {
      video.pause();
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setwindowinnerWidth(window.innerWidth);
    }
    //Carousel bullets gtm
    document.querySelectorAll(".carouseldots").forEach((item) => {
      item.addEventListener("click", function (e) {
        {
          fnCarouselDotsEvent(e.target.getAttribute('data-index'));
        }
      })
    })

    //Pushing banner gtm
    document.querySelectorAll(".gaCarouselView").forEach((item) => {
      item.addEventListener("click", gtmClickCarouselBanner, { once: true })
    })

    if (fields && fields.carousel?.isLoyaltyPage && isMobile.any() && document.querySelectorAll(".slick-list")[0] !== null && document.querySelectorAll(".slick-list")[0] !== undefined && document.querySelectorAll(".slick-list")[0] !== "") {
      document.querySelectorAll(".slick-list")[0].classList.add('loyalty-program-slick-list');
    }

  }, []);

  useEffect(() => {
    carouselLoadingImgMobile();
    carouselLoadingImgDesktop();
    carouselLoad();

  }, [windowinnerWidth]);

  function gtmClickCarouselBanner(e) {
    var carouselPosition = e.currentTarget.getAttribute('data-position')
    if (showClickCarouselBannerGtm) {
      if (window.dataLayer !== undefined) {
        window.dataLayer.push({
          'carrousel_position': (carouselPosition !== undefined && carouselPosition !== null && carouselPosition !== "") ? carouselPosition : "undefined",
          'event': 'click_carrousel_banner',
        });
      }
    }
  }

  let i = 0;
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className={"bg-main carouseldots index"}
          data-role="none"
          data-index={i + 1}
          role="button"
          tabIndex="0"
        >
          {i}
        </button>
      );
    },
    arrows: false,
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => {
      fnGTMCarouselViewEvent(
        document.getElementsByClassName("carousel-news")[0]
      );
    },
    beforeChange: () => {
      stopAllYouTubeVideos();
    },
  };

  if (fields && fields.carousel?.items?.length > 0) {
    return (
      <section
        className={fields.carousel.isLoyaltyPage ? "loyalty-block-wc p-0" : fields.carousel.carouselSectionClassName + (fields.carousel.isNewBrandedSite ? "  news back-white" : " ")}
        id="newscarousel"
      >
        <div className={fields.carousel.isLoyaltyPage ? " loyalty-slider-block py-5 py-lg-5 wrapper container" : "wrapper container"}>
          {fields.carousel.displayCarouselTitle ?
            !fields.carousel.isLoyaltyPage ?
              <div
                className={
                  (fields.carousel.isNewBrandedSite ? "" : "carousel-title mb-3 ") +
                  "header-title d-flex align-items-baseline flex-wrap justify-content-lg-between"
                }
              >
                {fields.carousel.isWhatsHotPage &&
                  fields.carousel.newsCount > 0 ? (
                  fields.carousel.isBackWhite == true ? (
                    <>
                      <a
                        aria-label={fields?.carousel?.headingLeftLink}
                        className={
                          (fields.carousel.isNewBrandedSite
                            ? "mt-0 title"
                            : "title mb-1 mb-lg-0") + " gtmClickNavigation"
                        }
                        href={fields.carousel.headingLeftLink}
                      >
                        {fields.carousel.carouselTitle != "" &&
                          fields.carousel.carouselTitle != null && (
                            // <Text
                            //   field={fields.carousel.carouselTitle}
                            //   tag="h2"
                            // />
                            <h2>
                              <span className="news-title">{fields.carousel.carouselTitle.value}</span>
                            </h2>
                          )}
                      </a>
                      {/* <a
                        className="action picto icon-plus-big gtmClickNavigation"
                        aria-label="news-link"
                        href={fields.carousel.headingRightLink}
                      ></a> */}
                    </>
                  ) : !fields.carousel.isNewBrandedSite ? (
                    <>
                      <a
                        aria-label={fields?.carousel?.headingLeftLink}
                        className="title mb-1 mb-lg-0 gtmClickNavigation"
                        href={fields.carousel.headingLeftLink}
                      >
                        {fields.carousel.carouselTitle != "" &&
                          fields.carousel.carouselTitle != null && (
                            // <Text
                            //   field={fields.carousel.carouselTitle}
                            //   tag="h2"
                            // />
                            <h2>
                            <span className="news-title">{fields.carousel.carouselTitle.value}</span>
                          </h2>
                          )}
                      </a>
                      {/* <a
                        className="action gtmClickNavigation"
                        href={fields.carousel.headingRightLink}
                        aria-label={fields?.carousel?.newsCount}
                      >
                        <strong>{fields.carousel.carouselLink}</strong>
                        {" (" + fields.carousel.newsCount + ")"}
                        <span className="picto icon-ico-chevron-right"></span>
                      </a> */}
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <a
                    className={
                      fields.carousel.isNewBrandedSite
                        ? "mt-0 title"
                        : "title mb-1 mb-lg-0"
                    }
                    href="#"
                    aria-label={fields?.carousel?.carouselTitle}
                  >
                    {fields.carousel.carouselTitle != "" &&
                      fields.carousel.carouselTitle != null && (
                        // <Text field={fields.carousel.carouselTitle} tag="h2" />
                        <h2>
                        <span className="news-title">{fields.carousel.carouselTitle.value}</span>
                      </h2>
                      )}
                  </a>
                )}
              </div>
              : <div className={"loyalty-block-header-title d-flex flex-wrap"}>{fields.carousel.title}</div>
            : <></>}

          <Slider
            ref={ref}
            {...settings}
            className="carousel-news  gacarousel carouselDiv"
            id="carouselNews" 
          >
            {fields.carousel.items.map((item, index) => {
              i++;
              const itemIndex = i.toString();
              const blockPosition =
                item.text_Placement == "Left"
                  ? "justify-content-start"
                  : item.text_Placement == "Right"
                    ? "justify-content-end"
                    : "justify-content-center";
              const textPosition =
                item.text_Alignment == "Left"
                  ? "align-items-start text-start"
                  : item.text_Alignment == "Right"
                    ? "align-items-end text-end"
                    : "align-items-center text-center";
              const color =
                item.text_Colour != null && item.text_Colour != ""
                  ? item.text_Colour + ""
                  : fields.carousel.isPageEditor
                    ? "black"
                    : "white";
              const opentab =
                item.button != null &&
                  item.button.value.target.length > 0 &&
                  item.button.value.target == "_blank"
                  ? "_blank"
                  : "_self";
              const openLinktab =
                item.link != null &&
                  item.link.value.target.length > 0 &&
                  item.link.value.target == "_blank"
                  ? "_blank"
                  : "_self";

              if (item.jssBackground_Image?.value?.src != "" ||
                (item.button?.value?.text != null && item.button?.value?.text != "" && item.button?.value?.url != null && item.button?.value?.url != "") ||
                item.videoLink != null && item.videoLink.value?.url != "") {
                showClickCarouselBannerGtm = true;
              }

              return (
                <div
                  key={index}
                  className={fields.carousel.isLoyaltyPage ?
                    "gaCarouselView loyalty-program-slick-list " + fields.carousel.analytics_CarouselClick : "gaCarouselView " + fields.carousel.analytics_CarouselClick
                  }
                  data-id={"carrousel-" + itemIndex}
                  data-position={itemIndex}
                >
                  {item.videoLink != null && item.videoLink.value.url != "" ? (
                    <div
                      className={fields.carousel.analytics_CarouselClick + " videoclick"}
                      aria-label={item.videoLink.value.url}
                    >
                      <ReactPlayerCarousel fields={item} carouselref={ref} windowinnerWidth={windowinnerWidth} />
                    </div>
                  ) : (
                    <div
                      className={(item.dark_Overlay ? "dark-overlay-active " : "") + "carousel-card"}
                    >

                      {windowinnerWidth < 991 && (
                        <div className={fields.carousel.isLoyaltyPage ? "loyalty-program-img position-relative mobile" : "carousel-img position-relative mobile"}>
                          <img
                            src={fields.carousel.defaultImage}
                            className={fields.carousel.isLoyaltyPage ? "w-100" : "img-fluid"}
                            loading="lazy"
                            data-alt={
                              item.jssMobile_Image != null &&
                                item.jssMobile_Image.value != null &&
                                item.jssMobile_Image.value.src != ""
                                ? item.jssMobile_Image.value.src
                                : item?.jssBackground_Image?.value?.src
                            }
                            id={"carouselLoadingMobile" + index}
                            alt={item?.jssMobile_Image?.value?.src}
                          />
                        </div>
                      )}
                      {windowinnerWidth > 991 && (
                        <div className={fields.carousel.isLoyaltyPage ? "loyalty-program-img position-relative desktop" : "carousel-img position-relative desktop"}>
                          <img
                            src={fields.carousel.defaultImage}
                            className={fields.carousel.isLoyaltyPage ? "" : "img-fluid"}
                            loading="lazy"
                            data-alt={
                              item.jssBackground_Image != null &&
                                item.jssBackground_Image.value != null &&
                                item.jssBackground_Image.value.src != ""
                                ? item.jssBackground_Image.value.src
                                : item.jssBackground_Image.value.src
                            }
                            id={"carouselLoadingDesktop" + index}
                            alt={item?.jssBackground_Image?.value?.src}
                          />
                        </div>
                      )}
                      <div
                        className={
                          "carousel-wrap " +
                          (!fields.carousel.isPageEditor
                            ? blockPosition + " position-absolute"
                            : "")
                        }
                      >
                        <div className={"carousel-block " + textPosition}>
                          {item.title != null && item.title.value != "" && (
                            <div className="carousel-title" style={{ color }}>
                              <Text field={item.title} />
                            </div>
                          )}
                          {item.sub_Title != null &&
                            item.sub_Title.value != "" && (
                              <div
                                className="carousel-description"
                                style={{ color }}
                              >
                                <Text field={item.sub_Title} />
                              </div>
                            )}
                          {item.button != null &&
                            item.button.value.text != null &&
                            item.button.value.text != "" &&
                            item.button.value.url != null &&
                            item.button.value.url != "" && (
                              fields.carousel.isLoyaltyPage ?
                                <div className="d-flex justify-content-center">
                                  <button
                                    className={"btn btn-white loyalty-program-member"}
                                    data-testid="testclick1"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(item.button.value.url, opentab);
                                    }}
                                  >
                                    {item.button.value.text}
                                  </button>
                                </div>
                                : <button
                                  className={"btn btn-transparent"}
                                  data-testid="testclick2"
                                  style={{
                                    color: `${color}`,
                                    border: "1px solid",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(item.button.value.url, opentab);
                                  }}
                                >
                                  {item.button.value.text}
                                </button>
                            )}
                        </div>
                      </div>
                      {item.link != null &&
                        item.link?.value?.url != null &&
                        item.link?.value?.url != "" && (
                          <a
                            className="stretched-link"
                            href={item.link.value.url}
                            target={openLinktab}
                            aria-label={item?.link?.value?.url}
                          ></a>
                        )}
                    </div>
                  )}
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    );
  } else {
    return <div></div>;
  }
}

function carouselLoadingImgMobile() {
  document.querySelectorAll("img[src='" + baseModel.defaultImage + "']").forEach(x => {
    var isInViewport;
    if (x.id !== "" && x.id !== null && x.id !== undefined && x.id.includes("carouselLoadingMobile")) {
      isInViewport = x.getBoundingClientRect().top < window.innerHeight;
    }
    else {
      isInViewport = false;
    }

    if (x.getAttribute("data-alt") != undefined && x.getAttribute("data-alt") != "" &&
      isInViewport) {
      x.setAttribute('src', x.getAttribute("data-alt"));
    }
  });
}

function carouselLoadingImgDesktop() {
  document.querySelectorAll("img[src='" + baseModel.defaultImage + "']").forEach(x => {
    var isInViewport;
    if (x.id !== "" && x.id !== null && x.id !== undefined && x.id.includes("carouselLoadingDesktop")) {
      isInViewport = x.offsetTop < (window.scrollY + window.innerHeight + 500);
    }
    else {
      isInViewport = false;
    }

    if (x.getAttribute("data-alt") != undefined && x.getAttribute("data-alt") != "" &&
      isInViewport) {
      x.setAttribute('src', x.getAttribute("data-alt"));
    }
  });
}
