import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import { baseModel } from '../../RouteHandler';
import { lazyLoadingImg } from '../Js-CommonFunctions.js';
import RoutableSitecoreLink from "../RoutableSitecoreLink";

export default function HeroOneColumnCenterText({ fields }) {
    useEffect(() => {
        lazyLoadingImg();
    }, []);

    if (fields !== null && fields !== undefined && fields !== "" && fields.heroItems !== null && fields.heroItems !== undefined && fields.heroItems !== "") {
        var content = fields.fromContentResolver;
        var constant = fields.heroItems.constant;
        if (content[constant.imageLink]?.value?.href !== undefined && content[constant.imageLink]?.value?.href !== null && content[constant.imageLink]?.value?.href !== "") {
            let url = content[constant.imageLink]?.value?.href
                ?.replace(baseModel.domain, '') // removes sitecore domain
                .replace(baseModel.appDomain, '') // removes webapp domain
                .replace(baseModel.nodeServerPort, '') // removes port number
                .replace(baseModel.siteUrlPrefix, ''); // removes virtual path language
            if (!url.startsWith('http')) {
                url = baseModel.appDomain + baseModel.siteUrlPrefix + url;
            }
            content[constant.imageLink].value.href = url;
        }
        if (fields.heroItems != null && fields.heroItems.isHeading) {
            if (fields.heroItems.item.backgroundImage != null) {
                return (
                    <section className="access-contact" id="contact">
                        <div className="container">
                            <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3 d-block d-lg-none">
                                <div className="content-title">
                                    <Text field={fields.heroItems.contactHeading} />
                                </div>
                            </div>
                            <div className="row access-block">
                                {/* <div className="col-12 col-md-6 p-lg-0"> */}
                                <div className="access-content h-100 text-center col-lg-4 col-md-12 col-sm-12 d-flex justify-content-start align-items-lg-start flex-column">
                                    <div className='header-title mb-3 d-none d-lg-block'> <Text field={fields.heroItems.contactHeading} /></div>
                                    <div className="title mb-3"><RichText field={content[constant.heading]} /></div>
                                    <div className="description mb-4"><RichText field={content[constant.description]} /></div>
                                    {(content[constant.imageLink]?.value?.href !== "" && content[constant.imageLink]?.value?.href !== null) &&
                                        <RoutableSitecoreLink field={content[constant.imageLink]} showLinkTextWithChildrenPresent={false} className={"link-button btn-dark-transparent-2 " + fields.heroItems.gtmClickNavigation} >
                                            {content[constant.imageLink]?.value?.href != "" &&
                                                <Text field={content[constant.imageLinkLabel]} />
                                            }
                                        </RoutableSitecoreLink>
                                    }
                                </div>
                                {/* </div> */}
                                <div className="col-lg-8 col-md-12 col-sm-12 p-0">
                                    <div className="img-wrap">
                                        {fields.heroItems.isExperienceEditor ?
                                            (<Image field={content[constant.backgroundImage]} imageParams={{ mh: 224 }} />) :
                                            (<img className="img-fluid border-8" src={fields.heroItems.defaultImage} data-alt={fields.heroItems.item.backgroundImageMaxHeight} alt={fields.heroItems.item.backgroundImage.value.alt} />)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
            else {
                return <></>;
            }
        }
        else {
            if (fields.heroItems.requestUrl != null && fields.heroItems.requestUrlPathAndQuery?.toLowerCase().includes(fields.heroItems.loyaltyOffers_Page) && fields.heroItems.uidCookie != null) {
                return <></>
            }
            else {
                if (fields.heroItems.backgroundImage !== null) {
                    return (
                        <section className={fields.heroItems.modelClass + (fields.heroItems.isNewBrandedsite && fields.heroItems.isJobspage ? " " : "") + " bg-1"} id="contact">
                            <div className="title-img-container">
                                <div className="row">
                                    <div className="col-md-12 position-relative p-0">
                                        {fields.heroItems.isExperienceEditor ?
                                            (
                                                <div className="img-wrap">
                                                    <Image field={content[constant.backgroundImage]} imageParams={{ mh: 411 }} />
                                                </div>
                                            ) :
                                            (fields.heroItems.item !== null && fields.heroItems.item.backgroundImage !== null && fields.heroItems.item.backgroundImage.value.src !== null && fields.heroItems.item.backgroundImage.value.src !== "" ?
                                                (<div className="img-wrap">
                                                    <img src={fields.heroItems.item.backgroundImageMaxHeight411} alt={fields.heroItems.item.backgroundImage.value.alt} />
                                                </div>) :
                                                (<></>)
                                            )
                                        }
                                        <div className="content-wrap">
                                            <div className="vertical-align">
                                                <RichText field={content[constant.heading]} />
                                                <RichText field={content[constant.description]} />
                                                {fields.heroItems.linkButton &&
                                                    <div className="action">
                                                        {(content[constant.imageLink]?.value?.href !== "" && content[constant.imageLink]?.value?.href !== null) &&
                                                            <RoutableSitecoreLink field={content[constant.imageLink]} showLinkTextWithChildrenPresent={false} className={"link-button " + (fields.heroItems.isNewBrandedsite ? "btn-white " : "btn-transparent ") + fields.heroItems.gtmClickNavigation}>
                                                                {content[constant.imageLink]?.value?.href != "" &&
                                                                    <Text field={content[constant.imageLinkLabel]} />
                                                                }
                                                            </RoutableSitecoreLink>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
                else {
                    return <></>
                }
            }
        }
    }
    else {
        return (<></>)
    }
}