import loadable from "@loadable/component";
import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { baseModel } from "../../RouteHandler.js";
import store from "../../redux/store.js";
import { doLogout, setParkingPartners, setSmartParkerCenters } from '../../redux/user/actions';
import AddVisitorVehiclePopin from "../AddVisitorVehiclePopin/index.js";
import { erasecookies, fnGTMAddVehicle, fnRedirectToMyAccount, isMobile } from "../Js-CommonFunctions.js";

const IntroductionInnerPage = loadable(() => import("../IntroductionInnerPage/index.js")
);

var elementfocus = "";
var companyelementfocus = "";
var sixteen = "16";

export function GoBackToMenu(idToHide) {
    if (isMobile.any()) {
        document.getElementById("menuList").classList.remove("d-none");
        document.getElementById(idToHide).classList.add("d-none");
        if (document.getElementById(idToHide + "-tab")) {
            document.getElementById(idToHide + "-tab").classList.remove("active");
            document.getElementById(idToHide + "-tab").classList.remove("show");
        }
    }
}

export function CloseMessage(idToHide) {
    document.getElementById(idToHide).classList.add("d-none");
}

export function GoBackFromProfileInfo() {
    document.getElementById("personalInfoContainer").classList.remove("d-none");
    if (isMobile.any()) {
        document.getElementById("personalInfoHeader").classList.remove("d-none");
    }
    document.getElementById("profileInfoContainer").classList.add("d-none");
    if (isMobile.any()) {
        document.getElementById("profileInfoHeader").classList.add("d-none");
    }
    if (!document.getElementById("profileSuccessMsg").classList.contains('d-none')) {
        document.getElementById("profileSuccessMsg").classList.add("d-none");
    }
    if (!document.getElementById("profileErrorMsg").classList.contains('d-none')) {
        document.getElementById("profileErrorMsg").classList.add("d-none");
    }

    let mobileHeaderHeight = 0;
    if (isMobile.any()) {
        mobileHeaderHeight = document.getElementById("personalInfoHeader").offsetHeight;
    }
    window.scrollTo({
        top: document.getElementById('personalInfoContainer').offsetTop - 85 - mobileHeaderHeight,
        behavior: "smooth",
    });
}

export function GoBackFromProfessionalInfo() {
    document.getElementById("personalInfoContainer").classList.remove("d-none");
    if (isMobile.any()) {
        document.getElementById("personalInfoHeader").classList.remove("d-none");
    }
    document.getElementById("professionalInfoContainer").classList.add("d-none");
    if (isMobile.any()) {
        document.getElementById("professionalInfoHeader").classList.add("d-none");
    }
    if (!document.getElementById("professionalSuccessMsg").classList.contains('d-none')) {
        document.getElementById("professionalSuccessMsg").classList.add("d-none");
    }
    if (!document.getElementById("professionalErrorMsg").classList.contains('d-none')) {
        document.getElementById("professionalErrorMsg").classList.add("d-none");
    }

    let mobileHeaderHeight = 0;
    if (isMobile.any()) {
        mobileHeaderHeight = document.getElementById("personalInfoHeader").offsetHeight;
    }
    window.scrollTo({
        top: document.getElementById('personalInfoContainer').offsetTop - 85 - mobileHeaderHeight,
        behavior: "smooth",
    });
}

export function GoBackFromVehicleInfo() {
    document.getElementById("personalInfoContainer").classList.remove("d-none");
    if (isMobile.any()) {
        document.getElementById("personalInfoHeader").classList.remove("d-none");
    }
    document.getElementById("vehicleInfoContainer").classList.add("d-none");
    if (isMobile.any()) {
        document.getElementById("vehicleInfoHeader").classList.add("d-none");
    }
    if (!document.getElementById("professionalSuccessMsg").classList.contains('d-none')) {
        document.getElementById("professionalSuccessMsg").classList.add("d-none");
    }
    if (!document.getElementById("professionalErrorMsg").classList.contains('d-none')) {
        document.getElementById("professionalErrorMsg").classList.add("d-none");
    }
}

export function GoBackFromInterestsInfo(interestsdata) {
    document.getElementById("personalInfoContainer").classList.remove("d-none");
    if (isMobile.any()) {
        document.getElementById("personalInfoHeader").classList.remove("d-none");
    }
    document.getElementById("interestSelector").classList.add("d-none");
    if (isMobile.any()) {
        document.getElementById("interestInfoHeader").classList.add("d-none");
    }
    if (!document.getElementById("professionalSuccessMsg").classList.contains('d-none')) {
        document.getElementById("professionalSuccessMsg").classList.add("d-none");
    }
    if (!document.getElementById("professionalErrorMsg").classList.contains('d-none')) {
        document.getElementById("professionalErrorMsg").classList.add("d-none");
    }

}

export function ResetData(menu) {
    if (menu === "my-personal-information") {

        if (document.getElementById("personalInfoContainer")) {
            document.getElementById("personalInfoContainer").classList.remove("d-none");
            if (isMobile.any()) {
                document.getElementById("personalInfoHeader").classList.remove("d-none");
            }
        }

        if (document.getElementById("profileInfoContainer")) {
            document.getElementById("profileInfoContainer").classList.add("d-none");
            if (isMobile.any()) {
                document.getElementById("profileInfoHeader").classList.add("d-none");
            }
        }

        if (document.getElementById("professionalInfoContainer")) {
            document.getElementById("professionalInfoContainer").classList.add("d-none");
            if (isMobile.any()) {
                document.getElementById("professionalInfoHeader").classList.add("d-none");
            }
        }

        if (document.getElementById("interestSelector")) {
            document.getElementById("interestSelector").classList.add("d-none");
            if (isMobile.any()) {
                document.getElementById("interestInfoHeader").classList.add("d-none");
            }
        }

        if (document.getElementById("vehicleInfoContainer")) {
            document.getElementById("vehicleInfoContainer").classList.add("d-none");
            if (isMobile.any()) {
                document.getElementById("vehicleInfoHeader").classList.add("d-none");
            }
        }

        if (document.getElementById("profileSuccessMsg") &&
            !document.getElementById("profileSuccessMsg").classList.contains('d-none')) {
            document.getElementById("profileSuccessMsg").classList.add("d-none");
        }

        if (document.getElementById("profileErrorMsg") &&
            !document.getElementById("profileErrorMsg").classList.contains('d-none')) {
            document.getElementById("profileErrorMsg").classList.add("d-none");
        }

        if (document.getElementById("professionalSuccessMsg") &&
            !document.getElementById("professionalSuccessMsg").classList.contains('d-none')) {
            document.getElementById("professionalSuccessMsg").classList.add("d-none");
        }

        if (document.getElementById("professionalErrorMsg") &&
            !document.getElementById("professionalErrorMsg").classList.contains('d-none')) {
            document.getElementById("professionalErrorMsg").classList.add("d-none");
        }
    }
    else if (menu === "my-benefits-and-services") {
        if (document.getElementById('benefitsServices'))
            document.getElementById('benefitsServices').classList.remove('d-none')
        if (document.getElementById('lpdiscovermyadvantagesmultisc'))
            document.getElementById('lpdiscovermyadvantagesmultisc').classList.remove('d-none')
        if (document.getElementById('multipreferredsc'))
            document.getElementById('multipreferredsc').remove();
        if (document.getElementById('smartparkingview'))
            document.getElementById('smartparkingview').remove();
        if (document.getElementById('opinionblock'))
            document.getElementById('opinionblock').remove();
        if (document.getElementById('loyaltydiscovermyadvantages'))
            document.getElementById('loyaltydiscovermyadvantages').remove();
        if (document.getElementById('multisctab'))
            document.getElementById('multisctab').remove();

    }
}

export function TitleMrClick() {
    document.getElementById("lblmr").classList.add("active");
    document.getElementById("lblmrs").classList.remove("active");
    document.getElementById("lblnotspecified").classList.remove("active");
}

export function TitleMrsClick() {
    document.getElementById("lblmrs").classList.add("active");
    document.getElementById("lblmr").classList.remove("active");
    document.getElementById("lblnotspecified").classList.remove("active");
}

export function TitleNotSpecifiedClick() {
    document.getElementById("lblnotspecified").classList.add("active");
    document.getElementById("lblmr").classList.remove("active");
    document.getElementById("lblmrs").classList.remove("active");
}

export function TitleValidation(isValid) {
    if (document.getElementById("lblmrs") && document.getElementById("lblmrs").classList.contains("active")) {
        document.querySelector("#mrs").checked = true;
        document.querySelector("#mr").checked = false;
        document.querySelector("#notspecified").checked = false;
        document.getElementById("gender").value = "f";
    }

    if (document.getElementById("lblmr") && document.getElementById("lblmr").classList.contains("active")) {
        document.querySelector("#mrs").checked = false;
        document.querySelector("#mr").checked = true;
        document.querySelector("#notspecified").checked = false;
        document.getElementById("gender").value = "m";
    }

    if (document.getElementById("lblnotspecified") && document.getElementById("lblnotspecified").classList.contains("active")) {
        document.querySelector("#mrs").checked = false;
        document.querySelector("#mr").checked = false;
        document.querySelector("#notspecified").checked = true;
        document.getElementById("gender").value = "u";
    }

    var titleMr = document.getElementById("mr");
    var titleMrs = document.getElementById("mrs");
    var titleNotDefined = document.getElementById("notspecified");

    if (titleMr.checked || titleMrs.checked || titleNotDefined.checked) {
        document.getElementById("TitleCheck").innerHTML = "";
    } else {
        isValid = false;
        document.getElementById("TitleCheck").innerHTML = document.getElementById("TitleCheck").getAttribute("data-validatormsg").valueOf();
        document.getElementById("TitleCheck").style.display = "block";
    }

    return isValid;
}

export function FirstNameValidation(isValid) {
    var nospecial = /^[^*|\":<>[\]{}`\\();@&$]+$/;
    var fname = document.getElementById("firstname").value.trim();

    if (fname == "") {
        document.getElementById("firstname-error").innerHTML = document.getElementById("firstname-error").getAttribute("data-validatormsg1").valueOf();
        document.getElementById("firstname-error").style.display = "block";
        document.getElementById("firstname").classList.add("error");

        if (isValid) {
            elementfocus = "firstname";
            isValid = false;
        }
    } else if (!fname.match(nospecial) || !fname.match(/^[A-Za-z\u00C0-\u017F- ']+$/)
    ) {
        document.getElementById("firstname-error").innerHTML = document.getElementById("firstname-error").getAttribute("data-validatormsg2").valueOf();
        document.getElementById("firstname-error").style.display = "block";
        document.getElementById("firstname").classList.add("error");

        if (isValid) {
            elementfocus = "firstname";
            isValid = false;
        }
    } else {
        document.getElementById("firstname-error").innerHTML = "";
        document.getElementById("firstname").classList.remove("error");
    }

    return isValid;
}

export function LastNameValidation(isValid) {
    var nospecial = /^[^*|\":<>[\]{}`\\();@&$]+$/;
    var lname = document.getElementById("lastname").value.trim();

    if (lname == "") {
        document.getElementById("lastname-error").innerHTML = document.getElementById("lastname-error").getAttribute("data-validatormsg1").valueOf();
        document.getElementById("lastname-error").style.display = "block";
        document.getElementById("lastname").classList.add("error");

        if (isValid) {
            elementfocus = "lastname";
            isValid = false;
        }
    } else if (
        !lname.match(nospecial) ||
        !lname.match(/^[A-Za-z\u00C0-\u017F- ']+$/)
    ) {
        document.getElementById("lastname-error").innerHTML = document.getElementById("lastname-error").getAttribute("data-validatormsg2").valueOf();
        document.getElementById("lastname-error").style.display = "block";
        document.getElementById("lastname").classList.add("error");

        if (isValid) {
            elementfocus = "lastname";
            isValid = false;
        }
    } else {
        document.getElementById("lastname-error").innerHTML = "";
        document.getElementById("lastname").classList.remove("error");
    }

    return isValid;
}

export function ZipCodeValidation(isValid) {
    var nospecial = /^[^*|\":<>[\]{}`\\();@&$]+$/;
    var zipCode = document.getElementById("zipcode").value.trim();

    if (zipCode == "") {
        // document.getElementById("zipcode-error").innerHTML = document.getElementById("zipcode-error").getAttribute("data-validatormsg1").valueOf();
        // document.getElementById("zipcode-error").style.display = "block";
        // document.getElementById("zipcode").classList.add("error");

        // if (isValid) {
        //     elementfocus = "zipcode";
        //     isValid = false;
        // }
        isValid = true;
    }
    else if (
        !zipCode.match(nospecial) ||
        !zipCode.match(/^[A-Za-z0-9s-]+$/)
    ) {
        document.getElementById("zipcode-error").innerHTML = document.getElementById("zipcode-error").getAttribute("data-validatormsg2").valueOf();
        document.getElementById("zipcode-error").style.display = "block";
        document.getElementById("zipcode").classList.add("error");

        if (isValid) {
            elementfocus = "zipcode";
            isValid = false;
        }
    } else {
        document.getElementById("zipcode-error").innerHTML = "";
        document.getElementById("zipcode").classList.remove("error");
    }

    return isValid;
}

export function PhoneNumberValidation(isValid) {
    var phoneNumber = document.getElementById("phonenumber").value.trim();

    if (phoneNumber !== "" && !phoneNumber.match(/^[0-9]{1,15}$/)) {
        document.getElementById("phonenumber-error").innerHTML = document.getElementById("phonenumber-error").getAttribute("data-validatormsg1").valueOf();
        document.getElementById("phonenumber-error").style.display = "block";
        document.getElementById("phonenumber").classList.add("error");

        if (isValid) {
            elementfocus = "phonenumber";
            isValid = false;
        }
    } else {
        document.getElementById("phonenumber-error").innerHTML = "";
        document.getElementById("phonenumber").classList.remove("error");
    }

    return isValid;
}

export function BirthdateValidation(isValid) {
    var datecheck = true;
    var monthcheck = true;
    var yearcheck = true;

    datecheck = document.getElementById("dateDOB").validity.valid;
    monthcheck = document.getElementById("monthDOB").validity.valid;
    yearcheck = document.getElementById("yearDOB").validity.valid;

    if (!datecheck) {
        document.getElementById("dateDOB").classList.add("error");
    }
    if (!monthcheck) {
        document.getElementById("monthDOB").classList.add("error");
    }
    if (!yearcheck) {
        document.getElementById("yearDOB").classList.add("error");
    }
    if (document.getElementById("dateDOB").value == "" && document.getElementById("monthDOB").value == "" && document.getElementById("yearDOB").value == "") {
        datecheck = true;
        monthcheck = true;
        yearcheck = true;
        document.getElementById("dateDOB").classList.remove("error");
        document.getElementById("monthDOB").classList.remove("error");
        document.getElementById("yearDOB").classList.remove("error");
        document.getElementById("errorHolderLabel").innerHTML = "";
    }
    else if (document.getElementById("dateDOB").value !== "" && (document.getElementById("monthDOB").value === "" || document.getElementById("yearDOB").value === "")) {
        if (document.getElementById("monthDOB").value === "" && document.getElementById("yearDOB").value === "") {
            monthcheck = false;
            yearcheck = false;
            document.getElementById("monthDOB").classList.add("error");
            document.getElementById("yearDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
        else if (document.getElementById("monthDOB").value === "") {
            monthcheck = false;
            document.getElementById("monthDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
        else {
            yearcheck = false;
            document.getElementById("yearDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
    }
    else if (document.getElementById("monthDOB").value !== "" && (document.getElementById("dateDOB").value === "" || document.getElementById("yearDOB").value === "")) {
        if (document.getElementById("dateDOB").value === "" && document.getElementById("yearDOB").value === "") {
            datecheck = false;
            yearcheck = false;
            document.getElementById("dateDOB").classList.add("error");
            document.getElementById("yearDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
        else if (document.getElementById("dateDOB").value === "") {
            datecheck = false;
            document.getElementById("dateDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
        else {
            yearcheck = false;
            document.getElementById("yearDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
    }
    else if (document.getElementById("yearDOB").value !== "" && (document.getElementById("dateDOB").value === "" || document.getElementById("monthDOB").value === "")) {
        if (document.getElementById("dateDOB").value === "" && document.getElementById("monthDOB").value === "") {
            datecheck = false;
            monthcheck = false;
            document.getElementById("dateDOB").classList.add("error");
            document.getElementById("monthDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
        else if (document.getElementById("dateDOB").value === "") {
            datecheck = false;
            document.getElementById("dateDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
        else {
            monthcheck = false;
            document.getElementById("monthDOB").classList.add("error");
            document.getElementById("errorHolderLabel").innerHTML =
                document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
            document.getElementById("errorHolderLabel").style.display = "block";
        }
    }
    else {
        if (
            //document.getElementById("dateDOB").value == "" ||
            document.getElementById("dateDOB").value < 1 ||
            document.getElementById("dateDOB").value > 31
        ) {
            datecheck = false;
        }
        if (
            //document.getElementById("monthDOB").value == "" ||
            document.getElementById("monthDOB").value < 1 ||
            document.getElementById("monthDOB").value > 12
        ) {
            monthcheck = false;
        }
        if (
            // document.getElementById("yearDOB").value == "" ||
            document.getElementById("yearDOB").value.length != 4
        ) {
            yearcheck = false;
        }
    }

    if (!(datecheck && monthcheck && yearcheck)) {
        // if (document.getElementById("dateDOB").value == "" && document.getElementById("monthDOB").value == "" && document.getElementById("yearDOB").value == "") {
        //     if (document.getElementById("errorHolderLabel")) {
        //         document.getElementById("errorHolderLabel").innerHTML = "";
        //         document.getElementById("errorHolderLabel").style.display = "block";
        //     }
        // }
        if (!datecheck && monthcheck && yearcheck) {
            if (isValid) {
                elementfocus = "dateDOB";
                isValid = false;
            }
        } else if (datecheck && !monthcheck && yearcheck) {
            if (isValid) {
                elementfocus = "monthDOB";
                isValid = false;
            }
        } else if (datecheck && monthcheck && !yearcheck) {
            if (isValid) {
                elementfocus = "yearDOB";
                isValid = false;
            }
        } else {
            if (isValid) {
                elementfocus = "dateDOB";
                isValid = false;
            }
        }

    }
    return isValid;
}

export function BirthDateEventsValidation() {
    var hasDateError = false;
    var hasMonthError = false;

    // Blur Events
    if (document.getElementById("dateDOB")) {
        document.getElementById("dateDOB").addEventListener("blur", function () {
            if (document.getElementById("dateDOB").validity.valid == false) {
                document.getElementById("dateDOB").classList.add("error");
                if (document.getElementById("dateDOB").value == "") {
                    // document.getElementById("errorHolderLabel").innerHTML =
                    //     document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
                }
                else {
                    document.getElementById("errorHolderLabel").innerHTML =
                        document.getElementById("errorHolderLabel").getAttribute("data-validatormsg2").valueOf();
                }
                document.getElementById("errorHolderLabel").style.display = "block";
                hasDateError = true;
            } else {
                var dateValue = parseInt(document.getElementById("dateDOB").value);
                var maxDateValue = document.getElementById("dateDOB").getAttribute("max");
                // if (
                //     dateValue === "" ||
                //     dateValue === undefined ||
                //     dateValue === null ||
                //     Number.isNaN(dateValue)
                // ) {
                //     document.getElementById("dateDOB").classList.add("error");
                //     document.getElementById("errorHolderLabel").innerHTML =
                //         document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
                //     document.getElementById("errorHolderLabel").style.display = "block";
                //     hasDateError = true;
                // } else 
                if (dateValue > maxDateValue) {
                    document.getElementById("dateDOB").classList.add("error");
                    document.getElementById("errorHolderLabel").innerHTML =
                        document.getElementById("errorHolderLabel").getAttribute("data-validatormsg2").valueOf();
                    document.getElementById("errorHolderLabel").style.display = "block";
                    hasDateError = true;
                } else if (dateValue <= maxDateValue) {
                    document.getElementById("dateDOB").classList.remove("error");
                    hasDateError = false;
                    if (!hasMonthError) {
                        document.getElementById("errorHolderLabel").innerHTML = "";
                    }
                }
            }

            var collector = document.getElementById("dateDOB").value;
            if (collector.length != 2) {
                if (collector.length == 1) {
                    collector = "0" + collector;
                }
            }
            document.getElementById("dateDOB").value = collector;

        });
    }

    if (document.getElementById("monthDOB")) {
        document.getElementById("monthDOB").addEventListener("blur", function () {
            if (document.getElementById("monthDOB").validity.valid == false) {
                document.getElementById("monthDOB").classList.add("error");

                if (document.getElementById("monthDOB").value == "") {
                    // document.getElementById("errorHolderLabel").innerHTML =
                    //     document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
                }
                else {
                    document.getElementById("errorHolderLabel").innerHTML =
                        document.getElementById("errorHolderLabel").getAttribute("data-validatormsg2").valueOf();
                }
                document.getElementById("errorHolderLabel").style.display = "block";
                hasMonthError = true;
            } else {
                var monthValue = parseInt(document.getElementById("monthDOB").value);
                var maxMonthValue = document.getElementById("monthDOB").getAttribute("max");
                // if (
                //     monthValue === "" ||
                //     monthValue === undefined ||
                //     monthValue === null ||
                //     Number.isNaN(monthValue)
                // ) {
                //     document.getElementById("monthDOB").classList.add("error");
                //     document.getElementById("errorHolderLabel").innerHTML =
                //         document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
                //     document.getElementById("errorHolderLabel").style.display = "block";
                //     hasMonthError = true;
                // } else
                if (monthValue > maxMonthValue) {
                    document.getElementById("monthDOB").classList.add("error");
                    document.getElementById("errorHolderLabel").innerHTML =
                        document.getElementById("errorHolderLabel").getAttribute("data-validatormsg2").valueOf();
                    document.getElementById("errorHolderLabel").style.display = "block";
                    hasMonthError = true;
                } else if (monthValue <= maxMonthValue) {
                    document.getElementById("monthDOB").classList.remove("error");
                    hasMonthError = false;
                    if (!hasDateError) {
                        document.getElementById("errorHolderLabel").innerHTML = "";
                    }
                }
            }

            var collector = document.getElementById("monthDOB").value;
            if (collector.length != 2) {
                if (collector.length == 1) {
                    collector = "0" + collector;
                }
            }
            document.getElementById("monthDOB").value = collector;

        });
    }

    if (document.getElementById("yearDOB")) {
        document.getElementById("yearDOB").addEventListener("blur", function () {
            if (document.getElementById("yearDOB").validity.valid == false) {
                document.getElementById("yearDOB").classList.add("error");

                if (document.getElementById("yearDOB").value == "") {
                    // document.getElementById("errorHolderLabel").innerHTML =
                    //     document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
                }
                else {
                    document.getElementById("errorHolderLabel").innerHTML =
                        document.getElementById("errorHolderLabel").getAttribute("data-validatormsg2").valueOf();
                }
                document.getElementById("errorHolderLabel").style.display = "block";
            } else {
                var yearValue = document.getElementById("yearDOB").value;

                var minimumAgeValue =
                    document.getElementById("yearDOB").getAttribute("max") - sixteen;
                // if (yearValue === "" || yearValue === undefined || yearValue === null) {
                //     document.getElementById("yearDOB").classList.add("error");
                //     document.getElementById("errorHolderLabel").innerHTML =
                //         document.getElementById("errorHolderLabel").getAttribute("data-validatormsg1").valueOf();
                //     document.getElementById("errorHolderLabel").style.display = "block";
                // } else
                if (yearValue > minimumAgeValue) {
                    document.getElementById("yearDOB").classList.add("error");
                    document.getElementById("errorHolderLabel").innerHTML =
                        document.getElementById("errorHolderLabel").getAttribute("data-validatormsg3").valueOf();
                    document.getElementById("errorHolderLabel").style.display = "block";
                } else if (yearValue <= minimumAgeValue) {
                    if (!hasDateError && !hasMonthError) {
                        document.getElementById("yearDOB").classList.remove("error");
                        document.getElementById("errorHolderLabel").innerHTML = "";
                    } else {
                        document.getElementById("yearDOB").classList.add("error");
                    }
                }
            }
        });
    }

    // Keyup Events
    if (document.getElementById("dateDOB")) {
        document.getElementById("dateDOB").addEventListener("keyup", function (e) {
            if (e.currentTarget.value.length == e.currentTarget.maxLength) {
                var i = parseInt(e.currentTarget.getAttribute("tabindex"));
                i = i + 1;
                if (
                    document
                        .querySelectorAll('[tabindex="' + i + '"]')[0]
                        .getAttribute("id") == "monthDOB" ||
                    document
                        .querySelectorAll('[tabindex="' + i + '"]')[0]
                        .getAttribute("id") == "yearDOB"
                ) {
                    if (
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].value
                            .length ==
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].maxLength
                    ) {
                        i = i + 1;
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                    } else document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                } else {
                    document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                }
            }
        });
    }

    if (document.getElementById("monthDOB")) {
        document.getElementById("monthDOB").addEventListener("keyup", function (e) {
            if (e.currentTarget.value.length == e.currentTarget.maxLength) {
                var i = parseInt(e.currentTarget.getAttribute("tabindex"));
                i = i + 1;

                if (
                    (document.querySelectorAll('[tabindex="' + i + '"]')[0] &&
                        document
                            .querySelectorAll('[tabindex="' + i + '"]')[0]
                            .getAttribute("id") == "dateDOB") ||
                    document
                        .querySelectorAll('[tabindex="' + i + '"]')[0]
                        .getAttribute("id") == "yearDOB"
                ) {
                    if (
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].value
                            .length ==
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].maxLength
                    ) {
                        i = i + 1;
                        if (document.querySelectorAll('[tabindex="' + i + '"]')[0])
                            document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                    } else document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                } else {
                    document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                }
            }
        });
    }

    if (document.getElementById("yearDOB")) {
        document.getElementById("yearDOB").addEventListener("keyup", function (e) {
            if (e.currentTarget.value.length == e.currentTarget.maxLength) {
                var i = parseInt(e.currentTarget.getAttribute("tabindex"));
                i = i + 1;

                if (
                    (document.querySelectorAll('[tabindex="' + i + '"]')[0] &&
                        document
                            .querySelectorAll('[tabindex="' + i + '"]')[0]
                            ?.getAttribute("id") == "monthDOB") ||
                    document
                        .querySelectorAll('[tabindex="' + i + '"]')[0]
                        ?.getAttribute("id") == "dateDOB"
                ) {
                    if (
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].value
                            .length ==
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].maxLength
                    ) {
                        i = i + 1;
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                    } else document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                } else {
                    if (document.querySelectorAll('[tabindex="' + i + '"]')[0])
                        document.querySelectorAll('[tabindex="' + i + '"]')[0].focus();
                }
            }
        });
    }
}

export function FormValidation() {

    document.querySelectorAll('input[type="text"]').forEach((x) => {
        x.classList.remove("error");
    });

    var isValid = true;
    isValid = TitleValidation(isValid);
    var validTitle = isValid;
    isValid = FirstNameValidation(isValid);
    var validFirstName = isValid;
    isValid = LastNameValidation(isValid);
    var validLastName = isValid;
    isValid = ZipCodeValidation(isValid);
    var validZipCode = isValid;
    isValid = BirthdateValidation(isValid);
    var validBirthDate = isValid;
    // isValid = PhoneNumberValidation(isValid);
    // var validPhoneNumber = isValid;

    if (!isValid && elementfocus != null && elementfocus != undefined) {
        var element = document.getElementById(elementfocus);
        var heightHeader = 0;
        element.focus();
        var rect = document
            .querySelector("#" + elementfocus)
            .getBoundingClientRect();
        window.scrollTo({
            top: rect.top + window.scrollY - 30 - heightHeader,
            behavior: "smooth",
        });
    }

    return isValid;
}

export function DeleteVisitorConfirmation() {
    if (
        document.getElementById("popinAccDelcode") !== null &&
        document.getElementById("popinAccDelcode") !== ""
    ) {
        var globalPopinHtml = document.getElementById("popinAccDelcode").innerHTML;
        if (globalPopinHtml.trim() === "") {

            var ajaxUrl = "/api/sitecore/PopinApi/DeleteVisitorPopin?strPopup=DeleteBasicAccount&contextLanguage="
                + baseModel.contextLang;

            axios({
                method: "post",
                url: ajaxUrl,
                async: false,
            })
                .then(function (response) {
                    createRoot(document.getElementById("popinAccDelcode"))
                        .render(<IntroductionInnerPage introductionData={response.data} />);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            var ele = document.getElementById("modalDelAccSuccess");
            if (ele !== null && ele !== undefined) {
                var popinClick = document.getElementById("popinDelAccClick");
                popinClick.click();
            }
        }
    }
    return false;
}

export function DeleteVisitorAccount() {

    const state = store.getState();
    const visitorId = state.user.visitorId;
    const accessToken = state.user.accessToken;
    const refreshToken = state.user.refreshToken;

    axios({
        method: "post",
        url: "/api/sitecore/VisitorApi/VisitorDelete",
        data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken,
        },
        mimeType: "multipart/form-data",
    })
        .then(function (response) {
            if (response.data == true) {
                store.dispatch(doLogout({
                    isLoggedIn: false,
                    accessToken: '',
                    refreshToken: '',
                    visitorId: '',
                    acquisitionPcCode: '',
                    firstName: '',
                    lastName: '',
                    initials: '',
                    email: '',
                    barCode: '',
                    workerCenter: '',
                    birthdate: '',
                    accountCreationDate: '',
                    favCenterLables: '',
                    bookmarkedOffer: '',
                    bookmarkedStore: '',
                    bookmarkedEvent: '',
                    bookmarkedRestaurant: ''
                }));
                erasecookies();
                // set user object to null
                let url = baseModel.siteUrlPrefix ? baseModel.siteUrlPrefix : "/";
                url = url + "?SuccessMessage=Account Deleted";
                window.location.href = url;
                // URWindowLocation(
                //     baseModel.siteUrlPrefix + "?SuccessMessage=Account Deleted"
                // );
            } else if (response.data == false) {
                fnLoadFailurePopin("/api/sitecore/PopinApi/DeleteVisitorPopin?strPopup=DeleteBasicAccountFailure"
                    + "&contextLanguage=" + baseModel.contextLang);
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return false;
}

export function FormAutocompleteLoad(companyInputId, autoCompleteId, hiddenCompaniesId) {
    var lastCheckedString = "";
    var companyNameInput = document.getElementById(companyInputId);
    var autocompleteList = document.getElementById(autoCompleteId);
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (companyNameInput) {
        companyNameInput.addEventListener("focus", function (e) {
            if (!e.currentTarget.value) autocompleteListFull();
        });
    }

    // IE Fix for the 'company name' autocomplete field
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        document.querySelector("body").addEventListener("mousedown", function (e) {
            if (e.target.id == autocompleteList.prop("id"))
                autocompleteList.style.display = "block";
            else autocompleteList.removeAttr("style");
        });
    }

    if (companyNameInput) {
        companyNameInput.addEventListener("keydown", function (e) {
            if (e.keyCode == 9 && autocompleteList.children("li.active").length > 0) {
                e.preventDefault();
                companyNameInput.dispatchEvent(
                    new KeyboardEvent("keydown", { key: "a" })
                );
            }
        });
    }

    if (companyNameInput) {
        companyNameInput.addEventListener("keyup", function (e) {
            e.currentTarget.setAttribute("value", e.currentTarget.value);

            if (
                (e.keyCode == 38 || e.keyCode == 40) &&
                autocompleteList.childNodes.length > 0
            ) {
                var activeLi = autocompleteList.querySelector("li.active");
                if (activeLi !== null) {
                    if (activeLi) activeLi.classList.remove("active");
                    switch (e.keyCode) {
                        case 40: //down arrow
                            if (activeLi.nextSibling)
                                activeLi.nextSibling.classList.add("active");
                            break;
                        case 38: //up arrow
                            if (activeLi.previousSibling)
                                activeLi.previousSibling.classList.add("active");
                            break;
                        default:
                            break;
                    }
                } else {
                    activeLi =
                        e.keyCode == 40
                            ? autocompleteList
                                .querySelector("li:first-child")
                                .classList.add("active")
                            : autocompleteList
                                .querySelector("li:last-child")
                                .classList.add("active");
                    activeLi =
                        e.keyCode == 40
                            ? autocompleteList.querySelector("li:first-child")
                            : autocompleteList.querySelector("li:last-child");
                }
                activeLi = autocompleteList.querySelector("li.active");

                if (autocompleteList.scrollHeight > autocompleteList.clientHeight) {
                    var scrollBottom =
                        autocompleteList.clientHeight + autocompleteList.scrollTop;
                    var elementBottom = activeLi.offsetTop + activeLi.offsetHeight;
                    if (elementBottom > scrollBottom) {
                        autocompleteList.scrollTop =
                            elementBottom - autocompleteList.clientHeight;
                    } else if (activeLi.offsetTop < autocompleteList.scrollTop) {
                        autocompleteList.scrollTop = activeLi.offsetTop;
                    }
                }
            } else if (
                (e.keyCode == 13 || e.keyCode == 9) &&
                autocompleteList.querySelector("li.active")
            ) {
                e.preventDefault();
                autocompleteList
                    .querySelector("li.active")
                    .dispatchEvent(new Event("mousedown"));
                e.currentTarget.blur();
            } else if (e.keyCode != 37 && e.keyCode != 39) {
                var basedOnFullList = true;
                var $this = e.currentTarget; //companyNameInput;
                var stringToCheck = $this.value;

                if (stringToCheck) {
                    if (lastCheckedString) {
                        if (
                            lastCheckedString.length < stringToCheck.length &&
                            stringToCheck.substr(0, lastCheckedString.length).toUpperCase() ==
                            lastCheckedString.toUpperCase()
                        ) {
                            basedOnFullList = false;
                            var listLength = autocompleteList.childNodes.length;
                            autocompleteList.querySelectorAll("li").forEach((e) => {
                                var thisLi = e;
                                var decodedStr = thisLi.innerHTML;
                                if (
                                    decodedStr.substr(0, stringToCheck.length).toUpperCase() !=
                                    stringToCheck.toUpperCase()
                                ) {
                                    thisLi.remove();
                                }
                            });
                        }
                    }
                }

                if (basedOnFullList) autocompleteListFull(stringToCheck);

                lastCheckedString = stringToCheck;
                //document.getElementById("decode-company-name").innerHTML = "";
            }
        });
    }

    function autocompleteListFull(stringToCheck) {
        autocompleteList.innerHTML = "";
        var companies = JSON.parse(document.getElementById(hiddenCompaniesId).value);
        for (var i = 0; i < companies.length; i++) {
            var decodedStr = companies[i].companyName;
            var itemId = companies[i].centerCompanyId;
            if (
                !stringToCheck ||
                decodedStr.substr(0, stringToCheck.length).toUpperCase() ==
                stringToCheck.toUpperCase()
            ) {
                var li = document.createElement("li");
                li.setAttribute("data-companyId", itemId);
                li.appendChild(document.createTextNode(decodedStr));
                li.addEventListener(
                    "mousedown",
                    function (e) {
                        selectCompanyNameMouseDown(e);
                    },
                    false
                );
                autocompleteList.appendChild(li);
            }
        }
    }

    function selectCompanyNameMouseDown(e) {
        var target = e.currentTarget;
        if (target.tagName.toLowerCase() == "li") {
            companyNameInput.value = e.currentTarget.innerHTML;
            companyNameInput.setAttribute("data-company", e.currentTarget.getAttribute("data-companyId").valueOf());
            companyNameInput.dispatchEvent(new KeyboardEvent("keyup", { key: "a" }));
        }
    }
}

export function CompanyNameValidation(elementId, validationFlag) {

    var matchCompanyNameInList = false;
    var input = document.getElementById(elementId);

    if (input) {
        var companyNameInputVal = input.value;
        var parentDiv = input.parentElement;
        var errorSpan = parentDiv.querySelector('span.error');

        if (companyNameInputVal != undefined && companyNameInputVal != null && companyNameInputVal != "") {

            var listCompany = parentDiv.querySelector('input[type="hidden"]');
            var companyNamesArr = JSON.parse(listCompany.value);

            for (var i = 0; i < companyNamesArr.length; i++) {
                if (!matchCompanyNameInList && companyNamesArr[i].companyName == companyNameInputVal) {
                    matchCompanyNameInList = true;
                    break;
                }
            }
        }

        if (!matchCompanyNameInList) {
            errorSpan.style.display = "block";
            errorSpan.innerHTML = companyNameInputVal
                ? errorSpan.getAttribute("data-validatormsg2").valueOf()
                : errorSpan.getAttribute("data-validatormsg1").valueOf();

            input.classList.add("error");

            if (validationFlag) {
                companyelementfocus = input.id;
                validationFlag = false;
            }
        }
        else {
            errorSpan.innerHTML = "";
            input.classList.remove("error");
        }
    }

    return validationFlag;
}

export function scrollToError(flag) {
    if (!flag && companyelementfocus != null && companyelementfocus != undefined && companyelementfocus != "") {
        var element = document.getElementById(companyelementfocus);
        var heightHeader = 0;

        element.focus();
        var rect = document.querySelector("#" + companyelementfocus).getBoundingClientRect();
        window.scrollTo({
            top: rect.top + window.scrollY - 30 - heightHeader,
            behavior: "smooth",
        });
    }
}

export function handleMblBackButton(isMultiCentre) {
    if (isMultiCentre) {
        if (document.getElementById("benefitsServices") && document.getElementById("multipreferredsc")) {
            document.getElementById("multipreferredsc").remove();
            document.getElementById("benefitsServices").classList.remove('d-none');
            if (document.getElementById('lpdiscovermyadvantagesmultisc')) {
                document.getElementById('lpdiscovermyadvantagesmultisc').classList.remove('d-none');
            }
            if (document.getElementById('multisctab'))
                document.getElementById('multisctab').remove();
            if (document.getElementById("smartparkingview"))
                document.getElementById("smartparkingview").remove();
            if (document.getElementById("opinionblock"))
                document.getElementById("opinionblock").remove();
            if (document.getElementById("loyaltydiscovermyadvantages"))
                document.getElementById("loyaltydiscovermyadvantages").remove();
        }
    } else {
        GoBackToMenu("my-benefits-and-services")
    }
}

function fnLoadFailurePopin(deleteAccountUrl) {
    axios({
        method: "post",
        url: deleteAccountUrl,
        async: false,
    })
        .then(function (response) {
            {
                createRoot(document.getElementById("globalPopin"))
                    .render(<IntroductionInnerPage introductionData={response.data} />);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function updatePartnerDetail(pcCode, parkingPartner, accountUrl) {
    const state = store.getState();
    const visitorId = state.user.visitorId;
    const accessToken = state.user.accessToken;
    const refreshToken = state.user.refreshToken;
    let parkingPartners = state.user.parkingPartners;
    let smartParkerCenters = state.user.smartParkerCenters;

    if (parkingPartner !== null && parkingPartner !== undefined && parkingPartner !== "") {
        axios({
            method: "post",
            url: "/api/sitecore/VisitorApi/CreateVisitorParkingPartner",//?pcCode=" + pcCode + "&parkingPartner=" + parkingPartner,
            data: {
                pcCode: pcCode,
                parkingPartner: parkingPartner,
                visitorId: visitorId,
                accessToken: accessToken,
                refreshToken: refreshToken,
            },
            async: false,
        })
            .then(function (response) {
                if (response.data != null && response.data != "") {
                    if (!parkingPartners.includes(parkingPartner))
                        parkingPartners = parkingPartners + ',' + parkingPartner;
                    store.dispatch(setParkingPartners(parkingPartners));

                    axios({
                        method: "post",
                        url: "/api/sitecore/VisitorApi/VisitorCenterParkingStatusUpdate",// ?pcCode=" + pcCode +"&parkingStatus=" + true,
                        data: {
                            pcCode: pcCode,
                            parkingStatus: true,
                            visitorId: visitorId,
                            accessToken: accessToken,
                            refreshToken: refreshToken,
                        },
                        async: false,
                    })
                        .then(function (response) {
                            if (response != null && response.data) {
                                if (!smartParkerCenters.includes(pcCode))
                                    smartParkerCenters = smartParkerCenters + ',' + pcCode;
                                store.dispatch(setSmartParkerCenters(smartParkerCenters));
                                if (accountUrl !== null && accountUrl !== "" && accountUrl !== undefined)
                                    fnRedirectToMyAccount(accountUrl);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    else {
        axios({
            method: "post",
            url: "/api/sitecore/VisitorApi/VisitorCenterParkingStatusUpdate",//?pcCode=" + pcCode +"&parkingStatus=" + true,
            data: {
                pcCode: pcCode,
                parkingStatus: true,
                visitorId: visitorId,
                accessToken: accessToken,
                refreshToken: refreshToken,
            },
            async: false,
        })
            .then(function (response) {
                if (response != null && response.data) {
                    if (!smartParkerCenters.includes(pcCode))
                        smartParkerCenters = smartParkerCenters + ',' + pcCode;
                    store.dispatch(setSmartParkerCenters(smartParkerCenters));
                    if (accountUrl !== null && accountUrl !== "" && accountUrl !== undefined)
                        fnRedirectToMyAccount(accountUrl);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

}

export function addVehicleClick(data, item, addvehicle, editvehicle, addVehicleData, removeVehicleData, isParkingCall) {
    if (addvehicle)
        fnGTMAddVehicle();
    createRoot(document.getElementById("popinAddVehicle"))
        .render(<AddVisitorVehiclePopin fields={data} model={item} addvehicle={addvehicle} editvehicle={editvehicle} addVehicleData={addVehicleData} removeVehicleData={removeVehicleData} isParkingCall={isParkingCall} />);
}


