import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { checkWebViewEqualsOne, createCookie } from '../Js-CommonFunctions';

export default function AnnouncementComponent({ fields }) {

  function fnAddAnnouncementCookie(name, days) {
    createCookie(name, "true", days);
    var element = document.getElementById("NoticeBlock");
    element.classList.add("d-none");
    element.setAttribute("data-bannerCookie", true)
    document.body.classList.remove("emergency-notice-show")
  }

  var hasWebviewEqualsOne = checkWebViewEqualsOne();

  if (fields !== undefined && fields.announcement !== null && fields.announcement !== "" && fields.announcement !== undefined && !hasWebviewEqualsOne) {
    var detail = { value: fields.announcement.detail };
    var name = { value: fields.announcement.name };
    return (
      <div>
        <div id="NoticeBlock" className={"emergency-notice bg-gray"}>
          <div className="container notice-block text-center">
            {/* {(fields.announcement.image) &&
              <div className="img-wrap">
                <img
                  src={fields.announcement.image}
                  alt={fields.announcement.name}
                />
              </div>
            } */}
            <div className="d-flex justify-content-between align-items-center">
              <div className="title d-flex text-start justify-content-start mt-2">
                <Text field={name} />
              </div>
              <span className="picto icon-cross text-black ps-3" role="button" data-testid="testClick" onClick={() => fnAddAnnouncementCookie(fields.announcement.cookieName, fields.announcement.cookieExpiry)}></span>
            </div>
            <div className="description d-flex text-start justify-content-start mt-2">
              <RichText field={detail} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (<></>);
  }
}
