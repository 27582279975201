import React, { useEffect } from 'react';
import { fnClickNavigation, fnUpdatePageNameInDataAttr } from '../Js-CommonFunctions';

export default function HomePageCinemaInner({ model }) {
  useEffect(() => {

    var numberOfCinemaBlocks = document.querySelectorAll(

      "#homePageCinemaInner .movie-block"

    ).length;

    if (numberOfCinemaBlocks > 0) {

      if (document.querySelectorAll("#homePageCinema")[0] !== undefined)

        document.querySelectorAll("#homePageCinema")[0].style.display =

          "block";

    }
  }, [])
  if (model != null && model.filmCarousel != null && model.filmCarousel.length > 0) {
    var iCounter = 0;
    return (
      <>
        {model.filmCarousel.map((movieDetail, index) => {
          iCounter++;
          return (
            (movieDetail.film != null) &&
            <div key={index} className="col text-start p-0 mt-2">
              <div className="movie-block position-relative">
                <> <a href={model.urlPrefix + movieDetail.strMoviePageUrl} aria-label={model.urlPrefix} className={"img-wrap d-flex movie-img-new  justify-content-center align-items-center  mb-2" + (model.isNewBrandedSite ? " " : " " + " gtmClickNavigation")} onClick={(e) => gtmHomePgeCinema(e)}>
                  {(movieDetail.film?.imageposter !== "" && movieDetail.film?.imageposter !== null) ?
                    (
                      <img className=" movie-img-new" src={model.defaultImage} data-alt={movieDetail.film.imageposter} alt={movieDetail.film.filmTitle} title={movieDetail.film.filmTitle} />

                    ) :
                    (
                      <span className="picto icon-srv-cinema align-middle"></span>
                    )
                  }
                </a>
                </>
                <div className={"movie-title-new text-capitalize" + (model.isNewBrandedSite ? " " : "")}>
                  <a href={model.urlPrefix + movieDetail.strMoviePageUrl} aria-label={model.urlPrefix} className="d-block gtmClickNavigation" onClick={(e) => gtmHomePgeCinema(e)}>{movieDetail.film.filmTitle}</a>
                </div>
                {(movieDetail.filmEvent != null) &&
                  // <div className={"movie-version" + (model.isNewBrandedSite ? " fw-bold" : "")}>
                    <a href={model.urlPrefix + movieDetail.strMoviePageUrl} aria-label={model.urlPrefix} className={"d-block movie-version-new language" + (model.isNewBrandedSite ? " " : "") + " gtmClickNavigation"} onClick={(e) => gtmHomePgeCinema(e)}>
                      {movieDetail.filmEvent.filmEventVersion}
                    </a>
                  //</div>
                }
              </div>
            </div>
          );
        }
        )}
        <input type="hidden" id="hdnMoviesCount" value={model.totalMovieCount} />
      </>

    )
  } else {
    return <></>;
  }
}


function gtmHomePgeCinema(e) {
  fnUpdatePageNameInDataAttr(e.currentTarget, 'gtmclickposition');
  fnClickNavigation(e.currentTarget);
}