import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function TitleDiscover({ fields }) {
  if (fields) {
    return (
      <div className="container">
        <h1 className="page-title text-uppercase col-12">
          {(fields["Title"] !== null && fields["Title"].value !== "" && fields["Title"].value !== null) &&
            <span><RichText field={fields["Title"]} /></span>
          }
        </h1>
      </div>
    )
  } else {
    return <></>;
  }
}

