import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Slider from "react-slick";

export default function PhotoGallery({ fields }) {
  if (fields.photoGalleryModelObj !== null && fields.photoGalleryModelObj !== undefined && fields.photoGalleryModelObj != "") {
    let index = 0;
    var settings = {
      customPaging: function (i) {
        return (
          <button type="button" className="bg-main" data-role="none" role="button" tabIndex="0" />
        );
      },
      arrows: true,
      dots: true,
      infinite: fields.photoGalleryModelObj.count > 3 ? true : false,
      slidesToShow: 3,
      slidesToScroll: 3,
      slidesToShowTablet: 2,
      slidesToShowMobile: 1,

      responsive: [
        {
          breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          //tab
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },

        {
          breakpoint: 480,
          //mobile
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

    };

    return (
      <section className="all-pictures" id="pictures">
        <div className="container">
          {(fields.json1 !== null && (fields.json1["Type"] !== null)) &&

            < h2 className={(fields.photoGalleryModelObj.isNewBrandedSite ? " page-title" : "content-title") + " text-uppercase" + (fields.photoGalleryModelObj.isNewBrandedSite ? "" : " text-center underline")}><RichText field={fields.json1["Type"]} /></h2>
          }
          <div className="link-dark">
            {(!fields.photoGalleryModelObj.isNewBrandedSite && fields.json3 !== null && (fields.json3["Title"] !== null && fields.json3["Title"] !== undefined) && fields.json3["Title"].value !== null && fields.json3["Title"].value !== "") &&

              <h3 className="sub-title text-uppercase"><RichText field={fields.json3["Title"].value} /></h3>

            }

            <Slider {...settings} className={"carousel " + (fields.photoGalleryModelObj.isNewBrandedSite ? "carouselDiv" : "carouselDiv ") + fields.photoGalleryModelObj.scModeStyle}>

              {
                fields.photoGalleryModelObj.image.map((item, index) => {
                  index = index + 1;
                  return (
                    (item.image !== null && item.image.value !== null && item.image?.value?.src !== null && item.image?.value?.src !== "") &&
                    <img src={item.image.value.src} alt={item.image.value} className="gtmCarouselView" id={"photoGallery" + index} />
                  )
                })
              }
            </Slider >
            <div className="action">
              {(fields.json2 !== null && fields.json2["Type"] !== null) &&

                <a className="link-button btn-dark-transparent" href="#">{fields.json2["Type"].value} </a>
              }
            </div>
          </div>
        </div>
      </section >
    )
  } else {
    return <></>;
  }
}
