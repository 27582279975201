import React from 'react';
import { baseModel } from '../../RouteHandler';

export default function ServicesFilterInner({ model }) {
  let serviceCounter = 0;

  if (model != null && model.servicesList != null && model.servicesList.length > 0) {
    return (
      <div className={(model.display_Picture_For_Services ? "all-services home-services " : "search-wrapper px-lg-2")}>
        <div className={model.display_Picture_For_Services ? "d-flex" : "categories d-flex flex-row "}>
          {model.servicesList.map((item, index) => {
            serviceCounter++;
            return (
              (model.display_Picture_For_Services) ?
                (
                  (item.image != "" && item.image !== null) ?
                    (
                      <div key={index} className="card rounded-0 border-0 img-block bg-transparent service-block">
                        <div className="img-wrap bg-transparent position-relative">
                          <img src={item.image} className="card-img-top" alt={item.serviceData?.shortTitle} />
                          {model.isNewBrandedSite ?
                            (item.hasElligibility && baseModel.westfieldClubLogo?.value !== null && baseModel.westfieldClubLogo?.value !== "" && baseModel.westfieldClubLogo?.value.src !== null && baseModel.westfieldClubLogo?.value?.src !== "" &&
                              <div className="service-picto-container d-flex align-items-center">
                                <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                                  alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                              </div>
                            ) : (item.hasElligibility && baseModel.clubLogo?.value !== null && baseModel.clubLogo?.value !== "" && baseModel.clubLogo?.value.src !== null && baseModel.clubLogo?.value?.src !== "" &&
                              <div className="service-picto-container d-flex align-items-center">
                                <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" src={baseModel.clubLogo?.value?.src} loading="lazy"
                                  alt={baseModel.clubLogo?.value?.alt} title="club" />
                              </div>
                            )
                          }
                        </div>
                        <div className="card-body d-flex flew-row align-items-center px-0">
                          <span className="picto icon-tag-rw align-middle px-0 fs-5"></span>
                          <a href={item.serviceUrl}
                            aria-label={item?.serviceData?.shortTitle}
                            className="d-flex align-items-center justify-content-center stretched-link gtmClickNavigation">
                            <div className="title">{item.serviceData?.shortTitle}</div>
                          </a>
                        </div>
                      </div>
                    ) :
                    (
                      <div key={index} className="card rounded-0 border-0 img-block bg-transparent service-block">
                        <div className="img-wrap position-relative">
                          {/* <img src={item.image} className="card-img-top rounded-0" alt={item.serviceData?.shortTitle} /> */}
                          {/* <div className="service-picto-container">
                        <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                          alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                      </div> */}
                        </div>
                        <div className="card-body d-flex flew-row align-items-center px-0">
                          <span className="picto icon-tag-rw align-middle px-0 fs-5"></span>
                          <a href={item.serviceUrl}
                            aria-label={item?.serviceData?.shortTitle}
                            className="d-flex align-items-center stretched-link pt-2 gtmClickNavigation">
                            <div className="title">{item.serviceData?.shortTitle}</div>
                          </a>
                        </div>
                      </div>
                    )
                ) :
                (
                  <div key={index} className="search-col mb-1 pe-1 pe-lg-2">
                    <div className='block store-wrap with-logo'>
                      <div className='store-img'>
                        <span className={"picto " + (item.serviceData?.pictoOn != "" ? item.serviceData?.pictoOn : "icon-servicesaspictosservices") + " border-8"}></span>
                        <a href={item.serviceUrl}
                          aria-label={item?.serviceData?.shortTitle}
                          className="d-flex store-name text-start stretched-link gtmClickNavigation">
                          {item.serviceData?.shortTitle}
                        </a>
                      </div>
                    </div>
                  </div>
                )
            )
          })}
          <input type="hidden" id="hdnServiceCount" value={model.totalServicesCount} />
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}
