import React, { useEffect } from 'react';
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { fnBackButtonClickFromMultiSC } from '../Js-CommonFunctions';
import { handleMblBackButton } from "../MyAccount/MyAccountScripts";
import ServicesAndExperiences from '../ServicesAndExperiences';

export default function MyAccountBasicBenefits({ item, fields, isMultiCentre, displayOnlyBasicBenefits, isWestfield }) {

  useEffect(() => {
    let contentEle = document.getElementById("accountMain");
    if (contentEle !== null && contentEle !== undefined && contentEle !== "" &&
      contentEle.classList !== null && contentEle.classList !== undefined && contentEle.classList !== "") {

      if (isWestfield) {
        if ((contentEle.classList.contains("account-brandedpage-loyalty"))) {
          contentEle.classList.remove("account-brandedpage-loyalty");
        }
        contentEle.classList.add("account-unbrandedpage-loyalty")
      }

      else {
        if ((contentEle.classList.contains("account-unbrandedpage-loyalty"))) {
          contentEle.classList.remove("account-unbrandedpage-loyalty");
        }
        contentEle.classList.add("account-brandedpage-loyalty")
      }
    }

  }, []);

  if (fields !== undefined && fields !== null && fields !== "") {

    return (
      <>
        <div className="account-tab-pane1 rounded-0 bg-white pb-lg-1 fade in active show" id="myaccountbasicbenefits">
          <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
            {displayOnlyBasicBenefits &&
              <div className="container modalContainer p-0 ">
                <nav className="benefits-header-height px-lg-1 row m-0 py-2">
                  <div className="col-2 text-center px-0">
                    <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                      href="javascript:void(0);" data-testid='handleMblBackbtn-id' onClick={() => { handleMblBackButton(isMultiCentre) }}></a>
                  </div>
                  <div className="col-8 p-0 text-center">
                    <div className=" text-center  d-none benefits-title ">
                      {fields.benefitsAndServicesTitle}
                    </div>
                    <div className=" text-center benefits-subtitle ">
                      {item.centerLabel}
                    </div>
                  </div>
                  <div className="col-2 text-center px-0">
                  </div>
                </nav>
              </div>
            }
          </div>
          <div className="mob-pd my-3 row ps-lg-1 text-black">
            <div
              className={"align-items-center go-back-text d-lg-block d-none d-xl-block col-md-3 d-flex " + (isMultiCentre ? "" : " invisible")} onClick={() => { fnBackButtonClickFromMultiSC() }} data-testid="fnBackButtonClickFromMultiSCTestClick">
              <span
                className="account-icon-spacing icon-left-arrow multicentre-icon-left-arrow picto"></span>
              <span className="align-items-center ms-2 multicentre-card-header">{fields.goBack}</span>
            </div>
            <div
              className="col-md-6 d-flex d-lg-block d-none d-xl-block justify-content-center multicentre-center-name text-black ">
              <div
                className="d-flex justify-content-center account-center-name">
                {item.centerLabel}</div>           
            </div>
          </div>
         

          <div className="row mob-pd equal mx-0">
            {item.loyaltyBenefits !== undefined && item.loyaltyBenefits !== null && item.loyaltyBenefits !== "" && 
              <ServicesAndExperiences fields={item.loyaltyBenefits} isWestfield={isWestfield} isAccountPage={true} />
            }

            {(item.loyaltyBenefits !== undefined && item.loyaltyBenefits !== null && item.loyaltyBenefits !== "" && item.loyaltyBenefits.loyaltyPillar !== undefined && item.loyaltyBenefits.loyaltyPillar !== null && item.loyaltyBenefits.loyaltyPillar !== "") ?
              (<>
                <div className={"col-sm-6 p-1 d-flex" + (((item.loyaltyBenefits.loyaltyPillar.offerPillarDescription === null || item.loyaltyBenefits.loyaltyPillar.offerPillarDescription === "") &&
                  (item.loyaltyBenefits.loyaltyPillar.offerPicture === null || item.loyaltyBenefits.loyaltyPillar.offerPicture === "")) ? " d-none" : "")}>
                  <div
                    className="card card-inverse w-100 account-box-shadow border-0 text-decoration-none">
                    <div className="card-block p-3">
                      <div className="d-flex justify-content-center" >
                        {(item.loyaltyBenefits.loyaltyPillar.offerPicture != null && item.loyaltyBenefits.loyaltyPillar.offerPicture != undefined && item.loyaltyBenefits.loyaltyPillar.offerPicture !== "") ?
                          ((item.loyaltyBenefits.loyaltyPillar.offerPicture != null && item.loyaltyBenefits.loyaltyPillar.offerPicture != undefined && item.loyaltyBenefits.loyaltyPillar.offerPicture !== "") ?
                            (<img src={item.loyaltyBenefits.loyaltyPillar.offerPicture}
                              className="account-basic-icon img-fluid" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />)
                            : (<img src="#" className="account-basic-icon img-fluid d-none" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />))
                          : (<div
                            className="account-basic-icon text-black multicentre-basic-icon icon-servicesaspictosoffers img-fluid">
                          </div>)}
                      </div>
                      <div className="multicentre-card-title text-center ">{(item.loyaltyBenefits.loyaltyPillar.offerTitle != null && item.loyaltyBenefits.loyaltyPillar.offerTitle != undefined) ? item.loyaltyBenefits.loyaltyPillar.offerTitle : ""}
                      </div>
                      <p className="card-text mt-2">{(item.loyaltyBenefits.loyaltyPillar.offerPillarDescription != null && item.loyaltyBenefits.loyaltyPillar.offerPillarDescription != undefined) ? item.loyaltyBenefits.loyaltyPillar.offerPillarDescription : ""}
                      </p>

                    </div>
                  </div>
                </div>
                <div className={"col-sm-6 p-1 d-flex" + (((item.loyaltyBenefits.loyaltyPillar.servicePillarDescription === null || item.loyaltyBenefits.loyaltyPillar.servicePillarDescription === "") &&
                  (item.loyaltyBenefits.loyaltyPillar.servicePicture === null || item.loyaltyBenefits.loyaltyPillar.servicePicture === "")) ? " d-none" : "")}>
                  <div
                    className="card card-inverse w-100 account-box-shadow  border-0 text-decoration-none">
                    <div className="card-block p-3">
                      <div className="d-flex justify-content-center">
                        {(item.loyaltyBenefits.loyaltyPillar.servicePicture != null && item.loyaltyBenefits.loyaltyPillar.servicePicture != undefined && item.loyaltyBenefits.loyaltyPillar.servicePicture !== "")
                          || (item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg != undefined && item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg != null && item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg != "")
                          ?
                          ((item.loyaltyBenefits.loyaltyPillar.servicePicture != null && item.loyaltyBenefits.loyaltyPillar.servicePicture != undefined && item.loyaltyBenefits.loyaltyPillar.servicePicture !== "") ?
                            ((<img src={item.loyaltyBenefits.loyaltyPillar.servicePicture} className="account-basic-icon  img-fluid" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />))
                            :
                            (<img src="#" className="account-basic-icon  img-fluid d-none" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />))
                          : ((item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg != undefined && item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg != null && item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg != "" &&
                            item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg.value.src != undefined && item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg.value.src != null && item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg.value.src != "") ?
                            (<img src={item.loyaltyBenefits.loyaltyPillar.pillarservice1pictoImg.value.src} alt={item.loyaltyBenefits.loyaltyPillar.offerTitle}
                              className="account-basic-icon img-fluid" />)
                            : (<div
                              className="account-basic-icon text-black multicentre-basic-icon icon-srv-kids-area img-fluid">
                            </div>))
                        }
                      </div>
                      <div className="multicentre-card-title text-center ">{(item.loyaltyBenefits.loyaltyPillar.serviceTitle != null && item.loyaltyBenefits.loyaltyPillar.serviceTitle != undefined) ? item.loyaltyBenefits.loyaltyPillar.serviceTitle : ""}
                      </div>
                      <p className="card-text mt-2">{(item.loyaltyBenefits.loyaltyPillar.servicePillarDescription != null && item.loyaltyBenefits.loyaltyPillar.servicePillarDescription != undefined) ? item.loyaltyBenefits.loyaltyPillar.servicePillarDescription : ""}
                      </p>

                    </div>
                  </div>
                </div>
                <div className={"col-sm-6 p-1 d-flex" + (((item.loyaltyBenefits.loyaltyPillar.giftsPillarDescription === null || item.loyaltyBenefits.loyaltyPillar.giftsPillarDescription === "")
                  && (item.loyaltyBenefits.loyaltyPillar.giftsPicture === null || item.loyaltyBenefits.loyaltyPillar.giftsPicture === "")) ? " d-none" : "")}>
                  <div
                    className="card card-inverse w-100 account-box-shadow  border-0 text-decoration-none">
                    <div className="card-block p-3">
                      <div className="d-flex justify-content-center">
                        {(item.loyaltyBenefits.loyaltyPillar.giftsPicture != undefined && item.loyaltyBenefits.loyaltyPillar.giftsPicture != null && item.loyaltyBenefits.loyaltyPillar.giftsPicture !== "") ||
                          (item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg != undefined && item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg != null && item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg != "") ?
                          ((item.loyaltyBenefits.loyaltyPillar.giftsPicture != undefined && item.loyaltyBenefits.loyaltyPillar.giftsPicture != null && item.loyaltyBenefits.loyaltyPillar.giftsPicture !== "") ?
                            (<img src={item.loyaltyBenefits.loyaltyPillar.giftsPicture} className="account-basic-icon img-fluid" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />)
                            : (<img src="#" className="account-basic-icon img-fluid d-none" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />))
                          : ((item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg != undefined && item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg != null && item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg != "" &&
                            item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg.value.src != undefined && item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg.value.src != null && item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg.value.src != "") ?
                            (<img src={item.loyaltyBenefits.loyaltyPillar.pillargiftspictoImg.value.src} alt={item.loyaltyBenefits.loyaltyPillar.offerTitle}
                              className="account-basic-icon img-fluid" />)
                            : (<div
                              className="account-basic-icon text-black multicentre-basic-icon icon-pictoservices img-fluid">
                            </div>))}
                      </div>
                      <div className="multicentre-card-title text-center ">{(item.loyaltyBenefits.loyaltyPillar.giftsTitle != undefined && item.loyaltyBenefits.loyaltyPillar.giftsTitle != null) ? item.loyaltyBenefits.loyaltyPillar.giftsTitle : ""}
                      </div>
                      <p className="card-text mt-2">{(item.loyaltyBenefits.loyaltyPillar.giftsPillarDescription != undefined && item.loyaltyBenefits.loyaltyPillar.giftsPillarDescription != null) ? item.loyaltyBenefits.loyaltyPillar.giftsPillarDescription : ""}
                      </p>

                    </div>
                  </div>
                </div>
                <div className={"col-sm-6 p-1 d-flex" + (((item.loyaltyBenefits.loyaltyPillar.eventPillarDescription === null || item.loyaltyBenefits.loyaltyPillar.eventPillarDescription === "") &&
                  (item.loyaltyBenefits.loyaltyPillar.eventPicture === null || item.loyaltyBenefits.loyaltyPillar.eventPicture === "")) ? " d-none" : "")}>
                  <div
                    className="card card-inverse w-100 account-box-shadow  border-0 text-decoration-none">
                    <div className="card-block p-3">
                      <div className="d-flex justify-content-center">
                        {(item.loyaltyBenefits.loyaltyPillar.eventPicture != undefined && item.loyaltyBenefits.loyaltyPillar.eventPicture != null && item.loyaltyBenefits.loyaltyPillar.eventPicture !== "") ?
                          ((item.loyaltyBenefits.loyaltyPillar.eventPicture != undefined && item.loyaltyBenefits.loyaltyPillar.eventPicture != null && item.loyaltyBenefits.loyaltyPillar.eventPicture !== "") ?
                            ((<img src={item.loyaltyBenefits.loyaltyPillar.eventPicture} alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} className="account-basic-icon img-fluid" />)) :
                            (<img src="#" className="account-basic-icon img-fluid d-none" alt={item.loyaltyBenefits.loyaltyPillar.offerTitle} />))
                          : (<div
                            className="account-basic-icon text-black multicentre-basic-icon icon-servicesaspictosevent img-fluid">
                          </div>)}
                      </div>
                      <div className="multicentre-card-title text-center ">
                        {(item.loyaltyBenefits.loyaltyPillar.eventTitle != undefined && item.loyaltyBenefits.loyaltyPillar.eventTitle != null) ? item.loyaltyBenefits.loyaltyPillar.eventTitle : ""}</div>
                      <p className="card-text mt-2">{(item.loyaltyBenefits.loyaltyPillar.eventPillarDescription != undefined && item.loyaltyBenefits.loyaltyPillar.eventPillarDescription != null) ? item.loyaltyBenefits.loyaltyPillar.eventPillarDescription : ""}
                      </p>

                    </div>
                  </div>
                </div></>
              ) :
              (<></>)}

            

          </div>
          {(item.loyaltyBenefits !== undefined && item.loyaltyBenefits !== null && item.loyaltyBenefits !== "") &&
            <div className="centre-alma-discover-button">
              <a href={item.loyaltyBenefits.discoverAdvantagesLink} aria-label={item?.loyaltyBenefits?.discoverAdvantagesLink} className="d-flex justify-content-center  account-discover-button">{fields.discoverAdvantages}
              </a>
            </div>
          }
        </div>


      </>
    );

  }
  else {
    return <></>;
  }

}

