import loadable from "@loadable/component";
import React from "react";
const IntroductionInnerPage = loadable(() => import("../IntroductionInnerPage"));

export default function Introduction({ fields }) {

  if (fields.introduction != null) {
    return (
      <IntroductionInnerPage introductionData={fields.introduction} />
    )
  } else {
    return <></>;
  }

}
