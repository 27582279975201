import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from "react";
import { GetRenewedAccessToken, fnInputCheckBoxClick, getCookie, isTokenExpired, lazyLoadingImg } from '../Js-CommonFunctions';
import { DeleteVisitorAccount } from '../MyAccount/MyAccountScripts';

export default function IntroductionInnerPage({ introductionData }) {

  const [model, setModel] = useState(introductionData);

  const [id, setId] = useState(null);

  useEffect(() => {
    setId(getCookie("UUID"));
    var ele = document.getElementById("modalAccSuccess");
    var delAcc = document.getElementById("modalDelAccSuccess");
    if (delAcc !== null && delAcc !== undefined && model !== null && model.visitor_Deletion_Popin) {
      var popinClick = document.getElementById("popinDelAccClick");
      popinClick.click();
    }
    else if (ele !== null && ele !== undefined) {
      var popinClick = document.getElementById("popinClick");
      popinClick.click();
    }

    fnInputCheckBoxClick();
    lazyLoadingImg();

  }, []);

  if (model != null) {
    return (
      <>
        {(model.introductionItem != null && model.introductionItem.content_Title != null && model.introductionItem.content_Title != "") &&
          IntroductionCondition(model, id)
        }
      </>
    );

  } else {
    return <></>;
  }

  function IntroductionCondition(model, id) {
    if (model.cinema_Page || model.pageEditor_Cinema) {
      return (

        (model.introductionItem.content_Title.value !== "" && model.introductionItem.content_Title.value !== null || model.introductionItem.content_Introduction.value !== "" || model.introductionItem.content_Link_URL.value.url !== null && model.introductionItem.content_Link_URL.value.url !== "") &&
        <section className="welcome" id="theatre">
          <div className="container">
            {(model.introductionItem.content_Title.value !== "" && model.introductionItem.content_Title.value !== null) &&
              <Text field={model.introductionItem.content_Title} className="text-uppercase" tag="h2" />
            }
            <div><RichText field={model.introductionItem.content_Introduction} /></div>
            <div className="action">
              <a href={model.introductionItem.content_Link_URL.value.url} aria-label={model?.introductionItem?.content_Link_URL?.value} className="link-button  btn-lighter" target={model.introductionItem.content_Link_URL.value.target}>
                {model.introductionItem.content_Link_URL != null &&
                  <Text field={model.introductionItem.content_Link_Text} />
                }
              </a>
            </div>
          </div>
        </section>
      )
    }
    else if ((model.shop_Landing || model.pageEditor_ShopLanding) || (model.restaurant_Landing || model.pageEditor_RestaurantLanding)) {

      return (
        (((id === null || id === "" || id === undefined)) && ((model.content_Heading.value !== "" && model.content_Heading.value !== null) || (model.content_Pictogtram.value !== "" && model.content_Pictogtram.value !== null) || (model.introductionItem.content_Title.value !== "" && model.introductionItem.content_Title.value !== null) || (model.introductionItem.content_Introduction.value !== "" && model.introductionItem.content_Introduction.value !== null) || (model.introductionItem.content_Link_URL.value.url !== null && model.introductionItem.content_Link_URL.value.url !== ""))) &&
        <section className="whats-hot section-vip position-relative" id="getCard">
          <div className="position-absolute bg-img">
            <img src={model.defaultImage} data-alt={model.newbrandeD_INTRODUCTION_BG_IMG} alt={model.introductionItem.content_Title} />
          </div>
          <div className="container">
            <div className="whats-hot-item">
              <div className="whats-hot-content">
                <div className="d-flex flex-column">
                  {/* <div className="col-12 col-lg-2">
                      <div className="vertical-align">
                        <div className="picto-wrap"><RichText field={model.content_Pictogtram} /> </div>

                      </div>

                    </div> */}
                  <div className="col-12 col-lg-7">
                    <div className="vertical-align">
                      {model.introductionItem.content_Title !== "" && (
                        <h3 className="whats-hot-title text-start">
                          <span className="title-light text-start pt-4">
                            <RichText
                              field={model.introductionItem.content_Title}
                            />
                          </span>
                        </h3>
                      )}
                      <div className="description text-start">
                        <RichText
                          field={
                            model.introductionItem.content_Introduction
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-12 col-lg-3 " + (model.shop_Landing ? "line-height-normal" : "")} >
                    <div className="vertical-align">
                      <a href={model.introductionItem.content_Link_URL.value.url} aria-label={model?.introductionItem?.content_Link_URL?.value} className="link-button btn-white-transparent" target={model.introductionItem.content_Link_URL.value.target}>
                        {model.introductionItem.content_Link_URL != null &&
                          <Text field={model.introductionItem.content_Link_Text} />
                        }
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )

    }
    else if (model.servicePage || model.pageEditor_ServicePage) {
      return (
        (model.introductionItem.content_Heading.value !== "" && model.introductionItem.content_Heading.value !== null || model.introductionItem.content_Pictogram.value !== "" && model.introductionItem.content_Pictogram.value !== null || model.introductionItem.content_Title.value !== "" && model.introductionItem.content_Title.value !== null || model.introductionItem.content_Introduction.value !== "" && model.introductionItem.content_Introduction.value !== null || model.introductionItem.content_Link_URL.value.url != null && model.introductionItem.content_Link_URL.value.url != "") &&
        <section className="whats-hot" id="suggestions">
          <div className="container">
            <h2 className="content-title text-uppercase text-center underline"><RichText field={model.introductionItem.content_Heading} /></h2>
            <div className="col-sm-12  height-auto ">
              <div className="whats-hot-item whats-hot-text whats-hot-large-text  text-dark link-dark">
                <div className="whats-hot-content">
                  <div className="vertical-align">
                    <div className="no-picto">
                      <RichText field={model.introductionItem.content_Pictogram} />
                    </div>
                    {(model.introductionItem.content_Title.value !== "" && model.introductionItem.content_Title.value !== null) &&
                      <RichText className="whats-hot-title text-uppercase" field={model.introductionItem.content_Title} tag="h3" />

                    }
                    <div className="description"><RichText field={model.introductionItem.content_Introduction} /></div>
                    <a href={model.introductionItem.content_Link_URL.value.url} aria-label={model?.introductionItem?.content_Link_URL?.value} className="link-button btn-transparent">
                      {model.introductionItem.content_Link_URL != null &&
                        <Text field={model.introductionItem.content_Link_Text} />
                      }
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )

    }
    //LoyaltyProgram
    else if (model.loyaty_Program || model.pageEditor_Loyaty_Program) {
      return (
        (!model.cookies_UID && ((model.introductionItem.content_Heading.value !== "" && model.introductionItem.content_Heading.value !== null) || (model.introductionItem.content_Pictogram.value !== "" && model.introductionItem.content_Pictogram.value !== null) || (model.introductionItem.content_Title.value !== "" && model.introductionItem.content_Title.value !== null) || (model.introductionItem.content_Introduction.value !== "" && model.introductionItem.content_Introduction.value !== null))) &&
        <section className="whats-hot" id="become-member">
          {/*         Loyalty Introduction  */}
          <div className="container">
            {(model.introductionItem.content_Heading.value !== "" && model.introductionItem.content_Heading.value !== null) &&
              <h2 className="content-title text-uppercase text-center underline"><RichText field={model.introductionItem.content_Heading} /></h2>

            }
            <div className="col-sm-12 ">
              <div className="whats-hot-item whats-hot-text whats-hot-large-text  text-dark bg-white link-dark">
                <div className="whats-hot-content">
                  <div className="vertical-align">

                    <div className="picto-wrap">
                      <RichText field={model.introductionItem.content_Pictogram} />
                    </div>
                    {(model.introductionItem.content_Title !== "") &&
                      <h3 className="whats-hot-title text-uppercase"><Text field={model.introductionItem.content_Title} /></h3>

                    }
                    <div className="description"><RichText field={model.introductionItem.content_Title} /></div>
                    <a href={model.introductionItem.content_Link_URL.value.url} aria-label={model?.introductionItem?.content_Link_URL?.value} className="link-button btn-dark">
                      {model.introductionItem.content_Link_URL != null &&
                        <Text field={model.introductionItem.content_Link_Text} />
                      }
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </section>
      )

    }
    else if (model.loyaltyOffers_Page) {
      return (
        (model.introductionItem.content_Introduction.value !== "" && model.introductionItem.content_Introduction.value !== null) &&
        <section className="welcome text-center">
          <div className="container">
            <div><RichText field={model.introductionItem.content_Introduction} /></div>
          </div>
        </section>
      )
    }
    else if (model.visitor_Deletion_Popin) {
      return (
        <>
          <div className="modal-dialog  modal-popin-trends disassociate-my-bank-account" id="modalDelAccSuccess">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="picto icon-close-circle-rw border-0 fs-4 bg-white text-black" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body text-center">
                <div className="popin-wrap">
                  {(model.management_Introduction_Title !== "" && model.management_Introduction_Title !== null) &&

                    <Text className="msg-title text-uppercase" field={model.management_Introduction_Title} tag="h3" />
                  }
                  <div className="msg-text">{model.management_Introduction}</div>
                  <a className="link-button btn-dark center-block" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);">{model.no}</a>
                  <a className="link-button btn-white center-block" onClick={(e) => VisitorDelete()} href="javascript:void(0);">{model.yes}</a>
                  <a className="center-block link-text account-profile-delete text-underline" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);">{model.cancel}</a>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    else if (model.bank_Association_Downgraded_Popin || model.account_Deletion_Success_Popin) {
      return (

        <div className="modal-dialog modal-popin-trends bank-association-downgraded-popin" id="modalAccSuccess">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="picto icon-close-circle-rw border-0 fs-4 bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="popin-wrap">
                {(model.management_Introduction_Title !== "" && model.management_Introduction_Title !== null) &&

                  <Text className="msg-title text-uppercase" field={model.management_Introduction_Title} tag="h3" />
                }
                <div className="msg-text">{model.management_Introduction}</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if (model.bank_Association_Downgrade_Failure_Popin || model.account_Deletion_Failure_Popin) {
      return (
        <div className="modal-dialog  modal-popin-trends " id="modalAccSuccess">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="picto icon-close-circle-rw border-0 fs-4 bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <div className="popin-wrap">
                {(model.management_Introduction_Title !== "" && model.management_Introduction_Title !== null) &&

                  <Text className="msg-title text-uppercase" field={model.management_Introduction_Title} tag="h3" />
                }
                <div className="msg-text">{model.management_Introduction}</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if (model.contact_Us) {
      return (
        (model.content_Introduction.value !== "" && model.content_Introduction.value !== null) &&
        <section className="welcome">
          <div className="container">
            <p><Text field={model.content_Introduction} /></p>
          </div>
        </section>

      )
    }
    else {
      return (
        <></>
      )
    }
  }

  function VisitorDelete() {
    if (isTokenExpired()) {
      var args = [];
      GetRenewedAccessToken(DeleteVisitorAccount, args);
    } else {
      DeleteVisitorAccount();
    }
  }
}
