import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect } from "react";
import { CommonBookmarkallpage } from "../Js-CommonFunctions";

export default function StoreMapInfoLeft({ fields }) {
  useEffect(() => {
    CommonBookmarkallpage();
    // if(fields !== null && fields !== "" && fields !== undefined && fields.redirectToParentPage){
    //   URWindowLocation(fields.urlToRedirect);
    // }
  }, []);
  if (fields.storeMapInfoLeft != null) {
    //var todayOpenOrClosedLabel = { value:fields.storeMapInfoLeft.todayOpenOrClosedLabel?.value?.toLowerCase()};
    return (
      <div className="row">
        <div
          className={`col-md-4 col-sm-12 position-relative bg-white d-none d-lg-flex ${fields.storeMapInfoLeft.isNewBrandedSite ? "" : " px-3 py-4 "
            } ${fields.storeMapInfoLeft.hasCurbside ? "curbside-block" : ""
            } left-top-content`}
        >
          {fields.storeMapInfoLeft.hasCurbside && (
            <div className="curbside position-absolute fw-bold text-uppercase notation left">
              <Text field={fields.storeMapInfoLeft.curbsidePickupText} />
            </div>
          )}
          {fields.storeMapInfoLeft.isLUXNewStore && (
            <div className="position-absolute fw-bold text-uppercase notation left">
              <Text field={fields.storeMapInfoLeft.newStoreTag} />
            </div>
          )}
          {fields.storeMapInfoLeft.isComingSoon && (
            <div className="position-absolute fw-bold text-uppercase notation left">
              <Text field={fields.storeMapInfoLeft.comingSoonStoreTag} />
            </div>
          )}
          <a
            href="javascript:void(0);"
            className="position-absolute text-reset fs-4 open-popin bookmark-news-new bookmark ava-wishlist  picto icon-heart-unfill-rw"
            role="button"
            aria-label={fields.storeMapInfoLeft?.itemId}
            aria-labelledby={fields.storeMapInfoLeft.storeType.value == "restaurant" ? "restaurant" : "store"}
            data-itemlabel={fields.storeMapInfoLeft.name.value}
            data-templatetype={fields.storeMapInfoLeft.storeType.value == "store" ? "Store Detail" : "Restaurant Detail"}
          ></a>
          {fields.storeMapInfoLeft.storeLogoUrl !== null &&
            fields.storeMapInfoLeft.storeLogoUrl !== "" && (
              <div className="text-center header">
                <img
                  className="store-logo-img "
                  src={fields.storeMapInfoLeft.storeLogoUrl}
                  alt={
                    fields.storeMapInfoLeft.StoreLogoAlt !== null &&
                      fields.storeMapInfoLeft.StoreLogoAlt !== undefined
                      ? fields.storeMapInfoLeft.StoreLogoAlt.value
                      : ""
                  }
                />
              </div>
            )}
          {/* <div
            className={`store-heading-text text-start ${fields.storeMapInfoLeft.isNewBrandedSite
              ? ""
              : ""
              } m-0`}
          >
            <Text field={fields.storeMapInfoLeft.name} />
          </div> */}
          {/* {((fields.storeMapInfoLeft.openingHours !== null &&
            fields.storeMapInfoLeft.openingHours !== undefined &&
            fields.storeMapInfoLeft.openingHours.length > 0) ||
            (fields.storeMapInfoLeft.openingHoursList !== null &&
              fields.storeMapInfoLeft.openingHoursList !== undefined &&
              fields.storeMapInfoLeft.openingHoursList.length > 0)) &&
            !fields.storeMapInfoLeft.isComingSoon && (
              <p
                className={`time-text fw-bold text-start ${fields.storeMapInfoLeft.isNewBrandedSite
                  ? ""
                  : ""
                  })`}
              >
                <Text field={fields.storeMapInfoLeft.todayOpenOrClosedLabel} />
                <br></br>
                {fields.storeMapInfoLeft.isCinema ? (
                  <span className="time">
                    <Text field={fields.storeMapInfoLeft.todayTimes} />
                  </span>
                ) : (
                  fields.storeMapInfoLeft.timelst.map((time) => {
                    return (
                      <>
                        <span className="time">
                          <Text field={time} />
                        </span>
                        <br></br>
                      </>
                    );
                  })
                )}
              </p>
            )} */}
          {/* <div className="weblink-text d-lg-block d-none">
            {fields.storeMapInfoLeft.websiteUrl.value !== null &&
              fields.storeMapInfoLeft.websiteUrl.value !== "" && (
                <>
                  <span className="picto icon-website"></span>
                  <a
                    aria-label={fields?.storeMapInfoLeft?.websiteUrl?.value}
                    target="_blank"
                    className="text-reset"
                    rel="noopener noreferrer"
                    href={fields.storeMapInfoLeft.websiteUrl.value}
                  >
                    <Text field={fields.storeMapInfoLeft.websiteText} />
                  </a>
                </>
              )}
          </div> */}
          {/* {fields.storeMapInfoLeft.telephone.value !== null &&
            fields.storeMapInfoLeft.telephone.value !== "" && (
              <div className="phone-text d-lg-block d-none">
                <span
                  className="picto icon-srv-telephone-point"
                  style={{ paddingRight: "5.5px" }}
                ></span>
                <a href="#" className="text-reset"><Text field={fields.storeMapInfoLeft.telephone} /></a>
              </div>
            )} */}
          {/* <div className="mt-3"> */}
          {/* {fields.storeMapInfoLeft.isMapExist && (
              <div id="mapofthemallcta">
                <a
                  aria-label={fields?.storeMapInfoLeft?.mapLocation}
                  className="w-100 mw-100 text-reset link-button btn-white-new mb-lg-0 mb-3 scrollToHidden"
                  href="#mapofthemall"
                >
                  <Text field={fields.storeMapInfoLeft.mapLocation} />
                </a>
              </div>
            )} */}
          {/* not covered
            {!fields.storeMapInfoLeft.isCinema && (
              <div id="storesservices" className="hidden">
                <a
                  aria-label={fields?.storeMapInfoLeft?.availableServicesText}
                  className="w-100 mw-100 text-reset link-button btn-white-new mb-lg-0 mb-3 scrollToHidden"
                  href="#availableservices"
                >
                  <Text field={fields.storeMapInfoLeft.availableServicesText} />
                </a>
              </div>
            )}
            {fields.storeMapInfoLeft.websiteUrl.value !== null &&
              fields.storeMapInfoLeft.websiteUrl.value !== "" && (
                <div className="weblink-text d-lg-none d-block">
                  <span className="picto icon-website"></span>
                  <a
                    aria-label={fields?.storeMapInfoLeft?.websiteUrl?.value}
                    className="text-reset"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={fields.storeMapInfoLeft.websiteUrl.value}
                  >
                    <Text field={fields.storeMapInfoLeft.websiteText} />
                  </a>
                </div>
              )}
            {fields.storeMapInfoLeft.telephone.value !== null &&
              fields.storeMapInfoLeft.telephone.value !== "" && (
                <div className="phone-text d-lg-none d-block">
                  {fields.storeMapInfoLeft.telephoneHasOnlyNumbers ? (
                    <>
                      <span className="picto icon-srv-telephone-point"></span>
                      <a
                        aria-label={fields?.storeMapInfoLeft?.telephone?.value}
                        className="text-reset"
                        href={`tel:${fields.storeMapInfoLeft.telephone.value}`}
                      >
                        <Text field={fields.storeMapInfoLeft.telephone} />
                      </a>
                    </>
                  ) : (
                    <>
                      <span className="picto icon-srv-telephone-point"></span>
                      <a className="text-reset" aria-label={fields?.storeMapInfoLeft?.telephone}>
                        <Text field={fields.storeMapInfoLeft.telephone} />
                      </a>
                    </>
                  )}
                </div>
              )} */}
          {/* </div> */}
        </div>
        <div className="col-md-8 col-sm-12 px-3">
          {fields.storeMapInfoLeft.name.value !== null &&
            fields.storeMapInfoLeft.name.value !== "" && (
              <div
                className="mb-4 heading-text"
              >
                <Text field={fields.storeMapInfoLeft.name} />
              </div>
            )}
          {((fields.storeMapInfoLeft.openingHours !== null &&
            fields.storeMapInfoLeft.openingHours !== undefined &&
            fields.storeMapInfoLeft.openingHours.length > 0) ||
            (fields.storeMapInfoLeft.openingHoursList !== null &&
              fields.storeMapInfoLeft.openingHoursList !== undefined &&
              fields.storeMapInfoLeft.openingHoursList.length > 0)) &&
            !fields.storeMapInfoLeft.isComingSoon && (
              <p
                className={`time-text text-start  ps-3 d-flex align-items-center ${fields.storeMapInfoLeft.isOpen
                  ? " active"
                  : " inactive"
                  }`}
              >
                <Text field={fields.storeMapInfoLeft.todayOpenOrClosedLabel} />
                {fields.storeMapInfoLeft.isCinema ? (
                  <span className="time ps-1">
                    <Text field={fields.storeMapInfoLeft.todayTimes} />
                  </span>
                ) : (
                  fields.storeMapInfoLeft.timelst.map((time) => {
                    return (
                      <>
                        <span className="time ps-1">
                          <Text field={time} />
                        </span>
                        <br/>
                      </>
                    );
                  })
                )}
              </p>
            )}
          <div
            className={`col-md-4 col-sm-12 position-relative bg-white d-lg-none d-sm-block ${fields.storeMapInfoLeft.isNewBrandedSite ? "" : " px-3 py-4 "
              } ${fields.storeMapInfoLeft.hasCurbside ? "curbside-block" : ""
              } left-top-content`}
          >
            {fields.storeMapInfoLeft.hasCurbside && (
              <div className="curbside position-absolute fw-bold text-uppercase notation left">
                <Text field={fields.storeMapInfoLeft.curbsidePickupText} />
              </div>
            )}
            {fields.storeMapInfoLeft.isLUXNewStore && (
              <div className="position-absolute fw-bold text-uppercase notation left">
                <Text field={fields.storeMapInfoLeft.newStoreTag} />
              </div>
            )}
            {fields.storeMapInfoLeft.isComingSoon && (
              <div className="position-absolute fw-bold text-uppercase notation left">
                <Text field={fields.storeMapInfoLeft.comingSoonStoreTag} />
              </div>
            )}
            <a
              href="javascript:void(0);"
              className="position-absolute text-reset fs-4 open-popin bookmark-news-new bookmark ava-wishlist  picto icon-heart-unfill-rw"
              role="button"
              aria-label={fields.storeMapInfoLeft?.itemId}
              aria-labelledby={fields.storeMapInfoLeft.storeType.value == "restaurant" ? "restaurant" : "store"}
              data-itemlabel={fields.storeMapInfoLeft.name.value}
              data-templatetype={fields.storeMapInfoLeft.storeType.value == "store" ? "Store Detail" : "Restaurant Detail"}
            ></a>
            {fields.storeMapInfoLeft.storeLogoUrl !== null &&
              fields.storeMapInfoLeft.storeLogoUrl !== "" && (
                <div className="text-center header">
                  <img
                    className="store-logo-img "
                    src={fields.storeMapInfoLeft.storeLogoUrl}
                    alt={
                      fields.storeMapInfoLeft.StoreLogoAlt !== null &&
                        fields.storeMapInfoLeft.StoreLogoAlt !== undefined
                        ? fields.storeMapInfoLeft.StoreLogoAlt.value
                        : ""
                    }
                  />
                </div>
              )}

          </div>

          <div className="">
            {fields.storeMapInfoRight.description.value !== null &&
              fields.storeMapInfoRight.description.value !== "" && (
                <div
                  className={"text-area mb-4 more"}
                  id="descriptiontext"
                >
                  <RichText field={fields.storeMapInfoRight.description} />
                </div>
              )}
            {/* <span className="morelink" id="more-text-trigger">Show More</span>
              <span className="morelink less" id="less-text-trigger">Show Less</span> */}
          </div>
          {((fields.storeMapInfoRight.safetyMessageTitle !== undefined &&
            fields.storeMapInfoRight.safetyMessageTitle.value !== null &&
            fields.storeMapInfoRight.safetyMessageTitle.value !== "") ||
            (fields.storeMapInfoRight.safetyMessageDescription !==
              undefined &&
              fields.storeMapInfoRight.safetyMessageDescription.value !==
              null &&
              fields.storeMapInfoRight.safetyMessageDescription.value !==
              "") ||
            (fields.storeMapInfoRight.safetyMessageImage !== undefined &&
              fields.storeMapInfoRight.safetyMessageImage.value !== null &&
              fields.storeMapInfoRight.safetyMessageImage.value !== "")) && (
              <div className="safety-message-block">
                {fields.storeMapInfoRight.safetyMessageTitle.value !== null &&
                  fields.storeMapInfoRight.safetyMessageTitle.value !== "" && (
                    <div className="heading-text">
                      <Text
                        field={fields.storeMapInfoRight.safetyMessageTitle}
                      />
                    </div>
                  )}
                {((fields.storeMapInfoRight.safetyMessageImage.value !== null &&
                  fields.storeMapInfoRight.safetyMessageImage.value !== "") ||
                  (fields.storeMapInfoRight.safetyMessageDescription !==
                    undefined &&
                    fields.storeMapInfoRight.safetyMessageDescription.value !==
                    null &&
                    fields.storeMapInfoRight.safetyMessageDescription.value !==
                    "")) && (
                    <div className="safety-message ">
                      <div
                        className={`safety-message-wrap position-relative border-0 shadow-none ${fields.storeMapInfoRight.safetyMessageImage.value !==
                          null &&
                          fields.storeMapInfoRight.safetyMessageImage.value !== ""
                          ? ""
                          : "no-image"
                          }`}
                      >
                        {fields.storeMapInfoRight.safetyMessageImage.value !==
                          null &&
                          fields.storeMapInfoRight.safetyMessageImage.value !==
                          "" && (
                            <div
                              className={`img-wrap ${fields.storeMapInfoRight
                                .safetyMessageDescription !== undefined &&
                                fields.storeMapInfoRight.safetyMessageDescription
                                  .value !== null &&
                                fields.storeMapInfoRight.safetyMessageDescription
                                  .value !== ""
                                ? "withtext"
                                : ""
                                }`}
                            >
                              <img
                                className="border-8"
                                src={
                                  fields.storeMapInfoRight.safetyMessageImage
                                    .value
                                }
                                alt={
                                  fields.storeMapInfoRight.safetyMessageImageAlt
                                    .value
                                }
                              />
                            </div>
                          )}
                        {fields.storeMapInfoRight.safetyMessageDescription !==
                          undefined &&
                          fields.storeMapInfoRight.safetyMessageDescription
                            .value !== null &&
                          fields.storeMapInfoRight.safetyMessageDescription
                            .value !== "" && (
                            <div className="content-block">
                              <div className="title">HEALTH &amp; SAFETY</div>
                              <div className="description">
                                <RichText
                                  field={
                                    fields.storeMapInfoRight
                                      .safetyMessageDescription
                                  }
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                {fields.storeMapInfoRight.safetyMessageLinkSrc.value !== null &&
                  fields.storeMapInfoRight.safetyMessageLinkSrc.value !== "" &&
                  fields.storeMapInfoRight.safetyMessageLinkText.value !==
                  null &&
                  fields.storeMapInfoRight.safetyMessageLinkText.value !==
                  "" && (
                    <a
                      aria-label={fields?.storeMapInfoRight?.safetyMessageLinkTarget?.value}
                      className={"safety-nav " + (fields.storeMapInfoRight.isRebranded ? "" : "text-start")}
                      href={fields.storeMapInfoRight.safetyMessageLinkSrc.value}
                      target={
                        fields.storeMapInfoRight.safetyMessageLinkTarget.value
                      }
                    >
                      <span>
                        <Text
                          field={fields.storeMapInfoRight.safetyMessageLinkText}
                        />
                      </span>
                      <span className="picto icon-go-right-arrow"></span>
                    </a>
                  )}
              </div>
            )}
          {fields.storeMapInfoLeft.telephone.value !== null &&
            fields.storeMapInfoLeft.telephone.value !== "" && (
              <>
                <div className="phone-text d-flex mb-3 d-lg-flex d-md-flex d-none">
                  <span
                    className="picto icon-phone-call d-flex align-items-center pe-2"
                    style={{ paddingRight: "5.5px" }}
                  ></span>
                  <a href="javascript:void(0);" className="weblink-text-number "><Text field={fields.storeMapInfoLeft.telephone} /></a>
                </div>
                <div className="phone-text d-flex mb-3 d-lg-none d-md-none d-sm-flex ">
                  <span
                    className="picto icon-phone-call d-flex align-items-center pe-2"
                    style={{ paddingRight: "5.5px" }}
                  ></span>
                  <a href={`tel:${fields.storeMapInfoLeft.telephone.value}`} className="weblink-text-number "><Text field={fields.storeMapInfoLeft.telephone} /></a>
                </div>
              </>
            )}
          <div className="d-flex weblink-text-flex mb-3" >
            {fields.storeMapInfoLeft.isMapExist &&
              // <div id="mapofthemallcta">
              <a
                className="link-button btn-dark btn-width mb-lg-0 mb-3"
                href="#mapofthemall"
              > <Text field={fields.storeMapInfoLeft.mapLocation} />
              </a>
              // </div>
            }
            <div className="weblink-text d-flex align-items-center">
              {fields.storeMapInfoLeft.websiteUrl.value !== null &&
                fields.storeMapInfoLeft.websiteUrl.value !== "" && (
                  <>
                    {/* <span className="picto icon-website"></span> */}
                    <a
                      aria-label={fields?.storeMapInfoLeft?.websiteUrl?.value}
                      target="_blank"
                      className="ps-lg-3 weblink-text-link text-decoration-underline"
                      rel="noopener noreferrer"
                      href={fields.storeMapInfoLeft.websiteUrl.value}
                    >
                      <Text field={fields.storeMapInfoLeft.websiteText} />
                    </a>
                  </>
                )}
            </div>

          </div>
          {!fields.storeMapInfoLeft.isCinema && (
            <div id="storesservices" className="d-flex weblink-text-flex mb-3 hidden">
              <a
                aria-label={fields?.storeMapInfoLeft?.availableServicesText}
                className="link-button btn-dark btn-width mb-lg-0 mb-3 scrollToHidden"
                href="#availableservices"
              >
                <Text field={fields.storeMapInfoLeft.availableServicesText} />
              </a>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
