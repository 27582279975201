import React from 'react';
import { handleMblBackButton } from '../MyAccount/MyAccountScripts';

export default function BenefitsHeaderTab({ fields, label, isLoyaltyBenefits, isMultiCentre, isEasyParking }) {
  if (fields !== undefined && fields !== null && fields !== "") {
    return (
      <div >
        <div id="tlpHeader" className="basic-benefits-container d-lg-none d-xl-none bg-white">
          <div className="container modalContainer p-0 ">
            <nav className="benefits-header-height px-lg-1 row m-0 py-2">
              <div className="col-2 text-center px-0">
                <a aria-label={fields?.benefitsAndServicesTitle} className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black fs-3"
                  href="javascript:void(0);" data-testid="handlemblbackbuttonclick" onClick={() => { handleMblBackButton(isMultiCentre) }}></a>
              </div>
              <div className="col-8 p-0 text-center">
                <div className="text-center d-none benefits-title ">
                  {fields.benefitsAndServicesTitle}
                </div>
                <div className="text-center benefits-subtitle ">
                  {label}
                </div>
              </div>
              <div className="col-2 text-center px-0">
              </div>
            </nav>
          </div>
        </div>
        <ul className="nav mob-menu nav-pills" role="tablist">
          <li>
            <a aria-label={fields?.smartParkingTitle} data-bs-toggle="pill" href={isLoyaltyBenefits ? "#myaccountbasicbenefits" : "#tlpbenefits"} className="nav-link multi-detail-nav-link align-items-center d-flex d-lg-none d-xl-none no-underline filter active text-center" data-testid="showtlporbenefitsclick" onClick={(e) => { ShowTLPOrBenefits() }}>
              <span className="picto multi-detail-icon-mob icon-srv-gift-cards d-block d-lg-none"></span>
              <div className=" multi-detail-icon-mob-text active-color wt-600 m-lg-0 d-inline-block">{fields.loyaltyTitle?.toLowerCase()}</div>
            </a>
          </li>
          <li>
            <a aria-label={fields?.smartParkingTitle} data-bs-toggle="pill" id="my-parking-tab" aria-controls="myparking" href="#myparking" className="nav-link multi-detail-nav-link align-items-center filter d-flex d-lg-none d-xl-none text-center" data-testid="showsmartparkingclick" onClick={(e) => { ShowSmartParking() }}>
              <span className="picto multi-detail-icon-mob icon-srv-cars d-block d-lg-none"></span>
              {!isEasyParking &&
                <div id="smart-parking-header" className=" m-lg-0 multi-detail-icon-mob-text d-inline-block">{fields.smartParkingTitle?.toLowerCase()} </div>
              }
              {isEasyParking &&
                <div id="easy-parking-header" className=" m-lg-0 multi-detail-icon-mob-text d-inline-block">{fields.easyParkingTitle?.toLowerCase()} </div>
              }
            </a>
          </li>
        </ul>
      </div>
    )
  }
  else {
    return <></>
  }
}

function hideLPBlock() {
  if (document.getElementById('loyaltydiscovermyadvantages'))
    document.getElementById('loyaltydiscovermyadvantages').classList.add('d-none')
  if (document.getElementById('loyaltyadvantagesmonosc'))
    document.getElementById('loyaltyadvantagesmonosc').classList.add('d-none')
}

function showLPBlock() {
  if (document.getElementById('loyaltydiscovermyadvantages'))
    document.getElementById('loyaltydiscovermyadvantages').classList.remove('d-none')
  if (document.getElementById('loyaltyadvantagesmonosc'))
    document.getElementById('loyaltyadvantagesmonosc').classList.remove('d-none')
}

function hideOpinionBlock() {
  if (document.getElementById('opinionblock'))
    document.getElementById('opinionblock').classList.add('d-none')
  if (document.getElementById('opinionmonosc'))
    document.getElementById('opinionmonosc').classList.add('d-none')
}

function showOpinionBlock() {
  if (document.getElementById('opinionblock'))
    document.getElementById('opinionblock').classList.remove('d-none')
  if (document.getElementById('opinionmonosc'))
    document.getElementById('opinionmonosc').classList.remove('d-none')
}

function ShowSmartParking(event) {
  hideLPBlock();
  hideOpinionBlock();
  if (document.getElementById('multipreferredsc')) { document.getElementById('multipreferredsc').classList.add('d-none') }
  else {
    if (document.getElementById('myaccountbasicbenefits'))
      document.getElementById('myaccountbasicbenefits').classList.add('d-none')
    if (document.getElementById('tlpbenefits'))
      document.getElementById('tlpbenefits').classList.add('d-none')
  }
  if (document.getElementById('smartparkingview')) {
    document.getElementById('smartparkingview').classList.remove('d-none')
  } else {
    if (document.getElementById('myparking')) {
      document.getElementById('myparking').classList.remove('d-none');
    }
  }
}

function ShowTLPOrBenefits() {
  showLPBlock();
  showOpinionBlock();
  if (document.getElementById('multipreferredsc')) { document.getElementById('multipreferredsc').classList.remove('d-none') }
  else {
    if (document.getElementById('myaccountbasicbenefits'))
      document.getElementById('myaccountbasicbenefits').classList.remove('d-none')
    if (document.getElementById('tlpbenefits'))
      document.getElementById('tlpbenefits').classList.remove('d-none')
  }
  if (document.getElementById('smartparkingview')) {
    document.getElementById('smartparkingview').classList.add('d-none')
  } else {
    if (document.getElementById('myparking')) {
      document.getElementById('myparking').classList.add('d-none');
    }
  }
}
