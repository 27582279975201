import React from 'react';
import { baseModel } from '../../RouteHandler';


export default function SearchNoResult({ model }) {
  if (model != null) {
    return (
      <>
        <div className="align-items-end d-flex justify-content-between text-black">
          {(model.no_Result_Text) &&
            <h2 className="search-title-no-result text-start">{model.no_Result_Text}</h2>
          }
          </div> 
          {(model.search_No_Result_Menus != null && model.search_No_Result_Menus.length > 0) &&
            (!baseModel.isNewBrandedSite) ?
            (
              <div className="search-desc  d-flex align-items-start my-3">{model.no_Result_Menu_Text}</div>
            ) :
            (
              <div className="search-desc  d-flex align-items-start my-3">{model.no_Result_Menu_Text}</div>
            )
          }
        {NoStoresInnerLoop(model)}
      </>
    )
  } else {
    return <></>
  }

}
var iCounter;
function NoStoresInnerLoop(model) {
  if (model.search_No_Result_Menus != null && model.search_No_Result_Menus.length > 0) {
    return (
      <>
        <div className="categories row justify-content-start">
          {fnNoResultMenusloop(model)}
        </div>
        <ul className="manageable">
          {fnNoResultMenusInnerloop(model)}

        </ul>
      </>
    )
  }

}

function fnNoResultMenusloop(model) {
  let NoReultMenus = [];
  for (iCounter = 0; (iCounter < 6 && iCounter < model.search_No_Result_Menus.length); iCounter++) {
    var menu = model.search_No_Result_Menus[iCounter];
    if (menu == null || menu.search_No_Result_Menus.urlWebView == null) {
      return <></>;
    }
    NoReultMenus.push(
      <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1">
        <a className="block store-wrap d-flex justify-content-center border-8 bg-gray" href={menu.menuUrl}>
          <div className="store-img d-flex justify-content-center ">
            {(menu.search_No_Result_Menus.pictoOn.value != null && (menu.search_No_Result_Menus.pictoOn.value?.src !== "")) ?
              (
                <img src={menu.search_No_Result_Menus.pictoOn.value.src} alt={menu.search_No_Result_Menus.pictoOn.value.alt} title={menu.search_No_Result_Menus.menuName} />
              ) :
              (
                <span className="picto icon-infos"></span>
              )
            }
          </div>
        </a>
        <a href={menu.menuUrl} aria-label={menu?.menuUrl} className="d-flex store-name text-start gtmSearchNavigation">{menu.search_No_Result_Menus.menuName}</a>
      </div>
    )
  }
  return NoReultMenus;

}

function fnNoResultMenusInnerloop(model) {
  let menus = [];
  for (; iCounter < model.search_No_Result_Menus.length; iCounter++) {
    var menu = model.search_No_Result_Menus[iCounter];
    if (menu == null || menu.search_No_Result_Menus.urlWebView == null) {
      return <></>;
    }
    menus.push(
      <li>
        <a href={menu.menuUrl} aria-label={menu?.menuUrl} className="gtmSearchNavigation">{menu.search_No_Result_Menus.menuName}</a>
      </li>
    )
  }
  return menus;
}