import Enumerable from "linq";
import React, { useEffect, useState } from "react";
import { convertUTCTimeToClientTime, fngtmModuleclick, isMobile, lazyLoadingImg } from '../Js-CommonFunctions';

export default function PastEvent({ fields }) {
  const [visible, setVisible] = useState(0);
  const [pastEventList, setPastEventList] = useState([]);

  useEffect(() => {
    var localeDateTime = new Date();
    if (fields !== null && fields !== undefined && fields !== "" && fields.liveEvent !== null && fields.liveEvent.videoList !== null && fields.liveEvent.videoList !== "" && fields.liveEvent.videoList !== undefined && fields.liveEvent.videoList.length > 0) {
      var pastList = Enumerable.from(fields.liveEvent.videoList).orderByDescending(x => new Date(x.finishesAt).getTime()).where(x => (x.status != "unpublished" && x.status != "pending" && new Date(x.finishesAt).getTime() <= localeDateTime.getTime())).toArray();
      setPastEventList(pastList);
    }
  }, []);

  useEffect(() => {
    if (isMobile.any()) {
      setVisible(2);
    }
    else if (isMobile.isTablet()) {
      setVisible(3);
    }
    else if (window.screen.orientation != null && window.screen.orientation != "" && window.screen.orientation.type != null && window.screen.orientation.type != "") {
      if (window.screen.orientation.type == "landscape-primary" || window.screen.orientation.type == "landscape-secondary") {
        setVisible(3);
      }
    }
    else
      setVisible(3);
  }, [pastEventList]);

  const fnViewmoreClick = () => {
    setVisible(pastEventList.length)
    lazyLoadingImg();
    if (document.getElementById("viewmorepageother")) {
      document.getElementById("viewmorepageother").style.display = "none";
    }
  }

  if (fields !== null && fields !== undefined && fields !== "" && fields.liveEvent != null &&
    pastEventList != null && pastEventList !== undefined && pastEventList.length > 0) {
    return (
      <section className="generic-three-block even-live live-events position-relative bg-2 even">
        <div className="container">
          <div className="row">
            <div className="header-title d-flex align-items-center flex-wrap justify-content-between ps-0 mb-2">
              <h2 className="title-shopping-event event-title-top-margin ps-0">
                {fields.liveEvent.past_Events_Title}
              </h2>
              {pastEventList.length > visible ?
                <a href="javascript:void(0);" id="viewmorepageother" className=" explore-event action explore-event-live"
                  data-testid="fnViewmoreClick" onClick={(e) => fnViewmoreClick()}>
                  <strong>{fields.liveEvent.explore_All}</strong></a>
                : <></>
              }
            </div>
            <div className="d-flex past-shopping-slider p-0">
            {pastEventList.slice(0, visible).map((item, index) => {
              var formatedDateTime = convertUTCTimeToClientTime(item.startsAt, false, false);
              return (
                <div key={index} className="col-6 col-lg-4  col-md-4  mb-3 ps-lg-0 pe-lg-2 px-1">
                  <div className="bg-transparent border-0 shadow-none col grid-item past-live-shopping-event-mob grid-withpicture bg-white position-relative">
                    <div className="picto-icon img-event-container position-relative grid-picture m-0 p-0">
                      {
                        item.media != null && item.media != "" && (
                          <img
                            src={fields.liveEvent.defaultImage}
                            data-alt={item.media.eventImage}
                            alt={item.name}
                            title={item.name}
                            className="border-8"
                          />
                        )
                      }
                    </div>
                    <a href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                      aria-label={fields?.liveEvent?.multi_Retailers}
                      className={"position-absolute z-index-1 w-100 h-100 block-link"}
                    ></a>
                    <div className="grid-content grid-content-event p-0">
                    {item.startsAt != null && item.startsAt != "" && (
                        <div className="date-live-event mb-0 text-start mt-3 mb-1">{formatedDateTime}
                        </div>
                      )}
                      {item.retailers != null && item.retailers != "" ?
                        (item.retailers.length > 1 ?
                          <div className="description-event text-start p-0 mb-1">{fields.liveEvent.multi_Retailers}</div> :
                          <div className="description-event  text-start p-0 mb-1">
                            {item.retailers[0].retailerName}
                          </div>)
                        :
                        <div className="description-event retailer-name-title text-start p-0 mt-3">
                        </div>
                      }
                      
                      {item.name != null && item.name != "" && (
                        <h3 className="grid-title-live d-flex p-0 text-start mt-2 mb-0">
                          {item.name}
                        </h3>
                      )}
                     
                      <div className=" d-flex position-absolute text-start flex-column my-1 fs-lg5">
                       <div className="text-start bg-transparent ">
                        <a
                          href={fields.liveEvent.urlPrefix + item.liveEventDetailPagePath}
                          className="grid-new-cta pe-1 grid-new-cta-color"
                          data-testid="fngtmModuleclick" onClick={() => fngtmModuleclick(item, "click_see_details", "Archived/Past events")}
                        >{fields.liveEvent.see_Details}</a>
                        <span className="picto icon-arrow-square-red-right-rw align-middle px-0"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            </div>
          </div>
        </div>
      </section>
    );
  }
  else {
    return <></>;
  }
}
