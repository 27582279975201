import React from 'react';

export default function StoreCategoryList({ fields }) {

  if (fields.storeCategoryList !== null && fields.storeCategoryList !== undefined && fields.storeCategoryList.categoryData.length > 0) {
    return (
      <section className="store-detail-categories bg-4">
        <div className="container">
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
            <div className="title mb-1 mb-lg-0">
              <h2>{fields.storeCategoryList.categories.value}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <ul className="categories">
                {fields.storeCategoryList.categoryData.map((category, index) => {
                  return (
                    <li key={index}><a aria-label={category?.name?.value} className="gtmClickNavigation" href={fields.constants.urlPrefix + fields.constants.storeType + category.url}>{category.name.value}</a></li>

                  )
                })
                }

              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return <></>;
  }
}