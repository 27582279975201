import Enumerable from "linq";
import React from "react";

export default function DestinationRetailersInnerPage({ model, itemsPerPage, props }) {
  if (
    model != null &&
    model.displaystoreitems != null &&
    model.displaystoreitems.length > 0
  ) {
    let count = 1;
    let newTag = model.newTagText;
    let redirectUrl = "";
    let isNewBrandedsite = model.isRebranded;
    let urlPrefix = model.urlPrefix;

    return (
      <>
        <input
          type="hidden"
          id="viewmoreretailerscount"
          value={model.retailerPageNumber}
        />
        <input
          type="hidden"
          id="firstviewmoreretailerscount"
          value={model.retailerPageNumber * model.itemsPerPage}
        />
        <input
          type="hidden"
          id="totalviewmoreretailerscount"
          value={model.displaystoreitems.length}
        ></input>

        {model.displaystoreitems
          .slice(0, itemsPerPage)
          .map((retailer, key) => {
            if (retailer === null) {
              return <></>;
            }
            redirectUrl =
              retailer.retailerType === model.typeStore.toString()
                ? model.storePage + retailer.urlPath
                : model.restaurantPage + retailer.urlPath;
            let storeItemClickPosition;

            if (count <= model.itemsPerPage * model.retailerPageNumber) {
              storeItemClickPosition =
                retailer.retailerType + "-" + count + "-{pagename}";
              count++;
              
              return (
                <div key={key} className="col-md-3 col-sm-4 col-12 " id="retailersView">
                  <div
                    className={
                      "thumbnail position-relative border-0 rounded-0 p-0 text-center shadow-none " +
                      (model.isUSUK ? model.usUkStoreLogo : "")
                    }
                  >
                    {retailer.isNewStore && (
                      <a
                        aria-label={model?.comingSoon}
                        href={urlPrefix + model.slash + redirectUrl}
                        className={
                          "new-tap loyalty-text fw-bold position-absolute text-start text-uppercase" +
                          (isNewBrandedsite ? "" : " pink") +
                          "  gtmClickNavigation"
                        }
                      >
                        {newTag}
                      </a>
                    )}
                    {retailer.isComingSoon && (
                      <a
                        aria-label={model?.comingSoon}
                        href={urlPrefix + model.slash + redirectUrl}
                        className={
                          "new-tap loyalty-text fw-bold position-absolute text-start text-uppercase " +
                          (isNewBrandedsite ? "" : " pink") +
                          "  gtmClickNavigation"
                        }
                      >
                        {model.comingSoon}
                      </a>
                    )}
                    <a
                      href="javascript:void(0);"
                      className="open-popin  bookmark picto icon-heart-unfill-rw add-wishlist ava-wishlist"
                      role="button"
                      aria-label={retailer.storeIdWithOutRegex}
                      aria-labelledby={retailer.storeTemplate}
                    ></a>
                    <a
                      aria-label={retailer.imgAltText}
                      href={
                        retailer !== null &&
                          retailer.urlPath !== null &&
                          retailer.urlPath !== ""
                          ? urlPrefix + model.slash + redirectUrl
                          : " javascript:void(0);"
                      }
                      className="gtmClickNavigation"
                    >
                      <div className="header">
                        <span className="helper"></span>
                        <img
                          src={
                            retailer.imgUrl === null || retailer.imgUrl === ""
                              ? ""
                              : retailer.imgUrl
                          }
                          className="d-flex p-0 home-offers-image"
                          alt={
                            retailer.imgAltText === null ||
                              retailer.imgAltText === ""
                              ? ""
                              : retailer.imgAltText
                          }
                          title={
                            retailer.imgAltText === null ||
                              retailer.imgAltText === ""
                              ? ""
                              : retailer.imgAltText
                          }
                        />
                      </div>
                    </a>
                    <div className="caption">
                      <a
                        aria-label={retailer.display_Name}
                        className="brand-name text-uppercase d-block gtmClickNavigation"
                        href={urlPrefix + model.slash + redirectUrl}
                      >
                        {retailer.display_Name === null ||
                          retailer.display_Name === ""
                          ? ""
                          : retailer.display_Name}
                      </a>
                      {retailer.openingHours.length > 0 &&
                        retailer.isOpen &&
                        !retailer.isComingSoon ? (
                        retailer.openingHours.map((opening, key) => {
                          return (
                            <a key={key}
                              aria-label={retailer?.isComingSoon}
                              className="opening-hours fw-bold d-block gtmClickNavigation"
                              href={urlPrefix + model.slash + redirectUrl}
                            >
                              {opening}
                            </a>
                          );
                        })
                      ) : !retailer.isOpen && !retailer.isComingSoon ? (
                        <a
                          aria-label={retailer?.openingHours}
                          className="opening-hours fw-bold d-block gtmClickNavigation"
                          href={urlPrefix + model.slash + redirectUrl}
                        >
                          {Enumerable.from(
                            retailer.openingHours
                          ).firstOrDefault()}
                        </a>
                      ) : (
                        <></>
                      )}
                      <a
                        aria-label={retailer?.zone}
                        className="area-map d-block gtmClickNavigation"
                        href={
                          urlPrefix +
                          model.slash +
                          redirectUrl +
                          (retailer.level === null ||
                            (retailer.level === "" && retailer.zone === null) ||
                            retailer.zone === ""
                            ? ""
                            : " #topBar")
                        }
                      >
                        {(retailer.level === null ||
                          retailer.level === "") && (retailer.zone === null ||
                            retailer.zone === "")
                          ? ""
                          : retailer.level + " " + retailer.zone}
                      </a>
                      <a
                        aria-label={retailer?.offersCount}
                        className={
                          "offer-tap loyalty-text position-absolute fw-bold d-block"
                        }
                        href={
                          urlPrefix +
                          model.slash +
                          redirectUrl +
                          (retailer.offersCount > 0
                            ? "#" + props.eventOffers
                            : "")
                        }
                      >
                        {retailer.offersCount > 0 && (
                          <>
                            <span
                              className={
                                "picto align-middle" +
                                (retailer.vipIconDisplay === true
                                  ? " icon-vip-card"
                                  : "")
                              }
                            ></span>
                            <span>
                              {retailer.offersCount +
                                " " +
                                retailer.offersDictionary}{" "}
                            </span>
                          </>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </>
    );
  } else if (
    model !== null && model !== undefined && model !== "" &&
    (model.displaystoreitems === null || model.displaystoreitems.length < 1)
  ) {
    return (
      <div className="picto-wrap emptystate">
        <span className="picto icon-ico-ph-shops"></span>
        <p className="text-uppercase">{model.brandsEmptyText}</p>
      </div>
    );
  } else {
    return <></>;
  }
}
