import React from 'react';
import { GoBackToMenu } from "../MyAccount/MyAccountScripts";

export default function BasicBenefitsSmartParking({ fields }) {
  if (fields !== undefined && fields !== null && fields !== "") {
    return (
      <>
        <div className="account-tab-pane1 rounded-0 bg-white p-5 pt-lg-4">
          <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
            <div className="container modalContainer p-0 ">
              <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                <div className="col-2 text-center px-0">
                  <a aria-label={fields?.benefitsAndServicesTitle} className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                    href="javascript:void(0);" data-testid="gobacktomenu" onClick={() => { GoBackToMenu("my-benefits-and-services") }}></a>
                </div>
                <div className="col-8 p-0 text-center">
                  <div className=" text-center benefits-title ">
                    {fields.benefitsAndServicesTitle}
                  </div>
                  <div className=" text-center benefits-subtitle ">
                    {fields.centerLabel}
                  </div>
                </div>
                <div className="col-2 text-center px-0">
                </div>
              </nav>
            </div>
          </div>
          <div
            className="d-flex d-lg-block d-none d-xl-block justify-content-center  account-center-name">
            {fields.centerLabel}</div>
          <div
            className="d-flex d-lg-block d-none d-xl-block justify-content-center  account-center-subtitle">
            {fields.benefitsAndServicesTitle}</div>
          <div className="d-flex my-3 mob-pd text-black ps-1">
            <span className="picto icon-vip-card account-icon-spacing  fs-3"></span>
            <span className="d-flex ms-2  account-card-header align-items-center">{fields.loyaltyTitle}</span>
          </div>
          <div className="row mob-pd equal">
            <div className="col-sm-6 d-flex pb-3">
              <a aria-label={fields?.benefitsAndServicesTitle}
                className="card card-inverse w-100 account-box-shadow border-0 rounded-0 text-decoration-none">
                <div className="card-block p-3">
                  <div className="d-flex justify-content-center">
                    {<img src={fields.loyaltyPillar.offerPicture} alt={fields.benefitsAndServicesTitle}
                      className="account-basic-icon img-fluid" />}
                  </div>
                  <p className="card-text mt-2">{fields.loyaltyPillar.offerPillarDescription}
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 d-flex pb-3">
              <a aria-label={fields?.benefitsAndServicesTitle}
                className="card card-inverse w-100 account-box-shadow  border-0 rounded-0 text-decoration-none">
                <div className="card-block p-3">
                  <div className="d-flex justify-content-center">
                    {<img src={fields.loyaltyPillar.servicePicture} alt={fields.benefitsAndServicesTitle}
                      className="account-basic-icon img-fluid" />}
                  </div>
                  <p className="card-text mt-2">{fields.loyaltyPillar.servicePillarDescription}
                  </p>
                </div>
              </a>
            </div>
            <div className="col-sm-6 d-flex pb-3">
              <a aria-label={fields?.benefitsAndServicesTitle}
                className="card card-inverse w-100 account-box-shadow  border-0 rounded-0 text-decoration-none">
                <div className="card-block p-3">
                  <div className="d-flex justify-content-center">
                    {<img src={fields.loyaltyPillar.giftsPicture} alt={fields.benefitsAndServicesTitle}
                      className="account-basic-icon img-fluid" />}
                  </div>
                  <p className="card-text mt-2">{fields.loyaltyPillar.giftsPillarDescription}
                  </p>

                </div>
              </a>
            </div>
            <div className="col-sm-6 d-flex pb-3">
              <a aria-label={fields?.benefitsAndServicesTitle}
                className="card card-inverse w-100 account-box-shadow  border-0 rounded-0 text-decoration-none">
                <div className="card-block p-3">
                  <div className="d-flex justify-content-center">
                    {<img src={fields.loyaltyPillar.eventPicture} alt={fields.benefitsAndServicesTitle}
                      className="account-basic-icon img-fluid" />}
                  </div>
                  <p className="card-text mt-2">{fields.loyaltyPillar.eventPillarDescription}
                  </p>

                </div>
              </a>
            </div>
          </div>
          <button className="d-flex justify-content-center account-discover-button">{fields.discoverAdvantages}
          </button>
        </div>
      </>
    );
  }
  else {
    return <></>;
  }
}
