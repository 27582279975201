import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function PhTitleComponent({ rendering }) {
  if (rendering) {
    return (
      <div className="anchor-navigation no-logo d-none d-sm-block">
        <div className="container">
          <Placeholder name="ph_title" rendering={rendering} />
        </div>
      </div>
    )
  } else {
    return <></>;
  }
}
