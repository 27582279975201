import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

export default function RetailerDetails({ fields }) {
  if (fields !== null && fields !== undefined && fields !== "" &&
    fields.detailModel !== null && fields.detailModel !== undefined && fields.detailModel !== "") {
    var model = fields.detailModel;
    if (model !== null) {
      var desc = { value: model.retailerInfo.storeProfile };
      return (
        <section className="stores retailer-detail">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-3 mb-5">
                <div className="card rounded-0 retail-block h-auto bg-transparent border-0">
                  <div className="img-wrap bg-white border-8">
                    <img
                      src={model.logo}
                      className="img-fluid"
                      alt={model.retailerInfo.name}
                    />
                  </div>
                  <div className="card-body border-0 px-0 text-start">
                    <div className="retail-title text-uppercase m-0">
                      {model.retailerInfo.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9 mb-5">
                <div className="col-12 col-md-12 store-detail p-0 mb-4 d-lg-flex">
                  <div className="col-12 col-md-7 about-store p-0 pe-lg-4 mb-5">
                    <div className="sub-title ">{model.about}</div>
                    <div className="description ">
                      <RichText field={desc} />
                    </div>
                  </div>
                  <div className="col-12 col-md-5 store-categories p-0 ps-lg-4">
                    <div className="sub-title ">{model.categories}</div>
                    <ul className="list-unstyled ">
                      {model.categoryInfo.map((category, index) => {
                        return (
                          <li key={index}>
                            <a href={category.url} aria-label={category?.name}>{category.name}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="store-location">
                  <div className="sub-title">
                    <span className="picto icon-location"></span>
                    {model.location}
                  </div>
                  <div className="locations">
                    <div className="location-header d-flex">
                      <div className="centre-name w-50">{model.center_Name}</div>
                      <div className="position w-50">{model.position}</div>
                    </div>

                    <div className="location-list">
                      {model.retailerLocation.map((location, index) => {
                        return (
                          <a className="list-item d-flex" href={location.url} key={index}>
                            <div className="centre-name w-50">
                              {location.centerName}
                            </div>
                            <div className="position w-50">
                              {location.location}
                              <span className="picto icon-go-right-arrow"></span>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  } else {
    return <></>;
  }
}
