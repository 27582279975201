import React from 'react';
import { baseModel } from '../../RouteHandler.js';
import LPDiscoverMyAdvantages from '../LPDiscoverMyAdvantages';

export default function GlobalLoyaltyProgram({ fields }) {
  if (fields !== undefined && fields !== null && fields.loyaltyProgram !== null && fields.loyaltyProgram !== undefined && fields.loyaltyProgram !== "") {
    return (
      <div className="bg-gray-loyalty-program pb-4">
        <div className="program-loyalty">
          <div className="title text-start">{fields.loyaltyProgram.pillarItem?.lpTitle}</div>
          {(!fields.loyaltyProgram.isAuthenticated) &&
            <div className="col-12 mob-margin margin-loyalty-program loyalty-program-box-shadow became-member bg-white text-center">
              <div className="title-became-member text-uppercase my-3">{fields.loyaltyProgram.memberTitle}</div>
              <div className="desc px-3">{fields.loyaltyProgram.memberDescription}</div>
              <div className="align-items-center d-flex justify-content-center ">
                <a href={baseModel.connectRegisterUrl} aria-label={baseModel.connectRedirectUrl} className="btn btn-loyalty-program btn-dark my-3 align-items-center"
                >{fields.loyaltyProgram.signUpText}</a>
              </div>
              <div className="mb-3"><a href={baseModel.connectRedirectUrl} aria-label={baseModel.connectRedirectUrl} className="note text-decoration-underline">{fields.loyaltyProgram.loginText}</a></div>
            </div>
          }
          <div className="bg-gray-loyalty-program">
            <div className="loyalty-program-cards equal mb-2">

              <div className={"col-lg-6 ps-0 mob-margin margin-loyalty-program loyalty-prog-pr loyalty-card d-flex" + (((fields.loyaltyProgram.pillarItem?.offerPillarDescription === null || fields.loyaltyProgram.pillarItem?.offerPillarDescription === "") &&
                (fields.loyaltyProgram.pillarItem?.offerTitle === null || fields.loyaltyProgram.pillarItem?.offerTitle === "") && (fields.loyaltyProgram.pillarItem?.offerPicture === null || fields.loyaltyProgram.pillarItem?.offerPicture === "")) ? " d-none" : "")}>
                <a className="bg-white no-underline w-100 loyalty-program-box-shadow" aria-label={fields?.loyaltyProgram?.pillarItem?.offerTitle}>
                  <div className="card-block p-3">
                    <div className="d-flex justify-content-center">
                      <img src={fields.loyaltyProgram.pillarItem?.offerPicture} alt={fields.loyaltyProgram.pillarItem?.offerTitle === null || fields.loyaltyProgram.pillarItem?.offerTitle === ""}
                        className="account-basic-icon img-fluid" />
                    </div>
                    <div className="picto-lable text-center text-uppercase">{fields.loyaltyProgram.pillarItem?.offerTitle}</div>
                    <p className="text-center loyalty-program-card-desc mx-2 mt-2">{fields.loyaltyProgram.pillarItem?.offerPillarDescription}</p>

                  </div>
                </a>
              </div>
              <div className={"col-lg-6 pe-0 mob-margin margin-loyalty-program loyalty-prog-pl loyalty-card d-flex " + (((fields.loyaltyProgram.pillarItem?.servicePillarDescription === null || fields.loyaltyProgram.pillarItem?.servicePillarDescription === "") &&
                (fields.loyaltyProgram.pillarItem?.serviceTitle === null || fields.loyaltyProgram.pillarItem?.serviceTitle === "") && (fields.loyaltyProgram.pillarItem?.servicePicture === null || fields.loyaltyProgram.pillarItem?.servicePicture === "")) ? " d-none" : "")}>
                <a className="bg-white no-underline w-100 loyalty-program-box-shadow" aria-label={fields?.loyaltyProgram?.pillarItem?.serviceTitle}>
                  <div className="card-block p-3">
                    <div className="d-flex justify-content-center">
                      <img src={fields.loyaltyProgram.pillarItem?.servicePicture} alt={fields.loyaltyProgram.pillarItem?.serviceTitle === null || fields.loyaltyProgram.pillarItem?.serviceTitle === ""}
                        className="account-basic-icon img-fluid" />
                    </div>
                    <div className="picto-lable text-center text-uppercase">{fields.loyaltyProgram.pillarItem?.serviceTitle}</div>
                    <p className="text-center loyalty-program-card-desc mx-2 mt-2">{fields.loyaltyProgram.pillarItem?.servicePillarDescription}
                    </p>

                  </div>
                </a>
              </div>
              <div className={"col-lg-6 ps-0 mob-margin margin-loyalty-program loyalty-prog-pr loyalty-card d-flex " + (((fields.loyaltyProgram.pillarItem?.eventPillarDescription === null || fields.loyaltyProgram.pillarItem?.eventPillarDescription === "") &&
                (fields.loyaltyProgram.pillarItem?.eventTitle === null || fields.loyaltyProgram.pillarItem?.eventTitle === "") && (fields.loyaltyProgram.pillarItem?.eventPicture === null || fields.loyaltyProgram.pillarItem?.eventPicture === "")) ? " d-none" : "")}>
                <a className="bg-white no-underline w-100 loyalty-program-box-shadow" aria-label={fields?.loyaltyProgram?.pillarItem?.serviceTitle}>
                  <div className="card-block p-3">
                    <div className="d-flex justify-content-center">
                      <img src={fields.loyaltyProgram.pillarItem?.eventPicture} alt={fields.loyaltyProgram.pillarItem?.eventTitle === null || fields.loyaltyProgram.pillarItem?.eventTitle === ""}
                        className="account-basic-icon img-fluid" />
                    </div>
                    <div className="picto-lable text-center text-uppercase">{fields.loyaltyProgram.pillarItem?.eventTitle}</div>
                    <p className="text-center loyalty-program-card-desc mx-2 mt-2">{fields.loyaltyProgram.pillarItem?.eventPillarDescription}
                    </p>

                  </div>
                </a>
              </div>
              <div className={"col-lg-6 pe-0 mob-margin margin-loyalty-program loyalty-prog-pl loyalty-card d-flex " + (((fields.loyaltyProgram.pillarItem?.giftsPillarDescription === null || fields.loyaltyProgram.pillarItem?.giftsPillarDescription === "") &&
                (fields.loyaltyProgram.pillarItem?.giftsTitle === null || fields.loyaltyProgram.pillarItem?.giftsTitle === "") && (fields.loyaltyProgram.pillarItem?.giftsPicture === null || fields.loyaltyProgram.pillarItem?.giftsPicture === "")) ? " d-none" : "")}>
                <a className="bg-white no-underline w-100 loyalty-program-box-shadow" aria-label={fields?.loyaltyProgram?.pillarItem?.serviceTitle}>
                  <div className="card-block p-3">
                    <div className="d-flex justify-content-center">
                      <img src={fields.loyaltyProgram.pillarItem?.giftsPicture} alt={fields.loyaltyProgram.pillarItem?.giftsTitle === null || fields.loyaltyProgram.pillarItem?.giftsTitle === ""}
                        className="account-basic-icon img-fluid" />
                    </div>
                    <div className="picto-lable text-center text-uppercase">{fields.loyaltyProgram.pillarItem?.giftsTitle}</div>
                    <p className="text-center loyalty-program-card-desc mx-2 mt-2">{fields.loyaltyProgram.pillarItem?.giftsPillarDescription}
                    </p>

                  </div>
                </a>
              </div>
            </div>

            {(fields.loyaltyProgram.isAuthenticated && fields.loyaltyProgram.discoverMore !== null
              && fields.loyaltyProgram.discoverMore !== undefined && fields.loyaltyProgram.discoverMore !== "") ?
              (
                <>
                  <LPDiscoverMyAdvantages fields={fields.loyaltyProgram.discoverMore} />
                </>
              ) :
              (<></>)
            }
          </div>
        </div>
      </div>
    );
  }
  else {
    return <></>;
  }

}

