import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function PhTitleDescriptionComponent({ rendering }) {
  if (rendering) {
    return (
      <div className="container">
        <Placeholder name="titledesc01" rendering={rendering} />
      </div>

    )
  } else {
    return <></>;
  }
}

