import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
export default function RichTextComponent({ fields }) {
  if (fields) {
    return (
      <section className="richtext" >
        <div className="container">
          {(fields["Rich text"] !== null && fields["Rich text"].value !== "" && fields["Rich text"].value !== null) &&
            <RichText field={fields["Rich text"]} />
          }
        </div>
      </section >
    )
  } else {
    return <></>;
  }
}