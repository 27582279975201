import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';


export default function PhListImageTitleLinkDes({ rendering }) {

  if (rendering) {
    return (
      <div className="container">
        <Placeholder name="ph_listimgtextlink" rendering={rendering} />
      </div>
    )

  } else {

    return <></>;

  }

}