import React from 'react';

export default function AccessTotheCenterAddress({ fields }) {
  if (fields.accessToTheCenterAddress !== null) {
    return (
      <div className="address-block" id="access-center">
        <h2 className="content-title text-uppercase text-center mt-0">{fields.accessToTheCenterAddress.accessToTheCenter}</h2>
        <div className="address-wrap">
          <span className="picto icon-center"></span>
          <div className="address-content">
            <div className="center-name text-uppercase fw-bold">
              {fields.accessToTheCenterAddress.centerName}
            </div>
            <div className="center-address">
              {fields.accessToTheCenterAddress.street_Address}<br />
              {fields.accessToTheCenterAddress.suburbs}<br />
              {fields.accessToTheCenterAddress.state} {fields.accessToTheCenterAddress.postal_Code}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
