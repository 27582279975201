import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import { MetaNoReferer } from "../Js-CommonFunctions";

export default function GenericThreeColumn({ fields }) {
  useEffect(() => {
    MetaNoReferer();
  }, []);
  var Model = fields.genericitem;
  if (fields !== null && Model != null && Model.items !== null) {
    return (
      <section className="generic-three-block bg-2">
        <div className="container">
          <div className="row">
            {Model.items.map((genericItem, index) => {
              if (genericItem.image !== null && genericItem.image?.value?.src !== null && genericItem.image?.value?.src !== "" && genericItem.title !== "" && genericItem.title !== null && genericItem.title?.value !== "" && genericItem.title?.value !== null) {
                return (
                  <>

                    <div key={index} className="col-12 col-sm-6 col-md-4 pe-lg-3 mb-3 ps-lg-0">
                      <div className="col grid-item grid-withpicture position-relative bg-white border-8 shadow-none">
                        <div className={"position-relative " + (Model.displayLogo ? "grid-logo" : "grid-picture") + " m-0 p-0 text-center"}>
                          {(genericItem.image != null && genericItem.image?.value?.src != null && genericItem.image?.value?.src !== "") &&
                            Model.isExperienceEditor ?
                            (<Image className="border-8" field={genericItem.image} imageParams={{ mh: 350 }} />) :
                            (<img src={Model.defaultImage} data-alt={genericItem.image?.value?.src} className="img-fluid border-8" alt={genericItem.image?.value?.alt} title={genericItem.image?.value?.alt} />)

                          }
                        </div>
                        <div className="grid-content ">
                          {(genericItem.title !== "" && genericItem.title != null) &&
                            <RichText field={genericItem.title} tag="h3" className="grid-title text-start" />
                          }
                          {(genericItem.description !== "" && genericItem.description != null) &&

                            < div className="description text-start">
                              <RichText field={genericItem.description} />
                            </div>
                          }
                          {(genericItem.link?.value?.url !== "" && genericItem.link?.value?.url !== null && genericItem.link?.value?.text !== "" && genericItem.link?.value?.text !== null) &&
                            <div className="text-start">
                              <a href={genericItem.link?.value?.url} aria-label={genericItem.link?.value} target={genericItem.link?.value?.target} className="grid-new-cta">{genericItem.link?.value?.text}</a>
                              <span className='picto icon-arrow-square-red-right-rw align-middle px-0 fs-5' />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            })
            }
          </div>
        </div >
      </section >
    )
  }
  else {
    return <></>;
  }
}
