import React, { useEffect } from "react";
import store from "../../redux/store.js";
import { readCookie, fngtmModuleclick, isTokenExpired, GetRenewedAccessToken } from "../Js-CommonFunctions.js";
import axios from "axios";


export default function NotifyMeEvent({ fields, item }) {
  const state = store.getState();
  var userEmail = state?.user?.email;

  useEffect(() => {
    if (fields !== null && fields !== undefined && fields !== "" &&
      userEmail !== null && userEmail !== "" && userEmail !== undefined) {
      document.getElementById("email").value = userEmail;
      document.getElementById("email").readOnly = true;
    }
  }, []);

  function showErrorMessage() {
    if (document.getElementById("notifyFailure")) { document.getElementById("notifyFailure").classList.remove("d-none"); }
    if (document.getElementById("emailLabel")) { document.getElementById("emailLabel").classList.add("d-none"); }
    if (document.getElementById("emailInput")) { document.getElementById("emailInput").classList.add("d-none"); }
    if (document.getElementById("btnConfirm")) { document.getElementById("btnConfirm").classList.add("d-none"); }
    if (document.getElementById("btnRetry")) { document.getElementById("btnRetry").classList.remove("d-none"); }
  }

  function ShowSuccessMessage(fields, emailid) {
    var successmessage = fields.successMessage.replace("{EMAIL}", "{" + emailid + "}");
    var left = successmessage.indexOf("{");
    var right = successmessage.indexOf("}") + 1;
    var strLength = successmessage.length
    var rightString = successmessage.substring(right, strLength);
    var leftString = successmessage.substring(0, left);
    var middleString = successmessage.substring(left, right);
    var strMiddle = middleString.replace("{", "");
    var strMid = strMiddle.replace("}", "");

    var maindiv = document.getElementById("notifySuccessMessage");
    var a = document.createElement("DIV");
    a.setAttribute("class", "notify-success-wb d-flex justify-content-between my-3 p-0");
    a.setAttribute("id", "notifySuccess");
    var b = document.createElement("DIV");
    b.setAttribute("class", "d-flex align-items-center");
    var c = document.createElement("SPAN");
    c.setAttribute("class", "icon-icon-notify-success success-icon fs-3 me-2");
    var d = document.createElement("DIV");
    d.setAttribute("class", "d-flex flex-column align-items-start");
    var e = document.createElement("DIV");
    e.setAttribute("class", "notify-success-content");
    var f = document.createElement("SPAN");
    f.innerHTML = leftString;
    e.appendChild(f);
    var g = document.createElement("SPAN");
    g.setAttribute("class", "notify-success-bold");
    g.innerHTML = strMid;
    e.appendChild(g);
    var h = document.createElement("SPAN");
    h.innerHTML = rightString;
    e.appendChild(h);
    d.appendChild(e);
    b.appendChild(c);
    b.appendChild(d);
    a.appendChild(b);
    maindiv.appendChild(a);
    if (document.getElementById("emailLabel")) { document.getElementById("emailLabel").classList.add("d-none"); }
    if (document.getElementById("emailInput")) { document.getElementById("emailInput").classList.add("d-none"); }
    if (document.getElementById("btnClose")) { document.getElementById("btnClose").classList.remove("d-none"); }
    if (document.getElementById("linkCancel")) { document.getElementById("linkCancel").classList.add("d-none"); }
    if (document.getElementById("btnConfirm")) { document.getElementById("btnConfirm").classList.add("d-none"); }
  }

  function RetryData() {
    if (document.getElementById("emailLabel")) { document.getElementById("emailLabel").classList.remove("d-none"); }
    if (document.getElementById("emailInput")) { document.getElementById("emailInput").classList.remove("d-none"); }
    if (document.getElementById("btnConfirm")) { document.getElementById("btnConfirm").classList.remove("d-none"); }
    if (document.getElementById("btnRetry")) { document.getElementById("btnRetry").classList.add("d-none"); }
    if (document.getElementById("notifyFailure")) { document.getElementById("notifyFailure").classList.add("d-none"); }
  }

  function FnSubmitData(fields, item)
  {
    if (isTokenExpired()) {
      var args = [fields, item];
      GetRenewedAccessToken(SubmitData, args);
    } else {
      SubmitData(fields, item, state.user.visitorId, state.user.accessToken, state.user.refreshToken);
    }
  }

  function SubmitData(fields, item, visitorId, accessToken, refreshToken) {
    var validation = false;
    if (userEmail !== null && userEmail !== "" && userEmail !== undefined) {
      var validation = true;
    }
    else {
      validation = emailvalidate();
    }
    if (validation) {
      fngtmModuleclick(item, "email_notification", "Carousel");
      var emailid = document.getElementById("email").value;
      var url = fields.subscriptionLink?.value?.url + "&email=" + emailid;
      var id = readCookie("UUID");
      if (id === null || id === undefined || id === "") {
        window.location.href = url;
      }
      else {
        axios({
          method: "post",
          url:
            "/api/sitecore/VisitorApi/GetNotifyMepopin",
          data: {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken,
          },
          async: false,
        })
          .then(function (response) {
            if (response !== null && response !== undefined && response !== "" && response.data === "Success") {
              ShowSuccessMessage(fields, emailid);
            }
            else if (response !== null && response !== undefined && response !== "" && response.data === "Failed") {
              window.location.href = url;
            }
            else if (response.status !== 200) {
              showErrorMessage();
            }
          })
          .catch(function (error) {
            showErrorMessage();
            console.log(error);
          });
      }
    }
  }

  function emailvalidate() {

    var emailid = document.getElementById("email").value;
    if (emailid == "") {
      document.getElementById("email-error").innerHTML = document
        .getElementById("email-error")
        ?.getAttribute("data-validatormsg2")
        ?.valueOf();
      document.getElementById("email-error").style.display = "block";
      document.getElementById("email").classList.add("error");
      return false;
    }
    else {
      document.getElementById("email-error").innerHTML = "";
      var atpos = emailid?.indexOf("@");
      var dotpos = emailid?.lastIndexOf(".");
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailid?.length) {
        document.getElementById("email-error").innerHTML = document
          .getElementById("email-error")
          .getAttribute("data-validatormsg1")
          .valueOf();
        document.getElementById("email-error").style.display = "block";
        document.getElementById("email").classList.add("error");
        return false;
      }
    }
    document.getElementById("email").classList.remove("error");
    return true;
  }

  function SetDefaultValues() {
    if (document.getElementById("email")) {
      document.getElementById("email").classList.remove("error");
      if (userEmail === null || userEmail === "" || userEmail === undefined) { document.getElementById("email").value = ""; }

    }
    if (document.getElementById("email-error")) { document.getElementById("email-error").innerHTML = ""; }
    if (document.getElementById("notifySuccess")) {
      document.getElementById("notifySuccess").remove();
    }
    if (document.getElementById("emailLabel")) { document.getElementById("emailLabel").classList.remove("d-none"); }
    if (document.getElementById("emailInput")) { document.getElementById("emailInput").classList.remove("d-none"); }
    if (document.getElementById("btnClose")) { document.getElementById("btnClose").classList.add("d-none"); }
    if (document.getElementById("linkCancel")) { document.getElementById("linkCancel").classList.remove("d-none"); }
    if (document.getElementById("btnConfirm")) { document.getElementById("btnConfirm").classList.remove("d-none"); }
    if (document.getElementById("btnRetry")) { document.getElementById("btnRetry").classList.add("d-none"); }
    if (document.getElementById("notifyFailure")) { document.getElementById("notifyFailure").classList.add("d-none"); }

  }

  if (fields !== null && fields !== undefined && fields !== "") {
    return (

      <div className=" modal-dialog modal-dialog-centered" id="notifymePopin" role="document">
        <div className="modal-content">
          <div className="modal-header justify-content-end">
            <button type="button" className="picto icon-close-circle-rw border-0 fs-3 bg-white text-black" data-bs-dismiss="modal"
              aria-label="Close" data-testid="setDefaultValuesClick" onClick={(e) => { SetDefaultValues() }}>
            </button>
          </div>
          <div className="modal-body text-center">
            <div className="popin-wrap">
              <div className="notify">
                <div className="notify-sub-head text-uppercase d-flex align-items-left">{fields?.notifyMeTitle}</div>
              </div>
              <p className="notify-modal-content text-uppercase d-flex align-items-left">{fields?.registerToNewsletter}</p>
              <div className="d-flex align-items-left notify-modal-label " id="emailLabel">{fields?.enterEmail}</div>

              <div className="form-group " id="emailInput">
                <input className="form-control profile-label-text profile-label-name mt-1 " type="text"
                  name="emailaddress" id="email" placeholder={fields?.email_Placeholder} autoFocus="" />
                <span id="email-error" data-validatormsg1={fields?.validationValidEmail}
                  data-validatormsg2={fields?.validationEmptyEmail}
                  className="error"></span>
              </div>


              <div id="notifySuccessMessage">

              </div>

              <div className="notify-failure-wb d-none d-flex justify-content-between my-3" id="notifyFailure">
                <div className="d-flex align-items-center ">
                  <span className="icon-icon-notify-cancel warning-icon fs-3 mx-2">
                  </span>
                  <div className="d-flex flex-column align-items-start">
                    <div className="notify-failure-content">{fields?.errorMessage} </div>
                  </div>
                </div>

              </div>

              <a className="notifty-modal-button btn-dark center-block" aria-label="Close"
                href="javascript:void(0);" id="btnConfirm" data-testid="submitDataClick" onClick={(e) => { FnSubmitData(fields, item) }}>{fields?.confirm}</a>
              <a className="notifty-modal-button d-none btn-dark center-block" aria-label="Close"
                href="javascript:void(0);" id="btnRetry" data-testid="retryDataClick" onClick={(e) => { RetryData() }}>{fields?.retry}</a>
              <a className="notifty-modal-button d-none btn-dark center-block" aria-label="Close" data-bs-dismiss="modal"
                href="javascript:void(0);" id="btnClose" data-testid="setDefaultValuesCancelBtnClick" onClick={(e) => { SetDefaultValues() }}>{fields?.close}</a>
              <a className="center-block link-text account-profile-delete text-underline" data-bs-dismiss="modal"
                aria-label="Close" id="linkCancel" href="javascript:void(0);" data-testid="setDefaultValuesCancelLinkClick" onClick={(e) => { SetDefaultValues() }}>{fields?.cancel}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return <></>;
  }
}
