import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function ServicesAndExperiences({ fields, isWestfield, isAccountPage }) {

  let twoSliderServices = [];
  let threeSliderServices = [];
  let desc = { value: '' };


  function addSlider() {  
     if (fields !== undefined && fields !== "" && fields !== null &&
      fields.serviceItems !== null && fields.serviceItems !== undefined && fields.serviceItems !== "" &&
      fields.serviceItems.communityList !== null && fields.serviceItems.communityList !== undefined && fields.serviceItems.communityList !== "") {

      if (fields.serviceItems.communityList?.length <= 3) {
        fields.serviceItems.communityList.map((item, index) => {
          threeSliderServices.push(item);
        });
      }
      else {
        fields.serviceItems.communityList.map((item, index) => {
          if (index <= 1) {
            twoSliderServices.push(item);
          }
          else {
            threeSliderServices.push(item);
          }
        });
      }
    }
  }

  if (fields !== undefined && fields !== "" && fields !== null &&
    fields.serviceItems != undefined && fields.serviceItems != null && fields.serviceItems != "") {

    desc.value =  fields?.serviceItems?.description; 
    addSlider();
    return (
      <>
        <section className={"loyalty-block-wc p-0 bg-white " + (isAccountPage ? "loyalty-block-account-page" : "")}>
          <div className={"loyalty-discover-world-container " + (isAccountPage ? "py-2" : "container py-lg-5")}>
            <div className={"loyalty-discover-world-content d-flex justify-content-center py-2 py-lg-2 " + (isAccountPage ? "account-loyalty-discover-world-content text-black" : "")}>
              {<RichText field={fields.serviceItems.heading} />}</div>
            <div className={"loyalty-discover-world-subcontent d-flex justify-content-center py-2 py-lg-2 " + (isAccountPage ? "account-loyalty-discover-world-subcontent text-black" : "")}><Text field={desc} /></div>
          </div>

          <div className={"loyalty-slider-container " + (isAccountPage ? "py-2" : "container pb-5 pb-lg-5")}>
            <div className="loyalty-two-slider ">
              <div className="row mx-0">
                {twoSliderServices?.map((item, index) => {
                  return (
                    (
                      <div key={index} className="col-md-6 p-3 p-lg-2 d-flex">
                        <div className="card card-inverse w-100 pb-3  rounded-3 text-decoration-none">
                          <div className="card-block rounded-3">
                            <div className="d-flex justify-content-center rounded-3 position-relative align-items-center">
                              <span className={"position-absolute loyalty-card-title " + (isAccountPage ? "account-loyalty-card-title" : "")}>{item.communityTitle}</span>
                              {(isAccountPage ?
                                (<>
                                  {isWestfield ?
                                    (
                                      <div className="w-100 loyalty-card-title-img loyalty-card-branded-color account-loyalty-card-title-img"></div>
                                    ) :
                                    (
                                      <div className="w-100 loyalty-card-title-img loyalty-card-unbranded-color account-loyalty-card-title-img"></div>
                                    )}
                                </>
                                ) :
                                (
                                  <div className={"w-100 loyalty-card-title-img "}></div>
                                )
                              )
                              }

                            </div>
                            <div className="d-flex justify-content-center rounded-3 align-items-center">
                              <img src={item.communityImage?.value?.src} alt=""
                                loading="lazy" className="w-100 loyalty-program-card-image"></img>
                            </div>
                            {<RichText field={item?.description} />}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div >
            <div className="loyalty-three-slider ">
              <div className="row mx-0 d-flex justify-content-center">
                {threeSliderServices?.map((item, index) => {
                  return (
                    (
                      <div key={index} className="col-md-4 p-3 p-lg-2 d-flex">
                        <div className="card card-inverse w-100 pb-3  rounded-3 text-decoration-none">
                          <div className="card-block rounded-3">
                            <div className="d-flex justify-content-center rounded-3 position-relative align-items-center">
                              <span className={"position-absolute loyalty-card-title " + (isAccountPage ? "account-loyalty-card-title" : "")}>{item.communityTitle}</span>
                              {(isAccountPage ?
                                (<>
                                  {isWestfield ?
                                    (
                                      <div className="w-100 loyalty-card-title-img loyalty-card-branded-color account-loyalty-card-title-img"></div>
                                    ) :
                                    (
                                      <div className="w-100 loyalty-card-title-img loyalty-card-unbranded-color account-loyalty-card-title-img"></div>
                                    )}
                                </>
                                ) :
                                (
                                  <div className="w-100 loyalty-card-title-img" ></div>
                                )
                              )
                              }
                            </div>
                            <div className="d-flex justify-content-center rounded-3">
                              <img src={item?.communityImage?.value?.src} alt=""
                                loading="lazy" className={"w-100 loyalty-program-card-image"}></img>
                            </div>
                            {<RichText field={item?.description} />}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
         
          </div >
        </section>
      </>
    )
  }
  else {
    return (<></>)
  }
}

