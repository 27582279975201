import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';
import axios from 'axios';

export default function AllServices({ fields }) {

  const [allServiceData, setAllServiceData] = useState();

  let workerCenter = useSelector((state) => state.user.workerCenter);


  useEffect(() => {
    let isWorker =
      workerCenter !== undefined &&
      workerCenter !== '' &&
      workerCenter.split(',').includes(baseModel.pC_Code);

    if (isWorker) {
      var loader = document.getElementById("dotloader")
      if (loader)
        loader.classList.remove("d-none");
      axios({
        method: "post",
        url: "/api/sitecore/ServiceApi/GetAllServices",
        data: {
          ursite: baseModel.urContextSite,
          urlang: baseModel.contextLang,
          isWorker: isWorker
        },
        async: false,
      })
        .then(function (response) {
          if (loader)
            loader.classList.add("d-none");
          if (response.data !== "") {
            setAllServiceData(response.data);

          }
          // else {
          //   loader.classList.add("d-none");
          // }
        })
        .catch(function (error) {
          console.log(error);
          if (loader)
            loader.classList.add("d-none");
        });

    }
    else {
      if (fields.allService != null && fields.allService.length > 0)
        setAllServiceData(fields.allService)
    }
  }, []);

  if (fields !== null && fields !== "" && fields !== undefined > 0) {
    return (
      <>
        {allServiceData !== null && allServiceData?.length > 0 && allServiceData.map((groups, index1) => {
          return (
            <>
              {groups !== null && groups.key !== '' && (
                <>
                  <section key={index1} className={"all-services " + (allServiceData.isNewBranded ? " all-services-new" : "")} id={groups.id}>
                    <div className="container">
                      <h2 className={allServiceData.isNewBranded ? "page-title" : "content-title text-uppercase"}>{groups.key}</h2>
                      {groups.topService.length > 0 && (
                        <div className="row top-services">
                          {groups.topService.map((service, index) => {
                            return (
                              service.image !== '' && groups.displayPictureForServices ? (
                                <div key={index} className="col-12 col-lg-4 mb-3">
                                  <div className="card border-8 bg-transparent shadow-none">
                                    <img
                                      src={service.image}
                                      className="card-img-top border-8"
                                      alt={service.serviceData.shortTitle}
                                    />
                                    <div className="card-body text-start px-0">
                                      <a
                                        href={service.serviceUrl}
                                        aria-label={service.serviceData?.shortTitle}
                                        className="d-flex align-items-center text-start stretched-link"
                                      >
                                        <span
                                          className={
                                            'picto ' +
                                            (service.serviceData.pictoOn !== ''
                                              ? service.serviceData.pictoOn
                                              : 'icon-servicesaspictosservices')
                                          }
                                        ></span>
                                        <div className="title">{service.serviceData.shortTitle}</div>
                                      </a>
                                      {service.isServiceLoyalty === true && (
                                        <div className="label loyalty-text mt-1">
                                          <span className="align-middle picto icon-vip-card"></span>
                                          <div className="text d-inline-block text-uppercase fw-bold">
                                            {service.elligibility_Text}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-12 col-lg-4 mb-3">
                                  <div className="card border-8 bg-transparent shadow-none">
                                    <div className="service-picto picto-block">
                                      <span
                                        className={
                                          'picto ' +
                                          (service.serviceData.pictoOn !== ''
                                            ? service.serviceData.pictoOn
                                            : 'icon-servicesaspictosservices')
                                        }
                                      ></span>
                                    </div>
                                    <div className="card-body text-start px-0">
                                      <a
                                        href={service.serviceUrl}
                                        aria-label={service.serviceData?.shortTitle}
                                        className="d-flex align-items-center text-start stretched-link"
                                      >
                                        <div className="title">{service.serviceData.shortTitle}</div>
                                      </a>
                                      {service.isServiceLoyalty === true && (
                                        <div className="label loyalty-text mt-1">
                                          <span className="align-middle picto icon-vip-card"></span>
                                          <div className="text d-inline-block text-uppercase fw-bold">
                                            {service.elligibility_Text}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}
                      {groups.nontopService.length > 0 && (
                        <div className="row">
                          {groups.nontopService.map((service, nontopServiceindex) => {
                            return (
                              <div key={nontopServiceindex} className="col-12 col-lg-3">
                                <a
                                  href={service.serviceUrl}
                                  aria-label={service.serviceData?.shortTitle}
                                  className="d-flex align-items-center service-block mb-3"
                                >
                                  <span
                                    className={
                                      'picto ' +
                                      (service.serviceData.pictoOn != ''
                                        ? service.serviceData.pictoOn
                                        : 'icon-servicesaspictosservices')
                                    }
                                  ></span>
                                  <div className="title">{service.serviceData.shortTitle}</div>
                                </a>
                                {service.isServiceLoyalty === true && (
                                  <div className="label loyalty-text mt-1">
                                    <span className="align-middle picto icon-vip-card"></span>
                                    <div className="text d-inline-block text-uppercase fw-bold">
                                      {service.elligibility_Text}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </section>
                </>
              )}
            </>
          );
        }
        )}
        {/* <div className="loadersmall asyncloader d-none" id="loader">
          <div className="dot-spin"></div>
        </div> */}
      </>
    );
  } else {
    return <></>
  }
}
