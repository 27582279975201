import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';


export default function RichText404({ fields }) {
  if (fields !== undefined && fields.props !== null && fields.props !== undefined && fields.fromContentResolver !== undefined && fields.fromContentResolver !== null) {
    return (
      (fields.fromContentResolver[fields.props.strRichTextValue] !== null && fields.fromContentResolver[fields.props.strRichTextValue] !== undefined && fields.fromContentResolver[fields.props.strRichTextValue].value !== "" && fields.fromContentResolver[fields.props.strRichTextValue].value !== null) &&
      <RichText field={fields.fromContentResolver[fields.props.strRichTextValue]} />
    )
  } else {
    return <></>;
  }
}