import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import axios from 'axios';
import React from 'react';
import store from '../../redux/store';
import { GetRenewedAccessToken, isTokenExpired } from '../Js-CommonFunctions';

export default function TermsAndConditionPopin({ model, preferredCenters, pcCode, centerName, countryCode, legalTextId, callbackfromconsentpopin, isFromBenefits ,isFromUnsubscribe}) {

  function bindfnNewAcceptTermsAndCondition(pcCode)
  {
    if (!isFromUnsubscribe && isTokenExpired()) {
      var args = [pcCode];
      GetRenewedAccessToken(fnNewAcceptTermsAndCondition, args, false);
    } else {
      fnNewAcceptTermsAndCondition(pcCode);
    }
  }
  function fnNewAcceptTermsAndCondition(pcCode) {
    const state = store.getState();
    const accessToken = state.user.accessToken;
    const refreshToken = state.user.refreshToken;
    const visitorId = state.user.visitorId;
    //update Commercial Optin
    var createOptinURL = "/api/sitecore/VisitorApi/CreateVisitorOptin?optinType=commercial&optinChannel=email&legalTextId=" + legalTextId + "&visitorId=" + visitorId;
    axios({
      method: "post",
      url: createOptinURL,
      data: {
        accessToken: accessToken,
        refreshToken: refreshToken,
        isFromUnsubscribe : isFromUnsubscribe
      },
      async: true,
    })
      .then(function (response) {
        if (response.data[0]) {
          callbackfromconsentpopin(centerName, pcCode, countryCode, legalTextId);
          var popinClick = document.getElementById("VisitorTermsAndConditionPopinClick");
          popinClick.click();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function fnClosePopin() {
    //hide t&c popin
    var popinClick = document.getElementById("VisitorTermsAndConditionPopinClick");
    if (popinClick)
      popinClick.click();
    if (isFromBenefits) {
      var popinClick = document.getElementById("popinPreferredSCClick");
      if (popinClick)
        popinClick.click();
    }
    else {
      //open sc popin
      var popinClick = document.getElementById("popinVisitorCenterAddClick");
      if (popinClick)
        popinClick.click();
    }
  }

  if (model != undefined && model != null && model != "") {
    let descContent = "";
    if (preferredCenters.length === 1) {
      descContent = { value: model.single_SC_Description_Text?.value !== "" ? model.single_SC_Description_Text?.value?.replace("{CENTER}", centerName) : "" };
    }
    else {
      descContent = { value: model.multi_SC_Description_Text?.value !== "" ? model.multi_SC_Description_Text?.value?.replace("{PREF_SC_COUNT}", preferredCenters.length) : "" };
    }
    return (
      <div className="modal-dialog  modal-popin-trends disassociate-my-bank-account" id="termsconditionpopin">
        <div className="modal-content">
          <div className="modal-header">

            <button type="button" className="picto icon-close-circle-rw border-0 fs-4 bg-white" data-testid='testclick1' onClick={(e) => fnClosePopin()} aria-label="Close"></button>
          </div>
          <div className="modal-body text-center">
            <div className="popin-wrap">
              <div className="icon-center d-flex align-items-center justify-content-center fs-2"></div>
              {(model.add_to_Center_Title !== "" && model.add_to_Center_Title !== null) &&
                <Text className="msg-title text-uppercase" field={model.add_to_Center_Title} tag="h3" />
              }

              <div className="msg-text"><RichText field={descContent} /></div>

              <a className="link-button btn-dark center-block" aria-label={model?.accept_CTA} data-testid='testclick2' onClick={(e) => bindfnNewAcceptTermsAndCondition(pcCode)} href="javascript:void(0);">{model.accept_CTA}</a>
              <a className="link-button btn-white center-block" aria-label="Close" href="javascript:void(0);" data-testid='testclick3' onClick={(e) => fnClosePopin()}>{model.refuse_CTA}</a>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}