/* eslint-disable no-unused-expressions */
import React from "react";
import { LoadHomePageCinema } from '../Js-CommonFunctions';
import { useEffect } from 'react';

export default function HomePageCinema({ fields }) {

    useEffect(() => {
        if (fields.carousel !== null) {
            LoadHomePageCinema(fields.carousel.hpCinemaDatasource);
        }
    }, []);

    if (fields.carousel != null && fields.carousel.filmCarousel != null && fields.carousel.filmCarousel.length > 0) {
        return (
            <>
                <section className={"back-white cinema" + (fields.carousel.isNewBrandedSite ? " " : "")} id="homePageCinema">
                    <div className="container " id="homePageCinemaContainer">
                        <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between" id="homePageCinemaHeader">
                            {(fields.carousel.isCinemaPageExists) ?
                                (<>
                                    <a aria-label={fields?.carousel?.headingNavigation} className="title gtmClickNavigation" href={fields.carousel.headingNavigation}>

                                        {(!fields.carousel.isNewBrandedSite) ?
                                            (
                                                <h2><span className="news-title text-black">{fields.carousel.homeCinemaTitle}</span></h2>

                                            )
                                            :
                                            (
                                                <h2><span className="news-title">{fields.carousel.homeCinemaTitle}</span></h2>
                                            )}
                                    </a>
                                    {/* <a aria-label={fields?.carousel?.homeCinemaAllMovies} className={"action" + (fields.carousel.isNewBrandedSite ? " picto icon-plus-bigt" : "") + " gtmClickNavigation"} href={fields.carousel.headingNavigation}>
                                        {fields.carousel.isNewBrandedSite === false &&
                                            (
                                                <> <strong>{fields.carousel.homeCinemaAllMovies}</strong><span id="spnMoviesCount"> ({fields.carousel.totalMovieCount})</span><span className={"picto icon-ico-chevron-right"}></span></>
                                            )}

                                    </a> */}
                                </>
                                )
                                :
                                (
                                    <div className="title ">
                                        {(fields.carousel.isNewBrandedSite === false) ?
                                            (
                                                <h2><span className="news-title">{fields.carousel.homeCinemaTitle}</span></h2>
                                            )
                                            :
                                            (
                                                <h2><span className="news-title">{fields.carousel.homeCinemaTitle}</span></h2>
                                            )}
                                    </div>
                                )}
                        </div>
                        <div className="loadersmall" id="loaderDivCinema"></div>
                        <div className="row carousel-cinema" id="homePageCinemaInner">
                        </div>

                    </div>
                </section>



            </>
        )
    } else {
        return <div></div>;
    }
}


