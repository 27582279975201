import React, { useEffect } from 'react';
import { activateDesktopSegmentHoverSystem, getCheckedBrand, hideAllStores } from '../Js-CommonFunctions.js';

let nbMaxStoresToDisplay = 56;
let nbMaxStoresToDisplayPerPage = 14;
let maxColNb = 4;
let iItem = 0;

function renderStore({ model }) {
    let numberOfPage = 0;
    var storesArray = [];

    if ((model.storesList.length % nbMaxStoresToDisplay) == 0) {
        numberOfPage = (model.storesList.length / nbMaxStoresToDisplay);
    }
    else {
        numberOfPage = ((model.storesList.length / nbMaxStoresToDisplay) + 1);
    }

    for (let iPage = 0; numberOfPage > iPage && model.storesList.length > 0 && iItem < model.storesList.length; iPage++) {
        storesArray.push(
            <div className={'marques-liste-' + iPage}>
                {LoadStoreRows({ model })}
            </div>
        )
    }
    return storesArray;
}

function LoadStoreRows({ model }) {
    var rows = [];
    for (let iRow = 0; iRow < nbMaxStoresToDisplayPerPage && iItem < model.storesList.length; iRow++) {
        rows.push(
            <div className='row'>
                {LoadStoreDetail({ model })}
            </div>
        )
    }
    return rows;
}

function LoadStoreDetail({ model }) {
    var storeDetails = [];
    for (let iCol = 0; iCol < maxColNb && iItem < model.storesList.length; iCol++) {
        storeDetails.push(
            <><div className="col-6 col-lg-3 col">
                <div className="marques-liste__image border-8">
                    <div className="marques-liste__cta marque-liste__cta-hover shadow-none" data-brand-id={model.storesList[iItem].storeId} data-brand-name={model.storesList[iItem].name}>
                        <img src={model.storesList[iItem]._links.logo.href} alt={model.storesList[iItem].name} />
                    </div>
                </div>
            </div>
                <input type="hidden" value={iItem++} />
            </>
        )
    }
    return storeDetails
}

export default function GameStoresPageInner({ model }) {
    useEffect(() => {
        window.currentStorePageClassName = "marques-liste-0";
        document.getElementById('storePageTitle').innerHTML = window.storeTitle;
        if (model.storesList.length === 0) {
            document.getElementById('storePageTitle').innerHTML = window.emptyStoreTitle;
        }
        activateDesktopSegmentHoverSystem();
        getCheckedBrand();
        hideAllStores();
        if (document.getElementsByClassName("marques-liste-0")[0])
            document.getElementsByClassName("marques-liste-0")[0].style.display = "block";
    }, [model]);

    if (model !== null && model !== "") {
        return (
            <div id="storepagecontent">
                <div className="container-fluid page-marques">
                    <div className="container container-marques">
                        <div className="container-marques__header">
                            {model.isRebranded ?
                                (
                                    <div>
                                        <h3 className="" id='storePageTitle'></h3>
                                    </div>
                                ) :
                                (
                                    <div className="bloc">
                                        <h3 className="hidden-xs" id='storePageTitle'></h3>
                                    </div>
                                )
                            }
                        </div>
                        <div className={"marques-liste " + (model.isRebranded ? "mb-4" : "")}>
                            {renderStore({ model })}
                        </div>
                        <div className="pager-nav">
                            <div className="btn-sticky btn-submit">
                                <button className={"btn btn-dark btn-valide" + (model.isRebranded ? "" : " text-uppercase")}>{model.gameStoresCtaText}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<></>)
    }
}
