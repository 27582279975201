import React from 'react';

export default function FilmAnchors({ fields }) {
  const handleClick = (e) => {
    e.preventDefault();
  };
  
  if (fields && fields.filmAnchors != null) {
    return (
      <div className="anchor-navigation no-logo d-none d-sm-block">
        <div className="container">
          <ul className="nav">
            {fields.filmAnchors.filmlandingAnchors.map((child, index) => {
              return (
                <>
                  <li><a key={index} aria-label={child.links?.value?.url} href={child.links?.value?.url} className="text-uppercase scrollTo subanchors d-none" onClick={handleClick}>{child.links?.value?.text}</a></li>
                </>
              )
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
