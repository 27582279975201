import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function PhTitleImageDesc({  rendering }) {
  if (rendering) {
    return (
      <div className="container">
        <Placeholder name="ph_twocol" rendering={rendering} />
      </div>
    )
  } else {
    return <></>;
  }
}