import { Image } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';



export default function HeroOnCTA({ fields }) {
  if (fields.heroOnCTA != null) {
    return (
      (fields.heroOnCTA.showTimeList != null || fields.heroOnCTA.execpationalDay.ticks > 1) &&
      <section id="opening-hours" className="exceptional-opening-closing-block bg-1">
        <div className="img-wrap">
          {(fields.heroOnCTA.image.picture != null) &&
            <Image field={fields.heroOnCTA.image.picture} />
          }
        </div>
        <div className="container">
          <div className="row position-relative justify-content-start mx-2">
            <div className="col-12 col-md-6">
              <div className="exceptional-opening-closing-wrap text-center py-4">
                {fields.heroOnCTA.showTimeList.map((item, index) => {
                  return (
                    <>
                      {(item.openToday !== "" && item.openToday !== null) &&
                        <div key={index} className="title mb-2 active ">{item.openToday}</div>
                      }

                      {(item.type.toString().toLowerCase() === fields.heroOnCTA.centerClosed) ?
                        (
                          <div className="title mb-2  inactive">{item.fromTime}</div>

                        ) :
                        (
                          <div className="opening-hours">
                            {item.fromTime.substring(0, item.fromTime.length - 3)}
                          </div>
                        )
                      }
                    </>
                  )
                })}
                <a href="#accessinfo" aria-label={fields?.heroOnCTA?.seeAllOpeningHourLink} className="mt-4 link-button btn-white-transparent scrollToHidden gtmSeeOpeningHours" data-expand-elem="heroonctaopeninghrs">{fields.heroOnCTA.seeAllOpeningHourLink}</a>

{/* 
                {(fields.heroOnCTA.centerHoursCaption !== null) &&
                  <div className="stores-hours-vary">
                    <div className="d-block mb-1">{fields.heroOnCTA.centerHoursCaption.label}</div>
                    <a href={fields.heroOnCTA.centerHoursCaption.url?.value?.url} aria-label={fields?.heroOnCTA?.centerHoursCaption?.url} target={fields.heroOnCTA.centerHoursCaption.url?.value?.target} className="link-underline d-block text-white">
                      {fields.heroOnCTA.centerHoursCaption.url?.value?.text}
                    </a>
                  </div>
                } */}


              </div>
            </div>

            {(fields.heroOnCTA.execpationalDay !== null) &&
              (fields.heroOnCTA.exceptionalValue !== "" && fields.heroOnCTA.exceptionalValue !== null && fields.heroOnCTA.execpationalDate !== "" && fields.heroOnCTA.execpationalDate !== null) &&
              <div className="col-12 push-wrap" >
                <div className="title">{fields.heroOnCTA.exceptionalValue}</div>
                <div className="day ">{fields.heroOnCTA.execpationalDate}</div>
                {/* not present in FED */}
                {(fields.heroOnCTA.execpationalDateSummary !== "" && fields.heroOnCTA.execpationalDateSummary !== null) &&
                  <div className="mt-1 fs-16">{fields.heroOnCTA.execpationalDateSummary}</div>

                }
                <div className="action">
                  <a className="link-button btn-dark scrollToHidden" aria-label={fields?.heroOnCTA?.seeAll} href="#address-info">{fields.heroOnCTA.seeAll}</a>

                </div>
              </div>

            }
          </div>
        </div>
      </section>
    );

  } else {
    return <></>;
  }
}
