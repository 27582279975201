import React from "react";

export default function LoyaltyHeader({ fields }) {
  if (fields !== undefined && fields !== "" && fields !== null &&
    (fields.desktopImage !== null && fields.desktopImage !== undefined && fields.desktopImage !== "" ||
      fields.mobileImage !== null && fields.mobileImage !== undefined && fields.mobileImage !== ""
    )) {
    return (
      <>
        <section className="loyalty-block-wc p-0 loyalty-program-sticky-header p-0">
          <div className="loyalty-block-wc-img position-relative">
            <picture>
              {
                <>
                  <div className=" justify-content-between align-items-center loyalty-program-desktop-header ">
                    {fields.logo?.value?.src !== null && fields.logo?.value?.src !== ""
                      && fields.logo?.value?.src !== undefined &&
                      <img src={fields.logo?.value?.src} alt="loyalty-program-header-left "
                        className="loyalty-program-responsive-content d-flex " loading="lazy">
                      </img>}

                    {/* {fields.desktopImage?.value?.src !== null && fields.desktopImage?.value?.src !== ""
                      && fields.desktopImage?.value?.src !== undefined &&
                      <img src={fields.desktopImage?.value?.src}
                        alt="loyalty-program-header-right" className="loyalty-program-responsive-img d-flex" loading="lazy">
                      </img>
                    } */}
                  </div>

                  <div className=" justify-content-between align-items-center loyalty-program-mobile-header">
                    {fields.mobileLogo?.value?.src !== null && fields.mobileLogo?.value?.src !== ""
                      && fields.mobileLogo?.value?.src !== undefined &&
                      <img src={fields.mobileLogo?.value?.src} alt="loyalty-program-header-left "
                        className="loyalty-program-responsive-content d-flex " loading="lazy">
                      </img>
                    }
                    {/* {fields.mobileImage?.value?.src !== null && fields.mobileImage?.value?.src !== ""
                      && fields.mobileImage?.value?.src !== undefined &&
                      <img src={fields.mobileImage?.value?.src}
                        alt="loyalty-program-header-right" className="loyalty-program-responsive-img d-flex" loading="lazy">
                      </img>
                    } */}
                  </div>
                </>
              }
            </picture>

          </div>
        </section>
      </>
    )
  }
  else {
    return (<></>)
  }
}
