import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { JobOffersIconClick, lazyLoadingImg } from '../Js-CommonFunctions';

export default function JobOffers({ fields }) {
  let i = 0;
  useEffect(() => {
    lazyLoadingImg();
    JobOffersIconClick()
  }, []);
  if (fields.jobOffers != null && fields.jobOffers.jobOfferSortingCollection != null && fields.jobOffers.jobOfferSortingCollection.length > 0) {
    return (
      <>
        <section className={"all-services back-gray" + (fields.jobOffers.isNewBrandedSite === true ? " " : "")}>
          <div className="container">
            <div id="allServices">
              {fields.jobOffers.jobOfferSortingCollection.map((jobOffer, index) => {
                i++;
                var offer = jobOffer.offer;
                if ((offer.jobtitle !== null && offer.jobtitle !== "" && offer.jobtitle.value !== "" && offer.jobtitle.value !== null) || (offer.contracttype !== null && offer.contracttype.value !== "")) {
                  var jobtitleId = "JobTitles" + i;
                  return (
                    <>
                      <div key={index} className="panel panel-job">
                        <div className="panel-heading panel-heading-parents analytics_jobOffersplusclick">
                          <h4 className="panel-title">
                            <a data-bs-toggle="collapse" href={"#" + jobtitleId} className={"bg-lighter text-lighter" + (fields.jobOffers.isNewBrandedSite ? " px-0" : "")} data-parent="#allServices">
                              <div className="img-wrap brand-wrap">
                                {(offer.logo != null && offer?.logo?.value?.src != null) &&
                                  <img src={fields.jobOffers.defaultImage} data-alt={offer.logo?.value?.src} alt={offer.logo?.value?.alt} />
                                }
                              </div>
                              {offer.jobtitle !== null && offer.jobtitle !== "" && offer.jobtitle.value !== "" && offer.jobtitle.value !== null &&
                                <div className="job-name text-uppercase">
                                  <RichText field={offer.jobtitle} />
                                </div>
                              }
                              {offer.contracttype !== null && offer.contracttype !== "" && offer.contracttype.value !== "" && offer.contracttype.value !== null &&
                                <div className="contract-type text-uppercase">
                                  <RichText field={offer.contracttype} />
                                </div>
                              }
                              <div className="icone-more"> + </div>
                            </a>
                          </h4>
                        </div>
                        <div id={jobtitleId} className={"panel-collapse collapse" + (fields.jobOffers.isNewBrandedSite ? " boottom" : "")}>
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-sm-4">
                                {offer.contracttype !== null && offer.contracttype !== "" && offer.contracttype.value !== "" && offer.contracttype.value !== null &&
                                  <div className="info-sup">
                                    <div className={"infos-title" + (fields.jobOffers.isNewBrandedSite ? "" : " text-uppercase")}>
                                      {fields.jobOffers.contractType_Label} :
                                    </div>
                                    <div className="infos-value">
                                      <RichText field={offer.contracttype} />
                                    </div>
                                  </div>
                                }
                                {offer.hours !== null && offer.hours !== "" && offer.hours.value !== "" && offer.hours.value !== null &&
                                  <div className="info-sup">
                                    <div className={"infos-title" + (fields.jobOffers.isNewBrandedSite ? "" : " text-uppercase")}>
                                      {fields.jobOffers.hours_Label} :
                                    </div>
                                    <div className="infos-value">
                                      <RichText field={offer.hours} />
                                    </div>
                                  </div>
                                }
                                {offer.salary !== null && offer.salary !== "" && offer.salary.value !== "" && offer.salary.value !== null &&
                                  <div className="info-sup">
                                    <div className={"infos-title" + (fields.jobOffers.isNewBrandedSite ? "" : " text-uppercase")}>
                                      {fields.jobOffers.salary_Label} :
                                    </div>
                                    <div className="infos-value">
                                      <RichText field={offer.salary} />
                                    </div>
                                  </div>
                                }
                                {offer.startdate !== null && offer.startdate !== "" && offer.startdate.value !== "" && offer.startdate.value !== null &&
                                  <div className="info-sup">
                                    <div className={"infos-title" + (fields.jobOffers.isNewBrandedSite ? "" : " text-uppercase")}>
                                      {fields.jobOffers.date_Label} :
                                    </div>
                                    <div className="infos-value">
                                      <RichText field={offer.startdate} />
                                    </div>
                                  </div>
                                }
                                {offer.location !== null && offer.location !== "" && offer.location.value !== "" && offer.location.value !== null &&
                                  <div className="info-sup">
                                    <div className={"infos-title" + (fields.jobOffers.isNewBrandedSite ? "" : " text-uppercase")}>
                                      {fields.jobOffers.location_Label} :
                                    </div>
                                    <div className="infos-value">
                                      <RichText field={offer.location} />
                                    </div>
                                  </div>
                                }
                              </div>
                              <div className={"col-sm-7 pull-right" + (fields.jobOffers.isNewBrandedSite ? "" : " p-0")}>
                                {offer.profile !== null && offer.profile !== "" && offer.profile.value !== "" && offer.profile.value !== null &&
                                  <div className="job-details">
                                    <div className="title text-uppercase">{fields.jobOffers.profile_Label}</div>
                                    <div><RichText field={offer.profile} /></div>
                                  </div>
                                }
                                {offer.responsibilities !== null && offer.responsibilities !== "" && offer.responsibilities.value !== "" && offer.responsibilities.value !== null &&
                                  <div className="job-details">
                                    <div className="title text-uppercase">{fields.jobOffers.responsabilities_Label}</div>
                                    <div><RichText field={offer.responsibilities} /></div>
                                  </div>
                                }
                                {offer.requiredskills !== null && offer.requiredskills !== "" && offer.requiredskills.value !== "" && offer.requiredskills.value !== null &&
                                  <div className="job-details">
                                    <div className="title text-uppercase">{fields.jobOffers.requiredSkill_Label}</div>
                                    <RichText field={offer.requiredskills} />
                                  </div>
                                }
                                {offer.contact !== null && offer.contact !== "" && offer.contact.value !== "" && offer.contact.value !== null &&
                                  <div className="job-details">
                                    <div className="title text-uppercase">{fields.jobOffers.contact_Label}</div>
                                    <div>
                                      <RichText field={offer.contact} />
                                    </div>
                                  </div>
                                }
                                <div className="job-actions clearfix">
                                  {(offer.emailforappliance !== null && offer.emailforappliance?.value !== null && offer.emailforappliance?.value !== "") ?
                                    (
                                      <a className={(fields.jobOffers.isNewBrandedSite ? "" : " ") + "btn-dark-transparent-2 link-button pull-left mb-3 analytics_jobOffersapplyclick"} href={"mailto:" + (offer.emailforappliance.value)}>{fields.jobOffers.apply_Label}</a>
                                    )
                                    : (offer.websiteforappliance !== null && offer.websiteforappliance !== "") &&
                                    (
                                      <a className={(fields.jobOffers.isNewBrandedSite ? "" : "") + "btn-dark-transparent-2 link-button pull-left mb-3 analytics_jobOffersapplyclick"} href={offer.websiteforappliance} target="_blank" rel="noreferrer">{fields.jobOffers.apply_Label}</a>
                                    )}
                                </div>
                              </div>
                            </div>
                            {offer.about !== null && offer.about !== "" && offer.about.value !== "" && offer.about.value !== null &&
                              <div id="registration" className="msg-wrap no-popin">
                                <div className={(fields.jobOffers.isNewBrandedSite ? "popin-wrap" : "wrap")}>
                                  {(fields.jobOffers.aboutTheBrand_Label !== "") &&
                                    (
                                      <h3 className="msg-title text-uppercase">{fields.jobOffers.aboutTheBrand_Label}</h3>
                                    )}
                                  <div className="msg-text"><RichText field={offer.about} /></div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>

                    </>
                  );
                }
              }
              )}

            </div>
          </div>
        </section>
      </>
    );

  } else {
    return <></>;
  }

}



