import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import loadable from "@loadable/component";
import { baseModel } from '../../RouteHandler.js';
import { GetRenewedBookingAccessToken, cancelBookingDataLayer, fnConnectRedirectTodetailPage, fnEventOccurrenceDetails, fnRedirectToConnectLogin, fngtmBookingTicket, isBookingAccessTokenExpired, showMore } from '../Js-CommonFunctions.js';
import axios from 'axios';
import { fnGtmVideo, getVideoTypeFromvideoUrl } from '../Js-CommonFunctions';

const ReactPlayer = loadable(() => import('react-player'), { ssr: false });

export default function NewsAndOffersInformation({ fields }) {
    const [displayCancelBookingBlock, setDisplayCancelBookingBlock] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [bookingId, setBookingId] = useState("");
    const ref = useRef(null);
    const [windowinnerWidth, setwindowinnerWidth] = useState(1000); //by default set to be greater value to target desktop device
    const [counter, setCounter] = useState(0);
    const [showvideo, setshowvideo] = useState(false);

    var model = fields.heroNewsModel;
    const visitorId = useSelector((state) => state.user.visitorId)
    const encryptedEmail = useSelector((state) => state.user.encryptedEmail);
    var bookingAccessToken = useSelector((state) => state.user.bookingAccessToken);
    let workerCenter = useSelector((state) => state.user.workerCenter);

    let durationSec = "";
    var pauseSlick = () => {
        setCounter(counter + 1)

    };

    var VideoDuration = (x) => {
        durationSec = x;
    }

    var playSlick = () => {
    };

    useEffect(() => {
        if (model !== null && model !== undefined && model !== "" && (model.displayName.trim() !== "" || model.description.trim() !== "" || (model.date_Time_Info !== null && model.date_Time_Info?.length > 0))) {
            let isWorker =
                workerCenter !== undefined &&
                workerCenter !== '' &&
                workerCenter.split(',').includes(baseModel.pC_Code);

            if (typeof window !== "undefined") {
                setwindowinnerWidth(window.innerWidth);
                if (!isWorker) {
                    if (model.isWorkerNews)
                        window.location.href = model.redirectUrl;
                }
            }
            if (visitorId !== null && visitorId !== "" && model.hasCancelBookingItem) {
                if (isBookingAccessTokenExpired()) {
                    var args = bookingAccessToken;
                    GetRenewedBookingAccessToken(getVisitorBookingInfo, args);
                } else {
                    getVisitorBookingInfo(bookingAccessToken);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (counter === 1 || showvideo) {
            setshowvideo(false)
        }
    }, [counter]);


    function getVisitorBookingInfo(bookingAccessToken) {
        var loader = document.getElementById("loader");
        if (loader)
            loader.classList.remove("d-none");

        axios({
            method: "post",
            url: "/api/sitecore/VisitorApi/GetBookingInfoWithEventBookingId",
            data: {
                ursite: baseModel.urContextSite,
                urlang: baseModel.contextLang,
                accessToken: bookingAccessToken,
                email: encryptedEmail,
                eventbookingId: model.bookingId,
                strNewsTypeId: model.newsTypeID
            },
        })
            .then(function (response) {
                if (response.data) {
                    var localeDateTime = new Date();
                    if (new Date(response.data.endsAt).getTime() >= localeDateTime.getTime()) {
                        setBookingId(response.data.id);
                        setCompanyId(response.data.bookingCompanyid);
                        setDisplayCancelBookingBlock(true);
                    }
                }
                else {
                    //handle for negative scenario
                }
                if (loader)
                    loader.classList.add("d-none");

            })
            .catch(function (error) {
                console.log(error);
                if (loader)
                    loader.classList.add("d-none");
            });
    }

    function closeConfirmationPopin() {
        if (document.getElementById('cancelBookingPopin')) {
            document.getElementById('cancelBookingPopin').classList.add('d-none')
        }
    }
    function openConfirmationPopin() {
        if (document.getElementById('cancelBookingPopin')) {
            document.getElementById('cancelBookingPopin').classList.remove('d-none')
        }
    }

    function btnCancelBookingClick() {
        cancelBookingDataLayer(model.displayName);
        if (isBookingAccessTokenExpired()) {
            var args = bookingAccessToken;
            GetRenewedBookingAccessToken(cancelBookingApiCall, args);
        } else {
            cancelBookingApiCall(bookingAccessToken);
        }
    }

    function cancelBookingApiCall(bookingAccessToken) {
        var loader = document.getElementById("loader");
        if (loader)
            loader.classList.remove("d-none");
        axios({
            method: "post",
            url: "/api/sitecore/VisitorApi/CancelVisitorBooking",
            data: {
                ursite: baseModel.urContextSite,
                urlang: baseModel.contextLang,
                accessToken: bookingAccessToken,
                companyId: companyId,
                bookingId: bookingId
            },
        })
            .then(function (response) {
                if (response.data !== "") {
                    closeConfirmationPopin();
                    setDisplayCancelBookingBlock(false);
                }
                if (loader)
                    loader.classList.add("d-none");

            })
            .catch(function (error) {
                console.log(error);
                if (loader)
                    loader.classList.add("d-none");
            });
    }


    if (model !== null && model !== undefined && model !== "" && (model.displayName.trim() !== "" || model.description.trim() !== "" || (model.date_Time_Info !== null && model.date_Time_Info?.length > 0))) {
        var desc = { value: '' };
        desc.value = model.description;
        let doesEventHaveOccurrencePeriod = (model.eventOccurrencePeriod.trim() !== "");
        let isLoggedIn = useSelector((state) => state.user.isLoggedIn);
        let countOfEventsFromCurrentDate = 0;

        return (
            <>
                <section className="news-detail position-relative my-0 py-lg-3 py-0 bg-2" id="infos">
                    <div className="container px-lg-3 p-0">
                        <div className='news-detail-mobile-container'>
                            {model.retailerList.trim() !== "" &&
                                <div className="retailer-name">{model.retailerList}</div>
                            }
                            <div className="news-picto d-flex align-items-center justify-content-start my-3">
                                <h1 className="page-title text-uppercase m-0 p-0">{model.displayName}</h1>
                            </div>

                            <div className="news-detail-date mb-3 d-lg-none d-md-none d-sm-flex">{model.eventOccurrencePeriod}</div>
                            <div className="row">
                                <div className={"col-12 col-lg-6 ps-lg-0 pe-lg-3 pt-4 pt-lg-0 position-relative order-lg-0 order-md-0 order-sm-1 order-1" + (doesEventHaveOccurrencePeriod ? "" : "")}>
                                    <div className="news-detail-date mb-3 d-lg-flex d-md-flex d-none">{model.eventOccurrencePeriod}</div>
                                    <>
                                        <RichText field={desc} />
                                        {
                                            (displayCancelBookingBlock && model.hasCancelBookingItem) &&
                                            <><div className="enjoy-cta-block px-4 py-2 text-start bg-white border-8 d-lg-flex d-md-flex flex-column d-none">
                                                <div className="title text-uppercase easyparking-header text-start mb-3 mt-3 mb-lg-3">
                                                    {model.cancelBookingTitle}
                                                </div>
                                                <div className="description text-start text-midgray mb-4">
                                                    {model.cancelBookingDesc}
                                                </div>
                                                {model.cancelBookingBtnText !== null && model.cancelBookingBtnText !== "" &&
                                                    <button className="d-lg-flex d-md-flex d-none justify-content-center easy-parking-content-button btn btn-dark mb-3" id="cancel_bookingbug_cta"
                                                        onClick={(e) => { openConfirmationPopin() }} data-testid="cancelbookingbugcta">{model.cancelBookingBtnText}</button>
                                                }
                                            </div>
                                                <button className="d-lg-none d-md-none d-flex justify-content-center btn btn-dark mb-3" id="cancel_bookingbug_cta"
                                                    onClick={(e) => { openConfirmationPopin() }} data-testid="cacncelbookingbugcta">{model.cancelBookingBtnText}</button>
                                            </>
                                        }
                                    </>
                                </div>
                                <div className='col-12 col-lg-6 ps-lg-3 pe-lg-0 order-lg-1 order-md-1 order-sm-0 order-0'>
                                    <div className='news-detail-accordian mt-0'>
                                        <div className="img-wrap mb-4">
                                            {model.hasVideo ?
                                                <ReactPlayer
                                                    muted={false}
                                                    ref={ref}
                                                    config={{
                                                        youtube: {
                                                            playerVars: {
                                                                playsinline: 1,
                                                                rel: 0,
                                                                loop: 0,
                                                            },
                                                        },

                                                        vimeo: { playerOptions: { playsinline: 1 } },
                                                    }}
                                                    playsinline={true}
                                                    className="react-player-news-detail"
                                                    url={model.videoUrl}
                                                    width="100%"
                                                    height="560px"
                                                    controls={true}
                                                    playing={true}
                                                    loop={false}
                                                    light={
                                                        model.imgurl
                                                    }
                                                    pip={false}
                                                    playIcon={<div className="play-icon"></div>}
                                                    onPlay={pauseSlick}
                                                    onPause={playSlick}
                                                    onDuration={() => [
                                                        (fnGtmVideo('video_start', 'News Detail', model.videoUrl, model.displayName, ref.current.getDuration(), ref.current.getCurrentTime())),
                                                    ]}
                                                    onProgress={(x) => [
                                                        (gtmVideoOnProgress(fields, x.played, ref.current.getDuration(), ref.current.getCurrentTime()))
                                                    ]}
                                                    onEnded={() => [
                                                        (setshowvideo(true), fnGtmVideo('video_completion', 'News Detail', model.videoUrl, model.displayName, ref.current.getDuration(), ref.current.getCurrentTime()), ref.current.showPreview()),
                                                    ]}
                                                /> :
                                                <div>
                                                    <img className="d-md-none border-8 img-fluid w-100" src={model.imgurl} alt={model.imgalt} title={model.imgalt} />
                                                    <img className="d-none d-sm-none d-md-block p-0 border-8 img-fluid" src={model.imgurl} alt={model.imgalt} title={model.imgalt} />
                                                </div>
                                            }
                                            {/* <img className="d-md-none border-8 img-fluid w-100" src="../assets/img/news/news-detail-accordian-mobile.png" alt="desktop image" />
                                            <img className="d-none d-sm-none d-md-block p-0 border-8 img-fluid" src="../assets/img/news/news-detail-accordian.png" alt="mobile image" /> */}
                                        </div>
                                        <div className="accordion accordion-flush" id="accordionFlushOne">
                                            <div className="accordion-item mb-3 border-0">
                                                <div className="accordion-header">
                                                    <button className="bg-white shadow-none border-0 p-3 w-100 d-flex flex-row align-items-center detail-accordian-address-container cursor-default" type="button" aria-expanded="false">
                                                        <span className="picto icon-location-rw me-3 d-flex flex-column"></span>
                                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                                            <div className="d-flex flex-column">
                                                                <div className="d-flex justify-content-start detail-accordian-address">
                                                                    {model.centerAddress}
                                                                </div>

                                                            </div>
                                                            <span className='picto icon-routing me-2' />
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className="accordion-item mb-3 border-0">
                                                <div className="accordion-header" id="flush-headingTwo">
                                                    <div className="accordion-button bg-white shadow-none cursor-pointer collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        <span className="picto icon-computer-devices me-3"></span>
                                                        <span className="fw-bold detail-accordian-day">Site web lorem ipsum</span>
                                                    </div>
                                                </div>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-parent="#accordionFlushOne" >
                                                    <div className="accordion-body bg-white">
                                                        <div className="tarif">47,50€/24H</div>
                                                        <div className="tarif-description pt-3">
                                                            Tarifs en vigueur au 15/09/2021 - Tout 1/4h commencé est dû. *20min de parking gratuit par jour.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {model.date_Time_Info != null && model.date_Time_Info.length > 0 &&
                                                <div className="accordion-item mb-3 border-0">
                                                    <div className="accordion-header" id="flush-headingOne">
                                                        <div className={"bg-white accordion-button shadow-none border-0 p-3 w-100 d-flex flex-row detail-accordian-address-container align-items-start collapsed cursor-pointer " + (model.date_Time_Info.length > 4 ? "" : "nocollapse")} type="button" aria-expanded="false" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-controls="flush-collapseOne"
                                                            onClick={(e) => {
                                                                fnEventOccurrenceDetails(e, countOfEventsFromCurrentDate);
                                                            }}>
                                                            <div className="picto icon-menu-board me-3 mt-1 d-flex flex-start"></div>
                                                            <ul className="ms-0 mb-0 p-0 bg-white list-unstyled">
                                                                {model.date_Time_Info.map((date, index) => {
                                                                    var endDateTime = new Date(date.endTimeValue).setHours(0, 0, 0, 0);
                                                                    var localeDateTime = new Date(model.localeDateTime).setHours(0, 0, 0, 0);
                                                                    if (endDateTime >= localeDateTime) {
                                                                        countOfEventsFromCurrentDate++;
                                                                        return (
                                                                            <li key={index} id={"event" + (countOfEventsFromCurrentDate) + "_Details"} className={(countOfEventsFromCurrentDate > 4 ? "d-none " : "") + " justify-content-between event-details-collapsed time-text-area"}>
                                                                                <span className="days fw-bold me-2">{date.day}</span>
                                                                                <span className="days fw-bold me-2">{date.date + " " + date.month}</span>
                                                                                <span className="days fw-bold">{date.time + " - " + date.endTime}</span>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {(model.showExternalLinkCTA) &&
                                                <a href={model.externalLinkCTALink} target="_blank" rel="noreferrer" className="link-button text-uppercase btn-dark">{model.externalLinkCTAText}</a>
                                            }
                                            {(model.showBookingBugCTA && model.bookingIframeUrl !== null && model.bookingIframeUrl !== "") &&
                                                <div className="action">
                                                    {(isLoggedIn) ?
                                                        (
                                                            <a href={model.bookingBugCTALink} className="link-button text-uppercase btn-dark" onClick={() => { fngtmBookingTicket(model); }}>{model.bookingBugCTAText}</a>
                                                        ) :
                                                        (
                                                            <a href={baseModel?.connectRedirectUrl} className="link-button text-uppercase btn-dark" id="bookingbug_cta" onClick={(e) => { fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl); }}>{model.bookingBugCTATextNotLogin}</a>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal show d-block modal-popin-trends  d-none" id="cancelBookingPopin" tabIndex="-1" aria-labelledby="cancelBookingPopin"
                    aria-modal="true" role="dialog">
                    <div className=" modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="picto icon-close-circle-rw border-0 fs-3 bg-white text-black" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={(e) => { closeConfirmationPopin() }}></button>
                            </div>
                            <div className="modal-body text-center">
                                <div className="popin-wrap">
                                    <div className="msg-text">{model.cancelBookingPopinTitle}</div>
                                    <a className="link-button btn-dark center-block" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);"
                                        onClick={(e) => { closeConfirmationPopin() }}>{model.btnNo}</a>
                                    <a className="link-button btn-white center-block" onClick={(e) => { btnCancelBookingClick() }}
                                        href="javascript:void(0);">{model.btnYes}</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="loader" data-bs-title="dot-spin" className="d-flex align-items-center justify-content-center account-loader d-none bg-black opacity-50">
                    <div className="dot-spin"></div>
                </div>
            </>
        )
    }
    else {
        return (<></>)
    }
}

var pushedValue = 0;

function gtmVideoOnProgress(fields, played, videoDuration, videoCurrentTime) {
    var currentValue = "";
    var duration = Math.round((played + Number.EPSILON) * 100) / 100;

    if (duration == "0.5" || duration == "0.25" || duration == "0.75") {
        var videoProvider = getVideoTypeFromvideoUrl("", fields.heroNewsModel.videoUrl);
        if (videoCurrentTime === null)
            videoCurrentTime = 0;
        if (pushedValue !== duration) {
            pushedValue = duration;
            if (window !== undefined && window !== null && window.dataLayer !== undefined && window !== null) {
                window.dataLayer.push({
                    event: "video_progress",
                    video_current_time: Math.round(videoCurrentTime),
                    video_duration: Math.round(videoDuration),
                    video_percent: duration * 100,
                    video_provider: (videoProvider !== null && videoProvider !== "") ? videoProvider : undefined,
                    video_title: (fields.heroNewsModel.displayName !== null && fields.heroNewsModel.displayName !== "") ? fields.heroNewsModel.displayName : undefined,
                    video_url: (fields.heroNewsModel.videoUrl !== null && fields.heroNewsModel.videoUrl !== "") ? fields.heroNewsModel.videoUrl : undefined,
                    visible: true
                });
            }
        }
    }
}
