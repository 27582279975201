import loadable from "@loadable/component";
import axios from "axios";
import he from "he";
import jwt_decode from 'jwt-decode';
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import Cookies from "universal-cookie";
import { baseModel, global } from "../RouteHandler.js";
import store from "../redux/store.js";
import { doLogout, setAccessToken, setBookingAccessToken, setBookingTokenExipry, setBookmarkedEvent, setBookmarkedOffer, setBookmarkedRestaurant, setBookmarkedStore, setFavCenters, setRefreshToken } from "../redux/user/actions.js";
import CenterSelectorPopin from "./CenterSelectorPopin/index.js";
import CountrySelectorPopin from "./CountrySelectorPopin/index.js";
import ErrorPopin from "./ErrorPopin/index.js";
import QueueManagementRetailersInner from "./QueueManagementRetailersInner/index.js";
import { sha256, sha224 } from 'js-sha256';

const GameStoresPageInner = loadable(() => import("./GameStoresPageInner/index.js"));
const HomePageCinemaInner = loadable(() => import("./HomePageCinemaInner/index.js"));
const IntroductionInnerPage = loadable(() => import("./IntroductionInnerPage/index.js"));
const SearchNoResult = loadable(() => import("./SearchNoResult/index.js"));
const SearchNewStores = loadable(() => import("./SearchNewStores/index.js"));
const antiForgeryToken = loadable(() => import("./Header/index.js"));
const ServicesFilterInner = loadable(() => import("./ServicesFilterInner/index.js"));
const LoyaltyCardBarCodePopin = loadable(() => import("./LoyaltyCardBarCodePopin/index.js"));
const SubscriptionMessagePopin = loadable(() => import("./SubscriptionMessagePopin/index.js"));
const cookies = new Cookies();

/**
 * Common Js function which is called from React js.
 */

//Global Variables

var pageAndQuery;
var directionsDisplay;
var controlTraffic;
var bookMarked = "";
var ajaxCallIncrement = 0;
var ajaxCallPresent = false;

//Variables for Search page Tagging related
var vpvSearchResult = "vpvSearchResult";
var vpvSearchNoResult = "vpvSearchNoResult";
var GTMsearchResultPage = "search-bar/results";
var GTMsearchNoResultPage = "search-bar/no-result";
var searchPage = "";
var GTMSearchStatus = "";
var GTMSearchPage = "";
var GTMSearchText = "";
var GTMSearchCount = "";


export var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
  isTablet: function () {
    return navigator.userAgent.match(
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
    );
  },
};

export function fnApplyPyjamaEffect() {
  var isMobileView = document
    .getElementsByTagName("body")[0]
    .classList.contains("on-mobile")
    ? true
    : false;
  var position = 0;
  Array.from(document
    .getElementById("wrapper")
    .getElementsByTagName("section"))
    .forEach((x) => {
      if (
        x.parentElement.id == "wrapper" && //apply Pyjama effect only to parent sections, not to child sections (e.g., Generic page - rich text component)
        (x.style.display == "" || x.style.display == "block") && //apply only to visible components
        (isMobileView || !x.classList.contains("mobile-only"))
      ) {
        //few components are visible only in Mobile view. Skip those components in Desktop view
        if (position % 2 == 0) {
          x.classList.remove("odd");
          x.classList.add("even");
        } else {
          x.classList.remove("even");
          x.classList.add("odd");
        }
        position = position + 1;
      }
    });

  // if (document.getElementById("brands")) {
  //   var className = document.getElementsByClassName("brand-anchor");
  //   if (document.getElementById("brands").classList.contains("even")) {
  //     for (var index = 0; index < className.length; index++) {
  //       className[index].classList.add("bg-white");
  //     }
  //   }
  //   else {
  //     for (var index = 0; index < className.length; index++) {
  //       className[index].classList.add("bg-gray");
  //     }
  //   }
  // }

}

export function fnInitializeVendorGTM(email) {
  if (typeof window !== "undefined") {
    var hasWebviewEqualsOne = checkWebViewEqualsOne();
    if (window.location.href.indexOf("#mypersonalinformation") <= 0 || (email !== undefined && email !== "" && email !== null)) {
      if (global.isGTMTaggingEnabled && !hasWebviewEqualsOne) {
        pageAndQuery = global.pageAndQuery;
        window.dataLayer = window.dataLayer || [];
        const state = store.getState();
        if (!global.isGTMTaggingEnabled) {
          return;
        }
        else {
          let hashedEmail = undefined;
          if (email !== null && email !== "" && email !== undefined) {
            hashedEmail = sha256(email)
          }
          else {
            hashedEmail = state.user.email !== null && state.user.email !== "" && state.user.email !== undefined ? sha256(state.user.email) : undefined
          }
          window.dataLayer.push({
            branded_center: baseModel.isLandingPage ? undefined : baseModel.branded_center,
            country: baseModel.country !== "" && baseModel.country !== null && baseModel.country !== undefined ? baseModel.country : undefined,
            event: "page_view",
            booking_event_name: baseModel.newsEventName !== undefined && baseModel.newsEventName !== null && baseModel.newsEventName !== "" ? baseModel.newsEventName : undefined,
            booking_event_id: baseModel.newsEventID !== undefined && baseModel.newsEventID !== null && baseModel.newsEventID !== "" ? baseModel.newsEventID : undefined,
            language: baseModel.language !== null && baseModel.language !== "" ? baseModel.language.replace(/\([^()]*\)/g, '').trim() : undefined,
            login_status: (baseModel.loginStatus) ? "logged" : "not logged",
            page: (pageAndQuery !== undefined && pageAndQuery !== null && pageAndQuery !== "") ? pageAndQuery : undefined,
            page_type: ((baseModel.pageTypeGTM !== "" && baseModel.pageTypeGTM !== "") ? baseModel.pageTypeGTM : ((baseModel.currentPageItemName !== undefined && baseModel.currentPageItemName !== null && baseModel.currentPageItemName !== "") ? baseModel.currentPageItemName : undefined)),
            pc_code: baseModel.pC_Code !== undefined && baseModel.pC_Code !== null && baseModel.pC_Code !== "" ? baseModel.pC_Code : undefined,
            retailer_id: baseModel.retailerId !== null && baseModel.retailerId !== "" ? baseModel.retailerId : undefined,
            retailer_name: he.decode(baseModel.storeSIName) !== null && he.decode(baseModel.storeSIName) !== "" ? he.decode(baseModel.storeSIName) : undefined,
            shop_id: baseModel.storeId !== null && baseModel.storeId !== "" ? baseModel.storeId : undefined,
            template_name: (baseModel.pageTemplateType !== undefined && baseModel.pageTemplateType !== null && baseModel.pageTemplateType !== "") ? baseModel.pageTemplateType : undefined,
            user_id: state.user.visitorId !== null && state.user.visitorId !== "" ? state.user.visitorId : undefined,
            visitor_id: state.user.visitorId !== null && state.user.visitorId !== "" ? state.user.visitorId : undefined,
            first_name: state.user.firstName !== null && state.user.firstName !== "" ? state.user.firstName : undefined,
            birthdate: state.user.birthdate !== null && state.user.birthdate !== "" ? state.user.birthdate : undefined,
            account_creation_date: state.user.accountCreationDate !== null && state.user.accountCreationDate !== "" ? state.user.accountCreationDate : undefined,
            favorite_shopping_centers: state.user.favCenterLables !== null && state.user.favCenterLables !== "" ? state.user.favCenterLables : undefined,
            favorite_offers: state.user.bookmarkedOffer !== null && state.user.bookmarkedOffer !== "" ? state.user.bookmarkedOffer : undefined,
            favorite_events: state.user.bookmarkedEvent !== null && state.user.bookmarkedEvent !== "" ? state.user.bookmarkedEvent : undefined,
            favorite_stores: state.user.bookmarkedStore !== null && state.user.bookmarkedStore !== "" ? state.user.bookmarkedStore : undefined,
            favorite_restaurants: state.user.bookmarkedRestaurant !== null && state.user.bookmarkedRestaurant !== "" ? state.user.bookmarkedRestaurant : undefined,
            service_tag: baseModel.serviceTag !== null && baseModel.serviceTag !== "" && baseModel.serviceTag !== undefined ? baseModel.serviceTag : undefined,
            service_type: baseModel.serviceType !== null && baseModel.serviceType !== "" && baseModel.serviceType !== undefined ? baseModel.serviceType : undefined,
            smart_park_user: baseModel.loginStatus ? (state.user.smartParkUser ? true : false) : undefined,
            parking_club_active_user: baseModel.loginStatus ? (state.user.parkingClubActiveUser ? true : false) : undefined,
            hashed_email: hashedEmail
          });
        }
      }
      if (
        baseModel.gtmContainerId !== null &&
        baseModel.gtmContainerId !== "") {
        //Google Tag Manager
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", baseModel.gtmContainerId);
      }
    }
  }
}

export function URWindowLocation(url) {
  if (typeof window !== "undefined") {
    window.location.href = URWindowURLReturn(url);
  }
}

export function MetaNoReferer() {
  var getatag = document.getElementsByTagName("a");
  Array.from(getatag).forEach((x) => {
    if (x.getAttribute("target") == "_blank") {
      x.setAttribute("rel", "noreferrer");
    }
  });
}

function chunkArray(myArray, chunk_size) {
  var index = 0;
  var arrayLength = myArray.length;
  var finalArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    var myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    finalArray.push(myChunk);
  }

  return finalArray;
}

export function sectionBgClass() {
  var getSection = document.querySelectorAll("section");
  var sectionArr = Array.from(getSection);
  var newArr = [];
  for (var i = 0; i < getSection.length; i++) {
    if (
      !getSection[i].classList.contains("parking-banner") &&
      !getSection[i].classList.contains("quickLinks")
    ) {
      newArr.push(getSection[i]);
    }
  }
  var eachSection = chunkArray(newArr, 4);

  eachSection.forEach((x) => {
    if (x[0] != undefined) {
      x[0].classList.add("bg-1");
    }
    if (x[1] != undefined) {
      x[1].classList.add("bg-2");
    }
    if (x[2] != undefined) {
      x[2].classList.add("bg-3");
    }
    if (x[3] != undefined) {
      x[3].classList.add("bg-4");
    }
  });
}

export function URWindowURLReturn(url) {
  if (typeof window !== "undefined") {
    var urlInnerValue = "";
    if (window.location.href.indexOf("webview") >= 0) {
      var querystring = getUrlQuerystringVarsWebviewEqualsOne();
      if (querystring.webview == 1) {
        var querystring = "webview=1";
        if (!url.includes(querystring)) {
          if (url != "#" && url != "/" && url != "javascript: void (0)") {
            if (url.includes("#")) {
              var spliturl = url.split("#");
              if (spliturl.length == 2) {
                url =
                  spliturl[0] +
                  (spliturl[0].match(/\?/) ? "&" : "?") +
                  querystring +
                  "#" +
                  spliturl[1];
                urlInnerValue = url;
              }
            } else {
              url += (url.match(/\?/) ? "&" : "?") + querystring;
              urlInnerValue = url;
            }
          }
        } else {
          urlInnerValue = url;
        }
      }
    } else {
      urlInnerValue = url;
    }
    return urlInnerValue;
  }
}

function getUrlQuerystringVarsWebviewEqualsOne() {
  var vars = [],
    hash;
  var hashes = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&");
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    if (hash[1] != null && hash[1] != undefined && hash[1] != "") {
      if (hash[1].includes("#")) {
        var splithash = hash[1].split("#");
        if (splithash.length == 2) {
          vars.push(hash[0]);
          vars[hash[0]] = splithash[0];
        }
      } else {
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
    } else {
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
  }
  return vars;
}

export function menuDataLayer() {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_find_center',
    'page': (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

export function changeCountryDataLayer() {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_change_country',
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

export function centerSelecterDataLayer(titleValue) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'select_center',
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'selected_center_name': (titleValue !== undefined && titleValue !== null && titleValue !== "") ? titleValue : undefined,
  });
}

export function countrySelecterDataLayer(titleValue) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'select_country',
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'selected_country_name': (titleValue !== undefined && titleValue !== null && titleValue !== "") ? titleValue : undefined,
  });
}

export function homeProfileDataLayer(titleValue) {
  var profileElement = "";
  profileElement = (titleValue === "my-benefits-and-services") ? ("Advantages and services") : (
    (titleValue === "my-loyalty-card") ? ("Fidelity card") : (
      (titleValue === "my-personal-information") ? ("Personal informations") : (
        (titleValue === "my-communications") ? ("Communications") : (
          (titleValue === "my-favorites") ? ("Favorites") : ""
        )
      )
    )
  )
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  if (titleValue === "my-bookings") {
    window.dataLayer.push({
      'event': 'click_booking_info'
    });
  }
  else {
    window.dataLayer.push({
      'event': 'click_profile_element',
      'profile_element_name': (profileElement !== null && profileElement !== "") ? profileElement : undefined,
    });
  }
}

export function loyaltyProgramDataLayer() {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_loyalty_program',
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

export function menuLoyaltyProgramDataLayer() {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_loyalty_program',
    'page': (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}
export function fnAnchorNavigation(isnewsMenu) {
  if (isnewsMenu)
    createCookie("anchorclick", "news", 0);
}

export function menuElementDataLayer(destinationUrl,) {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'destination_page': (destinationUrl !== null && destinationUrl !== "") ? destinationUrl : undefined,
    'event': 'click_menu_element',
  });
}

function gtmFuncAddToFavorite(favoriteName, favoriteType) {
  const state = store.getState();
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'add_to_favorite',
    'favorite_name': (favoriteName !== null && favoriteName !== "") ? favoriteName : undefined,
    'favorite_type': (favoriteType === "deal") ? "offers" : (
      (favoriteType === "event") ? "news" : favoriteType
    ),
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'favorite_offers': state.user.bookmarkedOffer !== null && state.user.bookmarkedOffer !== "" ? state.user.bookmarkedOffer : undefined,
    'favorite_events': state.user.bookmarkedEvent !== null && state.user.bookmarkedEvent !== "" ? state.user.bookmarkedEvent : undefined,
    'favorite_stores': state.user.bookmarkedStore !== null && state.user.bookmarkedStore !== "" ? state.user.bookmarkedStore : undefined,
    'favorite_restaurants': state.user.bookmarkedRestaurant !== null && state.user.bookmarkedRestaurant !== "" ? state.user.bookmarkedRestaurant : undefined
  });
}

export function gtmFuncRemoveFromFavorite(favoriteName, favoriteType) {
  const state = store.getState();
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'remove_from_favorite',
    'favorite_name': (favoriteName !== null && favoriteName !== "") ? favoriteName : undefined,
    'favorite_type': (favoriteType === "deal") ? "offers" : (
      (favoriteType === "event") ? "news" : favoriteType
    ),
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'favorite_offers': state.user.bookmarkedOffer !== null && state.user.bookmarkedOffer !== "" ? state.user.bookmarkedOffer : undefined,
    'favorite_events': state.user.bookmarkedEvent !== null && state.user.bookmarkedEvent !== "" ? state.user.bookmarkedEvent : undefined,
    'favorite_stores': state.user.bookmarkedStore !== null && state.user.bookmarkedStore !== "" ? state.user.bookmarkedStore : undefined,
    'favorite_restaurants': state.user.bookmarkedRestaurant !== null && state.user.bookmarkedRestaurant !== "" ? state.user.bookmarkedRestaurant : undefined
  });
}

export function gtmFuncClickAccountHeader() {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_account_header',
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

export function gtmFuncAddCenterToFavorite(centerLable, favCenter) {
  let favCenterLables = '';
  if (favCenter !== null && favCenter !== undefined && favCenter !== '') {
    favCenterLables = favCenter;
  } else {
    const state = store.getState();
    favCenterLables = state.user.favCenterLables !== null && state.user.favCenterLables !== "" ? state.user.favCenterLables.split(",") : undefined;
  }
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'add_center_to_favorite',
    'favorite_center': (centerLable !== null && centerLable !== "") ? centerLable : undefined,
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'favorite_shopping_centers': favCenterLables !== null && favCenterLables !== "" ? favCenterLables : undefined,
  });
}

export function fnGtmOfferClick(offername, storename, retailername, offerType, isDetailPage, isNewBrandedSite) {
  offername = offername !== null && offername !== undefined && offername !== "" ? offername : "";
  storename = storename !== null && storename !== undefined && storename !== "" ? storename : "";
  let offer_name = storename + " " + offername;
  if (isNewBrandedSite) {
    offerType = offerType !== null && offerType !== undefined && offerType !== "" ? "Westfield Club Offer" : "Classic Offer";
  }
  else {
    offerType = offerType !== null && offerType !== undefined && offerType !== "" ? "Club Offer" : "Classic Offer";
  }

  if (!global.isGTMTaggingEnabled || isDetailPage) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_offer',
    'offer_name': offer_name !== undefined && offer_name !== null && offer_name !== "" ? offer_name : undefined,
    'offer_type': offerType,
    'retailer_name': retailername !== undefined && retailername !== null && retailername !== "" ? retailername : undefined,
  });
}

export function gtmFuncRemoveCenterToFavorite(centerLable, favCenter) {
  let favCenterLables = '';
  if (favCenter !== null && favCenter !== undefined && favCenter !== '') {
    favCenterLables = favCenter;
  } else {
    const state = store.getState();
    favCenterLables =
      state.user.favCenterLables !== null && state.user.favCenterLables !== ''
        ? state.user.favCenterLables.split(',')
        : undefined;
  }
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'remove_center_to_favorite',
    'favorite_center': (centerLable !== null && centerLable !== "") ? centerLable : undefined,
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'favorite_shopping_centers': favCenterLables,
  });
}

export function floorCenterMapDataPush(targetFloorID, templateType) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_floor_center_map',
    'floor_level': (targetFloorID !== undefined && targetFloorID !== null && targetFloorID !== "") ? targetFloorID : undefined,
  });
}

export function clickItineraryLaunchDataPush() {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'itinerary_launch',
  });
}

export function mapInteractionDataPush() {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'map_interaction',
  });
}

export function loyaltyProgramSubscriptionDataPush(ctaPosition) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'cta_position': (ctaPosition !== undefined && ctaPosition !== null && ctaPosition !== "") ? ctaPosition : undefined,
    'event': 'click_subscribe_loyalty_program',
  });
}

export function profileUpdateSuccessDataPush(preferredCenter, preferredCode) {
  const state = store.getState();
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'update_account',
    'preferred_center_name': (preferredCenter !== undefined && preferredCenter !== null && preferredCenter !== "") ? preferredCenter : undefined,
    'preferred_center_pc_code': (preferredCode !== undefined && preferredCode !== null && preferredCode !== "") ? preferredCode : undefined,
    'visitor_id': (state.user.visitorId !== undefined && state.user.visitorId !== null && state.user.visitorId !== "") ? state.user.visitorId : undefined
  })
}

export function getUrlQuerystringVars() {
  var vars = [],
    hash;
  var hashes = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&");
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
}

export function switchTabOrScroll() {
  //This below block should always be at the bottom of the ready event
  var currentUrl = window.location.href;
  if (currentUrl.indexOf("#") > -1) {
    var idToScroll = currentUrl.substring(currentUrl.indexOf("#"));
    if (idToScroll == "" || idToScroll == "#") return;
    if (idToScroll.indexOf("Tab") > -1) {
      if (!currentUrl.includes("webview=1")) {
        idToScroll = currentUrl.split("=")[1];
      } else {
        idToScroll = currentUrl.split("Tab=")[1];
      }
      //Top Tab Desktop
      if (!!idToScroll) {
        //if value exists next to = do the below
        var topTabClear = document.getElementById("Tabbing");
        if (!!topTabClear) {
          //If the element exist clear the active
          //Clear the Active class for all elements.
          var ul = document.getElementById("Tabbing");

          var lis = ul.getElementsByTagName("li");
          for (var i = 0; i < lis.length; i++) {
            //remove active from the top Tab
            lis[i].classList.remove("active");
            lis[i].removeAttribute("style");
            //clear active from its respective table
            var hrefelement = lis[i].getElementsByClassName("genericTabs");
            hrefelement = hrefelement[0].getAttribute("href");
            var itemsTab = document.getElementById(hrefelement.split("#")[1]);
            itemsTab.classList.remove("active");
          }
        }
      }

      //Top Tab Mobile

      //First//clear all top menu mobile
      if (!!idToScroll) {
        //if value exists next to = do the below
        var topTabClear = document.getElementById("TabbingMob");
        if (!!topTabClear) {
          //If the element exist clear the active
          //Clear the Active class for all elements.
          var ul = document.getElementById("TabbingMob");

          var lis = ul.getElementsByTagName("li");
          for (i = 0; i < lis.length; i++) {
            //remove active from the top Tab
            lis[i].classList.remove("active");
            //clear active from its respective table
            var hrefelement = lis[i].getElementsByClassName("genericTabs");
            hrefelement = hrefelement[0].getAttribute("href");
            var itemsTab = document.getElementById(hrefelement.split("#")[1]);
            itemsTab.classList.remove("active");
          }
        }
      }

      //second//set the top menu mobile
      if (!!idToScroll) {
        //if value exists next to = do the below
        var topTabClearMob = document.getElementById("TabbingMob");
        if (!!topTabClear) {
          //If the element exist clear the active
          //set the Active class for the top menu of Mobile .
          var lis = topTabClearMob.getElementsByTagName("li");
          for (i = 0; i < lis.length; i++) {
            //get the Li and check if it has the respective href
            var hrefelement = lis[i].getElementsByClassName("genericTabs");
            var hreftext = hrefelement[0].getAttribute("href");
            if (hreftext.split("#")[1] == idToScroll) {
              hrefelement[0].parentElement.classList.add("active");
              document.getElementById("selected-bookmark").textContent =
                hrefelement[0].textContent;
              var Span = document.createElement("span");
              Span.classList.add("caret");
              document.getElementById("selected-bookmark").appendChild(Span);
            }
          }
        }
      }

      //then Set Active for the Element in URL.
      var topTabClear = document.getElementById("Tabbing");
      if (!!topTabClear) {
        //If the element exist set active to idToScroll
        //set the Active class for the top menu.
        var ul = document.getElementById("Tabbing");
        var lis = ul.getElementsByTagName("li");
        for (i = 0; i < lis.length; i++) {
          //get the Li and check if it has the respective href
          var hrefelement = lis[i].getElementsByClassName("genericTabs");
          var hreftext = hrefelement[0].getAttribute("href");
          var itemsTab = document.getElementById(hreftext.split("#")[1]);
          if (hreftext.split("#")[1] == idToScroll) {
            itemsTab.classList.add("active");
            hrefelement[0].classList.add("active");
            if (!document.getElementById("clubShopsTab")) {
              hrefelement[0].setAttribute(
                "style",
                "border-bottom: 1px solid #393939"
              );
            }
            //set active from its respective second table
            var secondTab = document.getElementById(idToScroll);
            secondTab.classList.add("active");
          }
        }
      }
      if (idToScroll != "" && (idToScroll != "#") != 0) {
        if (window.location.href.indexOf("webview") >= 0) {
          var querystring = getUrlQuerystringVars();
          if (querystring.webview == 1) {
            if (
              document.getElementById("cookieWidgetID").style.display == "block"
            ) {
              window.scrollTo({
                top: document.getElementsByClassName("navme")[0].offsetTop - 55,
                behavior: "smooth",
              });
            } else {
              window.scrollTo({
                top: document.getElementsByClassName("navme")[0].offsetTop - 30,
                behavior: "smooth",
              });
            }
          }
        } else {
          if (document.getElementById("MainHeader") &&
            document.getElementById("MainHeader").offsetWidth <= 0 &&
            document.getElementById("MainHeader").offsetHeight <= 0
          ) {
            var heightHeader =
              document.getElementById("headerBlock").offsetHeight;
          } else {
            var heightHeader =
              document.getElementById("headerBlock").offsetHeight +
              document.getElementById("MainHeader").offsetHeight;
          }
          if (
            document.getElementById("cookieWidgetID")?.style.display == "block"
          ) {
            window.scrollTo({
              top:
                document.getElementsByClassName("navme")[0].offsetTop -
                heightHeader -
                55,
              behavior: "smooth",
            });
          } else {
            window.scrollTo({
              top:
                document.getElementsByClassName("navme")[0].offsetTop -
                heightHeader -
                30,
              behavior: "smooth",
            });
          }
        }
      }
    } else if (idToScroll.indexOf("Login") > -1) {
      idToScroll = currentUrl.split("=")[1];
      if (idToScroll.indexOf("changed") > -1) {
      }
      setDefaultTabs();
    } else {
      var scrollLength = 0;
      var page;
      var speed;
      if (window.location.href.indexOf("webview") >= 0) {
        var querystring = getUrlQuerystringVars();
        if (querystring.webview == 1) {
          setDefaultTabs();
          if (
            document.getElementById("cookieWidgetID")?.style.display == "block"
          ) {
            if (idToScroll == "#offers") {
              return (
                (page = idToScroll),
                (speed = 750),
                window.scrollTo(
                  {
                    top: page.offsetTop,
                    behavior: "smooth",
                  },
                  750
                ),
                !1
              );
            } else {
              scrollLength = document.querySelector(idToScroll).offsetTop - 55;
              return (
                (page = idToScroll),
                (speed = 750),
                window.scrollTo(
                  {
                    top: scrollLength,
                    behavior: "smooth",
                  },
                  750
                ),
                !1
              );
            }
          } else {
            scrollLength = document.querySelector(idToScroll).offsetTop - 30;
            return (
              (page = idToScroll),
              (speed = 750),
              window.scrollTo(
                {
                  top: scrollLength,
                  behavior: "smooth",
                },
                750
              ),
              !1
            );
          }
        }
      } else {
        if (baseModel.currentPageItemName == "access") {
          idToScroll = document.querySelector(idToScroll)
            ? idToScroll
            : "#accessinfo";
        }
        if (document.getElementById("MainHeader") &&
          document.getElementById("MainHeader").offsetWidth <= 0 &&
          document.getElementById("MainHeader").offsetHeight <= 0
        ) {
          var heightHeader =
            document.getElementById("headerBlock").offsetHeight;
        } else {
          var heightHeader =
            document.getElementById("headerBlock").offsetHeight +
            document.getElementById("MainHeader").offsetHeight;
        }
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
        setDefaultTabs();
        if (
          document.getElementById("cookieWidgetID")?.style.display == "block"
        ) {
          if (idToScroll == "offers") {
            if (document.querySelector(idToScroll)) {
              scrollLength =
                document.querySelector(idToScroll).offsetTop - heightHeader;
              window.scrollTo({ top: scrollLength, behavior: "smooth" });
            }
          } else {
            if (document.querySelector(idToScroll)) {
              scrollLength =
                document.querySelector(idToScroll).offsetTop -
                heightHeader -
                55;
              window.scrollTo({ top: scrollLength, behavior: "smooth" });
            }
          }
        } else {
          if (baseModel.currentPageItemName != "access") {
            var dynamicHeight = baseModel.currentPageItemName == "services" ? 0 : 30;
            if (document.querySelector(idToScroll)) {
              scrollLength =
                document.querySelector(idToScroll).offsetTop -
                heightHeader - dynamicHeight;
              window.scrollTo({ top: scrollLength, behavior: "smooth" });
            }
          }
        }
      }
    }
  } else {
    if (currentUrl.indexOf("SuccessMessage") > -1) {
      if (
        baseModel.successmessages !== undefined &&
        baseModel.successmessages !== null
      ) {
        var splitMessageFromCode = baseModel.successmessages.split(" ");
        for (var x = 0; x < splitMessageFromCode.length; x++) {
          if (currentUrl.indexOf(splitMessageFromCode[x]) > -1) {
            setDefaultTabs();
            break;
          }
        }
      }
    }
    setDefaultTabs();
  }
}

//set default tabs for bookmark component and loyalty component Only IF THEY EXIST
export function setDefaultTabs() {
  var makeDefaultTabActive = document.getElementsByClassName("makethisactive");
  if (!!makeDefaultTabActive) {
    if (document.getElementById("shopsTab"))
      document.getElementById("shopsTab").classList.add("active");
    for (var i = 0; i < makeDefaultTabActive.length; i++) {
      makeDefaultTabActive[i].classList.add("active");
    }
  }
  var makeTabActiveMobile = document.getElementById("offers");
  if (!!makeTabActiveMobile) {
    makeTabActiveMobile.classList.add("active");
  }

  var makeTabActiveLoyalty = document.getElementById("lcAdvShopsTab");
  if (!!makeTabActiveLoyalty && document.getElementById("shopsTab")) {
    makeTabActiveLoyalty.classList.add("active");
  }
}

export function subAnchors() {
  var subAnchorsListElem = document.getElementsByClassName("subanchors");
  for (let i = 0; i < subAnchorsListElem.length; i++) {
    var anchor = subAnchorsListElem[i].getAttribute("href");
    if (anchor !== null && anchor !== "") {
      if (anchor.indexOf("?webview=1") > -1) {
        anchor = anchor.replace("?webview=1", "");
      }
      var elevalue = anchor.replace("#", "");

      if (anchor.startsWith("#") && document.getElementById(elevalue)) {
        document
          .getElementsByClassName("subanchors")
        [i].classList.remove("d-none");
        document
          .getElementsByClassName("subanchors")
        [i].classList.add("d-block");
      } else if (!anchor.startsWith("#")) {
        document
          .getElementsByClassName("subanchors")
        [i].classList.remove("d-none");
        document
          .getElementsByClassName("subanchors")
        [i].classList.add("d-block");
      } else {
        if (
          subAnchorsListElem[i].closest("li") !== null &&
          subAnchorsListElem[i].closest("li") !== ""
        ) {
          subAnchorsListElem[i].closest("li").remove();
          i = i - 1;
        }
      }
    }
  }
}

export function mainLoad() {
  var scrollToEle = document.querySelectorAll(".scrollTo");
  scrollToEle.forEach((x) => {
    x.addEventListener("click", function (e) {
      if (window.location.href.indexOf("webview") >= 0) {
        var querystring = getUrlQuerystringVars();
        if (querystring.webview == 1) {
          if (window.screen.width < 992) {
            var topcategoryheight = 0;
            if (
              document.getElementById("categoryfilter") != null &&
              document.getElementById("categoryfilter").style.height !=
              undefined
            ) {
              topcategoryheight =
                document.getElementById("categoryfilter").style.height;
            }
          }
          var idToScroll = e.currentTarget.getAttribute("href");

          if (idToScroll.indexOf("?webview=1") > -1) {
            idToScroll = idToScroll.replace("?webview=1", "");
          }
          var ele = idToScroll.replace("#", "");
          var rect = document.querySelector(idToScroll).getBoundingClientRect();

          if (ele != "" && document.getElementById(ele) != null) {
            window.scrollTo({ top: rect.top, behavior: "smooth" });
          }
        }
      } else {
        if (window.screen.width < 992) {
          var topcategoryheight = 0;
          if (
            document.getElementById("categoryfilter") != null &&
            document.getElementById("categoryfilter") != null
          ) {
            topcategoryheight =
              document.getElementById("categoryfilter").offsetHeight;
          }
          var heightHeader =
            document.getElementById("MainHeader").offsetHeight +
            topcategoryheight +
            document.getElementById("headerBlock").offsetHeight;
        } else {
          var heightHeader =
            document.getElementById("headerBlock").offsetHeight +
            document.getElementById("MainHeader").offsetHeight;
        }

        var idToScroll = e.currentTarget.getAttribute("href");
        var ele = idToScroll.replace("#", "");
        var rect = document.querySelector(idToScroll).getBoundingClientRect();

        if (ele != "" && document.getElementById(ele) != null) {
          window.scrollTo({ top: rect.top - heightHeader, behavior: "smooth" });
        }
      }
    });
  });
}


export function createCookie(name, value, days) {

  var analyticsOpted = false;
  if (value == "" && days == -1) analyticsOpted = true;

  if (days) {
    //Allowed for erasing the cookies.
    var date = new Date();
    date.setTime(date.getTime() + 24 * days * 60 * 60 * 1e3);
    var expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + value + expires + "; path=/";
  } else {
    var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }
}

export function getCookie(cname) {

  var name = cname + "=";
  if (typeof document !== "undefined") {
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function readCookie(name) {
  if (typeof document !== "undefined") {
    var i,
      nameEQ = name + "=",
      ca = document.cookie.split(";");
    for (i = 0; i < ca.length; i++) {
      for (var c = ca[i]; " " == c.charAt(0);) c = c.substring(1, c.length);
      if (0 == c.indexOf(nameEQ)) return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

//Create a cookie with expiry date given in UTC format
export function setCookie(name, value, exdate) {

  //If exdate exists then pass it as a new Date and convert to UTC format
  exdate && (exdate = new Date(exdate).toUTCString());
  var c_value =
    value +
    (exdate === null || exdate === undefined ? "" : "; expires=" + exdate) +
    "; path=/";
  document.cookie = name + "=" + c_value;
}

export function unibailscrollLoad() {
  var delay = 1000;
  setTimeout(function () {
    switchTabOrScroll();
    var pathQueryLocal = window.location.href.split(window.location.hostname);
    var pageAndQueryLocal =
      pathQueryLocal.length > 1 ? pathQueryLocal[1].toLowerCase() : "";
    var elementalphabet = document.getElementsByClassName(
      "filter-alphabetic-old"
    );

    if (
      elementalphabet.length == "0" &&
      pageAndQueryLocal.indexOf("?&strcategoryitemid") >= 0
    ) {
      var elementoffer = document.getElementById("offers");
      var heightHeader =
        document.getElementById("headerBlock").offsetHeight +
        document.getElementById("MainHeader").offsetHeight;
      if (document.getElementById("cookieWidgetID")) {
        if (
          document.getElementById("cookieWidgetID").style.display == "block"
        ) {
          if (elementoffer != "undefined" && elementoffer != null)
            window.scrollTo({
              top: elementoffer.offsetTop - heightHeader - 55,
              behavior: "smooth",
            });
        }
      } else {
        if (elementoffer != "undefined" && elementoffer != null)
          window.scrollTo({
            top: elementoffer.offsetTop - heightHeader - 30,
            behavior: "smooth",
          });
      }
    } else if (
      elementalphabet.length > 0 &&
      pageAndQueryLocal.indexOf("?&strcategoryitemid") >= 0
    ) {
      var heightHeader =
        document.getElementById("headerBlock").offsetHeight +
        document.getElementById("MainHeader").offsetHeight;
      if (document.getElementById("cookieWidgetID")) {
        if (
          document.getElementById("cookieWidgetID").style.display == "block"
        ) {
          if (elementalphabet != "undefined" && elementalphabet != null)
            window.scrollTo({
              top: elementalphabet.offsetTop - heightHeader - 55,
              behavior: "smooth",
            });
        }
      } else {
        if (elementalphabet != "undefined" && elementalphabet != null)
          window.scrollTo({
            top: elementalphabet.offsetTop - heightHeader - 30,
            behavior: "smooth",
          });
      }
    }
    if (
      baseModel.currentPageItemName == "stores" ||
      baseModel.currentPageItemName == "restaurants"
    ) {
      var storefilter = pageAndQueryLocal.replace(baseModel.siteUrlPrefix, "").split("/");
      if (storefilter.length > 2) {
        var elementoffer = document.getElementById("products");
        var topbrandheight = 0;
        var topletterheight = 0;
        if (document.getElementsByClassName("top-brands")[0] !== undefined) {
          if (
            document.getElementsByClassName("top-brands")[0].offsetHeight !==
            undefined
          )
            topbrandheight =
              document.getElementsByClassName("top-brands")[0].offsetHeight;
        }
        if (
          document.getElementsByClassName("filter-alphabetic-old")[0] !=
          undefined
        ) {
          if (
            document.getElementsByClassName("filter-alphabetic-old")[0]
              .offsetHeight != undefined
          )
            topletterheight = document.getElementsByClassName(
              "filter-alphabetic-old"
            )[0].offsetHeight;
        }
        if (window.screen.width < 992) {
          var heightHeader =
            document.getElementById("categoryfilter").offsetHeight +
            document.getElementById("headerBlock").offsetHeight +
            topbrandheight +
            topletterheight;
        } else {
          var heightHeader =
            document.getElementById("headerBlock").offsetHeight +
            document.getElementById("MainHeader").offsetHeight;
        }
        if (document.getElementById("cookieWidgetID")) {
          if (
            document.getElementById("cookieWidgetID").style.display == "block"
          ) {
            if (elementoffer != "undefined" && elementoffer != null)
              window.scrollTo({
                top: elementoffer.offsetTop - heightHeader - 55,
                behavior: "smooth",
              });
          }
        } else {
          if (elementoffer != "undefined" && elementoffer != null)
            window.scrollTo({
              top: elementoffer.offsetTop - heightHeader,
              behavior: "smooth",
            });
        }
      }
    }
    else if (baseModel.currentPageItemName === "news" || baseModel.currentPageItemName === "events") {
      var eventsfilter = pageAndQueryLocal.replace(baseModel.siteUrlPrefix, "").split("/");
      var cookieValue = readCookie("anchorclick");
      if (eventsfilter.length > 2 || cookieValue) {
        var elementoffer = document.getElementById("events");
        if (elementoffer) {
          createCookie("anchorclick", "", -1);
          var heightHeader =
            document.getElementById("headerBlock").offsetHeight +
            document.getElementById("MainHeader").offsetHeight;
          // var offersAndNewsheight = "0px";
          // var shoplandinganchorsheight = "0px";
          // if (document.getElementById("news")) {
          //   offersAndNewsheight = document.getElementById("news").offsetHeight;
          // }
          // if (document.getElementById("shoplandinganchors")) {
          //   shoplandinganchorsheight = document.getElementById("shoplandinganchors").offsetHeight;
          // }
          
          window.scrollTo({
            top: elementoffer.offsetTop - heightHeader, //- offersAndNewsheight -shoplandinganchorsheight,
            behavior: "smooth",
          });
        }
      }
    }
  }, delay);
}

export function scrollToHidden() {
  var scrollToHiddenEle = document.querySelectorAll(".scrollToHidden");

  scrollToHiddenEle.forEach((x) => {
    x.addEventListener("click", function (e) {
      e.preventDefault();
      var idToScroll = e.currentTarget.getAttribute("href");
      if (idToScroll.indexOf("myBookmarks") > -1) {
        document.getElementById("bookmarkli").classList.add("active");
        document.getElementById("myBookmarks").style.display = "block";
        idToScroll = idToScroll.replace("#myBookmarks", "");
        showTab(idToScroll);
        idToScroll = "#" + idToScroll;
      }
      if (idToScroll.indexOf("?webview=1") > -1) {
        idToScroll = idToScroll.replace("?webview=1", "");
      }
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
      if (
        idToScroll != "" &&
        idToScroll != "#" &&
        document.querySelector(idToScroll) != null
      ) {
        var heightHeader = 0;
        if (
          document.getElementById("MainHeader") !== null &&
          document.getElementById("headerBlock") !== null
        ) {
          if (
            document.getElementById("MainHeader").offsetWidth <= 0 &&
            document.getElementById("MainHeader").offsetHeight <= 0
          ) {
            heightHeader =
              document.getElementById("headerBlock") != null
                ? document.getElementById("headerBlock").offsetHeight
                : 0;
          } else {
            var headerBlock =
              document.getElementById("headerBlock") != null
                ? document.getElementById("headerBlock").offsetHeight
                : 0;
            var mainHeader =
              document.getElementById("MainHeader") != null
                ? document.getElementById("MainHeader").offsetHeight
                : 0;
            heightHeader = headerBlock + mainHeader;
          }
        }
        window.scrollTo({
          top: document.querySelector(idToScroll).offsetTop - heightHeader,
          behavior: "smooth",
        });
      }
    });
  });
}

export function fngtmExtraLiveClick(eventName) {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    event: (eventName !== null && eventName !== "") ? eventName : undefined,
    page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

export function fngtmLiveDetailClick(fields, eventName, liveInteractionName, status) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  if (eventName != null && eventName != undefined && eventName == 'live_interaction') {
    window.dataLayer.push({
      associated_category_id: fields.liveEvent.videoStreamInfo.gtmCategoryId ? fields.liveEvent.videoStreamInfo.gtmCategoryId : undefined,
      associated_category_name: fields.liveEvent.videoStreamInfo.gtmCategoryName ? fields.liveEvent.videoStreamInfo.gtmCategoryName : undefined,
      associated_country: fields.liveEvent.videoStreamInfo.country ? fields.liveEvent.videoStreamInfo.country : undefined,
      associated_retailer_id: fields.liveEvent.videoStreamInfo.gtmRetailerID ? fields.liveEvent.videoStreamInfo.gtmRetailerID : undefined,
      associated_retailer_name: fields.liveEvent.videoStreamInfo.gtmRetailerName ? fields.liveEvent.videoStreamInfo.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      live_interaction_name: liveInteractionName ? liveInteractionName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    })
  }
  else if (status !== undefined && status !== null && status !== "") {
    window.dataLayer.push({
      associated_category_id: fields.liveEvent.videoStreamInfo.gtmCategoryId ? fields.liveEvent.videoStreamInfo.gtmCategoryId : undefined,
      associated_category_name: fields.liveEvent.videoStreamInfo.gtmCategoryName ? fields.liveEvent.videoStreamInfo.gtmCategoryName : undefined,
      associated_country: fields.liveEvent.videoStreamInfo.country ? fields.liveEvent.videoStreamInfo.country : undefined,
      associated_retailer_id: fields.liveEvent.videoStreamInfo.gtmRetailerID ? fields.liveEvent.videoStreamInfo.gtmRetailerID : undefined,
      associated_retailer_name: fields.liveEvent.videoStreamInfo.gtmRetailerName ? fields.liveEvent.videoStreamInfo.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
      extra_live_event_status: status,
    })
  }
  else {
    window.dataLayer.push({
      associated_category_id: fields.liveEvent.videoStreamInfo.gtmCategoryId ? fields.liveEvent.videoStreamInfo.gtmCategoryId : undefined,
      associated_category_name: fields.liveEvent.videoStreamInfo.gtmCategoryName ? fields.liveEvent.videoStreamInfo.gtmCategoryName : undefined,
      associated_country: fields.liveEvent.videoStreamInfo.country ? fields.liveEvent.videoStreamInfo.country : undefined,
      associated_retailer_id: fields.liveEvent.videoStreamInfo.gtmRetailerID ? fields.liveEvent.videoStreamInfo.gtmRetailerID : undefined,
      associated_retailer_name: fields.liveEvent.videoStreamInfo.gtmRetailerName ? fields.liveEvent.videoStreamInfo.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    })
  }
}

export function fngtmModuleclick(item, eventName, moduleName) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  if (eventName != null && eventName != undefined && eventName == 'click_watch_now') {
    window.dataLayer.push({
      associated_category_id: item.gtmCategoryId ? item.gtmCategoryId : undefined,
      associated_category_name: item.gtmCategoryName ? item.gtmCategoryName : undefined,
      associated_country: item.country ? item.country : undefined,
      associated_retailer_id: item.gtmRetailerID ? item.gtmRetailerID : undefined,
      associated_retailer_name: item.gtmRetailerName ? item.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    })
  }
  else if (eventName != null && eventName != undefined && (eventName == 'click_notify_me' || eventName == 'email_notification')) {
    window.dataLayer.push({
      associated_category_id: item.gtmCategoryId ? item.gtmCategoryId : undefined,
      associated_category_name: item.gtmCategoryName ? item.gtmCategoryName : undefined,
      associated_country: item.country ? item.country : undefined,
      associated_retailer_id: item.gtmRetailerID ? item.gtmRetailerID : undefined,
      associated_retailer_name: item.gtmRetailerName ? item.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      module_name: moduleName ? moduleName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    })
  }
  else {
    window.dataLayer.push({
      associated_category_id: item.gtmCategoryId ? item.gtmCategoryId : undefined,
      associated_category_name: item.gtmCategoryName ? item.gtmCategoryName : undefined,
      associated_country: item.country ? item.country : undefined,
      associated_retailer_id: item.gtmRetailerID ? item.gtmRetailerID : undefined,
      associated_retailer_name: item.gtmRetailerName ? item.gtmRetailerName : undefined,
      module_name: moduleName ? moduleName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
      extra_live_event_name: item.name ? item.name : undefined,
    })
  }
}

export function fngtmVpvComponent(liveShoppingevent, eventName, liveInteractionName) {
  var finishAtTime = new Date(liveShoppingevent.finishesAt);
  var startAtTime = new Date(liveShoppingevent.startsAt);
  var localeDateTime = new Date();
  let status = startAtTime < localeDateTime && finishAtTime > localeDateTime ? "Direct" :
    startAtTime > localeDateTime && finishAtTime > localeDateTime ? "Upcoming" : "Replay";

  if (!global.isGTMTaggingEnabled) {
    return;
  }
  if (liveInteractionName !== null && liveInteractionName != "" && liveInteractionName != undefined) {
    window.dataLayer.push({
      associated_category_id: liveShoppingevent.gtmCategoryId ? liveShoppingevent.gtmCategoryId : undefined,
      associated_category_name: liveShoppingevent.gtmCategoryName ? liveShoppingevent.gtmCategoryName : undefined,
      associated_country: liveShoppingevent.country ? liveShoppingevent.country : undefined,
      associated_retailer_id: liveShoppingevent.gtmRetailerID ? liveShoppingevent.gtmRetailerID : undefined,
      associated_retailer_name: liveShoppingevent.gtmRetailerName ? liveShoppingevent.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      live_interaction_name: liveInteractionName ? liveInteractionName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    });


  }
  else if (eventName === "extra_live_video_view" || eventName === "extra_live_video_start") {
    window.dataLayer.push({
      associated_category_id: liveShoppingevent.gtmCategoryId ? liveShoppingevent.gtmCategoryId : undefined,
      associated_category_name: liveShoppingevent.gtmCategoryName ? liveShoppingevent.gtmCategoryName : undefined,
      associated_country: liveShoppingevent.country ? liveShoppingevent.country : undefined,
      associated_retailer_id: liveShoppingevent.gtmRetailerID ? liveShoppingevent.gtmRetailerID : undefined,
      associated_retailer_name: liveShoppingevent.gtmRetailerName ? liveShoppingevent.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
      extra_live_event_status: status,
    });
  }
  else {
    window.dataLayer.push({
      associated_category_id: liveShoppingevent.gtmCategoryId ? liveShoppingevent.gtmCategoryId : undefined,
      associated_category_name: liveShoppingevent.gtmCategoryName ? liveShoppingevent.gtmCategoryName : undefined,
      associated_country: liveShoppingevent.country ? liveShoppingevent.country : undefined,
      associated_retailer_id: liveShoppingevent.gtmRetailerID ? liveShoppingevent.gtmRetailerID : undefined,
      associated_retailer_name: liveShoppingevent.gtmRetailerName ? liveShoppingevent.gtmRetailerName : undefined,
      event: (eventName !== null && eventName !== "") ? eventName : undefined,
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    });
  }
}

export function fngtmBookingTicket(model) {

  if (!global.isGTMTaggingEnabled) {
    return;
  }
  if (model !== null && model !== undefined && model !== "") {
    window.dataLayer.push({
      event: "book_tickets",
      provider_name: model.provideName !== null && model.provideName !== undefined && model.provideName !== "" ? model.provideName : undefined,
      booking_event_name: model.displayName !== null && model.displayName !== undefined && model.displayName !== "" ? model.displayName : undefined,
      booking_event_id: model.eventID !== null && model.eventID !== undefined && model.eventID !== "" ? model.eventID : undefined,
    });
  }
}

export function fngtmWorldOrCountrySelDataLayer(eventName, title) {
  if (window.dataLayer !== null && window.dataLayer !== undefined && window.dataLayer !== "") {
    window.dataLayer.push({
      'event': eventName,
      'page': (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
      'selected_center_name': title !== null && title !== "" ? title : undefined,
    });
  }
}



//Load GoogleMaps
export function webViewEqualsOne() {
  if (window.location.href.indexOf("webview") >= 0) {
    var querystring = getUrlQuerystringVarsWebviewEqualsOne();
    if (querystring.webview == 1) {
      var querystring = "webview=1";
      var domainName = window.location.hostname;
      var anchortag = document.getElementsByTagName("a");
      for (let i = 0; i < anchortag.length; ++i) {
        var datatoggle = anchortag[i].getAttribute("data-bs-toggle");
        if (datatoggle != "collapse" && datatoggle != "tab") {
          var href = anchortag[i].getAttribute("href");
          if (anchortag[1] !== null && anchortag[1] !== undefined) {
            var anchorDomainName =
              domainName + anchortag[1].getAttribute("href");

            if (href != undefined && href != "") {
              if (anchorDomainName.indexOf(domainName) >= 0) {
                if (!href.includes(querystring)) {
                  if (
                    href != "#" &&
                    href != "/" &&
                    href != "javascript: void (0)"
                  ) {
                    if (href.includes("#")) {
                      var spliturl = href.split("#");
                      if (
                        spliturl != null &&
                        spliturl != undefined &&
                        spliturl != ""
                      ) {
                        if (spliturl.length == 2) {
                          href =
                            spliturl[0] +
                            (spliturl[0].match(/\?/) ? "&" : "?") +
                            querystring +
                            "#" +
                            spliturl[1];
                          anchortag[i].setAttribute("href", href);
                        }
                      }
                    } else {
                      if (
                        href.startsWith("http") ||
                        href.startsWith("https") ||
                        href.startsWith("www")
                      ) {
                      } else {
                        href += (href.match(/\?/) ? "&" : "?") + querystring;
                        anchortag[i].setAttribute("href", href);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

function addTrafficToggle(controlDiv, map) {
  controlDiv.style.padding = "5px";
  var controlUI = document.createElement("div");
  controlUI.style.backgroundColor = "white";
  controlUI.style.border = "1px solid";
  controlUI.style.cursor = "pointer";
  controlUI.style.textAlign = "center";
  controlUI.title = "Hide the current traffic";
  controlDiv.appendChild(controlUI);
  var controlText = document.createElement("div");
  controlText.style.fontFamily = "Arial,sans-serif";
  controlText.style.fontSize = "12px";
  controlText.style.paddingLeft = "4px";
  controlText.style.paddingRight = "4px";
  controlText.innerHTML = "<b>Hide Traffic<b>";
  controlUI.appendChild(controlText);
  controlTraffic = controlUI;
}

export function initialize(CenterLatitude, CenterLongitude) {
  if (window?.google?.maps !== undefined) {
    directionsDisplay = new window.google.maps.DirectionsRenderer();
    if (
      CenterLatitude != undefined &&
      CenterLatitude != null &&
      CenterLongitude != undefined &&
      CenterLongitude != null
    ) {
      var mapProp = {
        center: new window.google.maps.LatLng(CenterLatitude, CenterLongitude),
        zoom: 15,
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      };

      var map = new window.google.maps.Map(
        document.getElementById("googleMap"),
        mapProp
      );
      directionsDisplay.setMap(map);
      directionsDisplay.setPanel(document.getElementById("itineraire"));
      var marker = new window.google.maps.Marker({
        position: mapProp.center,
        map: map,
      });
      var trafficLayer = new window.google.maps.TrafficLayer();
      trafficLayer.setMap(map);
      // Create a DIV to hold the control and call HomeControl()
      var homeControlDiv = document.createElement("div");
      var homeControl = new addTrafficToggle(homeControlDiv, map);
      window.google.maps.event.addDomListener(
        controlTraffic,
        "click",
        function () {
          if (
            typeof trafficLayer.getMap() == "undefined" ||
            trafficLayer.getMap() === null
          ) {
            trafficLayer.setMap(map);
            controlTraffic.innerHTML = "<b>Hide Traffic</b>";
            controlTraffic.style.fontFamily = "Arial,sans-serif";
            controlTraffic.style.fontSize = "12px";
            controlTraffic.style.paddingLeft = "4px";
            controlTraffic.style.paddingRight = "4px";
          } else {
            trafficLayer.setMap(null);
            controlTraffic.innerHTML = "<b>Show Traffic</b>";
            controlTraffic.style.fontFamily = "Arial,sans-serif";
            controlTraffic.style.fontSize = "12px";
            controlTraffic.style.paddingLeft = "4px";
            controlTraffic.style.paddingRight = "4px";
          }
        }
      );
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        homeControlDiv
      );
    }
  }
}


export function calcRoute(CenterLatitude, CenterLongitude) {
  unibailGooglemapLoad();
  if (window?.google?.maps !== undefined) {
    var directionsService = new window.google.maps.DirectionsService();
    var start = document.getElementById("targetAddress").value;
    var end = new window.google.maps.LatLng(
      CenterLatitude != undefined ? CenterLatitude : "",
      CenterLongitude != undefined ? CenterLongitude : ""
    );
    var request = {
      origin: start,
      destination: end,
      travelMode: getTravelMode(),
    };
    directionsService.route(request, function (response, status) {
      if (status == window.google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      }
    });
  }
}

function getTravelMode() {
  var travelModeValue;
  if (document.getElementById("car").checked) {
    travelModeValue = document.getElementById("car").value;
  }
  if (document.getElementById("foot").checked) {
    travelModeValue = document.getElementById("foot").value;
  }
  if (document.getElementById("transit").checked) {
    travelModeValue = document.getElementById("transit").value;
  }
  return travelModeValue;
}

export function unibailGooglemapLoad() {
  if (document.getElementById("car").checked) {
    var ele = document.querySelector(".js-access-tab .nav-link.active");

    document
      .querySelector(".js-access-tab .nav-link.active")
      .classList.remove("active");
    document.getElementById("car").parentElement.classList.add("active");
  }
  if (document.getElementById("foot").checked) {
    document
      .querySelector(".js-access-tab .nav-link.active")
      .classList.remove("active");
    document.getElementById("foot").parentElement.classList.add("active");
  }
  if (document.getElementById("transit").checked) {
    document
      .querySelector(".js-access-tab .nav-link.active")
      .classList.remove("active");
    document.getElementById("transit").parentElement.classList.add("active");
  }
  document.getElementById("car").onchange = function () {
    document
      .querySelector(".js-access-tab .nav-link.active")
      .classList.remove("active");
    document.getElementById("car").parentElement.classList.add("active");
  };
  document.getElementById("foot").onchange = function () {
    document
      .querySelector(".js-access-tab .nav-link.active")
      .classList.remove("active");
    document.getElementById("foot").parentElement.classList.add("active");
  };
  document.getElementById("transit").onchange = function () {
    document
      .querySelector(".js-access-tab .nav-link.active")
      .classList.remove("active");
    document.getElementById("transit").parentElement.classList.add("active");
  };
}

function showTab(id) {
  var refId = [
    "services",
    "offers",
    "event",
    "stores",
    "movies",
    "restaurants",
    "pages",
    "publicestablishmentsearch",
  ];

  for (let i = 0; i < refId.length; i++) {
    document.getElementById(refId[i]).style.display = "none";
  }

  if (id == "publicestablishmentsearch") {
    return (document.getElementById("publicestablishmentsearch").style.display =
      "block");
  }
  if (id == "services") {
    return document.getElementById("services").style.display == "block";
  }
  if (id == "offers") {
    return document.getElementById("offers").style.display == "block";
  }
  if (id == "event") {
    return document.getElementById("event").style.display == "block";
  }
  if (id == "stores") {
    return document.getElementById("stores").style.display == "block";
  }
  if (id == "movies") {
    return document.getElementById("movies").style.display == "block";
  }
  if (id == "restaurants") {
    return document.getElementById("restaurants").style.display == "block";
  }
  if (id == "pages") {
    return document.getElementById("pages").style.display == "block";
  }
}

export function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight ||
      document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
    (window.innerWidth ||
      document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export function isElementPresentInViewport(element, options = {}) {
  const rect = element.getBoundingClientRect();
  const height = window.innerHeight || document.documentElement.clientHeight;
  const width = window.innerWidth || document.documentElement.clientWidth;
  const offset = { left: 0, right: 0, top: 0, bottom: 0, ...options };

  // At least 1 corner is in viewport
  return (
    rect.right >= -offset.left &&
    rect.bottom >= -offset.top &&
    rect.left <= width + offset.right &&
    rect.top <= height + offset.bottom
  );
}

function GetDevice() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}

export function isDesktopdevice() {
  var device = GetDevice();
  if (device == "desktop") {
    document.body.classList.add("desktop");
  }
}

//Loads the Login pop-up into the div element defined in BaseLayout.cshtml

export function lazyLoadingImg() {
  document
    .querySelectorAll("img[src='" + baseModel.defaultImage + "']")
    .forEach((x) => {
      var isInViewport;
      if (
        x.id !== "" &&
        x.id !== null &&
        x.id.includes("mallGalleryLazyLoadImg")
      ) {
        isInViewport = false;
      } else {
        isInViewport = isElementPresentInViewport(x);
      }

      if (
        x.getAttribute("data-alt") != undefined &&
        x.getAttribute("data-alt") != "" &&
        isInViewport
      ) {
        x.setAttribute("src", x.getAttribute("data-alt"));
      }
    });
}

export function mobileValidation() {
  var UnibailDetectResolution = {
    config: {
      tablet: 970,
      mobile: 768,
    },
    detect: function (elem) {
      var $bodyW = elem.offsetWidth;
      $bodyW < UnibailDetectResolution.config.tablet &&
        $bodyW >= UnibailDetectResolution.config.mobile
        ? UnibailDetectResolution.isTablet(elem)
        : $bodyW < UnibailDetectResolution.config.mobile
          ? UnibailDetectResolution.isMobile(elem)
          : UnibailDetectResolution.isDesktop(elem);
    },
    isTablet: function (elem) {
      elem.classList.remove("on-tablet");
      elem.classList.remove("on-mobile");
      elem.classList.remove("on-desktop");
      elem.classList.add("on-tablet");
    },
    isMobile: function (elem) {
      elem.classList.remove("on-tablet");
      elem.classList.remove("on-mobile");
      elem.classList.remove("on-desktop");
      elem.classList.add("on-mobile");
    },
    isDesktop: function (elem) {
      elem.classList.remove("on-tablet");
      elem.classList.remove("on-mobile");
      elem.classList.remove("on-desktop");
      elem.classList.add("on-desktop");
    },
  };

  if (isMobile.Android()) {
    document.body.classList.add("android");
  }
  if (isMobile.iOS()) {
    document.body.classList.add("iOS");
  }

  //For Hiding the Footer title and description in Mobile view
  //if the applicable Download option is not there.
  if (isMobile.Android()) {
    var googlePlay = document.getElementById("footergooglePlay");
    if (
      (googlePlay == "undefined" || googlePlay == null) &&
      document.getElementById("footerTop") != undefined &&
      document.getElementById("footerTop") != null
    )
      document.getElementById("footerTop").style.display = "none";
  }
  if (isMobile.iOS()) {
    var appStore = document.getElementById("footerappStore");
    if (
      (appStore == "undefined" || appStore == null) &&
      document.getElementById("footerTop") != undefined &&
      document.getElementById("footerTop") != null
    )
      document.getElementById("footerTop").style.display = "none";
  }
}

//Extend/reset the cookie expiry limit
export function fnResetCookieExpiry() {
  var iCookieExpiry = baseModel.cookieExpiryMinutes;

  var date = new Date().toUTCString();
  var CurrentDate = new Date(Date.parse(date));
  var expiry = CurrentDate.setMinutes(+CurrentDate.getMinutes() + +iCookieExpiry);
  if (getCookie("UUID") != "") setCookie("UUID", getCookie("UUID"), expiry);
}

export function offersClick(
  e,
  link,
  isDetailPage,
) {
  var element = e.target;
  if (element.classList.contains("js-tooltip")) {
    //if clicked on the 'i' icon, flip the block to show the description
    flip(e);

    return;
  }
  if (element.classList.contains("flip-close", "picto", "icon-ico-close")) {
    //close of the flip to hide the description and show offer block
    var displayStyle = "flex";
    // if (compoType === "shopLandingOffers") displayStyle = "flex";
    closeFlip(e, displayStyle);
    return;
  }
  //no action when - click on anywhere inside flipped description block, if current page is store detail page & user is already logged in, or clicked on bookmark to remove bookmarking
  if (
    (((element.classList.contains("remove-flip") &&
      element.classList.contains("flip-back")) ||
      element.classList.contains("flip-message")) &&
      element.style.display != "none") ||
    isDetailPage != false ||
    element.classList.contains("remove-bookmark")
  ) {
    return;
  }
  if (element.classList.length > 0) {
    //when user already logged in and clicked on Store/Restaurant name and is not a store detail page(already covered in previous 'if')
    var link =
      element.getAttribute("data-link") != undefined
        ? element.getAttribute("data-link")
        : link;

    var offerAnchorNav = "";
    if (isDetailPage == false) {
      offerAnchorNav = "#offers";
    }
    link =
      link.startsWith("http") ||
        link.startsWith("https") ||
        link.startsWith("www")
        ? link + offerAnchorNav
        : baseModel.siteUrlPrefix + link + offerAnchorNav;
    URWindowLocation(link);
  }
}

export function offersTooltipOnHover() {
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
  if (!isMobileView) {
    const ele = document.querySelectorAll('.js-tooltip');
    const ele1 = document.querySelectorAll('.flip-card');

    // Add a mouseover event listener
    ele.forEach((x) => {
      x.addEventListener('mouseover', (e) => {
        flip(e);
      });

    })

    // Add a mouseout event listener
    ele1.forEach((x) => {
      x.addEventListener('mouseleave', (e) => {
        var displayStyle = "block";
        closeFlip(e, displayStyle);
      });
    });
  }
}

export function flip(event) {
  event.stopPropagation();
  event.target.closest(".flip-card").querySelector(".flip-back").style.display =
    "block";
  event.target
    .closest(".flip-card")
    .querySelector(".flip-back")
    .classList.remove("flipped");
  event.target
    .closest(".flip-card")
    .querySelector(".flip-back")
    .classList.add("remove-flip");
  event.target
    .closest(".flip-card")
    .querySelector(".flip-front").style.display = "none";
  event.target
    .closest(".flip-card")
    .querySelector(".flip-front")
    .classList.remove("remove-flip");
  event.target
    .closest(".flip-card")
    .querySelector(".flip-front")
    .classList.add("flipped");
}

export function closeFlip(event, displayStyle) {
  event.stopPropagation();
  event.target
    .closest(".flip-card")
    .querySelector(".flip-front").style.display = displayStyle;
  event.target
    .closest(".flip-card")
    .querySelector(".flip-front")
    .classList.remove("flipped");
  event.target
    .closest(".flip-card")
    .querySelector(".flip-front")
    .classList.add("remove-flip");
  event.target.closest(".flip-card").querySelector(".flip-back").style.display =
    "none";
  event.target
    .closest(".flip-card")
    .querySelector(".flip-back")
    .classList.remove("remove-flip");
  event.target
    .closest(".flip-card")
    .querySelector(".flip-back")
    .classList.add("flipped");
}


//Update the current context page item name in the specified Data attribute's value
export function fnUpdatePageNameInDataAttr(element, dataAttributeName) {
  var attributeValue = element.dataset[dataAttributeName];

  if (attributeValue != undefined && attributeValue != "") {
    var changedvalue = attributeValue.replace(
      "{pagename}",
      baseModel.currentPageItemName
    );
    element.setAttribute("data-" + dataAttributeName, changedvalue);
  }
}

//Update the retailer Name in the specified data attribute's value
export function fnUpdateStoreSINameInDataAttr(element, dataAttributeName) {
  var attributeValue = element.dataset[dataAttributeName];

  if (attributeValue != undefined && attributeValue != "") {
    var changedvalue = attributeValue.replace(
      "{storeSIName}",
      he.decode(baseModel.storeSIName)
    );
    element.setAttribute("data-" + dataAttributeName, changedvalue);
  }
}

function unibailAnalyticsGTMLoad() {
  var globalTimer = null;

  //When navigated by clicking on internal links, create clickPosition & clickLabel cookies - to be used for Page Load dataLayer variables
  if (
    document.querySelectorAll(".gtmClickNavigation") !== null &&
    document.querySelectorAll(".gtmClickNavigation") !== undefined
  ) {
    document.querySelectorAll(".gtmClickNavigation").forEach((x) => {
      x.addEventListener("click", function (e) {
        if (e.currentTarget.classList.contains("inner-toggler")) {
          //In Mobile - Header navigation, there is span for just showing sub-navigations. This span will not actually navigate away to another page. So, do not store GTM navigation data when this span is clicked.
          return;
        }
        fnClickNavigation(e.currentTarget);
      });
    });
  }

}

//gtm's
export function fnGtmSocailNetwork(socialNetwork) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    event: 'follow',
    page: (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    social_network: (socialNetwork !== undefined && socialNetwork !== null && socialNetwork !== "") ? socialNetwork : undefined,
  });
}

export function fnGtmAppClickDownload(appOSName) {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    app_os: (appOSName !== undefined && appOSName !== null && appOSName !== "") ? appOSName : undefined,
    event: 'click_app_download',
    page: (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

export function fnGtmVideo(eventName, templateName, videoUrl, VideoTitle, videoDuration, videoCurrentTime) {
  var completeperc = "";
  var videoProvider = getVideoTypeFromvideoUrl("", videoUrl);
  if (videoCurrentTime === null && eventName === "video_started")
    videoCurrentTime = 0;

  if (videoCurrentTime !== "" && videoCurrentTime !== null && videoCurrentTime !== undefined && videoDuration !== "" && videoDuration !== null && videoDuration !== undefined) {
    completeperc = videoCurrentTime / videoDuration * 100;
  }

  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    event: (eventName !== null && eventName !== "") ? eventName : undefined,
    video_current_time: Math.round(videoCurrentTime),
    video_duration: Math.round(videoDuration),
    video_percent: Math.round(completeperc),
    video_provider: (videoProvider !== null && videoProvider !== "") ? videoProvider : undefined,
    video_title: (VideoTitle !== null && VideoTitle !== "") ? VideoTitle : undefined,
    video_url: (videoUrl !== null && videoUrl !== "") ? videoUrl : undefined,
    visible: true
  });
}

export function fnGtmAddToFavorite(favoriteName, favoriteType) {
  const state = store.getState();
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    event: 'add_to_favorite',
    favorite_Name: (favoriteName !== null && favoriteName !== "") ? favoriteName : undefined,
    favorite_Type: favoriteType,
    page: (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    favorite_offers: state.user.bookmarkedOffer !== null && state.user.bookmarkedOffer !== "" ? state.user.bookmarkedOffer : undefined,
    favorite_events: state.user.bookmarkedEvent !== null && state.user.bookmarkedEvent !== "" ? state.user.bookmarkedEvent : undefined,
    favorite_stores: state.user.bookmarkedStore !== null && state.user.bookmarkedStore !== "" ? state.user.bookmarkedStore : undefined,
    favorite_restaurants: state.user.bookmarkedRestaurant !== null && state.user.bookmarkedRestaurant !== "" ? state.user.bookmarkedRestaurant : undefined
  });
}

export function fnGtmCategorySearchFilter(event, filterCategory, filterDate, filterSearchResults, filterType, templateName) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'search_filter',
    'filter_category': (filterCategory !== null && filterCategory !== "") ? filterCategory : undefined,
    'filter_date': (filterDate !== null && filterDate !== "") ? filterDate : undefined,
    'filter_search_results': (filterSearchResults !== null && filterSearchResults !== "") ? filterSearchResults : undefined,
    'filter_type': (filterType !== null && filterType !== "") ? filterType : undefined,
    'page': (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
  });
}

//Add gtm click events if gtm is enabled
export function AddGtmEventListeners() {
  //Add click navigation analytics changes for news mosaic block
  document
    .querySelectorAll(".whats-hot-mosiac .gtmClickNavigation")
    .forEach((item) => {
      item.addEventListener("click", function (event) {
        fnClickNavigation(event.currentTarget);
      });
    });



  if (global.isGTMTaggingEnabled) {
    unibailAnalyticsGTMLoad();
  }
}

//Create cookies for ClickPosition and ClickLabel when an element is clicked
export function fnClickNavigation(currentElement) {
  lazyLoadingImg();
}

//Load HomePageCinema component - Movies list and movie count asynchronously
export function LoadHomePageCinema(hpCinemaDatasource) {
  if (
    document.getElementById("homePageCinema") != undefined &&
    document.getElementById("homePageCinema") != null
  ) {
    ajaxCallPresent = true;
    ajaxCallIncrement = ajaxCallIncrement + 1;
    var url =
      "/api/sitecore/MoviesApi/GetHomePageCinemaInner?strDatasource=" +
      hpCinemaDatasource +
      "&ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang;
    axios({
      method: "GET",
      url: url,
      async: true,
    })
      .then(function (response) {
        {
          createRoot(document.getElementById("homePageCinemaInner"))
            .render(<HomePageCinemaInner model={response.data} />);
        }
        webViewEqualsOne();
        var loaderEle = document.getElementById("loaderDivCinema");
        if (loaderEle !== null) {
          loaderEle.remove();
        }
        var numberOfCinemaBlocks = document.querySelectorAll(
          "#homePageCinemaInner .movie-block"
        ).length;
        if (numberOfCinemaBlocks > 0) {
          if (
            document.getElementById("spnMoviesCount") != undefined &&
            document.getElementById("spnMoviesCount") != null &&
            document.getElementById("spnMoviesCount").length > 0
          ) {
            document.querySelectorAll("spnMoviesCount")[0].innerText =
              "(" + document.getElementById("hdnMoviesCount").value + ")"; //bind the Total movies count from the hidden field in Partial view to the span in Main view
          }
          if (document.getElementById("homePageCinemaHeader") !== null)
            document
              .getElementById("homePageCinemaHeader")
              .classList.remove("hide");
        } else {
          if (document.querySelectorAll("#homePageCinema")[0] !== undefined)
            document.querySelectorAll("#homePageCinema")[0].style.display =
              "none"; //hide the HomePageCinema component when there are no movies found
          fnApplyPyjamaEffect();
        }
        lazyLoadingImg();
      })
      .catch(function (error) {
        console.log(error);
        var loaderEle = document.getElementById("loaderDiv");
        if (loaderEle !== null) {
          loaderEle.remove();
        }
        if (document.querySelectorAll("#homePageCinema")[0] !== undefined)
          document.querySelectorAll("#homePageCinema")[0].style.display =
            "none"; //hide the HomePageCinema component when there are no movies found
      });
  }
}

//Function to track GTM - view rate events
var itemsViewed = [];

export function fnGTMViewEventTracking() {
  if (!global.isGTMTaggingEnabled) {
    return;
  }

  var list = document.getElementsByClassName("gtmcarousel");
  for (var i = 0; i < list.length; i++) {
    const isInViewport =
      list[i].offsetTop < window.scrollY + window.innerHeight + 500;
    if (isInViewport) {
      fnGTMCarouselViewEvent(list[i]);
    }
  }

}

//Function to track GTM - Carousel view rate event
export function fnGTMCarouselViewEvent(carouselObj) {
  const isInViewport = isElementInViewport(carouselObj); //carouselObj.offsetTop < (window.scrollY + window.innerHeight + 500);
  if (
    !global.isGTMTaggingEnabled ||
    carouselObj == null ||
    carouselObj == undefined ||
    !isInViewport
  ) {
    return;
  }
  var activeBanner = document.querySelectorAll(
    ".slick-active div.gaCarouselView"
  );
  if (
    activeBanner == null ||
    activeBanner == undefined ||
    activeBanner.length == 0 ||
    itemsViewed.indexOf(activeBanner[0].getAttribute("data-id")) >= 0
  ) {
    return;
  }

  itemsViewed.push(activeBanner[0].getAttribute("data-id"));
  window.dataLayer.push({
    carrousel_position: activeBanner[0].getAttribute("data-position")
      ? activeBanner[0].getAttribute("data-position")
      : 1,
    event: "view_carrousel_banner",
  });
}

//Push the GTM event & variables for click on Carousel prev & next arrows
function fnCarouselArrowEvent(arrowDirection) {
  if (!global.isGTMTaggingEnabled || arrowDirection == "") {
    return;
  }
  window.dataLayer.push({
    event: "carrouselArrows",
    arrowsDirection: arrowDirection,
  });
}

//Push the GTM event for click on Carousel dots
export function fnCarouselDotsEvent(carouselPosition) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'carrousel_position': (carouselPosition !== undefined && carouselPosition !== null && carouselPosition !== "") ? carouselPosition : undefined,
    'event': 'click_carrousel_bullet',

  });
}
export function carouselLoad() {
  // On before slide change - added for Analytics - GTM Tag plan
  document.querySelectorAll(".gacarousel").forEach((item) => {
    item.addEventListener("afterChange", function (event, slick, currentSlide) {
      fnGTMCarouselViewEvent(event.currentTarget);
    });
  });
}

//ListOfShopRestaurantCategory
export function letterFilter(e1Value, ele) {
  if (ele.classList.contains("disablehref")) {
    ele.removeEventListener("click", null);
    return false;
  }
  if (e1Value.length == 3 || e1Value == "Others") {
    document.querySelectorAll("#products .letter-filter").forEach((x) => {
      if (x.classList.contains("d-block")) {
        x.classList.remove("d-block");
        x.classList.add("d-none");
      } else x.classList.add("d-none");
    });
    document
      .querySelectorAll("#products .letter-filterLoyaltyPillar")
      .forEach((x) => {
        if (x.classList.contains("d-block")) {
          x.classList.remove("d-block");
          x.classList.add("d-none");
        } else x.classList.add("d-none");
      });
  } else {
    document
      .querySelectorAll("#products .letter-filterLoyaltyPillar")
      .forEach((x) => {
        if (x.classList.contains("d-none")) {
          x.classList.remove("d-none");
          x.classList.add("d-block");
        } else x.classList.add("d-block");
      });
  }

  document.querySelectorAll("#products .letters a.active").forEach((x) => {
    x.classList.remove("active");
  });
  var splitArrayValue = e1Value.split(",");
  for (let i = 0; i < splitArrayValue.length; i++) {
    document
      .querySelectorAll("#products .letter-filter." + splitArrayValue[i])
      .forEach((x) => {
        x.classList.remove("d-none");
        x.classList.add("d-block");
      });
    if (splitArrayValue[i] == "Others") {
      var isChild = false;
      var currentValue = splitArrayValue[i];
      document
        .querySelectorAll(
          "#products .letter-filter." +
          splitArrayValue[i] +
          " .categoryListFilter"
        )
        .forEach((x) => {
          var display = x.style.display;
          if (display != "none") {
            isChild = true;
            var titleValue = x.getAttribute("data-name");

            document
              .querySelectorAll(
                "#products .letter-filter." +
                currentValue +
                '[data-name="' +
                titleValue +
                '"]'
              )
              .forEach((x) => {
                x.classList.remove("d-none");
                x.classList.add("d-block");
              });
          }
        });
    } else {
      var isChild = false;
      document
        .querySelectorAll(
          "#products .letter-filter." +
          splitArrayValue[i] +
          " .categoryListFilter"
        )
        .forEach((x) => {
          var display = x.style.display;
          if (display != "none") {
            isChild = true;
          }
        });
      if (isChild) {
        document
          .querySelectorAll("#products .letter-filter." + splitArrayValue[i])
          .forEach((x) => {
            x.classList.remove("d-none");
            x.classList.add("d-block");
          });
      }
    }
  }

  ele.classList.add("active");
  lazyLoadingImg();
  return false;
}

export function categoryFilter(e1, e2, newtag, offertag) {
  var arrLetters = [];
  var firstIndexCheck = 0;
  var firstCharLetter = "";
  document.querySelectorAll("#products .categoryListFilter").forEach((x) => {
    if (e1 == "All") {
      x.style.display = "block";
      if (firstIndexCheck == 0) {
        firstCharLetter = x.getAttribute("data-alt");
      }
      arrLetters.push(x.getAttribute("data-alt"));
      firstIndexCheck++;
    } else if (
      !newtag &&
      !offertag &&
      x.getAttribute("data-title").indexOf(e1) >= 0
    ) {
      x.style.display = "block";
      if (firstIndexCheck == 0) {
        firstCharLetter = x.getAttribute("data-alt");
      }
      arrLetters.push(x.getAttribute("data-alt"));
      firstIndexCheck++;
    } else if (newtag && x.querySelectorAll(".new-tap").length == 1) {
      x.style.display = "block";
      if (firstIndexCheck == 0) {
        firstCharLetter = x.getAttribute("data-alt");
      }
      arrLetters.push(x.getAttribute("data-alt"));
      firstIndexCheck++;
    } else if (offertag && x.querySelectorAll(".offer-tap").length == 1) {
      x.style.display = "block";
      if (firstIndexCheck == 0) {
        firstCharLetter = x.getAttribute("data-alt");
      }
      arrLetters.push(x.getAttribute("data-alt"));
      firstIndexCheck++;
    } else {
      x.style.display = "none";
    }
  });
  dosubCategoryFilter(firstCharLetter, "", arrLetters);
  document.querySelectorAll("#products .listOfShopSubCategory").forEach((x) => {
    x.classList.remove("active");
  });
  e2.classList.add("active");
  lazyLoadingImg();
  return true;
}

export function dosubCategoryFilter(firstShop, jsonData, arrLetters) {
  var arr = [];
  if (arrLetters.length > 0) {
    arr = arrLetters;
  } else {
    arr = JSON.parse(jsonData);
  }

  var arr1 = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "LoyaltyPillar",
  ];
  if (arr != null) {
    for (var j = 0; j < arr.length; j++) {
      if (arr[j] != "") {
        if (arr1.indexOf(arr[j]) > -1) {
          document
            .querySelectorAll(
              "#products .filter-alphabetic-old span.vertical-align.text-uppercase"
            )
            .forEach((x) => {
              if (x.innerHTML.indexOf(arr[j]) != -1) {
                x.parentElement.classList.remove("disablehref");
              }
            });
          document
            .querySelector(
              "#products .filter-alphabetic-old span.vertical-align.text-uppercase.all"
            )
            .parentElement.classList.remove("disablehref");
        } else {
          document
            .querySelectorAll(
              "#products .filter-alphabetic-old span.vertical-align.text-uppercase"
            )
            .forEach((x) => {
              if (x.innerHTML.indexOf("0-9") != -1) {
                x.parentElement.classList.remove("disablehref");
              }
            });
          document
            .querySelector(
              "#products .filter-alphabetic-old span.vertical-align.text-uppercase.all"
            )
            .parentElement.classList.remove("disablehref");
        }
      }
    }
  }
  if (firstShop != "") {
    document
      .querySelectorAll(
        "#products .filter-alphabetic-old span.vertical-align.text-uppercase.all"
      )
      .forEach((x) => {
        x.parentElement.classList.add("active");
      });
    document
      .querySelectorAll(
        "#products .filter-alphabetic-old span.vertical-align.text-uppercase.all"
      )
      .forEach((x) => {
        var event = document.createEvent("Event");
        event.initEvent("onClick", false, true);
        x.dispatchEvent(event);
      });
    document.querySelectorAll("#products #letterFilterResult").forEach((x) => {
      x.style.display = "block";
    });
  }
}

export function brandFilterClass() {
  document.getElementById("brandFilter").classList.add("collapse");
}

function onLoadOfListOfShop() {
  document.addEventListener("click", function (event) {
    if (event.target.id == "#brandFilter > ul > li > a") {
      document.querySelectorAll("#brandFilter > ul > li > a").forEach((x) => {
        x.classList.remove("selected");
      });
      document.querySelectorAll("#brandFilter > ul > li > a").forEach((x) => {
        x.classList.remove("active");
      });
      document
        .querySelectorAll("#brandFilter > ul > li > ul >li > a")
        .forEach((x) => {
          x.classList.remove("selected");
        });
      document.querySelectorAll("#brandFilter > ul > li > ul").forEach((x) => {
        x.classList.remove("in");
      });
      event.target.classList.add("selected");
      document.querySelectorAll(".filter-item-list").forEach((x) => {
        x.classList.add("selected");
      });
      document.querySelectorAll("#filter-category-list").forEach((x) => {
        x.classList.remove("selected");
      });
    }
  });
}

function onLoadOfListOFShopRes() {
  document.addEventListener("click", function (event) {
    if (event.target.id == "#brandFilter > ul > li > ul >li > a") {
      document
        .querySelectorAll("#brandFilter > ul > li > ul > li > a")
        .forEach((x) => {
          x.classList.remove("selected");
        });

      event.classList.add("selected");
      document
        .getElementById("filter-category-list")
        .classList.remove("selected");

      document.querySelectorAll(".filter-item-list").forEach((x) => {
        x.classList.add("selected");
      });
      document.querySelectorAll("#filter-category-list").forEach((x) => {
        x.classList.remove("selected");
      });
      var element = event.target.closest('ul[class^="filter-item-list"]')[0]
        .previousElementSibling;
      element.classList.add("active");
      element.classList.remove("selected");
    }
  });
}

export function ListofShopsMobileView() {
  if (window.innerWidth < 991) {
    document.addEventListener("click", function (event) {
      if (event.target.id == "#categoryfilter") {
        var ele = document.getElementsByClassName("icon-drop-up-arrow");
        for (let i = 0; i < ele.length; i++) {
          if (ele[0].length > 0) {
            ele[0].classList.remove("icon-drop-up-arrow");
            ele[0].classList.add("icon-drop-down-arrow");
            document
              .getElementById("categoryfilter")
              .classList.add("collapsed");
            document.getElementById("brandFilter").classList.remove("in");
          } else {
            document.querySelectorAll(".icon-drop-down-arrow").forEach((x) => {
              x.classList.remove("icon-drop-down-arrow");
              x.classList.add("icon-drop-up-arrow");
            });
            document.getElementById("categoryfilter").classList.add("in");
          }
        }
      }
    });
    document.addEventListener("click", function (event) {
      if (event.target.id == "#brandFilter > ul > li > a") {
        document.getElementById("categoryfilter").classList.add("collapsed");
        document.getElementById("brandFilter").classList.remove("in");
      }
    });
    document.addEventListener("click", function (event) {
      if (event.target.id == "#brandFilter > ul > li > ul >li > a") {
        document.getElementById("categoryfilter").classList.add("collapsed");
        document.getElementById("brandFilter").classList.remove("in");
      }
    });
    if (document.getElementById("categoryfilter")) {
      document.getElementById("categoryfilter")
        .addEventListener("click", function () {
          if (document.body.classList.contains("stores-filter-overflow")) {
            document.body.classList.remove("stores-filter-overflow")
          }
          else {
            document.body.classList.add("stores-filter-overflow")
          }
        });
    }
    if (document.getElementsByClassName("link-expandable")[0]) {
      document
        .getElementsByClassName("link-expandable")[0]
        .addEventListener("click", function () {
          if (document.getElementsByClassName("link-expandable")[0].classList.contains("open"))
            document.getElementsByClassName("link-expandable")[0].classList.remove("open")
          else
            document.getElementsByClassName("link-expandable")[0].classList.add("open")
        });
    }
  }
}


var eventtemplate = "News";
var eventmultidatetemplate = "News";
var movietemplate = "Cinema";
var offertemplate = "Offer";
var servicetemplate = "Service";
var storetemplate = "Brand";
var publictemplate = "Brand";
var emptybookmark = "@emptybookMark";
var publicount;
var eventcount;
var moviecount;
var offercount;
var servicecount;
var storecount;
let itemid;
let templateid;

export function CommonBookmarkallpage() {
  var id = readCookie("UUID");
  if (id !== null && id !== undefined && id !== "") {
    if (isTokenExpired()) {
      var args = [];
      GetRenewedAccessToken(bookmarkItems, args, false);
    } else {
      bookmarkItems();
    }
  }

  document.querySelectorAll("a.ava-wishlist").forEach((x) => {
    if (x.getAttribute("events") != undefined && x.getAttribute("events").click != undefined) {
      //do nothing as the click event is already there
    } else {
      x.removeEventListener("click", bookmarkHandler);
      x.addEventListener("click", bookmarkHandler);
    }
  });
}

export function bookmarkItems() {
  var url = "/api/sitecore/VisitorApi/GetVisitorBookmarks?contextSite=" + baseModel.urContextSite;

  const state = store.getState();
  const visitorId = state.user.visitorId;
  const accessToken = state.user.accessToken;
  const refreshToken = state.user.refreshToken;

  ajaxCallPresent = true;
  ajaxCallIncrement = ajaxCallIncrement + 1;

  axios({
    method: "post",
    url: url,
    data: {
      visitorId: visitorId,
      accessToken: accessToken,
      refreshToken: refreshToken
    },
    dataType: "json",
  })
    .then(function (response) {
      var data = response.data;
      if (baseModel.bookmarked !== undefined) {
        bookMarked = data[2];
        if (bookMarked !== null && bookMarked !== undefined) {
          if (bookMarked.length > 0) {
            document.querySelectorAll("a.ava-wishlist").forEach((x) => {
              itemid = x.getAttribute("aria-label");
              templateid = x.getAttribute("aria-labelledby");
              if (bookMarked.indexOf(itemid) > -1) {
                x.classList.add("active");
              } else {
                x.classList.remove("active");
              }
            });
          }
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function bookmarkHandler(event) {
  event.stopPropagation();
  event.preventDefault();
  var isLoginPage = false;

  if (baseModel.currentPageItemName != "cinema" && baseModel.currentPageItemName != "store" &&
    baseModel.currentPageItemName != "restaurant") {
    event.stopPropagation();
    event.preventDefault();
  }

  if (document.getElementById("navigationpubliccount") !== null) {
    publicount = document.getElementById("navigationpubliccount").value;
  }
  if (document.getElementById("navigationeventcount") !== null) {
    eventcount = document.getElementById("navigationeventcount").value;
  }
  if (document.getElementById("navigationmoviecount") !== null) {
    moviecount = document.getElementById("navigationmoviecount").value;
  }
  if (document.getElementById("navigationoffercount") !== null) {
    offercount = document.getElementById("navigationoffercount").value;
  }
  if (document.getElementById("navigationservicecount") !== null) {
    servicecount = document.getElementById("navigationservicecount").value;
  }
  if (document.getElementById("navigationstorecount") !== null) {
    storecount = document.getElementById("navigationstorecount").value;
  }

  var element = event.currentTarget;
  var bookmarkId = element.getAttribute("aria-label");
  var bookmarkType = element.getAttribute("aria-labelledby");
  var bookmarkLabel = element.getAttribute("data-itemlabel");

  if (bookmarkType == "deal") {
    var retailerName = element.getAttribute("data-storename");
    bookmarkLabel = bookmarkLabel + "/" + retailerName;
  }

  if (isLoginPage) event.currentTarget.removeAttribute("data-bs-target");

  var id = getCookie("UUID");

  if (id == "") {

    fnConnectRedirectTodetailPage();
    createCookie("BookmarkId", bookmarkId, 0);
    createCookie("BookmarkType", bookmarkType, 0);
    createCookie("BookmarkLabel", bookmarkLabel, 0);

    if (window.innerWidth < 991) {
      //need to validate
      document.querySelectorAll(".filter-category-wrap").forEach((x) => {
        x.classList.remove("sticky");
      });
    }

    if (isLoginPage) {
      var userMenuElement = document.getElementById(
        "user-menu-toggler",
        "user-menu-toggler-picto"
      );
      if (userMenuElement !== null)
        userMenuElement.setAttribute("reloadpage", "false");
    }

    fnRedirectToConnectLogin(baseModel.connectRedirectUrl);
  } else {
    if (isTokenExpired()) {
      var args = [element];
      GetRenewedAccessToken(toogleBookmark, args, false);
    } else {
      toogleBookmark(element);
    }
  }
}

function toogleBookmark(element) {

  var bookmarkId = element.getAttribute("aria-label");
  var bookmarkType = element.getAttribute("aria-labelledby");
  var itemLabel = element.getAttribute("data-itemlabel");
  var bookmarktemplate = element.getAttribute("data-templatetype");
  if (bookmarktemplate === null || bookmarktemplate === "") {
    bookmarktemplate = baseModel.pageTemplateType
  }
  if (bookmarkType === "deal") {
    var retailerName = element.getAttribute("data-storename");
    itemLabel = itemLabel + "/" + retailerName;
  }

  var ajaxUrl = "/api/sitecore/VisitorApi/ToggleVisitorBookmark?contextSite=" + baseModel.urContextSite
    + "&bookmarkItemId=" + bookmarkId + "&bookmarkType=" + bookmarkType + "&bookmarkItemLabel=" + itemLabel;

  const state = store.getState();
  const visitorId = state.user.visitorId;
  const accessToken = state.user.accessToken;
  const refreshToken = state.user.refreshToken;

  axios({
    method: "post",
    url: ajaxUrl,
    data: {
      visitorId: visitorId,
      accessToken: accessToken,
      refreshToken: refreshToken
    },
    dataType: "json",
  })
    .then(function (response) {
      var data = response.data;
      if (data[0]) {
        if (data[2]) {
          if (data[3] !== null && data[3] !== undefined && data[3] !== "") {
            let bookmark = data[3];
            const state = store.getState();
            if (bookmark.bookmarkType === "deal") {
              let bookmarkedOffer = state.user.bookmarkedOffer
              let UpdatedValue = UpdateBookmark(bookmarkedOffer, bookmark);
              store.dispatch(setBookmarkedOffer(UpdatedValue));
            }
            if (bookmark.bookmarkType === "store") {
              let bookmarkedStore = state.user.bookmarkedStore
              let UpdatedValue = UpdateBookmark(bookmarkedStore, bookmark);
              store.dispatch(setBookmarkedStore(UpdatedValue));
            }
            if (bookmark.bookmarkType === "restaurant") {
              let bookmarkedRestaurant = state.user.bookmarkedRestaurant
              let UpdatedValue = UpdateBookmark(bookmarkedRestaurant, bookmark);
              store.dispatch(setBookmarkedRestaurant(UpdatedValue));
            }
            if (bookmark.bookmarkType === "event" || bookmark.bookmarkType === "news") {
              let bookmarkedEvent = state.user.bookmarkedEvent
              let UpdatedValue = UpdateBookmark(bookmarkedEvent, bookmark);
              store.dispatch(setBookmarkedEvent(UpdatedValue));
            }
          }
          element.setAttribute("gtmevent", "bookmark");
          gtmFuncAddToFavorite(itemLabel, bookmarkType);
          element.classList.add("active");
          document.querySelectorAll("a.ava-wishlist").forEach((x) => {
            itemid = x.getAttribute("aria-label");
            templateid = x.getAttribute("aria-labelledby");
            if (itemid.toUpperCase() == bookmarkId.toUpperCase()) {
              x.classList.add("active");
            }
          });
          if (document.getElementById(bookmarkId) !== null) {
            if (!document.getElementById(bookmarkId).classList.contains("active")) {
              document.getElementById(bookmarkId).classList.add("active");
              document.getElementById(bookmarkId).querySelector("#addremovetext").textContent = "Remove from wishlist";
            }
          }
          if (bookmarkType.toUpperCase() == publictemplate) {
            document.getElementById("navigationpubliccount").value = parseInt(publicount) + 1;
          }
          if (bookmarkType.toUpperCase() == eventtemplate || bookmarkType.toUpperCase() == eventmultidatetemplate) {
            document.getElementById("navigationeventcount").value = parseInt(eventcount) + 1;
          }
          if (bookmarkType.toUpperCase() == movietemplate) {
            document.getElementById("navigationmoviecount").value = parseInt(moviecount) + 1;
          }
          if (bookmarkType.toUpperCase() == offertemplate) {
            document.getElementById("navigationoffercount").value = parseInt(offercount) + 1;
          }
          if (bookmarkType.toUpperCase() == servicetemplate) {
            document.getElementById("navigationservicecount").value = parseInt(servicecount) + 1;
          }
          if (bookmarkType.toUpperCase() == storetemplate) {
            document.getElementById("navigationstorecount").value = parseInt(storecount) + 1;
          }
          var addRemoveEle = element.querySelector("#addremovetext");
          if (addRemoveEle !== null && addRemoveEle !== undefined) {
            element.querySelector("#addremovetext").textContent = "Remove from wishlist";
          }
        } else {
          if (data[3] !== null && data[3] !== undefined && data[3] !== "") {
            let bookmark = data[3];
            const state = store.getState();
            if (bookmark.bookmarkType === "deal") {
              let bookmarkedOffer = state.user.bookmarkedOffer
              let UpdatedValue = RemoveBookmark(bookmarkedOffer, bookmark);
              store.dispatch(setBookmarkedOffer(UpdatedValue));
            }
            if (bookmark.bookmarkType === "store") {
              let bookmarkedStore = state.user.bookmarkedStore
              let UpdatedValue = RemoveBookmark(bookmarkedStore, bookmark);
              store.dispatch(setBookmarkedStore(UpdatedValue));
            }
            if (bookmark.bookmarkType === "restaurant") {
              let bookmarkedRestaurant = state.user.bookmarkedRestaurant
              let UpdatedValue = RemoveBookmark(bookmarkedRestaurant, bookmark);
              store.dispatch(setBookmarkedRestaurant(UpdatedValue));
            }
            if (bookmark.bookmarkType === "event" || bookmark.bookmarkType === "news") {
              let bookmarkedEvent = state.user.bookmarkedEvent
              let UpdatedValue = RemoveBookmark(bookmarkedEvent, bookmark);
              store.dispatch(setBookmarkedEvent(UpdatedValue));
            }
          }
          gtmFuncRemoveFromFavorite(itemLabel, bookmarkType);
          element.classList.remove("active");
          document.querySelectorAll("a.ava-wishlist").forEach((x) => {
            itemid = x.getAttribute("aria-label");
            templateid = x.getAttribute("aria-labelledby");
            if (itemid.toUpperCase() == bookmarkId.toUpperCase()) {
              x.classList.remove("active");
            }
          });
          if (document.getElementById(bookmarkId) !== null) {
            if (document.getElementById(bookmarkId).classList.contains("active")) {
              document.getElementById(bookmarkId).classList.remove("active");
              document.getElementById(bookmarkId).querySelector("#addremovetext").textContent = "Add to wishlist";
            }
          }
          if (bookmarkType.toUpperCase() == publictemplate) {
            publicount <= 0 ? (document.getElementById("navigationpubliccount").value = "0")
              : (document.getElementById("navigationpubliccount").value = parseInt(publicount) - 1);
          }
          if (
            bookmarkType.toUpperCase() == eventtemplate ||
            bookmarkType.toUpperCase() == eventmultidatetemplate
          ) {
            document.getElementById("navigationeventcount").value = parseInt(eventcount) - 1;
          }
          if (bookmarkType.toUpperCase() == movietemplate) {
            document.getElementById("navigationmoviecount").value = parseInt(moviecount) - 1;
          }
          if (bookmarkType.toUpperCase() == offertemplate) {
            document.getElementById("navigationoffercount").value = parseInt(offercount) - 1;
          }
          if (bookmarkType.toUpperCase() == servicetemplate) {
            document.getElementById("navigationservicecount").value = parseInt(servicecount) - 1;
          }
          if (bookmarkType.toUpperCase() == storetemplate) {
            document.getElementById("navigationstorecount").value = parseInt(storecount) - 1;
          }
          var addRemoveEle = element.querySelector("#addremovetext");
          if (addRemoveEle !== null && addRemoveEle !== undefined) {
            element.querySelector("#addremovetext").textContent = "Add to wishlist";
          }
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function UpdateBookmark(bookmarkedValue, UpdateBookmark) {
  let UpdatedValue = ""
  if (bookmarkedValue !== null && bookmarkedValue !== undefined && bookmarkedValue !== "") {
    if (!bookmarkedValue.includes(UpdateBookmark.label)) { UpdatedValue = bookmarkedValue + "," + UpdateBookmark.label; }
    else { return UpdateBookmark.label; }
  }
  else {
    UpdatedValue = UpdateBookmark.label;
  }
  return UpdatedValue;
}

function RemoveBookmark(bookmarkedValue, DeleteBookmark) {
  if (bookmarkedValue !== null && bookmarkedValue !== undefined && bookmarkedValue !== "") {
    let updatedBookmark = bookmarkedValue.split(",").filter((x) => x !== DeleteBookmark.label).join(",");
    return updatedBookmark;
  }
}

/* Navigation Styles */
export function cookiesizing() {
  var height = window.innerHeight ? window.innerHeight : window.screen.height;
  var isHomepage =
    baseModel.currentPageItemName != undefined &&
      baseModel.currentPageItemName != null &&
      baseModel.currentPageItemName.toLowerCase() == "homepage"
      ? true
      : false;
  var headerBlock = document.getElementById("headerBlock");
  var NoticeBlockheight = document.getElementById("NoticeBlock")
    ? document.getElementById("NoticeBlock").offsetHeight
    : 0;
  var headerBlockheight = document.getElementById("navbarHeader")
    ? document.getElementById("navbarHeader").offsetHeight
    : 0;
  var NavHeight = document.getElementById("headerBlock")
    ? document.getElementById("headerBlock").offsetHeight
    : 0;
  var mainHeader = document.getElementById("MainHeader")
    ? document.getElementById("MainHeader").offsetHeight
    : 0;
  var announcementbannerHeright = document.getElementById("NoticeBlock")
    ? document.getElementById("NoticeBlock").offsetHeight
    : 0;
  var SmHeight = document.getElementsByClassName("smartbanner")[0]
    ? document.getElementsByClassName("smartbanner")[0].offsetHeight
    : 0;

  if (window.innerWidth > 991) {
    var searchPopin = document.getElementById("searchPopin");
    if (searchPopin !== null && searchPopin !== undefined) {
      searchPopin.addEventListener("show.bs.modal", function (e) {
        searchPage = window.location.href;

        if (!document.getElementsByTagName("body")[0].classList.contains("on-search")) {
          document.getElementById("SearchText").value = "";
          if (document.getElementById("searchTextReset"))
            document.getElementById("searchTextReset").style.display = "none";
          if (document.getElementById("searchNewStoresList") !== null)
            document.getElementById("searchNewStoresList").style.display = "block";
        }

        var elementOffset = document.getElementById("headerBlock").offsetTop;
        var scrollTop = window.scrollTop;
        var compoheight = mainHeader + NavHeight;
        var aTop = elementOffset - scrollTop + compoheight;

        document.getElementById("searchPopin").style.cssText = "top :" + aTop + "px !important";
        document.getElementsByTagName("body")[0].classList.add("on-search");

        if (typeof window.history.pushState != "undefined") {
          var obj = { Page: "", Url: baseModel.siteUrlPrefix + "/search" };
          window.history.pushState(obj, obj.Page, obj.Url);
        }
      });

      document
        .getElementById("searchPopin")
        .addEventListener("shown.bs.modal", function (e) {
          var scrollTop = window.scrollTop;
          var elementOffset = document.getElementById("headerBlock").offsetTop;
          var compoheight = mainHeader + NavHeight;
          var aTop = elementOffset - scrollTop + compoheight;
          if (document.getElementsByClassName("modal-backdrop")[0]) {
            document.getElementsByClassName("modal-backdrop")[0].style.top = aTop + "px";
          }
        });

      document
        .getElementById("searchPopin")
        .addEventListener("hide.bs.modal", function (e) {
          if (document.getElementsByTagName("body")[0].classList.contains("on-search")) {
            var searchEndURL = baseModel.searchPage ? baseModel.siteUrlPrefix + baseModel.homePage : searchPage;

            document.getElementById("searchContent").innerHTML = "";

            if (typeof window.history.pushState != "undefined") {
              var obj = { Page: "", Url: searchEndURL };
              window.history.pushState(obj, obj.Page, obj.Url);

              if (baseModel.searchPage)
                // Redirect to Home page on SearchClose is searchpage.
                window.location.reload();
            }
          }
          document.getElementsByTagName("body")[0].classList.remove("on-search");
        });
    }
  }

  if (window.innerWidth < 991) {
    window.addEventListener("scroll", function (e) {
      var scrollTop = window.scrollTop;
      var elementOffset =
        document.getElementById("headerBlock") !== null
          ? document.getElementById("headerBlock").offsetTop
          : 0;
      var NavHeight = document.getElementById("headerBlock")
        ? document.getElementById("headerBlock").offsetHeight
        : 0;
      var aTop = elementOffset - scrollTop + NavHeight;
      var scroll = window.pageYOffset;
      if (scroll >= 1) {
        document.getElementsByTagName("body")[0].classList.add("on-scroll");
        if (document.getElementsByClassName("slidemenu")[0])
          document.getElementsByClassName("slidemenu")[0].style.cssText =
            "top :" + aTop + "px !important";
        if (document.getElementById("searchPopin"))
          document.getElementById("searchPopin").style.cssText =
            "top :" + aTop + "px !important";
        if (document.getElementsByClassName("modal-backdrop")[0])
          document.getElementsByClassName("modal-backdrop")[0].style.top =
            aTop + "px";
      } else {
        document.getElementsByTagName("body")[0].classList.remove("on-scroll");
      }
    });

    if (document.getElementsByClassName("smartbanner")[0] && document.getElementsByClassName("smartbanner-show")) {
      var SmartBannerHeight =
        document.getElementsByClassName("smartbanner")[0].offsetHeight;
      document
        .getElementsByClassName("smartbanner-close")[0]
        .addEventListener("click", function (e) {
          window.smartBannerCloseCss();
        });
      if (document.querySelector("#NoticeBlock .icon-close-search-btn")) {
        document
          .querySelector("#NoticeBlock .icon-close-search-btn")
          .addEventListener("click", function (e) {
            document.getElementById("NoticeBlock").classList.add("d-none");
            document
              .getElementsByTagName("body")[0]
              .classList.remove("emergency-notice-show");
            if (document.getElementById("navbar"))
              document.getElementById("navbar").style.top =
                SmartBannerHeight + headerBlockheight + "px";
          });
      }

      // if (
      //   document.getElementsByClassName("emergency-notice")[0] != undefined &&
      //   document.getElementsByClassName("emergency-notice-show")[0]
      // ) {
      //   if (document.getElementById("navbar"))
      //     document.getElementById("navbar").style.top =
      //       SmartBannerHeight + headerBlockheight + NoticeBlockheight + "px";
      // }
    } else {
      if (document.querySelector("#NoticeBlock .icon-close-search-btn")) {
        document
          .querySelector("#NoticeBlock .icon-close-search-btn")
          .addEventListener("click", function () {
            document.getElementById("NoticeBlock").classList.add("d-none");
            document
              .getElementsByTagName("body")[0]
              .classList.remove("emergency-notice-show");
            if (document.getElementById("navbar"))
              document.getElementById("navbar").style.top =
                headerBlockheight + "px";
          });
      }
      // if (document.getElementsByClassName("emergency-notice")[0] &&
      //   document.getElementsByClassName("emergency-notice-show")[0]) {
      //   if (document.getElementById("navbar"))
      //     document.getElementById("navbar").style.top =
      //       headerBlockheight + NoticeBlockheight + "px";
      // }
    }

    var elms = document.querySelectorAll("#MainHeader .dropdown.connection");
    elms.forEach((x) => {
      x.addEventListener("mouseover", function (e) {
        document.querySelector(".dropdown.connection").classList.add("picto");
      });
    });

    elms.forEach((x) => {
      x.addEventListener("mouseout", function (e) {
        document
          .querySelector(".dropdown.connection")
          .classList.remove("picto");
      });
    });

    if (
      document.getElementById("accountmenu") !== undefined &&
      document.getElementById("accountmenu") !== null
    ) {
      document
        .getElementById("accountmenu")
        .addEventListener("show.bs.collapse", function () {
          if (
            document.getElementsByClassName("smartbanner") != undefined &&
            document.getElementsByClassName("smartbanner-show").length > 0
          ) {
            var SmartBannerHeight =
              document.getElementsByClassName("smartbanner").innerHeight;
          }
          hideCollapse(document.getElementById("navbar"));

          document.getElementById("searchPopin").style.display = "none";
          document
            .getElementsByTagName("body")[0]
            .classList.add("account-nav-on");
          document.querySelectorAll(".nav-overlay").forEach((x) => {
            x.classList.add("active");
          });
          document.querySelectorAll(".nav-overlay").forEach((x) => {
            x.style.cssText =
              "top :" +
              (headerBlockheight + NoticeBlockheight + SmartBannerHeight) +
              "px";
          });
          document
            .querySelectorAll(".filter-category-wrap")
            .forEach((x) => {
              x.classList.remove("sticky");
            });
        });
    }

    if (document.getElementById("navbar") !== null) {
      document
        .getElementById("navbar")
        .addEventListener("show.bs.collapse", function () {
          hideCollapse(document.getElementById("accountmenu"));
          if (
            document.getElementById("searchPopin")?.classList.contains("show")
          )
            document.getElementById("stickySearchIcon").click();
          document.getElementsByTagName("body")[0].classList.add("nav-on");

          document
            .querySelectorAll(".filter-category-wrap")
            .forEach((x) => {
              x.classList.remove("sticky");
            });
          document.querySelectorAll(".nav-overlay").forEach((x) => {
            x.classList.add("active");
          });
        });
    }

    var el = document.querySelectorAll("#navbar,#accountmenu");
    if (el !== null && el !== undefined) {
      el.forEach((x) => {
        x.addEventListener("hide.bs.collapse", function () {
          document.getElementsByTagName("body")[0].classList.remove("nav-on");
          document
            .getElementsByTagName("body")[0]
            .classList.remove("account-nav-on");
          document.querySelectorAll(".nav-overlay").forEach((x) => {
            x.classList.remove("active");
          });
        });
      });
    }

    var elments = document.querySelectorAll(".nav-overlay");
    if (elments !== null && elments !== undefined) {
      elments.forEach((x) => {
        x.addEventListener("click", function (e) {
          hideCollapse(document.getElementById("accountmenu"));
          e.currentTarget.classList.remove("active");
          document.getElementsByTagName("body")[0].classList.remove("nav-on");
        });
      });
    }

    if (document.getElementById("searchPopin") !== null) {
      document
        .getElementById("searchPopin")
        .addEventListener("show.bs.modal", function (e) {
          searchPage = window.location.href;
          if (!document.getElementsByTagName("body")[0].classList.contains("on-search")) {
            document.getElementById("SearchText").value = "";
            if (document.getElementById("searchTextReset"))
              document.getElementById("searchTextReset").style.display = "none";
            if (document.getElementById("searchNewStoresList"))
              document.getElementById("searchNewStoresList").style.display =
                "block";
          }
          var scrollTop = window.scrollTop;
          var elementOffset = document.getElementById("headerBlock").offsetTop;
          var NavHeight = document.getElementById("headerBlock")
            ? document.getElementById("headerBlock").offsetHeight
            : 0;

          var aTop = elementOffset - scrollTop + NavHeight;
          document.getElementById("searchPopin").style.cssText =
            "top :" + aTop + "px !important";
          if (document.getElementsByClassName("modal-backdrop")[0])
            document.getElementsByClassName("modal-backdrop")[0].style.cssText =
              "top :" + aTop + "px";
          document.getElementsByTagName("body")[0].classList.add("on-search");
          document.getElementsByTagName("body")[0].classList.remove("nav-on");
          hideCollapse(document.getElementById("navbar"));
          hideCollapse(document.getElementById("accountmenu"));
          document
            .getElementsByTagName("body")[0]
            .classList.remove("account-nav-on");

          if (typeof window.history.pushState != "undefined") {
            var obj = { Page: "", Url: baseModel.siteUrlPrefix + "/search" };
            window.history.pushState(obj, obj.Page, obj.Url);
          }
        });

      document
        .getElementById("searchPopin")
        .addEventListener("shown.bs.modal", function (e) {
          var scrollTop = window.scrollTop;
          var elementOffset = document.getElementById("headerBlock").offsetTop;
          var NavHeight = document.getElementById("headerBlock")
            ? document.getElementById("headerBlock").offsetHeight
            : 0;
          var aTop = elementOffset - scrollTop + NavHeight;
          if (document.getElementsByClassName("modal-backdrop")[0]) {
            document.getElementsByClassName("modal-backdrop")[0].style.cssText =
              "top:" + aTop + "px;";
          }
        });

      document
        .getElementById("searchPopin")
        .addEventListener("hide.bs.modal", function (e) {
          if (document.getElementsByTagName("body")[0].classList.contains("on-search")) {
            var searchEndURL = baseModel.searchPage ? baseModel.siteUrlPrefix + baseModel.homePage : searchPage;
            document.getElementById("searchContent").innerHTML = "";
            if (typeof window.history.pushState != "undefined") {
              var obj = {
                Page: "",
                Url: searchEndURL,
              };
              window.history.pushState(obj, obj.Page, obj.Url);

              if (baseModel.searchPage)
                // Redirect to Home page on SearchClose is searchpage.
                window.location.reload();
            }
          }
          document.getElementsByTagName("body")[0].classList.remove("on-search");
          hideCollapse(document.getElementById("navbar"));
          hideCollapse(document.getElementById("accountmenu"));
        });
    }
  }

  function hideCollapse(selector) {
    try {
      selector.collapse("hide");
    } catch (e) {
    }
  }
}

//User logout function
export function logoutFromGS() {
  const state = store.getState();
  const visitorId = state.user.visitorId;
  axios({
    method: "post",
    url:
      "/api/sitecore/VisitorApi/LogoutVisitorAccount",
    data: {
      visitorId: visitorId
    },
    async: false,
  })
    .then(function (response) {
      if (response.data === true) {
        let url = window.location.href;
        store.dispatch(doLogout({
          isLoggedIn: false,
          accessToken: '',
          refreshToken: '',
          visitorId: '',
          acquisitionPcCode: '',
          firstName: '',
          lastName: '',
          initials: '',
          email: '',
          barCode: '',
          workerCenter: '',
          birthdate: '',
          accountCreationDate: '',
          favCenterLables: '',
          bookmarkedOffer: '',
          bookmarkedStore: '',
          bookmarkedEvent: '',
          bookmarkedRestaurant: '',
          bookingAccessToken: '',
          bookingAccessTokenExpiryValue: ''
        }));
        erasecookies();
        //when current page is user's account page, after logout redirect to home page
        if (url.indexOf(baseModel.accountPage) > -1) {
          url = url.replace(baseModel.accountPage, "");
        }
        //Added to reload page containig hash value. location.reload does not work with hash values
        if (window.location.hash) {
          url = url.replace(window.location.hash, "");
        }
        window.location.href = url;
      }
      else if (response.data == false) {
        genericTechnicalErrorPopin();
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function erasecookies() {
  cookies.remove("UUID", { path: "/" });
  cookies.remove("URW-AUTH", { path: "/", domain: ".westfield.com" });

  cookies.remove("ASP.NET_SessionId", { path: "/" });
  cookies.remove("shell#lang", { path: "/" });
  cookies.remove("sitecore_userticket", { path: "/" });
}

export function erasecookie(name) {
  createCookie(name, "", -1);
}

export function replaceQueryString(url, param, value) {
  var re = new RegExp("([?|&|&amp;])" + param + "=.*?(&|&amp;|$)", "i");
  if (url.match(re)) return url.replace(re, "$1" + param + "=" + value + "$2");
  else return url + "&" + param + "=" + value;
}

function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = typeof el === "string" ? document.querySelector(el) : el;

  var styles = window.getComputedStyle(el);
  var margin =
    parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
  return Math.ceil(el.offsetHeight + margin);
}

export function categoryScrollTop() {
  var scrollTop = window.scrollY;
  var height = window.innerHeight ? window.innerHeight : window.innerHeight;
  if (document.getElementById("navbarHeader") !== null)
    var headerBlockheight = getAbsoluteHeight("#navbarHeader");
  if (document.getElementById("categoryfilter") !== null)
    var categoryFilterheight = getAbsoluteHeight("#categoryfilter");
  document.querySelectorAll("#categoryfilter").forEach((item) => {
    var topDistance =
      getElementOffsetTopValue("#categoryfilter") -
      getAbsoluteHeight("#navbarHeader");
    var topDistanceDown =
      getElementOffsetTopValue(".filter-wrap") -
      getAbsoluteHeight("#navbarHeader");
    if (topDistance < scrollTop) {
      document
        .getElementById("categoryfilter")
        .setAttribute("style", "top:" + headerBlockheight + "px");
      document.querySelectorAll(".filter-category-wrap").forEach((item) => {
        item.classList.add("sticky");
      });
      document.getElementById("filter-category-list").style.maxHeight =
        height - (headerBlockheight + 75) + "px";
    }

    if (topDistanceDown >= scrollTop) {
      document.getElementById("categoryfilter").removeAttribute("style");
      document.querySelectorAll(".filter-category-wrap").forEach((item) => {
        item.classList.remove("sticky");
      });
      document.getElementById("filter-category-list").style.maxHeight = "";
    }
  });
}

function getElementOffsetTopValue(ele) {
  var rect = document.querySelector(ele).getBoundingClientRect();

  var offset = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
  return offset.top;
}

export function LoadPopins() {
  var deleteAccountUrl =
    "/api/sitecore/PopinApi/DeleteVisitorPopin?strPopup=DeleteBasicAccountSuccess" +
    "&contextLanguage=" + baseModel.contextLang;


  if (typeof window !== "undefined") {
    if (window.location.href.indexOf("subscription=success") >= 0) {
      var subScriptionSuccessUrl =
        "/api/sitecore/PopinApi/SubscribePopinSuccess?ursite=" +
        baseModel.urContextSite +
        "&urlang=" +
        baseModel.contextLang;
      axios({
        method: "post",
        url: subScriptionSuccessUrl,
        async: false,
      })
        .then(function (response) {
          {
            createRoot(document.getElementById("globalPopin")).render(
              <SubscriptionMessagePopin model={response.data} />
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (window.location.href.indexOf("subscription=failure") >= 0) {
      var subScriptionFailureUrl =
        "/api/sitecore/PopinApi/SubscribePopinFailure?ursite=" +
        baseModel.urContextSite +
        "&urlang=" +
        baseModel.contextLang;
      axios({
        method: "post",
        url: subScriptionFailureUrl,
        async: false,
      })
        .then(function (response) {
          {
            createRoot(document.getElementById("globalPopin")).render(
              <SubscriptionMessagePopin model={response.data} />
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }

  if (
    baseModel.deleteAccountSuccess != undefined &&
    baseModel.successMsg == baseModel.deleteAccountSuccess
  ) {
    axios({
      method: "post",
      url: deleteAccountUrl,
      async: false,
    })
      .then(function (response) {

        {
          createRoot(document.getElementById("globalPopin")).render(
            <IntroductionInnerPage introductionData={response.data} />
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

}

//Search Js

var storeRequest = null;
var serviceRequest = null;
var filmRequest = null;
var movieRequest = null;
var searchIntervalTimer;
var SearchResultCount = 0;
var SearchNoResultCount = 0;
var searchTextLessThan2Length = "true";
var TextSearchResult = "";

var TotalSearchResultCount = 0;
var searchResulttimer = setTimeout(function () { }, 1);

export function fnSearchText() {
  SearchResultCount = 0;
  SearchNoResultCount = 0;
  searchTextLessThan2Length = "true";
  TotalSearchResultCount = 0;
  var output2 = document.getElementById("SearchText").value;
  var searchText = output2.trim();
  TextSearchResult = searchText;

  if (searchText != "" && searchText.length >= 2) {
    searchTextLessThan2Length = "false";

    var storesresult = new Array();
    var servicesresult = "";
    var filmsresult = "";

    // var luxLanguageEle = document.getElementById("luxLanguage");
    // var language = "";
    // if (luxLanguageEle !== null) {
    //   language = luxLanguageEle.innerHTML;
    // }
    // var luxPassEle = document.getElementById("luxPass");
    // var key = "";
    // if (luxPassEle !== null) {
    //   key = luxPassEle.innerHTML;
    // }

    var gtmNoResult = 0;
    var gtmResult = 0;
    const source = axios.CancelToken.source();
    var myNode = document.getElementById("searchContent");
    while (myNode.firstChild) {
      myNode.removeChild(myNode.firstChild);
    }

    if (
      document.querySelectorAll("#searchContent") !== undefined &&
      document.querySelectorAll("#searchContent") !== null &&
      document.querySelectorAll("#getsearchresult") !== undefined &&
      document.querySelectorAll("#getsearchresult") !== null
    )
      document.querySelectorAll("#searchContent")[0].innerHTML +=
        document.querySelectorAll("#getsearchresult")[0].outerHTML;

    axios({
      method: "post",
      url: "/api/sitecore/SearchApi/GetCustomSearchResults",
      params: {
        searchText: searchText,
        centreId: baseModel.centerId,
        contextLang: baseModel.contextLang,
        isEuropeanSite: baseModel.isEuropeanSite.toString().toLowerCase(),
        urContextSite: baseModel.urContextSite,
      },
      async: true,
      cancelToken: source.token,
      timeout: baseModel.searchTimeout,
    })
      .then(function (result) {
        if (result != null &&
          result != undefined &&
          result != "" &&
          result.data != null &&
          result.data != undefined &&
          result.data != "" &&
          result.data.success) {
          if (result.data.storedata) {
            var storesResultData = JSON.parse(result.data.storedata);
            var storesfilterresult = new Array();
            if (
              storesResultData.data.hits !== undefined &&
              storesResultData.data.hits !== null
            ) {
              storesResultData.data.hits.forEach((item) => {
                if (
                  item.status.state.toLowerCase() == "live" &&
                  (item.store_type_ids
                    .toString()
                    .includes(baseModel.luxStoreType) ||
                    item.store_type_ids
                      .toString()
                      .includes(baseModel.luxRestaurantType))
                ) {
                  storesfilterresult.push(item);
                }
              });
            }

            storesresult = storesfilterresult.reduce(function (item, e1) {
              var matches = item.filter(function (e2) {
                return e1.name == e2.name;
              });
              if (matches.length == 0) {
                item.push(e1);
              }
              return item;
            }, []);

            if (storesresult.length > 0) {
              document
                .getElementById("search-store-block")
                .classList.remove("hidden");
              document.getElementById("storeTitle").classList.remove("hidden");
              document.getElementById("storeTitle").innerText =
                baseModel.storeSearchTitle.replace("/&amp;/g", "&") +
                " " +
                "(" +
                storesresult.length +
                ")";

              TotalSearchResultCount =
                TotalSearchResultCount + storesresult.length;

              var storeliResult = "";
              storesresult.forEach((item) => {
                var type = "";
                var newStoreType = IsNewStore(item.status.starts_at);
                if (newStoreType) {
                  if (
                    document.getElementById("liSpanStoreName") !== null &&
                    document.getElementById("liSpanStoreName") !== undefined
                  )
                    document.getElementById("liSpanStoreName").innerText =
                      baseModel.searchNewTag;
                }
                if (
                  item.store_type_ids.toString().includes(baseModel.luxStoreType)
                ) {
                  type = baseModel.storePageName;
                } else if (
                  item.store_type_ids
                    .toString()
                    .includes(baseModel.luxRestaurantType)
                ) {
                  type = baseModel.restaurantPageName;
                }
                var url =
                  UrlReplace(
                    baseModel.siteUrlPrefix + "/" + type + "/" + item.name
                  ) +
                  "/" +
                  item.store_id;
                document
                  .getElementById("liDescriptionAnchor")
                  .setAttribute("href", url);
                document
                  .getElementById("liStoreAnchor")
                  .setAttribute("href", url);
                document
                  .getElementById("liDescriptionAnchorOffer")
                  .setAttribute("href", url + "#OFFERS");

                if (item._links.logo.href != null) {
                  document
                    .getElementById("liImage")
                    .setAttribute("src", item._links.logo.href);
                  document
                    .getElementById("liImage")
                    .setAttribute("alt", item.name);
                  document
                    .getElementById("liImage")
                    .setAttribute("title", item.name);
                } else if (item._links.store_front.href != null) {
                  document
                    .getElementById("liImage")
                    .setAttribute("src", item._links.store_front.href);
                  document
                    .getElementById("liImage")
                    .setAttribute("alt", item.name);
                  document
                    .getElementById("liImage")
                    .setAttribute("title", item.name);
                } else if ((type = baseModel.storePageName)) {
                  if (
                    document.getElementById("liSpanStoresDefaultPicto") !== null
                  )
                    document.getElementById(
                      "liSpanStoresDefaultPicto"
                    ).style.display = "block";
                  if (
                    document.getElementById("liSpanRestaurantsDefaultPicto") !==
                    null
                  )
                    document.getElementById(
                      "liSpanRestaurantsDefaultPicto"
                    ).style.display = "none";
                } else if ((type = baseModel.restaurantPageName)) {
                  if (
                    document.getElementById("liSpanRestaurantsDefaultPicto") !==
                    null
                  )
                    document.getElementById(
                      "liSpanRestaurantsDefaultPicto"
                    ).style.display = "block";
                  if (
                    document.getElementById("liSpanStoresDefaultPicto") !== null
                  )
                    document.getElementById(
                      "liSpanStoresDefaultPicto"
                    ).style.display = "none";
                }

                document.getElementById("liDescriptionAnchor").innerText =
                  item.name;
                if (
                  document.querySelectorAll("#storesresult") !== undefined &&
                  document.querySelectorAll("#storesresult") !== null
                )
                  storeliResult =
                    storeliResult +
                    document.querySelectorAll("#storesresult")[0].outerHTML;
              });

              var myNode2 = document.getElementById("ulStores");

              while (myNode2.firstChild) {
                myNode2.removeChild(myNode2.firstChild);
              }
              if (
                document.getElementById("ulStores") !== null &&
                document.getElementById("ulStores") !== undefined
              ) {
                document.getElementById("ulStores").style.display = "flex";
                document.getElementById("ulStores").innerHTML += storeliResult;
              }
              if (
                document.getElementById("searchNewStoresList") !== null &&
                document.getElementById("searchNewStoresList") !== undefined
              )
                document.getElementById("searchNewStoresList").style.display =
                  "none";

              SearchResultCount = SearchResultCount + 1;
              document.getElementById("searchContent").style.display = "block";

              gtmResult = gtmResult + 1;
              // if (gtmResult == 3) {
              //   searchResulttimer = setTimeout(fnPushSearchSuccess, 1000);
              //   GTMSearchStatus = vpvSearchResult;
              //   GTMSearchPage = GTMsearchResultPage;
              //   GTMSearchText = TextSearchResult;
              //   GTMSearchCount = TotalSearchResultCount;
              //   var elments = document.getElementsByClassName(
              //     "gtmSearchNavigation"
              //   );
              //   bindSearchHeaderGTMEvents();
              // }
            } else {
              SearchNoResultCount = SearchNoResultCount + 1;
              document.getElementById("search-store-block").classList.add("d-none");
              if (window.innerWidth > 991) {
                document.getElementById("storeTitle").classList.remove("hidden");
                document.getElementById("storeTitle").innerText =
                  baseModel.storeSearchTitle.replace("/&amp;/g", "&") +
                  " " +
                  "(" +
                  0 +
                  ")";
              } else {
                document
                  .getElementById("search-store-block")
                  .classList.add("hidden");
                document.getElementById("storeTitle").classList.add("hidden");
              }

              document.getElementById("ulStores").style.display = "none";

              gtmNoResult = gtmNoResult + 1;
              gtmResult = gtmResult + 1;
              if (gtmNoResult == 3) {
                GTMSearchStatus = vpvSearchNoResult;
                GTMSearchPage = GTMsearchNoResultPage;
                GTMSearchText = TextSearchResult;
                GTMSearchCount = TotalSearchResultCount;
                var elments = document.getElementsByClassName(
                  "gtmSearchNavigation"
                );
                bindSearchHeaderGTMEvents();
              }
            }
          }
          if (result.data.servicedata) {
            var serviceResultData = JSON.parse(result.data.servicedata);
            var servicesfilterresult = new Array();
            serviceResultData.data.hits.forEach((item) => {
              if (item.active == true) {
                servicesfilterresult.push(item);
              }
            });
            servicesresult = servicesfilterresult.reduce(function (item, e1) {
              var matches = item.filter(function (e2) {
                return e1.short_title == e2.short_title;
              });
              if (matches.length == 0) {
                item.push(e1);
              }
              return item;
            }, []);

            if (servicesresult.length > 0) {
              document
                .getElementById("search-service-block")
                .classList.remove("d-none");
              document
                .getElementById("serviceTitle")
                .classList.remove("hidden");
              document.getElementById("serviceTitle").innerText =
                baseModel.servicesSearchTitle.replace("/&amp;/g", "&") +
                " " +
                "(" +
                servicesresult.length +
                ")";

              TotalSearchResultCount =
                TotalSearchResultCount + servicesresult.length;

              var serviceliResult = "";

              servicesresult.forEach((item) => {
                if (item.active) {
                  var url = UrlReplace(
                    baseModel.siteUrlPrefix +
                    "/" +
                    baseModel.serviceDetailPage +
                    "/" +
                    item.short_title
                  );
                  document
                    .getElementById("liServiceDescription")
                    .setAttribute("href", url);
                  document
                    .getElementById("liServiceAnchor")
                    .setAttribute("href", url);

                  if (
                    item.picto_on == "" ||
                    item.picto_on == undefined ||
                    item.picto_on == "" ||
                    item.picto_on == null
                  ) {
                    document.getElementById("liDefaultPicto").style.display =
                      "block";
                    document.getElementById("liServicePicto").style.display =
                      "none";
                  } else {
                    var pictoStyle = item.picto_on
                      .replace("<i class='picto ", "")
                      .replace("'></i>", "");
                    document.getElementById("liDefaultPicto").style.display =
                      "none";
                    document
                      .getElementById("liServicePicto")
                      .setAttribute("class", "");
                    const cls = ["picto", pictoStyle];
                    cls.forEach((ele) => {
                      document
                        .getElementById("liServicePicto")
                        .classList.add(...cls);
                    });
                    document.getElementById("liServicePicto").style.display =
                      "block";
                  }
                  if (
                    document.querySelectorAll("#liServiceDescription")[0] !==
                    null &&
                    document.querySelectorAll("#liServiceDescription")[0] !==
                    undefined
                  )
                    document.querySelectorAll(
                      "#liServiceDescription"
                    )[0].innerText = item.short_title;
                  if (
                    document.querySelectorAll("#servicesresult")[0] !== null &&
                    document.querySelectorAll("#servicesresult")[0] !==
                    undefined
                  )
                    serviceliResult =
                      serviceliResult +
                      document.querySelectorAll("#servicesresult")[0].outerHTML;
                }
              });

              var myNode3 = document.getElementById("ulServices");
              while (myNode3.firstChild) {
                myNode3.removeChild(myNode3.firstChild);
              }
              document.getElementById("ulServices").style.display = "flex";
              document.getElementById("ulServices").innerHTML +=
                serviceliResult;
              if (
                document.getElementById("searchNewStoresList") !== null &&
                document.getElementById("searchNewStoresList") !== undefined
              )
                document.getElementById("searchNewStoresList").style.display =
                  "none";
              SearchResultCount = SearchResultCount + 1;
              document.getElementById("searchContent").style.display = "block";

              gtmResult = gtmResult + 1;

              // if (gtmResult == 3) {
              //   GTMSearchText = TextSearchResult;
              //   searchResulttimer = setTimeout(fnPushSearchSuccess, 1000);
              //   GTMSearchStatus = vpvSearchResult;
              //   GTMSearchPage = GTMsearchResultPage;
              //   GTMSearchCount = TotalSearchResultCount;
              //   var elments = document.getElementsByClassName(
              //     "gtmSearchNavigation"
              //   );
              //   bindSearchHeaderGTMEvents();
              // }
            } else {
              SearchNoResultCount = SearchNoResultCount + 1;

              if (window.innerWidth > 991) {
                document
                  .getElementById("search-service-block")
                  .classList.add("d-none");
                // document
                //   .getElementById("serviceTitle")
                //   .classList.remove("hidden");
                // document.getElementById("serviceTitle").innerText =
                //   baseModel.servicesSearchTitle.replace("/&amp;/g", "&") +
                //   " " +
                //   "(" +
                //   0 +
                //   ")";
              } else {
                document
                  .getElementById("search-service-block")
                  .classList.add("d-none");
                document.getElementById("serviceTitle").classList.add("hidden");
              }

              document.getElementById("ulServices").style.display = "none";

              gtmNoResult = gtmNoResult + 1;
              gtmResult = gtmResult + 1;

              if (gtmNoResult == 3) {
                GTMSearchStatus = vpvSearchNoResult;
                GTMSearchPage = GTMsearchNoResultPage;
                GTMSearchText = TextSearchResult;
                GTMSearchCount = TotalSearchResultCount;
                var elments = document.getElementsByClassName(
                  "gtmSearchNavigation"
                );
                bindSearchHeaderGTMEvents();
              }
            }
          }
          if (result.data.moviedata) {
            if (baseModel.isEuropeanSite.toString().toLowerCase() == "false") {
              var filmResultData = JSON.parse(result.data.moviedata);
              filmsresult = filmResultData.data.hits.reduce(function (
                item,
                e1
              ) {
                var matches = item.filter(function (e2) {
                  return e1.title == e2.title;
                });
                if (matches.length == 0) {
                  item.push(e1);
                }
                return item;
              },
                []);

              if (filmsresult.length > 0) {
                document
                  .getElementById("search-cinema-block")
                  .classList.remove("d-none");
                document.getElementById("filmTitle").classList.remove("hidden");
                document.getElementById("filmTitle").innerText =
                  baseModel.filmsSearchTitle.replace("/&amp;/g", "&") +
                  " " +
                  "(" +
                  filmsresult.length +
                  ")";

                TotalSearchResultCount =
                  TotalSearchResultCount + filmsresult.length;

                var filmliResult = "";
                filmsresult.forEach((item) => {
                  var url = UrlReplace(
                    baseModel.siteUrlPrefix +
                    "/" +
                    baseModel.movieDetailPage +
                    "/" +
                    item.title
                  );
                  if (
                    document.getElementById("liDescriptionAnchorMovie") !== null
                  )
                    document
                      .getElementById("liDescriptionAnchorMovie")
                      .setAttribute("href", url);

                  if (item._links.image.href != null) {
                    document
                      .getElementById("movieblock")
                      .classList.add("with-banner");
                    document
                      .getElementById("movieblock")
                      .classList.remove("with-logo");
                    document
                      .getElementById("liImgFilms")
                      .setAttribute("src", item._links.image.href);
                    document
                      .getElementById("liImgFilms")
                      .setAttribute("alt", item.title);
                    if (document.getElementById("liSpanFilms"))
                      document.getElementById("liSpanFilms").style.display = "none";
                    document.getElementById("liImgFilms").style.display =
                      "block";
                  } else {
                    document
                      .getElementById("movieblock").classList.add("with-logo");
                    document
                      .getElementById("movieblock")
                      .classList.remove("with-banner");
                    if (document.getElementById("liSpanFilms"))
                      document.getElementById("liSpanFilms").style.display =
                        "none";
                    document.getElementById("liImgFilms").style.display =
                      "none";
                  }

                  document
                    .getElementById("liDescriptionAnchorMovie")
                    .setAttribute("href", url);
                  document
                    .getElementById("liDescriptionAnchorOffer")
                    .setAttribute("href", url);

                  document.getElementById(
                    "liDescriptionAnchorMovie"
                  ).innerText = item.title;
                  document.getElementById(
                    "liDescriptionAnchorOffer"
                  ).innerText = item.classification.rating;
                  if (
                    document.querySelectorAll("#liFilms")[0] !== undefined &&
                    document.querySelectorAll("#liFilms")[0] !== null
                  )
                    filmliResult =
                      filmliResult +
                      document.querySelectorAll("#liFilms")[0].outerHTML;
                });

                var myNode4 = document.getElementById("ulFilms");
                while (myNode4.firstChild) {
                  myNode4.removeChild(myNode4.firstChild);
                }
                document.getElementById("ulFilms").style.display = "flex";
                document.getElementById("ulFilms").innerHTML += filmliResult;

                if (document.getElementById("searchNewStoresList") !== null)
                  document.getElementById("searchNewStoresList").style.display =
                    "none";

                SearchResultCount = SearchResultCount + 1;
                gtmResult = gtmResult + 1;

                // if (gtmResult == 3) {
                //   GTMSearchText = TextSearchResult;
                //   searchResulttimer = setTimeout(fnPushSearchSuccess, 1000);
                //   GTMSearchStatus = vpvSearchResult;
                //   GTMSearchPage = GTMsearchResultPage;
                //   GTMSearchCount = TotalSearchResultCount;
                //   var elments = document.getElementsByClassName(
                //     "gtmSearchNavigation"
                //   );
                //   bindSearchHeaderGTMEvents();
                // }
              } else {
                SearchNoResultCount = SearchNoResultCount + 1;

                if (window.innerWidth > 991) {
                  document
                    .getElementById("search-cinema-block")
                    .classList.add("d-none");
                  // document
                  //   .getElementById("filmTitle")
                  //   .classList.remove("hidden");
                  // document.getElementById("filmTitle").innerText =
                  //   baseModel.filmsSearchTitle.replace("/&amp;/g", "&") +
                  //   " " +
                  //   "(" +
                  //   0 +
                  //   ")";
                } else {
                  document
                    .getElementById("search-cinema-block")
                    .classList.add("d-none");
                  document.getElementById("filmTitle").classList.add("hidden");
                }
                document.getElementById("ulFilms").style.display = "none";

                gtmNoResult = gtmNoResult + 1;
                gtmResult = gtmResult + 1;
                if (gtmNoResult == 3) {
                  GTMSearchStatus = vpvSearchNoResult;
                  GTMSearchPage = GTMsearchNoResultPage;
                  GTMSearchText = TextSearchResult;
                  GTMSearchCount = TotalSearchResultCount;
                  var elments = document.getElementsByClassName(
                    "gtmSearchNavigation"
                  );
                  bindSearchHeaderGTMEvents();
                }
              }
            }
            else {
              var filmsresult = JSON.parse(result.data.moviedata);
              if (
                filmsresult != null &&
                filmsresult != undefined &&
                filmsresult != ""
              ) {
                if (
                  filmsresult.Movies_Results.length > 0 ||
                  filmsresult.CinemaDistributor_Results.length > 0
                ) {
                  if (window.innerWidth > 991) {
                    document.getElementById("filmTitle").classList.remove("hidden");
                    document.getElementById("filmTitle").innerText =
                      baseModel.filmsSearchTitle.replace("/&amp;/g", "&") +
                      " " +
                      "(" +
                      (filmsresult.Movies_Results.length +
                        filmsresult.CinemaDistributor_Results.length) +
                      ")";
                  } else {
                    document
                      .getElementById("search-cinema-block")
                      .classList.remove("d-none");
                    document.getElementById("filmTitle").classList.remove("hidden");
                    if (
                      document.getElementById("filmTitle") !== undefined &&
                      document.getElementById("filmTitle") !== null
                    )
                      document.getElementById("filmTitle").innerText =
                        baseModel.filmsSearchTitle.replace("/&amp;/g", "&") +
                        " " +
                        "(" +
                        (filmsresult.Movies_Results.length +
                          filmsresult.CinemaDistributor_Results.length) +
                        ")";
                  }

                  TotalSearchResultCount =
                    TotalSearchResultCount +
                    (filmsresult.Movies_Results.length +
                      filmsresult.CinemaDistributor_Results.length);

                  var filmliResult = "";
                  filmsresult.Movies_Results.forEach((item) => {
                    if (document.getElementById("liAnchorFilms") !== null)
                      document
                        .getElementById("liAnchorFilms")
                        .setAttribute(
                          "href",
                          baseModel.siteUrlPrefix + item.Redirection_Link
                        );

                    if (item.Service_Picto_Off != null) {
                      document
                        .getElementById("movieblock")
                        .classList.add("with-banner");
                      document
                        .getElementById("movieblock")
                        .classList.remove("with-logo");
                      if (document.getElementById("liImgFilms") !== null) {
                        document
                          .getElementById("liImgFilms")
                          .setAttribute("src", item.Service_Picto_Off);
                        document
                          .getElementById("liImgFilms")
                          .setAttribute("alt", item.Display_Name);
                        document.getElementById("liImgFilms").style.display =
                          "block";
                      }
                      if (document.getElementById("liSpanFilms") !== null)
                        document.getElementById("liSpanFilms").style.display =
                          "none";
                    } else {
                      document
                        .getElementById("movieblock")
                        .classList.add("with-logo");
                      document
                        .getElementById("movieblock")
                        .classList.remove("with-banner");
                      if (document.getElementById("liSpanFilms"))
                        document.getElementById("liSpanFilms").style.display =
                          "block";
                      document.getElementById("liImgFilms").style.display = "none";
                    }

                    document
                      .getElementById("liDescriptionAnchorMovie")
                      .setAttribute(
                        "href",
                        baseModel.siteUrlPrefix + item.Redirection_Link
                      );
                    document
                      .getElementById("liDescriptionAnchorOffer")
                      .setAttribute(
                        "href",
                        baseModel.siteUrlPrefix + item.Redirection_Link
                      );

                    document.getElementById("liDescriptionAnchorMovie").innerText =
                      item.Display_Name;
                    document.getElementById("liDescriptionAnchorOffer").innerText =
                      item.Cinema_Versions;
                    if (
                      document.querySelectorAll("#liFilms")[0] !== undefined &&
                      document.querySelectorAll("#liFilms")[0] !== null
                    )
                      filmliResult =
                        filmliResult +
                        document.querySelectorAll("#liFilms")[0].outerHTML;
                  });

                  filmsresult.CinemaDistributor_Results.forEach((item) => {
                    if (document.getElementById("liAnchorDistributor") !== null)
                      document
                        .getElementById("liAnchorDistributor")
                        .setAttribute(
                          "href",
                          baseModel.siteUrlPrefix + item.Redirection_Link
                        );

                    if (item.Store_Logo != null) {
                      if (document.getElementById("liImageDistributor1") !== null) {
                        document
                          .getElementById("liImageDistributor1")
                          .setAttribute("src", item.Store_Logo);
                        document
                          .getElementById("liImageDistributor1")
                          .setAttribute("alt", item.Store_Logo_Alt_Text);
                      }
                      if (document.getElementById("liImageDistributor2") !== null)
                        document.getElementById(
                          "liImageDistributor2"
                        ).style.display = "none";
                    } else {
                      if (document.getElementById("liImageDistributor2") !== null) {
                        document.getElementById(
                          "liImageDistributor2"
                        ).style.display = "block";
                        document
                          .getElementById("liImageDistributor2")
                          .setAttribute(
                            "src",
                            baseModel.searchDistributorDefaultImage
                          );
                        document
                          .getElementById("liImageDistributor2")
                          .setAttribute("alt", item.Display_Name);
                      }
                      if (document.getElementById("liImageDistributor1") !== null)
                        document.getElementById(
                          "liImageDistributor1"
                        ).style.display = "none";
                    }

                    document
                      .getElementById("liDescriptionAnchorDistributor")
                      .setAttribute(
                        "href",
                        baseModel.siteUrlPrefix + item.Redirection_Link
                      );
                    if (
                      document.getElementById("liDescriptionAnchorDistributor") !==
                      undefined &&
                      document.getElementById("liDescriptionAnchorDistributor") !==
                      null
                    )
                      document.getElementById(
                        "liDescriptionAnchorDistributor"
                      ).innerText = item.Display_Name;
                    if (
                      document.querySelectorAll("#liDistributor") !== undefined &&
                      document.querySelectorAll("#liDistributor") !== null
                    )
                      filmliResult =
                        filmliResult +
                        document.querySelectorAll("#liDistributor")[0].outerHTML;
                  });

                  var myNode = document.getElementById("ulFilms");
                  while (myNode.firstChild) {
                    myNode.removeChild(myNode.firstChild);
                  }
                  document.getElementById("ulFilms").style.display = "flex";
                  if (
                    document.getElementById("ulFilms") !== null &&
                    document.getElementById("ulFilms") !== undefined
                  )
                    document.getElementById("ulFilms").innerHTML += filmliResult;
                  if (document.getElementById("searchNewStoresList") !== null)
                    document.getElementById("searchNewStoresList").style.display =
                      "none";

                  SearchResultCount = SearchResultCount + 1;
                  gtmResult = gtmResult + 1;

                  // if (gtmResult == 3) {
                  //   GTMSearchText = TextSearchResult;
                  //   searchResulttimer = setTimeout(fnPushSearchSuccess, 1000);
                  //   GTMSearchStatus = vpvSearchResult;
                  //   GTMSearchPage = GTMsearchResultPage;
                  //   GTMSearchCount = TotalSearchResultCount;
                  //   var elments = document.getElementsByClassName(
                  //     "gtmSearchNavigation"
                  //   );
                  //   bindSearchHeaderGTMEvents();
                  // }
                } else {
                  SearchNoResultCount = SearchNoResultCount + 1;

                  if (window.innerWidth > 991) {
                    document
                      .getElementById("search-cinema-block")
                      .classList.add("d-none");
                    // document.getElementById("filmTitle").classList.remove("hidden");
                    // document.getElementById("filmTitle").innerText =
                    //   baseModel.filmsSearchTitle.replace("/&amp;/g", "&") +
                    //   " " +
                    //   "(" +
                    //   0 +
                    //   ")";
                  } else {
                    document
                      .getElementById("search-cinema-block")
                      .classList.add("d-none");
                    document.getElementById("filmTitle").classList.add("hidden");
                  }
                  document.getElementById("ulFilms").style.display = "none";

                  gtmNoResult = gtmNoResult + 1;
                  gtmResult = gtmResult + 1;
                  if (gtmNoResult == 3) {
                    GTMSearchStatus = vpvSearchNoResult;
                    GTMSearchPage = GTMsearchNoResultPage;
                    GTMSearchText = TextSearchResult;
                    GTMSearchCount = TotalSearchResultCount;
                    var elments = document.getElementsByClassName(
                      "gtmSearchNavigation"
                    );
                    bindSearchHeaderGTMEvents();
                  }
                }
              }
            }
          }
          if (gtmResult == 3) {
            GTMSearchText = TextSearchResult;
            if (TotalSearchResultCount > 0)
              searchResulttimer = setTimeout(fnPushSearchSuccess, 1000);
            GTMSearchStatus = vpvSearchResult;
            GTMSearchPage = GTMsearchResultPage;
            GTMSearchCount = TotalSearchResultCount;
            var elments = document.getElementsByClassName(
              "gtmSearchNavigation"
            );
            bindSearchHeaderGTMEvents();
          }
        } else {
          // Handle the failure case
          console.error('Error fetching data:', result.data.message);
        }
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else if (error.code === 'ECONNABORTED') {
          console.log('Request timed out');
          // Handle timeout - return empty result or show a message to the user
          //alert("There are some technical issues, please try after sometime.");
          //openConfirmationPopin();
          document.getElementById('searchTimeOut').classList.remove('d-none')
        }
        SearchResultCount += 1;
        storesresult = servicesresult = filmsresult = null;
      });
    // .catch(function (data) {
    //   SearchResultCount = SearchResultCount + 1;
    //   filmsresult = null;
    // });
  } else {
    SearchResultCount = -2;
    searchTextLessThan2Length = "true";
    document.getElementById("searchContent").style.display = "none";
    if (document.getElementById("search-no-results") !== null)
      document.getElementById("search-no-results").style.display = "none";
    if (document.getElementById("searchNewStoresList") !== null)
      document.getElementById("searchNewStoresList").style.display = "block";
  }
}

export function fnSetSearchResultVisible() {
  if (document.getElementById("search-no-results") !== null)
    document.getElementById("search-no-results").style.display = "none";
  if (document.getElementById("searchNewStoresList") !== null)
    document.getElementById("searchNewStoresList").style.display = "none";
  if (document.getElementById("searchContent") !== null)
    document.getElementById("searchContent").style.display = "block";
}
export function fnSetSearchNoResultsVisibile() {
  if (document.getElementById("storeTitle") !== null)
    document.getElementById("storeTitle").classList.add("hidden");
  if (document.getElementById("filmTitle") !== null)
    document.getElementById("filmTitle").classList.add("hidden");
  if (document.getElementById("serviceTitle") !== null)
    document.getElementById("serviceTitle").classList.add("hidden");
  if (document.getElementById("searchContent") !== null)
    document.getElementById("searchContent").style.display = "none";

  if (searchTextLessThan2Length == "true") {
    if (document.getElementById("search-no-results") !== null)
      document.getElementById("search-no-results").style.display = "none";
    if (document.getElementById("searchNewStoresList") !== null)
      document.getElementById("searchNewStoresList").style.display = "block";
  } else {
    if (document.getElementById("search-no-results") !== null)
      document.getElementById("search-no-results").style.display = "block";
    if (document.getElementById("searchNewStoresList") !== null)
      document.getElementById("searchNewStoresList").style.display = "none";
  }
}

export function fnSearchNoResult() {
  if (document.getElementById("search-no-results") &&
    document.getElementById("search-no-results").innerHTML == "") {
    var url =
      "/api/sitecore/SearchApi/GetSearchNoResults?ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang;
    axios({
      url: url,
      type: "get",
    }).then(function (response) {
      createRoot(document.getElementById("search-no-results")).render(<SearchNoResult model={response.data} />);
    });
  }
}

export function LoadNewStores() {
  if (document.getElementById("searchNewStoresList") &&
    document.getElementById("searchNewStoresList").innerHTML == "") {
    var ajaxSearchURL =
      "/api/sitecore/SearchApi/GetNewStores?ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang;
    ajaxCallPresent = true;
    ajaxCallIncrement = ajaxCallIncrement + 1;
    axios({
      url: ajaxSearchURL,
      type: "get",
      async: true,
      cache: true,
    })
      .then(function (response) {
        {
          createRoot(document.getElementById("searchNewStoresList")).render(
            <SearchNewStores model={response.data} />
          );
        }
      })
      .catch(function (a, b, c) {
        if (document.getElementById("searchContent1") !== null)
          document.getElementById("searchContent1").style.display = "none";

      });
  }
  unBindSearchCloseEvents();
}
export function unBindSearchCloseEvents() {
  if (
    document.getElementsByTagName("body")[0].classList.contains("on-search")
  ) {
    document
      .querySelectorAll("#headerBlock a")
      .forEach((x) => x.removeEventListener("click", headerSearchCloseTrack));
    document
      .querySelectorAll("#MainHeader a")
      .forEach((x) => x.removeEventListener("click", headerSearchCloseTrack));
  }
}
function bindSearchHeaderGTMEvents() {
  if (
    document.getElementsByTagName("body")[0].classList.contains("on-search")
  ) {
    document
      .querySelectorAll("#headerBlock a")
      .forEach((x) => x.removeEventListener("click", headerSearchCloseTrack));
    document
      .querySelectorAll("#MainHeader a")
      .forEach((x) => x.removeEventListener("click", headerSearchCloseTrack));
  }
}


function headerSearchCloseTrack() {
  if (TextSearchResult != undefined) {
    unBindSearchCloseEvents();
  }
}

function IsNewStore(storeStartDate) {
  var date = new Date().toUTCString();
  return storeStartDate != null ? date - storeStartDate < 30 : false;
}

function UrlReplace(url) {
  if (url != null) {
    return url
      .replaceAll("&", "_o_")
      .replaceAll("+", "_p_")
      .replaceAll("%", "_e_")
      .replaceAll("?", "_q_")
      .replaceAll("<", "_l_")
      .replaceAll(">", "_r_")
      .replaceAll("*", "_a_")
      .replaceAll(":", "_c_")
      .replaceAll('"', "_s_")
      .replaceAll(".", "~d~")
      .replaceAll("'", "_t_")
      .replaceAll(/ /g, "-").toLowerCase();
  }
  return url;
}

function fnPushSearchSuccess() {
  if (TextSearchResult !== null && TextSearchResult !== "") {
    window.dataLayer.push({
      event: 'search',
      page: (global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
      search_term: (TextSearchResult !== null && TextSearchResult !== "") ? TextSearchResult : undefined,
      site_search_results: (TotalSearchResultCount !== null && TotalSearchResultCount !== "") ? TotalSearchResultCount : undefined,
    });
  }
}


export function fnSearchIconClick() {
  if (document.getElementById("stickySearchIcon") !== null) {
    document.getElementById("stickySearchIcon").addEventListener("click", function (e) {

      var hamburgerElement =
        document.getElementsByClassName("navbar-toggler")[0];
      if (!hamburgerElement.classList.contains("collapsed")) {
        document.getElementsByClassName("navbar-toggler")[0].click();
        if (document.getElementById("searchPopin") !== null) {
          document.getElementById("searchPopin").style.display = "block";
        }
      }

      searchIntervalTimer = setInterval(fnSearchBind, 1000);

      if (baseModel.searchPage) {
        if (typeof window.history.pushState != "undefined") {
          var obj = {
            Page: "",
            Url: baseModel.siteUrlPrefix + baseModel.homePage,
          };
          window.history.pushState(obj, obj.Page, obj.Url);
          // Redirect to Home page on SearchClose is searchpage.
          window.location.reload();
        }
      }

      if (window.location.href.includes('/search')) {
        LoadNewStores();
        fnSearchNoResult();
      }

      SearchResultCount = -2;
      searchTextLessThan2Length = "true";
    });
  }

  if (document.getElementById("mainSearchIcon") !== null) {
    document.getElementById("mainSearchIcon").addEventListener("click", function (e) {
      var element = document.getElementById("NoticeBlock");
      if (element) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
          document.body.classList.remove("emergency-notice-show");
        }
        else {
          if (!element.getAttribute("data-bannerCookie")) {
            element.classList.remove("d-none");
            document.body.classList.add("emergency-notice-show");
          }

        }
      }

      searchIntervalTimer = setInterval(fnSearchBind, 1000);

      if (baseModel.searchPage) {
        if (typeof window.history.pushState != "undefined") {
          var obj = {
            Page: "",
            Url: baseModel.siteUrlPrefix + baseModel.homePage,
          };
          window.history.pushState(obj, obj.Page, obj.Url);
          // Redirect to Home page on SearchClose is searchpage.
          window.location.reload();
        }
      }

      if (window.location.href.includes('/search')) {
        LoadNewStores();
        fnSearchNoResult();
      }

      SearchResultCount = -2;
      searchTextLessThan2Length = "true";
    });
  }
}

export function fnSearchBind() {
  if (SearchResultCount > 0) {
    fnSetSearchResultVisible();
  } else if (SearchNoResultCount == 3 || SearchResultCount == -2) {
    fnSetSearchNoResultsVisibile();
  }
}

export function fnsearchTextReset() {
  var elments;
  //Search text box clear icon click
  elments = document.getElementById("searchTextReset");
  if (elments !== null) {
    elments.addEventListener("click", function (e) {
      if (document.getElementById("search-no-results") !== null)
        document.getElementById("search-no-results").style.display = "none";
      if (document.getElementById("searchTextReset") !== null)
        document.getElementById("searchTextReset").style.display = "none";
      if (document.getElementById("searchContent") !== null)
        document.getElementById("searchContent").style.display = "none";
      if (document.getElementById("searchNewStoresList") !== null)
        document.getElementById("searchNewStoresList").style.display = "block";
      SearchResultCount = -2;
      searchTextLessThan2Length = "true";
    });
  }
}

export function fnIsSearchPage() {
  if (baseModel.searchPage) {
    if (document.getElementById("searchNewStoresList") !== null)
      document.getElementById("searchNewStoresList").style.display = "block";
    SearchResultCount = -2;
    searchTextLessThan2Length = "true";
  }
}

export function dateselect() {
  var Alldatelist = document
    .getElementById("showtimes")
    .getElementsByTagName("div");
  var dataArray = [];
  var alldaystring = "Alldays";
  var a = 0;
  for (var n = 0; n < Alldatelist.length; n++) {
    var allid = Alldatelist[n].id;
    var pattern = allid.substring(0, 7);
    if (pattern == alldaystring) {
      var Finalid = allid.replace(alldaystring, "");
      dataArray[a] = Finalid;
      a++;
    }
  }
  var selectedid = document.getElementById("selectdatelist");
  var sid = selectedid[selectedid.selectedIndex].value;
  for (var i = 0; i < dataArray.length; i++) {
    if (sid == dataArray[i]) {
      document
        .getElementById(alldaystring + dataArray[i])
        .classList.remove("d-none");
      document
        .getElementById(alldaystring + dataArray[i])
        .classList.add("d-flex");
    } else {
      document
        .getElementById(alldaystring + dataArray[i])
        .classList.remove("d-flex");
      document
        .getElementById(alldaystring + dataArray[i])
        .classList.add("d-none");
    }
  }
}

export function fnEventOccurrenceDetails(e, displayedEventCount) {
  if (typeof document !== "undefined") {
    if (e.currentTarget.classList.contains("collapsed")) {
      e.currentTarget.classList.remove("collapsed")
      for (let i = 5; i <= displayedEventCount; i++) {
        document
          .getElementById("event" + i + "_Details")
          .classList.remove("d-none");
      }
    } else {
      e.currentTarget.classList.add("collapsed")
      for (let i = 5; i <= displayedEventCount; i++) {
        document
          .getElementById("event" + i + "_Details")
          .classList.add("d-none");
      }
    }
  }
}



//Load contents from StoreRightInfoBlock when loaded in mobile view
export function storeInfoRightMobile() {
  if (window.innerWidth < 767) {
    if (
      document.getElementById("openings") !== null &&
      document.getElementById("openings") !== undefined && document.getElementById("leftinfo")
    ) {
      document
        .getElementById("openings")
        .appendChild(document.getElementById("leftinfo"));
    }

    if (
      document.getElementById("availableservices") !== null &&
      document.getElementById("availableservices").length > 0 &&
      document.getElementById("storesservices") !== null
    ) {
      document.getElementById("storesservices").classList.remove("hidden");
    }
  }
}

export function openLoyaltyCardBarCodePopin(barcode) {
  if (
    document.getElementById("popinBarcode") !== null &&
    document.getElementById("popinBarcode") !== ""
  ) {
    var barCodeHtml = document.getElementById("popinBarcode").innerHTML;
    if (barCodeHtml.trim() == "") {
      createRoot(document.getElementById("popinBarcode")).render(
        <LoyaltyCardBarCodePopin model={barcode} />
      );
    } else {
      var ele = document.getElementById("barCodeModalPopin");
      if (ele !== null && ele !== undefined) {
        var popinClick = document.getElementById("popinBarCodeClick");
        popinClick.click();
      }
    }

    return false;
  }
}

export function dynamicAttributes(attribute, value) {
  var opts = {};
  if (typeof value !== "undefined" && value !== null) {
    opts['"' + attribute + '"'] = value;
    return opts;
  }
  return false;
}

export function optinSubmitChange(termsCheckBox, isBirthDateBlockDisplay) {
  if (!termsCheckBox.classList.contains("active")) {
    //Set the disabled property to FALSE and enable the button.
    document
      .getElementsByClassName("js-privacyoptin_submit")[0]
      .removeAttribute("disabled");
    var ele = document.querySelectorAll(".js-optincheckbox")[0];
    ele.classList.add("active");
    if (ele !== termsCheckBox) {
      ele.classList.add("active");
    }

  } else {
    //Otherwise, disable the submit button.
    document
      .getElementsByClassName("js-privacyoptin_submit")[0]
      .setAttribute("disabled", "disabled");
    var ele = document.querySelectorAll(".js-optincheckbox")[0];
    ele.classList.remove("active");


  }
}

export function setmeSearch(thisli, thisnode) {
  if (document.getElementsByClassName("makethisactive").length > 0) {
    var makeThisActiveEle = document.querySelectorAll(".makethisactive");
    makeThisActiveEle.forEach((x) => {
      x.classList.remove("active");
      var ele = document.getElementsByClassName("gtmBookmarkTabs genericTabs");
      for (let i = 0; i < ele.length; i++) {
        ele[i].removeAttribute("style");
      }
    });
  }
  var tabbingChildren = document.getElementById("Tabbing").children;
  if (tabbingChildren.length > 0) {
    tabbingChildren.forEach(function (x) {
      if (x.classList.contains("active")) x.classList.remove("active");
    });
  }
  thisnode.parentElement.classList.remove("active");
  var currentHref = thisli.split("#")[1];
  if (!thisli.includes("webview=1")) {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + "#Tab=" + currentHref
    );
  } else {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + "?webview=1" + "#Tab=" + currentHref
    );
  }
  var element = document.getElementById(currentHref);
  if (element != undefined) {
    var children = document.getElementById("myBookmarks").children;
    children.forEach(function (x) {
      if (x.classList.contains("active")) x.classList.remove("active");
      if (x.classList.contains("show")) x.classList.remove("show");
    });
    element.classList.add("active");
    element.classList.add("show");
    thisnode.parentElement.classList.add("active");
  }
}



//Function to load and show generic technical error popin
export function genericTechnicalErrorPopin() {
  axios({
    method: "post",
    url:
      "/api/sitecore/PopinApi/GetGenericTechnicalErrorPopin?ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang,
  })
    .then(function (response) {
      {
        createRoot(document.getElementById("globalPopin")).render(
          <ErrorPopin model={response.data} />
        );
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}



export function formReadyLoad() {
  var searchInput; //WS-1739 - issue 11
  //This has been removed for not to mark as required , for search input field
  var $fakeOptionSelect = document.getElementsByClassName("fake-select")[0];
  if ($fakeOptionSelect) {
    var $fakeSelect = $fakeOptionSelect.next("select");
    $fakeOptionSelect.next("select").addEventListener("change", function (e) {
      var optionSelected =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
      e.currentTarget.prev(".fake-select").text = optionSelected;
    });
  }
  document.querySelectorAll('[data-toggle="tooltipForm"]').forEach((x) => {
    x.tooltip();
  });
  if (document.querySelector(".input-radio")?.closest("input"))
    document.querySelector(".input-radio").closest("input").checked = false;
  if (document.querySelector(".input-radio label")) {
    document
      .querySelector(".input-radio label")
      .addEventListener("click", function (e) {
        var $currentLabel = e.currentTarget;
        var $parentBloc = e.currentTarget.parentElement;
        if ($parentBloc) {
          if ($parentBloc.closest("label"))
            $parentBloc.closest("label").classList.rememberMe("active");
        }
        if ($currentLabel) $currentLabel.classList.add("active");
      });
  }
  if (document.querySelector(".input-checkbox")?.closest("input"))
    document.querySelector(".input-checkbox").closest("input").checked = false;
  if (document.querySelector(".input-files .input-file")) {
    document
      .querySelector(".input-files .input-file")
      .addEventListener("change", function (e) {
        var $elem = e.currentTarget;
        var inputVal = $elem.value.split("\\").pop();
        var $resultInput = $elem.next(".input-result");
        $resultInput.value = inputVal;
      });
  }

  Array.from(document.getElementsByTagName("input")).forEach((x) => {
    x.addEventListener("focusin", function (e) {
      searchInput = e.currentTarget;
      if (
        searchInput.getAttribute("placeholder") !== null &&
        searchInput.getAttribute("placeholder") !== ""
      ) {
        searchInput.setAttribute(
          "data-place-holder-text",
          searchInput.getAttribute("placeholder")
        );
      }
    });
  });
  Array.from(document.getElementsByTagName("input")).forEach((x) => {
    x.addEventListener("focusout", function (e) {
      searchInput = e.currentTarget;
      if (
        searchInput.getAttribute("data-place-holder-text") !== null &&
        searchInput.getAttribute("data-place-holder-text") !== ""
      ) {
        searchInput.setAttribute(
          "placeholder",
          searchInput.getAttribute("data-place-holder-text")
        );
      }
    });
  });
  if (document.getElementById("globalPopin")) {
    document
      .getElementById("globalPopin")
      .addEventListener("shown.bs.modal", function () {
        if (document.querySelector(".popin-wrap .global-form"))
          var v = document.querySelector(".popin-wrap .global-form")[0].validity
            .valid;
      });
  }

  var displaylinesheight = 114;
  var heightofRichtxt = 0;
  if (document.getElementsByClassName("read-more-wrap")[0]) {
    heightofRichtxt =
      document.getElementsByClassName("read-more-wrap")[0].offsetHeight;
    if (document.getElementsByClassName("read-less-trigger")[0])
      document.getElementsByClassName("read-less-trigger")[0].style.display =
        "none";
    if (
      heightofRichtxt < displaylinesheight &&
      document.getElementsByClassName("read-more-trigger")[0]
    ) {
      document.getElementsByClassName("read-more-trigger")[0].style.display =
        "none";
    } else if (
      heightofRichtxt > displaylinesheight &&
      document.getElementsByClassName("read-more-wrap")[0]
    ) {
      document.getElementsByClassName("read-more-wrap")[0].style.height =
        displaylinesheight + "px";
      document.getElementsByClassName("read-more-trigger")[0].style.display =
        "inline-block";
    }

    if (document.getElementsByClassName("read-more-trigger")[0]) {
      document
        .getElementsByClassName("read-more-trigger")[0]
        .addEventListener("click", function () {
          document.getElementsByClassName(
            "read-more-trigger"
          )[0].style.display = "none";
          document.getElementsByClassName(
            "read-less-trigger"
          )[0].style.display = "inline-block";
          document.getElementsByClassName("read-more-wrap")[0].style.height =
            "";
          document
            .getElementsByClassName("read-more-wrap")[0]
            .classList.add("show-more");
        });
    }
    if (document.getElementsByClassName("read-less-trigger")[0]) {
      document
        .getElementsByClassName("read-less-trigger")[0]
        .addEventListener("click", function () {
          document.getElementsByClassName(
            "read-less-trigger"
          )[0].style.display = "none";
          document.getElementsByClassName(
            "read-more-trigger"
          )[0].style.display = "inline-block";
          document
            .getElementsByClassName("read-more-wrap")[0]
            .classList.remove("show-more");
          document.getElementsByClassName("read-more-wrap")[0].style.height =
            displaylinesheight + "px";
        });
    }
  }

  document
    .querySelectorAll(
      ".password-container #password-toggle, .password-container #confirm-password-toggle"
    )
    .forEach((x) => {
      x.addEventListener("click", function (e) {
        fnPasswordToggle(e.currentTarget);
      });
    });
  document
    .querySelectorAll("#accountInformation #oldpassword-toggle")
    .forEach((x) => {
      x.addEventListener("click", function (e) {
        //script for password eye picto behavior
        fnPasswordToggle(e.currentTarget);
      });
    });

  document
    .querySelectorAll("#accountInformation #newpassword-toggle")
    .forEach((x) => {
      x.addEventListener("click", function (e) {
        //script for password eye picto behavior
        fnPasswordToggle(e.currentTarget);
      });
    });
  document
    .querySelectorAll("#accountInformation #confirmpassword-toggle")
    .forEach((x) => {
      x.addEventListener("click", function (e) {
        //script for password eye picto behavior
        fnPasswordToggle(e.currentTarget);
      });
    });
  document.querySelectorAll("#iban-password-toggle").forEach((x) => {
    x.addEventListener("click", function (e) {
      fnPasswordToggle(e.currentTarget);
    });
  });

  //Subscribe page interests
  document.querySelectorAll(".interesetlist").forEach((x) => {
    x.addEventListener("click", function (e) {
      if (e.currentTarget.classList.contains("is-active")) {
        e.currentTarget.classList.remove("is-active");
      } else {
        e.currentTarget.classList.add("is-active");
      }
    });
  });
}

export function formReadyLoadloadable() {
  var displaylinesheight = 114;
  var heightofRichtxt = 0;
  if (document.getElementsByClassName("read-more-wrap")[0]) {
    heightofRichtxt =
      document.getElementsByClassName("read-more-wrap")[0].offsetHeight;
    if (document.getElementsByClassName("read-less-trigger")[0])
      document.getElementsByClassName("read-less-trigger")[0].style.display =
        "none";
    if (
      heightofRichtxt < displaylinesheight &&
      document.getElementsByClassName("read-more-trigger")[0]
    ) {
      document.getElementsByClassName("read-more-trigger")[0].style.display =
        "none";
    } else if (
      heightofRichtxt > displaylinesheight &&
      document.getElementsByClassName("read-more-wrap")[0]
    ) {
      document.getElementsByClassName("read-more-wrap")[0].style.height =
        displaylinesheight + "px";
      document.getElementsByClassName("read-more-trigger")[0].style.display =
        "inline-block";
    }

    if (document.getElementsByClassName("read-more-trigger")[0]) {
      document
        .getElementsByClassName("read-more-trigger")[0]
        .addEventListener("click", function () {
          document.getElementsByClassName(
            "read-more-trigger"
          )[0].style.display = "none";
          document.getElementsByClassName(
            "read-less-trigger"
          )[0].style.display = "inline-block";
          document.getElementsByClassName("read-more-wrap")[0].style.height =
            "";
          document
            .getElementsByClassName("read-more-wrap")[0]
            .classList.add("show-more");
        });
    }
    if (document.getElementsByClassName("read-less-trigger")[0]) {
      document
        .getElementsByClassName("read-less-trigger")[0]
        .addEventListener("click", function () {
          document.getElementsByClassName(
            "read-less-trigger"
          )[0].style.display = "none";
          document.getElementsByClassName(
            "read-more-trigger"
          )[0].style.display = "inline-block";
          document
            .getElementsByClassName("read-more-wrap")[0]
            .classList.remove("show-more");
          document.getElementsByClassName("read-more-wrap")[0].style.height =
            displaylinesheight + "px";
        });
    }
  }
}

export function fnInputCheckBoxClick() {
  document.querySelectorAll(".input-checkbox label").forEach((x) => {
    x.addEventListener("click", function (e) {
      var $currentLabel = e.currentTarget;
      e.currentTarget.classList.contains("active")
        ? $currentLabel.classList.remove("active")
        : $currentLabel.classList.add("active");
    });
  });
}

export function openVehicleErrorPopin() {
  if (document.getElementById("vehicleErrorText") && document.getElementById("vehicleErrorText").classList.contains("d-none")) {
    document.getElementById("vehicleErrorText").classList.remove("d-none");
  }
}

export function closeVehicleErrorPopin() {
  if (document.getElementById("vehicleErrorText") && !document.getElementById("vehicleErrorText").classList.contains("d-none")) {
    document.getElementById("vehicleErrorText").classList.add("d-none");
  }
}

//When the login popin is loaded through ajax, Remember-me checkbox is not working (not getting selected). To fix this issue, need to call this function after loading the checkbox to the page.
function fnCustomCheckBoxScript() {
  if (document.querySelector(".remember-me")) {
    if (document.querySelector(".remember-me").closest("input"))
      document.querySelector(".remember-me").closest("input").checked = false;
  }

  if (document.getElementsByClassName("remember-me label")[0]) {
    document
      .getElementsByClassName("remember-me label")[0]
      .addEventListener("click", function (e) {
        var currentLabel = e.currentTarget;
        e.currentTarget.classList.contains("active")
          ? currentLabel.classList.remove("active")
          : currentLabel.classList.add("active");
      });
  }
}

function fnPasswordToggle(pswdField) {
  var para = pswdField;
  const cls = ["icon-ico-eye-hide", "icon-ico-eye-reveal"];
  if (para.classList.contains("icon-ico-eye-hide")) {
    para.classList.remove("icon-ico-eye-hide");
    para.classList.add("icon-ico-eye-reveal");
  } else {
    para.classList.add("icon-ico-eye-hide");
    para.classList.remove("icon-ico-eye-reveal");
  }

  var attrValue = para.getAttribute("toggle");
  var input = document.querySelector(attrValue);
  if (input.getAttribute("type") == "password") {
    input.setAttribute("type", "text");
  } else {
    input.setAttribute("type", "password");
  }
}


export function fnGtmRetailerServiceClick(serviceMenuName) {
  window.dataLayer.push({
    'event': 'click_retailer_service',
    'retailer_name': (he.decode(baseModel.storeSIName) !== null && he.decode(baseModel.storeSIName) !== "") ? he.decode(baseModel.storeSIName) : undefined,
    'retailer_service_name': (serviceMenuName !== null && serviceMenuName !== "") ? serviceMenuName : undefined,
  });
}

export function createCookieLoyaltyProgramPage(isCookiePresent) {
  if (!isCookiePresent) {
    createCookie("showtcpopin", "true", 0);
  }
}

export function NavigateToTCFunnelPage(url) {
  if (url != null && url != undefined && url != "") {
    URWindowLocation(url);
  }
}


//Delete Account
export function DeleteAcountConfirmation(url) {
  if (
    document.getElementById("popinAccDelcode") !== null &&
    document.getElementById("popinAccDelcode") !== ""
  ) {
    var globalPopinHtml = document.getElementById("popinAccDelcode").innerHTML;
    if (globalPopinHtml.trim() === "") {
      var ajaxUrl =
        url +
        "&ursite=" +
        baseModel.urContextSite +
        "&urlang=" +
        baseModel.contextLang;
      axios({
        method: "post",
        url: ajaxUrl,
        async: false,
      })
        .then(function (response) {
          createRoot(document.getElementById("popinAccDelcode")).render(
            <IntroductionInnerPage introductionData={response.data} />
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      var ele = document.getElementById("modalDelAccSuccess");
      if (ele !== null && ele !== undefined) {
        var popinClick = document.getElementById("popinDelAccClick");
        popinClick.click();
      }
    }
  }
  return false;
}


export function RedirectToTCConnection(element, TCPremiumAccountUrl) {
  axios({
    method: "post",
    url:
      TCPremiumAccountUrl +
      "?ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang +
      "&forNewConnection=" +
      true,
    dataType: "json",
  })
    .then(function (response) {
      URWindowLocation(response.data[0]);
    })
    .catch(function (error) {
      console.log(error);
    });
  return false;

}



export function GenericBlocksMobile() {
  if (window.innerWidth < 767) {
    if (document.getElementsByClassName("js-hasdescription")[0]) {
      if (document.getElementsByClassName("smart-parking-wrap")) {
        document
          .getElementsByClassName("js-hasdescription")[0]
          .appendChild(
            document.getElementsByClassName("smart-parking-wrap")[0]
          );
      }
    }
  }
}

//LoginBLock functions ends

//Offer blocks should not be clickable in Store/Restaurant detail page - in connected mode
export function unibailOffersLoad() {
  if (baseModel.isDetailPage) {
    var offerelement = document.getElementById("offers");
    if (offerelement != null && offerelement != undefined) {
      offerelement.classList.add("connected");
    }
  }
}

//Click function to scroll to the Right Question Category in FAQ Page.
export function fnFAQAnchorslClick(elementid, event) {
  event.preventDefault();

  var element = document.getElementById(elementid);
  var hrefElement = document.getElementById(elementid).href;
  setFAQHistory(hrefElement, element);
  switchTabOrScroll1();
  event.target.blur();
}

//Sets the FAQ Page history , so that scrolling will access the Id.
function setFAQHistory(thisli, thisnode) {
  thisnode.parentElement.classList.remove("active");
  var currentHref = thisli.split("#")[1];
  if (!thisli.includes("webview=1")) {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + "#" + currentHref
    );
  } else {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + "?webview=1" + "#" + currentHref
    );
  }
}

export function switchTabOrScroll1() {
  //This below block should always be at the bottom of the ready event
  var currentUrl = window.location.href;
  if (currentUrl.indexOf("#") > -1) {
    var idToScroll = currentUrl.substring(currentUrl.indexOf("#"));
    idToScroll = idToScroll.replace("#", "");
    if (idToScroll == "" || idToScroll == "#") return;
    if (idToScroll.indexOf("Tab") > -1) {
      if (!currentUrl.includes("webview=1")) {
        idToScroll = currentUrl.split("=")[1];
      } else {
        idToScroll = currentUrl.split("Tab=")[1];
      }
      //Top Tab Desktop
      if (!!idToScroll) {
        //if value exists next to = do the below
        var topTabClear = document.getElementById("Tabbing");
        if (!!topTabClear) {
          //If the element exist clear the active
          //Clear the Active class for all elements.
          var ul = document.getElementById("Tabbing");

          var lis = ul.getElementsByTagName("li");
          for (var i = 0; i < lis.length; i++) {
            //remove active from the top Tab
            lis[i].classList.remove("active");
            lis[i].removeAttribute("style");
            //clear active from its respective table
            var hrefelement = lis[i].getElementsByClassName("genericTabs");
            hrefelement = hrefelement[0].getAttribute("href");
            var itemsTab = document.getElementById(hrefelement.split("#")[1]);
            itemsTab.classList.remove("active");
          }
        }
      }

      //Top Tab Mobile

      //First//clear all top menu mobile
      if (!!idToScroll) {
        //if value exists next to = do the below
        var topTabClear = document.getElementById("TabbingMob");
        if (!!topTabClear) {
          //If the element exist clear the active
          //Clear the Active class for all elements.
          var ul = document.getElementById("TabbingMob");

          var lis = ul.getElementsByTagName("li");
          for (i = 0; i < lis.length; i++) {
            //remove active from the top Tab
            lis[i].classList.remove("active");
            //clear active from its respective table
            var hrefelement = lis[i].getElementsByClassName("genericTabs");
            hrefelement = hrefelement[0].getAttribute("href");
            var itemsTab = document.getElementById(hrefelement.split("#")[1]);
            itemsTab.classList.remove("active");
          }
        }
      }

      //second//set the top menu mobile
      if (!!idToScroll) {
        //if value exists next to = do the below
        var topTabClearMob = document.getElementById("TabbingMob");
        if (!!topTabClear) {
          //If the element exist clear the active
          //set the Active class for the top menu of Mobile .
          var lis = topTabClearMob.getElementsByTagName("li");
          for (i = 0; i < lis.length; i++) {
            //get the Li and check if it has the respective href
            var hrefelement = lis[i].getElementsByClassName("genericTabs");
            var hreftext = hrefelement[0].getAttribute("href");
            if (hreftext.split("#")[1] == idToScroll) {
              hrefelement[0].parentElement.classList.add("active");
              document.getElementById("selected-bookmark").textContent =
                hrefelement[0].textContent;
              var Span = document.createElement("span");
              Span.classList.add("caret");
              document.getElementById("selected-bookmark").appendChild(Span);
            }
          }
        }
      }

      //then Set Active for the Element in URL.
      var topTabClear = document.getElementById("Tabbing");
      if (!!topTabClear) {
        //If the element exist set active to idToScroll
        //set the Active class for the top menu.
        var ul = document.getElementById("Tabbing");
        var lis = ul.getElementsByTagName("li");
        for (i = 0; i < lis.length; i++) {
          //get the Li and check if it has the respective href
          var hrefelement = lis[i].getElementsByClassName("genericTabs");
          var hreftext = hrefelement[0].getAttribute("href");
          var itemsTab = document.getElementById(hreftext.split("#")[1]);
          if (hreftext.split("#")[1] == idToScroll) {
            itemsTab.classList.add("active");
            hrefelement[0].classList.add("active");
            if (!document.getElementById("clubShopsTab")) {
              hrefelement[0].setAttribute(
                "style",
                "border-bottom: 1px solid #393939"
              );
            }
            //set active from its respective second table
            var secondTab = document.getElementById(idToScroll);
            secondTab.classList.add("active");
          }
        }
      }
      if (idToScroll != "" && (idToScroll != "#") != 0) {
        if (window.location.href.indexOf("webview") >= 0) {
          var querystring = getUrlQuerystringVars();
          if (querystring.webview == 1) {
            if (
              document.getElementById("cookieWidgetID").style.display == "block"
            ) {

              window.scrollTo({
                top: document.getElementsByClassName("navme")[0].offsetTop - 55,
                behavior: "smooth",
              });
            } else {

              window.scrollTo({
                top: document.getElementsByClassName("navme")[0].offsetTop - 30,
                behavior: "smooth",
              });
            }
          }
        } else {
          if (
            document.getElementById("MainHeader").offsetWidth <= 0 &&
            document.getElementById("MainHeader").offsetHeight <= 0
          ) {
            var heightHeader =
              document.getElementById("headerBlock").offsetHeight;
          } else {
            var heightHeader =
              document.getElementById("headerBlock").offsetHeight +
              document.getElementById("MainHeader").offsetHeight;
          }
          if (
            document.getElementById("cookieWidgetID")?.style.display == "block"
          ) {
            window.scrollTo({
              top:
                document.getElementsByClassName("navme")[0].offsetTop -
                heightHeader -
                55,
              behavior: "smooth",
            });
          } else {

            window.scrollTo({
              top:
                document.getElementsByClassName("navme")[0].offsetTop -
                heightHeader -
                30,
              behavior: "smooth",
            });
          }

        }
      }
    } else if (idToScroll.indexOf("Login") > -1) {
      idToScroll = currentUrl.split("=")[1];
      if (idToScroll.indexOf("changed") > -1) {
      }
      setDefaultTabs();
    } else {
      var scrollLength = 0;
      var page;
      var speed;
      if (window.location.href.indexOf("webview") >= 0) {
        var querystring = getUrlQuerystringVars();
        if (querystring.webview == 1) {
          setDefaultTabs();
          if (
            document.getElementById("cookieWidgetID")?.style.display == "block"
          ) {
            if (idToScroll == "#offers") {
              return (
                (page = idToScroll),
                (speed = 750),
                window.scrollTo(
                  {
                    top: page.offsetTop,
                    behavior: "smooth",
                  },
                  750
                ),
                !1
              );
            } else {
              scrollLength = document.getElementById(idToScroll).offsetTop - 55;
              return (
                (page = idToScroll),
                (speed = 750),
                window.scrollTo(
                  {
                    top: scrollLength,
                    behavior: "smooth",
                  },
                  750
                ),
                !1
              );
            }
          } else {
            scrollLength = document.getElementById(idToScroll).offsetTop - 30;
            return (
              (page = idToScroll),
              (speed = 750),
              window.scrollTo(
                {
                  top: scrollLength,
                  behavior: "smooth",
                },
                750
              ),
              !1
            );
          }

        }
      } else {
        if (baseModel.currentPageItemName == "access") {
          idToScroll = document.getElementById(idToScroll)
            ? idToScroll
            : "#accessinfo";
        }
        if (document.getElementById("MainHeader") && document.getElementById("headerBlock")) {
          if (
            document.getElementById("MainHeader").offsetWidth <= 0 &&
            document.getElementById("MainHeader").offsetHeight <= 0
          ) {
            var heightHeader =
              document.getElementById("headerBlock").offsetHeight;
          } else {
            var heightHeader =
              document.getElementById("headerBlock").offsetHeight +
              document.getElementById("MainHeader").offsetHeight;
          }
        }
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
        setDefaultTabs();
        if (
          document.getElementById("cookieWidgetID")?.style.display == "block"
        ) {
          if (idToScroll == "offers") {
            if (document.getElementById(idToScroll)) {
              scrollLength =
                document.getElementById(idToScroll).offsetTop - heightHeader;
              window.scrollTo({ top: scrollLength, behavior: "smooth" });
            }
          } else {
            if (document.querySelector(idToScroll)) {
              scrollLength =
                document.getElementById(idToScroll).offsetTop -
                heightHeader -
                55;
              window.scrollTo({ top: scrollLength, behavior: "smooth" });
            }
          }
        } else {
          if (baseModel.currentPageItemName != "access") {
            if (document.getElementById(idToScroll)) {
              scrollLength =
                document.getElementById(idToScroll).offsetTop -
                heightHeader -
                30;
              window.scrollTo({ top: scrollLength, behavior: "smooth" });
            }
          }
        }

      }
    }
  } else {
    if (currentUrl.indexOf("SuccessMessage") > -1) {
      if (
        baseModel.successmessages !== undefined &&
        baseModel.successmessages !== null
      ) {
        var splitMessageFromCode = baseModel.successmessages.split(" ");
        for (var x = 0; x < splitMessageFromCode.length; x++) {
          if (currentUrl.indexOf(splitMessageFromCode[x]) > -1) {
            setDefaultTabs();
            break;
          }
        }
      }
    }
    setDefaultTabs();
  }
}

export function FAQIconClick() {
  var acc = document.getElementsByClassName("accordion");
  var i;

  document.querySelectorAll(".card").forEach((item) => {
    item.addEventListener("show.bs.collapse", function (e) {
      var prev = e.target.previousElementSibling;
      var panel = e.currentTarget.nextElementSibling;
      if (panel) {
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        }
      }
      if (prev.className == "card-header") {
        prev.querySelector("a").classList.add("active");
        prev.querySelector(".icone-more").innerHTML = "-";
        prev.querySelector("a").blur();
      }
    });
  });
  document.querySelectorAll(".card").forEach((item) => {
    item.addEventListener("hide.bs.collapse", function (e) {
      var prev = e.target.previousElementSibling;
      if (prev.className == "card-header") {
        prev.querySelector("a").classList.remove("active");
        prev.querySelector(".icone-more").innerHTML = "+";
        prev.querySelector("a").blur();
      }
    });
  });

  const accordionBtns = document.querySelectorAll(".accordion");
  var active = null;
  accordionBtns.forEach((accordion) => {
    accordion.onclick = function (e) {
      let content = e.currentTarget.nextElementSibling;

      if (active === null) {
      } else {
        active.style.maxHeight = null;
      }

      if (content !== active) {
        content.style.maxHeight = content.scrollHeight + "px";
        active = content;
      } else {
        active = null;
      }
    };
  });
}

export function JobOffersIconClick() {

  document.querySelectorAll(".panel-job").forEach((item) => {
    item.addEventListener("show.bs.collapse", function (e) {
      var prev = e.target.previousElementSibling;
      var panel = e.currentTarget.nextElementSibling;
      if (prev.className == "panel-heading panel-heading-parents analytics_jobOffersplusclick") {
        prev.querySelector(".icone-more").innerHTML = "-";
      }
    });
  });
  document.querySelectorAll(".panel-job").forEach((item) => {
    item.addEventListener("hide.bs.collapse", function (e) {
      var prev = e.target.previousElementSibling;
      if (prev.className == "panel-heading panel-heading-parents analytics_jobOffersplusclick") {
        prev.querySelector(".icone-more").innerHTML = "+";
      }
    });
  });


}

//Update a new value for a specified attribute
function fnUpdateDataAttrValue(element, dataAttributeName, dataAttributeValue) {
  var attributeValue = element.getAttribute(dataAttributeName);

  if (attributeValue != undefined && attributeValue != "") {
    element.setAttribute(dataAttributeName, dataAttributeValue);
  }
}



//Game Page related scripts
export function gameFormValidate() {
  window.customerGameEmail = document.getElementById("customerGameEmail").value;
  document.getElementById("emailCheck-error").style.display = "none";
  var atpos = window.customerGameEmail.indexOf("@");
  var dotpos = window.customerGameEmail.lastIndexOf(".");
  if (window.customerGameEmail != "" && (atpos == -1 || dotpos == -1)) {
    displayInputErrorMessage(
      "customerGameEmail",
      "error",
      "data-validatormsg2",
      "emailCheck-error"
    );
  } else if (
    atpos < 1 ||
    dotpos < atpos + 2 ||
    dotpos + 2 >= window.customerGameEmail.length
  ) {
    displayInputErrorMessage(
      "customerGameEmail",
      "error",
      "data-validatormsg1",
      "emailCheck-error"
    );
  }
  document.getElementById("requiredOptinCheck-error").innerHTML = "";
  var isContestGame = document.getElementById("isContestGame").value;
  if (
    !(
      document.querySelector("input[name=game-rules-checkbox]") &&
      document.querySelector("input[name=game-rules-checkbox]").checked == true
    ) &&
    isContestGame &&
    isContestGame.toLowerCase() == "true"
  ) {
    document.getElementById("requiredOptinCheck-error").innerHTML = document
      .getElementById("requiredOptinCheck-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("requiredOptinCheck-error").style.display = "block";
  }
  return true;
}

//Check the email format and display in red an error message if not
function emailGameValidate() {
  window.customerGameEmail = document.getElementById("customerGameEmail").value;
  document.getElementById("emailCheck-error").style.display = "none";
  var atpos = window.customerGameEmail.indexOf("@");
  var dotpos = window.customerGameEmail.lastIndexOf(".");
  if (window.customerGameEmail != "" && (atpos == -1 || dotpos == -1)) {
    displayInputErrorMessage(
      "customerGameEmail",
      "error",
      "data-validatormsg2",
      "emailCheck-error"
    );
    return false;
  } else if (
    atpos < 1 ||
    dotpos < atpos + 2 ||
    dotpos + 2 >= window.customerGameEmail.length
  ) {
    displayInputErrorMessage(
      "customerGameEmail",
      "error",
      "data-validatormsg1",
      "emailCheck-error"
    );
    return false;
  }
  return true;
}

//Check if the optin is checked and display in red an error message if not
function optinGameValidate() {
  document.getElementById("requiredOptinCheck-error").innerHTML = "";
  var isContestGame = document.getElementById("isContestGame").value;
  if (
    !(
      document.querySelector("input[name=game-rules-checkbox]") &&
      document.querySelector("input[name=game-rules-checkbox]").checked == true
    ) &&
    isContestGame &&
    isContestGame.toLowerCase() == "true"
  ) {
    document.getElementById("requiredOptinCheck-error").innerHTML = document
      .getElementById("requiredOptinCheck-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("requiredOptinCheck-error").style.display = "block";
    return false;
  }
  return true;
}

function prefillEmailOnGamePage() {
  if (window.location.href.indexOf(baseModel.gamePage) >= 0) {
    if (window.location.href.indexOf("?id=") >= 0 || window.location.href.indexOf("&id=") >= 0) {
      if (window.customerGameEmail) {
        var isLogged = readCookie("UUID") != undefined && readCookie("UUID") != "" ? true : false;
        if (!isLogged) {
          document.getElementById("customerGameEmail").value = window.customerGameEmail;
        }
      }
    } else if (window.location.href.indexOf("?SuccessMessage=") >= 0) {
      if (window.userEmail) {
        document.getElementById("customerGameEmail").value = window.userEmail;
        document.getElementById("customerGameEmail").readOnly = true;
      }
    } else if (window.userEmail) {
      document.getElementById("customerGameEmail").value = window.userEmail;
      document.getElementById("customerGameEmail").readOnly = true;
      var isLogged = readCookie("UUID") != undefined && readCookie("UUID") != "" ? true : false;
      if (isLogged) {
        var isContestGame = document.getElementById("isContestGame").value;
        if (isContestGame.toLowerCase() == "false") {
          document.getElementsByClassName("preloader")[0].classList.remove("d-none");
          document.getElementsByClassName("preloader")[0].classList.add("d-flex");
          checkGameEmail();
        }
      }
    }
  }
}

function CenterGameLoad() {
  if (baseModel.currentPageItemName == baseModel.gamePageItemName) {
    const state = store.getState();
    const accessToken = state.user.accessToken;
    const refreshToken = state.user.refreshToken;
    window.Segments = {
      segments: [],
      stores: [],
      accessToken: accessToken,
      refreshToken: refreshToken
    };
    getCheckedBrand();
    getCheckedCategory();
    increaseButton();
    activateDesktopSegmentHoverSystem();
    showMoreGameRules();
  }
}

export function checkGameEmail(isWifit, isWelcomeJourney, qsVisitorid, qsEmailId) {
  const state = store.getState();
  const visitorId = state.user.visitorId;
  const accessToken = state.user.accessToken;
  const refreshToken = state.user.refreshToken;
  var form = document.querySelector("#form-game");
  var serializedForm = new FormData(form);
  serializedForm.append(antiForgeryToken.name, antiForgeryToken.value);
  serializedForm.append("accessToken", accessToken);
  serializedForm.append("refreshToken", refreshToken);
  axios({
    url:
      "/api/sitecore/GameApi/CheckUserEmail?ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang +
      "&isWifit=" +
      isWifit +
      "&isWelcomeJourney=" +
      isWelcomeJourney +
      "&qsVisitorid=" +
      qsVisitorid +
      "&qsEmailId=" +
      qsEmailId +
      "&visitorId="
      + visitorId,
    method: "POST",
    data: serializedForm,
    dataType: "json",
    async: false,
  })
    .then(function (response) {
      if (response.data[1] == "Check Email and optin Successful") {
        window.currentPageId = MoveToNextPage(window.currentPageId, false);
        var emailInput = document.getElementById("customerGameEmail");
        emailInput.classList.remove("error");
        document.getElementById("game-registernow").style.display = "none";
      } else if (
        response.data[1] == "Check Email Failed" &&
        emailGameValidate()
      ) {
        var registernowText = document.getElementById("game-registernow");
        displayInputErrorMessage(
          "customerGameEmail",
          "error",
          "data-validatormsg3",
          "emailCheck-error"
        ).appendChild(registernowText);
        document.getElementById("game-registernow").style.display =
          "inline-block";
      } else if (
        response.data[1] == "Check Email success and checkOptin failed" &&
        emailGameValidate()
      ) {
        var isContestGame = document.getElementById("isContestGame").value;
        if (isContestGame && isContestGame.toLowerCase() == "false")
          window.currentPageId = MoveToNextPage(window.currentPageId, false);
        var emailInput = document.getElementById("customerGameEmail");
        emailInput.classList.remove("error");
        document.getElementById("game-registernow").style.display = "none";
      } else if (
        response.data[1] == "No response from the API" ||
        response.data[1] == "Something went wrong, sorry"
      ) {
        genericTechnicalErrorPopin();
      }
      document
        .getElementsByClassName("preloader")[0]
        .classList.remove("d-flex");
      document.getElementsByClassName("preloader")[0].classList.add("d-none");
    })
    .catch(function (error) {
      console.log(error);
    });
}

function sendSegments(moveToNextPage) {
  moveToNextPage = typeof moveToNextPage !== 'undefined' ? moveToNextPage : true;
  var data = JSON.stringify(window.Segments);

  const res = axios
    .post(
      '/api/sitecore/GameApi/SendSegments?ursite=' + baseModel.urContextSite + '&urlang=' + baseModel.contextLang,
      data,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      if (response.data[1] == "success") {
        //Api call success
        if (moveToNextPage == true) {
          window.currentPageId = MoveToNextPage(window.currentPageId, false);
        }
      } else {
        //Popin Api Error
        genericTechnicalErrorPopin();
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function sendStores(moveToNextPage) {

  moveToNextPage = typeof moveToNextPage !== 'undefined' ? moveToNextPage : true;
  var data = JSON.stringify(window.Segments);
  const res = axios
    .post(
      '/api/sitecore/GameApi/SendStores?ursite=' + baseModel.urContextSite + '&urlang=' + baseModel.contextLang,
      data,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      if (response.data[1] == "success") {
        //Api call success
        if (moveToNextPage == true) {
          window.currentPageId = MoveToNextPage(window.currentPageId, false);
        }
      } else {
        //Popin Api Error
        genericTechnicalErrorPopin();
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function getStores() {
  var data = JSON.stringify(window.Segments);
  const res = axios
    .post(
      "/api/sitecore/GameApi/GetAssociatedStores?ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang,
      data,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      {
        createRoot(document.getElementById("storesSelectionPage"))
          .render(<GameStoresPageInner model={response.data} />);

        window.currentPageId = MoveToNextPage(window.currentPageId, false);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function MoveToNextPage(
  currentPageId,
  skipStoresPage,
  currentStorePage
) {
  if (typeof currentStorePage === "undefined") {
    currentStorePage = "default";
  }
  switch (currentPageId) {
    case "welcomePage":
      if (emailGameValidate() && optinGameValidate()) {
        document.querySelector(".lbe.home").setAttribute("hidden", true);
        document.getElementsByClassName("page-question")[0].removeAttribute("hidden");
        document.getElementById("gameHeader").classList.remove("d-none");
        // class page-active for the color
        document.getElementsByClassName("page-question")[0].classList.add("page1--active");
        document.getElementsByClassName("page-question")[0].classList.remove("page2--active");
        document.getElementsByClassName("page-question")[0].classList.remove("page3--active");
        document.getElementsByClassName("container-question")[0].classList.add("page1--active");
        document.getElementsByClassName("container-question")[0].classList.remove("page2--active");
        document.getElementsByClassName("container-question")[0].classList.remove("page3--active");

        document.getElementById("segmentsSelectionPage1").style.display = "block";
        document.getElementById("segmentsSelectionPage2").style.display = "none";
        document.getElementById("IncreaseChancePage").style.display = "none";
        document.getElementById("storesSelectionPage").style.display = "none";
        document.getElementById("finalPage").style.display = "none";
        window.currentPageId = "segmentsSelectionPage1";
        scrollTopFunction();
      }
      break;
    case "segmentsSelectionPage1":
      document.getElementById("progressbar").style.width = "66%";
      // class page-active for the color
      document.getElementsByClassName("page-question")[0].classList.add("page2--active");
      document.getElementsByClassName("page-question")[0].classList.remove("page1--active");
      document.getElementsByClassName("page-question")[0].classList.remove("page3--active");
      document.getElementsByClassName("container-question")[0].classList.add("page2--active");
      document.getElementsByClassName("container-question")[0].classList.remove("page1--active");
      document.getElementsByClassName("container-question")[0].classList.remove("page3--active");

      document.getElementById("segmentsSelectionPage1").style.display = "none";
      document.getElementById("segmentsSelectionPage2").style.display = "block";
      document.getElementById("IncreaseChancePage").style.display = "none";
      document.getElementById("storesSelectionPage").style.display = "none";
      document.getElementById("finalPage").style.display = "none";
      window.currentPageId = "segmentsSelectionPage2";
      scrollTopFunction();
      break;
    case "segmentsSelectionPage2":
      document.getElementById("progressbar").style.width = "100%";
      document.getElementById("icongift").classList.add("active");
      document.getElementsByClassName("page-question")[0].setAttribute("hidden", true);
      document.getElementById("IncreaseChancePage").style.display = "block";
      document.getElementById("storesSelectionPage").style.display = "none";
      document.getElementById("finalPage").style.display = "none";
      window.currentPageId = "IncreaseChancePage";
      scrollTopFunction();
      break;
    case "IncreaseChancePage":
      if (skipStoresPage) {
        document.getElementById("gameHeader").classList.add("d-none");
        document.getElementsByClassName("page-question")[0].setAttribute("hidden", true);
        document.getElementById("IncreaseChancePage").style.display = "none";
        document.getElementById("storesSelectionPage").style.display = "none";
        document.getElementById("finalPage").style.display = "block";
        window.currentPageId = "finalPage";
        scrollTopFunction();
      } else {
        document.getElementById("gameHeader").classList.add("d-none");
        document.getElementsByClassName("page-question")[0].setAttribute("hidden", true);
        document.getElementById("IncreaseChancePage").style.display = "none";
        document.getElementById("storesSelectionPage").style.display = "block";
        document.getElementById("finalPage").style.display = "none";
        window.currentPageId = "storesSelectionPage";
        scrollTopFunction();
      }
      break;
    case "storesSelectionPage":
      document.getElementsByClassName("page-question")[0].setAttribute("hidden", true);
      document.getElementById("IncreaseChancePage").style.display = "none";
      document.getElementById("storesSelectionPage").style.display = "none";
      document.getElementById("finalPage").style.display = "block";
      window.currentPageId = "finalPage";
      scrollTopFunction();
      break;
    default:
      break;
  }
  return window.currentPageId;
}

function scrollTopFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export function hideAllStores() {
  if (document.getElementsByClassName("marques-liste")[0]) {
    var storeListContainer =
      document.getElementsByClassName("marques-liste")[0].children;
    if (storeListContainer != null) {
      //This will hide all matching elements
      for (var i = 0; i < storeListContainer.length; i++) {
        var a = storeListContainer[i];
        a.style.display = "none";
      }
    }
  }
}

function getCheckedCategory() {
  if (document.getElementsByClassName("page-question")[0]) {
    document.querySelectorAll(".categorie-liste__cta").forEach((item) => {
      item.addEventListener("click", function (e) {
        // Add .marques-liste__cta--active class
        if (e.currentTarget.classList.contains("categorie-liste__cta--active"))
          e.currentTarget.classList.remove("categorie-liste__cta--active");
        else e.currentTarget.classList.add("categorie-liste__cta--active");

        //Check if at least one brand has been checked on question page 1
        if (window.currentPageId == "segmentsSelectionPage2" || window.currentPageId == "segmentsSelectionPage1") {
          let lastBtn = document.querySelectorAll(".btn-next").length - 1;
          if (document.getElementsByClassName("categorie-liste__cta--active")[0]) {
            document.querySelectorAll(".btn-next")[lastBtn].removeAttribute("disabled");
          } else {
            document.querySelectorAll(".btn-next")[lastBtn].setAttribute("disabled", true);
          }
        }
      });
    });

    if (document.getElementsByClassName("btn-next")[0]) {
      document.querySelectorAll(".btn-next").forEach((item) => {
        item.addEventListener("click", function (e) {
          var elementExist = false;
          // Add category
          if (elementExist === false) {
            document.querySelectorAll(".categorie-liste__cta--active").forEach((x) => {
              for (var segmentindex in window.Segments.segments) {
                if (
                  window.Segments.segments[segmentindex].id ===
                  x.getAttribute("data-category-id") ||
                  window.Segments.segments[segmentindex] === "" ||
                  window.Segments.segments[segmentindex] === null
                ) {
                  return;
                }
              }

              if (elementExist === false) {
                window.Segments.segments.push({
                  id: x.getAttribute("data-category-id"),
                  name: x.getAttribute("data-category-name"),
                });
              }
              if (window.Segments.segments.length == 0) {
                window.Segments.segments.push({
                  id: x.getAttribute("data-category-id"),
                  name: x.getAttribute("data-category-name"),
                });
              }
            });
          }
          if (window.currentPageId === "segmentsSelectionPage2") {
            sendSegments();
          } else {
            window.currentPageId = MoveToNextPage(window.currentPageId, false);
          }
        });
      });
    }
  }
}

// Brand page : get checked brand
export function getCheckedBrand() {
  if (document.getElementsByClassName("page-marques")[0]) {
    document.querySelectorAll(".marques-liste__cta").forEach((item) => {
      item.addEventListener("click", function (e) {
        // Add .marques-liste__cta--active class
        if (e.currentTarget.classList.contains("marques-liste__cta--active"))
          e.currentTarget.classList.remove("marques-liste__cta--active");
        else e.currentTarget.classList.add("marques-liste__cta--active");
      });
    });

    if (document.getElementsByClassName("btn-valide")[0]) {
      document.querySelectorAll(".btn-valide").forEach((item) => {
        item.addEventListener("click", function (e) {
          var elementExist = false;
          // Add category
          if (elementExist === false) {
            document.querySelectorAll(".marques-liste__cta--active").forEach((x) => {
              for (var storeIndex in window.Segments.stores) {
                if (
                  window.Segments.stores[storeIndex].id ===
                  x.getAttribute("data-brand-id") ||
                  window.Segments.stores[storeIndex] === "" ||
                  window.Segments.stores[storeIndex] === null
                ) {
                  return;
                }
              }

              if (elementExist === false) {
                window.Segments.stores.push({
                  id: x.getAttribute("data-brand-id"),
                  name: x.getAttribute("data-brand-name"),
                });
              }
              if (window.Segments.stores.length == 0) {
                window.Segments.stores.push({
                  id: x.getAttribute("data-brand-id"),
                  name: x.getAttribute("data-brand-name"),
                });
              }
            });
          }
          if (window.currentStorePageClassName == getLastStorePageClass()) {
            if (window.Segments.stores.length == 0) {
              window.currentPageId = MoveToNextPage(window.currentPageId, false);
            } else {
              sendStores();
            }
          }
          else if (document.getElementsByClassName("marques-liste")[0].children.length === 0) {
            window.currentPageId = MoveToNextPage(window.currentPageId, false);
          }
          else {
            window.currentStorePageClassName = moveToNextStorePage(
              window.currentStorePageClassName
            );
          }
        });
      });
    }
  }
}

function moveToNextStorePage(currentStorePageClass) {
  if (currentStorePageClass != getLastStorePageClass()) {
    var pageNumber = parseInt(currentStorePageClass.split("-")[2], 10) + 1;
    hideAllStores();
    if (document.getElementsByClassName("marques-liste-" + pageNumber)[0]) { document.getElementsByClassName("marques-liste-" + pageNumber)[0].style.display = "block"; }
    return "marques-liste-" + pageNumber;
  }
}

function getLastStorePageClass() {
  var storeListContainer = document.getElementsByClassName("marques-liste")[0];
  var pageIndex = storeListContainer.children.length - 1;
  var lastStorePageClassName = storeListContainer.children[pageIndex]?.className;
  return lastStorePageClassName;
}

function increaseButton() {
  if (document.getElementsByClassName("btn-increase")[0]) {
    document
      .getElementsByClassName("btn-increase")[0]
      .addEventListener("click", function () {
        getStores();
      });
  }
}

function showMoreGameRules() {
  if (document.getElementsByClassName("game-read-more-trigger")[0]) {
    document
      .getElementsByClassName("game-read-more-trigger")[0]
      .addEventListener("click", function () {
        document.getElementsByClassName(
          "game-read-more-trigger"
        )[0].style.display = "none";
        document.getElementsByClassName(
          "game-read-less-trigger"
        )[0].style.display = "block";

        document.getElementsByClassName(
          "gamerules-extract-displayed"
        )[0].style.display = "none";
        document.getElementsByClassName(
          "gamerules-extract-to-hide"
        )[0].style.display = "inline-block";
      });
  }
  if (document.getElementsByClassName("game-read-less-trigger")[0]) {
    document
      .getElementsByClassName("game-read-less-trigger")[0]
      .addEventListener("click", function () {
        document.getElementsByClassName(
          "game-read-less-trigger"
        )[0].style.display = "none";
        document.getElementsByClassName(
          "game-read-more-trigger"
        )[0].style.display = "block";

        document.getElementsByClassName(
          "gamerules-extract-to-hide"
        )[0].style.display = "none";
        document.getElementsByClassName(
          "gamerules-extract-displayed"
        )[0].style.display = "inline-block";
      });
  }
}

// Activate the hover function only when it is needed (on desktop)
export function activateDesktopSegmentHoverSystem() {
  var windowWidth = window.innerWidth;
  if (document.getElementsByClassName("page-question")[0]) {
    if (windowWidth < 1025) {
      document.querySelectorAll(".categorie-liste__cta").forEach((x) => {
        x.classList.remove("categorie-liste__cta-hover");
      });
    } else {
      document.querySelectorAll(".categorie-liste__cta").forEach((x) => {
        x.classList.add("categorie-liste__cta-hover");
      });
    }
  }

  if (document.getElementsByClassName("page-marques")[0]) {
    if (windowWidth < 1025) {
      document.querySelectorAll(".marques-liste__cta").forEach((x) => {
        x.classList.remove("marque-liste__cta-hover");
      });
    } else {
      document.querySelectorAll(".marques-liste__cta").forEach((x) => {
        x.classList.add("marque-liste__cta-hover");
      });
    }
  }
}

function displayInputErrorMessage(
  InputId,
  errorClassValue,
  errorMessageToDisplay,
  errorElementToDisplay
) {
  var errorElement = document.getElementById(errorElementToDisplay);
  errorElement.innerHTML = errorElement
    .getAttribute(errorMessageToDisplay)
    .valueOf();
  errorElement.style.display = "block";
  addClass(document.getElementById(InputId), errorClassValue);
  return errorElement;
}

function addClass(element, classname) {
  var arr;
  arr = element.className.split(" ");
  if (arr.indexOf(classname) == -1) {
    element.className += " " + classname;
  }
}

//functions that needs to be called on document.ready()
export function documentReadyLoad() {

  //Functions to be called on pageload
  if (typeof window !== "undefined") {
    window.addEventListener("resize", cookiesizing);
  }
  carouselLoad();
  CenterGameLoad();
  prefillEmailOnGamePage();

  var isRetailerPage =
    baseModel.storePageName != undefined &&
      baseModel.currentPageItemName != null &&
      (baseModel.currentPageDisplayName.toLowerCase() ===
        baseModel.storePageName ||
        baseModel.currentPageDisplayName.toLowerCase() ==
        baseModel.restaurantPageName)
      ? true
      : false;

  //Retailer Page, General Link click navigation values.
  //This portion updates the existing click postion value, by updating the store name if its a reatailer page
  if (isRetailerPage) {
    // For the news  component update the Retailer name in the attribute
    document
      .querySelectorAll(".whats-hot .gtmClickNavigation")
      .forEach((item) => {
        //Bind the GTM click event for click navigations in this services component
        item.addEventListener("click", (event) => {
          fnClickNavigation(event.currentTarget);
        });
      });

    // For the offers component update the Retailer name in the attribute
    document.querySelectorAll(".offers .gtmClickNavigation").forEach((item) => {
      //Bind the GTM click event for click navigations in this services component
      item.addEventListener("click", (event) => {
        fnClickNavigation(event.currentTarget);
      });
    });

    // For the map component update the Retailer name in the attribute
    document
      .querySelectorAll("#mapofthemall .gtmClickNavigation")
      .forEach((item) => {
        //Bind the GTM click event for click navigations in this services component
        item.addEventListener("click", (event) => {
          fnClickNavigation(event.currentTarget);
        });
      });
    //Tagging for the Parent category link in Retailer page top
    document
      .querySelectorAll(".categories .gtmClickNavigation")
      .forEach((item) => {
        //Bind the GTM click event for click navigations in this services component
        item.addEventListener("click", (event) => {
          fnClickNavigation(event.currentTarget);
        });
      });
  }
}



export function getVideoTypeFromvideoUrl(event, videoUrl) {
  var url;
  if (event !== null && event.currentTarget !== undefined && event.currentTarget !== null && event.currentTarget !== "") {
    url = event.currentTarget.getAttribute("aria-label");
  }
  else {
    url = videoUrl;
  }
  if (url !== null && url !== undefined && url !== "") {
    let videoUrl = url.toString();
    let videoType = "";
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = videoUrl.match(regExp);
    var vimeoRegExp = "vimeo\\.com/(?:.*#|.*/videos/)?([0-9]+)";
    var vimeoMatch = videoUrl.match(vimeoRegExp);
    if (videoUrl.includes("youtube") || match && match[7].length == 11) {
      videoType = "YouTube";
    } else if (videoUrl.includes("vimeo") || vimeoMatch) {
      videoType = "Vimeo";
    }
    else if (videoUrl.includes("/media/")) {
      videoType = "Native";
    }
    else if (videoUrl.includes("twitch")) {
      videoType = "Twitch";
    }
    else if (videoUrl.includes("soundcloud")) {
      videoType = "SoundCloud";
    }
    else if (videoUrl.includes("streamable")) {
      videoType = "Streamable";
    }
    else if (videoUrl.includes("wistia")) {
      videoType = "Wistia";
    }
    else if (videoUrl.includes("mixcloud")) {
      videoType = "Mixcloud";
    }
    else if (videoUrl.includes("dailymotion")) {
      videoType = "DailyMotion";
    }
    else if (videoUrl.includes("kaltur")) {
      videoType = "Kaltur";
    }
    return videoType;

  }
}

export function countdown(fields, item, ct) {
  if (item.startsAt != "" && item.startsAt != "") {
    var startdate = item.startsAt;
    var countDownDate = new Date(startdate).getTime();

    var x = setInterval(function () {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      var clname = "counter-upcoming" + ct;
      var ctdays = "";
      var cthours = "";
      if (days > 1) { ctdays = fields.liveEvent.countdown_Days }
      else { ctdays = fields.liveEvent.countdown_Day }
      if (hours > 1) { cthours = fields.liveEvent.countdown_Hours }
      else { cthours = fields.liveEvent.countdown_Hour }
      // Display the result in the element with id="counter"
      if (typeof document !== 'undefined') {
        if (document.getElementById(clname) !== null) {
          document.getElementById(clname).innerHTML = fields.liveEvent.live_In + " : " + days + " " + ctdays + " : " + hours + " " + cthours + " : "
            + minutes + " " + fields.liveEvent.countdown_Min + " : " + seconds + " " + fields.liveEvent.countdown_Sec;

          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x);
            document.getElementById(clname).innerHTML = "";
          }
        }
      }
    }, 1000);
  }
}

export function convertUTCTimeToClientTime(date, isCarousel, isPastEvent) {
  var finalstring = "";
  var options = ""
  var startDate = new Date(date);
  var time = startDate
  var convertedTime = "";
  if (baseModel.currentLang === "en-GB") {
    convertedTime = time.toLocaleTimeString(baseModel.currentLang, {
      // en-US can be set to 'default' to use user's browser settings
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }
  else {
    convertedTime = time.toLocaleTimeString(baseModel.currentLang, {
      // en-US can be set to 'default' to use user's browser settings
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  if (isCarousel) {
    if (!isPastEvent) {
      options = { weekday: "short", year: "numeric", month: "long", day: "numeric" };
      finalstring = startDate.toLocaleDateString(baseModel.currentLang, { weekday: 'short', month: "long", day: "numeric" }).concat(" " + baseModel.eventsAt + " " + convertedTime);

    }
    else {
      options = { year: "numeric", month: "long", day: "numeric" };
      startDate.toLocaleDateString(baseModel.currentLang, options);
      finalstring = startDate.toLocaleDateString(baseModel.currentLang, { year: "numeric", month: "long", day: "numeric" });
    }
  }
  else {
    options = { year: "numeric", month: "long", day: "numeric" };
    startDate.toLocaleDateString(baseModel.currentLang, options);
    finalstring = startDate.toLocaleDateString(baseModel.currentLang, { year: "numeric", month: "long", day: "numeric" }).replace(",", " ").concat(" " + baseModel.eventsAt + " " + convertedTime);


  }
  return finalstring;
}

export function convertUTCTimeToClientTimeForBookingEvents(date) {
  var finalstring = "";
  var options = ""
  var startDate = new Date(date);
  var time = startDate
  var convertedTime = "";
  if (baseModel.currentLang === "en-GB") {
    convertedTime = time.toLocaleTimeString(baseModel.currentLang, {
      // en-US can be set to 'default' to use user's browser settings
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }
  else {
    convertedTime = time.toLocaleTimeString(baseModel.currentLang, {
      // en-US can be set to 'default' to use user's browser settings
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  options = { year: "numeric", month: "long", day: "numeric" };
  startDate.toLocaleDateString(baseModel.currentLang, options);
  finalstring = startDate.toLocaleDateString(baseModel.currentLang, { year: "numeric", month: "long", day: "numeric" })
  var year = startDate.toLocaleDateString(baseModel.currentLang, { year: "numeric" })
  var month = startDate.toLocaleDateString(baseModel.currentLang, { month: "long" })
  var datevalue = startDate.toLocaleDateString(baseModel.currentLang, { day: "numeric" })
  return {
    year: year,
    month: month,
    date: datevalue
  };
}


export function bannerToggleGlobal() {
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
  //Hide Smart App Banner
  if (document.getElementsByClassName("smartbanner")[0]) {
    if (document.getElementsByClassName("smartbanner")[0].classList.contains("d-none"))
      document.getElementsByClassName("smartbanner")[0].classList.remove('d-none');
    else
      document.getElementsByClassName("smartbanner")[0].classList.add('d-none');
  }

  if (!isMobileView) {
    if (document.getElementsByClassName("navbar-toggler") && document.getElementsByClassName("navbar-toggler")[0].classList.contains("icon-hamburger")) {
      document.body.classList.add("nav-on");
    }
    else {
      document.body.classList.remove("nav-on");
    }
  }
}

export function changeBurgerMenuIconForCenter() {
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
  var iconName = "icon-hamburger";
  var SmartBannerHeight = document.getElementsByClassName("smartbanner")[0] !== undefined && document.getElementsByClassName("smartbanner")[0] !== null ?
    document.getElementsByClassName("smartbanner")[0].offsetHeight : 0;

  var headerBlockheight = document.getElementById("navbarHeader")
    ? document.getElementById("navbarHeader").offsetHeight
    : 0;
  if (isMobileView) {
    if (document.getElementById("navbar"))
      // document.getElementById("navbar").style.top =
      //   headerBlockheight + "px";
      if (document.getElementById("navbar") && document.getElementById("navbar").classList.contains('show')) {
        // document.getElementsByClassName("navbar-toggler")[0].classList.remove(iconName)
        // document.getElementsByClassName("navbar-toggler")[0].classList.add("icon-cross")

        // var element = document.getElementById("NoticeBlock");
        // if (element) {
        //   element.classList.add("d-none");
        //   document.body.classList.remove("emergency-notice-show");
        // }
        //Hide Smart App Banner
        // if (document.getElementsByClassName("smartbanner")[0])
        //   document.getElementsByClassName("smartbanner")[0].classList.add('d-none');

        var preheaderEle = document.getElementById("headerBlock");
        if (preheaderEle !== null && preheaderEle !== "" && !preheaderEle.classList.contains("preheader-frontindex")) {
          document.getElementById("headerBlock").classList.add("preheader-frontindex");
        }

      }
      else {
        // document.getElementsByClassName("navbar-toggler")[0].classList.remove("icon-cross")
        // document.getElementsByClassName("navbar-toggler")[0].classList.add(iconName)

        // var element1 = document.getElementById("NoticeBlock");
        // if (element1 && !element1.getAttribute("data-bannerCookie")) {
        //   element1.classList.remove("d-none");
        //   document.body.classList.add("emergency-notice-show");
        // }
        //show Smart App Banner
        // if (document.getElementsByClassName("smartbanner")[0])
        //   document.getElementsByClassName("smartbanner")[0].classList.remove('d-none');

        var preheaderEle = document.getElementById("headerBlock");
        if (preheaderEle !== null && preheaderEle !== "" && preheaderEle.classList.contains("preheader-frontindex")) {
          document.getElementById("headerBlock").classList.remove("preheader-frontindex");
        }

      }
  }
  else {
    // if (document.getElementsByTagName("body")[0].classList.contains("on-search")) {
    //   document.getElementById("mainSearchIcon").click();
    // }
    if (document.getElementById("navbar") && document.getElementById("navbar").classList.contains('show')) {
      //document.body.classList.add("nav-on");
      var preheaderEle = document.getElementById("headerBlock");
      if (preheaderEle !== null && preheaderEle !== "" && !preheaderEle.classList.contains("preheader-frontindex")) {
        document.getElementById("headerBlock").classList.add("preheader-frontindex");
      }
    }
    else {
      document.body.classList.remove("nav-on");
    }
  }
}

export function fnDiscoverAnotherCountryPopin() {
  axios({
    method: "post",
    url:
      "/api/sitecore/PopinApi/DiscoverAnotherCountryOrCenter?" +
      "ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang + "&level=Global",
    async: false,
  })
    .then(function (response) {
      {
        var popinCountrySelectorHtml = document.getElementById("popinCountrySelector").innerHTML;
        if (popinCountrySelectorHtml.trim() === "") {
          createRoot(document.getElementById("popinCountrySelector")).render(
            <CountrySelectorPopin model={response.data} />
          );
        }
        else {
          var ele = document.getElementById("countrySelection");
          if (ele !== null && ele !== undefined) {
            if (document.getElementById("shoppingCenterSelection")) {
              //close the existing modal pop up
              var popinClick = document.getElementById("popinCenterSelectorClick");
              popinClick.click();
            }
            var popinClick = document.getElementById("popinCountrySelectorClick");
            popinClick.click();
          }
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function fnDiscoverAnotherCenterPopin(level) {
  axios({
    method: "post",
    url:
      "/api/sitecore/PopinApi/DiscoverAnotherCountryOrCenter?" +
      "ursite=" +
      baseModel.urContextSite +
      "&urlang=" +
      baseModel.contextLang + "&level=" + level,
    async: false,
  })
    .then(function (response) {
      {
        var popinCountrySelectorHtml = document.getElementById("popinCenterSelector").innerHTML;
        if (popinCountrySelectorHtml.trim() === "") {
          createRoot(document.getElementById("popinCenterSelector")).render(
            <CenterSelectorPopin model={response.data} />
          );
        }
        else {
          var ele = document.getElementById("shoppingCenterSelection");
          if (ele !== null && ele !== undefined) {
            var popinClick = document.getElementById("popinCenterSelectorClick");
            popinClick.click();
          }
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function backToCenterPopin() {
  if (document.getElementById("shoppingCenterSelection")) {
    var popinClick = document.getElementById("popinCenterSelectorClick");
    popinClick.click();
  }
}

export function enableAnnouncementBanner() {
  var element1 = document.getElementById("NoticeBlock");
  var headerblock = document.getElementById("headerBlock");
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
  if (isMobileView && element1 && !element1.getAttribute("data-bannerCookie") && element1.classList.contains("d-none")) {
    element1.classList.remove("d-none");
  }
  if (isMobileView && document.getElementsByClassName("smartbanner")[0].classList.contains("d-none")) { document.getElementsByClassName("smartbanner")[0].classList.remove("d-none") }
  if (headerblock && headerblock.classList.contains("preheader-frontindex")) {
    headerblock.classList.remove("preheader-frontindex")
  }
}

export function closeMenu() {
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
  var menu = document.getElementById('navbar');
  if (menu !== null) {
    menu.classList.remove('show');
    document.body.classList.remove("nav-on");
  }

  // if (isMobileView && document.getElementsByClassName("navbar-toggler")) {
  //   document.getElementsByClassName("navbar-toggler")[0].classList.remove("icon-cross");
  //   document.getElementsByClassName("navbar-toggler")[0].classList.add("icon-hamburger");
  // }
}

export function fnSearchExit() {
  var isMobileView = isMobile.Android() || isMobile.iOS() ? true : false;
  if (!isMobileView) {
    var element = document.getElementById("NoticeBlock");
    if (element) {
      element.classList.remove("d-none");
      document.body.classList.add("emergency-notice-show");
    }
  }
}

export function closeBurgerMenu() {
  // Get the menu
  var menu = document.getElementById('navbar');
  // Select required elements from the DOM
  const body = document.querySelector("body");
  // When the user clicks anywhere outside of the modal, close it
  window.addEventListener('click', function (event) {
    if (event.target === menu) {
      menu.classList.remove('show');
      document.body.classList.remove("nav-on");
      var preheaderEle = document.getElementById("headerBlock");
      if (preheaderEle !== null && preheaderEle !== "" && preheaderEle.classList.contains("preheader-frontindex")) {
        document.getElementById("headerBlock").classList.remove("preheader-frontindex");
      }
    }


  })
}

export function handlePageScrollBarOnMenuOpened() {
  //enable page scrollbar when burgermenu closes
  if (document.getElementById("burgerIcon")) {

    document.getElementById("burgerIcon").addEventListener('click', function (event) {
      if (document.getElementById("navbar") && document.getElementById("navbar").classList.contains("show")) {
        document.body.classList.add("nav-on");
      }
      else {
        // Enable scroll
        document.body.classList.remove("nav-on");
        var preheaderEle = document.getElementById("headerBlock");
        if (preheaderEle !== null && preheaderEle !== "" && preheaderEle.classList.contains("preheader-frontindex")) {
          document.getElementById("headerBlock").classList.remove("preheader-frontindex");
        }
      }
    })
  }
}

export function HeaderScripts() {
  var timeoutId;
  if (window.innerWidth > 991) {

    var menulist = document.querySelectorAll(".dropdown-login");
    if (menulist !== null && menulist !== undefined && menulist.length > 0) {
      menulist.forEach(function (divEl, ind) {

        divEl.addEventListener('mouseover', function () {
          document.querySelectorAll('.dropdown-login.hover')
            .forEach(element => {
              element.classList.remove('hover');
            });
          divEl.classList.add('hover');
          if (divEl.childNodes[1])
            divEl.childNodes[1].classList.add("d-block")
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        });
        divEl.addEventListener('mouseout', function () {
          timeoutId = setTimeout(function () {
            divEl.classList.remove('hover');
            if (divEl.childNodes[1])
              divEl.childNodes[1].classList.remove("d-block")
          }, 100);
        });
      })

    }
  }
}

export function fnRedirectToConnectLogin(url) {
  var cookieValue = readCookie("ParkingActivation");
  if (
    cookieValue !== null &&
    cookieValue !== "" && cookieValue !== window.location.href
  ) {
    createCookie("ParkingActivation", "", -1);
  }

  window.location.href = url;
}

export function fnRedirectToMyAccount(url) {
  window.location.href = url;
}

export function fnRedirectTosmartparking(url) {
  window.location.href = url;
}

export function createParkingCookie() {
  var pagePath = window.location.href;
  createCookie("ParkingActivation", pagePath, 1);
}

export function fnConnectRedirectTodetailPage() {
  var id = readCookie("UUID");
  if (baseModel.isDetailPageRedirect && (id == null || id == "")) {
    var pagePath = window.location?.href;
    createCookie("detailPage", pagePath, 0);
  }
}

export function HideBenefitsAndServicesTab() {
}

export function fnBackButtonClickFromMultiSC() {
  if (document.getElementById("benefitsServices") && document.getElementById("multipreferredsc")) {
    document.getElementById("multipreferredsc").remove();
    document.getElementById("benefitsServices").classList.remove('d-none');
    if (document.getElementById('lpdiscovermyadvantagesmultisc')) { document.getElementById('lpdiscovermyadvantagesmultisc').classList.remove('d-none'); }
    if (document.getElementById("smartparkingview"))
      document.getElementById("smartparkingview").remove();
    if (document.getElementById("loyaltydiscovermyadvantages"))
      document.getElementById("loyaltydiscovermyadvantages").remove();
    if (document.getElementById('opinionblock')) { document.getElementById('opinionblock').remove(); }


  }
}

export function sortCenterList(centers) {
  if (centers !== null && centers !== undefined && centers.length > 0) {
    centers.sort((a, b) => b.isWestfield - a.isWestfield)
  }
  return centers;
}

export function fnMallOffers() {
  if (document.getElementsByTagName("a")) {
    Array.from(document.getElementsByTagName("a")).forEach((x) => {
      var href = x.getAttribute("href");
      if (href == "" || href == null || href == "undefined") {
        x.removeAttribute("href");
      }
    });
  }
}
export function SmartAppBannerScripts() {
  if (document.getElementsByClassName("smartbanner")[0]) {
    if (baseModel.branded_center) {
      document.getElementsByClassName("smartbanner-close")[0].classList.add("icon-ico-close");
      document.getElementsByClassName("smartbanner-close")[0].classList.add('fs-1');
    }
    else {
      document.getElementsByClassName("smartbanner-close")[0].classList.add("icon-close-popin");
    }
  }
  var hasWebviewEqualsOne = checkWebViewEqualsOne();

  if (!hasWebviewEqualsOne) {
    if (typeof window !== "undefined") {

      window.UnibailSmartBannerInit(
        baseModel.androidAppId,
        baseModel.iTunesAppId,
        baseModel.iTunesAppIconUrl,
        baseModel.selectedLanguageISO,
        baseModel.centerName,
        baseModel.smartAppBannerCTA,
        baseModel.iTunesStore,
        baseModel.androidStore,
        baseModel.smartAppBannerPrice
      );
    }
  }
}
export function checkWebViewEqualsOne() {
  var hasWebviewEqualsOne = false;
  if (typeof window != "undefined") {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('webview');
    if (token === '1')
      hasWebviewEqualsOne = true;
  }
  return hasWebviewEqualsOne;
}

export function isTokenExpired() {
  const state = store.getState();
  const accessToken = state.user.accessToken;
  var tokenExpired = false;
  let decodedToken = accessToken !== '' && accessToken !== null && accessToken !== undefined ? jwt_decode(accessToken) : null;
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken?.exp * 1000 < currentDate.getTime()) {
    console.log("Token Expired.");
    tokenExpired = true;
  }
  return tokenExpired;
}

export function GetRenewedAccessToken(callback, args = [], pushArgs = true) {
  const state = store.getState();
  const visitorId = state.user.visitorId;
  const refreshToken = state.user.refreshToken;
  var tokenUrl = "/api/sitecore/VisitorApi/GetRenewedAccessToken";
  axios({
    method: "post",
    url: tokenUrl,
    data: {
      refreshToken: refreshToken,
    },
    dataType: "json",
  })
    .then(function (response) {
      if (response.data !== "") {
        store.dispatch(setAccessToken(response.data.access_token));
        store.dispatch(setRefreshToken(response.data.refresh_token));

        if (pushArgs) {
          args.push(visitorId);
          args.push(response.data.access_token);
          args.push(response.data.refresh_token);
        }

        callback.apply(null, args);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function AddFavCenterDatalayer(newCenterLable) {
  const state = store.getState();
  let favCenterLables = state.user.favCenterLables;
  if (newCenterLable !== null && newCenterLable !== undefined && newCenterLable !== "") {
    if (favCenterLables !== null && favCenterLables !== undefined && favCenterLables !== "") {
      if (!favCenterLables.includes(newCenterLable)) {
        favCenterLables = favCenterLables + "," + newCenterLable;
      }
    }
    else {
      favCenterLables = newCenterLable;
    }
    store.dispatch(setFavCenters(favCenterLables));
    gtmFuncAddCenterToFavorite(newCenterLable);
  }
}

export function RemoveFavCenterDatalayer(CenterLable) {
  const state = store.getState();
  let favCenterLables = state.user.favCenterLables;
  if (favCenterLables !== null && favCenterLables !== undefined && favCenterLables !== "" && CenterLable !== null && CenterLable !== undefined && CenterLable !== "") {
    if (favCenterLables.includes(CenterLable)) {
      let updatedCenterLables = favCenterLables.split(",").filter((x) => x !== CenterLable).join(",");
      store.dispatch(setFavCenters(updatedCenterLables));
      gtmFuncRemoveCenterToFavorite(CenterLable);
    }
  }
}

export function ReplaceFavCenterDatalayer(RemovedCenterLable, newCenterLable) {
  const state = store.getState();
  let favCenterLables = state.user.favCenterLables;

  if (favCenterLables !== null && favCenterLables !== undefined && favCenterLables !== "" && RemovedCenterLable !== null && RemovedCenterLable !== undefined && RemovedCenterLable !== "") {
    if (favCenterLables.includes(RemovedCenterLable)) {
      favCenterLables = favCenterLables.split(",").filter((x) => x !== RemovedCenterLable).join(",");
      store.dispatch(setFavCenters(favCenterLables));
    }
  }
  if (newCenterLable !== null && newCenterLable !== undefined && newCenterLable !== "") {
    if (favCenterLables !== null && favCenterLables !== undefined && favCenterLables !== "") {
      if (!favCenterLables.includes(newCenterLable)) {
        favCenterLables = favCenterLables + "," + newCenterLable;
      }
    }
    else {
      favCenterLables = newCenterLable;
    }
    store.dispatch(setFavCenters(favCenterLables));
  }
  gtmFuncRemoveCenterToFavorite(RemovedCenterLable);
  gtmFuncAddCenterToFavorite(newCenterLable);
}

export function fnGTMClickServiceOffer(serviceNameCTA) {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_service_offer',
    'page': (global.pageAndQuery !== undefined && global.pageAndQuery !== null && global.pageAndQuery !== "") ? global.pageAndQuery : undefined,
    'service_tag': baseModel.serviceTag !== null && baseModel.serviceTag !== "" && baseModel.serviceTag !== undefined ? baseModel.serviceTag : undefined,
    'service_type': baseModel.serviceType !== null && baseModel.serviceType !== "" && baseModel.serviceType !== undefined ? baseModel.serviceType : undefined,
    'service_name_cta': serviceNameCTA !== null && serviceNameCTA !== "" ? serviceNameCTA : undefined,
  });
}

export function fnGTMAddVehicle() {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_add_licence_plate',
  });
}

export function fnGTMSaveVehicle() {
  if (!global.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'click_save_licence_plate',
  });
}
export function showMore(e) {
  e.preventDefault();
  e.stopPropagation();

  if (e.currentTarget.classList.contains("collapsed")) {
    e.currentTarget.classList.remove("collapsed");
    let ele = document.getElementsByClassName("time-text-area");
    for (let i = 0; i < ele.length; i++) {
      ele[i].classList.remove("d-none");
      ele[i].classList.add("d-flex");
    }

  } else {
    e.currentTarget.classList.add("collapsed")
    let ele = document.getElementsByClassName("time-text-area");
    for (let i = 0; i < ele.length; i++) {
      if (!ele[i].classList.contains("active")) {
        ele[i].classList.add("d-none");
        ele[i].classList.remove("d-flex");
      }
    }
  }
}

export function cancelBookingDataLayer(eventName) {
  if (!global?.isGTMTaggingEnabled) {
    return;
  }
  window.dataLayer.push({
    'event': 'cancel_event_click',
    'cancel_event_name': eventName
  });
}

export function isBookingAccessTokenExpired() {
  const state = store.getState();
  const accessToken = state.user.bookingAccessToken;
  const tokenExpiryValue = state.user.bookingAccessTokenExpiryValue;

  var tokenExpired = false;
  if (accessToken === '' || accessToken === null || accessToken === undefined || tokenExpiryValue === '' || tokenExpiryValue === null || tokenExpiryValue === undefined)
    return true;

  let currentTimeInSec = Math.round(new Date().getTime());
  console.log(tokenExpiryValue);
  console.log(tokenExpiryValue < currentTimeInSec);
  // exp is in seconds
  if (tokenExpiryValue < currentTimeInSec) {
    console.log("Token Expired.");
    tokenExpired = true;
  }
  return tokenExpired;
}

export function GetRenewedBookingAccessToken(callback, args, pushArgs = true) {
  var tokenUrl = "/api/sitecore/VisitorApi/GetRenewedBookingAccessToken";
  axios({
    method: "post",
    url: tokenUrl,
    dataType: "json",
  })
    .then(function (response) {
      if (response.data !== "") {
        const expiryDate = Date.now() + response.data.expires_in * 1000
        store.dispatch(setBookingAccessToken(response.data.access_token));
        // store.dispatch(setBookingTokenExipry(response.data.expires_in));
        store.dispatch(setBookingTokenExipry(expiryDate));
        if (pushArgs) {
          args = response.data.access_token;
        }
        callback(response.data.access_token);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
const CommonFunctions = () => {
  useEffect(() => {

  }, []);
};

export default CommonFunctions;
