import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function TitleImageTextTwoColumns({ fields }) {
  if (fields) {
    return (
      <section className="history" id="history">
        <div className="container">
          <div className="row">
            <div className="col-12 big-block">
              {fields.fromContentResolver !== null && fields.constant.historY_TITLE != null && fields.constant.historY_TITLE !== "" &&
                <h3 className="title">
                  {(fields.fromContentResolver[fields.constant.historY_TITLE] !== null && fields.fromContentResolver[fields.constant.historY_TITLE].value !== "" && fields.fromContentResolver[fields.constant.historY_TITLE].value !== null) &&

                    <RichText field={fields.fromContentResolver[fields.constant.historY_TITLE]} />
                  }
                </h3>
              }
              <div className="img-wrap">

                {fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.historY_IMAGE] != null && fields.fromContentResolver[fields.constant.historY_IMAGE]?.value?.src != null ?
                  (<Image field={fields.fromContentResolver[fields.constant.historY_IMAGE]} imageParams={{ mh: 507 }} />)
                  :
                  <></>
                }
              </div>
              <div className="row">
                <div className="col-6 history-block">
                  <div className="DiscoverMalldescription">

                    {(fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.historY_DESCRIPTION] !== null && fields.fromContentResolver[fields.constant.historY_DESCRIPTION].value !== "" && fields.fromContentResolver[fields.constant.historY_DESCRIPTION].value !== null) &&
                      <RichText field={fields.fromContentResolver[fields.constant.historY_DESCRIPTION]} />
                    }
                  </div>
                </div>
                <div className="col-6 history-block">
                  <div className="DiscoverMalldescription">

                    {(fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.historY_DESCRIPTION2] !== null && fields.fromContentResolver[fields.constant.historY_DESCRIPTION2].value !== "" && fields.fromContentResolver[fields.constant.historY_DESCRIPTION2].value !== null) &&
                      <RichText field={fields.fromContentResolver[fields.constant.historY_DESCRIPTION2]} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  } else {
    return <></>;
  }
}