import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import persistState from 'redux-localstorage';
import promiseMiddleware from "redux-promise";
import thunk from 'redux-thunk';
import initialState from './initialState';
import rootReducer from './rootReducer';

export default function configureStore() {
  if (typeof window === 'undefined') {
    return createStore(rootReducer, initialState);
  }
  const handleInitialState = () => {
    return (state) => {
      let subset = { ...state, user: { ...state.user } };
      return subset;
    };
  };
  const devTool =
    // eslint-disable-next-line no-underscore-dangle
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose;

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk, promiseMiddleware),
      persistState(undefined, { slicer: handleInitialState }),
      devTool
    )
  );
}
