import React, { useEffect, useState } from 'react';
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { isMobile } from '../Js-CommonFunctions';



export default function AccessInfo({ fields }) {
  const [visible, setVisible] = useState(2);

  useEffect(() => {
    if (fields.nextExceptionopenHrs != null && fields.nextExceptionopenHrs.exceptional_DetailsList.length > 0) {
      if (isMobile.any()) {
        setVisible(2);
      }
      else if (isMobile.isTablet()) {
        setVisible(2);
      }
      else {
        setVisible(fields.nextExceptionopenHrs.exceptional_DetailsList.length);
      }
    }
  }, []);
  const loadMore = (e) => {
    e.preventDefault();
    setVisible(visible + fields.nextExceptionopenHrs.exceptional_DetailsList.length);
  };
  function permanentopeningHrs(fields) {
    return (
      <div className=" exceptional-opening-block">
        <div className="exceptional-opening border-8">
          <h2 className="content-title">{fields.title}</h2>
            <div className='list-dates-wrap d-flex flex-column flex-lg-row align-items-lg-center'>
              <ul className="list-dates">
                {fields.opening.map((item, index) => {
                  return (
                    <li key={index} className="item-date" >
                      <div className={"w-100 item-row" + (item.isToDay ? " active" : "")}>
                        <div className="d-flex justify-content-between flex-row">
                          <span className="date">{item.day}</span>
                          <span className="schedule">{item.openingInfo}</span>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
        </div>
      </div >
    )
  }
  function exceptionalHours(fields) {
    return (
      <>
        {fields.nextExceptionopenHrs.exceptional_Date_Title !== "" &&
          fields.nextExceptionopenHrs.exceptional_Date_Title !==
          null && (
            <h2 className="content-title text-start">
              <Text
                field={
                  fields.nextExceptionopenHrs.exceptional_Date_Title
                }
              />
            </h2>
          )}
        <div className="list-dates-wrap ">
          {" "}
          <ul className="list-dates">
            {fields.nextExceptionopenHrs.exceptional_DetailsList
              .slice(0, visible)
              .map((item, index) => {
                if (item !== null && item !== undefined && item.closed_Check !== null && item.closed_Check !== undefined) {
                  let summary = false;
                  return (
                    <li key={index} className="item-date d-flex">
                      {item.closed_Check.filter((item) =>
                        item.summary !== ""
                          ? (summary = true)
                          : (summary = false)
                      ) && summary ? (
                        <div className='w-100'>
                          <div
                            className={
                              "activity-list" +
                              (item.closed_Check.length <= 1
                                ? " mt-0"
                                : "")
                            }
                          >
                            {item.closed_Check.map(
                              (closedCheckItem, index) => {
                                return (
                                  <div key={index}
                                    className={
                                      "w-100 d-flex activity-wrap flex-lg-row align-items-center mb-3 mb-lg-0 " +
                                      (item.closed_Check.length >
                                        1
                                        ? ""
                                        : " border-0")
                                    }
                                  >
                                    <span className="d-flex flex-column w-100">
                                      <span className="date ps-0">
                                        {item.next_Expectional}
                                      </span>
                                      <span
                                        className={
                                          "date font-weight-normal" +
                                          (item.closed_Check
                                            .length > 1
                                            ? ""
                                            : " ps-0")
                                        }
                                      >
                                        {closedCheckItem.summary}
                                      </span>
                                    </span>
                                    <span
                                      className={
                                        "schedule" +
                                        (item.closed_Check
                                          .length > 1
                                          ? ""
                                          : " ps-0")
                                      }
                                    >
                                      {closedCheckItem.time}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <span className="date">
                            {item.next_Expectional}
                          </span>
                          <span className="schedule">
                            {item !== null && item !== undefined && item.closed_Check !== null && item.closed_Check !== undefined &&
                              item.closed_Check.map(
                                (timeItem, index) => {
                                  return (
                                    <>
                                      {timeItem.time}
                                      <br />
                                    </>
                                  );
                                }
                              )}

                          </span>
                        </>
                      )}
                    </li>
                  );

                }
              })}
          </ul>
          {visible <
            fields.nextExceptionopenHrs.exceptional_DetailsList
              .length && (
              <>
                {!fields.nextExceptionopenHrs.isNewBranded ? (
                  <div
                    className="action d-lg-none"
                    id="viewmoreopeninghrs"
                  >
                    <a
                      className='link-button btn-white'
                      href="#viewmoreopeninghrs"
                      onClick={loadMore}
                    >
                      <Text
                        field={
                          fields.nextExceptionopenHrs.view_More
                        }
                      />
                      <br />
                      <br />
                      <i className="picto icon-go-down-arrow"></i>
                    </a>
                  </div>
                ) : (
                  <div
                    className="action d-lg-none"
                    id="viewmoreopeninghrs"
                  >
                    <div className='link-button btn-white' onClick={loadMore}>
                      <Text
                        field={
                          fields.nextExceptionopenHrs.view_More
                        }
                      />
                      <br />
                    </div>
                  </div>
                )}
              </>
            )}
        </div>
        {fields.nextExceptionopenHrs.nextExceptitem !== null &&
          fields.nextExceptionopenHrs.nextExceptitem
            .exceptional_Closings !== null &&
          fields.nextExceptionopenHrs.nextExceptitem
            .exceptional_Closings !== "" && (
            <div className="except-dates-wrap">
              <div className="except-dates">
                <RichText
                  field={
                    fields.nextExceptionopenHrs.nextExceptitem
                      .exceptional_Closings
                  }
                />
              </div>
            </div>
          )}
      </>
    )
  }
  function accessToTheCenterAddress(fields) {
    if (fields !== null) {
      return (
        <div className='d-flex flex-column regular-opening-hours'>
          {fields.nextExceptionopenHrs != null && fields.nextExceptionopenHrs.exceptional_DetailsList.length > 0 &&
            <div className="exceptional-opening border-8">
              {exceptionalHours(fields)}
            </div>
          }
          {fields.accessToTheCenterAddress !== null &&
            <div className="address-block border-8" id="access-center">
              <h2 className="content-title">{fields.accessToTheCenterAddress.accessToTheCenter}</h2>
              <div className="address-wrap">
                <span className="picto icon-center"></span>
                <div className="address-content">
                  <div className="center-name text-uppercase fw-bold">
                    {fields.accessToTheCenterAddress.centerName}
                  </div>
                  <div className="center-address">
                    {fields.accessToTheCenterAddress.street_Address}<br />
                    {fields.accessToTheCenterAddress.suburbs}<br />
                    {fields.accessToTheCenterAddress.state} {fields.accessToTheCenterAddress.postal_Code}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    }
  }
  if (fields.permanentopeningHrs !== null) {
    return (
      <section className="opening-address-goto" id="accessinfo">
        <div className="container">
          <div className="d-flex flex-lg-row flex-column">
            {/* <div className="col-12 col-lg-7"> */}
            {permanentopeningHrs(fields.permanentopeningHrs)}

            {/* </div> */}
            <div className="d-flex flex-column" id="address-info">
              {accessToTheCenterAddress(fields)}
            </div>
          </div>
        </div>
      </section>

    );
  } else {
    return <></>;
  }
}
