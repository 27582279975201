import { Text } from '@sitecore-jss/sitecore-jss-react';
import axios from "axios";
import React, { useEffect } from 'react';
import store from "../../redux/store.js";
import { closeVehicleErrorPopin, fnBackButtonClickFromMultiSC, fnRedirectToMyAccount, isMobile, openVehicleErrorPopin, isTokenExpired, GetRenewedAccessToken } from '../Js-CommonFunctions';
import { addVehicleClick, handleMblBackButton, updatePartnerDetail } from '../MyAccount/MyAccountScripts';
import { setSmartParkerCenters } from "../../redux/user/actions";


export default function SmartParkingServices({ fields, isMultiCentre, displayOnlyParking, visitorInfo, iLogsSmartParker }) {

  const state = store.getState();
  let smartParkerCenters = state.user.smartParkerCenters;

  useEffect(() => {
    if (isMobile.any() && !displayOnlyParking) {
      if (document.getElementById('smartparkingview')) {
        document.getElementById('smartparkingview').classList.add('d-none')
      } else {
        if (document.getElementById('myparking')) {
          document.getElementById('myparking').classList.add('d-none');
        }
      }
    }
    if (document.getElementById('parkingIframe')) {
      document.getElementById('parkingIframe').addEventListener('load', function () {
        if (document.getElementById("iframeLoader") != null && document.getElementById("parkingIframe") != null) {
          document.getElementById("iframeLoader").classList.add("d-none");
        }
      });
    }
  }, []);

  function openConfirmationPopin() {
    if (document.getElementById('parkingDeletePopin')) {
      document.getElementById('parkingDeletePopin').classList.remove('d-none')
    }
  }
  function closeConfirmationPopin() {
    if (document.getElementById('parkingDeletePopin')) {
      document.getElementById('parkingDeletePopin').classList.add('d-none')
    }
  }

  function FnupdatePartnerDetail(pcCode, parkingPartner, accountUrl) {
    if (isTokenExpired()) {
      var args = [pcCode, parkingPartner, accountUrl];
      GetRenewedAccessToken(updatePartnerDetail, args, false);
    } else {
      updatePartnerDetail(pcCode, parkingPartner, accountUrl);
    }
  }

  function FndeactivateSmartParker(pcCode, accountUrl, smartParkerCenters, visitorInfo) {
    if (isTokenExpired()) {
      var args = [pcCode, accountUrl, smartParkerCenters];
      GetRenewedAccessToken(deactivateSmartParker, args);
    } else {
      deactivateSmartParker(pcCode, accountUrl, smartParkerCenters, visitorInfo.visitorId, visitorInfo.accessToken, visitorInfo.refreshToken);
    }
  }

  if (fields !== undefined && fields !== null && fields !== "") {
    
    if (fields.displayEasyParking) {
      if (fields.easyParking !== undefined && fields.easyParking !== null && fields.easyParking !== "" &&
        fields.easyParking.secondaryParkingBlock !== undefined && fields.easyParking.secondaryParkingBlock !== null && fields.easyParking.secondaryParkingBlock !== "") {
        return (
          <div id="myparking" aria-labelledby="my-parking-tab" className={"p-lg-5 pt-lg-2 bg-white multicentre-detail fade in active show" + (displayOnlyParking ? " preferred-sc" : "")}>
            {displayOnlyParking &&
              <>
                <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                  <div className="container modalContainer p-0 ">
                    <nav className="benefits-header-height px-lg-1 row m-0   py-2">
                      <div className="col-2 text-center px-0">
                        <a className={"picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3 "}
                          href="javascript:void(0);" onClick={() => { handleMblBackButton(isMultiCentre) }}></a>
                      </div>
                      <div className="col-8 p-0 text-center">
                        <div className=" text-center benefits-title ">
                          {(fields.easyParking.benefitsAndServicesTitle != undefined && fields.easyParking.benefitsAndServicesTitle != null) ? fields.easyParking.benefitsAndServicesTitle : ""}
                        </div>
                        <div className=" text-center benefits-subtitle ">
                          {(fields.centerLabel != undefined && fields.centerLabel != null) ? fields.centerLabel : ""}
                        </div>
                      </div>
                      <div className="col-2 text-center px-0">
                      </div>
                    </nav>
                  </div>

                </div>
              </>
            }

            {
              isMobile.any() && displayOnlyParking &&
              <div className="d-flex multi-detail-loyalty my-3 mob-pd text-black ps-1">
                <span className="picto icon-srv-cars account-icon-spacing fs-1"></span>
                <span className="d-flex ms-2 text-black account-card-header align-items-center">
                  {fields.easyParking.secondaryParkingBlock.parkingHeading}
                </span>
              </div>
            }

            {
              displayOnlyParking &&
              <>
                <div className="mob-pd my-3 multi-detail-header row ps-1 text-black">
                  <div className={"align-items-center go-back-text col-md-3 d-flex" + (isMultiCentre ? "" : " invisible")}
                    onClick={(e) => { fnBackButtonClickFromMultiSC() }}>
                    <span className="account-icon-spacing icon-left-arrow multicentre-icon-left-arrow picto"></span>
                    <span className="align-items-center ms-2 multicentre-card-header">{fields.easyParking.goBack}</span>
                  </div>
                  <div className="col-md-6 d-flex d-lg-block d-none d-xl-block justify-content-center multicentre-center-name text-black ">
                    <div className="d-flex justify-content-center  account-center-name">
                      {fields.centerLabel}
                    </div>
                    <div className="d-flex justify-content-center account-center-subtitle">
                      {fields.easyParking.benefitsAndServicesTitle}
                    </div>
                  </div>
                </div>
              </>
            }

            {
              !isMobile.any() &&
              <div className="d-flex multi-detail-loyalty my-3 mob-pd text-black ps-1">
                <span className="picto icon-srv-cars account-icon-spacing fs-1"></span>
                <span className="d-flex ms-2 account-card-header align-items-center">
                  {fields.easyParking.secondaryParkingBlock.parkingHeading}

                </span>
              </div>
            }

            <>
              <div className="row p-lg-0 mob-pd equal mx-lg-0 mobile-container">
                <div className="easy-parking-container pb-lg-0 pb-3">
                  {fields.easyParking.isSmartParker ? (
                    <>
                      <div id="vehicleErrorText" className={"profile-warning d-flex align-items-center my-3 mobile-container" +
                        (!fields.easyParking.visitorVehicleAvailable ? "" : " d-none")}>
                        <div className="text-black text-decoration-none d-flex align-items-center">
                          <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                          <Text field={fields.easyParking.secondaryParkingBlock.parkingErrorText} />
                        </div>
                        <div>
                          <a href="javascript:void(0);" className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none" onClick={() => { closeVehicleErrorPopin() }}></a>
                        </div>
                      </div>
                      <div className=" w-100 mb-4 easy-parking-content ">
                        <Text field={fields.easyParking.secondaryParkingBlock.parkingDescription} />
                      </div>
                      <div className="easy-parking-content-button-container d-flex justify-content-center ">
                        <button className=" easy-parking-content-button" onClick={() => { fnRedirectToMyAccount(fields.easyParking.accountUrl?.link?.value?.url + "?ShowSuccessPopin=manage-vehicles") }}>{fields.easyParking.secondaryParkingBlock.manageCTA}
                        </button>
                      </div>
                      <a href="javascript:void(0);" aria-label={fields?.easyParking?.secondaryParkingBlock?.parkingDeactivationLink} className="d-flex account-deactivate-parking text-underline" onClick={(e) => { openConfirmationPopin() }} >
                        {fields.easyParking.secondaryParkingBlock.parkingDeactivationLink}
                      </a>
                    </>
                  ) : (
                    <>
                      {(fields.easyParking.visitorVehicleAvailable) ? (
                        <>
                          {
                            (fields.easyParking.isPartnerActivated) ? (
                              <div className=" w-100 mb-4 easy-parking-content ">
                                <Text field={fields.easyParking.secondaryParkingBlock.secondaryActivationDescription} />
                              </div>
                            ) : (
                              <div className=" w-100 mb-4 easy-parking-content ">
                                <Text field={fields.easyParking.secondaryParkingBlock.parkingDescription} />
                              </div>
                            )
                          }
                          <>
                            <div className="easy-parking-content-button-container d-flex justify-content-center ">
                              <button className=" easy-parking-content-button" onClick={() => FnupdatePartnerDetail(fields.easyParking.pcCode, fields.easyParking.parkingPartner,
                                (fields.easyParking.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.easyParking.pcCode))}>{fields.easyParking.secondaryParkingBlock.firstActivationCTA}
                              </button>
                            </div>
                          </>
                        </>
                      ) : (
                        <>
                          {
                            (fields.easyParking.isPartnerActivated) ? (
                              <div className=" w-100 mb-4 easy-parking-content ">
                                <Text field={fields.easyParking.secondaryParkingBlock.secondaryActivationDescription} />
                              </div>
                            ) : (
                              <div className=" w-100 mb-4 easy-parking-content ">
                                <Text field={fields.easyParking.secondaryParkingBlock.parkingDescription} />
                              </div>
                            )
                          }
                          <>
                            <div className="easy-parking-content-button-container d-flex justify-content-center ">
                              <button className=" easy-parking-content-button" onClick={() => { addVehicleClick(fields.easyParking, null, true, false, null, null, true) }}>
                                {fields.easyParking.secondaryParkingBlock.firstActivationCTA}
                              </button>
                            </div>
                          </>
                        </>
                      )
                      }
                    </>
                  )}

                  <div className="modal show d-block modal-popin-trends disassociate-my-bank-account d-none" id="parkingDeletePopin" tabIndex="-1" aria-labelledby="parkingDeletePopin"
                    aria-modal="true" role="dialog">
                    <div className=" modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="picto icon-close-circle-rw border-0 fs-3 bg-white text-black" data-bs-dismiss="modal" aria-label="Close"
                            onClick={(e) => { closeConfirmationPopin() }}></button>
                        </div>
                        <div className="modal-body text-center">
                          <div className="popin-wrap">
                            <div className="msg-text"><Text field={fields.easyParking.secondaryParkingBlock.parkingDeactivationConfirmation} /></div>
                            <a className="link-button btn-dark center-block" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);"
                              onClick={(e) => { closeConfirmationPopin() }}>{fields.easyParking.no}</a>
                            <a className="link-button btn-white center-block" onClick={(e) => { FndeactivateSmartParker(fields.easyParking.pcCode, fields.easyParking.accountUrl?.link?.value?.url, smartParkerCenters, visitorInfo) }}
                              href="javascript:void(0);">{fields.easyParking.yes}</a>
                            <a className="center-block link-text account-profile-delete text-underline text-center" data-bs-dismiss="modal" aria-label="Close"
                              href="javascript:void(0);" onClick={(e) => { closeConfirmationPopin() }}>{fields.easyParking.cancel_CTA}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div >

        )
      }

    }
    else if (fields.displayOtherParking) {
      return (
        fields.otherParkingService !== undefined && fields.otherParkingService !== null && fields.otherParkingService !== "" &&

        <div id="myparking" aria-labelledby="my-parking-tab" className={"p-lg-5 pt-lg-2 bg-white multicentre-detail fade in active show" + (displayOnlyParking ? " preferred-sc" : "")}>

          {displayOnlyParking &&
            <>
              <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                <div className="container modalContainer p-0 ">
                  <nav className="benefits-header-height px-lg-1 row m-0   py-2">
                    <div className="col-2 text-center px-0">
                      <a className={"picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3 "}
                        href="javascript:void(0);" onClick={() => { handleMblBackButton(isMultiCentre) }}></a>
                    </div>
                    <div className="col-8 p-0 text-center">
                      <div className=" text-center benefits-title ">
                        {(fields.otherParkingService.benefitsAndServicesTitle != undefined && fields.otherParkingService.benefitsAndServicesTitle != null) ? fields.otherParkingService.benefitsAndServicesTitle : ""}
                      </div>
                      <div className=" text-center benefits-subtitle ">
                        {(fields.centerLabel != undefined && fields.centerLabel != null) ? fields.centerLabel : ""}
                      </div>
                    </div>
                    <div className="col-2 text-center px-0">
                    </div>
                  </nav>
                </div>

              </div>
            </>
          }

          {isMobile.any() && displayOnlyParking &&
            <div className="d-flex multi-detail-loyalty my-3 mob-pd text-black ps-1">
              <span className="picto icon-srv-cars account-icon-spacing fs-1"></span>
              <span className="d-flex ms-2 text-black account-card-header align-items-center">
                {fields.otherParkingService.smartParkingTitle}
              </span>
            </div>
          }

          {displayOnlyParking &&
            <>
              <div className="mob-pd my-3 multi-detail-header row ps-1 text-black">
                <div className={"align-items-center go-back-text col-md-3 d-flex" + (isMultiCentre ? "" : " invisible")}
                  onClick={(e) => { fnBackButtonClickFromMultiSC() }}>
                  <span className="account-icon-spacing icon-left-arrow multicentre-icon-left-arrow picto"></span>
                  <span className="align-items-center ms-2 multicentre-card-header">{fields.otherParkingService.goBack}</span>
                </div>
                <div className="col-md-6 d-flex d-lg-block d-none d-xl-block justify-content-center multicentre-center-name text-black ">
                  <div className="d-flex justify-content-center  account-center-name">
                    {fields.centerLabel}
                  </div>
                  <div className="d-flex justify-content-center account-center-subtitle">
                    {fields.otherParkingService.benefitsAndServicesTitle}
                  </div>
                </div>
              </div>
            </>
          }

          {!isMobile.any() &&
            <div className="d-flex multi-detail-loyalty my-3 mob-pd text-black ps-1">
              <span className="picto icon-srv-cars account-icon-spacing fs-1"></span>
              <span className="d-flex ms-2 text-black account-card-header align-items-center">
                {fields.otherParkingService.smartParkingTitle}
              </span>
            </div>
          }

          {fields.otherParkingService.otherParkingData !== undefined && fields.otherParkingService.otherParkingData !== null && fields.otherParkingService.otherParkingData !== "" &&

            <div className="col-sm-12 p-0 d-flex">
              <div className="card card-inverse w-100 multicenter-details-sc-border multicenter-other-parking text-decoration-none">
                <div className="bg-fade"></div>
                {(fields.otherParkingService.otherParkingData.image?.value != null && fields.otherParkingService.otherParkingData.image?.value !== "" && fields.otherParkingService.otherParkingData.image?.value !== undefined
                  && fields.otherParkingService.otherParkingData.image?.value.src != null && fields.otherParkingService.otherParkingData.image?.value.src !== "" && fields.otherParkingService.otherParkingData.image?.value.src !== undefined) &&
                  <img className="smart-parking-img" src={fields.otherParkingService.otherParkingData.image.value.src} alt={fields.otherParkingService.smartParkingTitle} />
                }
                <div className="smart-parking-card-block card-block p-3">
                  {(fields.otherParkingService.otherParkingData.title?.value != null && fields.otherParkingService.otherParkingData.title?.value !== "" && fields.otherParkingService.otherParkingData.title?.value !== undefined) &&
                    <p className="smart-parking-register-card-text">{fields.otherParkingService.otherParkingData.title.value}</p>}
                  <div className="d-flex mb-3 justify-content-center">
                    {(fields.otherParkingService.otherParkingData.register_Button?.value != null && fields.otherParkingService.otherParkingData.register_Button?.value !== "" && fields.otherParkingService.otherParkingData.register_Button?.value !== undefined &&
                      fields.otherParkingService.otherParkingData.register_Button?.value.href != null && fields.otherParkingService.otherParkingData.register_Button?.value.href !== "" && fields.otherParkingService.otherParkingData.register_Button?.value.href !== undefined) &&
                      <a href={fields.otherParkingService.otherParkingData.register_Button?.value.href}
                        aria-label={fields?.otherParkingService?.otherParkingData.register_Button?.value}
                        target={fields.otherParkingService.otherParkingData.register_Button?.value?.target}
                        className="btn btn-dark smart-parking-register-btn-transferent align-items-center">{fields.otherParkingService.otherParkingData.register_Button.value.text}</a>}
                  </div>
                  <div className="d-flex justify-content-center">
                    {(fields.otherParkingService.otherParkingData.image_Link?.value != null && fields.otherParkingService.otherParkingData.image_Link?.value !== "" && fields.otherParkingService.otherParkingData.image_Link?.value !== undefined
                      && fields.otherParkingService.otherParkingData.image_Link?.value.href != null && fields.otherParkingService.otherParkingData.image_Link?.value.href !== "" && fields.otherParkingService.otherParkingData.image_Link?.value.href !== undefined) &&
                      <a href={fields.otherParkingService.otherParkingData.image_Link.value.href}
                        aria-label={fields?.otherParkingService?.otherParkingData.image_Link?.value}
                        target={fields.otherParkingService.otherParkingData.image_Link?.value.target}
                        className="btn btn-white smart-parking-register-btn-white align-items-center">{fields.otherParkingService.otherParkingData.image_Link.value.text}</a>}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );

    }
    else if (fields.displayILogsParking) {
      return (
        fields.ilogsParkingService !== undefined && fields.ilogsParkingService !== null && fields.ilogsParkingService !== "" &&
        <div id="myparking" className={"bg-white  p-lg-5 pt-lg-2 " + (displayOnlyParking ? " preferred-sc" : "")}>

          {displayOnlyParking &&
            <>
              <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                <div className="container modalContainer p-0 ">
                  <nav className="benefits-header-height px-lg-1 row m-0   py-2">
                    <div className="col-2 text-center px-0">
                      <a aria-label={fields?.ilogsParkingService?.benefitsAndServicesTitle} className={"picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3 "}
                        href="javascript:void(0);" onClick={() => { handleMblBackButton(isMultiCentre) }}></a>
                    </div>
                    <div className="col-8 p-0 text-center">
                      <div className=" text-center benefits-title ">
                        {(fields.ilogsParkingService.benefitsAndServicesTitle != undefined && fields.ilogsParkingService.benefitsAndServicesTitle != null) ? fields.ilogsParkingService.benefitsAndServicesTitle : ""}
                      </div>
                      <div className=" text-center benefits-subtitle ">
                        {(fields.centerLabel != undefined && fields.centerLabel != null) ? fields.centerLabel : ""}
                      </div>
                    </div>
                    <div className="col-2 text-center px-0">
                    </div>
                  </nav>
                </div>

              </div>
            </>
          }

          {isMobile.any() && displayOnlyParking &&
            <div className="d-flex multi-detail-loyalty my-3 mob-pd text-black ps-1">
              <span className="picto icon-srv-cars account-icon-spacing fs-1"></span>
              <span className="d-flex ms-2 text-black account-card-header align-items-center">
                {fields.ilogsParkingService.smartParkingTitle}
              </span>
            </div>
          }

          {displayOnlyParking &&
            <>
              <div className="mob-pd my-3 multi-detail-header row ps-1 text-black">
                <div className={"align-items-center go-back-text col-md-3 d-flex" + (isMultiCentre ? "" : " invisible")}
                  onClick={(e) => { fnBackButtonClickFromMultiSC() }}>
                  <span className="account-icon-spacing icon-left-arrow multicentre-icon-left-arrow picto"></span>
                  <span className="align-items-center ms-2 multicentre-card-header">{fields.ilogsParkingService.goBack}</span>
                </div>
                <div className="col-md-6 d-flex d-lg-block d-none d-xl-block justify-content-center multicentre-center-name text-black ">
                  <div className="d-flex justify-content-center account-center-name">
                    {fields.centerLabel}
                  </div>
                  <div className="d-flex justify-content-center  account-center-subtitle">
                    {fields.ilogsParkingService.benefitsAndServicesTitle}
                  </div>
                </div>
              </div>
            </>
          }

          {!isMobile.any() &&
            <div className="d-flex multi-detail-loyalty my-3 mob-pd text-black ps-1">
              <span className="picto icon-srv-cars account-icon-spacing fs-1"></span>
              <span className="d-flex ms-2 text-black account-card-header align-items-center">
                {fields.ilogsParkingService.smartParkingTitle}
              </span>
            </div>
          }

          <div className="row p-lg-0 mob-pd equal mx-lg-0 mobile-container">
            {fields.ilogsParkingService.ilogsBookingData !== undefined && fields.ilogsParkingService.ilogsBookingData !== null && fields.ilogsParkingService.ilogsBookingData !== "" &&
              <div className="col-sm-6 p-0 d-flex smart-parking-card-1 pb-3">
                <div
                  className={"card card-inverse w-100 text-decoration-none" + (isMobile.any() ? "" : " multicenter-details-sc-border")}>
                  <div className="card-block p-3">
                    <div className="d-flex justify-content-center">
                      <div
                        className={"account-basic-icon multi-detail-icon-bg text-black multicentre-basic-icon img-fluid " + fields.ilogsParkingService.ilogsBookingData?.personalized_Picto}>
                      </div>
                    </div>
                    <p className="multi-detail-card-text">{fields.ilogsParkingService.ilogsBookingData?.personalized_Title}</p>
                    <div className="d-flex justify-content-center">
                      <a href={fields.ilogsParkingService.ilogsBookingData?.personalized_CTA?.value?.href}
                        aria-label={fields?.ilogsParkingService?.ilogsBookingData?.personalized_CTA?.value}
                        target="_blank" rel="noreferrer"
                        className="btn btn-dark smart-parking-btn-dark align-items-center">{fields.ilogsParkingService.ilogsBookingData?.personalized_CTA?.value?.text}</a>
                    </div>
                  </div>
                </div>
              </div>
            }

            {(!iLogsSmartParker) ? (
              <>
                {
                  fields.ilogsParkingService.ilogsPaymentData !== undefined && fields.ilogsParkingService.ilogsPaymentData !== null && fields.ilogsParkingService.ilogsPaymentData !== "" &&
                  <div className="col-sm-6 p-0 d-flex smart-parking-card-2 pb-3">
                    <div
                      className={"card card-inverse w-100 text-decoration-none" + (isMobile.any() ? "" : " multicenter-details-sc-border")}>
                      <div className="card-block p-3">
                        <div className="d-flex justify-content-center">
                          <div
                            className={"account-basic-icon text-black multi-detail-icon-bg multicentre-basic-icon img-fluid " + fields.ilogsParkingService.ilogsPaymentData?.personalized_Picto}>
                          </div>
                        </div>
                        <p className="multi-detail-card-text">{fields.ilogsParkingService.ilogsPaymentData?.personalized_Title}</p>
                        <div className="d-flex justify-content-center">
                          <a href={fields.ilogsParkingService.ilogsPaymentData?.personalized_CTA?.value?.href}
                            aria-label={fields?.ilogsParkingService?.ilogsPaymentData?.personalized_CTA?.value}
                            target="_blank" rel="noreferrer"
                            className="btn btn-dark smart-parking-btn-dark align-items-center">{fields.ilogsParkingService.ilogsPaymentData?.personalized_CTA?.value?.text}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            ) : (
              <>
                {
                  fields.ilogsParkingService.ilogsParkerData !== undefined && fields.ilogsParkingService.ilogsParkerData !== null && fields.ilogsParkingService.ilogsParkerData !== "" &&
                  <div className="col-sm-6 p-0 d-flex smart-parking-card-2 pb-3">
                    <div
                      className={"card card-inverse w-100 text-decoration-none" + (isMobile.any() ? "" : " multicenter-details-sc-border")}>
                      <div className="card-block p-3">
                        <div className="d-flex justify-content-center">
                          <div
                            className={"account-basic-icon text-black multi-detail-icon-bg multicentre-basic-icon img-fluid " + fields.ilogsParkingService.ilogsParkerData?.personalized_Picto}>
                          </div>
                        </div>
                        <p className="multi-detail-card-text">{fields.ilogsParkingService.ilogsParkerData?.personalized_Title}</p>
                        <div className="d-flex justify-content-center">
                          <a href={fields.ilogsParkingService.ilogsParkerData?.personalized_CTA?.value?.href}
                            aria-label={fields?.ilogsParkingService?.ilogsParkerData?.personalized_CTA?.value}
                            target="_blank" rel="noreferrer"
                            className="btn btn-dark smart-parking-btn-dark align-items-center">{fields.ilogsParkingService.ilogsParkerData?.personalized_CTA?.value?.text}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            )
            }

            {fields.ilogsParkingService.ilogsNonParkerData !== undefined && fields.ilogsParkingService.ilogsNonParkerData !== null && fields.ilogsParkingService.ilogsNonParkerData !== "" &&
              !iLogsSmartParker &&
              <div className="col-sm-6 p-0 d-flex smart-parking-card-1 pb-3">
                <div
                  className={"card card-inverse w-100 text-decoration-none" + (isMobile.any() ? "" : " multicenter-details-sc-border")}>
                  <div className="bg-fade"></div>
                  {(fields.ilogsParkingService.ilogsNonParkerData?.image?.value != null && fields.ilogsParkingService.ilogsNonParkerData?.image?.value !== "" && fields.ilogsParkingService.ilogsNonParkerData?.image?.value !== undefined
                    && fields.ilogsParkingService.ilogsNonParkerData?.image?.value?.src != null && fields.ilogsParkingService.ilogsNonParkerData?.image?.value?.src !== "" && fields.ilogsParkingService.ilogsNonParkerData?.image?.value?.src !== undefined) &&
                    <img src={fields.ilogsParkingService.ilogsNonParkerData?.image?.value?.src} alt={fields.otherParkingService.smartParkingTitle} className="smart-parking-img" />
                  }
                  <div className="smart-parking-card-block card-block p-3">

                    <div className="d-flex justify-content-center">
                      <div
                        className={"account-basic-icon multi-detail-icon-bg text-black multicentre-basic-icon img-fluid " + fields.ilogsParkingService.ilogsNonParkerData?.personalized_Picto}>
                      </div>
                    </div>
                    <p className="smart-parking-card-text multi-detail-card-text">{fields.ilogsParkingService.ilogsNonParkerData?.personalized_Title}</p>
                    <div className="d-flex justify-content-center">
                      <a href={fields.ilogsParkingService.ilogsNonParkerData?.personalized_CTA?.value?.href}
                        aria-label={fields?.ilogsParkingService?.ilogsNonParkerData?.personalized_CTA?.value}
                        target="_blank" rel="noreferrer"
                        className="btn btn-dark smart-parking-card-btn align-items-center">{fields.ilogsParkingService.ilogsNonParkerData?.personalized_CTA?.value?.text}</a>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>
      )
    }
    else {
      return <></>
    }
  }
  else {
    return <></>
  }
}

export function deactivateSmartParker(pcCode, accountUrl, smartParkerCenters, visitorId, accessToken, refreshToken) {
  axios({
    method: "post",
    url: "/api/sitecore/VisitorApi/VisitorCenterParkingStatusUpdate", //?pcCode=" + pcCode +"&parkingStatus=" + false,
    data: {
      pcCode: pcCode,
      parkingStatus: false,
      visitorId: visitorId,
      accessToken: accessToken,
      refreshToken: refreshToken,
    },
    async: false,
  })
    .then(function (response) {
      if (response != null && response.data) {
        let updatedSmartParkerCenters = smartParkerCenters.split(",").filter((x) => x !== pcCode).join(",");
        store.dispatch(setSmartParkerCenters(updatedSmartParkerCenters));
        fnRedirectToMyAccount(accountUrl);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}




