import React from 'react';

export default function QueueManagementRetailersInner({ model }) {
  if (model !== null) {
    return (
      <div className="container">
        <div className={"row retail-filter-result " + (model.isUSUK ? model.usUkStoreLogo : "")}>
          {model.participationStores.map((store, index) => {
            return (
              <div  key={index} className="col-lg-4 col-12 retailerqueue-tile mb-3">
                <div className="card border-0 rounded-0 text-center">
                  {(model.isLazyLoadEnabled) ?
                    (
                      <img src={model.defaultImage} data-alt={store.logo} className="retail-logo" alt={store.name} />

                    ) :
                    (
                      <img src={store.logo} className="retail-logo" alt={store.name} />

                    )}
                  <div className="card-body">
                    <div className="card-title brand-name text-uppercase d-block" href="#">{store.name}</div>
                    <div className="btn-wrap">
                      {(model.iosUrl !== null && model.iosUrl !== "") &&
                        <a className="link-button btn-dark-transparent mb-3" href={model.androidUrl} target={model.androidTarget}>{model.androidLabel}</a>

                      }
                      {(model.iosUrl !== null && model.iosUrl !== "") &&
                        <a className="link-button btn-dark-transparent mb-3" href={model.iosUrl} target={model.iosTarget}>{model.iosLabel}</a>

                      }
                    </div>
                  </div>
                </div>
              </div>

            )
          })
          }
        </div>
      </div>

    )
  }
  else {
    return <></>;
  }
}
