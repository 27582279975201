import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { CommonBookmarkallpage, URWindowLocation, createCookie, fnClickNavigation, fnGtmCategorySearchFilter, fnUpdatePageNameInDataAttr, isMobile, lazyLoadingImg, readCookie } from '../Js-CommonFunctions';
import { useSelector } from "react-redux";
import axios from "axios";
import { baseModel } from "../../RouteHandler";
const MosaicAll = loadable(() => import("../MosaicAll"));

export default function Mosaic({ fields }) {
  const [visibleEvents, setvisibleEvents] = useState(0);
  const [mosiacData, setMosaicData] = useState();
  let workerCenter = useSelector((state) => state.user.workerCenter);

  let EventsPerOther = 9;
  let EventsPerMobile = 3;
  let EventsPerTab = 9;

  useEffect(() => {
    if (fields.newsItems != null) {
      let isWorker =
        workerCenter !== undefined &&
        workerCenter !== '' &&
        workerCenter.split(',').includes(baseModel.pC_Code);

      if (isWorker) {
        var loader = document.getElementById("dotloader")

        if (loader) {
          loader.classList.remove("d-none");
          //loader.classList.add("visible");
        }
        var ajaxServicesURL =
          "/api/sitecore/NewsApi/GetMosaicData?ursite=" +
          baseModel.urContextSite +
          "&urlang=" +
          baseModel.contextLang +
          "&isWorker=" +
          isWorker +
          "&commercialCategory=" +
          fields.newsItems.strCommercialCategory +
          "&periodicity=" +
          fields.newsItems.strperiodCategory
        axios({
          method: "GET",
          url: ajaxServicesURL,
          async: true,
        })
          .then(function (response) {
            if (response && response.data !== "") {
              setMosaicData(response.data);
            }
            if (loader) {
              loader.classList.add("d-none");
              // loader.classList.remove("visible");
            }

          })
          .catch(function (error) {
            console.log(error);
            if (loader) {
              loader.classList.add("d-none");
              //loader.classList.remove("visible");
            }
          });
      }
      else {
        setMosaicData(fields.newsItems);
      }
      if (isMobile.any())
        setvisibleEvents(3);
      else if (isMobile.isTablet())
        setvisibleEvents(9);
      else
        setvisibleEvents(9);
    }
  }, []);

  useEffect(() => {

    if (mosiacData !== null && mosiacData !== "" && mosiacData !== undefined) {
      // anchorClick();

      var totalcount = mosiacData.customItems?.length;

      var eleViewMore = document.getElementById('whatshotviewmoreDiv');
      if (eleViewMore !== null) {
        if (totalcount > visibleEvents) {
          document.getElementById('whatshotviewmoreDiv').style.display = "block";
        } else {
          document.getElementById('whatshotviewmoreDiv').style.display = "none";
        }
      }
      CommonBookmarkallpage();
      lazyLoadingImg();

      if (mosiacData.redirectUrl != null && mosiacData.redirectUrl != "") {
        URWindowLocation(mosiacData.redirectUrl);
      }

      //When navigated by clicking on internal links, create clickPosition & clickLabel cookies - to be used for Page Load dataLayer variables
      if (document.querySelectorAll('.gtmClickNavigation') !== null && document.querySelectorAll('.gtmClickNavigation') !== undefined) {
        document.querySelectorAll('.gtmClickNavigation').forEach(x => {
          x.addEventListener('click', function (e) {
            if (e.target.classList.contains("inner-toggler")) { //In Mobile - Header navigation, there is span for just showing sub-navigations. This span will not actually navigate away to another page. So, do not store GTM navigation data when this span is clicked.
              return;
            }
            fnClickNavigation(e.target);
          })
        })
      }

    }

  }, [visibleEvents, mosiacData]);

  // function anchorClick() {
  //   if (baseModel.currentPageItemName === "news" || baseModel.currentPageItemName === "events") {
  //     var pathQueryLocal = window.location.href.split(window.location.hostname);
  //     var pageAndQueryLocal =
  //       pathQueryLocal.length > 1 ? pathQueryLocal[1].toLowerCase() : "";

  //     var eventsfilter = pageAndQueryLocal.replace(baseModel.siteUrlPrefix, "").split("/");
  //     var cookieValue = readCookie("anchorclick");
  //     if (eventsfilter.length > 2 || cookieValue) {
  //       var elementoffer = document.getElementById("events");
  //       if (elementoffer) {
  //         createCookie("anchorclick", "", -1);
  //         var heightHeader =
  //           document.getElementById("headerBlock").offsetHeight +
  //           document.getElementById("MainHeader").offsetHeight;
  //         window.scrollTo({
  //           top: elementoffer.offsetTop - heightHeader,
  //           behavior: "smooth",
  //         });
  //       }
  //     }
  //   }
  // }

  //Function to get more events/news when View More is clicked
  function eventsViewMore(e, perPageItem, ishref) {
    if (ishref) {
      e.preventDefault();
    }
    var itemsToDisplay = visibleEvents + perPageItem;
    setvisibleEvents(itemsToDisplay);
    document.getElementById('viewmorepageother').setAttribute('data-gtmpagenumber', '');
    let pagenumber = Math.ceil(visibleEvents / perPageItem);
    document.getElementById('viewmorepageother').setAttribute('gtmpagenumber', pagenumber);


    //Add click navigation analytics changes for news mosaic block
    var ele = document.querySelector('#row-isflex-flexgrid-clearfix .gtmClickNavigation');
    if (ele !== null) {
      ele.addEventListener('click', function (event) {
        fnUpdatePageNameInDataAttr(event, 'gtmclickposition');
        fnClickNavigation(event);
      });
    }
    var totalcount = document.getElementById('totalresult') !== null && document.getElementById('totalresult') !== undefined ? parseInt(document.getElementById('totalresult').value) : 0;
    var eleViewMore = document.getElementById('whatshotviewmoreDiv');
    if (eleViewMore !== null) {
      if (totalcount > itemsToDisplay) {
        document.getElementById('whatshotviewmoreDiv').style.display = "block";

      } else {
        document.getElementById('whatshotviewmoreDiv').style.display = "none";

      }
    }
    CommonBookmarkallpage();
    lazyLoadingImg();
    return false;
  }



  if (fields != null) {
    let stylePerOther;
    let stylePerMobile;
    let stylePerTab;
    let isNewBrandedSite;
    let destinationTitle;
    let title;
    if (mosiacData) {
      destinationTitle = mosiacData.destinationTitle;
      isNewBrandedSite = mosiacData.isRebranded;
      let urlPrefix = mosiacData.urlPrefix;
      title = destinationTitle !== "" ? mosiacData.newsTitle + mosiacData.symbolHyphen + destinationTitle : mosiacData.newsTitle;
      mosiacData.headingLeftLink = (mosiacData.headingLeftLink) !== null && mosiacData.headingLeftLink !== "" ? mosiacData.headingLeftLink : (isNewBrandedSite ? urlPrefix + mosiacData.slash + mosiacData.whatsHotPage : urlPrefix + mosiacData.slash + mosiacData.whatsHotPage + "/periodicity/Today");
      mosiacData.headingRightLink = (mosiacData.headingRightLink) ? mosiacData.headingRightLink : urlPrefix + mosiacData.slash + mosiacData.whatsHotPage;
      stylePerOther = (mosiacData.items.length > mosiacData.newsPagenumber * mosiacData.perOther) ? "" : " display:none !important"
      stylePerMobile = (mosiacData.items.length > mosiacData.newsPagenumber * mosiacData.perMobile) ? "" : " display:none !important"
      stylePerTab = ((mosiacData.items.length > mosiacData.newsPagenumber * mosiacData.perTab) ? "" : " display:none !important")
    }
    return (
      <div id="events">
        {mosiacData && mosiacData.items != null && mosiacData.items.length > 0 ? (
          <>
            <section
              className={'filter-area new news-events three-block'} id="events"
            >
              <div className='container '>
                {mosiacData.isDestinationPage ? (
                  <>
                    <div className="header-title destination d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
                      {mosiacData.whatsHotPage !== null &&
                        mosiacData.whatsHotPage !== null &&
                        mosiacData.totalNewsCount > 0 ? (
                        <>
                          <h2 className="title">
                            <a
                              href={mosiacData.headingLeftLink}
                              aria-label={fields?.newsItems?.newsTitle}
                              className="gtmClickNavigation"
                            >
                              {mosiacData.newsTitle}
                            </a>
                            {mosiacData.symbolHyphen} <span>{destinationTitle}</span>
                          </h2>
                        </>
                      ) : (
                        <a className="title" href="javascript:void(0)">
                          <h2>{title}</h2>
                        </a>
                      )}
                    </div>
                    <div id="unexpected">
                      <div id="row-isflex-flexgrid-clearfix">
                        <MosaicAll
                          model={mosiacData}
                          commonValues={mosiacData}
                          visibleItemCount={visibleEvents}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-12 col-lg-3"></div>
                      <div className="col-12 col-lg-9 ps-lg-0">
                        <h2 className="content-title d-flex text-start news-title ms-0">
                          {mosiacData.findUnexpectedSomethingDict}
                        </h2>
                      </div>
                    </div>
                    <div className="row filter-wrap new" id="unexpected">
                      <div className="col-12 col-lg-3 filter-category-wrap mb-4">
                        <div
                          className="action btn-show-filter collapsed"
                          id="categoryfilter"
                          data-bs-toggle="collapse"
                          data-bs-target="#brandFilter"
                          aria-expanded="false"
                          aria-controls="brandFilter"
                        >
                          <a className="link-button btn-see-more link-expandable">
                            {mosiacData.filterBy} <span className="picto icon-go-down-arrow"></span>
                          </a>
                        </div>
                        <div id="brandFilter" className="brandfilter bg-white p-3 border-8 collapse">
                          <ul
                            className={'filter-category-list ' + mosiacData.allSelected + " bg-white p-3 border-8"}
                            id="filter-category-list"
                          >
                            <li>
                              <a
                                href={mosiacData.urlPrefix + mosiacData.slash + fields.newsItems.strPageItemName}
                                aria-label={fields?.newsItems?.strPageItemName}
                                className={mosiacData.allSelected}
                                aria-expanded="false"
                                data-testid="testClick1"
                                onClick={(e) => {
                                  createCookie("anchorclick", "news", 0);
                                  fnGtmCategorySearchFilter(
                                    e.target,
                                    mosiacData.allEventsGTM,
                                    '',
                                    mosiacData.totalNewsCount,
                                    'News',
                                    'News List'
                                  )
                                }
                                }
                              >
                                {mosiacData.allEvents + ' (' + mosiacData.totalNewsCount + ')'}
                              </a>
                            </li>
                            <>
                              {mosiacData.periodicityCategories !== null &&
                                mosiacData.periodicityCategories.length > 0 && (
                                  <>
                                    <hr />
                                    <h2 className="filter-wrap-news-title text-uppercase">
                                      {mosiacData.periodicityTitle}
                                    </h2>
                                    {mosiacData.periodicityCategories.map((newsGroup, index) => {
                                      return (
                                        <li key={index}>
                                          <a
                                            href={
                                              mosiacData.urlPrefix +
                                              mosiacData.slash +
                                              fields.newsItems.strPageItemName +
                                              mosiacData.slash +
                                              mosiacData.periodicitySegment +
                                              mosiacData.slash +
                                              newsGroup.categoryUrl
                                            }
                                            className={newsGroup.class}
                                            data-testid="testClick2"
                                            onClick={(e) =>
                                              fnGtmCategorySearchFilter(
                                                e.target,
                                                '',
                                                newsGroup.perodicityItemName,
                                                newsGroup.newsCount,
                                                'News',
                                                'News List'
                                              )
                                            }
                                            aria-expanded="false"
                                          >
                                            {newsGroup.categoryName + ' (' + newsGroup.newsCount + ')'}
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </>
                                )}
                              {mosiacData.commercialCategories !== null &&
                                mosiacData.commercialCategories.length > 0 && (
                                  <>
                                    <hr />
                                    <h2 className="filter-wrap-news-title text-uppercase">
                                      {mosiacData.commercialCategoryTitle}
                                    </h2>
                                    {mosiacData.commercialCategories.map((newsGroup, index) => {
                                      return (
                                        <li key={index}>
                                          <a
                                            href={
                                              mosiacData.urlPrefix +
                                              mosiacData.slash +
                                              fields.newsItems.strPageItemName +
                                              mosiacData.slash +
                                              mosiacData.categorySegment +
                                              mosiacData.slash +
                                              newsGroup.categoryUrl
                                            }
                                            className={newsGroup.class}
                                            aria-expanded="false"
                                            data-testid="testClick3"
                                            onClick={(e) =>
                                              fnGtmCategorySearchFilter(
                                                e.target,
                                                newsGroup.categoryName,
                                                '',
                                                newsGroup.newsCount,
                                                'News',
                                                'News List'
                                              )
                                            }
                                          >
                                            {newsGroup.categoryName + ' (' + newsGroup.newsCount + ')'}
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </>
                                )}
                            </>
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-lg-9 filter-result-wrap" id="filter-result-wrap-news">
                        <MosaicAll
                          model={mosiacData}
                          commonValues={mosiacData}
                          visibleItemCount={visibleEvents}
                        />
                      </div>
                    </div>
                  </>
                )}
                {mosiacData.items != null && (
                  <div id="whatshotviewmoreDiv">
                    <div
                      className="text-end black-view-more my-2 d-none d-lg-block"
                      id="whatshotviewmore"
                      style={{ display: stylePerOther }}
                    >
                      <div
                        id="viewmorepageother"
                        className="grid-new-cta gtmViewMoreClick"
                        data-testid="testClick4"
                        onClick={(e) => eventsViewMore(e, EventsPerOther, false)}
                      >
                        {mosiacData.viewMoreDict}
                        <span className="picto icon-arrow-square-red-right-rw align-middle ps-1 fs-5"></span>
                      </div>
                    </div>
                    <div
                      className="view-more-unexpected d-block d-sm-none"
                      id="whatshotviewmoremobile"
                      style={{ display: stylePerMobile }}
                    >
                      <div
                        id="viewmorepagemobile"
                        className="gtmViewMoreClick"
                        data-testid="testClick5"
                        onClick={(e) => eventsViewMore(e, EventsPerMobile, false)}
                      >
                        {mosiacData.viewMoreDict}
                      </div>
                    </div>
                    <div
                      className="view-more-unexpected d-md-block d-none d-xl-none"
                      id="whatshotviewmoretab"
                      style={{ display: stylePerTab }}
                    >
                      <div
                        id="viewmorepagetab"
                        className="gtmViewMoreClick"
                        data-testid="testClick6"
                        onClick={(e) => eventsViewMore(e, EventsPerTab, false)}
                      >
                        {mosiacData.viewMoreDict}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <div className="loadersmall asyncloader invisible" id="mosaicloader">
              <div className="dot-spin top-left"></div>
            </div>
          </>
        ) : mosiacData && mosiacData.isDestinationPage ? (
          <><section className="whats-hot-mosiac bg-2">
            <div className="container">
              <div className="header-title destination d-flex align-items-baseline flex-wrap justify-content-lg-between mb-3">
                {mosiacData.whatsHotPage !== null &&
                  mosiacData.whatsHotPage !== '' &&
                  mosiacData.totalNewsCount > 0 ? (
                  <>
                    <h2 className="title">
                      <a
                        href={mosiacData.HeadingLeftLink}
                        aria-label={fields?.newsItems?.newsTitle}
                        className="gtmClickNavigation"
                      >
                        {mosiacData.newsTitle}
                      </a>{' '}
                      {mosiacData.symbolHyphen} <span>{destinationTitle}</span>
                    </h2>
                  </>
                ) : (
                  <a className="title" href="javascript:void(0)">
                    <h2>{title}</h2>
                  </a>
                )}
              </div>
              <div className="picto-wrap emptystate">
                <span
                  className={
                    'picto' +
                    (isNewBrandedSite ? ' icon-servicesaspictosservices' : 'iconloyalty-event')
                  }
                ></span>
                <p className="text-uppercase">{mosiacData.emptyText}</p>
              </div>
            </div>
          </section>
            <div className="loadersmall asyncloader invisible" id="mosaicloader">
              <div className="dot-spin top-left"></div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    )
  } else {
    return <></>;
  }
}
