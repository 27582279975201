import IframeResizer from '@iframe-resizer/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';

export default function EventBooking({ fields }) {
  var visitorId = useSelector((state) => state.user.visitorId)
  var accessToken = useSelector((state) => state.user.accessToken)
  var refreshToken = useSelector((state) => state.user.refreshToken)
  let workerCenter = useSelector((state) => state.user.workerCenter);

  var queryStringitemId = "";

  const [loaded, setLoaded] = useState(false);
  const [bookingBugUrl, setBookingBugUrl] = useState('');


  useEffect(() => {
    const js = document.createElement("script");
    js.type = "text/javascript";
    js.src = "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.14/iframeResizer.js";
    js.addEventListener('load', () => setLoaded(true))
    document.body.appendChild(js);

    if (visitorId !== null && visitorId !== "" && visitorId !== undefined) {
      getBookingBugUrl();
    }
    else {
      window.location.href = baseModel.newsPageRedirectUrl;
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
    }
    else {
      window.iFrameResize({ log: false, checkOrigin: false, enablePublicMethods: true }, '#bookingIframe');
    }
  }, [loaded, bookingBugUrl]);

  function getBookingBugUrl() {
    var loader = document.getElementById("dotloader");
    if (loader !== null && loader !== undefined && loader !== "") {
      loader.classList.remove("d-none");
    }
    let isWorker =
      workerCenter !== undefined &&
      workerCenter !== '' &&
      workerCenter.split(',').includes(baseModel.pC_Code);
    if (typeof window !== "undefined" && window.location.href !== '') {
      queryStringitemId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    }
    axios({
      method: "post",
      url: "/api/sitecore/NewsApi/GetEventBookingUrl",
      data: {
        ursite: baseModel.urContextSite,
        urlang: baseModel.contextLang,
        isWorker: isWorker,
        itemName: queryStringitemId,
        strUUID: visitorId,
        accessToken: accessToken,
        refreshToken: refreshToken,
      },
      async: false,
    })
      .then(function (response) {
        if (loader !== null && loader !== undefined && loader !== "") {
          loader.classList.add("d-none");
        }
        if (response.data !== "") {
          if (response.data[0]) {
            setBookingBugUrl(response.data[1]);
          } else {
            window.location.href = response.data[1];
          }
        }
        else {
          console.log(response.data + "Axios call exception");
          return <></>;
        }
      })
      .catch(function (error) {
        console.log(error);
        if (loader !== null && loader !== undefined && loader !== "") {
          loader.classList.remove("d-none");
        }
        return <></>;
      });

  }
  if (bookingBugUrl !== null && bookingBugUrl !== undefined && bookingBugUrl !== "") {
    return (
      <div className="" id="bookingIframeDiv" style={{ padding: '0.75rem' }}>
        <IframeResizer id="bookingIframe" src={bookingBugUrl} className="col-12" width="100%" title="bookingIframe" waitForLoad></IframeResizer>
      </div>
    )
  } else {
    return <></>;
  }
}
