import React from 'react';
import { dateselect } from '../Js-CommonFunctions';

// Accepts a Date object or date string that is recognized by the Date.parse() method
function getDayOfWeek(date) {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek) ? null :
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}

function fnShowTimedropdownListNonRebranded(fields) {
  let firstload = 1;
  let styleclassname = "d-flex";

  if (fields.movieShowItem.customDropDownListDates != null) {
    return (
      fields.movieShowItem.customDropDownListDates.map((listdates, index) => {
        if (firstload != 1) {
          styleclassname = "d-none";
        }
        firstload++;
        var dayOfWeek = getDayOfWeek(listdates.dropDownListDates)
        return (
            <div key={index} className={styleclassname + " showtimes-result row"} id={"Alldays" + (dayOfWeek !== null && dayOfWeek !== "" ? dayOfWeek : "")} >

            <div className="showtimes-result-date col-md-4 text-center text-uppercase">{listdates.listofdates}</div>
            <div className="col-md-8">
              {(fields.movieShowItem.movieShowList !== null) &&
                fields.movieShowItem.movieShowList.map((movieeventsitem, indexLst) => {
                  return (
                      <div key={indexLst} className="showtimes-version row">
                      <div className="showtimes-version-name col-3">{movieeventsitem.key.projection == "" ? movieeventsitem.key.filmEventVersion : movieeventsitem.key.filmEventVersion + "-" + movieeventsitem.key.projection}  </div>
                      <div className="showtimes-version-hours col-9">
                        <ul>
                          {(movieeventsitem.value !== null) &&
                            movieeventsitem.value.map((filmschedule, filmIndex) => {
                              if (listdates.date == filmschedule.filmScheduleExtracteddate) {
                                return (
                                    <li key={filmIndex} className="enable">
                                    {(filmschedule.enable) ?
                                      (
                                        <a  href={filmschedule.luxInstantBookingURL} aria-label={filmschedule?.luxInstantBookingURL} target="_blank" rel="noreferrer">{filmschedule.filmscheduletime}</a>

                                      ) :
                                      (
                                        filmschedule.filmscheduletime

                                      )
                                    }
                                  </li>

                                )
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  )
                })

              }
            </div>
          </div>
        )
      })
    )

  }
}

function fnShowTimedropdownListRebranded(fields) {
  let firstload = 1;
  let styleclassname = "d-flex";

  if (fields.movieShowItem.customDropDownListDates != null) {


    return (
      <div className="d-flex flex-column flex-lg-row align-items-end p-0">
        <div className="row showtime-hours">
          {fields.movieShowItem.customDropDownListDates.map((listdates, index) => {
            if (firstload != 1) {
              styleclassname = "d-none";
            }
            firstload++;
            return (
              <div key={index} className={styleclassname + " col-12 p-0 showtimes-version-hours"} id={"Alldays" + listdates.dayOfWeek}>
                {(fields.movieShowItem.movieShowList !== null) &&
                  fields.movieShowItem.movieShowList.map((movieeventsitem, indexLst) => {
                    return (
                      <>
                        <div key={indexLst} className="showtimes-version-name ps-0 pe-3 text-uppercase fw-bold">{movieeventsitem.key.projection == "" ? movieeventsitem.key.filmEventVersion : movieeventsitem.key.filmEventVersion + "-" + movieeventsitem.key.projection}  </div>
                        <ul className="m-0 p-0">
                          {(movieeventsitem.value != null) &&
                            movieeventsitem.value.map((filmschedule, index) => {
                              if (listdates.date == filmschedule.filmScheduleExtracteddate) {
                                const targetValue = filmschedule.luxInstantBookingURL !== "" && filmschedule.luxInstantBookingURL !== null ? "_blank" : "";
                                return (
                                  <li key={index}>
                                    {(filmschedule.enable) ?
                                      (
                                        <a href={filmschedule.luxInstantBookingURL} aria-label={filmschedule?.luxInstantBookingURL} target={targetValue}>{filmschedule.filmscheduletime}</a>

                                      ) :
                                      (
                                        filmschedule.filmscheduletime

                                      )
                                    }

                                  </li>

                                )
                              }
                            })

                          }
                        </ul>
                      </>
                    )
                  })

                }
              </div>
            )
          })
          }

        </div>
        {(fields.movieShowItem.urlValue !== null) &&
          <a className="link-button mr-2 mb-3 gtmMovieBook" data-analyticslabel="" href={fields.movieShowItem.urlValue}>{fields.movieShowItem.movie_BookNow}</a>

        }
      </div>
    )

  }
}

export default function MovieShowTimes({ fields }) {

  if (fields.movieShowItem !== undefined && fields.movieShowItem !== null && fields.movieShowItem !== "" && fields.movieShowItem.movieNextShow !== undefined
    && fields.movieShowItem.movieNextShow !== null && fields.movieShowItem.movieNextShow !== ""
    || (fields.movieShowItem.dropDownListDates !== null && fields.movieShowItem.dropDownListDates.length > 0)) {
    const isNewBrandedSite = fields.movieShowItem.isNewBrandedSite;

    return (
      (!isNewBrandedSite) ?
        (
          <section className="showtimes" id="showtimes">
            <div className="container">
              {(fields.movieShowItem.movie_Showtimes !== "" && fields.movieShowItem.movie_Showtimes !== null) &&
                <h2 className="content-title text-uppercase text-center underline">{fields.movieShowItem.movie_Showtimes}</h2>
              }
              {(fields.movieShowItem.movieNextShow !== undefined && fields.movieShowItem.movieNextShow !== null && fields.movieShowItem.movieNextShow !== "") &&
                <h3 className="text-uppercase text-center">
                  <span className="subtitle">{fields.movieShowItem.movie_NextShowIn}</span>
                  <span>
                    {fields.movieShowItem.movieNextShow}
                  </span>
                </h3>
              }
              <form className="form-showtimes row">
                <div className="col-12 col-sm-4 text-end">
                  <label className="text-uppercase">{fields.movieShowItem.movie_SelectPrivilegesDay}</label>
                </div>
                <div className="col-12 col-sm-8">
                  <select className="text-uppercase" id="selectdatelist" onChange={() => dateselect()}>
                    {(fields.movieShowItem.customDropDownListDates != null) &&

                      fields.movieShowItem.customDropDownListDates.map((listdates, index) => {
                        let optioncount = 1;
                        return (
                          (optioncount == 1 && listdates.date == listdates.currentdate) ?
                            (
                              <option value={listdates.dayOfWeek}>{fields.movieShowItem.dictToday + "," + listdates.listofdates.toUpperCase()}</option>

                            ) :
                            (
                              (optioncount == 2 && listdates.date == listdates.addDays) ?
                                (
                                  <option value={listdates.dayOfWeek}>{fields.movieShowItem.dictTomorro + "," + listdates.listofdates.toUpperCase()}</option>

                                ) :
                                (
                                  <option value={listdates.dayOfWeek}>{listdates.listofdates.toUpperCase()}</option>

                                )
                            )

                        )
                      })

                    }
                  </select>

                </div>
              </form>
              {fnShowTimedropdownListNonRebranded(fields)}
              {(fields.movieShowItem.urlValue !== "") &&
                <div className="text-center link-dark">
                  <a className="link-button btn-white analytics_moviebooknowclk2 gtmMovieBook" target="_blank" rel="noreferrer" data-analyticslabel="" href={fields.movieShowItem.urlValue}>{fields.movieShowItem.movie_BookNow}</a>
                </div>
              }
            </div>
          </section>

        ) :
        (
          <section className="showtimes-new back-gray" id="showtimes">
            <div className="container">
              <div className="p-0 showtimes-result row">
                <div className="col-md-4">
                  <div className="d-flex flex-column justify-content-center showtimes-result-date text-center text-uppercase border-8">
                    <span className="picto icon-clock"></span>
                    {(fields.movieShowItem.movieNextShow !== undefined && fields.movieShowItem.movieNextShow !== null && fields.movieShowItem.movieNextShow !== "") &&
                      <> < div className="title">{fields.movieShowItem.movie_NextShowIn}</div>
                        <div className="schedule"> {fields.movieShowItem.movieNextShow}</div>
                      </>
                    }
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="bg-white p-4 m-0 showtimes-version border-8">
                    <form>
                      <div className="mb-3">
                        <label className="mb-3 text-uppercase">{fields.movieShowItem.movie_SelectPrivilegesDay}</label>
                        <select className="form-control" id="selectdatelist" onChange={() => dateselect()}>
                          {(fields.movieShowItem.customDropDownListDates != null) &&

                            fields.movieShowItem.customDropDownListDates.map((listdates, index) => {
                              var optioncount = 1;
                              return (
                                (optioncount == 1 && listdates.date == listdates.currentdate) ?
                                  (
                                    <option value={listdates.dayOfWeek}>{fields.movieShowItem.dictToday + "," + listdates.listofdates.toUpperCase()}</option>

                                  ) :
                                  (
                                    (optioncount == 2 && listdates.date == listdates.addDays) ?
                                      (
                                        <option value={listdates.dayOfWeek}>{fields.movieShowItem.dictTomorrow + "," + listdates.listofdates.toUpperCase()}</option>

                                      ) :
                                      (
                                        <option value={listdates.dayOfWeek}>{listdates.listofdates.toUpperCase()}</option>

                                      )
                                  )

                              )
                            })
                          }
                        </select>
                      </div>
                    </form>

                    {fnShowTimedropdownListRebranded(fields)}

                  </div>
                </div>
              </div>
            </div>
          </section>
        )

    );

  } else {
    return <></>;
  }
}