import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import React from "react";

export default function SquadBanner({ fields, rendering }) {
  if (fields !== null && fields !== undefined && fields !== "" && fields.squadBanner !== null
    && fields.squadBanner !== undefined && fields.squadBanner !== "") {
    return (
      <section className="loyalty-block-wc p-0 position-relative">
        <div className="loyalty-block-wc-breadcrumb">
          <Placeholder
            name={fields.squadBanner.leftPlaceholder}
            rendering={rendering}
          />
        </div>
        <Placeholder
          name={fields.squadBanner.rightPlaceholder}
          rendering={rendering}
        />
      </section>
    );
  } else {
    return <></>;
  }
}

