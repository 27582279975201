import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

export default function ContactMall({ fields }) {
    if (fields.introduction !== null) {
        return (
            <>
                {(fields.introduction.isNewBrandedSite) ?
                    (
                        <section className="back-gray">
                            <div className="container">
                                <div className="contact-infos text-start">
                                    <div className="popin-wrap">
                                        {(fields.content !== null && fields.content !== "" && fields.content[fields.constant.contentHeading] !== null && fields.content[fields.constant.contentHeading] !== "") &&
                                            <h2 className="content-title "><Text field={fields.content[fields.constant.contentHeading]} /></h2>
                                        }
                                        <div className="picto-wrap">
                                            <i className={fields.introduction.content_Pictogtram?.value} />
                                        </div>
                                        {(fields.introduction.content_Title?.value !== "" && fields.introduction.content_Title?.value !== null) &&
                                            <h3 >{fields.introduction.content_Title.value} </h3>
                                        }
                                        <div className="description">
                                            {(fields.content !== null && fields.content !== "" && fields.content[fields.constant.contentIntroduction] !== null) &&
                                                < RichText field={fields.content[fields.constant.contentIntroduction]} />
                                            }
                                        </div>
                                        <div className="phone">
                                            {(fields.content !== null && fields.content !== "" && fields.content[fields.constant.contentLinkText] !== null) &&
                                                <Text field={fields.content[fields.constant.contentLinkText]} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) :
                    (
                        <section>
                            <div className="contact-infos">
                                <div className="wrap">
                                    {(fields.content !== null && fields.content !== "" && fields.content[fields.constant.contentHeading] !== null && fields.content[fields.constant.contentHeading] !== "") &&
                                        <h2 className="content-title text-start"><Text field={fields.content[fields.constant.contentHeading]} /></h2>
                                    }
                                    <div className="picto-wrap">
                                        <i className={fields.introduction.content_Pictogtram?.value} />
                                    </div>
                                    {(fields.introduction.content_Title?.value !== "" && fields.introduction.content_Title?.value !== null) &&
                                        <h3>{fields.introduction.content_Title.value} </h3>
                                    }
                                    <div className="description">
                                        {(fields.content !== null && fields.content !== "" && fields.content[fields.constant.contentIntroduction] !== null) &&
                                            <RichText field={fields.content[fields.constant.contentIntroduction]} />
                                        }
                                    </div>
                                    <div className="phone">
                                        {(fields.content !== null && fields.content !== "" && fields.content[fields.constant.contentLinkText] !== null) &&
                                            <Text field={fields.content[fields.constant.contentLinkText]} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            </>
        );
    } else {
        return <></>;
    }
}
