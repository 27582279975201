import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';


export default function PhPhotoGallery({ rendering }) {

  if (rendering) {
    return (
      <div className="container">
        <Placeholder name="ph_imagegalary" rendering={rendering} />
      </div>
    )
  } else {
    return <></>;
  }

}
