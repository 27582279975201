import loadable from "@loadable/component";
import React from 'react';
const MultiNewsWithImagesInnerPage = loadable(() => import("../MultiNewsWithImagesInnerPage"));


export default function ListImageTitleLinkDesc({ fields }) {
  if (fields.offerNewMV !== null && fields.offerNewMV !== undefined && fields.offerNewMV !== "" && fields.offerNewMV.genericPageTitle != null || (fields.offerNewMV !== null && fields.offerNewMV?.multiINewsWithImages != null && fields.offerNewMV.multiINewsWithImages.length > 0)) {
    return (
      <MultiNewsWithImagesInnerPage newsImagedata={fields.offerNewMV} />

    )
  } else {
    return <></>;
  }
}
