import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';


export default function TitleImageDesc({ fields }) {
  if (fields) {

    return (
      <div className="col-md-6 col-12 history-block">
        <div className="container">
          {fields.fromContentResolver !== null && fields.constant.historY_DATE != null && fields.constant.historY_DATE !== "" &&

            <h3 className="title">
              {(fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.historY_DATE] !== null && fields.fromContentResolver[fields.constant.historY_DATE]?.value !== "" && fields.fromContentResolver[fields.constant.historY_DATE]?.value !== null) &&
                <RichText field={fields.fromContentResolver[fields.constant.historY_DATE]} />
              }
            </h3>
          }
          <div className="img-wrap">
            {fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.historY_IMAGE] != null && fields.fromContentResolver[fields.constant.historY_IMAGE]?.value?.src != null ?
              (<Image field={fields.fromContentResolver[fields.constant.historY_IMAGE]} imageParams={{ mh: 507 }} />)
              :
              <></>
            }
          </div>
          <div className="row">
            <div className="col-12 history-block">
              <div className="DiscoverMalldescription">
                {(fields.fromContentResolver !== null && fields.fromContentResolver[fields.constant.historY_DESCRIPTION] !== null && fields.fromContentResolver[fields.constant.historY_DESCRIPTION]?.value !== "" && fields.fromContentResolver[fields.constant.historY_DESCRIPTION]?.value !== null) &&
                  <RichText field={fields.fromContentResolver[fields.constant.historY_DESCRIPTION]} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  } else {
    return <></>;
  }
}