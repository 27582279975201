import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

export default function PhAnchors({ rendering }) {
  if (rendering) {
    return (
      <Placeholder name="ph_mallanchors" rendering={rendering} />
    )
  } else {
    return <></>;
  }
}
